import React, { useState, useCallback, useContext } from "react";
import { GET_USERS_BY_COMPANY_ID_WITHOUT_ME } from 'data/queries';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "../../../../context/UserContext";
import { useQuery } from '@apollo/react-hooks';
import {
  Table,
  LinearProgress,
  Card,
  CardContent,
  Button,
  Tooltip,
  Dialog,
} from "@material-ui/core";
import clsx from 'clsx';

import { InputAdornment, TextField } from '@material-ui/core';

import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';

import defaultAvatar from "../../../../assets/images/climind/default_avatar.png";

export default function ModalSelectLeader({ modalLeader, toggleLeader, leaderSelected, setLeaderSelected, compId }) {
  const [filterTable, setFilterTable] = useState(null)
  const [searchStatus1, setSearchStatus1] = useState(false);
  const toggleSearch1 = () => setSearchStatus1(!searchStatus1);
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  let companyId = null;
  if (compId) {
    companyId = compId;
  } else {
    companyId = dbuser.getUserByUid.companyId;
  }

  const { loading: loadingUsers, error: errorUsers, data: dataUsers, networkStatus: networkStatusUsers, refetch: refetchUsers } = useQuery(GET_USERS_BY_COMPANY_ID_WITHOUT_ME,
    {
      variables: { id: companyId, userId: dbuser.getUserByUid.id },
      skip: !companyId,
      notifyOnNetworkStatusChange: true,
    })
  const onSelectUser = useCallback((user) => {
    setLeaderSelected(user)
    toggleLeader()
  })
  if (dataUsers) {
    return (
      <>
        <Dialog
          open={modalLeader}
          onClose={toggleLeader}
          maxWidth="xl"
          classes={{ paper: "modal-content rounded-lg" }}
        >
          <div className="text-center p-5 mb-3">
            <h4 className="text-center gx-mb-4">
              Selecciona un usuario al que entregar el reconocimiento
                  </h4>
            <div
              className={clsx('search-wrapper search-wrapper--grow mb-4', {
                'is-active': searchStatus1
              })}>
              <TextField
                variant="outlined"
                size="small"
                id="input-with-icon-textfield22-1"
                onFocus={toggleSearch1}
                onBlur={toggleSearch1}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchTwoToneIcon />
                    </InputAdornment>
                  )
                }}
                onChange={e => {
                  const filteredTable = dataUsers.getUsersByCompanyIdWithoutMe.filter(o =>
                    Object.keys(o).some(k => k === "name" &&
                      String(o[k])
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    )
                  );

                  setFilterTable(filteredTable)
                }}
              />
            </div>
            <Card className="card-box mb-spacing-6-x2">
              <CardContent>
                <div className="table-responsive-xl">
                  <Table className="table table-borderless table-hover text-nowrap mb-0">
                    <thead>
                      <tr>
                        <th className="text-left">Usuario</th>
                        <th className="text-center">Entregar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!filterTable ? dataUsers.getUsersByCompanyIdWithoutMe.map((element, index) => {
                        return (
                          <tr>
                            <td>
                              <div className="d-flex align-items-center">
                                {element.urlImage === null ||
                                  element.urlImage === "" ? (
                                  <div className="avatar-icon-wrapper avatar-initials avatar-icon-sm pr-2">
                                    <div className="avatar-icon text-white bg-primary">
                                      {element.name.charAt(0).toUpperCase()}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="avatar-icon-wrapper rounded-circle avatar-icon-sm pr-2">
                                    <div className="avatar-icon ">
                                      <img alt="..." className="img-fluid" src={element.urlImage} />
                                    </div>
                                  </div>
                                )}
                                <div>{element.name} {element.surname}</div>
                                <span className="text-black-50 d-block">
                                    {element.email}
                                  </span>
                              </div>
                            </td>
                            <td className="text-center">
                              <Button
                                size="small"
                                className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon"
                                onClick={() => onSelectUser(element)}
                              >
                                <FontAwesomeIcon icon={["fas", "arrow-right"]} />
                              </Button>
                            </td>
                          </tr>
                        )
                      }) :
                        filterTable.map((element, index) => {
                          return (
                            <tr>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="avatar-icon-wrapper mr-3">
                                    <div className="avatar-icon">
                                      {element.urlImage ? <img alt="..." src={element.urlImage} /> : <img alt="..." src={defaultAvatar} />}
                                    </div>
                                  </div>
                                  <div className="text-left">
                                    <a
                                      href="#/"
                                      onClick={(e) => e.preventDefault()}
                                      className="font-weight-bold text-black"
                                      title="..."
                                    >
                                      {element.name} {element.surname}
                                    </a>
                                    <span className="text-black-50 d-block">
                                      {element.email}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td className="text-center">
                                <Button
                                  size="small"
                                  className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon"
                                  onClick={() => onSelectUser(element)}
                                >
                                  <FontAwesomeIcon icon={["fas", "arrow-right"]} />
                                </Button>
                              </td>
                            </tr>
                          )
                        })
                      }

                    </tbody>
                  </Table>
                </div>
              </CardContent>
            </Card>

            <div className="pt-4" style={{ marginBottom: 20 }}>
              <Button
                onClick={toggleLeader}
                className="btn-neutral-dark btn-pill mx-1"
              >
                <span className="btn-wrapper--label">Atrás</span>
              </Button>
            </div>
          </div>
        </Dialog>
      </>
    );
  } else {
    return null
  }

}
