import React, { useEffect, useState, useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import moment from 'moment';

import {
  RingLoader,
} from 'react-spinners';

import { PageTitle } from "layout-components";

import Dashboards from "./components/Dashboards";

import Filters from "../../../../climind-components/Filters/Filters";


export default function DashboardEnvironment() {

  const [filtersInput, setFiltersInput] = useState({
    initDate: moment().subtract(30, 'days').format('MM-DD-YYYY'),
    finishDate: moment().format('MM-DD-YYYY'),
    departments: [],
    groups: []
  })

  const [filtersPreviousInput, setFiltersPreviousInput] = useState({
    initDate: moment().subtract(60, 'months').startOf('month').format('MM-DD-YYYY'),
    finishDate: moment().subtract(30, 'months').format('MM-DD-YYYY'),
    departments: [],
    groups: []
  })

  return (
    <>
      <PageTitle
        titleHeading="Clima"
        titleDescription="Analiza la información sobre Clima laboral."
      />
      <Filters
        setFiltersInput={setFiltersInput}
        setFiltersPreviousInput={setFiltersPreviousInput}
      />
      <Dashboards
        filtersInput={filtersInput}
        filtersPreviousInput={filtersPreviousInput}
      />

    </>
  );
}
