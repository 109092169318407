import React, { useContext } from "react";
import { useQuery } from "@apollo/react-hooks";

import DashboardUsage1 from "./DashboardUsage1";
import DashboardUsage2 from "./DashboardUsage2";


import { UserContext } from "../../../../../context/UserContext";
import { GET_METRICS_USAGE } from "data/queries";

export default function Dashboards({
    filtersInput,
    filtersPreviousInput
}) {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  let companyId = dbuser.getUserByUid.companyId;
  let userId = dbuser.getUserByUid.id;


  const { loading, error, data, networkStatus } = useQuery(GET_METRICS_USAGE, {
    variables: { id: userId, input: filtersInput },
    skip: !companyId,
    notifyOnNetworkStatusChange: true,
  });

  const { loading: loadingPrevious, error: errorPrevious, data: dataPrevious, networkStatus: netPrevious } = useQuery(GET_METRICS_USAGE, {
    variables: { id: userId, input: filtersPreviousInput },
    skip: !companyId,
    notifyOnNetworkStatusChange: true,
  });

  // if (networkStatus === 4) return "Refetching!";
  // if (loading || loadingPrevious || loadingDepartments || loadingGroups || loadingCompany) return "Esperando";
  if (error || errorPrevious) return `Error! ${error}`;

  if (data === undefined || dataPrevious === undefined ) {
    return null
  }

  if (data && dataPrevious) {
    // console.log(dataCompany.getCompanyById.createdAt);
    console.log(data);

    // Número total de empleados (Entero) - QUERY
    let TotalUsers = data.getMetricsUsage.totalUsers;
    let TotalUsersPrevious = dataPrevious.getMetricsUsage.totalUsers;

    // Número total de empleados Activos (Entero) - QUERY
    let TotalActiveUsers = data.getMetricsUsage.totalActiveUsers;
    let TotalActivePreviousUsers = dataPrevious.getMetricsUsage.totalActiveUsers;

    // Nivel de usuarios activos
    let TotalActiveUsersPercent = TotalActiveUsers / TotalUsers;
    let TotalActivePreviousUsersPercent = TotalActivePreviousUsers / TotalUsersPrevious;

    console.log(TotalActiveUsers);
    console.log(TotalUsers);
    console.log(TotalActivePreviousUsers);
    console.log(TotalUsersPrevious);

    // Número de días contabilizados
    let TotalDays = data.getMetricsUsage.dataUsers.length;
    let TotalDaysPrevious = dataPrevious.getMetricsUsage.dataUsers.length;

    // Nivel de recurrencia (Array)
    let arrayRecurringUsers = [];
    let arrayDates = [];
    let tmpTotalRecurringUsers = 0;
    data.getMetricsUsage.dataUsers.map((item, index) => {
      let tmpRecurringDay = item.users / TotalUsers;
      tmpTotalRecurringUsers = tmpTotalRecurringUsers + tmpRecurringDay;
      arrayRecurringUsers.push(Math.round(tmpRecurringDay * 100));
      arrayDates.push(item.date);
    });
    let TotalRecurringUsers = tmpTotalRecurringUsers / TotalDays;

    // Nivel de recurrencia Previo (Array)
    let arrayRecurringUsersPrevious = [];
    let arrayDatesPrevious = [];
    let tmpTotalRecurringUsersPrevious = 0;
    let TotalRecurringUsersPrevious = 0;
    if (dataPrevious.getMetricsUsage.dataUsers.length > 0) {
      dataPrevious.getMetricsUsage.dataUsers.map((item, index) => {
        let tmpRecurringDay = item.users / TotalUsersPrevious;
        tmpTotalRecurringUsersPrevious = tmpTotalRecurringUsersPrevious + tmpRecurringDay;
        TotalRecurringUsersPrevious = tmpTotalRecurringUsersPrevious / TotalDaysPrevious;
        arrayRecurringUsersPrevious.push(Math.round(tmpRecurringDay * 100));
        arrayDatesPrevious.push(item.date);
      })
    } else {
      TotalRecurringUsersPrevious = 0
    }

    return (
      <>
        <DashboardUsage1
          TotalUsers={TotalUsers}
          TotalActiveUsers={TotalActiveUsers}
          TotalActivePreviousUsers={TotalActivePreviousUsers}
          TotalActiveUsersPercent={TotalActiveUsersPercent}
          TotalActivePreviousUsersPercent={TotalActivePreviousUsersPercent}
          TotalRecurringUsers={TotalRecurringUsers}
          TotalRecurringUsersPrevious={TotalRecurringUsersPrevious}
          loading={loading}
          loadingPrevious={loadingPrevious}
        />
        <DashboardUsage2
          arrayRecurringUsers={arrayRecurringUsers}
          arrayDates={arrayDates}
          arrayRecurringUsersPrevious={arrayRecurringUsersPrevious}
          arrayDatesPrevious={arrayDatesPrevious}
        />
      </>
    );
  }
}
