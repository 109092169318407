import React, { useState, useCallback, useContext } from "react";
import { GET_COMPETENCES_BY_USER_ID } from "data/queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "../../../../context/UserContext";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  Table,
  LinearProgress,
  Card,
  CardContent,
  Button,
  Tooltip,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Input,
} from "@material-ui/core";
import { NEW_USER } from "../../../../data/mutations";
import { GET_DEPARTMENTS_GROUPS_AND_USER } from "../../../../data/queries";
// Forms
import { useFormik } from "formik";
import * as Yup from "yup";

import { Formik } from "formik";
import MaskedInput from "react-input-mask";

import PropTypes from "prop-types";
import SnackBarMessage from "../../../../utils/SnackBarMessage";

function TextMaskDate(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={"\u2000"}
      showMask
      guide={true}
    />
  );
}
TextMaskDate.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function TextMaskCc(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      showMask
      guide={true}
    />
  );
}
TextMaskCc.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default function ModalAddEmployee({
  openAddEmployee,
  toggleAddEmployee,
  compId,
  refetch
}) {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  let companyId = null;
  if (compId) {
    companyId = compId;
  } else {
    companyId = dbuser.getUserByUid.companyId;
  }

  const [newUser, { data }] = useMutation(NEW_USER);
  const [values, setValues] = useState({
    name: "",
    surname: "",
    email: "",
    dni: "",
    role: "",
    gender: "",
    position: "",
  });
  let thumb = "";
  const { name, surname, dni, email, role, gender, position } = values;
  const [alert, setAlert] = useState({
    messageAlert: "",
    severity: "",
    openSnackbar: false,
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      surname: "",
      email: "",
      dni: "",
      role: "",
      gender: "",
      position: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .matches(/^[A-zÀ-ÿ\s]*$/, {
          message: "Solo están permitidos letras A-Z",
        })
        .required("Obligatorio"),
      surname: Yup.string()
        .matches(/^[A-zÀ-ÿ\s]*$/, {
          message: "Solo están permitidos letras A-Z",
        })
        .required("Obligatorio"),
      email: Yup.string().email("Email invalido").required("Obligatorio"),
      dni: Yup.string()
        .matches(/^([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])$/, {
          message: "El documento de identidad no es valido",
        })
        .required("Obligatorio"),
      role: Yup.string().required("Obligatorio"),
      gender: Yup.string().required("Obligatorio"),
      position: Yup.string().required("Obligatorio"),
    }),
    onSubmit: async (data, { setErrors, resetForm }) => {
      let dataInput = {
        companyId: companyId,
        name: data.name,
        surname: data.surname,
        dni: data.dni,
        email: data.email,
        role: data.role,
        gender: data.gender,
        position: data.position,
      };
      console.log(dataInput);
      try {
        await newUser({
          variables: { input: dataInput },
          // refetchQueries: [
          //   {
          //     query: GET_DEPARTMENTS_GROUPS_AND_USER,
          //     variables: { id: companyId, offset: 0, limit: 5 },
          //   },
          // ],
        });
        refetch()
        setValues({
          name: "",
          surname: "",
          dni: "",
          email: "",
          role: "",
          gender: "",
          position: "",
        });
        toggleAddEmployee();
        await setAlert({
          ...alert,
          messageAlert: "Usuario creado con éxito",
          severity: "success",
          openSnackbar:
            (true,
            () => {
              setTimeout(function () {
                setAlert({ ...alert, openSnackbar: false });
              }, 5000);
            }),
        });
        formik.handleReset();
      } catch (err) {
        setAlert({
          ...alert,
          messageAlert: err.message,
          severity: "error",
          openSnackbar:
            (true,
            () => {
              setTimeout(function () {
                setAlert({ ...alert, openSnackbar: false });
              }, 5000);
            }),
        });
      }
    },
  });

  return (
    <>
      <SnackBarMessage
        open={alert.openSnackbar}
        handleClose={() => setAlert({ ...alert, openSnackbar: false })}
        message={alert.messageAlert}
        severity={alert.severity}
      />
      <Dialog
        open={openAddEmployee}
        onClose={() => {
          formik.handleReset();
          toggleAddEmployee();
        }}
        classes={{ paper: "modal-content rounded-lg" }}
      >
        <DialogTitle className="bg-white-10">Nuevo Empleado</DialogTitle>

        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <div className="text-center my-auto">
                  <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-primary shadow-primary-sm text-primary mb-2 d-90">
                    <FontAwesomeIcon
                      icon={["fas", "building"]}
                      className="d-flex align-self-center font-size-xxl"
                    />
                  </div>
                  <h6 className="font-weight-bold font-size-xxl mb-1 mt-3 text-primary">
                    Información Básica
                  </h6>
                </div>
                <Card
                  className="p-4 mt-4"
                  style={{ backgroundColor: "#eceef7" }}
                  elevation={0}
                >
                  <div>
                    <TextField
                      fullWidth
                      className="m-2"
                      id="outlined-multiline-static-1"
                      name="name"
                      label="Nombre"
                      placeholder="Escribe aqui..."
                      variant="outlined"
                      {...formik.getFieldProps("name")}
                      error={Boolean(formik.touched.name && formik.errors.name)}
                      helperText={formik.touched.name ? formik.errors.name : ""}
                    />
                    <TextField
                      fullWidth
                      className="m-2"
                      id="outlined-multiline-static-2"
                      name="surname"
                      label="Apellidos"
                      placeholder="Escribe aqui..."
                      variant="outlined"
                      {...formik.getFieldProps("surname")}
                      error={Boolean(
                        formik.touched.surname && formik.errors.surname
                      )}
                      helperText={
                        formik.touched.surname ? formik.errors.surname : ""
                      }
                    />
                    <TextField
                      fullWidth
                      className="m-2"
                      id="outlined-multiline-static-3"
                      name="dni"
                      label="DNI"
                      placeholder="Escribe aqui..."
                      variant="outlined"
                      {...formik.getFieldProps("dni")}
                      error={Boolean(formik.touched.dni && formik.errors.dni)}
                      helperText={formik.touched.dni ? formik.errors.dni : ""}
                    />
                    <TextField
                      fullWidth
                      className="m-2"
                      id="outlined-multiline-static-4"
                      name="email"
                      label="Email"
                      placeholder="Escribe aqui..."
                      variant="outlined"
                      {...formik.getFieldProps("email")}
                      error={Boolean(
                        formik.touched.email && formik.errors.email
                      )}
                      helperText={
                        formik.touched.email ? formik.errors.email : ""
                      }
                    />
                    <TextField
                      fullWidth
                      className="m-2"
                      id="outlined-multiline-static-4"
                      name="position"
                      label="Puesto de trabajo"
                      placeholder="Escribe aqui..."
                      variant="outlined"
                      {...formik.getFieldProps("position")}
                      error={Boolean(
                        formik.touched.position && formik.errors.position
                      )}
                      helperText={
                        formik.touched.position ? formik.errors.position : ""
                      }
                    />
                    <FormControl fullWidth variant="outlined" className="m-2">
                      <InputLabel id="demo-simple-select-outlined-label">
                        Sexo
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined"
                        id="demo-simple-select-outlined"
                        name="gender"
                        // value={props.values.gender}
                        // onChange={props.handleChange}
                        // onBlur={props.handleBlur}
                        {...formik.getFieldProps("gender")}
                        error={Boolean(
                          formik.touched.gender && formik.errors.gender
                        )}
                        helperText={
                          formik.touched.gender ? formik.errors.gender : ""
                        }
                        label="Age"
                      >
                        <MenuItem value={"MALE"}>MASCULINO</MenuItem>
                        <MenuItem value={"FEMALE"}>FEMENINO</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl fullWidth variant="outlined" className="m-2">
                      <InputLabel id="demo-simple-select-outlined-label">
                        Rol
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined"
                        id="demo-simple-select-outlined"
                        name="role"
                        label="Rol"
                        {...formik.getFieldProps("role")}
                        error={Boolean(
                          formik.touched.role && formik.errors.role
                        )}
                        helperText={
                          formik.touched.role ? formik.errors.role : ""
                        }
                      >
                        <MenuItem value="user">USUARIO</MenuItem>
                        <MenuItem value="admin">ADMINISTRADOR</MenuItem>
                      </Select>
                    </FormControl>
                    {/* <Tooltip
                      title="Rol y su funcionamiento"
                      arrow
                      placement="top"
                    >
                      <TextField
                        fullWidth
                        className="m-2"
                        id="demo-simple-select-outlined"
                        select
                        name="role"
                        label="Rol"
                        {...formik.getFieldProps("role")}
                        error={Boolean(
                          formik.touched.role && formik.errors.role
                        )}
                        helperText={
                          formik.touched.role ? formik.errors.role : ""
                        }
                      >
                        <MenuItem value="user">Usuario</MenuItem>
                        <MenuItem value="admin">Admin</MenuItem>
                      </TextField>
                    </Tooltip> */}
                  </div>
                </Card>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="p-4 bg-white-10">
            <Button
              className="btn-neutral-primary"
              onClick={() => {
                toggleAddEmployee();
              }}
              type="reset"
            >
              Cancelar
            </Button>{" "}
            <Button className="btn-primary ml-auto" type="submit">
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
