import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useM,
} from "react";

import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  Container,
  InputAdornment,
  ButtonGroup,
  Card,
  Button,
  List,
  ListItem,
  LinearProgress,
  Tooltip,
  TextField,
  Dialog,
  Snackbar,
} from "@material-ui/core";

import {
  EDIT_USER_BASIC_INFO,
  CHANGE_PASSWORD_USER,
  SAVE_USER_IMAGE,
} from "data/mutations";
import {
  GET_USER_BY_UID,
  GETS_FOR_GENERAL,
  GET_USER_BY_ID,
} from "data/queries";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import General from "../Profile/components/General";
import EditProfile from "../Profile/components/EditProfile";
import Privacity from "../Profile/components/Privacity";
import Groups from "../Profile/components/Groups";
import { useQuery, useMutation } from "@apollo/react-hooks";
import MuiAlert from "@material-ui/lab/Alert";
import { RingLoader } from "react-spinners";
import emotion from "../../assets/images/climind/reaction.png";
import recognition from  "../../assets/images/climind/thumbs-up.png";
import pregunta from "../../assets/images/climind/faq.svg";
import felicitacion from "../../assets/images/climind/regalo.png";
import agradecimiento from "../../assets/images/climind/pray.png";
import propuesta from "../../assets/images/climind/planta-de-cultivo.svg";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function User({ match }) {
  console.log(match.params.id);
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  const [inputBg, setInputBg] = useState(false);
  const toggleInputBg = () => setInputBg(!inputBg);
  const [picture, setPicture] = useState(null);
  const [visible, setVisible] = useState(false);
  const [crop, setCrop] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const history = useHistory();
  const [
    saveUserImage,
    {
      data: mutationSaveUserImageData,
      loading: mutationSaveUserImageLoading,
      error: mutationSaveUserImageError,
    },
  ] = useMutation(SAVE_USER_IMAGE);
  const {
    loading: loadingG,
    error: errorG,
    data: dataG,
    networkStatus: networkStatusG,
  } = useQuery(GETS_FOR_GENERAL, {
    variables: { id: match.params.id },
    skip: !match.params.id,
    notifyOnNetworkStatusChange: true,
  });
  const { loading, error, data, networkStatus } = useQuery(GET_USER_BY_ID, {
    variables: { id: match.params.id },
    skip: !match.params.id,
    notifyOnNetworkStatusChange: true,
  });
  const [activeTab2, setActiveTab2] = useState("1");

  const toggle2 = (tab) => {
    if (activeTab2 !== tab) setActiveTab2(tab);
  };

  console.log(dataG);
  console.log(data);

  if (dataG && data) {
    console.log("holaaaaaa");
    return (
      <>
        <div className="app-inner-content-layout">
          <div className="app-inner-content-layout--main bg-white p-0">
            <div className="hero-wrapper rounded-bottom shadow-xxl bg-composed-wrapper bg-second">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div
                  className="bg-composed-wrapper--image rounded-bottom opacity-3"
                  style={{ backgroundColor: "purple" }}
                />
                <div className="bg-composed-wrapper--bg rounded-bottom bg-deep-sky opacity-4" />
                <div className="bg-composed-wrapper--content px-3 pt-5">
                  <Container className="pt-4" style={{ width: "80%" }}>
                    <div className="d-block d-md-flex align-items-start">
                      <div className="dropzone rounded-circle shadow-sm-dark mr-md-3 ">
                        <div>
                          <div className="dropzone-inner-wrapper d-140 rounded-circle dropzone-avatar">
                            <div className="avatar-icon-wrapper d-140 rounded-circle m-2">
                              <div
                                key={data.getUserById.name}
                                className="rounded-circle avatar-image overflow-hidden d-140 bg-neutral-success text-center font-weight-bold text-success d-flex justify-content-center align-items-center"
                              >
                                <img
                                  className="img-fluid img-fit-container rounded-sm  rounded-circle"
                                  src={
                                    data.getUserById.urlImage
                                      ? data.getUserById.urlImage
                                      : require("../../assets/images/climind/default_avatar.png")
                                  }
                                  alt="..."
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex text-white flex-column pl-md-2">
                        <div className="d-block d-md-flex align-items-center">
                          <div
                            className="my-3 my-md-0"
                            style={{ marginRight: 50 }}
                          >
                            <div className="d-flex align-items-end">
                              <div className="font-size-xxl font-weight-bold">
                                @{data.getUserById.username}
                              </div>
                            </div>
                            <div className="font-weight-bold mt-1 font-size-lg text-white-50">
                              {data.getUserById.name}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex font-size-xl py-4 align-items-center">
                          <div className="mr-2">
                            {data.getUserById.score}{" "}
                            <span className="font-size-sm text-white-50">
                              Puntos
                            </span>
                          </div>
                          <div className="mr-2">
                            {dataG.getAnswerEmotionsSendedByUserIdLast30
                              .length +
                              dataG.getAnswersSendedByUserIdLast30.length +
                              dataG.getRecognitionsSendedByUserIdLast30.length +
                              dataG.getImprovementProposalsSendedByUserIdLast30
                                .length +
                              dataG.getCongratulationsSendedByUserIdLast30
                                .length +
                              dataG.getGratitudesSendedByUserIdLast30
                                .length}{" "}
                            <span className="font-size-sm text-white-50">
                              acciones realizadas en los últimos 30 días
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <List className="d-flex nav-tabs justify-content-center nav-tabs-success tabs-animated tabs-animated-shadow my-5">
                      <ListItem
                        button
                        className="bg-white-10 mx-3 rounded-lg"
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        <span className="font-size-lg text-white px-2 py-1">
                          Volver
                        </span>
                        <div className="divider" />
                      </ListItem>
                      <ListItem
                        button
                        selected={activeTab2 === "1"}
                        className="bg-white-10 mx-3 rounded-lg"
                        onClick={() => {
                          toggle2("1");
                        }}
                      >
                        <span className="font-size-lg text-white px-2 py-1">
                          Información
                        </span>
                        <div className="divider" />
                      </ListItem>

                      <ListItem
                        button
                        selected={activeTab2 === "2"}
                        className="bg-white-10 mx-3 rounded-lg"
                        onClick={() => {
                          toggle2("2");
                        }}
                      >
                        <span className="font-size-lg text-white px-2 py-1">
                          Grupos y Departamentos
                        </span>
                        <div className="divider" />
                      </ListItem>
                    </List>
                  </Container>
                </div>
              </div>
            </div>
            <Container className="z-over py-5">
              <div
                className={clsx("tab-item-wrapper overflow-visible d-none", {
                  "d-block active": activeTab2 === "1",
                })}
                index={1}
              >
                <General data={dataG} />
                <Grid container spacing={6}>
                  <Grid item xl={12}>
                    <Card className="card-box">
                      <div className="card-header-alt d-flex justify-content-between p-4">
                        <div>
                          <h6 className="font-weight-bold font-size-lg mb-1 text-black">
                            Acciones realizadas en los últimos 30 días.
                          </h6>
                          {/* <p className="text-black-50 mb-0">
                                Descubre cual es tu uso de climind y tus objetivos
                            </p> */}
                        </div>
                        <div className="d-flex flex-row">
                          {/* <img
                                    alt="..."
                                    className="card-img-top rounded-sm mr-2"
                                    src={premium}
                                    style={{ width: 50 }}
                                /> */}
                          {/* <div className="d-flex align-items-center">  */}
                          {/* <h6 className="font-weight-bold font-size-lg mb-1 text-black mr-3">
                                !Eres un usuario activo!
                            </h6> */}
                          <Tooltip
                            classes={{
                              tooltip: "text-center p-3 tooltip-secondary",
                            }}
                            arrow
                            placement="bottom"
                            title="Aquí podrás ver que porcentaje de acciones estás realizando en los últimos 30 días (Se parte de la base de que se trabaja una media de 20 días de los 30 últimos). Cuando una acción llegue al 100% no te dará más coins pero podrás seguir ayudando a tu empresa con tus respuestas."
                          >
                            <a
                              href="#/"
                              onClick={(e) => e.preventDefault()}
                              className="text-first font-weight-bold"
                            >
                              <div
                                className=" avatar-icon rounded-circle "
                                style={{ width: 26, height: 26 }}
                              >
                                <img
                                  alt="..."
                                  src={require("../../assets/images/climind/info.png")}
                                />
                              </div>
                            </a>
                          </Tooltip>
                          {/* </div> */}
                        </div>
                      </div>
                      {/* <div className="divider" />
                        <div className="px-3 px-lg-5 py-2 m-0 bg-secondary">
                            <Chart
                            options={chartSparklinesLarge2AOptions}
                            series={chartSparklinesLarge2AData}
                            type="line"
                            height={110}
                            />
                        </div> */}
                      <div className="divider" />
                      <div className="p-4 text-center">
                        <Grid container spacing={6}>
                          <Grid item md={4}>
                            <Tooltip
                              classes={{
                                tooltip: "text-center p-3 tooltip-secondary",
                              }}
                              arrow
                              placement="top"
                              title="Número de veces que has enviado información sobre cómo ha sido tu jornada laboral en los últimos 30 días"
                            >
                              <img
                                alt="..."
                                className="card-img-top rounded-sm mb-2 mr-2"
                                src={emotion}
                                style={{ width: 50, height: 50 }}
                              />
                            </Tooltip>
                            <div className="mb-1 font-weight-bold font-size-xl">
                              {Math.round(
                                ((dataG.getAnswerEmotionsSendedByUserIdLast30
                                  .length <= 20
                                  ? Math.round(
                                      dataG.getAnswerEmotionsSendedByUserIdLast30
                                        .length
                                    )
                                  : 20) /
                                  20) *
                                  100
                              )}
                              % (
                              {
                                dataG.getAnswerEmotionsSendedByUserIdLast30
                                  .length
                              }{" "}
                              de 20)
                            </div>
                            <LinearProgress
                              variant="determinate"
                              className="bg-secondary progress-constrained progress-bar-rounded progress-bar-rounded-alt progress-bar-danger"
                              value={
                                ((dataG.getAnswerEmotionsSendedByUserIdLast30
                                  .length <= 20
                                  ? dataG.getAnswerEmotionsSendedByUserIdLast30
                                      .length
                                  : 20) /
                                  20) *
                                100
                              }
                            />
                            {/* <div className="pt-2">Preguntas Emociones </div> */}
                          </Grid>
                          <Grid item md={4}>
                            <Tooltip
                              classes={{
                                tooltip: "text-center p-3 tooltip-secondary",
                              }}
                              arrow
                              placement="top"
                              title="Número de veces que has enviado información sobre la pregunta de clima en los últimos 30 días"
                            >
                              <img
                                alt="..."
                                className="card-img-top rounded-sm mb-2 mr-2"
                                src={pregunta}
                                style={{ width: 50, height: 50 }}
                              />
                            </Tooltip>
                            <div className="mb-1 font-weight-bold font-size-xl">
                              {Math.round(
                                ((dataG.getAnswersSendedByUserIdLast30.length <=
                                20
                                  ? Math.round(
                                      dataG.getAnswersSendedByUserIdLast30.length
                                    )
                                  : 20) /
                                  20) *
                                  100
                              )}
                              % ({dataG.getAnswersSendedByUserIdLast30.length} de
                              20)
                            </div>
                            <LinearProgress
                              variant="determinate"
                              className="bg-secondary progress-constrained progress-bar-rounded progress-bar-rounded-alt progress-bar-info"
                              value={
                                ((dataG.getAnswersSendedByUserIdLast30.length <=
                                20
                                  ? dataG.getAnswersSendedByUserIdLast30.length
                                  : 20) /
                                  20) *
                                100
                              }
                            />
                            {/* <div className=" pt-2">Preguntas Clima</div> */}
                          </Grid>
                          <Grid item md={4}>
                            <Tooltip
                              classes={{
                                tooltip: "text-center p-3 tooltip-secondary",
                              }}
                              arrow
                              placement="top"
                              title="Número de veces que has enviado un reconocimiento en los últimos 30 días"
                            >
                              <img
                                alt="..."
                                className="card-img-top rounded-sm mb-2 mr-2"
                                src={recognition}
                                style={{ width: 50, height: 50 }}
                              />
                            </Tooltip>
                            <div className="mb-1 font-weight-bold font-size-xl">
                              {Math.round(
                                ((dataG.getRecognitionsSendedByUserIdLast30
                                  .length <= 20
                                  ? Math.round(
                                      dataG.getRecognitionsSendedByUserIdLast30
                                        .length
                                    )
                                  : 20) /
                                  20) *
                                  100
                              )}
                              % (
                              {dataG.getRecognitionsSendedByUserIdLast30.length}{" "}
                              de 20)
                            </div>
                            <LinearProgress
                              variant="determinate"
                              className="bg-secondary progress-constrained progress-bar-rounded progress-bar-rounded-alt progress-bar-warning"
                              value={
                                ((dataG.getRecognitionsSendedByUserIdLast30
                                  .length <= 20
                                  ? dataG.getRecognitionsSendedByUserIdLast30
                                      .length
                                  : 20) /
                                  20) *
                                100
                              }
                            />
                            {/* <div className=" pt-2">Reconocimientos Enviados</div>  */}
                          </Grid>
                        </Grid>
                        <Grid container spacing={6}>
                          <Grid item md={4}>
                            <Tooltip
                              classes={{
                                tooltip: "text-center p-3 tooltip-secondary",
                              }}
                              arrow
                              placement="top"
                              title="Número de veces que has enviado una propuesta de mejora en los últimos 30 días"
                            >
                              <img
                                alt="..."
                                className="card-img-top rounded-sm mb-2 mr-2"
                                src={propuesta}
                                style={{ width: 50, height: 50 }}
                              />
                            </Tooltip>
                            <div className="mb-1 font-weight-bold font-size-xl">
                              {Math.round(
                                ((dataG
                                  .getImprovementProposalsSendedByUserIdLast30
                                  .length <= 20
                                  ? Math.round(
                                      dataG
                                        .getImprovementProposalsSendedByUserIdLast30
                                        .length
                                    )
                                  : 20) /
                                  20) *
                                  100
                              )}
                              % (
                              {
                                dataG.getImprovementProposalsSendedByUserIdLast30
                                  .length
                              }{" "}
                              de 20)
                            </div>
                            <LinearProgress
                              variant="determinate"
                              className="bg-secondary progress-constrained progress-bar-rounded progress-bar-rounded-alt progress-bar-success"
                              value={
                                ((dataG
                                  .getImprovementProposalsSendedByUserIdLast30
                                  .length <= 20
                                  ? dataG
                                      .getImprovementProposalsSendedByUserIdLast30
                                      .length
                                  : 20) /
                                  20) *
                                100
                              }
                            />
                            {/* <div className="pt-2">Propuestas de mejora enviadas </div> */}
                          </Grid>
                          <Grid item md={4}>
                            <Tooltip
                              classes={{
                                tooltip: "text-center p-3 tooltip-secondary",
                              }}
                              arrow
                              placement="top"
                              title="Número de veces que has enviado una felicitación en los últimos 30 días"
                            >
                              <img
                                alt="..."
                                className="card-img-top rounded-sm mb-2 mr-2"
                                src={felicitacion}
                                style={{ width: 50, height: 50 }}
                              />
                            </Tooltip>
                            <div className="mb-1 font-weight-bold font-size-xl">
                              {Math.round(
                                ((dataG.getCongratulationsSendedByUserIdLast30
                                  .length <= 20
                                  ? Math.round(
                                      dataG
                                        .getCongratulationsSendedByUserIdLast30
                                        .length
                                    )
                                  : 20) /
                                  20) *
                                  100
                              )}
                              % (
                              {
                                dataG.getCongratulationsSendedByUserIdLast30
                                  .length
                              }{" "}
                              de 20)
                            </div>
                            <LinearProgress
                              variant="determinate"
                              className="bg-secondary progress-constrained progress-bar-rounded progress-bar-rounded-alt progress-bar-primary"
                              value={
                                ((dataG.getCongratulationsSendedByUserIdLast30
                                  .length <= 20
                                  ? dataG.getCongratulationsSendedByUserIdLast30
                                      .length
                                  : 20) /
                                  20) *
                                100
                              }
                            />
                            {/* <div className=" pt-2">Felicitaciones enviadas</div> */}
                          </Grid>
                          <Grid item md={4}>
                            <Tooltip
                              classes={{
                                tooltip: "text-center p-3 tooltip-secondary",
                              }}
                              arrow
                              placement="top"
                              title="Número de veces que has enviado un agradecimiento en los últimos 30 días"
                            >
                              <img
                                alt="..."
                                className="card-img-top rounded-sm mb-2 mr-2"
                                src={agradecimiento}
                                style={{ width: 50, height: 50 }}
                              />
                            </Tooltip>
                            <div className="mb-1 font-weight-bold font-size-xl">
                              {Math.round(
                                ((dataG.getGratitudesSendedByUserIdLast30
                                  .length <= 20
                                  ? Math.round(
                                      dataG.getGratitudesSendedByUserIdLast30
                                        .length
                                    )
                                  : 20) /
                                  20) *
                                  100
                              )}
                              % ({dataG.getGratitudesSendedByUserIdLast30.length}{" "}
                              de 20)
                            </div>
                            <LinearProgress
                              variant="determinate"
                              className="bg-secondary progress-constrained progress-bar-rounded progress-bar-rounded-alt progress-bar-dark"
                              value={
                                ((dataG.getGratitudesSendedByUserIdLast30
                                  .length <= 20
                                  ? dataG.getGratitudesSendedByUserIdLast30
                                      .length
                                  : 20) /
                                  20) *
                                100
                              }
                            />
                            {/* <div className=" pt-2">Agradecimientos enviados</div>  */}
                          </Grid>
                        </Grid>
                      </div>
                    </Card>
                  </Grid>
                </Grid>
              </div>
              <div
                className={clsx("tab-item-wrapper overflow-visible d-none", {
                  "d-block active": activeTab2 === "2",
                })}
                index={2}
              >
                <Groups user={data.getUserById}/>
              </div>
            </Container>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
}
