import React, { useState,useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { CSVLink } from "react-csv";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Grid,
    Table
} from '@material-ui/core';

export default function StepReview({ employeesErrors, fieldMatched }) {

    console.log(employeesErrors);
    console.log(fieldMatched);
    fieldMatched=[...fieldMatched,{fieldImport:'error',fieldMatched:'Error'}]
    console.log(fieldMatched);

    return (
        <>
            <div className="pt-2">
                <Grid container spacing={12}  className="justify-content-center">
                    <div className="p-3">
                        <div className="px-2 pb-2">
                            <div className="font-weight-bold pb-1 font-size-lg text-capitalize">
                                Usuarios erroneos
                            </div>
                            <div className="text-black-50">
                                Lista de usuarios que tienen error de importación &nbsp;
                                <CSVLink
                                filename="users_errors.csv"
                                data={employeesErrors}
                                >
                                Descargar CSV
                                </CSVLink>
                            </div>
                        </div>
                        <Table className="table table-borderless table-hover text-nowrap mb-0">
                            <thead className="thead-light">
                                <tr>
                                    {fieldMatched.map(field => field.fieldMatch !== null &&
                                        <th className="text-center">{field.fieldMatch}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {employeesErrors &&
                                    employeesErrors.map((employee, index) => {
                                        return (
                                            <tr key={index}>
                                                {fieldMatched.map(field => field.fieldMatch !== null &&
                                                    <td className="text-center font-size-lg">{employee[field.fieldImport]}</td>
                                                )}
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </Table>
                    </div>
                </Grid>
            </div>
        </>
    );
};