import React, { useState, useContext } from "react";
import { UserContext } from "../../../../context/UserContext";
import { useMutation } from "@apollo/react-hooks";

import {
    Card,
    Button,
    Dialog,
    Grid,
    Stepper,
    Step,
    StepLabel
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import '../../../../assets/climind.scss'

import StepUpload from "./importEmployees/StepUpload"
import StepMapping from "./importEmployees/StepMapping"
import StepReview from "./importEmployees/StepReview"
import StepFinish from "./importEmployees/StepFinish"
import { IMPORT_USERS } from "../../../../data/mutations";
import { GET_DEPARTMENTS_GROUPS_AND_USER } from "../../../../data/queries";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

export default function ModalImportEmployees({
    openImportEmployees,
    toggleImportEmployees,
    compId
}) {
    const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
    let companyId = null;
    if (compId) {
        companyId = compId;
    } else {
        companyId = dbuser.getUserByUid.companyId;
    }

    const [importUsers, { data }] = useMutation(IMPORT_USERS);
    const [employeesImport, setEmployeesImport] = useState([]);
    const [employeesErrors, setEmployeesErrors] = useState([]);

    const [activeStep, setActiveStep] = useState(0);
    const [fieldMatched, setFieldMatched] = useState([]);

    const classes = useStyles();
    const steps = getSteps();


    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleImport = async() => {
        console.log(employeesImport);
        console.log(fieldMatched);
        let tmp = await importUsers({
            variables: { 
                input: JSON.stringify(employeesImport), 
                companyId: companyId,
                fields : JSON.stringify(fieldMatched)
            },
            refetchQueries: [
                {
                    query: GET_DEPARTMENTS_GROUPS_AND_USER,
                    variables: { id: companyId },
                },
            ],
        });
        setEmployeesErrors(tmp.data.addUsersFromCsv)
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };


    const handleReset = () => {
        setActiveStep(0);
    };

    function getSteps() {
        return ['1', '2', '3','4'];
    }

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <StepUpload
                    setEmployeesImport={setEmployeesImport}
                    handleNext={handleNext}
                />;
            case 1:
                return <StepMapping
                    employeesImport={employeesImport}
                    fieldMatched={fieldMatched}
                    setFieldMatched={setFieldMatched}
                    handleNext={handleNext}
                />;
            case 2:
                return <StepReview
                    fieldMatched={fieldMatched}
                    employeesImport={employeesImport}
                    setEmployeesImport={setEmployeesImport}
                    handleNext={handleNext}
                />;
            case 3:
                return <StepFinish
                    fieldMatched={fieldMatched}
                    employeesErrors={employeesErrors}
                    handleNext={handleNext}
                />;
            default:
                return <StepUpload
                    setEmployeesImport={setEmployeesImport}
                    handleNext={handleNext}
                />;
                
        }
    }

    return (
        <>
            <Dialog
                open={openImportEmployees}
                onClose={toggleImportEmployees}
                classes={{ paper: 'modal-content rounded' }}
                maxWidth="xl"
                    fullWidth
            >
                <Card className="card-box mb-lg-0">
                    <div className="card-header bg-secondary">
                        <div className="card-header--title">
                            <small>Añadir Usuarios</small>
                            <b>Desde un CSV</b>
                        </div>
                        <Grid item md={5}>
                            <Stepper activeStep={activeStep} alternativeLabel className="stepper-custom">
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel></StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Grid>
                    </div>
                    {/* <div>
                        <div>

                        </div>
                    </div> */}
                    <div>
                        {/* {activeStep === steps.length ? (
                            <div>
                                <Typography className={classes.instructions}>All steps completed</Typography>
                                <Button onClick={handleReset}>Reset</Button>
                            </div>
                        ) : ( */}
                        <div>
                            <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                            <div className="card-footer p-3 bg-white d-flex align-items-center justify-content-between flex-row">
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    className={classes.backButton}
                                >
                                    Atrás
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => activeStep === steps.length - 2 ? handleImport() : handleNext()}
                                    disabled={employeesImport.length === 0}
                                >
                                    {activeStep === steps.length - 1 ? 'Finalizar' : 'Siguiente'}
                                </Button>
                            </div>
                        </div>
                        {/* )} */}
                    </div>
                </Card>

            </Dialog>

        </>
    );
}

function Utf8ArrayToStr(array) {
    var out, i, len, c;
    var char2, char3;

    out = "";
    len = array.length;
    i = 0;
    while(i < len) {
    c = array[i++];
    switch(c >> 4)
    { 
      case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
        // 0xxxxxxx
        out += String.fromCharCode(c);
        break;
      case 12: case 13:
        // 110x xxxx   10xx xxxx
        char2 = array[i++];
        out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
        break;
      case 14:
        // 1110 xxxx  10xx xxxx  10xx xxxx
        char2 = array[i++];
        char3 = array[i++];
        out += String.fromCharCode(((c & 0x0F) << 12) |
                       ((char2 & 0x3F) << 6) |
                       ((char3 & 0x3F) << 0));
        break;
    }
    }

    return out;
}