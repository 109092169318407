import React, {useContext, useCallback, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Button,
  TextField,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Card
} from '@material-ui/core';

import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';

import firebase from 'firebase'
import { UserContext } from '../context/UserContext'
import { withRouter, Redirect } from 'react-router'
import { useQuery, useMutation } from "@apollo/react-hooks";
import { PREVIOUS_STEP } from "../data/mutations";
import { GET_USER_BY_UID } from "../data/queries";
import MuiAlert from '@material-ui/lab/Alert';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function PreviousStep() {
    const { currentUser, dbuser, getUserWithFirebase } = useContext(UserContext);
    const [savePreviousStep, { data, error, loading }] = useMutation(PREVIOUS_STEP);
    const history = useHistory()
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [username, setUsername] = useState("")
    const [check, setCheck] = useState(false)
    const [modalTerms, setModalTerms] = useState(false);
    const toggleTerms = () => setModalTerms(!modalTerms);


    const handleLogout = useCallback(async (event) => {
        firebase
          .auth()
          .signOut()
          .then(async function () {
            // localStorage.removeItem("token");
            // await setReloadUserContext(true)
            // history.push("/");
            history.push("/login");
          })
          .catch(function (error) {
            console.log("no se ha posido cerrar sesión");
          });
      });

    async function onClickButton ()  {
        let dataInput = {
          "id": dbuser.getUserByUid.id,
          "username": username,
          "useTerms": true,
        };
        console.log(dataInput)
        let uid = dbuser.getUserByUid.uid
        try{
            let response = await savePreviousStep({variables:{input:dataInput}, refetchQueries: [{query: GET_USER_BY_UID, variables: {uid,uid}}]});
            console.log(response);
            if(response){
                //window.location.reload()
                history.push("/");
                // setReloadUserContext()
                getUserWithFirebase()
            }
        }catch(e){
            setOpenSnackbar(true)
            console.log(e.message)
        }
        
        
        
      }
        return (
            <>
            <Snackbar
          anchorOrigin={{ vertical:"top", horizontal:"center" }}
          key={"top,center"}
          open={openSnackbar}
          // classes={{ root: "toastr-primary" }}
          autoHideDuration={4000}
          onClose={() => setOpenSnackbar(false)}
          message={"entregado correctamente"}
        >
          <Alert onClose={() => setOpenSnackbar(false)} severity="error" style={{color:"white"}}>
          Este nombre de usuario ya no está disponible
        </Alert>
        </Snackbar>
            <div className="app-wrapper bg-white min-vh-100">
                <div className="app-main min-vh-100">
                <div className="app-content p-0">
                    <div className="app-content--inner d-flex align-items-center">
                    <div className="flex-grow-1 w-100 d-flex align-items-center">
                        <div className="bg-composed-wrapper--content py-5">
                        <Grid item xs={10} md={6} lg={5} xl={4} className="mx-auto">
                            <div className="text-center mb-4">
                                <h2>Crea tu nombre de usuario y acepta los términos de uso</h2>
                                <h6>(Debe de ser distinto a tu email y con al menos 5 caracteres)</h6>
                            </div>
                            
                            <div>
                            <div className="mb-4">
                                <TextField
                                fullWidth
                                variant="outlined"
                                id="textfield-email"
                                label="Nombre de usuario"
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <AlternateEmailIcon />
                                    </InputAdornment>
                                    )
                                }}
                                onChange={(e) => {setUsername(e.target.value)}}
                                />
                            </div>
                            <Checkbox 
                                  onClick={(event) => {
                                    event.stopPropagation()
                                    setCheck(!check)
                                    }}
                                    className="mr-2" 
                                    />
                            
                              <span
                                // href="#/"
                                // onClick={(e) => setModalTerms(true)}
                                className="mr-4"
                                >
                                Acepto los terminos y condiciones
                              </span>
                                <a
                                // href="#/"
                                onClick={(e) => setModalTerms(true)}
                                className="text-first">
                                Términos y condiciones
                                </a>
                           
                            <div className="text-center py-4">
                                <Button className="btn-primary font-weight-bold w-50 my-2"
                                        onClick={onClickButton}
                                        disabled={!check || username.length < 5}
                                >
                                Aceptar y continuar
                                </Button>
                            </div>
                            <div className="text-center">
                                <Button className="btn-second font-weight-bold w-50"
                                        onClick={handleLogout}
                                >
                                Volver al login
                                </Button>
                            </div>

                            </div>
                        </Grid>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <Dialog
        open={modalTerms}
        onClose={toggleTerms}
        maxWidth="lg"
        width="80%"
        classes={{ paper: "modal-content rounded-lg" }}
      >
        <DialogTitle className="bg-white-10">Términos y condiciones</DialogTitle>
        <DialogContent>
          

                
            <div >
            <p>T&eacute;rminos de Uso</p>

<p>Fecha de entrada en vigencia: 11 de septiembre de 2021</p>

<p>&iexcl;Gracias por elegir a Climind Technologies! Nuestros productos incluyen la aplicaci&oacute;n Climind APP, as&iacute; como cualquier otra aplicaci&oacute;n desarrollada o publicada oportunamente por Climind Technologies(los &laquo;Productos&raquo;).</p>

<p>Debes saber que al registrarte o de alg&uacute;n modo usar cualquiera de nuestros Productos, nuestro sitio web o aplicaciones software (el &laquo;Servicio&raquo;), o al acceder a cualquier contenido o material puesto a disposici&oacute;n a trav&eacute;s de los Servicios (el &laquo;Contenido&raquo;) est&aacute;s aceptando cumplir estos t&eacute;rminos (los &laquo;T&eacute;rminos&raquo;) que constituyen el acuerdo vinculante entre Climind Technologies, como propietario de los Productos y proveedor de los Servicios (&laquo;Climind&raquo;, &laquo;nosotros&raquo; o &laquo;nuestro&raquo;), y t&uacute;, como usuario (el &laquo;Usuario&raquo;, &laquo;t&uacute; o &laquo;tu&raquo;).</p>

<p>Reconoces que has le&iacute;do y comprendido estos T&eacute;rminos, aceptas estos T&eacute;rminos y acuerdas regirte por ellos. Si no est&aacute;s de acuerdo (o no puedes cumplir) con estos T&eacute;rminos no podr&aacute;s usar los Servicios.</p>

<p>Si violas estos t&eacute;rminos podremos poner fin al uso que haces de los Servicios, prohibirte el uso futuro de los Productos o llevar a cabo las correspondientes acciones legales.</p>

<p>Podemos actualizar oportunamente estos T&eacute;rminos. Cuando actualicemos estos T&eacute;rminos revisaremos la &laquo;Fecha de Entrada en Vigencia&raquo; anteriormente establecida y publicaremos los nuevos T&eacute;rminos y Condiciones.</p>

<p>Lee estos T&eacute;rminos detenidamente y ante cualquier duda sobre ellos, cont&aacute;ctanos a info@climind.com.</p>

<p>&Iacute;NDICE</p>

<p>Uso de nuestros Servicios. Creaci&oacute;n de Contenido y uso. Privacidad. Renuncias y Limitaciones de Responsabilidad. General. 1.- Uso de nuestros Servicios</p>

<p>Prestar consentimiento a estos T&eacute;rminos y a todas sus modificaciones. Para usar los Servicios debes tener 18 a&ntilde;os o m&aacute;s y consentir a estos T&eacute;rminos, o tener 13 a&ntilde;os o m&aacute;s y contar con el consentimiento de tus padres o tutores a estos T&eacute;rminos. Podemos, ocasionalmente y a nuestra discreci&oacute;n, realizar cambios a estos T&eacute;rminos. Los cambios a estos T&eacute;rminos no ser&aacute;n retroactivos y la versi&oacute;n m&aacute;s actual de los T&eacute;rminos, que siempre podr&aacute; encontrarse en https://climind.com/terminos-de-uso/ regir&aacute; nuestra relaci&oacute;n contigo. Trataremos de notificarte sobre las revisiones importantes, por ejemplo, a trav&eacute;s de notificaciones de servicio o correo electr&oacute;nico al correo asociado con tu cuenta. En algunos casos te notificaremos con anticipaci&oacute;n y el uso que hagas de los Servicios con posterioridad a la realizaci&oacute;n de los cambios constituir&aacute; tu aceptaci&oacute;n a los cambios. Por ende, aseg&uacute;rate de leer todas esas notificaciones detenidamente. Si deseas dejar de seguir usando los Servicios de acuerdo con la nueva versi&oacute;n de los T&eacute;rminos podr&aacute;s ponerle fin a los Servicios poni&eacute;ndote en contacto con nosotros a trav&eacute;s de info@climind.com.</p>

<p>2.- Propiedad Intelectual</p>

<p>Usar nuestros Servicios no te hace propietario de ninguno de nuestros derechos de propiedad intelectual sobre nuestros Servicios, los Productos o el Contenido al que accedas. Estos t&eacute;rminos no te otorgan el derecho a usar ninguna marca o logos usados en nuestros Servicios. No elimines, ocultes ni alteres ninguna notificaci&oacute;n legal mostrada en nuestros Servicios o junto a ellos.</p>

<p>Los contenidos de todo el material disponible en los Servicios son derechos de autor de Climind Technologies, a menos que se indique lo contrario. Todos los derechos est&aacute;n reservados y el contenido no podr&aacute; ser reproducido, descargado, difundido o transferido, de manera alguna y por ning&uacute;n medio, salvo con el previo consentimiento escrito de Climind Technologies, como se indica a continuaci&oacute;n.</p>

<p>Restricciones de Uso</p>

<p>No hagas un uso incorrecto de nuestros Servicios. Por ejemplo, no interfieras con nuestros Servicios ni trates de acceder a ellos usando un m&eacute;todo diferente a la interfaz y a las instrucciones que te otorgamos. Puedes usar nuestros Servicios &uacute;nicamente como lo permite la ley, incluso las leyes y normas sobre exportaci&oacute;n y control de reexportaci&oacute;n.</p>

<p>No podr&aacute;s decompilar, emplear t&eacute;cnicas de &laquo;ingenier&iacute;a inversa&raquo;, desensamblar ni de otra manera intentar derivar un c&oacute;digo fuente de los Productos.</p>

<p>Puedes descargar p&aacute;ginas u otro Contenido para tu uso personal, pero ninguna porci&oacute;n de ese Contenido puede ser de otra manera o posteriormente reproducido, descargado, difundido o transferido, de forma ni en medio alguno, salvo con el previo consentimiento escrito de Climind Technologies y se&ntilde;al&aacute;ndolo expresamente.</p>

<p>Aceptas no (i) realizar un enlace profundo al sitio por cualquier motivo, a menos que te autoricemos a hacerlo; (ii) indagar, escanear o testear la vulnerabilidad de cualquier sistema o red, ni violar o eludir cualquier seguridad o medida de autenticaci&oacute;n; (iii) falsificar ninguna cabecera TCP/IP ni ninguna parte de la informaci&oacute;n de la cabecera en ning&uacute;n correo electr&oacute;nico o publicaci&oacute;n, o de cualquier manera usar los Servicios para enviar informaci&oacute;n de identificaci&oacute;n de la fuente alterada, enga&ntilde;osa o falsa; ni (iv) interferir, interrumpir, (o intentar hacerlo), el acceso de cualquier usuario, host o red, incluso enviar virus, sobrecargar, inundar, espamear o bombardear a los Servicios con mensajes.</p>

<p>No puedes modificar los Servicios ni crear ninguna obra derivada de los Servicios ni de los Productos o su documentaci&oacute;n complementaria. Las obras derivadas incluyen, pero no se limitan a traducciones. No puedes alterar ning&uacute;n archivo ni bibliotecas en ninguna parte de los Productos. No puedes reproducir una porci&oacute;n de la base de datos ni crear cuadros o informes relacionados con la porci&oacute;n de la base de datos..</p>

<p>Restricciones de Transferencia</p>

<p>No puedes ceder tus derechos ni obligaciones adquiridas en virtud de este documento, ni redistribuir, gravar, vender, alquilar, arrendar, sublicenciar o de otra manera transferir tus derechos al uso de los Servicios.</p>

<p>Cuenta de Miembro, Contrase&ntilde;a y Seguridad</p>

<p>Necesitar&aacute;s una cuenta para usar y acceder a nuestros Servicios. T&uacute; puedes crear tu propia cuenta, o tu cuenta podr&aacute; serte asignada por un administrador, tal como tu empleador o instituci&oacute;n educativa. Si est&aacute;s usando una cuenta que te fue asignada por un administrador, podr&aacute;n aplicarse otros t&eacute;rminos o unos adicionales, y tu administrador podr&aacute; acceder o desactivar tu cuenta.</p>

<p>T&uacute; eres el responsable de proteger tu cuenta as&iacute; que usa una contrase&ntilde;a fuerte y limita su uso a esta cuenta. No podemos y no nos haremos responsables de ninguna p&eacute;rdida o da&ntilde;o que sea consecuencia de tu incumplimiento de lo anterior. Eres el exclusivo responsable de todas las actividades que tengan lugar en relaci&oacute;n con tu contrase&ntilde;a o cuenta.</p>

<p>2.- Creaci&oacute;n de Contenido y uso</p>

<p>Responsabilidad por uso</p>

<p>Eres responsable de lo que publicas, esto significa que t&uacute;, y no Climind Technologies, eres completamente responsable por todo el Contenido que subes, publicas, env&iacute;as por correo electr&oacute;nico, transmites o, de otra manera, pones a disposici&oacute;n a trav&eacute;s de los servicios.</p>

<p>T&uacute; comprendes que, ya sea que el Contenido se publique o no, Climind Technologies no garantiza confidencialidad con respecto al Contenido.</p>

<p>Adem&aacute;s, entiendes que todo el Contenido, publicado de manera p&uacute;blica o transmitido en privado, es de la exclusiva responsabilidad de quien cre&oacute; ese Contenido.</p>

<p>Climind Technologies no puede monitorear o Controlar el contenido publicado a trav&eacute;s de los Servicios y, por ende, no puede hacerse responsable por dicho Contenido o por su veracidad. Todo uso o apoyo a cualquier Contenido publicado a trav&eacute;s de los Servicios o que t&uacute; obtengas a trav&eacute;s de los Servicios es a tu propio riesgo.</p>

<p>Climind Technologies pone a disposici&oacute;n los Servicios y la informaci&oacute;n y servicios aqu&iacute; incluidos &laquo;como est&aacute;n&raquo; (as is) sin ninguna garant&iacute;a, expresa, impl&iacute;cita u otra, acerca de la veracidad, rendimiento, completitud, eficacia u oportunidad de dicha informaci&oacute;n.</p>

<p>Creaci&oacute;n de contenido</p>

<p>Como usuario de los Servicios, t&uacute; puedes presentar Contenido. Aceptas que no publicar&aacute;s ni subir&aacute;s Contenido alguno que contenga material ilegal que poseas en el pa&iacute;s en el que resides, o que ser&iacute;a ilegal que Climind Technologies usase o poseyera en relaci&oacute;n con la prestaci&oacute;n de los Servicios.</p>

<p>Aceptas que ser&aacute;s el &uacute;nico responsable (frente a Climind Technologies y otros) por toda la actividad que ocurra en relaci&oacute;n con tu cuenta y por todo el Contenido que publiques a trav&eacute;s de los Servicios.</p>

<p>Aceptas no usar los Servicios para generar Contenido que:</p>

<p>sea ilegal, da&ntilde;ino, amenazante, abusivo, acosador, malicioso, difamatorio, vulgar, obsceno, calumnioso, invasivo sobre la privacidad ajena, cargado de odio, u objetable racial o &eacute;ticamente, o de cualquier otra manera; suplante la identidad de cualquier persona o entidad, o declare falsamente o de otra manera distorsione tu afiliaci&oacute;n con una persona o entidad; contenga material de derechos de autor de terceros o material sujeto a otros derechos de propiedad de terceros (incluidos derechos de privacidad o publicidad), a menos que cuentes con una licencia o permiso formal del propietario leg&iacute;timo, o de otra manera est&eacute;s legalmente facultado para publicar el material en cuesti&oacute;n; no tengas derecho de poner a disposici&oacute;n en virtud de cualquier ley o en relaci&oacute;n con relaciones contractuales o fiduciarias (tal como informaci&oacute;n privilegiada, de propiedad y confidencial conocida o divulgada como parte de relaciones laborales o en virtud de acuerdos de no divulgaci&oacute;n); viole cualquier patente, marca registrada, secreto comercial, derecho de autor u otro derecho de propiedad de cualquier parte; transmita o de otra manera disponibilice cualquier material que contenga software de virus o cualquier otro c&oacute;digo computacional, archivos o programas dise&ntilde;ados para interrumpir, destruir o limitar la funcionalidad de cualquier software o hardware computacional, o equipo de telecomunicaciones; o &laquo;acose&raquo; o de otra manera hostigue a otros. Aceptas no presentar informaci&oacute;n o datos que requieran protecci&oacute;n o controles de difusi&oacute;n de acuerdo con leyes o regulaci&oacute;n aplicable (&laquo;Informaci&oacute;n Prohibida&raquo;). Climind Technologies no ser&aacute; responsable de la Informaci&oacute;n Prohibida, sin perjuicio de lo estipulado en contrario en este documento.</p>

<p>Nos reservamos el derecho de quitar el Contenido que viole estos T&eacute;rminos, incluidas las violaciones a derechos de autor o marcas registradas, suplantaciones de identidad, conductas ilegales u hostigamiento.</p>

<p>Privacidad</p>

<p>Nuestra Pol&iacute;tica de Privacidad y Seguridad https://climind.com/politica-privacidad/ describe c&oacute;mo manejamos la informaci&oacute;n (incluidos tus datos personales) que t&uacute; nos provees al usar nuestros Servicios. Entiendes que al usar nuestros Servicios prestas tu consentimiento de forma libre, previa, expresa e informada a la recopilaci&oacute;n y uso (como lo establece la Pol&iacute;tica de Privacidad) de esta informaci&oacute;n tal como lo describe la Pol&iacute;tica de Privacidad, la cual ser&aacute; procesada con la finalidad de acceder a y utilizar los servicios.</p>

<p>Renuncias y Limitaciones de Responsabilidad</p>

<p>Exclusi&oacute;n de garant&iacute;as y Limitaciones de responsabilidad</p>

<p>ALGUNAS JURISDICCIONES OTORGAN CIERTAS GARANT&Iacute;AS, TALES COMO GARANT&Iacute;AS IMPL&Iacute;CITAS DE COMERCIALIZACI&Oacute;N, IDONEIDAD PARA UN PROP&Oacute;SITO PARTICULAR Y AUSENCIA DE VIOLACI&Oacute;N. EN LA MEDIDA PERMITIDA POR LA LEY, NOSOTROS EXCLUIMOS TODAS LAS GARANT&Iacute;AS.</p>

<p>Climind Technologies no garantiza que la operaci&oacute;n de los Servicios ser&aacute; segura, estar&aacute; libre de errores o de interrupciones. T&uacute; debes determinar si los servicios cumplen suficientemente con tus requisitos de seguridad y ausencia de interrupci&oacute;n. Eres el &uacute;nico responsable por cualquier p&eacute;rdida incurrida debido a la falla de los servicios para cumplir con tus requisitos. Bajo ninguna circunstancia Climind Technologies ser&aacute; responsable ni tendr&aacute; obligaciones con respecto a las p&eacute;rdidas de datos en cualquier computadora o dispositivo de almacenamiento de informaci&oacute;n.</p>

<p>Limitaciones de responsabilidad</p>

<p>Al usar los Servicios aceptas que la responsabilidad de Climind Technologies, sus controladoras, subsidiarias, partes relacionadas, ejecutivos, directores, empleados, intermediarios representantes, socios y licenciatarios se limita en la mayor medida posible en tu pa&iacute;s de residencia.</p>

<p>CUANDO LA LEY LO PERMITA, CLIMIND TECHNOLOGIES NO SER&Aacute; RESPONSABLE DEL LUCRO CESANTE, P&Eacute;RDIDAS DE GANANCIAS, DATOS O FINANCIERAS, NI DE DA&Ntilde;OS INDIRECTOS, ESPEC&Iacute;FICOS, DIRECTOS, EJEMPLARES O PUNITIVOS.</p>

<p>CLIMIND TECHNOLOGIES NO SER&Aacute; RESPONSABLE EN NING&Uacute;N CASO DE NINGUNA P&Eacute;RDIDA O DA&Ntilde;O QUE NO PUEDA PREVERSE DE FORMA RAZONABLE.</p>

<p>General</p>

<p>Derechos reservados</p>

<p>Climind Technologies se reserva el derecho de acceder, preservar y divulgar informaci&oacute;n de tu cuenta y contenido en caso de que lo deba hacer por ley o si de buena fe cree que la preservaci&oacute;n a dicho acceso o la divulgaci&oacute;n es razonablemente necesaria para: (i) cumplir con un proceso legal; (ii) hacer cumplir estos T&eacute;rminos; (iii) responder a reclamos sobre que cualquier contenido viola los derechos de terceros; o (iv) proteger los derechos, la propiedad o la seguridad personal de Climind Technologies, sus usuarios y el p&uacute;blico.</p>

<p>Adem&aacute;s, reconoces, aceptas y acuerdas que todos los usuarios pueden quitar contenido del que son receptores o emisores sin necesidad de justificar su decisi&oacute;n.</p>

<p>Nulidad parcial</p>

<p>En caso de que cualquiera de estos T&eacute;rminos sea considerado inv&aacute;lido o no pasible de ejecuci&oacute;n, los T&eacute;rminos restantes continuar&aacute;n teniendo vigencia. En la medida en que cualquier restricci&oacute;n expresa o impl&iacute;cita no sea permitida por la legislaci&oacute;n aplicable, esas restricciones expresas o impl&iacute;citas seguir&aacute;n vigentes en la mayor medida permitida por esa legislaci&oacute;n aplicable.</p>

<p>Extinci&oacute;n de estos T&eacute;rminos</p>

<p>Puedes extinguir tu acuerdo legal con Climind Technologies en cualquier momento al desactivar tus cuentas y descontinuar el uso de los Servicios.</p>

<p>Podemos suspender o eliminar tu cuenta, o dejar de prestarte todos o parte de los Servicios en cualquier momento y sin raz&oacute;n alguna, incluso si la prestaci&oacute;n de los Servicios que te hacemos ya no resulta comercialmente viable o si razonablemente consideramos que has violado estos T&eacute;rminos, entre otras razones.</p>
                </div>

          
        </DialogContent>
        <DialogActions className="p-4 bg-white-10 justify-content-center">
          <Button
            className="btn-neutral-primary"
            onClick={() => toggleTerms()}
          >
            Atras
          </Button>{" "}
          
        </DialogActions>
      </Dialog>
            </>
        );
    }
    

