import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Card, CardContent, Tooltip, Button } from "@material-ui/core";

import Traffic from "../../../../../../climind-components/TrafficLight/TrafficLight"

export default function LivePreviewExample({
  TotalActiveClimaUsersPercent,
  TotalRecurringUsers,
  RepresentivityClima,
  TotalActiveClimaUsers,
  TotalActivePreviousPulseUsersPercent,
  TotalActivePreviousUsers,
  TotalActiveClimaUsersPrevious,
  TotalRecurringUsersPrevious,
  RepresentivityClimaPrevious,
}) {
  let difActiveUsers = TotalActiveClimaUsersPercent - (isFinite(TotalActivePreviousPulseUsersPercent)?TotalActivePreviousPulseUsersPercent:0)
  let difRecurringUs = TotalRecurringUsers - (isFinite(TotalRecurringUsersPrevious)?TotalRecurringUsersPrevious:0)
  let difRepresent = RepresentivityClima - RepresentivityClimaPrevious

  return (
    <>
      <div className="mb-spacing-6">
        <Grid container spacing={6}>

          <Grid item xl={4} md={6}>
            <Card className="card-box border-0 shadow-success-sm p-4">
              <div className="d-flex align-items-center">
                <div className="d-40 btn-icon rounded-circle bg-success text-white text-center font-size-lg mr-3">
                  <FontAwesomeIcon icon={['fas', 'users']} />
                </div>
                <div className="text-black-50">Usuarios activos en clima</div>
                <div className="card-tr-actions">
                  <Tooltip
                    classes={{
                      tooltip: "text-center p-3 tooltip-secondary",
                    }}
                    arrow
                    placement="top"
                    title="Número de usuarios activos en clima en la plataforma en el periodo seleccionado."
                  >
                    <a
                      href="#/"
                      onClick={(e) => e.preventDefault()}
                      className="text-first font-weight-bold"
                    >
                      <div
                        className=" avatar-icon rounded-circle mr-2"
                        style={{ width: 26, height: 26 }}
                      >
                        <img
                          alt="..."
                          src={require("../../../../../../assets/images/climind/info.png")}
                        />
                      </div>
                    </a>
                  </Tooltip>
                </div>
              </div>
              <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                {(isFinite(difActiveUsers) && difActiveUsers > 0) ? (<FontAwesomeIcon
                  icon={['fas', 'arrow-up']}
                  className="font-size-sm text-success mr-2"
                />) : (isFinite(difActiveUsers) && difActiveUsers < 0) ? (<FontAwesomeIcon
                  icon={['fas', 'arrow-down']}
                  className="font-size-sm text-warning mr-2"
                />) : (isFinite(difActiveUsers)) ? (<FontAwesomeIcon
                  icon={['far', 'dot-circle']}
                  className="font-size-sm text-first mr-2"
                />) : <></>}
                <Tooltip
                  classes={{
                    tooltip: "text-center p-3 tooltip-secondary",
                  }}
                  arrow
                  placement="bottom"
                  title={isFinite((TotalActiveClimaUsersPercent * 100).toFixed(2)) ? (TotalActiveClimaUsersPercent * 100).toFixed(2)+"% ("+TotalActiveClimaUsers+" Usuarios)" : 0 +"% (0 Usuarios)"}
                >
                <div>{(TotalActiveClimaUsersPercent * 100 > 30) ? (<Traffic color="green" />) : (TotalActiveClimaUsersPercent * 100 < 15) ? (<Traffic color="red" />) : isFinite(TotalActiveClimaUsersPercent) ? (<Traffic color="yellow" />) : <Traffic color="red" />}</div>
                </Tooltip>
              </div>
              <div className="text-black-50 text-center pt-3">
                {isFinite(difActiveUsers ) ?
                  (<><b>{(difActiveUsers  * 100).toFixed(2) + '%'}</b> desde el periodo pasado</>) :
                  (<></>)
                }
              </div>
            </Card>
          </Grid>

          <Grid item xl={4} md={6}>
            <Card className="card-box border-0 shadow-primary-sm p-4">
              <div className="d-flex align-items-center">
                <div className="d-40 btn-icon rounded-circle bg-primary text-white text-center font-size-lg mr-3">
                  <FontAwesomeIcon icon={['fas', 'child']} />
                </div>
                <div className="text-black-50">Nivel de recurrencia</div>
                <div className="card-tr-actions">
                  <Tooltip
                    classes={{
                      tooltip: "text-center p-3 tooltip-secondary",
                    }}
                    arrow
                    placement="top"
                    title="Nivel de recurrencia. Porcentaje de usuarios activos recurrentes promedio en clima en la plataforma en el periodo seleccionado. VERDE (+ de 30% de usuarios recurrentes en el periodo), AMARILLO (entre 30 y 15 % de usuarios recurrentes en el perirodo) y ROJO (Menos de 15%) ."
                  >
                    <a
                      href="#/"
                      onClick={(e) => e.preventDefault()}
                      className="text-first font-weight-bold"
                    >
                      <div
                        className=" avatar-icon rounded-circle mr-2"
                        style={{ width: 26, height: 26 }}
                      >
                        <img
                          alt="..."
                          src={require("../../../../../../assets/images/climind/info.png")}
                        />
                      </div>
                    </a>
                  </Tooltip>
                </div>
              </div>
              <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                {(isFinite(difRecurringUs) && difRecurringUs > 0) ? (<FontAwesomeIcon
                  icon={['fas', 'arrow-up']}
                  className="font-size-sm text-success mr-2"
                />) : (isFinite(difRecurringUs) && difRecurringUs < 0) ? (<FontAwesomeIcon
                  icon={['fas', 'arrow-down']}
                  className="font-size-sm text-warning mr-2"
                />) : (isFinite(difRecurringUs)) ? (<FontAwesomeIcon
                  icon={['far', 'dot-circle']}
                  className="font-size-sm text-first mr-2"
                />) : <></>}
                <Tooltip
                  classes={{
                    tooltip: "text-center p-3 tooltip-secondary",
                  }}
                  arrow
                  placement="bottom"
                  title={isFinite((TotalRecurringUsers * 100).toFixed(2)) ? (TotalRecurringUsers * 100).toFixed(2)+"%" : 0 +"%"}
                >
                <div>{(TotalRecurringUsers * 100 > 30) ? (<Traffic color="green" />) : (TotalRecurringUsers * 100 < 15) ? (<Traffic color="red" />) : isFinite(TotalRecurringUsers) ? (<Traffic color="yellow" />) : <Traffic color="red" />}</div>
                </Tooltip>
              </div>
              <div className="text-black-50 text-center pt-3">
                {isFinite(difRecurringUs ) ?
                  (<><b>{(difRecurringUs  * 100).toFixed(2) + '%'}</b> desde el periodo pasado</>) :
                  (<></>)
                }
              </div>      
            </Card>
          </Grid>
          <Grid item xl={4} md={6}>
            <Card className="card-box border-0 shadow-primary-sm p-4">
              <div className="d-flex align-items-center">
                <div className="d-40 btn-icon rounded-circle bg-primary text-white text-center font-size-lg mr-3">
                  <FontAwesomeIcon icon={['fas', 'user-check']} />
                </div>
                <div className="text-black-50">Nivel de representatividad</div>
                <div className="card-tr-actions">
                  <Tooltip
                    classes={{
                      tooltip: "text-center p-3 tooltip-secondary",
                    }}
                    arrow
                    placement="top"
                    title="Nivel de representatividad. Porcentaje de usuarios activos en clima sobre el total de usuarios registrados en la plataforma. VERDE (+ de 30% de usuarios recurrentes en el periodo), AMARILLO (entre 30 y 15 % de usuarios recurrentes en el perirodo) y ROJO (Menos de 15%) ."
                  >
                    <a
                      href="#/"
                      onClick={(e) => e.preventDefault()}
                      className="text-first font-weight-bold"
                    >
                      <div
                        className=" avatar-icon rounded-circle mr-2"
                        style={{ width: 26, height: 26 }}
                      >
                        <img
                          alt="..."
                          src={require("../../../../../../assets/images/climind/info.png")}
                        />
                      </div>
                    </a>
                  </Tooltip>
                </div>
              </div>
              <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                {(isFinite(difRepresent) && difRepresent > 0) ? (<FontAwesomeIcon
                  icon={['fas', 'arrow-up']}
                  className="font-size-sm text-success mr-2"
                />) : (isFinite(difRepresent) && difRepresent < 0) ? (<FontAwesomeIcon
                  icon={['fas', 'arrow-down']}
                  className="font-size-sm text-warning mr-2"
                />) : (isFinite(difRepresent)) ? (<FontAwesomeIcon
                  icon={['far', 'dot-circle']}
                  className="font-size-sm text-first mr-2"
                />) : <></>}
                <Tooltip
                  classes={{
                    tooltip: "text-center p-3 tooltip-secondary",
                  }}
                  arrow
                  placement="bottom"
                  title={isFinite((RepresentivityClima * 100).toFixed(2)) ? (RepresentivityClima * 100).toFixed(2)+"%" : 0 +"%"}
                >
                <div>{(RepresentivityClima * 100 > 30) ? (<Traffic color="green" />) : (RepresentivityClima * 100 < 15) ? (<Traffic color="red" />) : isFinite(RepresentivityClima) ? (<Traffic color="yellow" />) : <Traffic color="red" />}</div>
                </Tooltip>
              </div>
              <div className="text-black-50 text-center pt-3">
                {isFinite(difRepresent ) ?
                  (<><b>{(difRepresent  * 100).toFixed(2) + '%'}</b> desde el periodo pasado</>) :
                  (<></>)
                }
              </div>  
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
