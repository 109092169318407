import React, { useEffect, useState, useCallback, useContext } from "react";
// import ModalSelectUser from "./ModalSelectUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import defaultAvatar from "../../../assets/images/climind/default_avatar.png";
import {
  Dialog,
  Button,
  Card,
  TextField,
  Menu,
  Snackbar,
  Switch,
  Grid,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Input,
  MenuItem,
  Select,
  Chip
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { UserContext } from "../../context/UserContext";
import { GET_USER_BY_UID, GETS_FOR_GENERAL } from "data/queries";
import { NEW_CHALLENGE, EDIT_CHALLENGE } from "data/mutations";
import InputEmoji from "react-input-emoji";
import challenge from "../../assets/images/climind/target.png";
import UploadImage from "./UploadImage";
import MuiAlert from "@material-ui/lab/Alert";
import { GET_DEPARTMENTS, GET_GROUPS, GET_CHALLENGES_BY_COMPANYID } from "data/queries";
import ModalSelectCompetence from "./ModalSelectCompetence";
import defaultAvatar from "../../assets/images/climind/default_avatar.png";
import SnackBarMessage from "../../utils/SnackBarMessage";
import firebase from 'firebase'

var moment = require("moment-timezone");
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function valuetext(value) {
  return <span>{{ value }}°C</span>;
}

export default function ModalNewChallenge({
  modalNewChallenge,
  toggleNewChallenge,
  refetchChallenges
}) {
  //   const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  //   const [userSelected, setUserSelected] = useState(null);
  //   const toggleUser = () => setModalUser(!modalUser);
  //   const [modalUser, setModalUser] = useState(false);
  //   const [commentary, setCommentary] = useState("");
  //   const [openSnackbar, setOpenSnackbar] = useState(false);

  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  const [newChallenge, { data: dataChallenge, error: errorChallenge, loading: loadingChallenge }] = useMutation(NEW_CHALLENGE);
  const [editChallenge, { data: dataEditChallenge, error: errorEditChallenge, loading: loadingEditChallenge }] = useMutation(EDIT_CHALLENGE);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [criteria, setCriteria] = useState("");
  const [coins, setCoins] = useState(0);
  const [checkAllCompany, setCheckAllCompany] = useState(true);
  const [checkDepartmentsGroups, setCheckDepartmentsGroups] = useState(false);
  const [initDate, setInitDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [crop, setCrop] = useState(null);
  const [picture, setPicture] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [groups, setGroups] = useState([]);
  const [competences, setCompetences] = useState([]);
  const [competencesIds, setCompetencesIds] = useState([]);
  const [modalCompetence, setModalCompetence] = useState(false);
  const toggleCompetence = () => setModalCompetence(!modalCompetence);
  const [messageAlert, setMessageAlert] = React.useState('');
  const [severity, setSeverity] = React.useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [error, setError] = useState(false);

  const [optionsDepartment, setOptionsDepartment] = useState([]);
  const [optionsGroup, setOptionsGroup] = useState([]);

  const {
    loading: loadingDepartments,
    error: errorDepartments,
    data: dataDepartments,
    networkStatus: netDepartments,
  } = useQuery(GET_DEPARTMENTS, {
    variables: { id: dbuser.getUserByUid.companyId },
    skip: !dbuser.getUserByUid.companyId,
    notifyOnNetworkStatusChange: true,
  });
  const {
    loading: loadingGroups,
    error: errorGroups,
    data: dataGroups,
    networkStatus: netGroups,
  } = useQuery(GET_GROUPS, {
    variables: { id: dbuser.getUserByUid.companyId },
    skip: !dbuser.getUserByUid.companyId,
    notifyOnNetworkStatusChange: true,
  });

  const handleCancel = useCallback((e) => {
    setName("")
    setDescription("");
    setCriteria("");
    setCoins(0);
    setCheckAllCompany(true)
    setCheckDepartmentsGroups(false);
    setInitDate(null);
    setEndDate(null);
    setCrop(null);
    setPicture(null);
    setDepartments([]);
    setGroups([]);
    setCompetences([]);
    setCompetencesIds([]);
  });
  const handleOk = (e) => {
    setPicture(crop);
  };
  const handleOkTest = async (e) => {
    // console.log(name)
    // console.log(description)
    // console.log(criteria)
    // console.log(coins)
    // console.log(checkAllCompany)
    // console.log(departments)
    // console.log(groups)
    // console.log(competences)
    // console.log(initDate)
    // console.log(endDate)
    // console.log(crop)
    if (!name || name === "") {
      setSeverity("error")
      setMessageAlert("Debes de agregar el campo del nombre")
      setOpenSnackbar(true, () => {
        setTimeout(function () {
          setOpenSnackbar(false);
        }, 5000);
      });
    }
    else if (!description || description === "") {
      setSeverity("error")
      setMessageAlert("Debes de agregar el campo de la descripción")
      setOpenSnackbar(true, () => {
        setTimeout(function () {
          setOpenSnackbar(false);
        }, 5000);
      });
    }
    else if (!criteria || criteria === "") {
      setSeverity("error")
      setMessageAlert("Debes de agregar el campo del criterio")
      setOpenSnackbar(true, () => {
        setTimeout(function () {
          setOpenSnackbar(false);
        }, 5000);
      });
    }
    else if (!coins || coins === "") {
      setSeverity("error")
      setMessageAlert("Debes de agregar el campo de lo coins")
      setOpenSnackbar(true, () => {
        setTimeout(function () {
          setOpenSnackbar(false);
        }, 5000);
      });
    }
    else if (!checkAllCompany && groups.length <= 0 && departments.length <= 0) {
      setSeverity("error")
      setMessageAlert("Debes de seleccionar algun grupo o departamento")
      setOpenSnackbar(true, () => {
        setTimeout(function () {
          setOpenSnackbar(false);
        }, 5000);
      });
    }
    else if (competences.length <= 0) {
      setSeverity("error")
      setMessageAlert("Debes de seleccionar alguna competencia")
      setOpenSnackbar(true, () => {
        setTimeout(function () {
          setOpenSnackbar(false);
        }, 5000);
      });
    }
    else if (!initDate) {
      setSeverity("error")
      setMessageAlert("Debes de agregar el campo de fecha de inicio")
      setOpenSnackbar(true, () => {
        setTimeout(function () {
          setOpenSnackbar(false);
        }, 5000);
      });
    }
    else if (!endDate) {
      setSeverity("error")
      setMessageAlert("Debes de agregar el campo de fecha de fin")
      setOpenSnackbar(true, () => {
        setTimeout(function () {
          setOpenSnackbar(false);
        }, 5000);
      });
    }
    else if (moment(initDate).isSameOrBefore(moment())) {
      setSeverity("error")
      setMessageAlert("la fecha de inicio debe de ser posterior al dia de hoy")
      setOpenSnackbar(true, () => {
        setTimeout(function () {
          setOpenSnackbar(false);
        }, 5000);
      });
    }
    else if (moment(endDate).isSameOrBefore(moment()) || moment(endDate).isSameOrBefore(initDate)) {
      setSeverity("error")
      setMessageAlert("la fecha de fin debe de ser posterior a la de inicio del reto")
      setOpenSnackbar(true, () => {
        setTimeout(function () {
          setOpenSnackbar(false);
        }, 5000);
      });
    } else {
      competences.forEach(element => {
        competencesIds.push(element.id)
      });
      let dataInput = {
        userId: dbuser.getUserByUid.id,
        companyId: dbuser.getUserByUid.companyId,
        name: name,
        description: description,
        criteria: criteria,
        forAllCompany: checkAllCompany,
        departments: departments,
        groups: groups,
        competences: competencesIds,
        reward: parseInt(coins),
        initDate: initDate,
        endDate: endDate,
        urlImage: null,
      };
      console.log(dataInput);
      let response = await newChallenge({
        variables: { userId: dbuser.getUserByUid.id, input: dataInput }
      })
      if (response.data) {
        try {
          const storageRef = firebase
            .storage()
            .ref(`/iconsChallenges/${response.data.newChallenge.id}`);
          const pictureUploaded = storageRef
            .putString(crop, "data_url")
            .then(async function (snapshot) {
              try {
                await storageRef.getDownloadURL().then(async (url) => {
                  let dataEditInput = {
                    urlImage: url,
                  };
                  const companyId = dbuser.getUserByUid.companyId
                  let responseEdit = await editChallenge({
                    variables: {
                      userId: dbuser.getUserByUid.id,
                      challengeId: response.data.newChallenge.id,
                      input: dataEditInput,
                    },
                    refetchQueries: [
                      { query: GET_CHALLENGES_BY_COMPANYID, variables: { companyId: companyId } }]
                  });
                });

              } catch (e) {
                console.log(e);
              }
            });
        } catch (e) {
          console.log(e);
        }
        setSeverity("success")
        setMessageAlert("Reto creado correctamente")
        setOpenSnackbar(true, () => {
          setTimeout(function () {
            setOpenSnackbar(false);
          }, 5000);
        });
        setName("")
        setDescription("");
        setCriteria("");
        setCoins(0);
        setCheckAllCompany(true)
        setCheckDepartmentsGroups(false);
        setInitDate(null);
        setEndDate(null);
        setCrop(null);
        setPicture(null);
        setDepartments([]);
        setGroups([]);
        setCompetences([]);
        setCompetencesIds([]);
        toggleNewChallenge();
        refetchChallenges()
      } else {
        console.log(response)
        setSeverity("error")
        setMessageAlert("Error al crear el reto")
        setOpenSnackbar(true, () => {
          setTimeout(function () {
            setOpenSnackbar(false);
          }, 5000);
        });
        setName("")
        setDescription("");
        setCriteria("");
        setCoins(0);
        setCheckAllCompany(true)
        setCheckDepartmentsGroups(false);
        setInitDate(null);
        setEndDate(null);
        setCrop(null);
        setPicture(null);
        setDepartments([]);
        setGroups([]);
        setCompetences([]);
        setCompetencesIds([]);
        toggleNewChallenge();
      }
    }

  };
  const handleBack = async (e) => {
    setName("")
    setDescription("");
    setCriteria("");
    setCoins(0);
    setCheckAllCompany(true)
    setCheckDepartmentsGroups(false);
    setInitDate(null);
    setEndDate(null);
    setCrop(null);
    setPicture(null);
    setDepartments([]);
    setGroups([]);
    setCompetences([]);
    setCompetencesIds([]);
    toggleNewChallenge();
  };

  const onChangeCoins = (e) => {
    const re = /^[0-9\b]+$/;

    if (e.target.value === '' || re.test(e.target.value)) {
      setCoins(e.target.value)
    }
  }

  //   const handleOk = async (e) => {
  //     let dataInput = {
  //       companyId: dbuser.getUserByUid.companyId,
  //       commentary: commentary,
  //       sender: dbuser.getUserByUid.id,
  //       receiver: userSelected.id,
  //     };

  //       let uid = dbuser.getUserByUid.uid;
  //       let id = dbuser.getUserByUid.id;
  //       let response = await saveCongratulation({
  //         variables: { input: dataInput },
  //         refetchQueries: [{ query: GET_USER_BY_UID, variables: { uid, uid } },{ query: GETS_FOR_GENERAL, variables: { id, id } }],
  //       });
  //       console.log("mutation response:" + response);
  //       setCommentary("");
  //       setUserSelected(null)
  //       setOpenSnackbar(true, () => {
  //         setTimeout(function () {
  //           setOpenSnackbar(false);
  //         }, 5000); //5 Second delay
  //       });
  //       setReloadUserContext()
  //       refetch()
  //       toggleCongratulation();

  //   };

  useEffect(() => {
    let userId = dbuser.getUserByUid.id;
    let isManager = dbuser.getIfIsManager;
    let role = dbuser.getUserByUid.role;
    if (dataDepartments && dataGroups) {
      if (role === 'admin') {

        let optionsDepartmentTmp = dataDepartments.getDepartmentsByCompanyId
        let optionsGroupTmp = dataGroups.getGroupsByCompanyId
        console.log(optionsDepartmentTmp);

        // optionsDepartmentTmp = optionsDepartmentTmp.filter(department => department.dataUsers.length >= 6)
        // optionsGroupTmp = optionsGroupTmp.filter(group => group.dataUsers.length >= 6)

        setOptionsDepartment(optionsDepartmentTmp)
        setOptionsGroup(optionsGroupTmp)
      } else {
        if (isManager) {
          let optionsDepartmentTmp = dataDepartments.getDepartmentsByCompanyId
          let optionsGroupTmp = dataGroups.getGroupsByCompanyId

          // optionsDepartmentTmp = optionsDepartmentTmp.filter(department => department.dataManager.length > 0 && department.dataManager[0]._id === userId && department.dataUsers.length >= 6)
          // optionsGroupTmp = optionsGroupTmp.filter(group => group.dataManager.length > 0 && group.dataManager[0]._id === userId && group.dataUsers.length >= 6)

          optionsDepartmentTmp = optionsDepartmentTmp.filter(department => department.dataManager.length > 0 && department.dataManager[0]._id === userId )
          optionsGroupTmp = optionsGroupTmp.filter(group => group.dataManager.length > 0 && group.dataManager[0]._id === userId)

          let arrayDepartments = optionsDepartmentTmp.map(({ id }) => id)
          let arrayGroups = optionsGroupTmp.map(({ id }) => id)

          setOptionsDepartment(optionsDepartmentTmp)
          setOptionsGroup(optionsGroupTmp)

        }
      }
    }
  }, [dataDepartments, dataGroups])

  if (dataDepartments && dataGroups) {
    // let optionsDepartment = dataDepartments.getDepartmentsByCompanyId
    // let optionsGroup = dataGroups.getGroupsByCompanyId
    return (
      <>
        {/* <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          key={"top,center"}
          open={openSnackbar}
          // classes={{ root: "toastr-primary" }}
          autoHideDuration={4000}
          onClose={() => setOpenSnackbar(false)}
          message={"entregado correctamente"}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity="success"
            style={{ color: "white" }}
          >
            Entregado correctamente
          </Alert>
        </Snackbar> */}
        <SnackBarMessage
          open={openSnackbar}
          handleClose={() => setOpenSnackbar(false)}
          message={messageAlert}
          severity={severity}
        />
        <Dialog
          open={modalNewChallenge}
          onClose={handleBack}
          fullWidth
          maxWidth="lg"
          width="80%"
          classes={{ paper: "modal-content rounded-lg" }}
        >
          <div className="text-center pt-5">
            <div className="avatar-icon-wrapper rounded-circle mb-4">
              {/* <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-success text-success m-0 d-130"> */}
              <img
                alt="..."
                className="card-img-top rounded-sm"
                src={challenge}
                style={{ width: 70 }}
              />
              {/* </div> */}
            </div>
            <h5 className="text-center">Nuevo Reto</h5>
          </div>
          <div className=" text-center">
            <h5 className="font-weight-bold mt-2" style={{ color: "#5a318e" }}>
              Paso 1. Nombre del Reto
            </h5>
            <h6>Obligatorio (min 10 caracteres)</h6>
            <TextField
              style={{ width: "90%" }}
              className="m-2"
              id="outlined-multiline-static"
              label="Nombre"
              placeholder="Escribe aqui..."
              value={name}
              onChange={(e) => setName(e.target.value)}
              variant="outlined"
            />
            <h5 className="font-weight-bold mt-2" style={{ color: "#5a318e" }}>
              Paso 2. Descripción del Reto
            </h5>
            <h6>Obligatorio (min 10 caracteres)</h6>
            <TextField
              style={{ width: "90%" }}
              className="m-2"
              id="outlined-multiline-static"
              label="Descripción"
              placeholder="Escribe aqui..."
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              variant="outlined"
            />
            <h5 className="font-weight-bold mt-2" style={{ color: "#5a318e" }}>
              Paso 3. Criterio de validación del Reto
            </h5>
            <h6>Obligatorio (min 10 caracteres)</h6>
            <TextField
              style={{ width: "90%" }}
              className="m-2"
              id="outlined-multiline-static"
              label="Criterio de validación"
              placeholder="Escribe aqui..."
              value={criteria}
              onChange={(e) => setCriteria(e.target.value)}
              variant="outlined"
            />
            <h5 className="font-weight-bold mt-2" style={{ color: "#5a318e" }}>
              Paso 4. Recompensa en forma de número coins por pasar reto
            </h5>
            <h6>Obligatorio introducir un número</h6>
            <TextField
              style={{ width: "20%" }}
              className="m-2"
              id="outlined-multiline-static"
              label="Número de coins"
              placeholder="Escribe aqui..."
              value={coins}
              onChange={(e) => onChangeCoins(e)}
              variant="outlined"

            />
            <h5 className="font-weight-bold mt-4" style={{ color: "#5a318e" }}>
              Paso 5. Elige a quien va destinado el reto
            </h5>

            <div className="d-flex flex-row justify-content-center mb-2">
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => {
                  event.stopPropagation();
                  setCheckAllCompany(!checkAllCompany);
                  setCheckDepartmentsGroups(!checkDepartmentsGroups);
                  // if(checkExclude === false){
                  //   setCheckInclude(false)
                  //   setCheckImprove(false)
                  //   setActionProposal("EXCLUDE")
                  // }else{
                  //   setActionProposal(null)
                  // }
                }}
                onFocus={(event) => event.stopPropagation()}
                control={<Checkbox checked={checkAllCompany} className="mr-2" />}
                label="Toda la empresa"
              />

              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => {
                  event.stopPropagation();
                  setCheckDepartmentsGroups(!checkDepartmentsGroups);
                  setCheckAllCompany(!checkAllCompany);
                  // if(checkInclude === false){
                  //   setCheckExclude(false)
                  //   setCheckImprove(false)
                  //   setActionProposal("INCLUDE")
                  // }else{
                  //   setActionProposal(null)
                  // }
                }}
                onFocus={(event) => event.stopPropagation()}
                control={
                  <Checkbox checked={checkDepartmentsGroups} className="mr-2" />
                }
                label="Algunos grupos o departamentos"
              />
            </div>

            {/* <div className="d-flex flex-row justify-content-center mb-spacing-6-x2">
              <Button
                className="btn-success text-uppercase font-weight-bold font-size-xs mb-2 mr-2"
                // style={ userSelected ? { backgroundColor: "orange" } : { backgroundColor: "#5a318e" }}
                style={{ backgroundColor: "orange" }}
                // onClick={toggleUser}
                disabled={checkGroups === true}
              >

                Seleccionar Grupos
              </Button>


              <Button
                className="btn-success text-uppercase font-weight-bold font-size-xs mb-2 ml-2"
                // style={ userSelected ? { backgroundColor: "orange" } : { backgroundColor: "#5a318e" }}
                style={{ backgroundColor: "orange" }}
                // onClick={toggleUser}
                disabled={checkGroups === true}
              >

                Seleccionar Departamentos
              </Button>
            </div> */}
            {!checkAllCompany && <div className="d-flex flex-row text-center justify-content-center mb-spacing-6-x2" >
              <FormControl variant="outlined" className="w-100" style={{ maxWidth: "60%" }}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Departamentos
                </InputLabel>
                <Select
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox-departments"
                  multiple
                  value={departments}
                  disabled={optionsDepartment.length === 0 || optionsDepartment.length === null || checkAllCompany}
                  onChange={(e) => setDepartments(e.target.value)}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={(selected) => (
                    <div style={{ display: 'flex', flexWrap: 'Wrap' }}>
                      {selected.map((value) => (
                        <Chip key={value} label={optionsDepartment.find(el => el.id === value).name} style={{ margin: 2 }} color='primary' />
                      ))}
                    </div>
                  )}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                >
                  {optionsDepartment.map((department) => (
                    <MenuItem key={department.id} value={department.id}>
                      {department.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            }
            {!checkAllCompany && <div className="d-flex flex-row text-center justify-content-center mb-spacing-6-x2" >
              <FormControl variant="outlined" className="w-100" style={{ maxWidth: "60%" }}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Grupos
                </InputLabel>
                <Select
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox-departments"
                  multiple
                  value={groups}
                  disabled={optionsGroup.length === 0 || optionsGroup.length === null || checkAllCompany}
                  onChange={(e) => setGroups(e.target.value)}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={(selected) => (
                    <div style={{ display: 'flex', flexWrap: 'Wrap' }}>
                      {selected.map((value) => (
                        <Chip key={value} label={optionsGroup.find(el => el.id === value).name} style={{ margin: 2 }} color='primary' />
                      ))}
                    </div>
                  )}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                >
                  {optionsGroup.map((group) => (
                    <MenuItem key={group.id} value={group.id}>
                      {group.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            }
            <h5 className="font-weight-bold mt-4" style={{ color: "#5a318e" }}>
              Paso 6. Seleccionar las competencias que se van a entregar en este
              reto
            </h5>
            <h6>Obligatorio seleccionar al menos una competencia</h6>
            <div className="d-flex flex-row justify-content-center">
              <Button
                className="btn-success text-uppercase font-weight-bold font-size-xs mb-2 ml-2"
                // style={ userSelected ? { backgroundColor: "orange" } : { backgroundColor: "#5a318e" }}
                style={{ backgroundColor: "orange" }}
                onClick={toggleCompetence}
              >
                {/* {userSelected ? "Cambiar" : "Seleccionar usuario"} */}
                Seleccionar competencias
              </Button>
            </div>

            {competences.map(element => {
              console.log(element)
              return (
                <div className="d-flex align-items-center justify-content-center mb-2 mt-3">
                  <div className="avatar-icon-wrapper mr-3">
                    <div className="avatar-icon">
                      {element.urlImage ? (
                        <img alt="..." src={element.urlImage} />
                      ) : (
                        <img alt="..." src={defaultAvatar} />
                      )}
                    </div>
                  </div>
                  <div>
                    <a
                      href="#/"
                      onClick={(e) => e.preventDefault()}
                      className="font-weight-bold text-black"
                      title="..."
                    >
                      {element.name}
                    </a>

                  </div>
                </div>
              )
            })}

            <h5 className="font-weight-bold mt-4" style={{ color: "#5a318e" }}>
              Paso 7. Seleccionar las fechas de inicio y fin de este reto
            </h5>
            <h6>Obligatorio seleccionar ambas fechas</h6>
            <div className="d-flex flex-row justify-content-center">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className="d-flex-row align-items-center justify-content-center m-4">
                  <h5 className="font-weight-bold" style={{ color: "orange" }}>
                    fecha de inicio
                  </h5>
                  <TextField
                    id="initDate"
                    // label="Birthday"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={initDate}
                    onChange={(e) => setInitDate(e.target.value)}
                  //   onBlur={props.handleBlur}
                  //   error={Boolean(
                  //     props.touched.birthday && props.errors.birthday
                  //   )}
                  //   helperText={
                  //     props.touched.birthday ? props.errors.birthday : ""
                  //   }

                  //   KeyboardButtonProps={{
                  //     'aria-label': 'change date'
                  //   }}
                  />
                </div>
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className="d-flex-row align-items-center justify-content-center m-4">
                  <h5 className="font-weight-bold" style={{ color: "orange" }}>
                    fecha de fin
                  </h5>
                  <TextField
                    id="endDate"
                    // label="Birthday"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  //   onBlur={props.handleBlur}
                  //   error={Boolean(
                  //     props.touched.birthday && props.errors.birthday
                  //   )}
                  //   helperText={
                  //     props.touched.birthday ? props.errors.birthday : ""
                  //   }

                  //   KeyboardButtonProps={{
                  //     'aria-label': 'change date'
                  //   }}
                  />
                </div>
              </MuiPickersUtilsProvider>
            </div>
            <h5 className="font-weight-bold mt-2" style={{ color: "#5a318e" }}>
              Paso 8. Seleccionar una imagen para este reto
            </h5>
            <h6>Opcional, sino se pondrá una imagen por defecto</h6>
            <div className="d-flex flex-row justify-content-center align-items-center">
              <UploadImage picture={picture} setCrop={setCrop} />
            </div>

            <div className="d-flex flex-row justify-content-center align-items-center p-4">
              <Button
                onClick={handleOk}
                disabled={!crop || picture}
                className="btn-primary btn-pill mx-1"
              >
                <span className="btn-wrapper--label">Seleccionar</span>
              </Button>
              <Button
                onClick={handleCancel}
                disabled={!crop || !picture}
                className="btn-warning btn-pill mx-1"
              >
                <span className="btn-wrapper--label">Cambiar</span>
              </Button>
            </div>
            <div className="d-flex flex-row justify-content-center mb-spacing-6-x2">
              <div className="pt-4" style={{ marginBottom: 20 }}>
                <Button
                  onClick={handleBack}
                  className="btn-neutral-dark btn-pill mx-1"
                >
                  <span className="btn-wrapper--label">Cancelar</span>
                </Button>
                <Button
                  onClick={handleOkTest}
                  className="btn-success btn-pill mx-1"
                // disabled={!userSelected || !commentary || commentary.length < 10}
                // disabled={!endDate || !initDate}
                >
                  <span className="btn-wrapper--label">Crear</span>
                </Button>
              </div>
            </div>
          </div>
        </Dialog>
        <ModalSelectCompetence
          modalCompetence={modalCompetence}
          toggleCompetence={toggleCompetence}
          competences={competences}
          setCompetences={setCompetences}
        />
      </>
    );
  } else {
    return null;
  }
}
