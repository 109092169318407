import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GaugeChart from "react-gauge-chart";

import { Table, Grid, LinearProgress, Card, Button } from "@material-ui/core";

import PerfectScrollbar from "react-perfect-scrollbar";
import { FlagIcon } from "react-flag-kit";

export default function TablaClimind({ id, title, arrayNatures }) {
  const [currentPercent2, setCurrentPercent2] = useState();

  let naturesArrayTable = [];
  let variablesArrayRadar = []
  if (arrayNatures) {
    // console.log(arrayNatures);
    // console.log(title);
    // console.log(id);
    //Obtener naturalezas
    arrayNatures.map((subsystem, sindex) => {
      subsystem.items.map((variable, vindex) => {
        let itemVariable = {};
        itemVariable.name= variable.name;
        itemVariable.kpi = variable.value/variable.numberAnswers;
        variablesArrayRadar.push(itemVariable)
        variable.items.map((nature, nindex) => {
          let itemNature = {};
          itemNature.variable = variable.name
          itemNature.name = nature.name;
          itemNature.value = nature.value;
          itemNature.numberAnswers = nature.numberAnswers;
          itemNature.kpi = nature.value / nature.numberAnswers;
          itemNature.bosses = nature.badWithBosses / nature.numberAnswers;
          itemNature.clients = nature.badWithClients / nature.numberAnswers;
          itemNature.departments =
            nature.badWithDepartments / nature.numberAnswers;
          itemNature.partners = nature.badWithPartners / nature.numberAnswers;
          naturesArrayTable.push(itemNature);
        });
      });
    });
  }
  

  const kpiIndicator = ( dataPercent ) => {
    if (dataPercent < 0.15) {
      return (
        <div className="text-left font-size-lg">
          <small className="text-black-50 d-block">Critico (>30%)</small>
          <b>
            <FontAwesomeIcon
              icon={["fas", "circle"]}
              className="font-size-lg mr-2"
              color="red"
            />
          </b>{" "}
          Rojo
        </div>
      );
    } else if (dataPercent >= 0.15 || dataPercent < 0.3) {
      return (
        <div className="text-left font-size-lg">
          <small className="text-black-50 d-block">Atender (>30%)</small>
          <b>
            <FontAwesomeIcon
              icon={["fas", "circle"]}
              className="font-size-lg mr-2 "
              color="yellow"
            />
          </b>{" "}
          Amarillo
        </div>
      );
    } else {
      return (
        <div className="text-left font-size-lg">
          <small className="text-black-50 d-block">Mantener (>30%)</small>
          <FontAwesomeIcon
            icon={["fas", "circle"]}
            className="font-size-lg mr-2"
            color="green"
          />
          Verde
        </div>
      );
    }
  };
  const valueIndicator = ( value ) => {
    // console.log(value)
    if (value < 4) {
      return (
        <div className="text-left font-size-lg">
          <small className="text-black-50 d-block text-danger">Valor</small>
          <span className="text-danger">{Math.round(value)}</span>
        </div>
      );
    } else if (value >= 4 || value < 7) {
      return (
        <div className="text-left font-size-lg">
          <small className="text-black-50 d-block text-warning">Valor</small>
          <span className="text-warning">{Math.round(value)}</span>
        </div>
      );
    } else {
      return (
        <div className="text-left font-size-lg">
          <small className="text-black-50 d-block text-success">Valor</small>
          <span className="text-success">{Math.round(value)}</span>
        </div>
      );
    }
  };

  return (
    <Card className="card-box">
      <div className="card-header">
        <div className="card-header--title font-weight-bold">{title}</div>
      </div>
      {/* <PerfectScrollbar className="scroll-area-sm mb-2"> */}
        <div className="table-responsive-md">
          <Table className="table table-borderless table-hover text-nowrap mb-0">
            <thead className="thead-light">
              <tr>
                <th className="text-center">Variables</th>
                <th className="text-center">Naturaleza</th>
                <th className="text-center">Valor</th>
                <th>Entre compañeros</th>
                <th>Con superiores</th>
                <th>Entre departamentos</th>
                <th>Clientes o proveedores</th>
              </tr>
            </thead>
            <tbody>
              {naturesArrayTable &&
                naturesArrayTable.map((nature, index) => {
                  // console.log(nature);
                  // console.log(nature.kpi);
                  let valueI = valueIndicator(nature.kpi);
                  // let valueI = 5;
                  let partnersIndicator = kpiIndicator(nature.partners);
                  let bossesIndicator = kpiIndicator(nature.bosses);
                  let departmentsIndicator = kpiIndicator(nature.departments);
                  let clientsIndicator = kpiIndicator(nature.clients);

                  return (
                    <tr key={index}>
                      <td className="text-center font-size-lg">
                        {nature.variable}
                      </td>
                      <td className="text-center font-size-lg">
                        {nature.name}
                      </td>
                      <td>{valueI}</td>
                      <td>{partnersIndicator}</td>
                      <td>{bossesIndicator}</td>
                      <td>{departmentsIndicator}</td>
                      <td>{clientsIndicator}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      {/* </PerfectScrollbar> */}
    </Card>
  );
}
