import React, { useState, useContext, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Table,
  InputLabel,
  InputAdornment,
  Card,
  Menu,
  MenuItem,
  Button,
  Tooltip,
  Checkbox,
  TextField,
  FormControl,
  Select,
  ButtonGroup,
  Dialog,
} from "@material-ui/core";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";

import { UserContext } from "../../../../context/UserContext";

import { GET_DEPARTMENTS } from "data/queries";
import { DELETE_DEPARTMENT} from "data/mutations";

import ModalAddDepartment from "./ModalAddDepartment";
import ModalEditDepartment from "./ModalEditDepartment";
import ModalAssignLeaderDepartment from "./ModalAssignLeaderDepartment";
import ModalViewTeam from "./ModalViewTeam";


export default function DepartmentsTable({ compId }) {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);

  let companyId = null;
  if (compId) {
    companyId = compId;
  } else {
    companyId = dbuser.getUserByUid.companyId;
  }

  const { loading, error, data, networkStatus,refetch } = useQuery(GET_DEPARTMENTS, {
    variables: { id: companyId },
    skip: !companyId,
    notifyOnNetworkStatusChange: true,
  });

  const [deleteDepartment, { data: dataDelete }] = useMutation(DELETE_DEPARTMENT);

  const [entries, setEntries] = useState("1");

  const [departmentSelected, setDepartmentSelected] = useState({});

  const [modalAddDepartment, setModalAddDepartment] = useState(false);
  const toggleAddDepartment = () => setModalAddDepartment(!modalAddDepartment);

  const [modalDelete, setModalDelete] = useState(false);
  const toggleDelete = () => setModalDelete(!modalDelete);


  const [modalViewTeam, setModalViewTeam] = useState(false);
  const toggleViewTeam = () => setModalViewTeam(!modalViewTeam);

  const [
    modalAssignLeaderDepartment,
    setModalAssignLeaderDepartment,
  ] = useState(false);
  const toggleLeaderDepartment = () =>
    setModalAssignLeaderDepartment(!modalAssignLeaderDepartment);

  const [modalEditDepartment, setModalEditDepartment] = useState(false);
  const toggleEditDepartment = () =>
    setModalEditDepartment(!modalEditDepartment);

  const handleChange = (event) => {
    setEntries(event.target.value);
  };

  // Menu
  const [team, setTeam] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [departmentsList, setDepartmentsList] = useState([]);
  const [departmentsListFilter, setDepartmentsListFilter] = useState([]);

  useEffect(() => {
    if (data != null) {
      setDepartmentsList([...data.getDepartmentsByCompanyId])
      setDepartmentsListFilter([...data.getDepartmentsByCompanyId])
    }
  }, [data])

  if (networkStatus === 4) return "Refetching!";
  if (loading) return "Esperando";
  if (error) return `Error! ${error}`;

  if (data) {

    return (
      <React.Fragment key={"fragmentDT01"}>
        <Card className="card-box mb-spacing-6-x2" elevation={0}>
          <div className="d-flex justify-content-between px-4 py-3">
            <div className="d-flex align-items-center">
              <Button
                size="small"
                className="btn-neutral-primary"
                onClick={() => setModalAddDepartment(true)}
              >
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={["fas", "plus-circle"]} />
                </span>
                <span className="btn-wrapper--label">Agregar Departamento</span>
              </Button>
            </div>
            <div className="search-wrapper">
              <TextField
                variant="outlined"
                size="small"
                id="input-search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchTwoToneIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={e => {
                  const filteredTable = departmentsList.filter(o =>
                    Object.keys(o).some(k => k === "name" &&
                      String(o[k])
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    )
                  );
                  setDepartmentsListFilter(filteredTable)
                }}
              />
            </div>
          </div>
          <div className="divider" />
          <div className="table-responsive-md">
            <Table className="table table-hover  mb-0">
              <thead>
                <tr>
                  <th key={0} className="bg-white">
                    Nombre
                  </th>
                  <th key={1} className="bg-white text-left" style={{ width: '40%' }}>
                    Descripción
                  </th>
                  <th key={2} className="bg-white text-center">
                    Lider
                  </th>
                  <th key={3} className="bg-white text-center">
                    Miembros
                  </th>
                  <th key={4} className="bg-white text-center">
                    Acciones
                  </th>
                </tr>
              </thead>
              <tbody className="w-100 justify-content-between text-center">
                {departmentsListFilter.map((department, index) => {
                  return (
                    <React.Fragment key={"tGroup" + department.id}>
                      <tr key={index}>
                        {/* Nombre */}
                        <td>
                          <div className="d-flex">
                            {/* <Checkbox
                                color="primary"
                                id="checkboxProjects15"
                                className="align-self-center mr-2"
                              /> */}
                            <div className="d-flex align-items-center">
                              <div className="avatar-icon-wrapper avatar-initials avatar-icon-sm pr-2">
                                <div className={`avatar-icon text-white`} style={{ backgroundColor: department.color }}>
                                  {department.name.charAt(0).toUpperCase()}
                                </div>
                              </div>

                              <div>{department.name}</div>
                            </div>
                          </div>
                        </td>
                        {/* Descripción */}
                        <td className="d-flex align-items-left">{
                          department.description}
                        </td>
                        {/* Managers */}
                        <td className="text-center">
                          <div className="avatar-wrapper-overlap d-flex justify-content-center">
                            {department.dataManager.map((manager, index) => {
                              // console.log(manager);
                              return (
                                <div
                                  className="avatar-icon-wrapper avatar-initials avatar-icon-sm pr-2"
                                  key={"managers" + index}
                                >
                                  <div
                                    className={`avatar-icon`}
                                  // style={{ boxShadow: "0 0 0 3px " + department.color }}
                                  >
                                    {manager.urlImage ? (
                                      <img
                                        alt="..."
                                        src={manager.urlImage}
                                      />
                                    ) :
                                      manager.name.charAt(0).toUpperCase()
                                    }
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </td>
                        {/* Miembros */}
                        <td className="text-center">
                          <div className="avatar-wrapper-overlap d-flex justify-content-center">
                            {department.dataUsers.length > 4
                              ? department.dataUsers.map((user, index) => {
                                if (index < 4) {
                                  return (
                                    <div
                                      className="avatar-icon-wrapper avatar-initials avatar-icon-sm pr-2"
                                      key={"members" + index}
                                    >
                                      <div
                                        className={`avatar-icon text-white`} style={{ backgroundColor: department.color }}
                                      >
                                        {user.urlImage ? (
                                          <img
                                            alt="..."
                                            src={user.urlImage}
                                          />
                                        ) :
                                          user.name.charAt(0).toUpperCase()
                                        }
                                      </div>
                                    </div>
                                  );
                                }
                                if (index === 4) {
                                  let totalTmp =
                                    department.dataUsers.length - 4;
                                  return (
                                    <div
                                      className="avatar-icon-wrapper avatar-initials avatar-icon-sm pr-2"
                                      key={"members2" + index}
                                    >
                                      <div
                                        className={`avatar-icon text-white`} style={{ backgroundColor: department.color }}
                                      >
                                        + {totalTmp}
                                      </div>
                                    </div>
                                  );
                                } else {
                                  return <></>;
                                }
                              })
                              : department.dataUsers.map((user, index) => {
                                // console.log(users);
                                return (
                                  <div
                                    className="avatar-icon-wrapper avatar-initials avatar-icon-sm pr-2"
                                    key={"members3" + index}
                                  >
                                    <div
                                      className={`avatar-icon text-white`} style={{ backgroundColor: department.color }}
                                    >
                                      {user.urlImage ? (
                                        <img
                                          alt="..."
                                          src={user.urlImage}
                                        />
                                      ) :
                                        user.name.charAt(0).toUpperCase()
                                      }
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </td>
                        {/* Acciones */}
                        <td className="text-center">
                          <Button
                            size="small"
                            className="btn-link d-30 p-0 btn-icon hover-scale-sm"
                            onClick={() => {
                              setDepartmentSelected(department);
                              setModalViewTeam(true);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={["far", "eye"]}
                              className="font-size-lg"
                            />
                          </Button>
                          <Button
                            size="small"
                            className="btn-link d-30 p-0 btn-icon hover-scale-sm"
                            onClick={() => {
                              setDepartmentSelected(department);
                              setModalEditDepartment(true);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={["far", "edit"]}
                              className="font-size-lg"
                            />
                          </Button>
                          {/* <Button
                            size="small"
                            className="btn-link d-30 p-0 btn-icon hover-scale-sm"
                            onClick={() => {
                              setDepartmentSelected(department);
                              setModalAssignLeaderDepartment(true);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={["fas", "user-tie"]}
                              className="font-size-lg"
                            />
                          </Button> */}
                          <Button
                            size="small"
                            className="btn-link d-30 p-0 btn-icon hover-scale-sm"
                            onClick={()=>{
                              setDepartmentSelected(department);
                              toggleDelete()}}
                          >
                            <FontAwesomeIcon
                              icon={["fas", "trash"]}
                              className="font-size-lg"
                            />
                          </Button>
                          <Dialog
                            open={modalDelete}
                            onClose={toggleDelete}
                            classes={{ paper: "shadow-lg rounded" }}
                            
                          >
                            <div className="text-center p-5">
                              <div className="avatar-icon-wrapper rounded-circle m-0">
                                <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
                                  <FontAwesomeIcon
                                    icon={["fas", "times"]}
                                    className="d-flex align-self-center display-3"
                                  />
                                </div>
                              </div>
                              <h4 className="font-weight-bold mt-4">
                                ¿Estás seguro de que desas borrar este departamento?
                              </h4>
                              <p className="mb-0 font-size-lg text-muted">
                                No puedes deshacer esta operación.
                              </p>
                              <div className="pt-4">
                                <Button
                                  onClick={toggleDelete}
                                  className="btn-neutral-secondary btn-pill mx-1"
                                >
                                  <span className="btn-wrapper--label">
                                    Cancelar
                                  </span>
                                </Button>
                                <Button
                                  onClick={() => {
                                    toggleDelete();
                                    deleteDepartment({
                                      variables: { id: departmentSelected.id },
                                      refetchQueries: [
                                        {
                                          query: GET_DEPARTMENTS,
                                          variables: { id: companyId },
                                        },
                                      ],
                                    });
                                  }}
                                  className="btn-danger btn-pill mx-1"
                                >
                                  <span className="btn-wrapper--label">
                                    Borrar
                                  </span>
                                </Button>
                              </div>
                            </div>
                          </Dialog>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </Table>
            {departmentsListFilter.length === 0 && (
              <div className="text-center my-5 mx-auto w-100">

                <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-primary shadow-primary-sm text-primary mb-2 d-90">
                  <FontAwesomeIcon
                    icon={["far", "gem"]}
                    className="d-flex align-self-center font-size-xxl"
                  />
                </div>
                <h6 className="font-weight-bold font-size-xxl mb-1 mt-3 text-primary">
                  No tienes departamentos registrados
                </h6>
                <p className="text-black-50 font-size-lg mb-0">
                  Registra departamentos en tu compañía para continuar
                </p>
              </div>
            )}
          </div>
          {/* <div className="card-footer py-3 d-flex justify-content-between">
            <Pagination
              className="pagination-second"
              variant="outlined"
              count={10}
            /> 
            <div className="d-flex align-items-center">
              <span>Mostrar</span>
              <FormControl size="small" variant="outlined" className="mx-3">
                <InputLabel id="select-entries-label">Número</InputLabel>
                <Select
                  labelId="select-entries-label"
                  id="select-entries"
                  value={entries}
                  onChange={handleChange}
                  label="Entries"
                >
                  <MenuItem className="mx-2" value={1}>
                    Todos
                  </MenuItem>
                  <MenuItem className="mx-2" value={10}>
                    10
                  </MenuItem>
                  <MenuItem className="mx-2" value={15}>
                    15
                  </MenuItem>
                  <MenuItem className="mx-2" value={20}>
                    20
                  </MenuItem>
                  <MenuItem className="mx-2" value={25}>
                    25
                  </MenuItem>
                  <MenuItem className="mx-2" value={30}>
                    30
                  </MenuItem>
                </Select>
              </FormControl>
              <span>empleados</span>
            </div>
          </div> */}
        </Card>
        <ModalAddDepartment
          openAddDepartment={modalAddDepartment}
          toggleAddDepartment={toggleAddDepartment}
          compId={compId}
        />
        {modalViewTeam && (
          <ModalViewTeam
            openViewTeam={modalViewTeam}
            toggleViewTeam={toggleViewTeam}
            teamData={departmentSelected}
            type={"department"}
            compId={compId}
          />
        )}
        {modalEditDepartment && (
          <ModalEditDepartment
            openEditDepartment={modalEditDepartment}
            toggleEditDepartment={toggleEditDepartment}
            department={departmentSelected}
            compId={compId}
          />
        )}
        {modalAssignLeaderDepartment && (
          <ModalAssignLeaderDepartment
            openLeaderDepartment={modalAssignLeaderDepartment}
            toggleLeaderDepartment={toggleLeaderDepartment}
            departmentSelected={departmentSelected}
            compId={compId}
          />
        )}
      </React.Fragment>
    );
  }
}