import React, { useState, useCallback, useContext } from "react";
import { GET_COMPETENCES_BY_USER_ID } from "data/queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "../../../../context/UserContext";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  Table,
  LinearProgress,
  Card,
  CardContent,
  Button,
  Tooltip,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Input,
  Select,
} from "@material-ui/core";
import { BlockPicker } from "react-color";
import { NEW_GROUP } from "../../../../data/mutations";
import { GET_GROUPS } from "data/queries";

export default function ModalAddGroup({ openAddGroup, toggleAddGroup, compId }) {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  let companyId = null;
  if (compId) {
    companyId = compId;
  } else {
    companyId = dbuser.getUserByUid.companyId;
  }

  const [newGroup, { data }] = useMutation(NEW_GROUP);
  const [values, setValues] = useState({
    name: "",
    description: "",
    color: "#49729b",
  });
  let thumb = "";
  const { name, description, color } = values;

  const [colorShow, setColorShow] = useState(false);

  const handleInputChange = (e) => {
    console.log(e.target.value);
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <>
      <Dialog
        open={openAddGroup}
        onClose={toggleAddGroup}
        classes={{ paper: "modal-content rounded-lg" }}
      >
        <DialogTitle className="bg-white-10">Nuevo Grupo</DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <div className="text-center my-auto">
                <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-primary shadow-primary-sm text-primary mb-2 d-90">
                  <FontAwesomeIcon
                    icon={["fas", "building"]}
                    className="d-flex align-self-center font-size-xxl"
                  />
                </div>
                <h6 className="font-weight-bold font-size-xxl mb-1 mt-3 text-primary">
                  Información básica del grupo
                </h6>
              </div>
              <Card
                className="p-4 mt-4"
                style={{ backgroundColor: "#eceef7" }}
                elevation={0}
              >
                <div>
                  <TextField
                    fullWidth
                    className="m-2"
                    id="outlined-multiline-static-1"
                    name="name"
                    label="Nombre"
                    placeholder="Escribe aqui..."
                    defaultValue={name}
                    onChange={handleInputChange}
                    variant="outlined"
                  />
                  <TextField
                    fullWidth
                    className="m-2"
                    id="outlined-multiline-static-2"
                    name="description"
                    label="Descripción"
                    placeholder="Escribe aqui..."
                    defaultValue={description}
                    onChange={handleInputChange}
                    variant="outlined"
                  />
                  <FormControl fullWidth variant="outlined" className="m-2">
                    <InputLabel id="demo-simple-select-outlined-label">
                      Color
                    </InputLabel>
                    <Input type="color" style={{width: '100px'}} name="color" onChange={handleInputChange}></Input>
                  </FormControl>
                </div>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="p-4 bg-white-10">
          <Button
            className="btn-neutral-primary"
            onClick={() => toggleAddGroup()}
          >
            Cancelar
          </Button>{" "}
          <Button
            className="btn-primary ml-auto"
            onClick={() => {
              // console.log(dbuser);
              let dataInput = {
                companyId: companyId,
                name: name,
                description: description,
                color: color,
              };
              // console.log(dataInput);
              newGroup({
                variables: { input: dataInput },
                refetchQueries: [
                  {
                    query: GET_GROUPS,
                    variables: { id: companyId },
                  },
                ],
              });
              setValues({
                name: "",
                description: "",
                color: "",
              });
              toggleAddGroup();
            }}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
