import React, {
  lazy,
  Suspense,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { Switch, Route, Redirect, useLocation, useHistory } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { ClimbingBoxLoader } from "react-spinners";
import { ThemeProvider } from "@material-ui/styles";

import MuiTheme from "./theme";
import firebase from "firebase";
// Layout Blueprints

import {
  LeftSidebar,
  CollapsedSidebar,
  MinimalLayout,
  PresentationLayout,
} from "./layout-blueprints";

// User Pages
import HomeUser from "./Pages/HomeUser/HomeUser";
import GiveRecognition from "./Pages/GiveRecognition/GiveRecognition";
import GiveProposal from "./Pages/GiveProposal/GiveProposal";
import Groups from "./Pages/Profile/components/Groups";
import PreviousStep from "./Pages/PreviousStep";
import Portfolio from "./Pages/Portfolio/Portfolio";
import Recognitions from "./Pages/Recognitions/Recognitions";
import Congratulations from "./Pages/Congratulations/Congratulations";
import Gratitudes from "./Pages/Gratitudes/Gratitudes";
import Shop from "./Pages/Shop/Shop";
import MainCurriculum from "./Pages/Curriculum/MainCurriculum";
import Challenges from "./Pages/Challenges/Challenges";
import Okrs from "./Pages/Okrs/Okrs"
import ChallengesList from "./Pages/ChallengesList/ChallengesList";
import User from "./Pages/User/User";

// Admin Pages
import Dashboards from "./Pages/Admin/Dashboards/Dashboards";
import DashboardUsage from "./Pages/Admin/Dashboards/Usage/Usage";
import DashboardEnvironment from "./Pages/Admin/Dashboards/Environment/Environment";
import DashboardPulse from "./Pages/Admin/Dashboards/Pulse/Pulse";
import DashboardRecognitions from "./Pages/Admin/Dashboards/Recognitions/Recognitions";
import DashboardOkrs from "./Pages/Admin/Dashboards/Okrs/Okrs";
import DashboardComments from "./Pages/Admin/Dashboards/Comments/Comments";

import Users from "./Pages/Admin/Users/Users";
import Teams from "./Pages/Admin/Teams/Teams";
import General from "./Pages/Admin/General/General";
import Ranking from "./Pages/Admin/Ranking/Ranking";
import Profile from "./Pages/Profile/Profile";

//SuperAdmin Pages
import LoadCompetences from "./Pages/SuperAdmin/LoadCompetences/LoadCompetences";
import LoadQuestions from "./Pages/SuperAdmin/LoadQuestions/LoadQuestions";
import Companies from "./Pages/SuperAdmin/Companies/Companies";
import Company from "./Pages/SuperAdmin/Companies/Company";
import AllUsers from "./Pages/SuperAdmin/Users/Users";

import { UserContext } from "./context/UserContext";
import { AssignmentReturned } from "@material-ui/icons";

import PrivateRoute from "./PrivateRoute"
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import Login from "./Pages/Login";
import PageError404 from "./example-pages/PageError404";
import PageError500 from "./example-pages/PageError500";
import PageError505 from "./example-pages/PageError505";



const handleLogout = async (event) => {
  firebase
    .auth()
    .signOut()
    .then()
    .catch(function (error) {
      // console.log("no se ha posido cerrar sesión");
    });
};

const Routes = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory()
  const { currentUser, dbuser:db, setReloadUserContext, loading, error } = useContext(UserContext);
  // var user = firebase.auth().currentUser;
  const [dbuser, setUser] = useState()
  useEffect(()=>{setUser(db)},[db])

  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };

  const pageTransition = {
    type: "tween",
    ease: "linear",
    duration: 0.3,
  };

  const SuspenseLoading = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      let timeout = setTimeout(() => setShow(true), 300);
      return () => {
        clearTimeout(timeout);
      };
    }, []);

    return (
      <>
        <AnimatePresence>
          {show && (
            <motion.div
              key="loading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}
            >
              <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
                <div className="d-flex align-items-center flex-column px-4">
                  <ClimbingBoxLoader color={"#3c44b1"} loading={true} />
                </div>
                <div className="text-muted font-size-xl text-center pt-3">
                  Please wait while we load the live preview examples
                  <span className="font-size-lg d-block text-dark">
                    This live preview instance can be slower than a real
                    production build!
                  </span>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );

  };

  // if (currentUser && !dbuser) {
  //   return (
  //     <div className={classes.divCircularProgress}>
  //       <CircularProgress size={100} className={classes.circularProgress} />
  //     </div>
  //   )

  // }
  if (loading){
    return (
          <div className={classes.divCircularProgress}>
            <CircularProgress size={100} className={classes.circularProgress} />
          </div>
        )
  }
  if(error){
    return(<Redirect exact to="/PageError500" />)
  }

  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>

            {dbuser && currentUser && !dbuser.getUserByUid.username &&
              <PrivateRoute component={PreviousStep} />
            }
            {dbuser && currentUser && <>

              {/* <Route exact path="*" render={() => <Redirect to="/PageError404" /> }/> */}

              <LeftSidebar>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                  >

                    {dbuser.getUserByUid.role === "admin" && location.pathname === "/" && (
                      <Redirect exact from="/" to="/DashboardUsage" />
                    )}
                    {dbuser.getUserByUid.role === "superadmin" && location.pathname === "/" && (
                      <Redirect exact from="/" to="/Companies" />
                    )}
                    {dbuser.getUserByUid.role === "user" && location.pathname === "/" && (
                      <Redirect exact from="/" to="/HomeUser" />
                    )}
                    {dbuser.getUserByUid.role === "admin" && location.pathname === "/login" && (
                      <Redirect exact from="/login" to="/DashboardUsage" />
                    )}
                    {dbuser.getUserByUid.role === "superadmin" && location.pathname === "/login" && (
                      <Redirect exact from="/login" to="/Companies" />
                    )}
                    {dbuser.getUserByUid.role === "user" && location.pathname === "/login" && (
                      <Redirect exact from="/login" to="/HomeUser" />
                    )}
                    {dbuser.getUserByUid.role === "admin" && <>
                      <PrivateRoute exact path="/Dashboards" component={Dashboards} />
                      <PrivateRoute exact path="/DashboardUsage" component={DashboardUsage} />
                      <PrivateRoute exact path="/DashboardEnvironment" component={DashboardEnvironment} />
                      <PrivateRoute exact path="/DashboardPulse" component={DashboardPulse} />
                      <PrivateRoute exact path="/DashboardRecognitions" component={DashboardRecognitions} />
                      <PrivateRoute exact path="/DashboardComments" component={DashboardComments} />
                      <PrivateRoute exact path="/DashboardOkrs" component={DashboardOkrs} />
                      <PrivateRoute exact path="/Users" component={Users} />
                      <PrivateRoute exact path="/Teams" component={Teams} />
                      <PrivateRoute exact path="/General" component={General} />
                      <PrivateRoute exact path="/Ranking" component={Ranking} />
                      <PrivateRoute exact path="/Profile" component={Profile} />
                      <PrivateRoute exact path="/Shop" component={Shop} />
                      <PrivateRoute exact path="/HomeUser" component={HomeUser} />
                      <PrivateRoute exact path="/GiveRecognition" component={GiveRecognition} />
                      <PrivateRoute exact path="/GiveProposal" component={GiveProposal} />
                      <PrivateRoute exact path="/Companies" component={Companies} />
                      <PrivateRoute exact path="/Company/:id" component={Company} />
                      <PrivateRoute exact path="/AllUsers" component={AllUsers} />
                      <PrivateRoute exact path="/Groups" component={Groups} />
                      <PrivateRoute exact path="/Portfolio" component={Portfolio} />
                      <PrivateRoute exact path="/Recognitions" component={Recognitions} />
                      <PrivateRoute exact path="/Congratulations" component={Congratulations} />
                      <PrivateRoute exact path="/Gratitudes" component={Gratitudes} />
                      <PrivateRoute exact path="/MainCurriculum" component={MainCurriculum} />
                      <PrivateRoute exact path="/Challenges" component={Challenges} />
                      <PrivateRoute exact path="/ChallengesList" component={ChallengesList} />
                      <PrivateRoute exact path="/Okrs" component={Okrs} />
                      <PrivateRoute exact path="/LoadCompetences" component={LoadCompetences} />
                      <PrivateRoute exact path="/PageError404" component={PageError404} />
                      <PrivateRoute exact path="/PageError500" component={PageError500} />
                      <PrivateRoute exact path="/User/:id" component={User} />

                    </>
                    }

                    {dbuser.getUserByUid.role === "superadmin" && <>
                      <PrivateRoute exact path="/Users" component={Users} />
                      <PrivateRoute exact path="/Teams" component={Teams} />
                      <PrivateRoute exact path="/General" component={General} />
                      <PrivateRoute exact path="/Ranking" component={Ranking} />
                      <PrivateRoute exact path="/Profile" component={Profile} />
                      <PrivateRoute exact path="/Shop" component={Shop} />
                      <PrivateRoute exact path="/HomeUser" component={HomeUser} />
                      <PrivateRoute exact path="/GiveRecognition" component={GiveRecognition} />
                      <PrivateRoute exact path="/GiveProposal" component={GiveProposal} />
                      <PrivateRoute exact path="/Companies" component={Companies} />
                      <PrivateRoute exact path="/Company/:id" component={Company} />
                      <PrivateRoute exact path="/AllUsers" component={AllUsers} />
                      <PrivateRoute exact path="/Groups" component={Groups} />
                      <PrivateRoute exact path="/Portfolio" component={Portfolio} />
                      <PrivateRoute exact path="/Recognitions" component={Recognitions} />
                      <PrivateRoute exact path="/Congratulations" component={Congratulations} />
                      <PrivateRoute exact path="/Gratitudes" component={Gratitudes} />
                      <PrivateRoute exact path="/MainCurriculum" component={MainCurriculum} />
                      <PrivateRoute exact path="/LoadCompetences" component={LoadCompetences} />
                      <PrivateRoute exact path="/PageError404" component={PageError404} />
                      <PrivateRoute exact path="/PageError500" component={PageError500} />
                      <PrivateRoute exact path="/LoadQuestions" component={LoadQuestions} />
                      <PrivateRoute exact path="/User/:id" component={User} />
                    </>
                    }

                    {dbuser.getUserByUid.role === "user" && <>
                      <PrivateRoute exact path="/Dashboards" component={Dashboards} />
                      <PrivateRoute exact path="/DashboardUsage" component={DashboardUsage} />
                      <PrivateRoute exact path="/DashboardEnvironment" component={DashboardEnvironment} />
                      <PrivateRoute exact path="/DashboardPulse" component={DashboardPulse} />
                      <PrivateRoute exact path="/DashboardRecognitions" component={DashboardRecognitions} />
                      <PrivateRoute exact path="/DashboardComments" component={DashboardComments} />
                      <PrivateRoute exact path="/DashboardOkrs" component={DashboardOkrs} />
                      <PrivateRoute exact path="/Ranking" component={Ranking} />
                      <PrivateRoute exact path="/Profile" component={Profile} />
                      <PrivateRoute exact path="/Shop" component={Shop} />
                      <PrivateRoute exact path="/General" component={General} />
                      <PrivateRoute exact path="/HomeUser" component={HomeUser} />
                      <PrivateRoute exact path="/GiveRecognition" component={GiveRecognition} />
                      <PrivateRoute exact path="/GiveProposal" component={GiveProposal} />
                      <PrivateRoute exact path="/AllUsers" component={AllUsers} />
                      <PrivateRoute exact path="/Groups" component={Groups} />
                      <PrivateRoute exact path="/Portfolio" component={Portfolio} />
                      <PrivateRoute exact path="/Recognitions" component={Recognitions} />
                      <PrivateRoute exact path="/Congratulations" component={Congratulations} />
                      <PrivateRoute exact path="/Gratitudes" component={Gratitudes} />
                      <PrivateRoute exact path="/MainCurriculum" component={MainCurriculum} />
                      <PrivateRoute exact path="/Challenges" component={Challenges} />
                      <PrivateRoute exact path="/ChallengesList" component={ChallengesList} />
                      <PrivateRoute exact path="/Okrs" component={Okrs} />
                      <PrivateRoute exact path="/LoadCompetences" component={LoadCompetences} />
                      <PrivateRoute exact path="/PageError404" component={PageError404} />
                      <PrivateRoute exact path="/PageError500" component={PageError500} />
                      <PrivateRoute exact path="/User/:id" component={User} />

                      {dbuser.getUserByUid.role === "admin" && location.pathname === "/login" && currentUser && (
                        <Redirect exact from="/login" to="/DashboardUsage" />
                      )}
                      {dbuser.getUserByUid.role === "superadmin" && location.pathname === "/login" && currentUser && (
                        <Redirect exact from="/login" to="/Companies" />
                      )}
                      {dbuser.getUserByUid.role === "user" && location.pathname === "/login" && currentUser && (
                        <Redirect exact from="/login" to="/HomeUser" />
                      )}
                      {/* <Route render={()=> <Redirect exact to="/PageError404" />} /> */}
                      {/* <PrivateRoute exact path="/PageError404" component={PageError404} /> */}
                    </>
                    }

                  </motion.div>
                </Switch>
              </LeftSidebar>


            </>
            }
            <Route path="/login" component={Login} />
            {/* <Route path="*" component={PageError404} /> */}
            
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );

  // }else{
  //   <ThemeProvider theme={MuiTheme}>
  // <AnimatePresence>
  //   <Suspense fallback={<SuspenseLoading />}>
  //     <Switch>
  //   <Route exact path="/login" component={login} />
  //   </Switch>
  //     </Suspense>
  //   </AnimatePresence>
  // </ThemeProvider>
  // }

}


export default Routes;


const useStyles = makeStyles((theme) => ({
  divCircularProgress: {
    width: "100vw",
    height: "100vh",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circularProgress: {
    color: "#5a318e"
  }
}));