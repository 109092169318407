//Para verlo mejor es necesario del paquete graphql-tag
import gql from "graphql-tag";

export const GET_USER_BY_UID = gql`
  query getUserByUid($uid: String!) {
    getUserByUid(uid: $uid) {
      id
      uid
      companyId
      dni
      name
      surname
      username
      email
      birthday
      gender
      country
      province
      city
      score
      points
      role
      hiringDate
      urlImage
      zipCode
      commentaryQuestionInTimeline
      commentaryQuestionForAdmins
      commentaryEmotionInTimeline
      commentaryEmotionForAdmins
      recognitionInTimeline
      position
      description
      cieHistory
    }
    getIfIsManager(uid: $uid)
  }
`;

export const GET_USER_BY_ID = gql`
  query getUserById($id: ID!) {
    getUserById(id: $id) {
      id
      uid
      companyId
      dni
      name
      surname
      username
      email
      birthday
      gender
      country
      province
      city
      score
      points
      role
      hiringDate
      urlImage
      zipCode
      commentaryQuestionInTimeline
      commentaryQuestionForAdmins
      commentaryEmotionInTimeline
      commentaryEmotionForAdmins
      recognitionInTimeline
      position
      description
    }
  }
`;

export const GET_RECOGNITIONS_RECEIVED_BY_COMPANY_ID = gql`
  query getRecognitionsReceivedByCompanyId($id: ID!) {
    getRecognitionsReceivedByCompanyId(id: $id) {
      companyId
      sender {
        id
        name
        urlImage
      }
      receiver {
        id
        name
      }
      competenceId {
        id
        name
        description
      }
      commentary
      date
      sentBySuperior
    }
  }
`;

//Admin
export const GET_COMPANY_BY_ID = gql`
  query getCompanyById($id: ID!) {
    getCompanyById(id: $id) {
      id
      nif
      name
      email
      payment
      numberTotalUsers
      numberUsersToRegister
      legalName
      createdAt
      website
      active
      plan
      stage
      timeZone
      offices {
        main
        code
        city
        name
        address
        country
        zipCode
        province
      }
    }
  }
`;

export const GET_COMPANIES = gql`
  query getCompanies {
    getCompanies {
      id
      name
      legalName
      nif
      website
      offices {
        main
        city
        name
        address
        country
        zipCode
        province
      }
    }
  }
`;

export const GET_ADMINS_BY_COMPANY_ID = gql`
  query getAdminsByCompanyId($id: ID!) {
    getAdminsByCompanyId(id: $id) {
      id
      uid
      companyId
      name
      surname
      username
      dni
      email
      birthday
      active
      gender
      country
      zipCode
      province
      city
    }
  }
`;

export const GET_COMPANY_AND_ADMINS_BY_COMPANY_ID = gql`
  query($id: ID!) {
    getCompanyById(id: $id) {
      name
      legalName
      nif
      website
      numberUsersToRegister
      offices {
        main
        city
        name
        address
        country
        zipCode
        province
      }
    }
  }
`;

export const GET_DEPARTMENTS_GROUPS_AND_USER = gql`
  query($id: ID!, $offset: Int, $limit: Int, $typeFilter: Int, $nameFilter: String, $searchText: String ) {
    getDepartmentsByCompanyId(companyId: $id) {
      id
      name
      description
      color
      dataUsers {
        _id
        name
        surname
        username
        email
        active
        urlImage
        role
        gender
        position
        description
      }
      dataManager {
        _id
        name
        surname
        email
        active
        urlImage
        role
        gender
        position
        description
      }
    }
    getGroupsByCompanyId(companyId: $id) {
      id
      name
      description
      color
      dataUsers {
        _id
        name
        surname
        username
        email
        active
        urlImage
        role
        gender
        position
        description
      }
      dataManager {
        _id
        name
        surname
        email
        active
        urlImage
        role
        gender
        position
        description
      }
    }
    getUsersWithGroups(companyId: $id, typeFilter: $typeFilter, nameFilter: $nameFilter, searchText: $searchText, offset: $offset, limit: $limit) {
      users{
        id
        companyId
        dni
        name
        surname
        username
        role
        email
        birthday
        gender
        country
        province
        city
        position
        description
        urlImage
        groups {
          id
          name
          color
        }
        departments {
          id
          name
          color
        }
      }
      totalPages
    }
  }
`;

export const GET_USERS_COMPANY = gql`
  query($id: ID!) {
    getUsersWithGroups(companyId: $id) {
      id
      companyId
      dni
      name
      surname
      username
      role
      email
      birthday
      gender
      country
      province
      city
      position
      description
      urlImage
      groups {
        id
        name
        color
      }
      departments {
        id
        name
        color
      }
    }
  }
`;

export const GET_GROUPS = gql`
  query getGroupsByCompanyId($id: ID!) {
    getGroupsByCompanyId(companyId: $id) {
      id
      name
      description
      color
      dataUsers {
        _id
        name
        surname
        username
        gender
        email
        active
        urlImage
        role
        position
        description
      }
      dataManager {
        _id
        name
        surname
        email
        active
        urlImage
        role
        position
        description
      }
    }
  }
`;

export const GET_DEPARTMENTS = gql`
  query getDepartmentsByCompanyId($id: ID!) {
    getDepartmentsByCompanyId(companyId:$id) {
    	id
      name
      description
      color
      dataUsers{
        _id
        name
        surname
        username
        gender
        email
        active
        urlImage
        role
        position
        description
      }
      dataManager{
        _id
        name
        surname
        email
        active
        urlImage
        role
        position
        description
      }
    }
  }   
`;
export const GET_DEPARTMENT_BY_ID = gql`
  query getDepartmentById($id: ID!) {
    getDepartmentById(id: $id) {
      id
      name
      description
      color
      dataUsers{
        _id
        name
        surname
        username
        gender
        email
        active
        urlImage
        role
        position
        description
      }
      dataManager{
        _id
        name
        surname
        email
        active
        urlImage
        role
        position
        description
      }
    }
  }
`;

export const GET_GROUP_BY_ID = gql`
  query getGroupById($id: ID!) {
    getGroupById(id: $id) {
      id
      name
      description
      color
      dataUsers {
        _id
        name
        surname
        username
        gender
        email
        active
        urlImage
        role
        position
        description
      }
      dataManager {
        _id
        name
        surname
        email
        active
        urlImage
        role
        position
        description
      }
    }
  }
`;

export const GET_USERS_BY_COMPANY_ID_WITHOUT_ME = gql`
  query getUsersByCompanyIdWithoutMe($id: ID!, $userId: ID!) {
    getUsersByCompanyIdWithoutMe(id: $id, userId: $userId) {
      name
      score
      username
      surname
      email
      id
      urlImage
      groups {
        name
        id
        color
      }
      departments {
        name
        id
        color
      }
    }
  }
`;

export const GET_COMPETENCES_BY_USER_ID = gql`
  query getCompetencesByUserId($id: ID!) {
    getCompetencesByUserId(id: $id) {
      id
      entitiesInvolved
      name
      description
      type
      color
      urlImage
      active
    }
  }
`;

export const GET_QUESTION = gql`
  query getQuestion($id: ID!){
    getQuestion(id: $id){
      id
      name
    }
  }
`;

export const GET_USERS_COUNTED = gql`
  query getUsersCounted($id: ID!){
    getUsersCounted(id: $id){
      count
      _id{
        date
      }
    }
  }
`;
export const GET_NUMBER_OF_USERS_BY_COMPANYID = gql`
  query getNumberOfUsersByCompanyId($id: ID!){
    getNumberOfUsersByCompanyId(id: $id){
      count
    }
  }
`;
export const GET_NUMBER_OF_ACTIVE_USERS_BY_COMPANYID = gql`
  query getNumberOfActiveUsersByCompanyId($id: ID!){
      getNumberOfActiveUsersByCompanyId(id: $id){
      count
    }
  }
`;

export const GETS_FOR_USAGE = gql`
  query($id: ID!) {
    getUsersCounted(id: $id){
      count
      _id{
        date
      }
    }
    getNumberOfUsersByCompanyId(id: $id){
      count
    }
    getNumberOfActiveUsersByCompanyId(id: $id){
      count
    }
  }
`;

export const GET_METRICS_USAGE = gql`
  query($id: ID,$input: FiltersInput) {
    getMetricsUsage(id: $id, input: $input){
      totalUsers
      totalActiveUsers
      dataUsers {
          date
          users
        }
      }
  }
`;
export const GET_METRICS_ENVIRONMENTS = gql`
query($id: ID,$input: FiltersInput) {
  getEnvironmentMetrics(id: $id, input: $input){
  totalUsers
  totalActiveUsers
  totalActiveClimaUsers
  kpiGlobal
  kpiIndividual
  kpiInterpersonal
  kpiOrganizative
  arrayNatures{
    id
    name
    description
    order
    parent
    value
    badWithClients
    badWithBosses
    badWithPartners
    badWithDepartments
    numberAnswers
    numberUsers
  }
  dataUsers {
    date
    users
  }
  }
}
`;
export const GET_METRICS_RECOGNITIONS = gql`
query($id: ID, $input: FiltersInput) {
  getRecognitionsMetrics(id: $id, input: $input) {
    totalUsers
    totalActiveUsers
    totalActiveRecognitionsUsers
    totalRecognitions
    totalCongratulations
    totalProposals
    totalGratitudes
    totalActions
  }
}
`;
export const GET_METRICS_PULSO = gql`
query($id: ID!,$input: FiltersInput) {
    getPulsoMetrics(id: $id, input: $input){
    totalUsers
    totalActiveUsers
    totalActivePulsoUsers
    countedAspectNegative{
      userId
      valueSad
      frecuencyTotal
      frecuencySad
      typeDesarrollo
      typeRol
      typeEquipos
      typeCargaRitmo
      typeRelacciones
      typeInteracciones
      typeParticipacion
      typeContenidoTrabajo
      typeTiempo
      date
      _id{
      date
      }
    }
    countedAspectPositive{
      userId
      valueHappy
      frecuencyTotal
      frecuencyHappy
      typeContenidoTrabajo
      typeParticipacion
      typeTrabajoGrupo
      typeRol
      typeTiempo
      date
      _id{
      date
      }
    }
    countedAspectNeutro{
      userId
      valueNeutro
      frecuencyTotal
      frecuencyNeutro
      typeContenidoTrabajo
      typeParticipacion
      typeTrabajoGrupo
      typeRol
      typeTiempo
      date
      _id{
      date
      }
    }
    dataPositive{
      date
      count
      prom
    }
    dataNegative{
      date
      count
      prom
    }
    dataNeutro{
      date
      count
      prom
    }
    dataAverage{
      date
      count
    }
    dataUsers {
      date
      users
    }
  }
  getDataRiskMatrix(id: $id, input: $input){
    userId
    promSad
    percentSad
  }
  getDataEngagementMatrix(id: $id, input: $input){
    userId
    promHappy
    percentHappy
  }
}
`;

export const GETS_FOR_CLIMA = gql`
  query($id: ID!,$userId: ID,$input: FiltersInput) {
    getNumberOfUsersByCompanyId(id: $id){
      count
    }
    getNumberOfActiveUsersByCompanyId(id: $id){
      count
    }
    getNumberOfClimaActiveUsersByCompanyId(id: $userId,input: $input){
      count
    }
    getUsersCountedClima(id: $id){
      count
      _id{
        date
      }
    }
    getAnwersMetrics(id: $id){
      global
      organizacional
      individual
      interpersonal
    }
    getTreeEntitiesMetrics(id: $id){
      id
      name
      description
      parent
      value
      badWithClients
      badWithBosses
      badWithPartners
      badWithDepartments
      numberAnswers
      numberUsers
    }
  }
`;

export const GETS_METRICS_DEATILS_FOR_NATURES = gql`
query($id: ID!,$companyId:ID!,$input: FiltersInput) {
  getTreeEntityMetricsById(id: $id,companyId:$companyId,input: $input){
    id
    name
    description
    order
    parent
    value
    badWithClients
    badWithBosses
    badWithPartners
    badWithDepartments
    numberAnswers
    numberUsers
  }
}
`;


export const GETS_FOR_PULSE = gql`
  query($id: ID!) {
    getNumberOfUsersByCompanyId(id: $id){
      count
    }
    getNumberOfActiveUsersByCompanyId(id: $id){
      count
    }
    getNumberOfPulsoActiveUsersByCompanyId(id: $id){
      count
    }
    getUsersCountedPulso(id: $id){
      count
      _id{
        date
      }
    }
    getUsersPulsoAverage(id: $id){
      count
      _id{
        date
      }
    } 
    getUsersPulsoPositiveCount(id: $id){
      count
      _id{
        date
      }
    } 
    getUsersPulsoNeutroCount(id: $id){
      count
      _id{
        date
      }
    } 
    getUsersPulsoNegativeCount(id: $id){
      count
      _id{
        date
      }
    } 
    getUsersPulsoAspectsNegative(id: $id){
      typeDesarrollo
      typeRol
      typeEquipos
      typeCargaRitmo
      typeRelacciones
      typeInteracciones
      typeParticipacion
      typeContenidoTrabajo
      typeTiempo
      _id{
       date
      }
    } 
      
  }
`;

export const GETS_FOR_RECOGNITION = gql`
  query($id: ID!) {
    getNumberOfUsersByCompanyId(id: $id){
      count
    }
    getNumberOfActiveUsersByCompanyId(id: $id){
      count
    }
    getNumberOfPulsoActiveUsersByCompanyId(id: $id){
      count
    }
    getUsersCountedPulso(id: $id){
      count
      _id{
        date
      }
    }
    getUsersPulsoAverage(id: $id){
      count
      _id{
        date
      }
    }
    getUsersCountedRecognitions(id: $id){
      count
      _id{
        date
      }
    }
    getUsersCountedProposals(id: $id){
      count
      _id{
        date
      }
    }
      
  }
`;

export const POSSIBLE_ANSWER_EMOTION_TODAY = gql`
  query possibleToAnswerEmotion($id: ID!) {
      possibleToAnswerEmotion(id: $id)
  }
`;

export const POSSIBLE_ANSWER_CLIMA_TODAY = gql`
  query possibleToAnswerClima($id: ID!) {
      possibleToAnswerClima(id: $id)
  }
`;

export const GET_TIMELINE = gql`
query getTimeline($id: ID!, $first: Int, $after: String) {
  getTimeline(id: $id, first: $first, after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      cursor
      node {
        date
        companyId
        commentary
        public
        sentBySuperior
        type
        sender
        receiver
        commentaryInTimeline
        receivers {
          id
          name
          surname
          username
          email
          urlImage
          position
        }
        groups {
          name
        }
        departments {
          name
        }
        urlImage
        userId {
          id
          name
          surname
          username
          email
          urlImage
          position
        }
        receiverId {
          id
          name
          surname
          username
          email
          urlImage
          position
        }
        competenceId {
          id
          name
        }
      }
    }
  }
}
`;

export const GET_USERS_BY_COMPANY_ID = gql`
query getUsersByCompanyId($id: ID!) {
  getUsersByCompanyId(id: $id){
      name
      score
      points
      username
      surname
      email
      id
      urlImage
      groups{
        name
        id
        color
      }
      departments{
        name
        id
        color
      }
  }
}
`;
export const GET_USERS_WITH_CIE_BY_COMPANY_ID = gql`
query getCompanyUsersCie($id: ID!, $companyId: ID, $days: Int) {
  getCompanyUsersCie(id: $id, companyId: $companyId, days: $days){
      name
      username
      surname
      email
      id
      urlImage
      points
      score
      cie
      cieHistory
      groups{
        name
        id
        color
      }
      departments{
        name
        id
        color
      }
  }
}
`;

export const GET_COMPETENCES = gql`
  query getCompetences{
    getCompetences{
      id
      entitiesInvolved
      name
      description
      type
      color
      urlImage
      active
      complexity
    }
  }
`;

export const GET_BEHAVIORS_BY_COMPETENCE_ID = gql`
  query getBehaviorsByCompetenceId($id: ID!){
    getBehaviorsByCompetenceId(id: $id){
      id
      competenceId
      name
      description
      active
    }
  }
`;


// export const GET_RECOGNITIONS_SENDED_BY_USER_ID_LAST_30 = gql`
//   query getRecognitionsSendedByUserIdLast30($id: ID!) {
//     getRecognitionsSendedByUserIdLast30(id: $id) {
//       companyId
//       sender
//       receiver
//       competenceId
//       commentary
//       date
//       sentBySuperior
//     }
//   }
// `;

export const GETS_FOR_GENERAL = gql`
  query($id: ID!) {
    getRecognitionsSendedByUserIdLast30(id: $id) {
      companyId
      sender
      receiver
      competenceId
      datasender {
        name
        surname
        email
        position
        username
        urlImage
      }
      datareceiver {
        name
        surname
        email
        position
        username
        urlImage
      }
      datareceivers {
        name
        surname
        email
        position
        username
        urlImage
      }
      datagroups{
        name
      }
      datadepartments{
        name
      }
      datacompetence {
        name
        description
        urlImage
      }
      commentary
      date
      sentBySuperior
      _id
    }
    getRecognitionsReceivedByUserIdLast30(id: $id) {
      companyId
      sender
      receiver
      competenceId
      datasender {
        name
        surname
        email
        position
        username
        urlImage
      }
      datareceiver {
        name
        surname
        email
        position
        username
        urlImage
      }
      datacompetence {
        name
        description
        urlImage
      }
      commentary
      date
      sentBySuperior
      _id
  }
  getImprovementProposalsSendedByUserIdLast30(id: $id) {
      companyId
      sender
      receiver
      competenceId
      actionCheck
      datasender {
        name
        surname
        email
        position
        username
        urlImage
      }
      datareceiver {
        name
        surname
        email
        position
        username
        urlImage
      }
      datacompetence {
        name
        description
        urlImage
      }
      commentary
      date
      sentBySuperior
      state
      _id
    }
    getImprovementProposalsReceivedByUserIdLast30(id: $id) {
      companyId
      sender
      receiver
      competenceId
      actionCheck
      datasender {
        name
        surname
        email
        position
        username
        urlImage
      }
      datareceiver {
        name
        surname
        email
        position
        username
        urlImage
      }
      datacompetence {
        name
        description
        urlImage
      }
      commentary
      date
      sentBySuperior
      state
      _id
  }
  getCongratulationsSendedByUserIdLast30(id: $id) {
      companyId
      sender
      receiver
      datasender {
        name
        surname
        email
        position
        username
        urlImage
      }
      datareceiver {
        name
        surname
        email
        position
        username
        urlImage
      }
      datareceivers {
        name
        surname
        email
        position
        username
        urlImage
      }
      datagroups{
        name
      }
      datadepartments{
        name
      }
      commentary
      date
      sentBySuperior
      _id
  }
  getCongratulationsReceivedByUserIdLast30(id: $id) {
      companyId
      sender
      receiver
      datasender {
        name
        surname
        email
        position
        username
        urlImage
      }
      datareceiver {
        name
        surname
        email
        position
        username
        urlImage
      }
      commentary
      date
      sentBySuperior
      _id
  }
  getGratitudesSendedByUserIdLast30(id: $id) {
      companyId
      sender
      receiver
      datasender {
        name
        surname
        email
        position
        username
        urlImage
      }
      datareceiver {
        name
        surname
        email
        position
        username
        urlImage
      }
      datareceivers {
        name
        surname
        email
        position
        username
        urlImage
      }
      datagroups{
        name
      }
      datadepartments{
        name
      }
      commentary
      date
      sentBySuperior
      _id
  }
  getGratitudesReceivedByUserIdLast30(id: $id) {
      companyId
      sender
      receiver
      datasender {
        name
        surname
        email
        position
        username
        urlImage
      }
      datareceiver {
        name
        surname
        email
        position
        username
        urlImage
      }
      commentary
      date
      sentBySuperior
      _id
  }
  getAnswerEmotionsSendedByUserIdLast30(id: $id) {
      userId
  }
  getAnswersSendedByUserIdLast30(id: $id) {
    userId
  }
  }
`;

export const GETS_FOR_GENERAL_TOTAL = gql`
  query($id: ID!) {
    
    getRecognitionsReceivedByUserIdTotal(id: $id) {
      companyId
      sender
      receiver
      competenceId
      datasender {
        name
        surname
        email
        position
        username
        urlImage
      }
      datareceiver {
        name
        surname
        email
        position
        username
        urlImage
      }
      datacompetence {
        name
        description
        urlImage
      }
      commentary
      date
      sentBySuperior
      _id
  }
  
    getImprovementProposalsReceivedByUserIdTotal(id: $id) {
      companyId
      sender
      receiver
      competenceId
      actionCheck
      datasender {
        name
        surname
        email
        position
        username
        urlImage
      }
      datareceiver {
        name
        surname
        email
        position
        username
        urlImage
      }
      datacompetence {
        name
        description
        urlImage
      }
      commentary
      date
      sentBySuperior
      state
      _id
  }
  
  getCongratulationsReceivedByUserIdTotal(id: $id) {
      companyId
      sender
      receiver
      datasender {
        name
        surname
        email
        position
        username
        urlImage
      }
      datareceiver {
        name
        surname
        email
        position
        username
        urlImage
      }
      commentary
      date
      sentBySuperior
      _id
  }

  
  getGratitudesReceivedByUserIdTotal(id: $id) {
      companyId
      sender
      receiver
      datasender {
        name
        surname
        email
        position
        username
        urlImage
      }
      datareceiver {
        name
        surname
        email
        position
        username
        urlImage
      }
      commentary
      date
      sentBySuperior
      _id
  }
  
  }
`;

export const GET_ANSWER_EMOTIONS_SENDED_BY_USER_ID_LAST_90 = gql`
  query getAnswerEmotionsSendedByUserIdLast90($id: ID!){    
    getAnswerEmotionsSendedByUserIdLast90(id: $id) {
      value
      date
  }
  }
`;

export const GET_RECOGNITIONS_RECEIVED_BY_USER_ID = gql`
  query getRecognitionsReceivedByUserId($id: ID!){    
    getRecognitionsReceivedByUserId(id: $id) {
    id
    count
    competence{
      _id
      name
      description
      active
      entitiesInvolved
      type
      complexity
      color
      urlImage
    }
  }
  }
`;

export const GET_RECOGNITIONS_RECEIVED_BY_USER_ID_GROUPS = gql`
  query getRecognitionsReceivedByUserIdGroups($id: ID!){    
    getRecognitionsReceivedByUserIdGroups(id: $id) {
    id
    count
    competence{
      _id
      name
      description
      active
      entitiesInvolved
      type
      complexity
      color
      urlImage
    }
  }
  }
`;

export const GET_CONGRATULATIONS_RECEIVED_BY_USER_ID = gql`
  query getCongratulationsReceivedByUserId($id: ID!, $limit: Int, $offset: Int){    
    getCongratulationsReceivedByUserId(id: $id,limit: $limit, offset: $offset) {
      congratulationsObtained{
        companyId
        sender {
          name
          surname
          email
          position
          username
          urlImage
        }
        receiver {
          name
          surname
          email
          position
          username
          urlImage
        }
        departmentReceiver
        groupReceiver
        commentary
        date
        sentBySuperior
        id
      }
      totalCongratulations
  }
  }
`;

export const GET_CONGRATULATIONS_SENDED_BY_USER_ID_2 = gql`
  query getCongratulationsSendedByUserId2($id: ID!, $limit: Int, $offset: Int){    
    getCongratulationsSendedByUserId2(id: $id,limit: $limit, offset: $offset) {
      congratulationsObtained{
        companyId
        sender {
          name
          surname
          email
          position
          username
          urlImage
        }
        receiver {
          name
          surname
          email
          position
          username
          urlImage
        }
        departmentReceiver
        groupReceiver
        commentary
        date
        sentBySuperior
        id
      }
      totalCongratulations
  }
  }
`;

export const GET_GRATITUDES_RECEIVED_BY_USER_ID = gql`
  query getGratitudesReceivedByUserId($id: ID!, $limit: Int, $offset: Int){    
    getGratitudesReceivedByUserId(id: $id,limit: $limit, offset: $offset) {
      gratitudesObtained{
        companyId
        sender {
          name
          surname
          email
          position
          username
          urlImage
        }
        receiver {
          name
          surname
          email
          position
          username
          urlImage
        }
        departmentReceiver
        groupReceiver
        commentary
        date
        sentBySuperior
        id
      }
      totalGratitudes
  }
  }
`;

export const GET_GRATITUDES_SENDED_BY_USER_ID_2 = gql`
  query getGratitudesSendedByUserId2($id: ID!, $limit: Int, $offset: Int){    
    getGratitudesSendedByUserId2(id: $id,limit: $limit, offset: $offset) {
      gratitudesObtained{
        companyId
        sender {
          name
          surname
          email
          position
          username
          urlImage
        }
        receiver {
          name
          surname
          email
          position
          username
          urlImage
        }
        departmentReceiver
        groupReceiver
        commentary
        date
        sentBySuperior
        id
      }
      totalGratitudes
  }
  }
`;

export const GET_RECOGNITIONS_RECEIVED_BY_USER_ID_2 = gql`
  query getRecognitionsReceivedByUserId2($id: ID!, $limit: Int, $offset: Int){    
    getRecognitionsReceivedByUserId2(id: $id,limit: $limit, offset: $offset) {
      recognitionsObtained{
        companyId
        sender {
          name
          surname
          email
          position
          username
          urlImage
        }
        receiver {
          name
          surname
          email
          position
          username
          urlImage
        }
        competenceId {
          name
          description
          urlImage
        }
        departmentReceiver
        groupReceiver
        commentary
        date
        sentBySuperior
        id
      }
      totalRecognitions
  }
  }
`;

export const GET_RECOGNITIONS_SENDED_BY_USER_ID_2 = gql`
  query getRecognitionsSendedByUserId2($id: ID!, $limit: Int, $offset: Int){    
    getRecognitionsSendedByUserId2(id: $id,limit: $limit, offset: $offset) {
      recognitionsObtained{
        companyId
        sender {
          name
          surname
          email
          position
          username
          urlImage
        }
        receiver {
          name
          surname
          email
          position
          username
          urlImage
        }
        competenceId {
          name
          description
          urlImage
        }
        departmentReceiver
        groupReceiver
        commentary
        date
        sentBySuperior
        id
      }
      totalRecognitions
  }
  }
`;

export const GET_IMPROVEMENT_PROPOSALS_RECEIVED_BY_USER_ID_2 = gql`
  query getImprovementProposalsReceivedByUserId2($id: ID!, $limit: Int, $offset: Int){    
    getImprovementProposalsReceivedByUserId2(id: $id,limit: $limit, offset: $offset) {
      improvementProposalsObtained{
        companyId
        sender {
          name
          surname
          email
          position
          username
          urlImage
        }
        receiver {
          name
          surname
          email
          position
          username
          urlImage
        }
        competenceId {
          name
          description
          urlImage
        }
        commentary
        date
        sentBySuperior
        id
        actionCheck
        state
      }
      totalImprovementProposals
  }
  }
`;

export const GET_IMPROVEMENT_PROPOSALS_SENDED_BY_USER_ID_2 = gql`
  query getImprovementProposalsSendedByUserId2($id: ID!, $limit: Int, $offset: Int){    
    getImprovementProposalsSendedByUserId2(id: $id,limit: $limit, offset: $offset) {
      improvementProposalsObtained{
        companyId
        sender {
          name
          surname
          email
          position
          username
          urlImage
        }
        receiver {
          name
          surname
          email
          position
          username
          urlImage
        }
        competenceId {
          name
          description
          urlImage
        }
        commentary
        date
        sentBySuperior
        id
        actionCheck
        state
      }
      totalImprovementProposals
  }
  }
`;

export const GET_AWARDS_BY_COMPANYID = gql`
  query getAwardsByCompanyId($id: ID!){
    getAwardsByCompanyId(id: $id){
      id
      name
      description
      price
      urlImage
      stock
      delivery
      cost
      cieTime
      cieValue
    }
  }
`;

export const GET_USER_WITH_GROUPS = gql`
  query getUserWithGroups($userId: ID!){
    getUserWithGroups(userId: $userId) {
      id
      companyId
      dni
      name
      surname
      username
      role
      email
      birthday
      gender
      country
      province
      city
      position
      description
      urlImage
      groups {
        id
        name
        color
        description
        manager{
          id
          name
          surname
          urlImage
          username
        }
        users{
          id
          name
          surname
          urlImage
          username
        }
      }
      departments {
        id
        name
        color
        description
        manager{
          id
          name
          surname
          urlImage
          username
        }
        users{
          id
          name
          surname
          urlImage
          username
        }
      }
    }
  }
`;

export const GET_ACHIEVED_AWARDS_BY_USERID = gql`
  query getAchievedAwardsById($id: ID!){
    getAchievedAwardsById(id: $id){
      id
      price
      date
      urlImage
      state
      awardId{
        name
        description
        urlImage
        date
        price
        delivery
        cieTime
        cieValue
      }
    }
  }
`;

export const GET_ACHIEVED_AWARDS_BY_COMPANYID = gql`
  query getAchievedAwardsByCompanyId($id: ID!, $limit: Int, $offset: Int){
    getAchievedAwardsByCompanyId(id: $id, limit: $limit, offset: $offset){
      achievedAwards {
        id
        price
        date
        urlImage
        state
        awardDeliveryDate
        awardCancelledDate
        userId{
          name
          surname
          username
          email
          urlImage
          position
        }
        awardId{
          name
          description
          urlImage
          date
          price
          delivery
          cieTime
          cieValue
        }
      }
      totalAchievedAwards
    }
  }
`;

export const GET_TREE_ENTITIES_WITH_QUESTIONS = gql`
query getTreeEntitiesWithQuestions{
  getTreeEntitiesWithQuestions{
    id
    name
 		description
    parent
    active
    questions{
        id
        name
        description
    }
    urlImage
    color
  }
}
`;

export const GET_OKRS_BY_COMPANYID = gql`
query getOkrsByCompanyId($companyId: ID!) {
  getOkrsByCompanyId(companyId: $companyId){
    id
    companyId
    userId
    date
    objective
    keyResultOne{
      description
      unit
      state
      percentage
      number
      boolean
      initDate
      endDate
      metric{
        userId
        percentage
        number
        boolean
        date
      }
    }
    keyResultTwo{
      description
      unit
      state
      percentage
      number
      boolean
      initDate
      endDate
      metric{
        userId
        percentage
        number
        boolean
        date
      }
    }
    keyResultThree{
      description
      unit
      state
      percentage
      number
      boolean
      initDate
      endDate
      metric{
        userId
        percentage
        number
        boolean
        date
      }
    }
    description
    state
    initDate
    endDate
    forAllCompany
    departments{
      id
      name
      description
    }
	  groups{
      id
      name
      description
    }
  }
}
`;

export const GET_CHALLENGES_BY_COMPANYID = gql`
query getChallengesByCompanyId($companyId: ID!) {
  getChallengesByCompanyId(companyId: $companyId){
    id
    userId{
      _id
      name
      surname
      username
      email
      active
      urlImage
      role
      gender
      description
      position
    }
    companyId
    name
    description
    criteria
    forAllCompany
    departments{
      id
      name
    }
    groups{
      id
      name
    }
    competences{
      _id
      name
      description
      type
      urlImage
    }
    reward
    date
    initDate
    endDate
    urlImage
    validated
    users{
      _id
      name
      surname
      username
      email
      active
      urlImage
      role
      gender
      description
      position
    }
    awardedUsers
    endedUsers
  }
}
`;

export const GET_CHALLENGE = gql`
query getChallenge($challengeId: ID!) {
  getChallenge(challengeId: $challengeId){
    id
    userId{
      _id
      name
      surname
      username
      email
      active
      urlImage
      role
      gender
      description
      position
    }
    companyId
    name
    description
    criteria
    forAllCompany
    departments{
      id
      name
    }
    groups{
      id
      name
    }
    competences{
      _id
      name
      description
      type
      urlImage
    }
    reward
    date
    initDate
    endDate
    urlImage
    validated
    users{
      _id
      name
      surname
      username
      email
      active
      urlImage
      role
      gender
      description
      position
    }
    awardedUsers
    endedUsers
  }
}
`;

export const GET_ANSWERS_BY_COMPANYID = gql`
  query getAnswersByCompanyId($id: ID!,$input: FiltersInput) {
    getAnswersByCompanyId(id: $id, input: $input){
      id
      companyId
      userId{
        name
        surname
        username
        email
        urlImage 
      }
      questionId{
        id
        entityId
        name
        description
        active
      }
      date
      answer
      commentary
      commentaryInTimeline
      commentaryForAdmins
      badWithPartners
      badWithBosses
      badWithDepartments
      badWithClients
      groups
      departments
    }
  }
`;

export const GET_ANSWERS_EMOTION_BY_COMPANYID = gql`
  query getAnswersEmotionByCompanyId($id: ID!,$input: FiltersInput) {
    getAnswersEmotionByCompanyId(id: $id, input: $input){
      id
      companyId
      userId{
        name
        surname
        username
        email
        urlImage 
      }
      date
      answer
      commentary
      commentaryInTimeline
      commentaryForAdmins
      typeInteracciones
      typeDesarrollo
      typeContenidoTrabajo
      typeParticipacion
      typeRelacciones
      typeCargaRitmo
      typeRol
      typeEquipos
      typeTiempo
      typeContenidoTrabajoSad
      typeTiempoSad
      typeParticipacionSad
      typeRolSad
      typeTrabajoGrupoSad
    }
  }
`;

export const GET_RECOGNITIONS_RECEIVED_BY_COMPANYID_COUNT = gql`
query getRecognitionsReceivedByCompanyIdCount($id: ID, $input: FiltersInput) {
  getRecognitionsReceivedByCompanyIdCount(id: $id, input: $input) {
    count
    id
    datacompetence{
      name
      type
      description
      urlImage
    }
  }
}
`;

export const GET_USER_CIE = gql`
query getUserCie($id: ID!) {
  getUserCie(id: $id)
}
`;