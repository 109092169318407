import React, { useState,useContext } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { PageTitle } from "layout-components";

import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Card, List, ListItem } from "@material-ui/core";


import { UserContext } from "../../../context/UserContext";
import { GET_DEPARTMENTS, GET_GROUPS } from "data/queries";

import Usage from './components/Usage';
import Clima from './components/Clima';
import Recognitions from './components/Recognitions';
import Pulse from './components/Pulse';


export default function Dashboards() {

  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  // console.log(dbuser)
  let companyId = dbuser.getUserByUid.companyId

  const { loading:loadingDepartments, error:errorDepartments, data: dataDepartments, networkStatus:netDepartments } = useQuery(GET_DEPARTMENTS, {
    variables: { id:companyId },
    skip: !companyId,
    notifyOnNetworkStatusChange: true,
  });
  const { loading:loadingGroups, error:errorloadingGroups, data: dataGroups, networkStatus:netGroups } = useQuery(GET_GROUPS, {
    variables: { id:companyId },
    skip: !companyId,
    notifyOnNetworkStatusChange: true,
  });

  const [activeTab, setActiveTab] = useState("0");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  if(dataDepartments && dataGroups){
  console.log("======");
  console.log(dataDepartments);
  console.log(dataGroups);


  return (
    <>
      <PageTitle
        titleHeading="Dashboards"
        titleDescription="Información en tiempo real de tu empresa."
      />
      <Grid container spacing={6}>
        <Grid item lg={12}>
          <Card className="shadow-xxl p-3">
            <List className="nav-tabs nav-tabs-primary d-flex">
              <ListItem
                button
                disableRipple
                selected={activeTab === "0"}
                onClick={() => {
                  toggle("0");
                }}
              >
                Uso
              </ListItem>
              <ListItem
                button
                disableRipple
                selected={activeTab === "1"}
                onClick={() => {
                  toggle("1");
                }}
              >
                Clima
              </ListItem>
              <ListItem
                button
                disableRipple
                selected={activeTab === "2"}
                onClick={() => {
                  toggle("2");
                }}
              >
                Pulso
              </ListItem>
              <ListItem
                button
                disableRipple
                selected={activeTab === "3"}
                onClick={() => {
                  toggle("3");
                }}
              >
                Reconocimientos
              </ListItem>
            </List>

            <div
              className={clsx("tab-item-wrapper", {
                active: activeTab === "0",
              })}
              index={0}
            >
               <Grid container>
                <Grid item xs={12}>
                  <div className="mt-4">
                  <Usage/>
                  </div>
                </Grid>
              </Grid>
            </div>

            <div
              className={clsx("tab-item-wrapper", {
                active: activeTab === "1",
              })}
              index={1}
            >
              <Grid container>
                <Grid item xs={12}>
                  <div className="mt-4">
                  <Clima/>
                  </div>
                </Grid>
              </Grid>
            </div>

            <div
              className={clsx("tab-item-wrapper", {
                active: activeTab === "2",
              })}
              index={2}
            >
              <Grid container>
                <Grid item xs={12}>
                  <div className="mt-4">
                  <Pulse/>
                  </div>
                </Grid>
              </Grid>
            </div>

            <div
              className={clsx("tab-item-wrapper", {
                active: activeTab === "3",
              })}
              index={3}
            >
              <Grid container>
                <Grid item xs={12}>
                  <div className="mt-4">
                  <Recognitions/>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Card>
        </Grid>
      </Grid>
    </>
  );
  }
}
