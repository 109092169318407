import React, { useEffect, useState, useContext } from "react";
import moment from 'moment';

import { PageTitle } from "layout-components";
import Dashboards from "./components/Dashboards";

import Filters from "../../../../climind-components/Filters/Filters";

export default function DashboardPulse() {

  const [filtersInput, setFiltersInput] = useState({
    initDate: moment().subtract(30, 'days').format('MM-DD-YYYY'),
    finishDate: moment().format('MM-DD-YYYY'),
    departments: [],
    groups: []
  })

  const [filtersPreviousInput, setFiltersPreviousInput] = useState({
    initDate: moment().subtract(60, 'days').format('MM-DD-YYYY'),
    finishDate: moment().subtract(30, 'days').format('MM-DD-YYYY'),
    departments: [],
    groups: []
  })

  return (
    <>
      <PageTitle
        titleHeading="Pulso"
        titleDescription="Analiza cómo evoluciona la percepción de la jornada laboral"
      />
      <Filters
        setFiltersInput={setFiltersInput}
        setFiltersPreviousInput={setFiltersPreviousInput}
      />
       <Dashboards
        filtersInput={filtersInput}
        filtersPreviousInput={filtersPreviousInput}
      />
    </>
  );
}