import React, { useState, useCallback, useEffect, useContext } from "react";
import { GET_COMPETENCES_BY_USER_ID } from "data/queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "../../../../context/UserContext";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  Table,
  LinearProgress,
  Card,
  CardContent,
  Button,
  Tooltip,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
} from "@material-ui/core";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { EDIT_USER } from "../../../../data/mutations";
import { GET_USER_BY_ID, GETS_FOR_GENERAL } from "../../../../data/queries";
import avatar from "../../../../assets/images/climind/default_avatar.png";

import { useFormik } from "formik";
import * as Yup from "yup";

import { Formik } from "formik";

export default function ModalViewEmployee({
  openViewEmployee,
  toggleViewEmployee,
  dataEmployee,
  compId
}) {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);

  // const [editUser, { data }] = useMutation(EDIT_USER);

  const [tab, setTab] = useState(0)

  const { loading, error, data, networkStatus } = useQuery(GETS_FOR_GENERAL, {
    variables: { id: dataEmployee.id },
    skip: !dataEmployee.id,
    notifyOnNetworkStatusChange: true,
  });


  const handleChange = (event, value) => {
    setTab({ value });
  };

  const styles = () => ({
    root: {
      flexGrow: 1,
      backgroundColor: "red",
    },
    tabsRoot: {
      borderBottom: '1px solid #e8e8e8',
    },
    tabsIndicator: {
      backgroundColor: '#1890ff',
    },
    tabRoot: {
      textTransform: 'initial',
      minWidth: 72,
      // fontWeight: theme.typography.fontWeightRegular,
      // marginRight: theme.spacing.unit * 4,
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:hover': {
        color: '#40a9ff',
        opacity: 1,
      },
      '&$tabSelected': {
        color: '#1890ff',
        // fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: '#40a9ff',
      },
    },
    tabSelected: {},
  });

  

  if(data){
    let cie = (data.getAnswerEmotionsSendedByUserIdLast30.length <= 20 ? data.getAnswerEmotionsSendedByUserIdLast30.length : 20)/20*100 * (30/100) +
  (data.getAnswersSendedByUserIdLast30.length <= 20 ? data.getAnswersSendedByUserIdLast30.length : 20)/20*100 * (30/100)+
  (data.getRecognitionsSendedByUserIdLast30.length <= 20 ? data.getRecognitionsSendedByUserIdLast30.length : 20)/20*100 * (15/100)+
  (data.getImprovementProposalsSendedByUserIdLast30.length <= 20 ? data.getImprovementProposalsSendedByUserIdLast30.length : 20)/20*100 * (15/100)+
  (data.getCongratulationsSendedByUserIdLast30.length <= 20 ? data.getCongratulationsSendedByUserIdLast30.length : 20)/20*100 * (5/100)+
  (data.getGratitudesSendedByUserIdLast30.length <= 20 ? data.getGratitudesSendedByUserIdLast30.length : 20)/20*100 * (5/100)
  return (
    <>
      <Dialog
        open={openViewEmployee}
        onClose={toggleViewEmployee}
        classes={{ paper: "modal-content rounded-lg" }}
        maxWidth="sm"
        fullWidth
      >
        {/* <DialogTitle className="bg-white-10">Ver Empleado</DialogTitle> */}

        <Grid item>
          <Card className="card-box p-4">
            {/* <div className="card-tr-actions">
              <Button
                variant="text"
                className="p-0 d-30 border-0 btn-transition-none text-dark"
                disableRipple
              >
                <FontAwesomeIcon
                  icon={["fas", "ellipsis-h"]}
                  className="font-size-lg"
                />
              </Button>
            </div> */}
            <div className="d-flex align-items-center mb-3 mt-1">
              <div className="avatar-icon-wrapper rounded-circle mr-3">
                <div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
                  <div className="rounded-circle overflow-hidden">
                    <img alt="..." className="img-fluid" src={dataEmployee.urlImage ? dataEmployee.urlImage : avatar} />
                  </div>
                </div>
              </div>
              <div className="w-100">
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="font-weight-bold font-size-lg"
                  title="..."
                >
                  {dataEmployee.name}
                </a>
                <span className="text-black-50 d-block">
                  {dataEmployee.position ? dataEmployee.position : ("No tiene puesto asignado")}
                </span>
                <div className="d-flex align-items-center pt-1">
                  <LinearProgress
                    variant="determinate"
                    className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt progress-bar-danger"
                    value={cie}
                  />
                  <div className="font-weight-bold text-black-50 pl-2">{cie}%</div>
                </div>
              </div>
            </div>
            <p className="text-black-50 font-size-md mb-0">
              {dataEmployee.description}
            </p>

            <div className="my-4 font-size-md p-3 bg-secondary rounded-sm">
              <div className="d-flex justify-content-between">
                <span className="font-weight-bold">Email:</span>
                <span className="text-black-70">{dataEmployee.email}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="font-weight-bold">DNI:</span>
                <span className="text-black-70">{dataEmployee.dni}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="font-weight-bold">Rol:</span>
                <span className="text-black-70">{dataEmployee.role}</span>
              </div>
            </div>

            <div className="my-4 font-size-md p-3 bg-secondary rounded-sm">
              <div className="d-flex justify-content-between">
                <span className="font-weight-bold">Grupos:</span>
              </div>
              {
                dataEmployee && dataEmployee.groups.map((item, index) => (
                  <div className="d-flex justify-content-between py-1">
                    <span className="text-black-70">{item.name}</span>
                  </div>
                ))
              }
            </div>

            <div className="my-4 font-size-md p-3 bg-secondary rounded-sm">
              <div className="d-flex justify-content-between">
                <span className="font-weight-bold">Departamentos:</span>
              </div>
              {
                dataEmployee && dataEmployee.departments.map((item, index) => (
                  <div className="d-flex justify-content-between py-1">
                    <span className="text-black-70">{item.name}</span>
                  </div>
                ))
              }
            </div>




            <Button maxWidth="sm" fullWidth className="btn-neutral-success text-uppercase" onClick={() => {
              toggleViewEmployee()
            }}>
              <span className="font-weight-bold">Ok</span>
            </Button>
          </Card>
        </Grid>
      </Dialog>
    </>
  )}
  else{
    return null
  }
}
