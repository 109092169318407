import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Grid,
    Table
} from '@material-ui/core';

export default function StepReview({ employeesImport, setEmployeesImport, setActiveStepImport, fieldMatched }) {

    return (
        <>
            <div className="pt-2">
                <Grid container spacing={12} className="justify-content-center">
                    <div className="p-3">
                        <div className="px-2 pb-2">
                            <div className="font-weight-bold pb-1 font-size-lg text-capitalize">
                                Usuarios que se importarán
                            </div>
                            <div className="text-black-50">
                                Lista de usuarios que se importarán a la lista de empleados
                            </div>
                        </div>
                        <Table className="table table-borderless table-hover text-nowrap mb-0">
                            <thead className="thead-light">
                                <tr>
                                    {fieldMatched.map(field => field.fieldMatch !== null &&
                                        <th className="text-center">{field.fieldMatch}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {employeesImport &&
                                    employeesImport.map((employee, index) => {
                                        return (
                                            <tr key={index}>
                                                {fieldMatched.map(field => field.fieldMatch !== null &&
                                                    <td className="text-center font-size-lg">{employee[field.fieldImport]}</td>
                                                )}
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </Table>
                    </div>
                </Grid>
            </div>
        </>
    );
};