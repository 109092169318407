import React, { useState, useCallback, useContext } from "react";
import { GET_COMPETENCES_BY_USER_ID } from "data/queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "../../../context/UserContext";
import { useQuery } from "@apollo/react-hooks";
import {
  Table,
  LinearProgress,
  Card,
  CardContent,
  Button,
  Tooltip,
  Dialog,
  Grid,
} from "@material-ui/core";

import stock1 from "../../../assets/images/stock-photos/stock-1.jpg";
import stock2 from "../../../assets/images/stock-photos/stock-2.jpg";
import stock3 from "../../../assets/images/stock-photos/stock-3.jpg";
import avatar5 from "../../../assets/images/avatars/avatar5.jpg";
import defaultAvatar from "../../../assets/images/climind/default_avatar.png";

export default function ModalSelectCompetence({
  modalCompetence,
  toggleCompetence,
  competenceSelected,
  setCompetenceSelected,
}) {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  const {loading: loadingCompetences,error: errorCompetences,data: dataCompetences,networkStatus: networkStatusCompetences, refetch: refetchCompetences} = useQuery(GET_COMPETENCES_BY_USER_ID,
    {
    variables:{ id: dbuser.getUserByUid.id},
    skip: !dbuser.getUserByUid.id,
    notifyOnNetworkStatusChange: true,
  })
  const onSelectCompetence = useCallback((competence) => {
    setCompetenceSelected(competence);
    toggleCompetence();
  });
  if (dataCompetences) {
    return (
      <>
        <Dialog
          open={modalCompetence}
          onClose={toggleCompetence}
          maxWidth="xl"
          classes={{ paper: "modal-content rounded-lg" }}
        >
          <div className="text-center p-5 mb-3">
            <h4 className="text-center mb-4">
              Selecciona la competencia a entregar
            </h4>

            <div className="mb-spacing-6">
              <Grid container spacing={6}>
              {dataCompetences.getCompetencesByUserId.map((element, index) => {
                console.log(element)
                return(
                <Grid item xl={2}>
                  <Card className="shadow-xxl" style={{borderRadius:"12px" , border: `solid 4px ${element.color}`}}>
                    <a
                      href="#/"
                      onClick={(e) => e.preventDefault()}
                      className="card-img-wrapper rounded"
                    >
                      <div className="img-wrapper-overlay">
                        <div className="overlay-btn-wrapper">
                          <div className="font-size-lg font-weight-bold">
                            {element.name}
                          </div>

                          <Button
                            size="small"
                            className="btn-success btn-pill px-4 hover-scale-sm mb-2"
                            onClick={()=>onSelectCompetence(element)}
                          >
                            <span className="btn-wrapper--icon">
                              <FontAwesomeIcon icon={["fas", "check"]} />
                            </span>
                            <span className="btn-wrapper--label">
                              Seleccionar
                            </span>
                          </Button>
                          <Tooltip
                              classes={{ tooltip: 'text-center p-3 tooltip-secondary' }}
                              arrow
                              placement="top"
                              title={<h6>{element.description}</h6>}>
                          <Button
                            size="small"
                            className="btn-info btn-pill px-4 hover-scale-sm"
                          >
                            <span className="btn-wrapper--icon">
                              <FontAwesomeIcon icon={["fas", "info"]} />
                            </span>
                            <span className="btn-wrapper--label">
                              Información
                            </span>
                          </Button>
                          </Tooltip>
                        </div>
                      </div>
                      {/* <div className="card-badges">
                        <div className="badge badge-pill badge-neutral-info text-info">
                          Información
                        </div>
                      </div> */}
                      <img
                        src={element.urlImage}
                        className="card-img-top rounded"
                        alt="..."
                      />
                    </a>
                    
                  </Card>
                  <h5 className="text-center font-weight-bold mt-4">
                  {element.name}
                  </h5>

                  
                </Grid>
                )
            })}
              </Grid>
            </div>

            <div className="pt-4" style={{ marginBottom: 20 }}>
              <Button
                onClick={toggleCompetence}
                className="btn-neutral-dark btn-pill mx-1"
              >
                <span className="btn-wrapper--label">Atrás</span>
              </Button>
            </div>
          </div>
        </Dialog>
      </>
    );
  } else {
    return null;
  }
}
