import React, { useEffect, useState, useContext } from "react";
import { PageTitle } from "layout-components";
import { UserContext } from "../../context/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { REGISTER_TO_CHALLENGE, UNREGISTER_TO_CHALLENGE } from "data/mutations";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import {
  Table,
  InputLabel,
  Card,
  MenuItem,
  Button,
  Tooltip,
  FormControl,
  Select,
  Dialog,
  Menu,
  Grid,
  TextField,
  InputAdornment,
  Snackbar
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
// import ModalNewChallenge from "./ModalNewChallenge";
import { GET_CHALLENGES_BY_COMPANYID, GET_USER_WITH_GROUPS } from "data/queries";
import { register } from "serviceWorker";
import ModalViewChallenge from "./ModalViewChallenge";
import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
var moment = require("moment-timezone");

export default function ChallengesList() {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  const [myMessage, setMyMessage] = useState(null)
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openSnackbarDanger, setOpenSnackbarDanger] = useState(false);
  const [challengeSelected, setChallengeSelected] = useState(null);
  const [modalViewChallenge, setModalViewChallenge] = useState(false);
  const toggleViewChallenge = () => setModalViewChallenge(!modalViewChallenge);
  const [hasAccessGroups, setHasAccessGroups] = useState(false);
  const [registerToChallenge,{ data: dataRegisterToChallenge, error: errorRegisterToChallenge, loading: loadingRegisterToChallenge }] = useMutation(REGISTER_TO_CHALLENGE);
  const [unregisterToChallenge,{ data: dataUnregisterToChallenge, error: errorUnregisterToChallenge, loading: loadingUnregisterToChallenge }] = useMutation(UNREGISTER_TO_CHALLENGE);
  const {loading: loadingChallenges,error: errorChallenges,data: dataChallenges,networkStatus: networkStatusChallenges, refetch: refetchChallenges} = useQuery(GET_CHALLENGES_BY_COMPANYID,
    {
    variables:{ companyId: dbuser.getUserByUid.companyId},
    skip: !dbuser.getUserByUid.companyId,
    notifyOnNetworkStatusChange: true,
  })
  const { loading, error, data:dataGroups, networkStatus } = useQuery(
    GET_USER_WITH_GROUPS,
    {
      variables: { userId: dbuser.getUserByUid.id },
      skip: !dbuser.getUserByUid.id,
      notifyOnNetworkStatusChange: true,
    }
  );

  const onClickViewChallenge = (challenge) => {
    setChallengeSelected(challenge)
    toggleViewChallenge()
  }

  const register = async (challenge) => {
    let companyId = dbuser.getUserByUid.companyId
    try{
    let responseEdit = await registerToChallenge({
        variables: {
          userId: dbuser.getUserByUid.id,
          challengeId: challenge.id,
        }
    });
    
    }catch(err){
      setMyMessage(err.message)
              setOpenSnackbarDanger(true, () => {
                setTimeout(function () {
                  setOpenSnackbarDanger(false);
                  setMyMessage(null)
                }, 5000); //5 Second delay
              });
              console.log(err.message);
    }
    refetchChallenges()
  };

  const unregister = async (challenge) => {
    let companyId = dbuser.getUserByUid.companyId
    try{
    let responseEdit = await unregisterToChallenge({
        variables: {
          userId: dbuser.getUserByUid.id,
          challengeId: challenge.id,
        }
    });
    
  }catch(err){
    setMyMessage(err.message)
              setOpenSnackbarDanger(true, () => {
                setTimeout(function () {
                  setOpenSnackbarDanger(false);
                  setMyMessage(null)
                }, 5000); //5 Second delay
              });
              console.log(err.message);
  }
  refetchChallenges()
  };
  console.log(dataChallenges)
  console.log(dataGroups)
  if (dataChallenges  && dataGroups) {

    
      
    console.log(dataChallenges)
    return (
      <>
      <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          key={"top,right"}
          open={openSnackbarDanger}
          // classes={{ root: "toastr-primary" }}
          autoHideDuration={4000}
          onClose={() => setOpenSnackbarDanger(false)}
          message={"entregado correctamente"}
        >
          <Alert
            onClose={() => setOpenSnackbarDanger(false)}
            severity="warning"
            style={{ color: "white" }}
          >
            {myMessage}
          </Alert>
        </Snackbar>
        <PageTitle
          titleHeading="Retos"
          titleDescription="Información de retos."
        />

        <Grid item xl={12}>
          <div className="text-center">
            <h5 className="mb-20"style={{marginBottom: 20}}>Retos disponibles</h5>
            {/* <h6 style={{color:"purple"}}>Tienes 345 puntos</h6> */}
          </div>
        </Grid>
        <Grid item xl={12}>
          <div className="mb-spacing-12">
            <Grid container spacing={4}>
                {dataChallenges.getChallengesByCompanyId.length > 0 && dataChallenges.getChallengesByCompanyId.map((challenge, index) => {
                  let included = false
                  let access = false
                  let idsGroups = []
                  let idsDepartments = []

                  challenge.groups && challenge.groups.length > 0 && challenge.groups.forEach(element => {
                    console.log(element)
                    idsGroups.push(element.id)
                  });
                  challenge.departments && challenge.departments.length > 0 && challenge.departments.forEach(element => {
                    idsDepartments.push(element.id)
                  });

                  console.log(idsGroups)
                  challenge.users.forEach(element => {
                    if (element._id === dbuser.getUserByUid.id){
                      included = true
                    }
                  });

                    if(challenge.forAllCompany){
                      access = true
                    }
                    dataGroups.getUserWithGroups.groups.forEach(group => {
                      group.users.forEach(user => {
                        // console.log(user.id)
                        // console.log(dbuser.getUserByUid.id)
                        // console.log(idsGroups)
                        // console.log(group.id)
                        if(user.id === dbuser.getUserByUid.id && (idsGroups.includes(group.id))){
                          access = true
                        }
                      });
                    });
                    dataGroups.getUserWithGroups.departments.forEach(department => {
                      department.users.forEach(user => {
                        if(user.id === dbuser.getUserByUid.id && (idsDepartments.includes(department.id))){
                          access = true
                        }
                      });
                    });
                    
                    if(access){
                    return (
                    <Grid
                    item
                    xl={3}
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="avatar-icon-wrapper avatar-icon-md d-flex justify-content-center">
                      <div
                        className="avatar-icon rounded-circle text-center"
                        style={{ width: 100, height: 100 }}
                      >
                        <img
                          alt="..."
                          src={
                            challenge.urlImage
                              ? challenge.urlImage
                              : require("../../assets/images/climind/target.png")
                          }
                          // style={{borderRadius:"50%" , border: "solid 6px gold"}}
                        />
                      </div>
                    </div>
                    <div
                      className="font-size-xl font-weight-bold mb-2 d-flex justify-content-center"
                      style={{ marginTop: 10 }}
                    >
                      {challenge.name}
                    </div>
                    <div
                      className="font-size-sm mb-2 d-flex justify-content-center"
                      style={{ marginTop: 5 }}
                    >
                      {moment(challenge.initDate).format('DD/MM/YYYY')} - {moment(challenge.endDate).format('DD/MM/YYYY')}
                    </div>
                    <div
                      className="font-size-sm mb-2 d-flex justify-content-center"
                      style={{ marginTop: 5 }}
                    >
                      <h5>{moment(challenge.initDate).isSameOrBefore(moment()) && moment(challenge.endDate).isSameOrAfter(moment()) ? "Iniciado" : moment(challenge.endDate).isSameOrBefore(moment()) ? "Finalizado" : "Sin iniciar"}</h5>
                    </div>
                    <Button
                      size="small"
                      className="btn-success btn-pill px-4 hover-scale-sm mb-2 mt-10"
                      style={{ marginTop: 10 }}
                      onClick={() => onClickViewChallenge(challenge)}
                    >
                      <span className="btn-wrapper--label">
                        Ver reto
                      </span>
                    </Button>
                    {!included && (
                      <Button
                        size="small"
                        className="btn-info btn-pill px-4 hover-scale-sm mb-2 mt-10"
                        onClick={() => register(challenge)}
                        disabled={moment().isAfter(challenge.endDate)}
                      >
                        <span className="btn-wrapper--label">
                          Inscribirme
                        </span>
                      </Button>
                    )}
                    {included && (
                      <Button
                        size="small"
                        className="btn-danger btn-pill px-4 hover-scale-sm mb-2 mt-10"
                        onClick={() => unregister(challenge)}
                        disabled={moment().isAfter(challenge.endDate)}
                      >
                        <span className="btn-wrapper--label">
                          Borrarme
                        </span>
                      </Button>
                    )}

                    <div></div>
                  </Grid>
                    );
                    }
                  })}
                  {dataChallenges.getChallengesByCompanyId.length <= 0 && 
                    <>
                       <Grid item xl={12} className="d-flex flex-column justify-content-center text-center" style={{marginTop:20}}>
                        <h3>Aún no hay retos disponibles</h3>
                      </Grid>
                    </>
                  }
                  </Grid>
          </div>
        </Grid>
           
        {challengeSelected && <ModalViewChallenge
          toggleViewChallenge={toggleViewChallenge}
          modalViewChallenge={modalViewChallenge}
          challengeSelected={challengeSelected}
          setChallengeSelected={setChallengeSelected}
          refetchChallenges={refetchChallenges}
        />}
      </>
    );
  } else {
    return null;
  }
}
