import React, {
  Fragment,
  Component,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import {
  Card,
  Button,
  Modal,
  Input,
  Avatar,
  Select,
  Table,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import Cropper from "react-cropper";

import firebase from "firebase";
import { GET_COMPETENCES, GET_BEHAVIORS_BY_COMPETENCE_ID } from "data/queries";
import {
  NEW_COMPETENCE,
  EDIT_COMPETENCE,
  NEW_BEHAVIOR,
  EDIT_BEHAVIOR,
} from "data/mutations";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import UploadImage from "./UploadImage";
import { UserContext } from "../../../context/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import * as Yup from "yup";



// const FormItem = Form.Item;
const { TextArea } = Input;
const { Option } = Select;

function LoadCompetences() {
  //-------------------------------------------
  //                  VARIABLES
  //-------------------------------------------
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  const [crop, setCrop] = useState(null);
  const [cropper, setCropper] = useState(null);
  const [src, setSrc] = useState(null);
  const [cropVisible, setCropVisible] = useState(false);
  const [competenceSelected, setCompetenceSelected] = useState(null);
  const [behaviorSelected, setBehaviorSelected] = useState(null);
  const [nameNewCompetence, setNameNewCompetence] = useState(null);
  const [colorNewCompetence, setColorNewCompetence] = useState(null);
  const [descriptionNewCompetence, setDescriptionNewCompetence] = useState(
    null
  );
  const [typeNewCompetence, setTypeNewCompetence] = useState("DIGITAL");
  const [complexityNewCompetence, setComplexityNewCompetence] = useState("LOW");
  const [modalNewCompetenceVisible, setModalNewCompetenceVisible] = useState(
    false
  );
  const [modalBehaviorsVisible, setModalBehaviorsVisible] = useState(false);
  const [nameNewBehavior, setNameNewBehavior] = useState(null);
  const [modalEditCompetenceVisible, setModalEditCompetenceVisible] = useState(
    false
  );
  const [modalEditBehaviorsVisible, setModalEditBehaviorsVisible] = useState(
    false
  );
  // const toggleAddCompetence = () => setModalAddCompetence(!modalAddCompetence);

  const onChange = useCallback((e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setSrc(reader.result);
    };
    reader.readAsDataURL(files[0]);
  });
  const cropImage = useCallback(() => {
    if (typeof cropper.getCroppedCanvas() === "undefined") {
      return;
    }
    setCrop(cropper.getCroppedCanvas().toDataURL());
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      color: "",
      type: "DIGITAL",
      complexity: "LOW",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Obligatorio"),
      description: Yup.string().required("Obligatorio"),
      color: Yup.string().required("Obligatorio"),
      type: Yup.string().required("Obligatorio"),
      complexity: Yup.string().required("Obligatorio"),
    }),
    onSubmit: async (data, { setErrors, resetForm }) => {
      try {
        let dataInput = {
          name: data.name,
          description: data.description,
          color: data.color,
          type: data.type,
          complexity: data.complexity,
        };
        console.log(dataInput);
        let response = await saveCompetence({
          variables: { input: dataInput },
          refetchQueries: [{ query: GET_COMPETENCES }],
        });
        console.log(response);
        //guardamos en firebase el icono
        try {
          const storageRef = firebase
            .storage()
            .ref(`/iconsCompetences/${response.data.newCompetence.id}`);
          const pictureUploaded = storageRef
            .putString(crop, "data_url")
            .then(async function (snapshot) {
              try {
                await storageRef.getDownloadURL().then(async (url) => {
                  console.log(url);
                  // setIcon(url)
                  let dataEditInput = {
                    urlImage: url,
                  };
                  let responseEdit = await editCompetence({
                    variables: {
                      id: response.data.newCompetence.id,
                      input: dataEditInput,
                    },
                    refetchQueries: [{ query: GET_COMPETENCES }],
                  });
                });
              } catch (e) {
                console.log(e);
              }
            });
        } catch (e) {
          console.log(e);
        }
        // setValues({
        //     name: "",
        //     description: "",
        //     color: "",
        //     type: "",
        //     complexity: ""
        // })
        setCrop(null);
        setSrc(null)
        setCropper(null)
        setModalNewCompetenceVisible(false);
      } catch (err) {
        console.log(err);
      }
    },
  });
  const formikEdit = useFormik({
    initialValues: {
        name: nameNewCompetence,
        description: descriptionNewCompetence,
        type: typeNewCompetence,
        complexity: complexityNewCompetence,
        color: colorNewCompetence,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Obligatorio"),
      description: Yup.string().required("Obligatorio"),
      color: Yup.string().required("Obligatorio"),
      type: Yup.string().required("Obligatorio"),
      complexity: Yup.string().required("Obligatorio"),
    }),
    onSubmit: async (data, { setErrors, resetForm }) => {
        console.log(data)
      try {
        let dataInput = {
          name: data.name,
          description: data.description,
          color: data.color,
          type: data.type,
          complexity: data.complexity
        };
        console.log(dataInput);
        let response = await editCompetence({
          variables: { id:competenceSelected.id, input: dataInput },
          refetchQueries: [{ query: GET_COMPETENCES }],
        });
        console.log(response);
        //guardamos en firebase el icono
        if(crop){
            try{
                const storageRef = firebase.storage().ref(`/iconsCompetences/${competenceSelected.id}`);
                const pictureUploaded = storageRef.putString(crop, 'data_url').then( async function(snapshot) {
                try{
                await storageRef.getDownloadURL().then(async (url) => {
                    console.log(url)
                    // setIcon(url)
                    let dataEditInput = {
                        "urlImage": url
                    };
                    let responseEdit2 = await editCompetence({variables:{id:competenceSelected.id, input:dataEditInput}, refetchQueries: [{query: GET_COMPETENCES}]});
                })
                }catch(e){
                console.log(e)
                }
                });
            }catch(e){
                console.log(e)
            }
        }

        setCrop(null);
        setSrc(null)
        setCropper(null)
        setModalEditCompetenceVisible(false);
      } catch (err) {
        console.log(err);
      }
    },
  });

  //-------------------------------------------
  //          TABLA DE COMPORTAMIENTOS
  //-------------------------------------------
  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        console.log(record);
        // return(<Fragment>

        //     <Row className="align-items-center">
        //         <Col xl={21} className="d-flex justify-content-left">
        //             <h3 className="ml-4">{text}</h3>
        //         </Col>
        //         <Col xl={3} className="d-flex justify-content-right">
        //             <Button className="btn-primary text-uppercase fs-sm mb-2" onClick={() => showModalEditBehaviors(record)}>Editar</Button>
        //             {/* <Button className="btn-primary text-uppercase fs-sm mb-2" >Borrar</Button> */}
        //         </Col>
        //     </Row>

        // </Fragment>)
        return <h1>hola</h1>;
      },
    },
  ];

  //+++++++++++++++++++++++++++++++++++++++++
  //            MODAL DE COMPETENCE
  //+++++++++++++++++++++++++++++++++++++++++
  const showModalNewCompetence = useCallback(() => {
    setModalNewCompetenceVisible(true);
  });
  const handleCancelNewCompetence = useCallback((e) => {
    setCrop(null);
    setSrc(null)
    setCropper(null)
    setColorNewCompetence(null);
    setComplexityNewCompetence("LOW");
    setNameNewCompetence(null);
    setDescriptionNewCompetence(null);
    setTypeNewCompetence("DIGITAL");
    setModalNewCompetenceVisible(false);
  });
  const handleOkNewCompetence = async (e) => {
    console.log(typeNewCompetence);
    if (
      nameNewCompetence === null ||
      descriptionNewCompetence === null ||
      typeNewCompetence === null ||
      complexityNewCompetence === null
    ) {
      // return(message.success('nombre, descripcion, tipo y complejidad son obligatorios'))
    }
    let dataInput = {
      name: nameNewCompetence,
      description: descriptionNewCompetence,
      type: typeNewCompetence,
      complexity: complexityNewCompetence,
      color: colorNewCompetence,
    };
    console.log(dataInput);
    let response = await saveCompetence({
      variables: { input: dataInput },
      refetchQueries: [{ query: GET_COMPETENCES }],
    });
    console.log(response);
    //guardamos en firebase el icono
    try {
      const storageRef = firebase
        .storage()
        .ref(`/iconsCompetences/${response.data.newCompetence.id}`);
      const pictureUploaded = storageRef
        .putString(crop, "data_url")
        .then(async function (snapshot) {
          try {
            await storageRef.getDownloadURL().then(async (url) => {
              console.log(url);
              // setIcon(url)
              let dataEditInput = {
                urlImage: url,
              };
              let responseEdit = await editCompetence({
                variables: {
                  id: response.data.newCompetence.id,
                  input: dataEditInput,
                },
                refetchQueries: [{ query: GET_COMPETENCES }],
              });
            });
          } catch (e) {
            console.log(e);
          }
        });
    } catch (e) {
      console.log(e);
    }
    setCrop(null);
    setSrc(null)
    setCropper(null)
    setColorNewCompetence(null);
    setComplexityNewCompetence("LOW");
    setNameNewCompetence(null);
    setDescriptionNewCompetence(null);
    setTypeNewCompetence("DIGITAL");
    setModalNewCompetenceVisible(false);
  };
  //+++++++++++++++++++++++++++++++++++++++++
  //        MODAL DE EDITAR COMPETENCE
  //+++++++++++++++++++++++++++++++++++++++++
  const showModalEditCompetence = useCallback((competence) => {
      console.log(competence)
    formikEdit.setFieldValue('name', competence.name)
    formikEdit.setFieldValue('description', competence.description)
    formikEdit.setFieldValue('color', competence.color)
    formikEdit.setFieldValue('type', competence.type)
    formikEdit.setFieldValue('complexity', competence.complexity)
    setCompetenceSelected(competence);
    setNameNewCompetence(competence.name);
    setDescriptionNewCompetence(competence.description);
    setColorNewCompetence(competence.color);
    //setCrop(competence.urlImage);
    setSrc(competence.urlImage);
    // setCropper(competence.urlImage)
    setComplexityNewCompetence("LOW");
    setTypeNewCompetence("DIGITAL");
    setModalEditCompetenceVisible(true);
  });
  const handleCancelEditCompetence = useCallback((e) => {
    setCrop(null);
    setSrc(null)
    setCropper(null)
    setColorNewCompetence(null);
    setComplexityNewCompetence("LOW");
    setNameNewCompetence(null);
    setDescriptionNewCompetence(null);
    setTypeNewCompetence("DIGITAL");
    setCompetenceSelected(null);
    setModalEditCompetenceVisible(false);
  });
  const handleOkEditCompetence = async (e) => {
    let dataInput = {
      name: nameNewCompetence,
      description: descriptionNewCompetence,
      type: typeNewCompetence,
      complexity: complexityNewCompetence,
      color: colorNewCompetence,
    };
    console.log(dataInput);
    let responseEdit = await editCompetence({
      variables: { id: competenceSelected.id, input: dataInput },
      refetchQueries: [{ query: GET_COMPETENCES }],
    });
    //guardamos en firebase el icono
    try {
      const storageRef = firebase
        .storage()
        .ref(`/iconsCompetences/${competenceSelected.id}`);
      const pictureUploaded = storageRef
        .putString(crop, "data_url")
        .then(async function (snapshot) {
          try {
            await storageRef.getDownloadURL().then(async (url) => {
              console.log(url);
              // setIcon(url)
              let dataEditInput = {
                urlImage: url,
              };
              let responseEdit2 = await editCompetence({
                variables: { id: competenceSelected.id, input: dataEditInput },
                refetchQueries: [{ query: GET_COMPETENCES }],
              });
            });
          } catch (e) {
            console.log(e);
          }
        });
    } catch (e) {
      console.log(e);
    }
    setCrop(null);
    setSrc(null)
    setCropper(null)
    setColorNewCompetence(null);
    setComplexityNewCompetence("LOW");
    setNameNewCompetence(null);
    setDescriptionNewCompetence(null);
    setTypeNewCompetence("DIGITAL");
    setModalEditCompetenceVisible(false);
  };
  //+++++++++++++++++++++++++++++++++++++++++
  //         MODAL DE COMPORTAMIENTOS
  //+++++++++++++++++++++++++++++++++++++++++
  const showModalBehaviors = (competence) => {
    setCompetenceSelected(competence);
    getBehaviorsByCompetenceId({ variables: { id: competence.id } });
    setModalBehaviorsVisible(true);
  };
  const handleCancelBehaviors = useCallback((e) => {
    setNameNewBehavior(null);
    setCompetenceSelected(null);
    setModalBehaviorsVisible(false);
  });
  const handleOkBehaviors = async (e) => {
    setCompetenceSelected(null);
    setModalBehaviorsVisible(false);
  };
  const createNewBehavior = async (e) => {
    console.log(competenceSelected);
    if (nameNewBehavior === null) {
      // return(message.success('nombre y descripcion son obligatorios'))
    }
    let dataInput = {
      name: nameNewBehavior,
      competenceId: competenceSelected.id,
    };
    console.log(dataInput);
    try {
      let response = await saveBehavior({
        variables: { input: dataInput },
        refetchQueries: [
          {
            query: GET_BEHAVIORS_BY_COMPETENCE_ID,
            variables: { id: competenceSelected.id },
          },
        ],
      });
    } catch (e) {
      console.log(e);
    }
    setNameNewBehavior(null);
    // return(message.success('creado correctamente el comportamiento'))
  };
  //+++++++++++++++++++++++++++++++++++++++++
  //      MODAL DE EDITAR COMPORTAMIENTOS
  //+++++++++++++++++++++++++++++++++++++++++
  const showModalEditBehaviors = (behavior) => {
    setBehaviorSelected(behavior);
    setNameNewBehavior(behavior.name);
    setModalEditBehaviorsVisible(true);
  };
  const handleCancelEditBehaviors = useCallback((e) => {
    setBehaviorSelected(null);
    setNameNewBehavior(null);
    setModalEditBehaviorsVisible(false);
  });
  const handleOkEditBehaviors = async (e) => {
    console.log(competenceSelected);
    let dataInput = {
      name: nameNewBehavior,
    };
    console.log(dataInput);
    try {
      let response = await editBehavior({
        variables: { id: behaviorSelected.id, input: dataInput },
        refetchQueries: [
          {
            query: GET_BEHAVIORS_BY_COMPETENCE_ID,
            variables: { id: competenceSelected.id },
          },
        ],
      });
    } catch (e) {
      console.log(e);
    }
    setBehaviorSelected(null);
    setNameNewBehavior(null);
    setModalEditBehaviorsVisible(false);
    // return(message.success('actualizado correctamente el comportamiento'))
  };

  //+++++++++++++++++++++++++++++++++++++++++
  //            MODAL DE CROP
  //+++++++++++++++++++++++++++++++++++++++++
  const showModalCrop = useCallback(() => {
    setCropVisible(true);
  });
  const handleCancelCrop = useCallback((e) => {
    setCropVisible(false);
  });
  const handleOkCrop = async (e) => {
    setCrop(crop);
    setCropVisible(false);
  };

  //-------------------------------------------
  //            QUERIES Y MUTATION
  //-------------------------------------------
  const [
    saveCompetence,
    {
      data: dataCompetence,
      error: errorCompetence,
      loading: loadingCompetence,
    },
  ] = useMutation(NEW_COMPETENCE);
  const [
    editCompetence,
    {
      data: dataEditCompetence,
      error: errorEditCompetence,
      loading: loadingEditCompetence,
    },
  ] = useMutation(EDIT_COMPETENCE);
  const [
    saveBehavior,
    { data: dataBehavior, error: errorBehavior, loading: loadingBehavior },
  ] = useMutation(NEW_BEHAVIOR);
  const [
    editBehavior,
    {
      data: dataEditBehavior,
      error: errorEditBehavior,
      loading: loadingEditBehavior,
    },
  ] = useMutation(EDIT_BEHAVIOR);
  const {
    loading: loadingCompetences,
    error: errorCompetences,
    data: dataCompetences,
    refetch: refetchCompetences,
    networkStatus: networkStatusCompetences,
  } = useQuery(GET_COMPETENCES, {
    variables: { id: dbuser.getUserByUid.id },
    skip: !dbuser.getUserByUid.id,
    notifyOnNetworkStatusChange: true,
  });
  const [
    getBehaviorsByCompetenceId,
    { loading: loadingBehaviors, data: dataBehaviors },
  ] = useLazyQuery(GET_BEHAVIORS_BY_COMPETENCE_ID);
  if (dataBehaviors) {
    console.log(dataBehaviors);
  }
  //-------------------------------------------
  //                  CSS
  //-------------------------------------------
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  };

  //-------------------------------------------
  //                VISUALIZACION
  //-------------------------------------------
  if (networkStatusCompetences === 4) return "Refetching!";
  if (loadingCompetences) return null;
  if (errorCompetences) return `Error! ${errorCompetences}`;
  if (dataCompetences) {
    return (
      <Fragment>
        <Card className="card">
          <h1 className="text-center mb-4">Competencias de climind</h1>

          <div className="text-center mb-4">
            <Button
              className="btn-primary text-uppercase fs-sm mb-2"
              onClick={() => showModalNewCompetence()}
            >
              Crear nueva competencia
            </Button>
          </div>

          <div className="mb-spacing-6">
            <Grid container spacing={6}>
              {dataCompetences.getCompetences[0] ? (
                dataCompetences.getCompetences.map((element, index) => {
                  return (
                    <Grid item xl={2} md={4} sm={6}>
                      <Card className="shadow-xxl" style={{borderRadius:"12px" , border: `solid 4px ${element.color}`}}>
                        <a
                          href="#/"
                          onClick={(e) => e.preventDefault()}
                          className="card-img-wrapper rounded"
                        >
                          <div className="img-wrapper-overlay">
                            <div className="overlay-btn-wrapper">
                              <div className="font-size-lg font-weight-bold">
                                {element.name}
                              </div>

                              <Button
                                size="small"
                                className="btn-success btn-pill px-4 hover-scale-sm mb-2"
                                onClick={() => showModalEditCompetence(element)}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Editar competencia
                                </span>
                              </Button>
                              <Button
                                size="small"
                                className="btn-info btn-pill px-4 hover-scale-sm"
                                onClick={() => showModalBehaviors(element)}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "info"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Ver comportamientos
                                </span>
                              </Button>
                            </div>
                          </div>
                          {/* <div className="card-badges">
                        <div className="badge badge-pill badge-neutral-info text-info">
                          Información
                        </div>
                      </div> */}
                          <img
                            src={element.urlImage}
                            className="card-img-top rounded"
                            alt="..."
                          />
                        </a>
                      </Card>
                      <h5 className="text-center font-weight-bold mt-4">
                        {element.name}
                      </h5>
                    </Grid>
                  );
                })
              ) : (
                <h3>no hay competencias</h3>
              )}
            </Grid>
          </div>
        </Card>
        {/* +++++++++++++++++++++++++++++++++++++++++
                        MODAL DE COMPETENCE
            +++++++++++++++++++++++++++++++++++++++++ */}

        <Dialog
          maxWidth="lg"
          // width="80%"
          open={modalNewCompetenceVisible}
          // onClose={toggleAddCompetence}
          classes={{ paper: "modal-content rounded-lg" }}
        >
          <DialogTitle className="bg-white-10">Nueva Competencia</DialogTitle>
          <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            <DialogContent width="80%">
              <div className="align-items-left justify-content-left">
                <div>
                  <TextField
                    fullWidth
                    className="m-2"
                    id="outlined-multiline-static-1"
                    name="name"
                    label="Nombre"
                    placeholder="Escribe aqui..."
                    variant="outlined"
                    {...formik.getFieldProps("name")}
                    error={Boolean(formik.touched.name && formik.errors.name)}
                    helperText={formik.touched.name ? formik.errors.name : ""}
                  />
                  <TextField
                    fullWidth
                    className="m-2"
                    id="outlined-multiline-static-2"
                    name="description"
                    label="Descripción"
                    placeholder="Escribe aqui..."
                    variant="outlined"
                    {...formik.getFieldProps("description")}
                    error={Boolean(
                      formik.touched.description && formik.errors.description
                    )}
                    helperText={
                      formik.touched.description
                        ? formik.errors.description
                        : ""
                    }
                  />
                  <TextField
                    fullWidth
                    className="m-2"
                    id="outlined-multiline-static-3"
                    name="color"
                    label="color"
                    placeholder="Escribe aqui..."
                    variant="outlined"
                    {...formik.getFieldProps("color")}
                    error={Boolean(formik.touched.color && formik.errors.color)}
                    helperText={formik.touched.color ? formik.errors.color : ""}
                  />
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">
                      Tipo
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      name="type"
                      label="type"
                      {...formik.getFieldProps("type")}
                      error={Boolean(formik.touched.type && formik.errors.type)}
                      helperText={formik.touched.type ? formik.errors.type : ""}
                    >
                      <MenuItem value="DIGITAL">DIGITAL</MenuItem>
                      <MenuItem value="EMOTIONAL">EMOTIONAL</MenuItem>
                      <MenuItem value="SOCIAL">SOCIAL</MenuItem>
                      <MenuItem value="TRANSVERSAL">TRANSVERSAL</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">
                      Complejidad
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      name="complexity"
                      label="complexity"
                      {...formik.getFieldProps("complexity")}
                      error={Boolean(
                        formik.touched.complexity && formik.errors.complexity
                      )}
                      helperText={
                        formik.touched.complexity
                          ? formik.errors.complexity
                          : ""
                      }
                    >
                      <MenuItem value="LOW">BAJA</MenuItem>
                      <MenuItem value="MEDIUM">MEDIA</MenuItem>
                      <MenuItem value="HIGH">ALTA</MenuItem>
                    </Select>
                  </FormControl>
                  <div className="d-flex align-items-center flex-column justify-content-center">
                    <div className="pb-4">
                      <input
                        accept="image/*"
                        onChange={onChange}
                        className="d-none"
                        id="upload-image-for-cropping"
                        multiple
                        type="file"
                      />
                      <label
                        className="d-block text-center mt-4"
                        htmlFor="upload-image-for-cropping"
                      >
                        <Button
                          className="btn-primary font-weight-bold"
                          size="large"
                          component="span"
                        >
                          Cargar imagen
                        </Button>
                      </label>

                      <div className="divider w-100 my-4" />
                      {src && (
                        <>
                          <Button
                            size="large"
                            className="btn-outline-danger m-2"
                            onClick={cropImage}
                          >
                            Recortar imagen
                          </Button>
                        </>
                      )}
                    </div>
                    {src && (
                      <>
                        <Cropper
                          style={{ height: 400, width: "60%" }}
                          preview=".img-preview"
                          aspectRatio={1 / 1}
                          guides={false}
                          src={src}
                          ref={(cropper) => {
                            setCropper(cropper);
                          }}
                          name="crop"
                          {...formik.getFieldProps("crop")}
                          error={Boolean(
                            formik.touched.crop && formik.errors.crop
                          )}
                          helperText={
                            formik.touched.crop ? formik.errors.crop : ""
                          }
                        />
                      </>
                    )}
                  </div>
                  <div className="divider my-4" />
                  {src && (
                    <>
                      <Grid
                        container
                        spacing={6}
                        className="align-items-center justify-content-center"
                      >
                        <Grid item md={6}>
                          <div className="font-weight-bold text-center font-size-xl mb-4">
                            Resultado final
                          </div>
                          {!crop && (
                            <Alert
                              className="alerts-alternate font-size-lg text-warning p-4 m-4"
                              severity="warning"
                            >
                              Haz Click en el boton de recortar!
                            </Alert>
                          )}
                          {crop && (
                            <div
                              className="shadow-xxl m-4 rounded-lg overflow-hidden w-100 img-preview d-flex justify-content-center"
                              style={{ height: 340 }}
                            >
                              <img
                                className="rounded-lg img-fit-container"
                                src={crop}
                                alt="..."
                              />
                            </div>
                          )}
                        </Grid>
                        <Grid item md={6}>
                          <div className="font-weight-bold text-center font-size-xl mb-4">
                            Vista previa
                          </div>
                          <div
                            className="shadow-xxl m-4 rounded-lg overflow-hidden w-100 d-flex justify-content-center"
                            style={{ height: 340 }}
                          >
                            <div className="rounded-lg img-fit-container img-preview" />
                          </div>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </div>
              </div>
            </DialogContent>

            <DialogActions className="p-4 bg-white-10">
              <Button
                className="btn-neutral-primary"
                onClick={() => handleCancelNewCompetence()}
              >
                Cancelar
              </Button>{" "}
              <Button className="btn-primary ml-auto" type="submit">
                Guardar
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        {/* +++++++++++++++++++++++++++++++++++++++++
                        MODAL DE EDIT COMPETENCE
            +++++++++++++++++++++++++++++++++++++++++ */}

<Dialog
          maxWidth="lg"
          // width="80%"
          open={modalEditCompetenceVisible}
          // onClose={toggleAddCompetence}
          classes={{ paper: "modal-content rounded-lg" }}
        >
          <DialogTitle className="bg-white-10">Editar Competencia</DialogTitle>
          <form onSubmit={formikEdit.handleSubmit} onReset={formikEdit.handleReset}>
            <DialogContent width="80%">
              <div className="align-items-left justify-content-left">
                <div>
                  <TextField
                    fullWidth
                    className="m-2"
                    id="outlined-multiline-static-1"
                    name="name"
                    label="Nombre"
                    placeholder="Escribe aqui..."
                    variant="outlined"
                    {...formikEdit.getFieldProps("name")}
                    error={Boolean(formikEdit.touched.name && formikEdit.errors.name)}
                    helperText={formikEdit.touched.name ? formikEdit.errors.name : ""}
                  />
                  <TextField
                    fullWidth
                    className="m-2" 
                    id="outlined-multiline-static-2"
                    name="description"
                    label="Descripción"
                    placeholder="Escribe aqui..."
                    variant="outlined"
                    {...formikEdit.getFieldProps("description")}
                    error={Boolean(formikEdit.touched.description && formikEdit.errors.description)}
                    helperText={formikEdit.touched.description ? formikEdit.errors.description : ""}
                  />
                  <TextField
                    fullWidth
                    className="m-2"
                    id="outlined-multiline-static-3"
                    name="color"
                    label="color"
                    placeholder="Escribe aqui..."
                    variant="outlined"
                    {...formikEdit.getFieldProps("color")}
                    error={Boolean(formikEdit.touched.color && formikEdit.errors.color)}
                    helperText={formikEdit.touched.color ? formikEdit.errors.color : ""}
                  />
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">
                      Tipo
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined" 
                      name="type"
                      label="type"
                      {...formikEdit.getFieldProps("type")}
                    error={Boolean(formikEdit.touched.type && formikEdit.errors.type)}
                    helperText={formikEdit.touched.type ? formikEdit.errors.type : ""}
                    >
                      <MenuItem value="DIGITAL">DIGITAL</MenuItem>
                      <MenuItem value="EMOTIONAL">EMOTIONAL</MenuItem>
                      <MenuItem value="SOCIAL">SOCIAL</MenuItem>
                      <MenuItem value="TRANSVERSAL">TRANSVERSAL</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">
                      Complejidad
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined" 
                      name="complexity"
                      label="complexity"
                      {...formikEdit.getFieldProps("complexity")}
                    error={Boolean(formikEdit.touched.complexity && formikEdit.errors.complexity)}
                    helperText={formikEdit.touched.complexity ? formikEdit.errors.complexity : ""}
                    >
                      <MenuItem value="LOW">BAJA</MenuItem>
                      <MenuItem value="MEDIUM">MEDIA</MenuItem>
                      <MenuItem value="HIGH">ALTA</MenuItem>
                    </Select>
                  </FormControl>
                  <div className="d-flex align-items-center flex-column justify-content-center">
                  {src && (
                        <>
                  <Grid item md={6}>
                          <div className="font-weight-bold text-center font-size-xl mb-4">
                            Resultado final
                          </div>
                          {!src && !crop && (
                            <Alert
                              className="alerts-alternate font-size-lg text-warning p-4 m-4"
                              severity="warning"
                            >
                              Haz Click en el boton de recortar!
                            </Alert>
                          )}
                          {crop && (
                            <div
                              className="shadow-xxl m-4 rounded-lg overflow-hidden w-100 img-preview d-flex justify-content-center"
                              style={{ height: 340 }}
                            >
                              <img
                                className="rounded-lg img-fit-container"
                                src={crop}
                                alt="..."
                              />
                            </div>
                          )}
                          {src && !crop && (
                            <div
                              className="shadow-xxl m-4 rounded-lg overflow-hidden w-100 img-preview d-flex justify-content-center"
                              style={{ height: 340, width:340 }}
                            >
                              <img
                                className="rounded-lg img-fit-container"
                                src={src}
                                alt="..."
                              />
                            </div>
                          )}
                        </Grid>
                        </>)}
                    <div className="pb-4">
                      <input
                        accept="image/*"
                        onChange={onChange}
                        className="d-none"
                        id="upload-image-for-cropping"
                        multiple
                        type="file" 
                      />
                      <label
                        className="d-block text-center mt-4"
                        htmlFor="upload-image-for-cropping"
                      >
                        <Button
                          className="btn-primary font-weight-bold"
                          size="large"
                          component="span"
                        >
                          Cargar imagen
                        </Button>
                      </label>

                      <div className="divider w-100 my-4" />
                      {src && (
                        <>
                          <Button
                            size="large"
                            className="btn-outline-danger m-2"
                            onClick={cropImage}
                            disabled={src.startsWith('https://firebasestorage')}
                          >
                            Recortar imagen
                          </Button>
                        </>
                      )}
                    </div>
                    {src && (
                      <>
                        <Cropper
                          style={{ height: 400, width: "60%" }}
                          preview=".img-preview"
                          aspectRatio={1 / 1}
                          guides={false}
                          src={src}
                          ref={(cropper) => {
                            setCropper(cropper);
                          }}
                          
                        />
                      </>
                    )}
                  </div>
                  
                  
                </div>
              </div>
            </DialogContent>

            <DialogActions className="p-4 bg-white-10">
              <Button
                className="btn-neutral-primary"
                onClick={() => handleCancelEditCompetence()}
              >
                Cancelar
              </Button>{" "}
              <Button className="btn-primary ml-auto" type="submit">
                Guardar
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </Fragment>
    );
  }
}

export default LoadCompetences;
