import React, { useState, useCallback, useContext, useEffect } from "react";
import { GET_COMPETENCES_BY_USER_ID } from "data/queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "../../../context/UserContext";
import { useQuery } from "@apollo/react-hooks";
import {
  Table,
  LinearProgress,
  Card,
  CardContent,
  Button,
  Tooltip,
  Dialog,
  Grid,
  Chip
} from "@material-ui/core";


export default function ModalSelectCompetence({
  modalCompetence,
  toggleCompetence,
  competenceSelected,
  setCompetenceSelected,
}) {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  const { loading: loadingCompetences, error: errorCompetences, data: dataCompetences, networkStatus: networkStatusCompetences, refetch: refetchCompetences } = useQuery(GET_COMPETENCES_BY_USER_ID,
    {
      variables: { id: dbuser.getUserByUid.id },
      skip: !dbuser.getUserByUid.id,
      notifyOnNetworkStatusChange: true,
    })
  const onSelectCompetence = useCallback((competence) => {
    setCompetenceSelected(competence);
    toggleCompetence();
  });
  const variables = [{
    variable: "Todas las competencias",
    color: "grey"
  },{
    variable: "Emocionales",
    color: "#FF3146"
  }, {
    variable: "Transversales",
    color: "#00029E"
  }, {
    variable: "Sociales",
    color: "#E6D600"
  }, {
    variable: "Digitales",
    color: "#00ACDF"
  }
  ]

  const [variablesList, setVariablesList] = useState([]);  
  const [variablesListFilter, setVariablesListFilter] = useState([]);  

  useEffect(()=> {
    if (dataCompetences!=null){
      setVariablesList([...dataCompetences.getCompetencesByUserId])
      setVariablesListFilter([...dataCompetences.getCompetencesByUserId])
    }
  },[dataCompetences])

  const handleClickFilter = element =>() => {
    if (element.color != "grey"){
    const filteredVariables = variablesList.filter(o=>
      Object.keys(o).some(k => k === "color" &&
        String(o[k]).toLowerCase().includes(element.color.toLowerCase())
      )
    );
    setVariablesListFilter(filteredVariables)  
    }else{
      setVariablesListFilter(variablesList)
    }
  }

  if (dataCompetences) {

    return (
      <>
        <Dialog
          open={modalCompetence}
          onClose={toggleCompetence}
          maxWidth="xl"
          classes={{ paper: "modal-content rounded-lg" }}
        >
          <div className="text-center p-5 mb-3">
            <h4 className="text-center mb-4">
              Selecciona la competencia a entregar
            </h4>
            <div className="mb-spacing-6">
              {
                variables.map((element,index)=> {
                 return( <Chip  variant="outlined" 
                                style={{ backgroundColor: element.color, color: "white", borderColor: element.color, marginRight: "10px", fontSize:18}}
                                label={element.variable}
                                key={element.color}
                                onClick = {handleClickFilter(element)}
                                />)
                })
              }
            </div>

            <div className="mb-spacing-6">
              <Grid container spacing={6}>
                {variablesListFilter.map((element, index) => {
                  return (
                    <Grid item md={2}>
                      <Card className="shadow-xxl" style={{ borderRadius: "12px", border: `solid 4px ${element.color}` }}>
                        <a
                          href="#/"
                          onClick={(e) => e.preventDefault()}
                          className="card-img-wrapper rounded"
                        >
                          <div className="img-wrapper-overlay">
                            <div className="overlay-btn-wrapper">
                              <div className="font-size-lg font-weight-bold">
                                {element.name}
                              </div>

                              <Button
                                size="small"
                                className="btn-success btn-pill px-4 hover-scale-sm mb-2"
                                onClick={() => onSelectCompetence(element)}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Seleccionar
                            </span>
                          </Button>
                          <Tooltip
                              classes={{ tooltip: 'text-center p-3 tooltip-secondary' }}
                              arrow
                              placement="top"
                              title={element.description}>
                          <Button
                            size="small"
                            className="btn-info btn-pill px-4 hover-scale-sm"
                          >
                            <span className="btn-wrapper--icon">
                              <FontAwesomeIcon icon={["fas", "info"]} />
                            </span>
                            <span className="btn-wrapper--label">
                              Información
                            </span>
                          </Button>
                          </Tooltip>
                        </div>
                      </div>
                      {/* <div className="card-badges">
                        <div className="badge badge-pill badge-neutral-info text-info">
                          Información
                        </div>
                      </div> */}
                          <img
                            src={element.urlImage}
                            className="card-img-top rounded"
                            alt="..."
                          />
                        </a>

                      </Card>
                      <h5 className="text-center font-weight-bold mt-4">
                        {element.name}
                      </h5>


                    </Grid>
                  )
                })}
              </Grid>
            </div>

            <div className="pt-4" style={{ marginBottom: 20 }}>
              <Button
                onClick={toggleCompetence}
                className="btn-neutral-dark btn-pill mx-1"
              >
                <span className="btn-wrapper--label">Atrás</span>
              </Button>
            </div>
          </div>
        </Dialog>
      </>
    );
  } else {
    return null;
  }
}
