// import React, {useState, useContext} from "react";

// import clsx from "clsx";
// import { PageTitle } from "layout-components";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Grid, Card, Button, List, Table, ListItem, LinearProgress } from "@material-ui/core";

// import { UserContext } from "../../context/UserContext";
// export default function HomeUser() {
//     const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
//     const [activeTab, setActiveTab] = useState("0");

//     const toggle = (tab) => {
//       if (activeTab !== tab) setActiveTab(tab);
//     };
// if(dbuser){
//     return (
//         <>
//           <PageTitle
//             titleHeading="Inicio"
//             titleDescription="¡Descubre todo lo que puedes hacer en Climind!"
//           />
//           <Grid container spacing={6}>

//         <Grid item lg={12}>
//           <Card className="shadow-xxl p-3">
//             <List className="nav-tabs nav-tabs-primary d-flex">
//               <ListItem
//                 button
//                 disableRipple
//                 selected={activeTab === "0"}
//                 onClick={() => {
//                   toggle("0");
//                 }}
//               >
//                 Mi Perfil
//               </ListItem>
//               <ListItem
//                 button
//                 disableRipple
//                 selected={activeTab === "1"}
//                 onClick={() => {
//                   toggle("1");
//                 }}
//               >
//                 Editar perfil
//               </ListItem>
//               <ListItem
//                 button
//                 disableRipple
//                 selected={activeTab === "2"}
//                 onClick={() => {
//                   toggle("2");
//                 }}
//               >
//                 Ajustes y Privacidad
//               </ListItem>
//             </List>

//             <div
//               className={clsx("tab-item-wrapper", {
//                 active: activeTab === "0",
//               })}
//               index={0}
//             >
//               <Grid container>
//                 <Grid item xs={12}>
//                 <div className="text-center p-4">
//                   <div className="avatar-icon-wrapper rounded-circle mx-auto">
//                     <div className="d-block p-0 avatar-icon-wrapper rounded-circle m-0 border-3 border-danger">
//                       <div className="rounded-circle border-3 border-white overflow-hidden">
//                         <img alt="..." className="img-fluid" src={dbuser.getUserByUid.urlImage}/>
//                       </div>
//                     </div>
//                   </div>
//                   <h4 className="font-size-lg font-weight-bold my-2" style={{fontSize:"large", color:"purple"}}>
//                    {dbuser.getUserByUid.name}
//                   </h4>
//                   <h4 className="font-size-lg font-weight-bold my-2" style={{fontSize:"large"}}>
//                   {dbuser.getUserByUid.email}
//                   </h4>
//                   <p className="mb-4">
//                     Aquí puedes ver los grupos a los que perteneces
//                   </p>
//                   <div className="text-center my-4">
//                     <div className="badge badge-pill badge-neutral-first text-first mx-1" style={{fontSize:"large"}}>
//                       UX Designer
//                     </div>
//                     <div className="badge badge-pill badge-neutral-warning text-warning mx-1" style={{fontSize:"large"}}>
//                       Software Architect
//                     </div>
//                     <div className="badge badge-pill badge-neutral-danger text-danger mx-1" style={{fontSize:"large"}}>
//                       React
//                     </div>
//                   </div>

//                   {/* <div className="d-flex justify-content-between text-center line-height-sm font-size-lg text-black-50">
//                     <div>
//                       <div className="text-second font-weight-bold">44%</div>
//                       <small>Profile completion</small>
//                     </div>
//                     <div>
//                       <div className="text-second font-weight-bold">$12,547</div>
//                       <small>Lifetime earnings</small>
//                     </div>
//                     <div>
//                       <div className="text-second font-weight-bold">347</div>
//                       <small>Projects completed</small>
//                     </div>
//                   </div> */}
//                   <Grid container spacing={6} className="d-flex align-items-center">
//           <Grid item xl={6}>
//             <div className="text-black-50 pb-3">Project management</div>
//             <div className="d-flex justify-content-center">
//               <div>
//                 <div className="text-center font-size-lg px-5">
//                   <span className="font-weight-bold">456</span>
//                   <small className="text-black-50 d-block">Visitors</small>
//                 </div>
//               </div>
//               <div>
//                 <div className="text-center font-size-lg px-5">
//                   <span className="font-weight-bold text-first">+34</span>
//                   <small className="text-black-50 d-block">New users</small>
//                 </div>
//               </div>
//               <div>
//                 <div className="text-center font-size-lg px-5">
//                   <span className="font-weight-bold">56%</span>
//                   <small className="text-black-50 d-block">Increase</small>
//                 </div>
//               </div>
//             </div>
//           </Grid>
//           <Grid item xl={6}>
//             <div className="text-black-50 pb-3">Current progress</div>
//             <LinearProgress
//               variant="determinate"
//               className="progress-animated-alt progress-bar-rounded progress-bar-success"
//               value={34}
//             />
//             <div className="align-box-row mt-1 text-muted">
//               <div className="font-weight-bold">Orders</div>
//               <div className="ml-auto">
//                 <div className="font-size-lg font-weight-bold text-success">
//                   348
//                 </div>
//               </div>
//             </div>
//           </Grid>
//         </Grid>
//                 </div>
//                 </Grid>
//               </Grid>
//             </div>

//             <div
//               className={clsx("tab-item-wrapper", {
//                 active: activeTab === "1",
//               })}
//               index={1}
//             >
//                 <Grid container spacing={4}>
//                 <Grid item md={4}>
//                   <div className="text-center my-5">
//                     <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-primary shadow-primary-sm text-primary mb-2 d-90">
//                       <FontAwesomeIcon
//                         icon={["fas", "building"]}
//                         className="d-flex align-self-center font-size-xxl"
//                       />
//                     </div>
//                     <h6 className="font-weight-bold font-size-xxl mb-1 mt-3 text-primary">
//                       Mi información
//                     </h6>
//                   </div>
//                 </Grid>
//                 <Grid item md={8}>
//                     {/* <BasicInformation companyData={companyData} companyId={companyId}/> */}
//                 </Grid>

//               </Grid>
//               <Grid container spacing={4}>
//                 <Grid item md={4}>
//                   <div className="text-center my-5">
//                     <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-primary shadow-primary-sm text-primary mb-2 d-90">
//                       <FontAwesomeIcon
//                         icon={["fas", "building"]}
//                         className="d-flex align-self-center font-size-xxl"
//                       />
//                     </div>
//                     <h6 className="font-weight-bold font-size-xxl mb-1 mt-3 text-primary">
//                       Información Básica
//                     </h6>
//                   </div>
//                 </Grid>
//                 <Grid item md={8}>
//                     {/* <BasicInformation companyData={companyData} companyId={companyId}/> */}
//                 </Grid>

//               </Grid>
//               <Grid container spacing={6}>
//                 <Grid item md={4}>
//                   <div className="text-center my-5">
//                     <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-primary shadow-primary-sm text-primary mb-2 d-90">
//                       <FontAwesomeIcon
//                         icon={["fas", "plus"]}
//                         className="d-flex align-self-center font-size-xxl"
//                       />
//                     </div>
//                     <h6 className="font-weight-bold font-size-xxl mb-1 mt-3 text-primary">
//                       Información Complementaria
//                     </h6>
//                   </div>
//                 </Grid>
//                 <Grid item md={8}>
//                     {/* <AdditionalInformation companyData={companyData} companyId={companyId}/> */}
//                 </Grid>
//               </Grid>
//             </div>

//             <div
//               className={clsx("tab-item-wrapper", {
//                 active: activeTab === "2",
//               })}
//               index={2}
//             >
//               <div className="text-center my-5">
//                 <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-primary shadow-primary-sm text-primary mb-2 d-90">
//                   <FontAwesomeIcon
//                     icon={["far", "gem"]}
//                     className="d-flex align-self-center font-size-xxl"
//                   />
//                 </div>
//                 <h6 className="font-weight-bold font-size-xxl mb-1 mt-3 text-primary">
//                   Sección en desarrollo
//                 </h6>
//                 <p className="text-black-50 font-size-lg mb-0">
//                   Estamos desarrollando lo mejor para tu empresa y tus empleados.
//                 </p>
//               </div>
//             </div>
//           </Card>
//         </Grid>
//       </Grid>

//         </>
//       );
// }else{
//     return null
// }

// }

import React, { useEffect, useState, useContext, useCallback, useM } from "react";

import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  Container,
  InputAdornment,
  ButtonGroup,
  Card,
  Button,
  List,
  ListItem,
  TextField,
  Dialog,
  Snackbar
} from "@material-ui/core";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import firebase from 'firebase'
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDropzone } from "react-dropzone";
import CountUp from "react-countup";
import { EDIT_USER_BASIC_INFO, CHANGE_PASSWORD_USER, SAVE_USER_IMAGE } from "data/mutations";
import { GET_USER_BY_UID, GETS_FOR_GENERAL } from 'data/queries'; 
import CloseTwoToneIcon from "@material-ui/icons/CloseTwoTone";
import PublishTwoToneIcon from "@material-ui/icons/PublishTwoTone";
import AccountCircleTwoToneIcon from "@material-ui/icons/AccountCircleTwoTone";
import CheckIcon from "@material-ui/icons/Check";
import { UserContext } from "../../context/UserContext";
import General from "./components/General"
import Compensations from "./components/Compensations"
import EditProfile from "./components/EditProfile"
import Privacity from "./components/Privacity"
import Groups from "./components/Groups"
import UploadImage from "./UploadImage";
import { useQuery, useMutation } from "@apollo/react-hooks";
import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function LivePreviewExample() {
  const { currentUser, dbuser, getUserWithFirebase } = useContext(UserContext);
  const [inputBg, setInputBg] = useState(false);
  const toggleInputBg = () => setInputBg(!inputBg);
  const [picture, setPicture] = useState(null)
  const [visible, setVisible] = useState(false)
  const [crop, setCrop] = useState(null)
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [saveUserImage, { data: mutationSaveUserImageData, loading: mutationSaveUserImageLoading, error: mutationSaveUserImageError }] = useMutation(SAVE_USER_IMAGE);
  const { loading, error, data, networkStatus } = useQuery(GETS_FOR_GENERAL, {
    variables: { id: dbuser.getUserByUid.id },
    skip: !dbuser.getUserByUid.id,
    notifyOnNetworkStatusChange: true,
  });
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [activeTab2, setActiveTab2] = useState("1");

  const toggle2 = (tab) => {
    if (activeTab2 !== tab) setActiveTab2(tab);
  };

  const [files, setFiles] = useState([]);
  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
    getRootProps,
    getInputProps,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  // const thumbs = files.map((file) => (
  //   <div
  //     key={file.name}
  //     className="rounded-circle avatar-image overflow-hidden d-140 bg-neutral-success text-center font-weight-bold text-success d-flex justify-content-center align-items-center"
  //   >
  //     <img
  //       className="img-fluid img-fit-container rounded-sm"
  //       src={file.preview}
  //       alt="..."
  //     />
  //   </div>
  // ));

  const showModal = useCallback( () => {
    setVisible(true)
  })
  const handleCancel = useCallback( (e) => {
    setVisible(false)
  })
  const handleOk = (e) => {

    const storageRef = firebase.storage().ref(`/${dbuser.getUserByUid.companyId}/${dbuser.getUserByUid.id}`);
    console.log(crop)
    const pictureUploaded = storageRef.putString(crop, 'data_url').then( async function(snapshot) {
      try{
      await storageRef.getDownloadURL().then(async (url) => {
        let dataInput = {
          id: dbuser.getUserByUid.id,
          urlImage: url
        };
        setPicture(url)
        let uid = dbuser.getUserByUid.uid
        try{
          await saveUserImage({variables:{input:dataInput}, refetchQueries: {query: GET_USER_BY_UID,variables: {uid,uid}}})
          getUserWithFirebase()
        }catch (e){
          console.log(e)
        }

        //window.location.reload()
      })
    }catch(e){
      
      console.log(e)
    }
    });
    setOpenSnackbar(true, () => {
      setTimeout(function () {
        setOpenSnackbar(false);
      }, 5000); //5 Second delay
    });
    setVisible(false)
  }



  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  if (dbuser && data) {
    return (
      <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        key={"top,center"}
        open={openSnackbar}
        // classes={{ root: "toastr-primary" }}
        autoHideDuration={4000}
        onClose={() => setOpenSnackbar(false)}
        message={"entregado correctamente"}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          style={{ color: "white" }}
        >
          Imagen cambiada correctamente
        </Alert>
      </Snackbar>
        <div className="app-inner-content-layout">
          <div className="app-inner-content-layout--main bg-white p-0">
            <div className="hero-wrapper rounded-bottom shadow-xxl bg-composed-wrapper bg-second">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div
                  className="bg-composed-wrapper--image rounded-bottom opacity-3"
                  style={{ backgroundColor: "purple" }}
                />
                <div className="bg-composed-wrapper--bg rounded-bottom bg-deep-sky opacity-4" />
                <div className="bg-composed-wrapper--content px-3 pt-5">
                  <Container className="pt-4" style={{ width: "80%" }}>
                    <div className="d-block d-md-flex align-items-start">
                      <div className="dropzone rounded-circle shadow-sm-dark mr-md-3 ">
                        <div
                          {...getRootProps({
                            className: "dropzone-upload-wrapper",
                          })}
                        >
                          <input {...getInputProps()} />
                          <div className="dropzone-inner-wrapper d-140 rounded-circle dropzone-avatar">
                            <div className="avatar-icon-wrapper d-140 rounded-circle m-2">
                              <Button
                                onClick={showModal}
                                className="btn-first avatar-button badge shadow-sm-dark btn-icon badge-position badge-position--bottom-right border-0 text-indent-0 d-40 badge-circle badge-first text-white"
                              >
                                <PublishTwoToneIcon className="d-20" />
                              </Button>

                              <div>
                                {isDragAccept && (
                                  <div className="rounded-circle overflow-hidden d-140 bg-success text-center font-weight-bold text-white d-flex justify-content-center align-items-center">
                                    <CheckIcon className="d-40" />
                                  </div>
                                )}
                                {isDragReject && (
                                  <div className="rounded-circle overflow-hidden d-140 bg-danger text-center font-weight-bold text-white d-flex justify-content-center align-items-center">
                                    <CloseTwoToneIcon className="d-60" />
                                  </div>
                                )}
                                {!isDragActive && (
                                  <div className="rounded-circle overflow-hidden d-140 bg-second text-center font-weight-bold text-white-50 d-flex justify-content-center align-items-center">
                                    <AccountCircleTwoToneIcon className="d-50" />
                                  </div>
                                )}
                              </div>
                              <div
                                key={dbuser.getUserByUid.name}
                                className="rounded-circle avatar-image overflow-hidden d-140 bg-neutral-success text-center font-weight-bold text-success d-flex justify-content-center align-items-center"
                              >
                                <img
                                  className="img-fluid img-fit-container rounded-sm  rounded-circle"
                                  // style={{
                                  //   borderRadius: "50%",
                                  //   border: "solid 6px gold",
                                  // }}
                                  src={dbuser.getUserByUid.urlImage ? dbuser.getUserByUid.urlImage : require("../../assets/images/climind/default_avatar.png")}
                                  alt="..."
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex text-white flex-column pl-md-2">
                        <div className="d-block d-md-flex align-items-center">
                          <div
                            className="my-3 my-md-0"
                            style={{ marginRight: 50 }}
                          >
                            <div className="d-flex align-items-end">
                              <div className="font-size-xxl font-weight-bold">
                                @{dbuser.getUserByUid.username}
                              </div>
                            </div>
                            <div className="font-weight-bold mt-1 font-size-lg text-white-50">
                              {dbuser.getUserByUid.name}
                            </div>
                          </div>
                          {/* <div className="ml-auto">
                            <div
                              className="badge badge-pill badge-neutral-first text-first mx-1"
                              style={{ fontSize: "large" }}
                            >
                              UX Designer
                            </div>
                            <div
                              className="badge badge-pill badge-neutral-warning text-warning mx-1"
                              style={{ fontSize: "large" }}
                            >
                              Software Architect
                            </div>
                            <div
                              className="badge badge-pill badge-neutral-danger text-danger mx-1"
                              style={{ fontSize: "large" }}
                            >
                              React
                            </div>
                          </div> */}
                        </div>
                        <div className="d-flex font-size-xl py-4 align-items-center">
                          <div className="mr-2">
                            {dbuser.getUserByUid.score}{" "}
                            <span className="font-size-sm text-white-50">
                              Puntos
                            </span>
                          </div>
                          <div className="mr-2">
                          {data.getAnswerEmotionsSendedByUserIdLast30.length  + 
                          data.getAnswersSendedByUserIdLast30.length +
                          data.getRecognitionsSendedByUserIdLast30.length +
                          data.getImprovementProposalsSendedByUserIdLast30.length +
                          data.getCongratulationsSendedByUserIdLast30.length +
                          data.getGratitudesSendedByUserIdLast30.length }{" "}
                            <span className="font-size-sm text-white-50">
                              acciones realizadas en los últimos 30 días
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <List className="d-flex nav-tabs justify-content-center nav-tabs-success tabs-animated tabs-animated-shadow my-5">
                      <ListItem
                        button
                        selected={activeTab2 === "1"}
                        className="bg-white-10 mx-3 rounded-lg"
                        onClick={() => {
                          toggle2("1");
                        }}
                      >
                        <span className="d-flex justify-content-center text-center font-size-lg text-white px-2 py-1">
                          Información
                        </span>
                        <div className="divider" />
                      </ListItem>

                      <ListItem
                        button
                        selected={activeTab2 === "2"}
                        className="bg-white-10 mx-3 rounded-lg"
                        onClick={() => {
                          toggle2("2");
                        }}
                      >
                        <span className="d-flex justify-content-center text-center font-size-lg text-white px-2 py-1">
                          Panel de compensaciones
                        </span>
                        <div className="divider" />
                      </ListItem>
                      <ListItem
                        button
                        selected={activeTab2 === "3"}
                        className="bg-white-10 mx-3 rounded-lg"
                        onClick={() => {
                          toggle2("3");
                        }}
                      >
                        <span className="d-flex justify-content-center text-center font-size-lg text-white px-2 py-1">
                          Editar Perfil
                        </span>
                        <div className="divider" />
                      </ListItem>

                      <ListItem
                        button
                        selected={activeTab2 === "4"}
                        className="bg-white-10 mx-3 rounded-lg"
                        onClick={() => {
                          toggle2("4");
                        }}
                      >
                        <span className=" d-flex justify-content-center text-center font-size-lg text-white px-2 py-1">
                        Privacidad
                        </span>
                        <div className="divider" />
                      </ListItem>

                      <ListItem
                        button
                        selected={activeTab2 === "5"}
                        className="bg-white-10 mx-3 rounded-lg"
                        onClick={() => {
                          toggle2("5");
                        }}
                      >
                        <span className="d-flex justify-content-center text-center font-size-lg text-white px-2 py-1">
                        Grupos y Departamentos
                        </span>
                        <div className="divider" />
                      </ListItem>

                     
                    </List>
                  </Container>
                </div>
              </div>
            </div>
            <Container className="z-over py-5">

              <div
                className={clsx("tab-item-wrapper overflow-visible d-none", {
                  "d-block active": activeTab2 === "1",
                })}
                index={1}
              >
                <General data={data}/>
              </div>
              <div
                className={clsx("tab-item-wrapper overflow-visible d-none", {
                  "d-block active": activeTab2 === "2",
                })}
                index={2}
              >
                <Compensations data={data}/>
              </div>
              <div
                className={clsx("tab-item-wrapper overflow-visible d-none", {
                  "d-block active": activeTab2 === "3",
                })}
                index={3}
              >
                  <EditProfile/>
              </div>
              <div
                className={clsx("tab-item-wrapper overflow-visible d-none", {
                  "d-block active": activeTab2 === "4",
                })}
                index={4}
              >
                  <Privacity/>
              </div>
              <div
                className={clsx("tab-item-wrapper overflow-visible d-none", {
                  "d-block active": activeTab2 === "5",
                })}
                index={5}
              >
                  <Groups/>
              </div>             
            </Container>
          </div>
        </div>
        <Dialog
          open={visible}
          onClose={handleCancel}
          maxWidth="lg"
          width="80%"
          classes={{ paper: "modal-content rounded-lg" }}
        >
          <div className="text-center p-5">
            
            <UploadImage setCrop={setCrop}/>
            <div className="pt-4">
              <Button
                onClick={handleOk}
                disabled={!crop}
                className="btn-primary btn-pill mx-1">
                <span className="btn-wrapper--label">Seleccionar</span>
              </Button>
              <Button onClick={handleCancel} className="btn-danger btn-pill mx-1">
                <span className="btn-wrapper--label">Atras</span>
              </Button>
            </div>
          </div>
          
        </Dialog>
      </>
    );
  } else {
    return null;
  }
}
