import React, { useState,useContext } from "react";

import { Grid, Card, Button, List,ListItem, Table } from "@material-ui/core";
import clsx from "clsx";


import DepartmentsTable from "./DepartmentsTable";
import GroupsTable from "./GroupsTable";

export default function TeamsTable({compId}) {
  const [activeTab, setActiveTab] = useState("0");


  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };



    return (
      <>
        <Grid container spacing={6}>
          <Grid item lg={12}>
            <Card className="shadow-xxl p-3" >
              <List className="nav-tabs nav-tabs-primary d-flex">
                <ListItem
                  button
                  disableRipple
                  selected={activeTab === "0"}
                  onClick={() => {
                    toggle("0");
                  }}
                  key={0}
                >
                  Departamentos
                </ListItem>
                <ListItem
                  button
                  disableRipple
                  selected={activeTab === "1"}
                  onClick={() => {
                    toggle("1");
                  }}
                  key={1}
                >
                  Grupos
                </ListItem>
              </List>

              <div
                className={clsx("tab-item-wrapper", {
                  active: activeTab === "0",
                })}
                index={0}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <div className="m-4">
                      <DepartmentsTable compId={compId}/>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div
                className={clsx("tab-item-wrapper", {
                  active: activeTab === "1",
                })}
                index={1}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <div className="m-4">
                      <GroupsTable compId={compId}/>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Card>
          </Grid>
        </Grid>
      </>
    );
}
