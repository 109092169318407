import React, { useState, useCallback,useEffect, useContext } from "react";
import { GET_COMPETENCES_BY_USER_ID } from "data/queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "../../../../context/UserContext";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  Table,
  LinearProgress,
  Card,
  CardContent,
  Button,
  Tooltip,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { EDIT_USER } from "../../../../data/mutations";

export default function ModalEditEmployee({
  openEditEmployee,
  toggleEditEmployee,
  dataEmployee,
}) {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);

  console.log(dataEmployee)
  console.log(dataEmployee.name)
  const [editUser, { data }] = useMutation(EDIT_USER);
  const [values, setValues] = useState({
    name: "",
    surname: "",
    dni: "",
    email: ""
  });
  
  useEffect(()=>{
    if(dataEmployee != null){
      setValues({
        name: dataEmployee.name,
        surname: dataEmployee.surname,
        dni: dataEmployee.dni,
        email: dataEmployee.email
      })
    }
  },[dataEmployee])

  let thumb = "";
  const { name, surname, dni, email } = values;
  console.log(values)

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <>
      <Dialog
        open={openEditEmployee}
        onClose={toggleEditEmployee}
        classes={{ paper: "modal-content rounded-lg" }}
      >
        <DialogTitle className="bg-white-10">Editar Empleado</DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
            <div className="text-center my-auto">
            <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-primary shadow-primary-sm text-primary mb-2 d-90">
                  <FontAwesomeIcon
                    icon={["fas", "building"]}
                    className="d-flex align-self-center font-size-xxl"
                  />
                </div>
                <h6 className="font-weight-bold font-size-xxl mb-1 mt-3 text-primary">
                      Información Básica
                    </h6>
                    </div>
              <Card
                className="p-4 mt-4"
                style={{ backgroundColor: "#eceef7" }}
                elevation={0}
              >
                
                <div>
                  <TextField
                    fullWidth
                    className="m-2"
                    id="outlined-multiline-static-1"
                    name="name"
                    label="Nombres"
                    placeholder="Escribe aqui..."
                    defaultValue={name}
                    onChange={handleInputChange}
                    variant="outlined"
                  />
                  <TextField
                    fullWidth
                    className="m-2"
                    id="outlined-multiline-static-2"
                    name="surname"
                    label="Apellidos"
                    placeholder="Escribe aqui..."
                    defaultValue={surname}
                    onChange={handleInputChange}
                    variant="outlined"
                  />
                  <TextField
                    fullWidth
                    className="m-2"
                    id="outlined-multiline-static-3"
                    name="dni"
                    label="DNI"
                    placeholder="Escribe aqui..."
                    defaultValue={dni}
                    onChange={handleInputChange}
                    variant="outlined"
                  />
                  <TextField
                    fullWidth
                    className="m-2"
                    id="outlined-multiline-static-4"
                    name="email"
                    label="Email"
                    placeholder="Escribe aqui..."
                    defaultValue={email}
                    onChange={handleInputChange}
                    variant="outlined"
                  />
                </div>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="p-4 bg-white-10">
          <Button
            className="btn-neutral-primary"
            onClick={() => toggleEditEmployee()}
          >
            Cancelar
          </Button>{" "}
          <Button
            className="btn-primary ml-auto"
            onClick={() => {
              console.log(dbuser)
              let dataInput = {
                'name': name,
                'surname': surname,
                'dni': dni,
                'email': email
              };
              console.log(dataInput)
              editUser({variables:{id:dataEmployee.id ,input:dataInput}});
              setValues({
                'name': '',
                'surname': '',
                'dni': '',
                'email': ''
              })
              toggleEditEmployee();
            }}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
