import React, { useState, useEffect } from 'react'
import firebase from 'firebase'
import { GET_USER_BY_UID, GET_IF_MANAGER_USER_BY_ID } from '../data/queries/index';
import { useLazyQuery } from "@apollo/client";

export const UserContext = React.createContext({})

export const UserProvider = ({ children }) => {

    const [currentUser, setCurrentUser] = useState(null)
    const [usuario, setUsuario] = useState(null)
    const [reloadUserContext, setReloadUserContext] = useState(false)

    //QUERY QUE SACA LA INFORMACIÓN DEL USUARIO DE LA BASE DE DATOS
    var [getUserByUid, { data: dbuser, loading, error }] = useLazyQuery(GET_USER_BY_UID, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {  
            setUsuario(data)
        }
    });
    
    //FUNCION QUE COMPRUEBA SI EXISTE UNA SESIÓN ACTIVA DE ALGUN USUARIO Y SI EXISTE SACA LA INFORMACIÓN DE LA BASE DE DATOS

    const restoreUser = () => {
        setUsuario(null)
    }
    const getUserWithFirebase = () => {
        firebase.auth().onAuthStateChanged( (user) => {
            setCurrentUser(user)
            if (user) {
                //HAY USUARIO EN FIREBASE
                getUserByUid({variables: {uid: user.uid}})
            } else {
                //NO HAY USUARIO EN FIREBASE
                setUsuario(null)
            }
        });
    }
    useEffect(() => {
        getUserWithFirebase()   
    },[])
    return (
        <UserContext.Provider value={{ currentUser, dbuser: usuario, restoreUser, getUserWithFirebase, error, loading }}>
            {children}
        </UserContext.Provider>
    )
}

// export const UserProvider = ({ children }) => {

//     const [currentUser, setCurrentUser] = useState(null)
//     const [usuario, setUsuario] = useState(null)
//     const [reloadUserContext, setReloadUserContext] = useState(false)

//     //QUERY QUE SACA LA INFORMACIÓN DEL USUARIO DE LA BASE DE DATOS
//     var [getUserByUid, { data: dbuser, loading, error }] = useLazyQuery(GET_USER_BY_UID, {
//         fetchPolicy: "network-only",
//         onCompleted: (data) => {  
//             setUsuario(data)
//         }
//     });
    
//     //FUNCION QUE COMPRUEBA SI EXISTE UNA SESIÓN ACTIVA DE ALGUN USUARIO Y SI EXISTE SACA LA INFORMACIÓN DE LA BASE DE DATOS

//     useEffect(() => {
//         firebase.auth().onAuthStateChanged( (user) => {
//             setCurrentUser(user)
//             if (user) {
//                 //HAY USUARIO EN FIREBASE
//                 getUserByUid({variables: {uid: user.uid}})
//             } else {
//                 //NO HAY USUARIO EN FIREBASE
//                 setUsuario(null)
//             }
//             setReloadUserContext(false)
//         });
//     }, [reloadUserContext]);

//     return (
//         <UserContext.Provider value={{ currentUser, dbuser:usuario, setReloadUserContext, error }}>
//             {children}
//         </UserContext.Provider>
//     )
// }
