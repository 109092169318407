import React, { useState, useCallback, useContext } from "react";
import { PageTitle } from "layout-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Table,
  LinearProgress,
  Card,
  CardContent,
  Button,
  Tooltip,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Input,
  Select,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Formik } from "formik";
import { UserContext } from "../../context/UserContext";
import PerfectScrollbar from "react-perfect-scrollbar";
import GridOnTwoToneIcon from "@material-ui/icons/GridOnTwoTone";
import NotificationsActiveTwoToneIcon from "@material-ui/icons/NotificationsActiveTwoTone";
import SettingsTwoToneIcon from "@material-ui/icons/SettingsTwoTone";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import BusinessCenterTwoToneIcon from "@material-ui/icons/BusinessCenterTwoTone";
import LocalLibraryTwoToneIcon from "@material-ui/icons/LocalLibraryTwoTone";
import MaskedInput from "react-input-mask";

import PropTypes from "prop-types";
import Cropper from "react-cropper";
import Alert from "@material-ui/lab/Alert";
import firebase from "firebase";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { NEW_AWARD, EDIT_AWARD } from "data/mutations";
import { GET_ACHIEVED_AWARDS_BY_USERID } from "data/queries";
var moment = require("moment-timezone");
export default function Bought() {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  const [crop, setCrop] = useState(null);
  const [cropper, setCropper] = useState(null);
  const [src, setSrc] = useState(null);
  const [modalAddPrice, setModalAddPrice] = useState(false);
  const [price, setPrice] = useState(null);
  const [modalInfo, setModalInfo] = useState(false);
  const [infoSelected, setInfoSelected] = useState(null);
  const toggleInfo = () => setModalInfo(!modalInfo);
  const toggleAddPrice = () => setModalAddPrice(!modalAddPrice);
  //   const [editAward,{data: dataEditAward,error: errorEditAward,loading: loadingEditAward}] = useMutation(EDIT_AWARD);
  //   const [saveAward, { data: dataAward, error: errorAward, loading: loadingAward }] = useMutation(NEW_AWARD);
  const {
    loading: loadingAwards,
    error: errorAwards,
    data: dataAwards,
    networkStatus: networkStatusAwards,
    refetch: refetchAwards,
  } = useQuery(GET_ACHIEVED_AWARDS_BY_USERID, {
    variables: { id: dbuser.getUserByUid.id },
    skip: !dbuser.getUserByUid.id,
    notifyOnNetworkStatusChange: true,
  });

  const onChange = useCallback((e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setSrc(reader.result);
    };
    reader.readAsDataURL(files[0]);
  });

  const cropImage = useCallback(() => {
    if (typeof cropper.getCroppedCanvas() === "undefined") {
      return;
    }
    setCrop(cropper.getCroppedCanvas().toDataURL());
  });

  if (dataAwards) {
    console.log(dataAwards);
    return (
      <>
        <Grid item xl={12}></Grid>
        <Grid item xl={12}>
          <div className="text-center mb-20">
            <h5 className="">Premios adquiridos</h5>
            {/* <h6 style={{color:"purple"}}>Tienes 345 puntos</h6> */}
          </div>
        </Grid>
        <Grid item xl={12}>
          <div className="mb-spacing-12">
            <Grid container spacing={4}>
              {/* {dataCompetences.getCompetencesByUserId.map((element, index) => { */}
              {/* return( */}
              {dataAwards.getAchievedAwardsById.map((element, index) => {
                console.log(element.awardId);
                return (
                  <Grid
                    item
                    xl={3}
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="avatar-icon-wrapper avatar-icon-md d-flex justify-content-center">
                      <div
                        className="avatar-icon rounded-circle text-center"
                        style={{ width: 100, height: 100 }}
                      >
                        <img
                          alt="..."
                          src={
                            element.urlImage
                              ? element.urlImage
                              : require("../../assets/images/climind/caja-de-regalo.png")
                          }
                          // style={{borderRadius:"50%" , border: "solid 6px gold"}}
                        />
                      </div>
                    </div>
                    <div
                      className="font-size-xl font-weight-bold mb-2 d-flex justify-content-center"
                      style={{ marginTop: 10 }}
                    >
                      {element.awardId ? element.awardId.name : null}
                    </div>
                    <div className="align-box-row userbox-details d-flex justify-content-center">
                      <div
                        className=" avatar-icon rounded-circle"
                        style={{ width: 30, height: 30 }}
                      >
                        <img
                          alt="..."
                          src={require("../../assets/images/climind/coin-final.png")}
                        />
                      </div>
                      <h6 className="d-flex align-items-center justify-content-center p-2 mb-0 mr-3">
                        {element.price} Coins
                      </h6>
                    </div>
                    <div>
                      <h6 className="d-flex align-items-center justify-content-center p-2 mb-0 mr-3">
                        Estado: &nbsp;
                        <a className="font-weight-bold">
                          {element.state == "UNDELIVERED" ? "No entregado" : element.state == "DELIVERED" ? "Entregado" : element.state == "CANCELLED" ? "Cancelado" : "Estado desconocido"}
                        </a>
                      </h6>
                    </div>
                    <Button
                      size="small"
                      className="btn-info btn-pill px-4 hover-scale-sm mb-2 mt-10"
                      style={{ marginTop: 10 }}
                      onClick={() => {
                        setInfoSelected(element);
                        toggleInfo();
                        // console.log(element)
                      }}
                    >
                      <span className="btn-wrapper--label">Información</span>
                    </Button>

                    <div></div>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </Grid>
        {infoSelected && (
          <Dialog
            open={modalInfo}
            onClose={toggleInfo}
            classes={{ paper: "shadow-lg rounded" }}
          >
            <div className="text-center p-5">
              <h4 className="font-weight-bold mt-4">
                {infoSelected.awardId.name}
              </h4>

              <h5 className="font-weight-bold mt-4">
                {infoSelected.awardId.description}
              </h5>
              <h5 className="font-weight-bold mt-4">
                Forma de entrega: {infoSelected.awardId.delivery}
              </h5>
              <h6 className="font-weight-bold mt-4">Comprado en la fecha:</h6>
              <h6 className="font-weight-bold mt-4">
                {moment(infoSelected.date).format("DD/MM/YYYY")}
              </h6>
              <div className="pt-4">
                <Button
                  onClick={toggleInfo}
                  className="btn-success btn-pill mx-1"
                >
                  <span className="btn-wrapper--label">Cerrar</span>
                </Button>
              </div>
            </div>
          </Dialog>
        )}
      </>
    );
  } else {
    return null;
  }
}
