import React, { useState, useCallback, useContext } from "react";
import ModalSelectLeader from "./ModalSelectLeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import defaultAvatar from "../../../../assets/images/climind/default_avatar.png";
import {
  Dialog,
  Button,
  Card,
  CardContent,
  TextField,
  Menu,
  Snackbar,
  Switch,
  Grid,
  Table,
} from "@material-ui/core";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { UserContext } from "../../../../context/UserContext";
import { GET_GROUPS, GET_GROUP_BY_ID } from "data/queries";
import { EDIT_LEADER_GROUP } from "data/mutations";

import clsx from 'clsx';

import { InputAdornment } from '@material-ui/core';

import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';

import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function valuetext(value) {
  return <span>{{ value }}°C</span>;
}

export default function ModalAssignLeaderGroup({
  toggleLeaderGroup,
  groupSelected,
  openLeaderGroup,
  compId
}) {
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleBack = async (e) => {
    toggleLeaderGroup();
  };

  const handleOk = async (e) => {
    console.log(groupSelected)
    console.log(leaderSelected)
    
    let response = await editLeader({
      variables: { id: groupSelected.id, user: leaderSelected._id },
      refetchQueries: [
        {
          query: GET_GROUPS,
          variables: { id: companyId },
        },
        {
          query: GET_GROUP_BY_ID,
          variables: { id: groupSelected.id },
        },
      ],
    });

    toggleLeaderGroup();
  };

  const onSelectUser = useCallback( (user) => {
    setLeaderSelected(user)
  })

  //State
  const [leaderSelected, setLeaderSelected] = useState(null);
  const [modalLeader, setModalLeader] = useState(false);
  const toggleLeader = () => setModalLeader(!modalLeader);

  const [filterTable, setFilterTable] = useState(null)
  const [searchStatus1, setSearchStatus1] = useState(false);
  const toggleSearch1 = () => setSearchStatus1(!searchStatus1);

  //Variables
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  let companyId = null;
  if (compId) {
    companyId = compId;
  } else {
    companyId = dbuser.getUserByUid.companyId;
  }

  //Hooks
  const { loading, error, data, networkStatus } = useQuery(GET_GROUPS, {
    variables: { id: companyId },
    skip: !companyId,
    notifyOnNetworkStatusChange: true,
  });
  const [editLeader, { data: dataLeader  }] = useMutation(
    EDIT_LEADER_GROUP
  );

  //Functions

  if (data) {
    console.log(groupSelected);

    return (
      <>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          key={"top,center"}
          open={openSnackbar}
          // classes={{ root: "toastr-primary" }}
          autoHideDuration={4000}
          onClose={() => setOpenSnackbar(false)}
          message={"Lider asignado correctamente"}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity="success"
            style={{ color: "white" }}
          >
            Lider asignado correctamente
          </Alert>
        </Snackbar>
        <Dialog
          open={openLeaderGroup}
          onClose={handleBack}
          maxWidth="lg"
          fullWidth
          classes={{ paper: "modal-content rounded-lg" }}
        >
          <div className="p-4 text-center">
            <h5 className="font-size-xl mb-1 font-weight-bold mx-5">
              Asignar Lider a grupo
            </h5>
            <p className="text-black-50 mb-4">Lider actual</p>
            <Grid container spacing={12}>
              <Grid item md={12}>
                {leaderSelected ? (
                  <>
                    <div className="d-flex align-items-center justify-content-center mb-2 mt-3">
                      <div className="avatar-icon-wrapper avatar-icon-lg">
                        <div className="avatar-icon">
                          {leaderSelected.urlImage ? (
                            <img alt="..." src={leaderSelected.urlImage} />
                          ) : (
                            <img alt="..." src={defaultAvatar} />
                          )}
                        </div>
                      </div>
                      <div>
                        <a
                          href="#/"
                          onClick={(e) => e.preventDefault()}
                          className="font-weight-bold text-black"
                          title="..."
                        >
                          {leaderSelected.name}
                        </a>
                        <span className="text-black-50 d-block">
                          {leaderSelected.position}
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {groupSelected.dataManager[0] ? 
                    (<div className="d-flex align-items-center justify-content-center mb-2 mt-3">
                    <div className="avatar-icon-wrapper avatar-icon-lg">
                      <div className="avatar-icon">
                        <img alt="..." src={groupSelected.dataManager[0].urlImage} />
                      </div>
                    </div>
                    <div>
                      <a
                        href="#/"
                        onClick={(e) => e.preventDefault()}
                        className="font-weight-bold text-black"
                        title="..."
                      >
                        {groupSelected.dataManager[0].position}
                      </a>
                    </div>
                  </div>):
                    (<div className="d-flex align-items-center justify-content-center mb-2 mt-3">
                    <div className="avatar-icon-wrapper avatar-icon-lg">
                      <div className="avatar-icon">
                        <img alt="..." src={defaultAvatar} />
                      </div>
                    </div>
                    {/* <div>
                      <a
                        href="#/"
                        onClick={(e) => e.preventDefault()}
                        className="font-weight-bold text-black"
                        title="..."
                      >
                        ¿?
                      </a>
                    </div> */}
                  </div>)}
                  </>
                )}
                {/* <Button
                  className="btn-success text-uppercase font-weight-bold font-size-xs my-2"
                  style={{ backgroundColor: "#5a318e" }}
                  onClick={toggleLeader}
                >
                  {leaderSelected
                    ? "Cambiar Lider"
                    : "Seleccionar Lider"}
                </Button> */}
              </Grid>
            </Grid>
          </div>
          <div className="text-center px-2">
            <h4 className="font-weight-bold mt-2" style={{ color: "#5a318e" }}>
              Usuarios miembros del Grupo:
            </h4>

            <div className="text-center mb-3">
              <h6 className="text-center gx-mb-4">
              Selecciona un usuario para hacerlo Lider
              </h6>
              <div
                className={clsx("search-wrapper search-wrapper--grow mb-4", {
                  "is-active": searchStatus1,
                })}
              >
                <TextField
                  variant="outlined"
                  size="small"
                  id="input-with-icon-textfield22-1"
                  onFocus={toggleSearch1}
                  onBlur={toggleSearch1}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    const filteredTable = groupSelected.dataUsers.filter(
                      (o) =>
                        Object.keys(o).some(
                          (k) =>
                            k === "name" &&
                            String(o[k])
                              .toLowerCase()
                              .includes(e.target.value.toLowerCase())
                        )
                    );

                    setFilterTable(filteredTable);
                  }}
                />
              </div>
              <Card className="card-box mb-spacing-6-x2">
                <CardContent>
                  <div className="table-responsive-xl">
                    <Table className="table table-borderless table-hover text-nowrap mb-0">
                      <thead>
                        <tr>
                          <th className="text-left">Usuario</th>
                          <th className="text-center">Hacer lider</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!filterTable
                          ? groupSelected.dataUsers.map(
                              (element, index) => {
                                return (
                                  <tr>
                                    <td>
                                      <div className="d-flex align-items-left">
                                        <div className="avatar-icon-wrapper mr-3">
                                          <div className="avatar-icon">
                                            {element.urlImage ? (
                                              <img
                                                alt="..."
                                                src={element.urlImage}
                                              />
                                            ) : (
                                              <img
                                                alt="..."
                                                src={defaultAvatar}
                                              />
                                            )}
                                          </div>
                                        </div>
                                        <div className="text-left">
                                          <a
                                            href="#/"
                                            onClick={(e) => e.preventDefault()}
                                            className="text-left font-weight-bold text-black"
                                            title="..."
                                          >
                                            {element.name} {element.surname}
                                          </a>
                                          <span className="text-left text-black-50 d-block">
                                            {element.email}
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="text-center">
                                      <Button
                                        size="small"
                                        className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon"
                                        onClick={() => onSelectUser(element)}
                                      >
                                        <FontAwesomeIcon
                                          icon={["fas", "arrow-right"]}
                                        />
                                      </Button>
                                    </td>
                                  </tr>
                                );
                              }
                            )
                          : filterTable.map((element, index) => {
                              return (
                                <tr>
                                  <td>
                                    <div className="d-flex align-items-left">
                                      <div className="avatar-icon-wrapper mr-3">
                                        <div className="avatar-icon">
                                          {element.urlImage ? (
                                            <img
                                              alt="..."
                                              src={element.urlImage}
                                            />
                                          ) : (
                                            <img
                                              alt="..."
                                              src={defaultAvatar}
                                            />
                                          )}
                                        </div>
                                      </div>
                                      <div className="text-left">
                                        <a
                                          href="#/"
                                          onClick={(e) => e.preventDefault()}
                                          className="text-left font-weight-bold text-black"
                                          title="..."
                                        >
                                          {element.name} {element.surname}
                                        </a>
                                        <span className="text-left text-black-50 d-block">
                                          {element.email}
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="text-center">
                                    <Button
                                      size="small"
                                      className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon"
                                      onClick={() => onSelectUser(element)}
                                    >
                                      <FontAwesomeIcon
                                        icon={["fas", "arrow-right"]}
                                      />
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })}
                      </tbody>
                    </Table>
                  </div>
                </CardContent>
              </Card>
{/* 
              <div className="pt-4" style={{ marginBottom: 20 }}>
                <Button
                  onClick={toggleLeader}
                  className="btn-neutral-dark btn-pill mx-1"
                >
                  <span className="btn-wrapper--label">Atrás</span>
                </Button>
              </div> */}
            </div>

            <h6 className="text-black-50 mb-0 mt-4">
              Si deseas modificar la lista de usuarios vuelve a la vista
              anterior
            </h6>

            <div className="pt-4" style={{ marginBottom: 20 }}>
              <Button
                onClick={handleBack}
                className="btn-neutral-dark btn-pill mx-1"
              >
                <span className="btn-wrapper--label">Cancelar</span>
              </Button>
              <Button
                onClick={handleOk}
                className="btn-success btn-pill mx-1"
                // disabled={!buttonValue}
              >
                <span className="btn-wrapper--label">Guardar</span>
              </Button>
            </div>
          </div>
        </Dialog>
        <ModalSelectLeader
          modalLeader={modalLeader}
          toggleLeader={toggleLeader}
          leaderSelected={leaderSelected}
          setLeaderSelected={setLeaderSelected}
        />
      </>
    );
  } else {
    return null;
  }
}
