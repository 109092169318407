import React, { useEffect, useState, useContext, useCallback } from "react";

import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  Container,
  InputAdornment,
  ButtonGroup,
  Card,
  Button,
  List,
  ListItem,
  TextField,
  LinearProgress,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Snackbar,
  FormItem,
  Input
} from "@material-ui/core";

import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { useQuery, useMutation } from "@apollo/react-hooks";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDropzone } from "react-dropzone";
import CountUp from "react-countup";
import { NavLink, useHistory } from "react-router-dom";
import CloseTwoToneIcon from "@material-ui/icons/CloseTwoTone";
import PublishTwoToneIcon from "@material-ui/icons/PublishTwoTone";
import AccountCircleTwoToneIcon from "@material-ui/icons/AccountCircleTwoTone";
import CheckIcon from "@material-ui/icons/Check";
import { UserContext } from "../../../context/UserContext";
import { EDIT_USER_BASIC_INFO, CHANGE_PASSWORD_USER } from "../../../data/mutations";
import firebase from 'firebase'
import { useFormik } from "formik";
import * as Yup from "yup";

import { Formik } from "formik";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { GET_USER_BY_UID } from "data/queries";
import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
var moment = require("moment-timezone");
export default function EditProfile() {
  const [currentPasswordState, setCurrentPassword] = useState("")
  const [passwordState, setPassword] = useState("")
  const [repeatPasswordState, setRepeatPassword] = useState("")
  const [badPasswordState, setBadPasswordState] = useState(false)
  const [okPasswordState, setOkPasswordState] = useState(false)
  const [label, setlabel] = useState(false)
  const [myMessage, setMyMessage] = useState(null)
  const { currentUser, dbuser, getUserWithFirebase, restoreUser } = useContext(UserContext);
  let companyId = dbuser.getUserByUid.companyId;
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openSnackbarDanger, setOpenSnackbarDanger] = useState(false);
  const history = useHistory();
  const [editUser, { data }] = useMutation(EDIT_USER_BASIC_INFO);
  const [selectedDate, setSelectedDate] = useState(new Date('2020-08-18'));
  const [changePasswordUser, { data: mutationPasswordData, loading: mutationPasswordLoading, error: mutationPasswordError }] = useMutation(CHANGE_PASSWORD_USER);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const [age, setAge] = useState('');

  const handleChangeAge = (event) => {
    setAge(event.target.value);
  };

  // const handleChangePasswordUser = useCallback( async () => {
  //   setBadPasswordState(false)
  //   setOkPasswordState(false)
  //   let dataInput = {
  //     id: dbuser.getUserByUid.id,
  //     password: passwordState,
  //     repeatPassword: repeatPasswordState
  //   };
  //   // console.log(id);
  //   // console.log(dataInput)

  //   var user = firebase.auth().currentUser;
  //   console.log(user.email)
  //   console.log(currentPasswordState)
  //   var credential = firebase.auth.EmailAuthProvider.credential(
  //     user.email,
  //     currentPasswordState
  //   );
  //   user.reauthenticateWithCredential(credential).then(async function() {
  //     console.log("buena pass")
  //     try{
  //       await changePasswordUser({variables:{input:dataInput}})
  //     }catch (e){
  //       console.log(e)
  //       let messageSplitted = e.message.split(':', 2)
  //       setMyError(messageSplitted[1])
  //       setCurrentPassword(currentPasswordState)
  //       setPassword(passwordState)
  //       setRepeatPassword(repeatPasswordState)
  //     }
  //     setOkPasswordState(true)
  //     setCurrentPassword("")
  //     setPassword("")
  //     setRepeatPassword("")
  //   }).catch(function(error) {
  //     console.log("mala pass")
  //     setOkPasswordState(false)
  //     setBadPasswordState(true)
  //   });

  // })

  const validationSchema = Yup.object().shape({
    // name: Yup.string()

    //   .max(100, "Como mucho 100 caracteres")
    //   .typeError("nombre invalido"),
    // legalName: Yup.string()

    //   .typeError("denominación social invalida"),
    // email: Yup.string().email("Email invalido").required("Obligatorio"),
    // nif: Yup.string().required("Obligatorio"),
    // numberTotalUsers: Yup.number()
    //   .integer("numero total de usuarios invalido")

    //   .typeError("numero de trabajadores invalido"),
    // numberUsersToRegister: Yup.number()
    //   .integer("numero de usuarios a registrar invalido")

    //   .typeError("numero de usuarios a registrar invalido"),
    // payment: Yup.string().required("Obligatorio"),
    // website: Yup.string().required("Obligatorio"),
    // nameOffice: Yup.string().required("Obligatorio"),
    // address: Yup.string().required("Obligatorio"),
    // country: Yup.string().required("Obligatorio"),
    // city: Yup.string().required("Obligatorio"),
    // province: Yup.string().required("Obligatorio"),
    //zipCode: Yup.number()
    //   .integer("Código postal invalido")

    //   .typeError("código postal invalido"),
    // code: Yup.number()
    //   .integer("Código invalido")

    //   .max(999, "Como mucho 3 caracteres")
    //   .typeError("código invalido"),
  });
  if (dbuser) {
    console.log("renderizando componente!!!!!")
    return (
      <>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          key={"top,center"}
          open={openSnackbar}
          // classes={{ root: "toastr-primary" }}
          autoHideDuration={4000}
          onClose={() => setOpenSnackbar(false)}
          message={"entregado correctamente"}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity="success"
            style={{ color: "white" }}
          >
            {myMessage}
          </Alert>
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          key={"top,right"}
          open={openSnackbarDanger}
          // classes={{ root: "toastr-primary" }}
          autoHideDuration={4000}
          onClose={() => setOpenSnackbarDanger(false)}
          message={"entregado correctamente"}
        >
          <Alert
            onClose={() => setOpenSnackbarDanger(false)}
            severity="warning"
            style={{ color: "white" }}
          >
            {myMessage}
          </Alert>
        </Snackbar>
        {/* <Grid item xl={12}>
            <Card className="card-box p-3">
              <div className="d-flex align-items-center pb-4 justify-content-between">
                <div>
                  <div className="font-weight-bold">Gana puntos por completar la información de tu perfil</div>
                </div>
                <div className="display-4 font-weight-bold text-first">
                  170 de 200 puntos ganados
                </div>
              </div>
              <div>
                <LinearProgress
                  variant="determinate"
                  className="progress-sm progress-bar-rounded progress-animated-alt progress-bar-first"
                  value={87}
                />
                <div className="align-box-row progress-bar--label mt-2">
                  <div>0%</div>
                  <div className="ml-auto">100%</div>
                </div>
              </div>
            </Card>
          </Grid> */}
        <Formik
          initialValues={{
            name: dbuser.getUserByUid.name ? dbuser.getUserByUid.name : null,
            surname: dbuser.getUserByUid.surname ? dbuser.getUserByUid.surname : null,
            username: dbuser.getUserByUid.username ? dbuser.getUserByUid.username : null,
            country: dbuser.getUserByUid.country ? dbuser.getUserByUid.country : null,
            city: dbuser.getUserByUid.city ? dbuser.getUserByUid.city : null,
            province: dbuser.getUserByUid.province ? dbuser.getUserByUid.province : null,
            zipCode: dbuser.getUserByUid.zipCode ? dbuser.getUserByUid.zipCode : null,
            gender: dbuser.getUserByUid.gender ? dbuser.getUserByUid.gender : null,
            birthday: dbuser.getUserByUid.birthday ? moment(dbuser.getUserByUid.birthday).format("YYYY-MM-DD") : null,
            description: dbuser.getUserByUid.description ? dbuser.getUserByUid.description : null,
            position: dbuser.getUserByUid.position ? dbuser.getUserByUid.position : null,
          }}
          onSubmit={async (values, actions) => {
            
              let dataInput = {
                name: values.name ? values.name : null,
                surname: values.surname ? values.surname : null,
                username: values.username ? values.username : null,
                country: values.country ? values.country : null,
                city: values.city ? values.city : null,
                province: values.province ? values.province : null,
                zipCode: values.zipCode ? values.zipCode : null,
                gender: values.gender ? values.gender : null,
                birthday: values.birthday ? values.birthday : null,
                description: values.description ? values.description : null,
              };
              console.log(dataInput);
              let id = dbuser.getUserByUid.id
              try {
              let response = await editUser({
                variables: { id: dbuser.getUserByUid.id, input: dataInput },
                refetchQueries:
                  { query: GET_USER_BY_UID, variables: { id, id } }
                
              });
              if(response){
                setMyMessage("Usuario editado correctamente")
                getUserWithFirebase()
              }
              
              // toggleEditEmployee();
            } catch (err) {
              setMyMessage(err.message)
              setOpenSnackbarDanger(true, () => {
                setTimeout(function () {
                  setOpenSnackbarDanger(false);
                  setMyMessage(null)
                }, 5000); //5 Second delay
              });
              console.log(err.message);
            }

            setOpenSnackbar(true, () => {
              setTimeout(function () {
                setOpenSnackbar(false);
                setMyMessage(null)
              }, 5000); //5 Second delay
            });

          }}
          // validationSchema={validationSchema}
        >
          {(props) => (

            <>
              {console.log(props)}
              <form onSubmit={props.handleSubmit} onReset={props.handleReset}>
                <Grid container spacing={4}>
                  {/* <Grid item md={4}>
                  <div className="text-center my-5">
                    <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-primary shadow-primary-sm text-primary mb-2 d-90">
                      <FontAwesomeIcon
                        icon={["fas", "building"]}
                        className="d-flex align-self-center font-size-xxl"
                      />
                    </div>
                    <h6 className="font-weight-bold font-size-xxl mb-1 mt-3 text-primary">
                      Información Básica
                    </h6>
                  </div>
                </Grid> */}
                  <Grid item md={12}>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <h3 className="ml-5 mt-4">Informacion Básica</h3>
                        <Card className="p-4 mt-4" style={{ backgroundColor: '#eceef7' }} elevation={0}>

                          <div>
                            <TextField
                              fullWidth
                              className="m-2"
                              id="outlined-multiline-static"
                              label="dni"
                              placeholder="Escribe aqui..."
                              value={dbuser.getUserByUid.dni}
                              disabled
                              variant="outlined"
                              disabled
                            />
                            <TextField
                              fullWidth
                              className="m-2"
                              id="outlined-multiline-static"
                              label="Email"
                              placeholder="Escribe aqui..."
                              value={dbuser.getUserByUid.email}
                              disabled
                              variant="outlined"
                              disabled
                            />
                            <TextField
                              fullWidth
                              className="m-2"
                              id="outlined-multiline-static"
                              label="Puesto de trabajo"
                              placeholder="Escribe aqui..."
                              value={dbuser.getUserByUid.position}
                              disabled
                              variant="outlined"
                              disabled
                            />
                          </div>
                        </Card>
                        <h6 className="mt-1">* Si deseas cambiar esta información ponte en contacto con tu empresa</h6>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
                <Grid container spacing={6}>
                  {/* <Grid item md={4}>
                  <div className="text-center my-5">
                    <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-primary shadow-primary-sm text-primary mb-2 d-90">
                      <FontAwesomeIcon
                        icon={["fas", "plus"]}
                        className="d-flex align-self-center font-size-xxl"
                      />
                    </div>
                    <h6 className="font-weight-bold font-size-xxl mb-1 mt-3 text-primary">
                      Información Complementaria
                    </h6>
                  </div>
                </Grid> */}

                  <Grid item md={12}>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>

                        <h3 className="ml-5 mt-4">Información Complementaria</h3>

                        <Card className="p-4 mt-4" style={{ backgroundColor: '#eceef7' }} elevation={0}>

                          <div>
                            <TextField
                              fullWidth
                              className="m-2"
                              id="outlined-multiline-static"
                              label='Nombre de usuario'
                              placeholder="Placeholder"
                              variant="outlined"
                              name="username"
                              value={props.values.username}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={Boolean(
                                props.touched.username && props.errors.username
                              )}
                              helperText={
                                props.touched.username ? props.errors.username : ""
                              }
                            />
                            <TextField
                              fullWidth
                              className="m-2"
                              id="outlined-multiline-static"
                              label='Nombre'
                              placeholder="Placeholder"
                              name="name"
                              variant="outlined"
                              value={props.values.name}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={Boolean(
                                props.touched.name && props.errors.name
                              )}
                              helperText={
                                props.touched.name ? props.errors.name : ""
                              }
                            />
                            <TextField
                              fullWidth
                              className="m-2"
                              id="outlined-multiline-static"
                              label="apellidos"
                              placeholder="Placeholder"
                              name="surname"
                              value={props.values.surname}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={Boolean(
                                props.touched.surname && props.errors.surname
                              )}
                              helperText={
                                props.touched.surname ? props.errors.surname : ""
                              }
                              variant="outlined"
                            />
                            <TextField
                              fullWidth
                              className="m-2"
                              id="outlined-multiline-static"
                              label="País"
                              placeholder="Placeholder"
                              name="country"
                              value={props.values.country}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={Boolean(
                                props.touched.country && props.errors.country
                              )}
                              helperText={
                                props.touched.country ? props.errors.country : ""
                              }
                              variant="outlined"
                            />
                            <TextField
                              fullWidth
                              className="m-2"
                              id="outlined-multiline-static"
                              label="Ciudad"
                              placeholder="Placeholder"
                              name="city"
                              value={props.values.city}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={Boolean(
                                props.touched.city && props.errors.city
                              )}
                              helperText={
                                props.touched.city ? props.errors.city : ""
                              }
                              variant="outlined"
                            />
                            <TextField
                              fullWidth
                              className="m-2"
                              id="outlined-multiline-static"
                              label="Provincia"
                              placeholder="Placeholder"
                              name="province"
                              value={props.values.province}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={Boolean(
                                props.touched.province && props.errors.province
                              )}
                              helperText={
                                props.touched.province ? props.errors.province : ""
                              }
                              variant="outlined"
                            />
                            <TextField
                              fullWidth
                              className="m-2"
                              id="outlined-multiline-static"
                              label="Código postal"
                              placeholder="Placeholder"
                              name="zipCode"
                              value={props.values.zipCode}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={Boolean(
                                props.touched.zipCode && props.errors.zipCode
                              )}
                              helperText={
                                props.touched.zipCode ? props.errors.zipCode : ""
                              }
                              variant="outlined"
                            />


                            <FormControl fullWidth variant="outlined" className="m-2">
                              <InputLabel id="demo-simple-select-outlined-label">
                                Sexo
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-outlined"
                                id="demo-simple-select-outlined"
                                name="gender"
                                value={props.values.gender}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                error={Boolean(
                                  props.touched.gender && props.errors.gender
                                )}
                                helperText={
                                  props.touched.gender ? props.errors.gender : ""
                                }

                                label="Age">
                                <MenuItem value={"MALE"}>HOMBRE</MenuItem>
                                <MenuItem value={"FEMALE"}>MUJER</MenuItem>
                              </Select>
                            </FormControl>
                            <TextField
                              fullWidth
                              className="m-2"
                              id="outlined-multiline-static"
                              label="Descripción"
                              placeholder="Placeholder"
                              name="description"
                              multiline
                              rows="4"
                              value={props.values.description}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={Boolean(
                                props.touched.description && props.errors.description
                              )}
                              helperText={
                                props.touched.description ? props.errors.description : ""
                              }
                              variant="outlined"
                            />
                            <div className="d-flex align-items-center justify-content-center flex-wrap">

                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid container>
                                  <div className="d-flex-row align-items-center justify-content-center m-4">
                                    <h4>fecha de nacimiento</h4>
                                    <TextField
                                      id="birthday"
                                      label="Birthday"
                                      type="date"
                                      InputLabelProps={{
                                        shrink: true
                                      }}
                                      value={props.values.birthday}
                                      onChange={props.handleChange}
                                      onBlur={props.handleBlur}
                                      error={Boolean(
                                        props.touched.birthday && props.errors.birthday
                                      )}
                                      helperText={
                                        props.touched.birthday ? props.errors.birthday : ""
                                      }

                                      KeyboardButtonProps={{
                                        'aria-label': 'change date'
                                      }}
                                    />
                                  </div>
                                  {/* <div className="d-flex-row align-items-center justify-content-center m-4">
              <h4>fecha de contratación</h4>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Date picker inline"
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                />
              </div> */}
                                </Grid>
                              </MuiPickersUtilsProvider>
                            </div>
                          </div>
                          <div className="pt-4 text-center">
                            <Button
                              // onClick={handleReset}
                              className="btn-warning font-weight-bold rounded hover-scale-lg mx-1"
                              size="large"
                              type="submit"
                            // component={Link} to="/homeuser/"
                            >
                              <span className="btn-wrapper--label">Actualizar</span>
                            </Button>
                          </div>
                        </Card>


                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </>
          )}
        </Formik>
        <Formik
          initialValues={{
            currentPassword: null,
            newPasswordOne: null,
            newPasswordTwo: null,

          }}
          onSubmit={async (values, actions) => {
            if (values.newPasswordOne != values.newPasswordTwo) {
              setMyMessage("Las nuevas contraseñas deben de coincidir")
              setOpenSnackbarDanger(true, () => {
                setTimeout(function () {
                  setOpenSnackbarDanger(false);
                  setMyMessage(null)
                }, 5000); //5 Second delay
              });
              return null
            }
            if (values.newPasswordTwo.length < 7 || values.newPasswordOne < 7) {
              setMyMessage("Las nuevas contraseñas deben de tener al menos 6 caracteres")
              setOpenSnackbarDanger(true, () => {
                setTimeout(function () {
                  setOpenSnackbarDanger(false);
                  setMyMessage(null)
                }, 5000); //5 Second delay
              });
              return null
            }
            console.log(values)
            try {
              let dataInput = {
                id: dbuser.getUserByUid.id,
                password: values.newPasswordOne ? values.newPasswordOne : null,
                repeatPassword: values.newPasswordTwo ? values.newPasswordTwo : null,
              };
              var user = firebase.auth().currentUser;
              console.log(user.email)
              console.log(dataInput.currentPassword)
              var credential = firebase.auth.EmailAuthProvider.credential(
                user.email,
                values.currentPassword
              );
              user.reauthenticateWithCredential(credential).then(async function () {
                console.log("buena pass")
                try {
                  await changePasswordUser({ variables: { input: dataInput } })
                  getUserWithFirebase()
                  setMyMessage("Contraseña cambiada con éxito")
                  setOpenSnackbar(true, () => {
                    setTimeout(function () {
                      setOpenSnackbar(false);
                      setMyMessage(null)
                    }, 5000); //5 Second delay
                  });
                  firebase
                    .auth()
                    .signOut()
                    .then(async function () {
                      // localStorage.removeItem("token");
                      await getUserWithFirebase()
                      await restoreUser()
                      history.push("/login");
                    })
                    .catch(function (error) {
                      console.log("no se ha posido cerrar sesión");
                    });
                } catch (e) {
                  console.log(e)
                  let messageSplitted = e.message.split(':', 2)
                  setMyMessage(messageSplitted[1])
                  setOpenSnackbarDanger(true, () => {
                    setTimeout(function () {
                      setOpenSnackbarDanger(false);
                      setMyMessage(null)
                    }, 5000); //5 Second delay
                  });

                  //setMyMessage(null)
                  // setCurrentPassword(currentPasswordState)
                  // setPassword(passwordState)
                  // setRepeatPassword(repeatPasswordState)
                }
                // setOkPasswordState(true)
                // setCurrentPassword("")
                // setPassword("")
                // setRepeatPassword("")
              }).catch(function (error) {
                console.log("mala pass")
                setMyMessage("Contraseña actual incorrecta")
                setOpenSnackbarDanger(true, () => {
                  setTimeout(function () {
                    setOpenSnackbarDanger(false);
                    setMyMessage(null)
                  }, 5000); //5 Second delay
                });
                //setMyMessage(null)
                // setOkPasswordState(false)
                // setBadPasswordState(true)
              });

              // toggleEditEmployee();
            } catch (err) {
              console.log(err);
            }

            //setMyMessage(null)
          }}
          validationSchema={validationSchema}
        >
          {(props) => (

            <>
              {console.log(props)}
              <form onSubmit={props.handleSubmit} onReset={props.handleReset}>

                <div className="mt-5">
                  <h3 className="ml-5 mt-4">Cambiar contraseña</h3>
                  <h5 className="ml-5 mt-4">Si se cambia con éxito se cerrará automáticamente tu sesión y podrás iniciar con la nueva contraseña</h5>
                  <div className="text-center mt-4">

                    <Card className="p-4 mt-4" style={{ backgroundColor: '#eceef7' }} elevation={0}>
                      <div className="d-flex-col align-items-left">
                        <TextField
                          label="Contraseña actual"
                          fullWidth
                          className="m-2"
                          id="outlined-multiline-static"
                          placeholder="Placeholder"
                          name="currentPassword"
                          multiline
                          value={props.values.currentPassword}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          error={Boolean(
                            props.touched.currentPassword && props.errors.currentPassword
                          )}
                          helperText={
                            props.touched.currentPassword ? props.errors.currentPassword : ""
                          }


                        />
                        <TextField
                          label="Nueva contraseña"
                          fullWidth
                          className="m-2"
                          id="outlined-multiline-static"
                          placeholder="Placeholder"
                          name="newPasswordOne"
                          multiline
                          value={props.values.newPasswordOne}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          error={Boolean(
                            props.touched.newPasswordOne && props.errors.newPasswordOne
                          )}
                          helperText={
                            props.touched.newPasswordOne ? props.errors.newPasswordOne : ""
                          }

                        />
                        <TextField
                          label="Repetir nueva contraseña"
                          fullWidth
                          className="m-2"
                          id="outlined-multiline-static"
                          placeholder="Placeholder"
                          name="newPasswordTwo"
                          multiline
                          value={props.values.newPasswordTwo}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          error={Boolean(
                            props.touched.newPasswordTwo && props.errors.newPasswordTwo
                          )}
                          helperText={
                            props.touched.newPasswordTwo ? props.errors.newPasswordTwo : ""
                          }

                        />
                      </div>
                      <Button
                        // onClick={handleReset}
                        className="btn-warning font-weight-bold rounded hover-scale-lg mx-1"
                        size="large"
                        type="submit"
                      // component={Link} to="/homeuser/"
                      >
                        <span className="btn-wrapper--label">Cambiar</span>
                      </Button>


                    </Card>
                    {/* {okPasswordState && <Alert message={"Se ha actualizado correctamente el usuario"} type="success" showIcon visible={label}/>}
              {mutationPasswordError && <Alert message={myError} type="error" showIcon visible={label}/>}
              {badPasswordState && <Alert message={"La contraseña actual introducida es incorrecta"} type="error" showIcon visible={label}/>} */}

                  </div>
                </div>
              </form>
            </>
          )}
        </Formik>
      </>
    )
  }
  else {
    return null
  }
}