import React, { Fragment, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Card, Button, List, Table, Tooltip } from "@material-ui/core";
import { POSSIBLE_ANSWER_EMOTION_TODAY } from "data/queries";
import { UserContext } from "../../../context/UserContext";
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';

import feliz from "../../../assets/images/climind/feliz.svg";
import neutro from "../../../assets/images/climind/neutro.svg";
import triste from "../../../assets/images/climind/triste.svg";
import thanks from "../../../assets/images/climind/thank-you.svg";
export default function Emotions({ toggle1, toggle2, toggle3, dataEmotion }) {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  // const { loading, error, data, refetch, networkStatus } = useQuery(
  //   POSSIBLE_ANSWER_EMOTION_TODAY,
  //   {
  //     variables: { id: dbuser.getUserByUid.id },
  //     skip: !dbuser.getUserByUid.id,
  //     notifyOnNetworkStatusChange: true,
  //   }
  // );
  // if (networkStatus === 4) return "Refetching!";
  // if (loading) return "Esperando";
  // if (error) return `Error! ${error}`;

  if (dataEmotion) {
    if(dataEmotion.possibleToAnswerEmotion === true){
    // if (true) {
      return (
        <Card className="text-center" style={{ height: 220 }}>
          <div className="card-tr-left">
            <div className="d-flex flex-row align-items-center">
              <div className="d-flex flex-row align-items-center">
                <Tooltip
                  classes={{
                    tooltip: "text-center p-3 tooltip-secondary",
                  }}
                  arrow
                  placement="bottom"
                  title="Gana 30 puntos por contestar"
                >
                  <div
                    className=" avatar-icon rounded-circle "
                    style={{ width: 30, height: 30 }}
                  >
                    <img
                      alt="..."
                      src={require("../../../assets/images/climind/coin-final.png")}
                    />
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="card-tr-actions">
            <div className="d-flex flex-row align-items-center">
              <Tooltip
                classes={{
                  tooltip: "text-center p-3 tooltip-secondary",
                }}
                arrow
                placement="top"
                title="LAS RESPUESTAS SON SIEMPRE ANÓNIMAS. (Puedes cambiar la privacidad en tu perfil)"
              >
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="text-first font-weight-bold"
                >
                  <div
                    className=" avatar-icon rounded-circle mr-2"
                    style={{ width: 30, height: 30 }}
                  >
                    <img
                      alt="..."
                      src={require("../../../assets/images/climind/candado.svg")}
                    />
                  </div>
                </a>
              </Tooltip>
              <Tooltip
                classes={{
                  tooltip: "text-center p-3 tooltip-secondary",
                }}
                arrow
                placement="top"
                title="Estamos interesados en mejorar tu experiencia laboral. 
                Necesitamos tu opinión y colaboración. Cada día tendrás que contestar 
                una sola vez sobre qué te ha parecido tu jornada laboral. 
                Pincha el emoticono que te identifique. Podrás ver cómo evoluciona"
              >
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="text-first font-weight-bold"
                >
                  <div
                    className=" avatar-icon rounded-circle mr-2"
                    style={{ width: 26, height: 26 }}
                  >
                    <img
                      alt="..."
                      src={require("../../../assets/images/climind/info.png")}
                    />
                  </div>
                </a>
              </Tooltip>
            </div>
          </div>
          {/* <div className="card-tr-footer">
          <div className="d-flex justify-content-end">
          <div className="d-flex flex-row align-items-center">
            <h5 className="font-weight-bold my-0 mr-1">20</h5>
              <div
                className=" avatar-icon rounded-circle "
                style={{ width: 30, height: 30 }}
              >
                <img
                  alt="..."
                  src={require("../../../assets/images/climind/vectorpaint.svg")}
                />
              </div>
              
            </div>
          </div>
          </div> */}
          <div className="card-header-alt d-flex flex-column justify-content-center p-10">
            {/* <h4 className="font-weight-bold font-size-lg text-black">
              ¿Cómo ha sido tu jornada?
            </h4> */}
            <h3 className="font-size-lg font-weight-bold" style={{ paddingTop: 30, paddingBottom: 10 }}>
              ¿Cómo ha sido tu jornada laboral?
            </h3>
          </div>

          <div className="py-2 d-flex align-items-center justify-content-center">
            <Card className="card-transparent mx-3 mb-3 hover-scale-sm">
              <a
                onClick={toggle2}
                className="card-img-wrapper card-box-hover rounded"
              >
                <img
                  alt="..."
                  className="card-img-top rounded-sm"
                  src={feliz}
                  style={{ width: 70 }}
                />
              </a>
            </Card>
            <Card className="card-transparent mx-3 mb-3 hover-scale-sm">
              <a
                onClick={toggle3}
                className="card-img-wrapper card-box-hover rounded"
              >
                <img
                  alt="..."
                  className="card-img-top rounded-sm"
                  src={neutro}
                  style={{ width: 70 }}
                />
              </a>
            </Card>
            <Card className="card-transparent mx-3 mb-3 hover-scale-sm">
              <a
                onClick={toggle1}
                className="card-img-wrapper card-box-hover rounded"
              >
                <img
                  alt="..."
                  className="card-img-top rounded-sm"
                  src={triste}
                  style={{ width: 70 }}
                />
              </a>
            </Card>
          </div>
        </Card>
      )
    } else {
      return (
        <Card className="text-center" style={{ height: 220 }}>
          <div className="card-tr-actions">
            <div className="d-flex flex-row align-items-center">
              <Tooltip
                classes={{
                  tooltip: "text-center p-3 tooltip-secondary",
                }}
                arrow
                placement="top"
                title="LAS RESPUESTAS SON SIEMPRE ANÓNIMAS. (Puedes cambiar la privacidad en tu perfil)"
              >
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="text-first font-weight-bold"
                >
                  <div
                    className=" avatar-icon rounded-circle mr-2"
                    style={{ width: 30, height: 30 }}
                  >
                    <img
                      alt="..."
                      src={require("../../../assets/images/climind/candado.svg")}
                    />
                  </div>
                </a>
              </Tooltip>
              <Tooltip
                classes={{
                  tooltip: "text-center p-3 tooltip-secondary",
                }}
                arrow
                placement="top"
                title="Estamos interesados en mejorar tu experiencia laboral. 
                Necesitamos tu opinión y colaboración. Cada día tendrás que contestar 
                una sola vez sobre qué te ha parecido tu jornada laboral. 
                Pincha el emoticono que te identifique. Podrás ver cómo evoluciona"
              >
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="text-first font-weight-bold"
                >
                  <div
                    className=" avatar-icon rounded-circle mr-2"
                    style={{ width: 26, height: 26 }}
                  >
                    <img
                      alt="..."
                      src={require("../../../assets/images/climind/info.png")}
                    />
                  </div>
                </a>
              </Tooltip>
            </div>
          </div>
          {/* <div className="card-tr-footer">
          <div class="d-flex justify-content-end">
            <div className="d-flex flex-row align-items-center">
            <h5 className="font-weight-bold my-0 mr-1">20</h5>
              <div
                className=" avatar-icon rounded-circle "
                style={{ width: 30, height: 30 }}
              >
                <img
                  alt="..."
                  src={require("../../../assets/images/climind/vectorpaint.svg")}
                />
              </div>
              
            </div>
          </div>
          </div> */}


          <div className="py-2 d-flex align-items-center justify-content-center" style={{ marginTop: 50 }}>
            <h4 className="font-weight-bold font-size-lg text-black">
              ¡Ya has contestado hoy!
            </h4>
          </div>
          <img
            alt="..."
            className="card-img-top rounded-sm"
            src={thanks}
            style={{ width: 70 }}
          />

        </Card>
      )
    }

  }else{return null}
}
