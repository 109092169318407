import React, { useEffect, useState, useContext } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";

import { UserContext } from "../../../../context/UserContext";
import { GETS_FOR_PULSE } from "data/queries";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Gauge from "../../../../climind-components/Gauge/Gauge";
import LinearGauge from "../../../../climind-components/Linear-Gauge/LinearGauge";
import CardClimind from "../../../../climind-components/Card/Card";
import ThermometerGauge from "../../../../climind-components/Thermometer/ThermometerGauge";
import ChartClimind from "../../../../climind-components/Chart/Chart";
import RadarSpider from "../../../../climind-components/RadarSpider/RadarSpider";
import TablaClimind from "../../../../climind-components/TablaClimind/TablaClimind";
import Chart from 'react-apexcharts';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
  },
  paper: {
    padding: "10px",
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid #dfe3e6",
    height: "150px",
  },
  paperTwo: {
    padding: "10px",
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid #dfe3e6",
    height: "250px",
  },
  paperThree: {
    padding: "10px",
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid #dfe3e6",
    height: "200px",
  },
  paperTop: {
    padding: "10px",
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid #dfe3e6",
    height: "250px",
  },
  paperBotton: {
    padding: "10px",
    marginTop: "10px",
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid #dfe3e6",
    height: "250px",
  },
  doublePaper: {
    padding: "10px",
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid #dfe3e6",
    height: "405px",
  },
  largePaper: {
    padding: "10px",
    marginBottom: "10px",
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid #dfe3e6",
    height: "250px",
  },
}));

export default function PulseDashboard() {
  const classes = useStyles();

  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  let companyId = dbuser.getUserByUid.companyId;

  const { loading, error, data, networkStatus } = useQuery(GETS_FOR_PULSE, {
    variables: { id: companyId },
    skip: !companyId,
    notifyOnNetworkStatusChange: true,
  });

  if (networkStatus === 4) return "Refetching!";
  if (loading) return "Esperando";
  if (error) return `Error! ${error}`;

  if (data) {
    // Número total de empleados (Entero) - QUERY
    let TotalUsers = data.getNumberOfUsersByCompanyId.count;

    // Número total de empleados Activos (Entero) - QUERY
    let TotalActiveUsers = data.getNumberOfActiveUsersByCompanyId.count;

    // Número total de empleados Activos en Clima (Entero) - QUERY
    let TotalActivePulseUsers = data.getNumberOfPulsoActiveUsersByCompanyId
      ? data.getNumberOfPulsoActiveUsersByCompanyId.count
      : 0;

    // Array de usuarios activos en Clima(Array) - QUERY
    let ArrayActivityUsersData = [];
    data.getUsersCountedPulso.map((item, index) => {
      let value = item.count;
      ArrayActivityUsersData.push(value);
    });

    // Número de días contabilizados
    // let TotalDays = ArrayActivityUsersData.length;
    let TotalDays = data.getUsersPulsoAverage.length;

    // Negative & Positive
    let arrayPositiveRatio = []
    let arrayNegativeRatio = []
    let totalNegativeDays=0
    let totalPositiveDays=0
    console.log(data)
    data.getUsersPulsoAverage.map((item,index)=>{
      if(item.count<0){
        arrayNegativeRatio.push(item.count)
        totalNegativeDays += 1
      }else{
        arrayPositiveRatio.push(item.count)
        totalPositiveDays += 1
      }
    })
    // temporal
    if(data.getUsersPulsoAverage.length<=0){
      arrayNegativeRatio = [0]
      arrayPositiveRatio = [0]
    }

    // Indicadores

    // 1. Usuarios activos pulso en el mes
    let TotalActivePulseUsersPercent = TotalActivePulseUsers / TotalActiveUsers;

    // 2. Nivel de recurrencia en pulso
    let ArrayRecurringPulseUsers = [];
    let tmpTotalRecurringPulseUsers = 0;
    ArrayActivityUsersData.map((item, index) => {
      let tmpRecurringDay = item / TotalActiveUsers;
      tmpTotalRecurringPulseUsers =
        tmpTotalRecurringPulseUsers + tmpRecurringDay;
      ArrayRecurringPulseUsers.push(tmpRecurringDay * 100);
    });
    let TotalRecurringUsers = tmpTotalRecurringPulseUsers / TotalDays;

    // 3. Nivel de representatividad
    let RepresentivityPulse = TotalActivePulseUsers / TotalUsers;

    // 4. Ratio pulso positivo
    let representivityPositive = arrayPositiveRatio.length / TotalDays;

    // 5. Ratio pulso negativo
    let representivityNegative = arrayNegativeRatio.length / TotalDays;

    // 6. Evolucion pulso positivo vs negativo
    //Array de datos 
    let dataSeriesPulse = []
    data.getUsersPulsoAverage.map((item,index)=>{
      dataSeriesPulse.push(Math.round(item.count))
    })
    // Array de fechas
    let date = new Date()
    date.setDate(date.getDate()-TotalDays)
    let ArrayDates = []
    for (let i=0;i<TotalDays;i++){
      let tmpDate = date.toLocaleDateString()
      date.setDate(date.getDate()+1)
      ArrayDates.push(tmpDate)
    }

    const dataSeriesChart = [
      {
        name: "Recurrencia",
        data: dataSeriesPulse,
      },
    ];
    const dataOptionsChart = {
      xaxis: {
        categories:ArrayDates,
        labels: {
          rotate: -15,
          rotateAlways: true,
        }
      },
      yaxis: {
        labels: {
          formatter: function(val) {
            return val + '/10'
          }
        }
      }
    };

    // 7. Ratio pulso positivo
    let sumPositive = arrayPositiveRatio.reduce((previous, current) => current += previous);
    let positiveRatio = sumPositive / arrayPositiveRatio.length;

    // 8. Ratio pulso negativo
    let sumNegative = arrayNegativeRatio.reduce((previous, current) => current += previous);
    let negativeRatio = sumNegative / arrayNegativeRatio.length;

    return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <CardClimind
                  id="p-c-01"
                  title={"Usuarios activos pulso en el mes"}
                  dataPercent={TotalActivePulseUsersPercent}
                />
              </Grid>
              <Grid item xs={4}>
                <CardClimind
                  id="p-c-02"
                  title={"Nivel de recurrencia en pulso"}
                  dataPercent={TotalRecurringUsers}
                />
              </Grid>
              <Grid item xs={4}>
                <CardClimind
                  id="p-c-03"
                  title={"Nivel de representatividad"}
                  dataPercent={RepresentivityPulse}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5}>
            <Grid
              container
              direction="column"
              justify="space-between"
              alignItems="center"
              className="h-100"
            >
              <Grid item>
                <Gauge
                  id="p-g-01"
                  title={"Ratio de pulso positivo"}
                  dataPercent={representivityPositive}
                />
              </Grid>
              <Grid item>
                <Gauge
                  id="p-g-02"
                  title={"Ratio de pulso negativo"}
                  dataPercent={representivityNegative}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={7}>
            <ChartClimind
              id="p-chart-01"
              title={"Evolución del pulso positivo + negativo"}
              dataSeriesChart={dataSeriesChart}
              dataOptionsChart={dataOptionsChart}
            />
          </Grid>
          <Grid item xs={6}>
          <LinearGauge
            id="p-lguage-01"
            title={"Intensidad Positiva"}
            subtitle={
              "Valor Promedio de Pulso positivo en el mes"
            }
            dataPercent={positiveRatio/100}
          />
        </Grid>
        <Grid item xs={6}>
          <LinearGauge
            id="p-lguage-02"
            title={"Intensidad Negativa"}
            subtitle={
              "Valor promedio de pulso negativo en el mes"
            }
            dataPercent={negativeRatio/100}
          />
        </Grid>
        </Grid>
      </div>
    );
  }
}
