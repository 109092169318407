import React, { useEffect, useState, useContext } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, LinearProgress, Card, Button, Tooltip } from '@material-ui/core';
import premium from "../../../assets/images/climind/calidad-premium.svg";
import Chart from 'react-apexcharts';
import { UserContext } from "../../../context/UserContext";
import { GETS_FOR_GENERAL } from "data/queries";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import emotion from "../../../assets/images/climind/reaction.png";
import recognition from  "../../../assets/images/climind/thumbs-up.png";
import pregunta from "../../../assets/images/climind/faq.svg";
import felicitacion from "../../../assets/images/climind/regalo.png";
import agradecimiento from "../../../assets/images/climind/pray.png";
import propuesta from "../../../assets/images/climind/planta-de-cultivo.svg";
export default function Information() {
  const chartSparklinesLarge2AOptions = {
    chart: {
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: true
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: ['#3c44b1'],
    stroke: {
      color: '#4191ff',
      curve: 'smooth',
      width: 4
    },
    xaxis: {
      crosshairs: {
        width: 1
      }
    },
    yaxis: {
      min: 0
    },
    legend: {
      show: false
    }
  };
  const chartSparklinesLarge2AData = [
    {
      name: 'Número de acciones',
      data: [2, 4, 5, 0, 9, 0, 0, 1, 4, 1, 3, 0, 0, 2]
    }
  ];

  const chartSparklinesLarge2BOptions = {
    chart: {
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: true
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: ['rgba(255,255,255,.75)'],
    stroke: {
      color: 'rgba(255,255,255,.75)',
      curve: 'smooth',
      width: 4
    },
    xaxis: {
      crosshairs: {
        width: 1
      }
    },
    yaxis: {
      min: 0
    },
    legend: {
      show: false
    }
  };
  const chartSparklinesLarge2BData = [
    {
      name: 'Orders',
      data: [47, 54, 38, 56, 25, 19, 56, 27, 45, 54, 38, 26, 56, 65]
    }
  ];
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  const { loading, error, data, networkStatus } = useQuery(GETS_FOR_GENERAL, {
    variables: { id: dbuser.getUserByUid.id },
    skip: !dbuser.getUserByUid.id,
    notifyOnNetworkStatusChange: true,
  });
  console.log(data);
  if (data) {
    let cie = (data.getAnswerEmotionsSendedByUserIdLast30.length <= 20 ? data.getAnswerEmotionsSendedByUserIdLast30.length : 20)/20*100 * (30/100) +
              (data.getAnswersSendedByUserIdLast30.length <= 20 ? data.getAnswersSendedByUserIdLast30.length : 20)/20*100 * (30/100)+
              (data.getRecognitionsSendedByUserIdLast30.length <= 20 ? data.getRecognitionsSendedByUserIdLast30.length : 20)/20*100 * (15/100)+
              (data.getImprovementProposalsSendedByUserIdLast30.length <= 20 ? data.getImprovementProposalsSendedByUserIdLast30.length : 20)/20*100 * (15/100)+
              (data.getCongratulationsSendedByUserIdLast30.length <= 20 ? data.getCongratulationsSendedByUserIdLast30.length : 20)/20*100 * (5/100)+
              (data.getGratitudesSendedByUserIdLast30.length <= 20 ? data.getGratitudesSendedByUserIdLast30.length : 20)/20*100 * (5/100)
  return (
    <>
      <Grid container spacing={6}>
          <Grid item xl={12}>
            <Card className="card-box">
              <div className="card-header-alt d-flex justify-content-between p-4">
                <div>
                <h6 className="font-weight-bold font-size-lg mb-1 text-black">
                    Acciones realizadas en los últimos 30 días.
                  </h6>
                  {/* <p className="text-black-50 mb-0">
                    Descubre cual es tu uso de climind y tus objetivos
                  </p> */}
                </div>
                <div className="d-flex flex-row">
                {/* <img
                        alt="..."
                        className="card-img-top rounded-sm mr-2"
                        src={premium}
                        style={{ width: 50 }}
                    /> */}
                {/* <div className="d-flex align-items-center">  */}
                {/* <h6 className="font-weight-bold font-size-lg mb-1 text-black mr-3">
                    !Eres un usuario activo!
                  </h6> */}
                  <Tooltip
                classes={{
                  tooltip: "text-center p-3 tooltip-secondary",
                }}
                arrow
                placement="bottom"
                title="Aquí podrás ver que porcentaje de acciones estás realizando en los últimos 30 días (Se parte de la base de que se trabaja una media de 20 días de los 30 últimos). Cuando una acción llegue al 100% no te dará más coins pero podrás seguir ayudando a tu empresa con tus respuestas."
              >
                  <a
                                href="#/"
                                onClick={(e) => e.preventDefault()}
                                className="text-first font-weight-bold">
              <div
                className=" avatar-icon rounded-circle "
                style={{ width: 26, height: 26 }}
              >
                <img
                  alt="..."
                  src={require("../../../assets/images/climind/info.png")}
                />
              </div>
                                </a>
                                </Tooltip>
                {/* </div> */}
                </div>
              </div>
              {/* <div className="divider" />
              <div className="px-3 px-lg-5 py-2 m-0 bg-secondary">
                <Chart
                  options={chartSparklinesLarge2AOptions}
                  series={chartSparklinesLarge2AData}
                  type="line"
                  height={110}
                />
              </div> */}
              <div className="divider" />
              <div className="p-4 text-center">
                <Grid container spacing={6}>
                  <Grid item md={4}>
                    <Tooltip
                      classes={{
                        tooltip: "text-center p-3 tooltip-secondary",
                      }}
                      arrow
                      placement="top"
                      title="Número de veces que has enviado información sobre cómo ha sido tu jornada laboral en los últimos 30 días"
                    >
                      <img
                        alt="..."
                        className="card-img-top rounded-sm mb-2 mr-2"
                        src={emotion}
                        style={{ width: 50, height: 50 }}
                      />
                    </Tooltip> 
                    <div className="mb-1 font-weight-bold font-size-xl">
                    {Math.round((data.getAnswerEmotionsSendedByUserIdLast30.length <= 20 ? Math.round(data.getAnswerEmotionsSendedByUserIdLast30.length) : 20)/20*100)}% ({data.getAnswerEmotionsSendedByUserIdLast30.length} de 20)
                    </div>
                    <LinearProgress
                      variant="determinate"
                      className="bg-secondary progress-constrained progress-bar-rounded progress-bar-rounded-alt progress-bar-danger"
                      value={(data.getAnswerEmotionsSendedByUserIdLast30.length <= 20 ? data.getAnswerEmotionsSendedByUserIdLast30.length : 20)/20*100}
                    />
                    {/* <div className="pt-2">Preguntas Emociones </div> */}
                  </Grid>
                  <Grid item md={4}>
                  <Tooltip
                      classes={{
                        tooltip: "text-center p-3 tooltip-secondary",
                      }}
                      arrow
                      placement="top"
                      title="Número de veces que has enviado información sobre la pregunta de clima en los últimos 30 días"
                    >
                  <img
                      alt="..."
                      className="card-img-top rounded-sm mb-2 mr-2"
                      src={pregunta}
                      style={{ width: 50, height: 50 }}
                    />
                    </Tooltip> 
                    <div className="mb-1 font-weight-bold font-size-xl">
                    {Math.round((data.getAnswersSendedByUserIdLast30.length <= 20 ? Math.round(data.getAnswersSendedByUserIdLast30.length) : 20)/20*100)}% ({ data.getAnswersSendedByUserIdLast30.length} de 20)
                    </div>
                    <LinearProgress
                      variant="determinate"
                      className="bg-secondary progress-constrained progress-bar-rounded progress-bar-rounded-alt progress-bar-info"
                      value={ (data.getAnswersSendedByUserIdLast30.length <= 20 ? data.getAnswersSendedByUserIdLast30.length : 20)/20*100}
                    />
                    {/* <div className=" pt-2">Preguntas Clima</div> */}
                  </Grid>
                  <Grid item md={4}>
                  <Tooltip
                      classes={{
                        tooltip: "text-center p-3 tooltip-secondary",
                      }}
                      arrow
                      placement="top"
                      title="Número de veces que has enviado un reconocimiento en los últimos 30 días"
                    >
                  <img
                      alt="..."
                      className="card-img-top rounded-sm mb-2 mr-2"
                      src={recognition}
                      style={{ width: 50, height: 50 }}
                    />
                    </Tooltip> 
                    <div className="mb-1 font-weight-bold font-size-xl">
                    {Math.round((data.getRecognitionsSendedByUserIdLast30.length <= 20 ? Math.round(data.getRecognitionsSendedByUserIdLast30.length) : 20)/20*100)}% ({data.getRecognitionsSendedByUserIdLast30.length} de 20)
                    </div>
                    <LinearProgress
                      variant="determinate"
                      className="bg-secondary progress-constrained progress-bar-rounded progress-bar-rounded-alt progress-bar-warning"
                      value={(data.getRecognitionsSendedByUserIdLast30.length <= 20 ? data.getRecognitionsSendedByUserIdLast30.length : 20)/20*100}
                    />
                    {/* <div className=" pt-2">Reconocimientos Enviados</div>  */}
                  </Grid>
                </Grid>
                <Grid container spacing={6}>
                  <Grid item md={4}>
                  <Tooltip
                      classes={{
                        tooltip: "text-center p-3 tooltip-secondary",
                      }}
                      arrow
                      placement="top"
                      title="Número de veces que has enviado una propuesta de mejora en los últimos 30 días"
                    >
                  <img
                      alt="..."
                      className="card-img-top rounded-sm mb-2 mr-2"
                      src={propuesta}
                      style={{ width: 50, height: 50 }}
                    />
                    </Tooltip> 
                    <div className="mb-1 font-weight-bold font-size-xl">
                    {Math.round((data.getImprovementProposalsSendedByUserIdLast30.length <= 20 ? Math.round(data.getImprovementProposalsSendedByUserIdLast30.length) : 20)/20*100)}% ({data.getImprovementProposalsSendedByUserIdLast30.length} de 20)
                    </div>
                    <LinearProgress
                      variant="determinate"
                      className="bg-secondary progress-constrained progress-bar-rounded progress-bar-rounded-alt progress-bar-success"
                      value={(data.getImprovementProposalsSendedByUserIdLast30.length <= 20 ? data.getImprovementProposalsSendedByUserIdLast30.length : 20)/20*100}
                    />
                    {/* <div className="pt-2">Propuestas de mejora enviadas </div> */}
                  </Grid>
                  <Grid item md={4}>
                  <Tooltip
                      classes={{
                        tooltip: "text-center p-3 tooltip-secondary",
                      }}
                      arrow
                      placement="top"
                      title="Número de veces que has enviado una felicitación en los últimos 30 días"
                    >
                  <img
                      alt="..."
                      className="card-img-top rounded-sm mb-2 mr-2"
                      src={felicitacion}
                      style={{ width: 50, height: 50 }}
                    />
                    </Tooltip> 
                    <div className="mb-1 font-weight-bold font-size-xl">
                    {Math.round((data.getCongratulationsSendedByUserIdLast30.length <= 20 ? Math.round(data.getCongratulationsSendedByUserIdLast30.length) : 20)/20*100)}% ({ data.getCongratulationsSendedByUserIdLast30.length} de 20)
                    </div>
                    <LinearProgress
                      variant="determinate"
                      className="bg-secondary progress-constrained progress-bar-rounded progress-bar-rounded-alt progress-bar-primary"
                      value={ (data.getCongratulationsSendedByUserIdLast30.length <= 20 ? data.getCongratulationsSendedByUserIdLast30.length : 20)/20*100}
                    />
                    {/* <div className=" pt-2">Felicitaciones enviadas</div> */}
                  </Grid>
                  <Grid item md={4}>
                  <Tooltip
                      classes={{
                        tooltip: "text-center p-3 tooltip-secondary",
                      }}
                      arrow
                      placement="top"
                      title="Número de veces que has enviado un agradecimiento en los últimos 30 días"
                    >
                  <img
                      alt="..."
                      className="card-img-top rounded-sm mb-2 mr-2"
                      src={agradecimiento}
                      style={{ width: 50, height: 50 }}
                    />
                    </Tooltip> 
                    <div className="mb-1 font-weight-bold font-size-xl">
                    {Math.round((data.getGratitudesSendedByUserIdLast30.length <= 20 ? Math.round(data.getGratitudesSendedByUserIdLast30.length) : 20)/20*100)}% ({data.getGratitudesSendedByUserIdLast30.length} de 20)
                    </div>
                    <LinearProgress
                      variant="determinate"
                      className="bg-secondary progress-constrained progress-bar-rounded progress-bar-rounded-alt progress-bar-dark"
                      value={(data.getGratitudesSendedByUserIdLast30.length <= 20 ? data.getGratitudesSendedByUserIdLast30.length : 20)/20*100}
                    />
                    {/* <div className=" pt-2">Agradecimientos enviados</div>  */}
                  </Grid>
                </Grid>
              </div>
            </Card>
          </Grid>
          </Grid>
    </>
  );}
  else{
    return null
  }
}