import React, { useState, useCallback, useContext } from "react";
import { GET_USERS_BY_COMPANY_ID_WITHOUT_ME } from "data/queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "../../../context/UserContext";
import { useQuery } from "@apollo/react-hooks";
import {
  Table,
  LinearProgress,
  Card,
  CardContent,
  Button,
  Tooltip,
  Dialog,
  Checkbox,
} from "@material-ui/core";
import clsx from "clsx";

import { InputAdornment, TextField } from "@material-ui/core";

import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import defaultAvatar from "../../../assets/images/climind/default_avatar.png";
export default function ModalSelectUsers({
  modalUsers,
  toggleUsers,
  usersSelected,
  setUsersSelected,
}) {
  const [filterTable, setFilterTable] = useState(null);
  const [searchStatus1, setSearchStatus1] = useState(false);
  const toggleSearch1 = () => setSearchStatus1(!searchStatus1);

  const [employeesSelected, setEmployeesSelected] = useState([]);

  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  const {
    loading: loadingUsers,
    error: errorUsers,
    data: dataUsers,
    networkStatus: networkStatusUsers,
    refetch: refetchUsers,
  } = useQuery(GET_USERS_BY_COMPANY_ID_WITHOUT_ME, {
    variables: {
      id: dbuser.getUserByUid.companyId,
      userId: dbuser.getUserByUid.id,
    },
    skip: !dbuser.getUserByUid.companyId,
    notifyOnNetworkStatusChange: true,
  });
  const onSelectUser = useCallback((user) => {
    setUsersSelected(user);
    // toggleUsers();
  });
  if (dataUsers) {
    let allEmployees = dataUsers.getUsersByCompanyIdWithoutMe;

    // allEmployees = allEmployees.filter(
    //   (e) => !teamSelected.dataUsers.some((eS) => eS._id === e.id)
    // );
    // let employeesList = data.teamSelected.dataUsers;

    const handleBack = async (e) => {
        toggleUsers();
      };
    
      const handleOk = async (e) => {
        // let dataIdList = []
        // let response
        // employeesSelected.map((item, index) => {
        //   dataIdList.push(item.id)
        // })
    
        // if (type === "department") {
        //   response = await addUsersToDepartment({
        //     variables: { id: teamSelected.id, users: dataIdList },
        //     refetchQueries: [
        //       {
        //         query: GET_DEPARTMENTS_GROUPS_AND_USER,
        //         variables: { id: companyId },
        //       },
        //       {
        //         query: GET_DEPARTMENT_BY_ID,
        //         variables: { id: teamSelected.id },
        //       }
        //     ],
        //   });
        // } else {
        //   response = await addUsersToGroup({
        //     variables: { id: teamSelected.id, users: dataIdList },
        //     refetchQueries: [
        //       {
        //         query: GET_DEPARTMENTS_GROUPS_AND_USER,
        //         variables: { id: companyId },
        //       },
        //       {
        //         query: GET_GROUP_BY_ID,
        //         variables: { id: teamSelected.id },
        //       }
        //     ],
        //   });
        // }
    
        // console.log("mutation response:" + response);
        // setOpenSnackbar(true, () => {
        //   setTimeout(function () {
        //     setOpenSnackbar(false);
        //   }, 5000); //5 Second delay
        // });
        setUsersSelected(employeesSelected)
        setFilterTable(null)
        toggleUsers();
      };
    const checkedUser = (element) => {
        console.log("DENTROOOOOOOOOOOO")
        // const emps = employeesSelected.find((e) => element.id === e.id)
        // console.log(emps)
        const users = employeesSelected.find((e) => e.id === element.id)
        if (users) {
            return true
        }else{
            return false
        }
        // usersSelected.map((e,i) => {
            
        //     if(e.id === element.id){
        //         console.log(e.id)
        //     console.log(element.id)
        //     console.log(i)
        //     console.log(true)
        //         return true
        //     }else{
        //         console.log(false)
        //         return false
        //     }
        // })
        // console.log(employeesSelected)
        // return false
    }

    const handleCheckbox = (event) => {
      let employeeT = employeesSelected.find(
        (res) => res.id === event.target.value
      );
      if (employeeT) {
        //Remove
        let listTmp = employeesSelected.filter(
          (res) => res.id !== event.target.value
        );
        setEmployeesSelected(listTmp);
      } else {
        //Add
        let emp = allEmployees.find((res) => res.id === event.target.value);
        setEmployeesSelected([...employeesSelected, emp]);
      }
    };
    return (
      <>
        <Dialog
          open={modalUsers}
          onClose={handleBack}
          maxWidth="lg"
          fullWidth
          // width="80%"
          classes={{ paper: "modal-content rounded-lg" }}
        >
          <div className="p-4 text-center">
            <h5 className="font-size-xl mb-1 font-weight-bold mx-2">
              Agregar usuarios al grupo
            </h5>
            {/* <p className="text-black-50 mb-4">Obligatorio seleccionar uno o más usuarios</p> */}
          </div>
          <div className="text-center px-2">
            {/* <h5 className="font-weight-bold mt-2" style={{ color: "#5a318e" }}>
              Usuarios seleccionados:
            </h5> */}

            <div className="text-center pl-5 pr-5 mb-3">
              <p className="text-black-50 mb-4">
                Selecciona a los usuarios que deseas agregar
              </p>
              <div
                className={clsx("search-wrapper search-wrapper--grow mb-4", {
                  "is-active": searchStatus1,
                })}
              >
                <TextField
                  variant="outlined"
                  size="small"
                  id="input-with-icon-textfield22-1"
                  onFocus={toggleSearch1}
                  onBlur={toggleSearch1}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    let filter = e.target.value.toLowerCase();
                    const filteredTable = allEmployees.filter(
                      (o) => {
                        let dataFilter = o.name
                          .toLowerCase()
                          .concat(" ", o.surname.toLowerCase());
                        let dataFilter2 = o.email.toLowerCase();
                        return (
                          dataFilter.indexOf(filter) !== -1 ||
                          dataFilter2.indexOf(filter) !== -1
                        );
                      }

                      // (o) => {
                      //   let dataFilter = o.name.toLowerCase()
                      //   return dataFilter.indexOf(filter) !== -1
                      // }

                      // (o) => Object.keys(o).some(
                      //   (k) =>
                      //     k === "name" &&
                      //     String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
                      // )
                    );

                    setFilterTable(filteredTable);
                  }}
                />
              </div>
              <Card className="card-box mb-spacing-6-x2">
                <CardContent>
                  <div className="table-responsive-xl">
                    <Table className="table table-borderless table-hover text-nowrap mb-0">
                      <thead>
                        <tr>
                          <th className="text-left">Empleados</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!filterTable || filterTable.lenght <= 0
                          ? allEmployees.map((element, index) => {
                              return (
                                <tr>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <Checkbox
                                        color="primary"
                                        id="checkboxProjects15"
                                        className="align-self-center mr-2"
                                        value={element.id}
                                        onChange={handleCheckbox}
                                        
                                        // defaultChecked={() => checkedUser(element)}
                                        checked={checkedUser(element)}
                                      />
                                      <div className="avatar-icon-wrapper mr-3">
                                        <div className="avatar-icon">
                                          {element.urlImage ? (
                                            <img
                                              alt="..."
                                              src={element.urlImage}
                                            />
                                          ) : (
                                            <img
                                              alt="..."
                                              src={defaultAvatar}
                                            />
                                          )}
                                        </div>
                                      </div>
                                      <div className="text-left">
                                        <a
                                          href="#/"
                                          onClick={(e) => e.preventDefault()}
                                          className="font-weight-bold text-black"
                                          title="..."
                                        >
                                          {element.name} {element.surname}
                                        </a>
                                        <span className="text-black-50 d-block">
                                          {element.username ? (
                                            <CheckCircleIcon color="secondary" />
                                          ) : null}{" "}
                                          {element.email}
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          : filterTable.map((element, index) => {
                              return (
                                <tr>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <Checkbox
                                        color="primary"
                                        id="checkboxProjects15"
                                        className="align-self-center mr-2"
                                        value={element.id}
                                        onChange={handleCheckbox}
                                        checked={checkedUser(element)}
                                      />
                                      <div className="avatar-icon-wrapper mr-3">
                                        <div className="avatar-icon">
                                          {element.urlImage ? (
                                            <img
                                              alt="..."
                                              src={element.urlImage}
                                            />
                                          ) : (
                                            <img
                                              alt="..."
                                              src={defaultAvatar}
                                            />
                                          )}
                                        </div>
                                      </div>
                                      <div className="text-left">
                                        <a
                                          href="#/"
                                          onClick={(e) => e.preventDefault()}
                                          className="font-weight-bold text-black"
                                          title="..."
                                        >
                                          {element.name} {element.surname}
                                        </a>
                                        <span className="text-black-50 d-block">
                                          {element.username ? (
                                            <CheckCircleIcon color="secondary" />
                                          ) : null}{" "}
                                          {element.email}
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                      </tbody>
                    </Table>
                  </div>
                </CardContent>
              </Card>

              {/* <div className="pt-4" style={{ marginBottom: 20 }}>
                <Button
                  onClick={toggleLeader}
                  className="btn-neutral-dark btn-pill mx-1"
                >
                  <span className="btn-wrapper--label">Atrás</span>
                </Button>
              </div> */}
            </div>

            <h6 className="text-black-50 mb-0 mt-4">
              Si deseas modificar la lista de usuarios vuelve a la vista
              anterior
            </h6>

            <div className="pt-4" style={{ marginBottom: 20 }}>
              <Button
                onClick={handleBack}
                className="btn-neutral-dark btn-pill mx-1"
              >
                <span className="btn-wrapper--label">Cancelar</span>
              </Button>
              <Button
                onClick={handleOk}
                className="btn-success btn-pill mx-1"
                // disabled={!buttonValue}
              >
                <span className="btn-wrapper--label">Agregar</span>
              </Button>
            </div>
          </div>
        </Dialog>
      </>
    );
  } else {
    return null;
  }
}
