import React, { useContext } from "react";
import { useQuery } from "@apollo/react-hooks";

import { UserContext } from "../../../../../context/UserContext";
import { GET_METRICS_PULSO } from "data/queries";
import { Grid } from "@material-ui/core";
import DashboardPulse1 from "./DashboardPulse1";
import DashboardPulse2 from "./DashboardPulse2";
import DashboardPulse3 from "./DashboardPulse3";
import DashboardPulse5 from "./DashboardPulse5";
import DashboardStatistics4 from "./DashboardPulse4";


import {
  RingLoader,
} from 'react-spinners';

export default function Dashboards({
  filtersInput,
  filtersPreviousInput
}) {
  console.log(filtersPreviousInput);

  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  let companyId = dbuser.getUserByUid.companyId;
  let userId = dbuser.getUserByUid.id;
  console.log(dbuser.getUserByUid.role)

  const { loading: loadingPrevious, error: errorPrevious, data: dataPrevious, networkStatus: netPrevious } = useQuery(GET_METRICS_PULSO, {
    variables: { id: userId, input: filtersPreviousInput },
    skip: !companyId,
    notifyOnNetworkStatusChange: true,
  });
  const { loading, error, data, networkStatus } = useQuery(GET_METRICS_PULSO, {
    variables: { id: userId, input: filtersInput },
    skip: !userId,
    notifyOnNetworkStatusChange: true,
  });


  if (networkStatus === 4) { console.log("test"); return ("Refetching!") };
  if (loading || loadingPrevious) return (<div
    className="d-flex align-items-center justify-content-center"
    style={{ width: '100%', height: '100%' }}>
    <RingLoader color={'var(--primary)'} loading={true} />
  </div>)
  if (error || errorPrevious) return `Error! ${error}`;

  if (data && dataPrevious) {

    // Número total de empleados (Entero) - QUERY
    let TotalUsers = data.getPulsoMetrics.totalUsers;
    let TotalUsersPrevious = dataPrevious.getPulsoMetrics.totalUsers;

    // Número total de empleados Activos (Entero) - QUERY
    let TotalActiveUsers = data.getPulsoMetrics.totalActiveUsers;
    let TotalActivePreviousUsers = dataPrevious.getPulsoMetrics.totalActiveUsers;

    // Número total de empleados Activos en Clima (Entero) - QUERY
    let TotalActivePulseUsers = data.getPulsoMetrics
      ? data.getPulsoMetrics.totalActivePulsoUsers
      : 0;

    let TotalActivePreviousPulseUsers = dataPrevious.getPulsoMetrics
      ? dataPrevious.getPulsoMetrics.totalActivePulsoUsers
      : 0;

    // Array de usuarios activos en Clima(Array) - QUERY
    let ArrayActivityUsersData = [];
    // data.getUsersCountedPulso.map((item, index) => {
    //   let value = item.count;
    //   ArrayActivityUsersData.push(value);
    // });

    // Número de días contabilizados
    // let TotalDays = ArrayActivityUsersData.length;
    let TotalDays = data.getPulsoMetrics.dataUsers.length;
    let TotalDaysPrevious = dataPrevious.getPulsoMetrics.dataUsers.length;

    // Negative & Positive
    let arrayPositiveRatio = [];
    let arrayNegativeRatio = [];
    let arrayNeutroRatio = [];
    let totalNegativeDays = 0;
    let totalPositiveDays = 0;
    let totalNeutroDays = 0;
    // data.getUsersPulsoAverage.map((item, index) => {
    //   if (item.count < 0) {
    //     arrayNegativeRatio.push(item.count);
    //     totalNegativeDays += 1;
    //   } else if (item.count > 0){
    //     arrayPositiveRatio.push(item.count);
    //     totalPositiveDays += 1;
    //   } else {
    //     arrayNeutroRatio.push(item.count);
    //     totalNeutroDays += 1;
    //   }
    // });
    arrayNegativeRatio = arrayNegativeRatio.concat(data.getPulsoMetrics.dataNegative)
    arrayPositiveRatio = arrayPositiveRatio.concat(data.getPulsoMetrics.dataPositive)
    arrayNeutroRatio = arrayNeutroRatio.concat(data.getPulsoMetrics.dataNeutro)

    totalNegativeDays = data.getPulsoMetrics.dataNegative.length
    totalPositiveDays = data.getPulsoMetrics.dataPositive.length
    totalNeutroDays = data.getPulsoMetrics.dataNeutro.length

    // temporal
    // if (data.getUsersPulsoAverage.length <= 0) {
    //   arrayNegativeRatio = [0];
    //   arrayPositiveRatio = [0];
    //   arrayNeutroRatio = [0];
    // }

    // Indicadores

    // 1. Usuarios activos pulso en el mes
    let TotalActivePulseUsersPercent = TotalActivePulseUsers / TotalActiveUsers;
    let TotalActivePreviousPulseUsersPercent = TotalActivePreviousPulseUsers / TotalActivePreviousUsers;
    let TotalActivePreviousUsersPercent = TotalActivePreviousUsers / TotalUsersPrevious;

    console.log(TotalActivePulseUsersPercent);
    console.log(TotalActivePulseUsers);
    console.log(TotalActiveUsers);
    // 2. Nivel de recurrencia en pulso
    let ArrayRecurringPulseUsers = [];
    let tmpTotalRecurringPulseUsers = 0;
    data.getPulsoMetrics.dataUsers.map((item, index) => {
      let tmpRecurringDay = item.users / TotalActiveUsers;
      tmpTotalRecurringPulseUsers =
        tmpTotalRecurringPulseUsers + tmpRecurringDay;
      ArrayRecurringPulseUsers.push(tmpRecurringDay * 100);
    });
    let TotalRecurringUsers = tmpTotalRecurringPulseUsers / TotalDays;


    let ArrayRecurringPulseUsersPrevious = [];
    let tmpTotalRecurringPulseUsersPrevious = 0;
    dataPrevious.getPulsoMetrics.dataUsers.map((item, index) => {
      let tmpRecurringDay = item.users / TotalActivePreviousUsers;
      tmpTotalRecurringPulseUsersPrevious =
        tmpTotalRecurringPulseUsersPrevious + tmpRecurringDay;
      ArrayRecurringPulseUsersPrevious.push(tmpRecurringDay * 100);
    });
    let TotalRecurringUsersPrevious = tmpTotalRecurringPulseUsersPrevious / TotalDaysPrevious;

    // 3. Nivel de representatividad
    let RepresentivityPulse = TotalActivePulseUsers / TotalUsers;
    let RepresentivityPulsePrevious = TotalActivePreviousPulseUsers / TotalUsersPrevious;

    // Para ratios de representatividad
    let countPositives = 0
    let countNegatives = 0
    let countNeutros = 0
    arrayPositiveRatio.map((el) => countPositives = countPositives + el.count);
    arrayNegativeRatio.map((el) => countNegatives = countNegatives + el.count);
    arrayNeutroRatio.map((el) => countNeutros = countNeutros + el.count);

    let totalPulses = countPositives + countNegatives + countNeutros;
    // 4. Ratio pulso positivo
    let representivityPositive = countPositives / totalPulses;

    // 5. Ratio pulso negativo
    let representivityNegative = countNegatives / totalPulses;

    // 5. Ratio pulso neutro
    let representivityNeutro = countNeutros / totalPulses;


    // 6. Evolucion pulso positivo vs negativo
    //Array de datos
    let dataSeriesPulse = [];
    // data.getUsersPulsoAverage.map((item, index) => {
    //   dataSeriesPulse.push(Math.round(item.count));
    // });
    // Array de fechas
    let date = new Date();
    date.setDate(date.getDate() - TotalDays);

    //Tratamiento de Positive, Negative y Neutro
    let arrayDates = [];
    data.getPulsoMetrics.dataPositive.map(n => {
      let index = arrayDates.findIndex((dataD, index) => n.date == dataD.date)
      if (index != -1) {
        arrayDates[index].positive = n.count
        switch (arrayDates[index].trend) {
          case "neutro":
            if (n.count >= arrayDates[index].neutro) {
              arrayDates[index].trend = "positive"
              arrayDates[index].count = 10
            }
            else {
              arrayDates[index].trend = "neutro"
              arrayDates[index].count = 20
            }
            break;
          case "negative":
            if (n.count >= arrayDates[index].negative) {
              arrayDates[index].trend = "positive"
              arrayDates[index].count = 10
            }
            else {
              arrayDates[index].trend = "negative"
              arrayDates[index].count = 30
            }
            break;
          default:
            break;
        }
      } else {
        arrayDates.push({ "date": n.date, "positive": n.count, "neutro": 0, "negative": 0, "trend": "positive", "count": 10, "total": 0, "prom": 0 })
      }
    })

    console.log(data.getPulsoMetrics.dataNeutro);
    data.getPulsoMetrics.dataNeutro.map(n => {
      let index = arrayDates.findIndex((dataD, index) => n.date == dataD.date)
      if (index != -1) {
        arrayDates[index].neutro = n.count
        // arrayDates[index].prom = n.count
        switch (arrayDates[index].trend) {
          case "positive":
            if (n.count >= arrayDates[index].positive) {
              arrayDates[index].trend = "neutro"
              arrayDates[index].count = 20
            }
            else {
              arrayDates[index].trend = "positive"
              arrayDates[index].count = 10
            }
            break;
          case "negative":
            if (n.count >= arrayDates[index].negative) {
              arrayDates[index].trend = "neutro"
              arrayDates[index].count = 20
            }
            else {
              arrayDates[index].trend = "negative"
              arrayDates[index].count = 30
            }
            break;
          default:
            break;
        }
      } else {
        arrayDates.push({ "date": n.date, "positive": 0, "neutro": n.count, "negative": 0, "trend": "neutro", "count": 20, "total": 0 })
      }
    })

    data.getPulsoMetrics.dataNegative.map(n => {
      let index = arrayDates.findIndex((dataD, index) => n.date == dataD.date)
      if (index != -1) {
        arrayDates[index].negative = n.count
        switch (arrayDates[index].trend) {
          case "positive":
            if (n.count >= arrayDates[index].positive) {
              arrayDates[index].trend = "negative"
              arrayDates[index].count = 30
            }
            else {
              arrayDates[index].trend = "positive"
              arrayDates[index].count = 10
            }
            break;
          case "neutro":
            if (n.count >= arrayDates[index].neutro) {
              arrayDates[index].trend = "negative"
              arrayDates[index].count = 30
            }
            else {
              arrayDates[index].trend = "neutro"
              arrayDates[index].count = 20
            }
            break;
          default:
            break;
        }
      } else {
        arrayDates.push({ "date": n.date, "positive": 0, "neutro": 0, "negative": n.count, "trend": "negative", "count": 30, "total": 0 })
      }
    })

    data.getPulsoMetrics.dataAverage.map(n => {
      let index = arrayDates.findIndex((dataD, index) => n.date == dataD.date)
      if (index != -1) {
        arrayDates[index].prom = n.count
      }
    })

    return (
      <>
        <Grid container spacing={3}>
          <Grid item xl={12} md={12}>
            <DashboardPulse1
              TotalActivePulseUsersPercent={TotalActivePulseUsersPercent}
              TotalActivePulseUsers={TotalActivePulseUsers}
              TotalRecurringUsers={TotalRecurringUsers}
              RepresentivityPulse={RepresentivityPulse}
              TotalActivePreviousPulseUsersPercent={TotalActivePreviousPulseUsersPercent}
              TotalActivePreviousPulseUsers={TotalActivePreviousPulseUsers}
              TotalRecurringUsersPrevious={TotalRecurringUsersPrevious}
              RepresentivityPulsePrevious={RepresentivityPulsePrevious}
            />
          </Grid>
          <Grid item xl={12} md={12}>
            <DashboardStatistics4
              arrayDates={arrayDates}
              representivityPositive={representivityPositive}
              representivityNegative={representivityNegative}
              representivityNeutro={representivityNeutro}
              countPositives={arrayPositiveRatio.length}
              countNegatives={arrayNegativeRatio.length}
              countNeutros={arrayNeutroRatio.length}
              totalPulse={totalPulses}
              initDate={filtersInput.initDate}
            />
          </Grid>
          <Grid item xl={12} md={12}>
            <DashboardPulse3
              representivityPositive={representivityPositive}
              representivityNegative={representivityNegative}
              representivityNeutro={representivityNeutro}
              countPositives={arrayPositiveRatio.length}
              countNegatives={arrayNegativeRatio.length}
              countNeutros={arrayNeutroRatio.length}
              TotalActivePulseUsers={TotalActivePulseUsers}
              totalPulse={totalPulses}
              dataMatrix={data.getDataRiskMatrix}
              countedAspectNegative={data.getPulsoMetrics.countedAspectNegative}
              dataNegative={data.getPulsoMetrics.dataNegative}
            />
          </Grid>
          <Grid item xl={12} md={12}>
            <DashboardPulse5
              TotalActivePulseUsers={TotalActivePulseUsers}
              totalPulse={totalPulses}
              dataMatrix={data.getDataRiskMatrix}
              dataEngagementMatrix={data.getDataEngagementMatrix}
              countedAspectNegative={data.getPulsoMetrics.countedAspectNegative}
              countedAspectPositive={data.getPulsoMetrics.countedAspectPositive}
              countedAspectNeutro={data.getPulsoMetrics.countedAspectNeutro}
              dataNegative={data.getPulsoMetrics.dataNegative}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}
