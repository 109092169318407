import React, { useEffect, useState, useContext } from "react";
import { PageTitle } from 'layout-components';
import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  Container,
  InputAdornment,
  ButtonGroup,
  Card,
  Button,
  List,
  ListItem,
  TextField,
} from "@material-ui/core";

import Curriculum from "./Curriculum"
import CurriculumGroups from "./CurriculumGroups"
import { UserContext } from "../../context/UserContext";
import { GETS_FOR_GENERAL } from "data/queries";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";


export default function MainCurriculum() {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  const [inputBg, setInputBg] = useState(false);
  const toggleInputBg = () => setInputBg(!inputBg);

  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [activeTab2, setActiveTab2] = useState("1");

  const toggle2 = (tab) => {
    if (activeTab2 !== tab) setActiveTab2(tab);
  };


    return (
      <>
      <PageTitle
        titleHeading="Curriculum de soft skills"
        titleDescription="Información del curriculum de soft skills."
      />
        <div className="app-inner-content-layout">
          <div className="app-inner-content-layout--main bg-white p-0">
            <div className="hero-wrapper rounded-bottom shadow-xxl bg-composed-wrapper bg-second">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--bg rounded-bottom bg-grow-early opacity-6" />
                <div className="bg-composed-wrapper--content px-3">
                  <Container className="pt-4" style={{ width: "80%" }}>
                    <div className="d-block d-md-flex align-items-start">
                      
                     
                    </div>

                    <List className="d-flex nav-tabs justify-content-center nav-tabs-warning tabs-animated tabs-animated-shadow my-5">
                      <ListItem
                        button
                        selected={activeTab2 === "1"}
                        className="bg-white-10 mx-3 rounded-lg"
                        onClick={() => {
                          toggle2("1");
                        }}
                      >
                        <span className="font-size-lg text-white px-2 py-1">
                        Curriculum individual
                        </span>
                        <div className="divider" />
                      </ListItem>

                      <ListItem
                        button
                        selected={activeTab2 === "2"}
                        className="bg-white-10 mx-3 rounded-lg"
                        onClick={() => {
                          toggle2("2");
                        }}
                      >
                        <span className="font-size-lg text-white px-2 py-1">
                          Curriculum colectivo
                        </span>
                        <div className="divider" />
                      </ListItem>
                     
                    </List>
                  </Container>
                </div>
              </div>
            </div>
            <Container className="z-over py-5">

              <div
                className={clsx("tab-item-wrapper overflow-visible d-none", {
                  "d-block active": activeTab2 === "1",
                })}
                index={1}
              >
                  <Curriculum />
                {/* <General/> */}
              </div>
              
              <div
                className={clsx("tab-item-wrapper overflow-visible d-none", {
                  "d-block active": activeTab2 === "2",
                })}
                index={2}
              >
                  <CurriculumGroups />
              </div>
            </Container>
          </div>
        </div>
      </>
    );
  
}