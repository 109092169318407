import React, { useEffect, useState,useContext } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";

import { UserContext } from "../../../../context/UserContext";
import { GETS_FOR_CLIMA } from "data/queries";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Gauge from "../../../../climind-components/Gauge/Gauge";
import CardClimind from "../../../../climind-components/Card/Card";
import ThermometerGauge from "../../../../climind-components/Thermometer/ThermometerGauge";
import ChartClimind from "../../../../climind-components/Chart/Chart";
import RadarSpider from "../../../../climind-components/RadarSpider/RadarSpider";
import TablaClimind from "../../../../climind-components/TablaClimind/TablaClimind";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
  },
  paper: {
    padding: "10px",
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid #dfe3e6",
    height: "150px",
  },
  paperTwo: {
    padding: "10px",
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid #dfe3e6",
    height: "250px",
  },
  paperThree: {
    padding: "10px",
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid #dfe3e6",
    height: "200px",
  },
  paperTop: {
    padding: "10px",
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid #dfe3e6",
    height: "250px",
  },
  paperBotton: {
    padding: "10px",
    marginTop: "10px",
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid #dfe3e6",
    height: "250px",
  },
  doublePaper: {
    padding: "10px",
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid #dfe3e6",
    height: "405px",
  },
  largePaper: {
    padding: "10px",
    marginBottom: "10px",
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid #dfe3e6",
    height: "250px",
  },
}));

export default function EnvironmentDashboard() {
  const classes = useStyles();

  function convert(array) {
    var map = {};
    array.forEach(obj => {
        map[obj.id] = { ...obj, items: [] };
    });
    array.forEach(obj => {
        var parent = obj.parent || "-";
        if (!map[parent]) {
            map[parent] = {
                items: [],
            };
        }
        map[parent].items.push(map[obj.id]);
    });
    return map["-"].items;
}

  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  let companyId = dbuser.getUserByUid.companyId;

  const { loading, error, data, networkStatus } = useQuery(GETS_FOR_CLIMA, {
    variables: { id: companyId },
    skip: !companyId,
    notifyOnNetworkStatusChange: true,
  });


  if (networkStatus === 4) return "Refetching!";
  if (loading) return "Esperando";
  if (error) return `Error! ${error}`;

  if(data){


  // Número total de empleados (Entero) - QUERY
  let TotalUsers = data.getNumberOfUsersByCompanyId.count
  
  // Número total de empleados Activos (Entero) - QUERY
  let TotalActiveUsers = data.getNumberOfActiveUsersByCompanyId.count
  
  // Número total de empleados Activos en Clima (Entero) - QUERY
  let TotalActiveClimaUsers = data.getNumberOfClimaActiveUsersByCompanyId ? data.getNumberOfClimaActiveUsersByCompanyId.count : 0

  // Array de usuarios activos en Clima(Array) - QUERY
  let ArrayActivityUsersData = []
  data.getUsersCountedClima.map((item,index)=>{
    let value=item.count
    ArrayActivityUsersData.push(value)
  })

  // Número de días contabilizados
  let TotalDays = ArrayActivityUsersData.length

  // Clima e indicadores

  console.log(data.getTreeEntitiesMetrics)
  let arrayNatures = convert(data.getTreeEntitiesMetrics)
  console.log(arrayNatures)
  

  // Indicadores
  
  // 1. Usuarios activos clima en el mes
  let TotalActiveClimaUsersPercent = TotalActiveClimaUsers/TotalActiveUsers

  // 2. Nivel de recurrencia en clima
  let ArrayRecurringClimaUsers = []
  let tmpTotalRecurringClimaUsers = 0
  ArrayActivityUsersData.map((item, index)=>{
    let tmpRecurringDay = item/TotalActiveUsers
    tmpTotalRecurringClimaUsers = tmpTotalRecurringClimaUsers + tmpRecurringDay
    ArrayRecurringClimaUsers.push(tmpRecurringDay*100)
  })
  let TotalRecurringUsers = tmpTotalRecurringClimaUsers/TotalDays

  // 3. Nivel de representatividad
  let RepresentivityClima = TotalActiveClimaUsers/TotalUsers


  // 4. Nivel de clima Global
  let KpiClimaGlobal = data.getAnwersMetrics.global/10

  // 5. Nivel de Subsistema Individual
  let KpiIndividualSubsytem = data.getAnwersMetrics.individual/10

  // 6. Nivel de Subsistema Interpersonal
  let KpiInterpersonalSubsytem = data.getAnwersMetrics.interpersonal/10

  // 7. Nivel de Subsistema Organizacional
  let KpiOrganizativeSubsytem = data.getAnwersMetrics.organizacional/10

  // 8. Tabla de Naturalezas


  var optionsRadar = {
    series: [{
    name: 'Series 1',
    data: [80, 50, 30, 40, 100, 20],
  }],
  title: {
    text: 'Basic Radar Chart'
  },
  xaxis: {
    categories: ['January', 'February', 'March', 'April', 'May', 'June']
  }
  };

  let optionsRadarArray = []
  

  
  //Debugger de indices
  // console.log(TotalActiveClimaUsersPercent)
  // console.log(TotalRecurringUsers)
  // console.log(RepresentivityClima)
  // console.log(KpiClimaGlobal)
  // console.log(KpiIndividualSubsytem)
  // console.log(KpiInterpersonalSubsytem)
  // console.log(KpiOrganizativeSubsytem)
  

  


  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <CardClimind id="c-c-01" title={"Usuarios activos clima en el mes"} dataPercent={TotalActiveClimaUsersPercent}/>
            </Grid>
            <Grid item xs={4}>
              <CardClimind id="c-c-02" title={"Nivel de recurrencia en clima"} dataPercent={TotalRecurringUsers}/>
            </Grid>
            <Grid item xs={4}>
              <CardClimind id="c-c-03" title={"Nivel de representatividad"} dataPercent={RepresentivityClima}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            {/* <Grid item xs={3}>
            <ThermometerGauge id="c-t-01" title="Clima de la organización" dataPercent={0.4}/>
            </Grid> */}
            <Grid item xs={4}>
            <Gauge id="c-g-04" title={"Subsistema Individual"} dataPercent={KpiIndividualSubsytem}/>
            </Grid>
            <Grid item xs={4}>
            <Gauge id="c-g-05" title={"Subsistema Interpersonal"} dataPercent={KpiInterpersonalSubsytem}/>
            </Grid>
            <Grid item xs={4}>
            <Gauge id="c-g-06" title={"Subsistema Organizacional"} dataPercent={KpiOrganizativeSubsytem}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TablaClimind id="c-t-01" title={"Naturalezas & KPI"} arrayNatures={arrayNatures}/>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            {/* <Grid item xs={3}>
            <ThermometerGauge id="c-t-01" title="Clima de la organización" dataPercent={0.4}/>
            </Grid> */}
            <Grid item xs={4}>
            <RadarSpider id="c-r-01" title="Subsistema 1" optionsRadar={optionsRadar}/>
            </Grid>
            <Grid item xs={4}>
            <RadarSpider id="c-r-02" title="Subsistema 2" optionsRadar={optionsRadar}/>
            </Grid>
            <Grid item xs={4}>
            <RadarSpider id="c-r-03" title="Subsistema 3" optionsRadar={optionsRadar}/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );

  }
}
