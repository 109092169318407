import React, { Fragment, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Button, Tooltip } from "@material-ui/core";
import { POSSIBLE_ANSWER_CLIMA_TODAY } from "data/queries";
import { UserContext } from "../../../context/UserContext";
import { useQuery } from "@apollo/react-hooks";
import pregunta from "../../../assets/images/climind/faq.svg";
import thanks from "../../../assets/images/climind/thank-you.svg";
export default function ActionQuestion({ toggleQuestion, dataClima }) {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  // const { loading, error, data, refetch, networkStatus } = useQuery(
  //   POSSIBLE_ANSWER_CLIMA_TODAY,
  //   {
  //     variables: { id: dbuser.getUserByUid.id },
  //     skip: !dbuser.getUserByUid.id,
  //     notifyOnNetworkStatusChange: true,
  //   }
  // );
  // if (networkStatus === 4) return "Refetching!";
  // if (loading) return "Esperando";
  // if (error) return `Error! ${error}`;
  if (dataClima) {
    // if (true) {
    if (dataClima.possibleToAnswerClima === true) {
      return (
        <Card className="p-4" style={{ height: 220 }}>
          <div className="card-tr-left">
            <div className="d-flex flex-row align-items-center">
              <div className="d-flex flex-row align-items-center">
                <Tooltip
                  classes={{
                    tooltip: "text-center p-3 tooltip-secondary",
                  }}
                  arrow
                  placement="bottom"
                  title="Gana 30 puntos por contestar"
                >
                  <div
                    className=" avatar-icon rounded-circle "
                    style={{ width: 30, height: 30 }}
                  >
                    <img
                      alt="..."
                      src={require("../../../assets/images/climind/coin-final.png")}
                    />
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="card-tr-actions">
            <div className="d-flex flex-row align-items-center">
              <Tooltip
                classes={{
                  tooltip: "text-center p-3 tooltip-secondary",
                }}
                arrow
                placement="top"
                title="LAS RESPUESTAS SON SIEMPRE ANÓNIMAS. (Puedes cambiar la privacidad en tu perfil)"
              >
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="text-first font-weight-bold"
                >
                  <div
                    className=" avatar-icon rounded-circle mr-2"
                    style={{ width: 30, height: 30 }}
                  >
                    <img
                      alt="..."
                      src={require("../../../assets/images/climind/candado.svg")}
                    />
                  </div>
                </a>
              </Tooltip>
              <Tooltip
                classes={{
                  tooltip: "text-center p-3 tooltip-secondary",
                }}
                arrow
                placement="top"
                title="Contesta la pregunta de Clima de forma diaria y ayuda a tu empresa a saber como se encuentra el clima laboral y poder tomar acción"
              >
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="text-first font-weight-bold"
                >
                  <div
                    className=" avatar-icon rounded-circle mr-2"
                    style={{ width: 26, height: 26 }}
                  >
                    <img
                      alt="..."
                      src={require("../../../assets/images/climind/info.png")}
                    />
                  </div>
                </a>
              </Tooltip>
            </div>
          </div>
          <div className="d-flex flex-column align-items-center">
            <h3 className="font-size-lg font-weight-bold mb-4">
              Pregunta diaria de clima laboral
            </h3>
            <img
              alt="..."
              className="card-img-top rounded-sm mb-2"
              src={pregunta}
              style={{ width: 70 }}
            />

            <div className="d-flex justify-content-between">
              <Button
                onClick={toggleQuestion}
                variant="text"
                className="btn-outline-primary mt-1"
              >
                <span className="btn-wrapper--label">Contestar</span>
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={["fas", "arrow-right"]} />
                </span>
              </Button>
            </div>
          </div>
        </Card>
      );
    } else {
      return (
        <Card className="text-center" style={{ height: 220 }}>
          <div className="card-tr-actions">
            <div className="d-flex flex-row align-items-center">
              <Tooltip
                classes={{
                  tooltip: "text-center p-3 tooltip-secondary",
                }}
                arrow
                placement="top"
                title="LAS RESPUESTAS SON SIEMPRE ANÓNIMAS. (Puedes cambiar la privacidad en tu perfil)"
              >
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="text-first font-weight-bold"
                >
                  <div
                    className=" avatar-icon rounded-circle mr-2"
                    style={{ width: 30, height: 30 }}
                  >
                    <img
                      alt="..."
                      src={require("../../../assets/images/climind/candado.svg")}
                    />
                  </div>
                </a>
              </Tooltip>
              <Tooltip
                classes={{
                  tooltip: "text-center p-3 tooltip-secondary",
                }}
                arrow
                placement="top"
                title="Contesta la pregunta de Clima de forma diaria y ayuda a tu empresa a saber como se encuentra el clima laboral y poder tomar acción"
              >
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="text-first font-weight-bold"
                >
                  <div
                    className=" avatar-icon rounded-circle mr-2"
                    style={{ width: 26, height: 26 }}
                  >
                    <img
                      alt="..."
                      src={require("../../../assets/images/climind/info.png")}
                    />
                  </div>
                </a>
              </Tooltip>
            </div>
          </div>
          <div
            className="py-2 d-flex align-items-center justify-content-center"
            style={{ marginTop: 50 }}
          >
            <h4 className="font-weight-bold font-size-lg text-black">
              ¡Ya has contestado hoy!
            </h4>
          </div>
          <img
            alt="..."
            className="card-img-top rounded-sm"
            src={thanks}
            style={{ width: 70 }}
          />
        </Card>
      );
    }
  } else {
    return null
  }
}
