import React, { useEffect, useState, useContext } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";

import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
    Grid,
    Collapse,
    Dialog,
    Menu,
    Card,
    Button,
    List,
    ListItem,
    Tooltip,
    FormControl,
    InputLabel,
    Input,
    MenuItem,
    Select,
    Checkbox,
    ListItemText,
    Box,
    TextField,
    Chip
} from '@material-ui/core';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { UserContext } from "../../context/UserContext";
import { GET_DEPARTMENTS, GET_GROUPS } from "data/queries";
import { least } from "d3-array";

export default function Filters({
    setFiltersInput,
    setFiltersPreviousInput,
}) {
    const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
    let companyId = dbuser.getUserByUid.companyId;
    let userId = dbuser.getUserByUid.id;
    let isManager = dbuser.getIfIsManager;
    let role = dbuser.getUserByUid.role;
    // console.log(isManager);
    // console.log(role);
    // console.log(dbuser.getUserByUid);

    const optionsPeriods = [{ label: 'Últimos 30 días', id: 1 }, { label: 'Últimos 7 días', id: 2 },
    { label: 'Esta semana', id: 3 }, { label: 'La semana pasada', id: 4 },
    { label: 'Este mes', id: 5 }, { label: 'El mes pasado', id: 6 },
    { label: 'Personalizado', id: 0 }]

    const optionsComparativePeriods = [{ label: 'Personalizado', id: 0 }, { label: 'Periodo anterior', id: 1 }]

    let sexOptions = []

    const { loading: loadingDepartments, error: errorDepartments, data: dataDepartments, networkStatus: netDepartments } = useQuery(GET_DEPARTMENTS, {
        variables: { id: companyId },
        skip: !companyId,
        notifyOnNetworkStatusChange: true,
    });
    const { loading: loadingGroups, error: errorGroups, data: dataGroups, networkStatus: netGroups } = useQuery(GET_GROUPS, {
        variables: { id: companyId },
        skip: !companyId,
        notifyOnNetworkStatusChange: true,
    });

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    let initialsFilters = {
        initDate: moment().subtract(30, 'days').format('MM-DD-YYYY'),
        finishDate: moment().format('MM-DD-YYYY'),
        departments: [],
        groups: [],
        genders: []
    }

    let initialsPreviousFilters = {
        initDate: moment().subtract(60, 'days').format('MM-DD-YYYY'),
        finishDate: moment().subtract(30, 'days').format('MM-DD-YYYY'),
        departments: [],
        groups: [],
        genders: []
    }

    // let optionsDepartment = []
    // let optionsGroup = []

    const [optionsDepartment, setOptionsDepartment] = useState([]);
    const [optionsGroup, setOptionsGroup] = useState([]);


    const [filtersInputTmp, setFiltersInputTmp] = useState(initialsFilters)

    const [filtersPreviousInputTmp, setFiltersPreviousInputTmp] = useState(initialsPreviousFilters)

    const [period, setPeriod] = useState({ label: 'Últimos 30 días', id: 1 })
    const [periodPrevious, setPeriodPrevious] = useState({ label: 'Periodo anterior', id: 1 })

    useEffect(() => {
        if (dataDepartments && dataGroups) {
            if (role === 'admin') {

                let optionsDepartmentTmp = dataDepartments.getDepartmentsByCompanyId
                let optionsGroupTmp = dataGroups.getGroupsByCompanyId
                // console.log(optionsDepartmentTmp);

                optionsDepartmentTmp = optionsDepartmentTmp.filter(department => department.dataUsers.length >= 6)
                optionsGroupTmp = optionsGroupTmp.filter(group => group.dataUsers.length >= 6)

                setOptionsDepartment(optionsDepartmentTmp)
                setOptionsGroup(optionsGroupTmp)
                // console.log(optionsDepartmentTmp);


            } else {
                if (isManager) {
                    let optionsDepartmentTmp = dataDepartments.getDepartmentsByCompanyId
                    let optionsGroupTmp = dataGroups.getGroupsByCompanyId

                    optionsDepartmentTmp = optionsDepartmentTmp.filter(department => department.dataManager.length > 0 && department.dataManager[0]._id === userId && department.dataUsers.length >= 6)
                    optionsGroupTmp = optionsGroupTmp.filter(group => group.dataManager.length > 0 && group.dataManager[0]._id === userId && group.dataUsers.length >= 6)

                    let arrayDepartments = optionsDepartmentTmp.map(({ id }) => id)
                    let arrayGroups = optionsGroupTmp.map(({ id }) => id)

                    initialsFilters = { ...initialsFilters, departments: arrayDepartments, groups: arrayGroups }
                    setOptionsDepartment(optionsDepartmentTmp)
                    setOptionsGroup(optionsGroupTmp)
                    setFiltersInputTmp(initialsFilters)
                    setFiltersInput(initialsFilters)

                    // console.log(optionsDepartmentTmp);
                    // console.log(optionsGroupTmp);

                }
            }
        }
    }, [isManager, dataDepartments, dataGroups])
    /*useEffect(() => {
        if (dataDepartments && dataGroups) {
            if (isManager) {
                if (filtersInputTmp.departments.length < 1 || filtersInputTmp.groups.length < 1) {
                    let optionsDepartmentTmp = dataDepartments.getDepartmentsByCompanyId
                    let optionsGroupTmp = dataGroups.getGroupsByCompanyId

                    optionsDepartmentTmp = optionsDepartmentTmp.filter(department => department.dataManager.length > 0 && department.dataManager[0]._id === userId && department.dataUsers.length >= 6)
                    optionsGroupTmp = optionsGroupTmp.filter(group => group.dataManager.length > 0 && group.dataManager[0]._id === userId && group.dataUsers.length >= 6)

                    let arrayDepartments = optionsDepartmentTmp.map(({ id }) => id)
                    let arrayGroups = optionsGroupTmp.map(({ id }) => id)

                    initialsFilters = { ...initialsFilters, departments: arrayDepartments, groups: arrayGroups }
                    initialsPreviousFilters = { ...initialsPreviousFilters, departments: arrayDepartments, groups: arrayGroups }

                    setOptionsDepartment(optionsDepartmentTmp)
                    setOptionsGroup(optionsGroupTmp)

                    setFiltersInputTmp({ ...filtersInputTmp, departments: arrayDepartments, groups: arrayGroups })
                    setFiltersPreviousInputTmp({ ...filtersPreviousInputTmp, departments: arrayDepartments, groups: arrayGroups });
                
                }
            }
        }
    }, [isManager, dataDepartments, dataGroups,filtersInputTmp,filtersPreviousInputTmp])*/

    if (dataDepartments === undefined || dataGroups === undefined) {
        return null
    }

    if (dataDepartments && dataGroups) {
        // console.log(dataDepartments);

        function calculateSexFilters() {
            sexOptions = []
            if (filtersInputTmp.departments.length > 0 || filtersInputTmp.groups.length > 0) {
                let maleFilter = 0
                let femaleFilter = 0
                if (filtersInputTmp.departments.length > 0) {
                    // console.log(filtersInputTmp);
                    filtersInputTmp.departments.map(department => {
                        let result = optionsDepartment.find(depa => depa.id == department)
                        // console.log(result);
                        result.dataUsers.length > 0 && result.dataUsers.map(user => {
                            if (user.gender === 'MALE') {
                                maleFilter += 1
                            }
                            if (user.gender === 'FEMALE') {
                                femaleFilter += 1
                            }
                        })
                    })
                }
                if (filtersInputTmp.groups.length > 0) {
                    filtersInputTmp.groups.map(group => {
                        let result = optionsGroup.find(depa => depa.id === group)
                        result.dataUsers.length > 0 && result.dataUsers.map(user => {
                            if (user.gender === 'MALE') {
                                maleFilter += 1
                            }
                            if (user.gender === 'FEMALE') {
                                femaleFilter += 1
                            }
                        })
                    })
                }
                if (maleFilter > 5) {
                    sexOptions.push({ label: 'Hombre', value: 'MALE', disabled: false })
                } else {
                    sexOptions.push({ label: 'Hombre', value: 'MALE', disabled: true })
                }
                if (femaleFilter > 5) {
                    sexOptions.push({ label: 'Mujer', value: 'FEMALE', disabled: false })
                } else {
                    sexOptions.push({ label: 'Mujer', value: 'FEMALE', disabled: true })
                }
                // console.log(sexOptions);
            } else {
                sexOptions = [{ label: 'Hombre', value: 'MALE', disabled: false }, { label: 'Mujer', value: 'FEMALE', disabled: false }]
            }
        }

        calculateSexFilters()

        const handleChangePeriod = (event) => {
            switch (event.target.value) {
                case 0:
                    // setFiltersInputTmp({
                    //     ...filtersInputTmp,
                    //     initDate: moment().subtract(30, 'days').format('MM-DD-YYYY'),
                    //     finishDate: moment().format('MM-DD-YYYY')
                    // })
                    setPeriod({ label: 'Personalizado', id: 0 })
                    // periodPrevious.id != 0 && setFiltersPreviousInputTmp({
                    //     ...filtersPreviousInputTmp,
                    //     initDate: moment().subtract(1, 'month').startOf('month').format('MM-DD-YYYY'),
                    //     finishDate: moment().subtract(1, 'month').endOf('month').format('MM-DD-YYYY')
                    // })
                    break;
                case 1:
                    setFiltersInputTmp({
                        ...filtersInputTmp,
                        initDate: moment().subtract(30, 'days').format('MM-DD-YYYY'),
                        finishDate: moment().format('MM-DD-YYYY')
                    })
                    setPeriod({ label: 'Últimos 30 días', id: 1 })
                    periodPrevious.id != 0 && setFiltersPreviousInputTmp({
                        ...filtersPreviousInputTmp,
                        initDate: moment().subtract(60, 'days').format('MM-DD-YYYY'),
                        finishDate: moment().subtract(30, 'days').format('MM-DD-YYYY')
                    })
                    break;
                case 2:
                    setFiltersInputTmp({
                        ...filtersInputTmp,
                        initDate: moment().subtract(7, 'days').format('MM-DD-YYYY'),
                        finishDate: moment().format('MM-DD-YYYY')
                    })
                    setPeriod({ label: 'Últimos 7 días', id: 2 })
                    periodPrevious.id != 0 && setFiltersPreviousInputTmp({
                        ...filtersPreviousInputTmp,
                        initDate: moment().subtract(14, 'days').format('MM-DD-YYYY'),
                        finishDate: moment().subtract(7, 'days').format('MM-DD-YYYY')
                    })
                    break;
                case 3:
                    setFiltersInputTmp({
                        ...filtersInputTmp,
                        initDate: moment().startOf('week').format('MM-DD-YYYY'),
                        finishDate: moment().endOf('week').format('MM-DD-YYYY')
                    })
                    setPeriod({ label: 'Esta semana', id: 3 })
                    periodPrevious.id != 0 && setFiltersPreviousInputTmp({
                        ...filtersPreviousInputTmp,
                        initDate: moment().subtract(1, 'week').startOf('week').format('MM-DD-YYYY'),
                        finishDate: moment().subtract(1, 'week').endOf('week').format('MM-DD-YYYY')
                    })
                    break;
                case 4:
                    setFiltersInputTmp({
                        ...filtersInputTmp,
                        initDate: moment().subtract(1, 'week').startOf('week').format('MM-DD-YYYY'),
                        finishDate: moment().subtract(1, 'week').endOf('week').format('MM-DD-YYYY')
                    })
                    setPeriod({ label: 'La semana pasada', id: 4 })
                    periodPrevious.id != 0 && setFiltersPreviousInputTmp({
                        ...filtersPreviousInputTmp,
                        initDate: moment().subtract(2, 'week').startOf('week').format('MM-DD-YYYY'),
                        finishDate: moment().subtract(2, 'week').endOf('week').format('MM-DD-YYYY')
                    })
                    break;
                case 5:
                    setFiltersInputTmp({
                        ...filtersInputTmp,
                        initDate: moment().startOf('month').format('MM-DD-YYYY'),
                        finishDate: moment().endOf('month').format('MM-DD-YYYY')
                    })
                    setPeriod({ label: 'Este mes', id: 5 })
                    periodPrevious.id != 0 && setFiltersPreviousInputTmp({
                        ...filtersPreviousInputTmp,
                        initDate: moment().subtract(1, 'month').startOf('month').format('MM-DD-YYYY'),
                        finishDate: moment().subtract(1, 'month').endOf('month').format('MM-DD-YYYY')
                    })
                    break;
                case 6:
                    setFiltersInputTmp({
                        ...filtersInputTmp,
                        initDate: moment().subtract(1, 'month').startOf('month').format('MM-DD-YYYY'),
                        finishDate: moment().subtract(1, 'month').endOf('month').format('MM-DD-YYYY')
                    })
                    setPeriod({ label: 'El mes pasado', id: 6 })
                    periodPrevious.id != 0 && setFiltersPreviousInputTmp({
                        ...filtersPreviousInputTmp,
                        initDate: moment().subtract(2, 'month').startOf('month').format('MM-DD-YYYY'),
                        finishDate: moment().subtract(2, 'month').endOf('month').format('MM-DD-YYYY')
                    })
                    break;

                default:
                    break;
            }
        }
        const handleChangePreviousPeriod = (event) => {
            switch (event.target.value) {
                case 0:
                    // setFiltersPreviousInputTmp({
                    //     ...filtersPreviousInputTmp,
                    //     initDate: moment().subtract(1, 'month').startOf('month').format('MM-DD-YYYY'),
                    //     finishDate: moment().subtract(1, 'month').endOf('month').format('MM-DD-YYYY')
                    // })
                    setPeriodPrevious({ label: 'Personalizado', id: 0 })
                    break;
                case 1:
                    switch (period.id) {
                        case 0:
                            // setFiltersPreviousInputTmp({
                            //     ...filtersPreviousInputTmp,
                            //     initDate: moment().subtract(60, 'days').format('MM-DD-YYYY'),
                            //     finishDate: moment().subtract(30, 'days').format('MM-DD-YYYY')
                            // })
                            break;
                        case 1:
                            setFiltersPreviousInputTmp({
                                ...filtersPreviousInputTmp,
                                initDate: moment().subtract(60, 'days').format('MM-DD-YYYY'),
                                finishDate: moment().subtract(30, 'days').format('MM-DD-YYYY')
                            })
                            break;
                        case 2:
                            setFiltersPreviousInputTmp({
                                ...filtersPreviousInputTmp,
                                initDate: moment().subtract(14, 'days').format('MM-DD-YYYY'),
                                finishDate: moment().subtract(7, 'days').format('MM-DD-YYYY')
                            })
                            break;
                        case 3:
                            setFiltersPreviousInputTmp({
                                ...filtersPreviousInputTmp,
                                initDate: moment().subtract(1, 'week').startOf('week').format('MM-DD-YYYY'),
                                finishDate: moment().subtract(1, 'week').endOf('week').format('MM-DD-YYYY')
                            })
                            break;
                        case 4:
                            setFiltersPreviousInputTmp({
                                ...filtersPreviousInputTmp,
                                initDate: moment().subtract(2, 'week').startOf('week').format('MM-DD-YYYY'),
                                finishDate: moment().subtract(2, 'week').endOf('week').format('MM-DD-YYYY')
                            })
                            break;
                        case 5:
                            setFiltersPreviousInputTmp({
                                ...filtersPreviousInputTmp,
                                initDate: moment().subtract(1, 'month').startOf('month').format('MM-DD-YYYY'),
                                finishDate: moment().subtract(1, 'month').endOf('month').format('MM-DD-YYYY')
                            })
                            break;
                        case 6:
                            setFiltersPreviousInputTmp({
                                ...filtersPreviousInputTmp,
                                initDate: moment().subtract(2, 'month').startOf('month').format('MM-DD-YYYY'),
                                finishDate: moment().subtract(2, 'month').endOf('month').format('MM-DD-YYYY')
                            })
                            break;
                    }
                    setPeriodPrevious({ label: 'Periodo anterior', id: 1 })
                    break;


                default:
                    break;
            }
        }
        const handleInitDateChange = date => {
            let month = date.getMonth() + 1
            let year = date.getFullYear()
            let day = date.getDate()
            let dateF = month + '-' + day + '-' + year
            setFiltersInputTmp({ ...filtersInputTmp, initDate: dateF });
        };
        const handleFinishDateChange = date => {
            let month = (date.getMonth() + 1).toString().padStart(2, "0");
            let year = date.getFullYear()
            let day = date.getDate().toString().padStart(2, "0");
            let dateF = month + '-' + day + '-' + year
            setFiltersInputTmp({ ...filtersInputTmp, finishDate: dateF });
            setFiltersInput(filtersInputTmp)
        };
        const handleInitDatePreviousChange = date => {
            let month = date.getMonth() + 1
            let year = date.getFullYear()
            let day = date.getDate()
            let dateF = month + '-' + day + '-' + year
            setFiltersPreviousInputTmp({ ...filtersPreviousInputTmp, initDate: dateF });
        };
        const handleFinishDatePreviousChange = date => {
            let month = (date.getMonth() + 1).toString().padStart(2, "0");
            let year = date.getFullYear()
            let day = date.getDate().toString().padStart(2, "0");
            let dateF = month + '-' + day + '-' + year
            setFiltersPreviousInputTmp({ ...filtersPreviousInputTmp, finishDate: dateF });
        };
        const handleChangeDepartments = (event) => {
            setFiltersInputTmp({ ...filtersInputTmp, departments: event.target.value, genders: [] });
            setFiltersPreviousInputTmp({ ...filtersPreviousInputTmp, departments: event.target.value, genders: [] });
            calculateSexFilters()
        };
        const handleChangeGroups = (event) => {
            setFiltersInputTmp({ ...filtersInputTmp, groups: event.target.value, genders: [] });
            setFiltersPreviousInputTmp({ ...filtersPreviousInputTmp, groups: event.target.value, genders: [] });
        };
        const handleChangeGender = (event) => {
            setFiltersInputTmp({ ...filtersInputTmp, genders: event.target.value });
            setFiltersPreviousInputTmp({ ...filtersPreviousInputTmp, genders: event.target.value });
        };

        const handleCleanFilters = (event) => {
            let filtersClean = {
                initDate: moment().subtract(30, 'days').format('MM-DD-YYYY'),
                finishDate: moment().format('MM-DD-YYYY'),
                departments: [],
                groups: [],
                genders: []
            }
            let filtersPreviousClean = {
                initDate: moment().subtract(60, 'days').format('MM-DD-YYYY'),
                finishDate: moment().subtract(30, 'days').format('MM-DD-YYYY'),
                departments: [],
                groups: [],
                genders: []
            }


            // Funcion para limpiar los filtros de los selects

            if (role === 'admin') {

                setFiltersInputTmp(filtersClean);
                setFiltersInput(filtersClean);
                setFiltersPreviousInputTmp(filtersPreviousClean);
                setFiltersPreviousInput(filtersPreviousClean);


            } else {
                if (isManager) {
                    let optionsDepartmentTmp = dataDepartments.getDepartmentsByCompanyId
                    let optionsGroupTmp = dataGroups.getGroupsByCompanyId

                    optionsDepartmentTmp = optionsDepartmentTmp.filter(department => department.dataManager.length > 0 && department.dataManager[0]._id === userId && department.dataUsers.length >= 6)
                    optionsGroupTmp = optionsGroupTmp.filter(group => group.dataManager.length > 0 && group.dataManager[0]._id === userId && group.dataUsers.length >= 6)

                    let arrayDepartments = optionsDepartmentTmp.map(({ id }) => id)
                    let arrayGroups = optionsGroupTmp.map(({ id }) => id)

                    initialsFilters = { ...initialsFilters, departments: arrayDepartments, groups: arrayGroups }
                    initialsPreviousFilters = { ...initialsPreviousFilters, departments: arrayDepartments, groups: arrayGroups }

                    setOptionsDepartment(optionsDepartmentTmp)
                    setOptionsGroup(optionsGroupTmp)

                    setFiltersInputTmp(initialsFilters)
                    setFiltersInput(initialsFilters)
                    setFiltersPreviousInputTmp(initialsPreviousFilters);
                    setFiltersPreviousInput(initialsPreviousFilters);
                    // console.log(optionsDepartmentTmp);
                    // console.log(optionsGroupTmp);
                }
            }

            setPeriod({ label: 'Últimos 30 días', id: 1 })
            setPeriodPrevious({ label: 'Periodo anterior', id: 1 })
        };
        const handleApplyFilter = (event) => {
            let optionsDepartmentTmp = dataDepartments.getDepartmentsByCompanyId
            let optionsGroupTmp = dataGroups.getGroupsByCompanyId

            optionsDepartmentTmp = optionsDepartmentTmp.filter(department => department.dataManager.length > 0 && department.dataManager[0]._id === userId && department.dataUsers.length >= 6)
            optionsGroupTmp = optionsGroupTmp.filter(group => group.dataManager.length > 0 && group.dataManager[0]._id === userId && group.dataUsers.length >= 6)

            let arrayDepartments = optionsDepartmentTmp.map(({ id }) => id)
            let arrayGroups = optionsGroupTmp.map(({ id }) => id)

            // if (role != 'admin' && isManager && ((arrayDepartments.length > 0 && filtersInputTmp.departments.length < 1) || (arrayGroups.length > 0 && filtersInputTmp.groups.length < 1))) {
            if (role != 'admin' && isManager && (filtersInputTmp.departments.length + filtersInputTmp.groups.length) < 1) {
                // console.log('No se puede aplicar el filtro porque no se ha seleccionado ningún departamento o grupo');

                initialsFilters = { ...initialsFilters, departments: arrayDepartments, groups: arrayGroups }

                setOptionsDepartment(optionsDepartmentTmp)
                setOptionsGroup(optionsGroupTmp)

                setFiltersInputTmp({ ...filtersInputTmp, departments: arrayDepartments, groups: arrayGroups })
                setFiltersPreviousInput({ ...filtersPreviousInputTmp, departments: arrayDepartments, groups: arrayGroups })

            } else {
                setFiltersInput(filtersInputTmp)
                setFiltersPreviousInput(filtersPreviousInputTmp)
            }




        };

        // console.log(filtersInputTmp.groups)

        return (
            <>
                <div className="accordion mb-spacing-6">
                    <Card className={clsx('card-box', { 'panel-open': isOpen })}>
                        <Card>
                            <div className="card-header">
                                <div className="panel-title">
                                    <div className="accordion-toggle">
                                        <Button
                                            variant="text"
                                            size="large"
                                            className="btn-link d-flex align-items-center justify-content-between btn-transition-none"
                                            onClick={toggle}>
                                            <span>Filtros</span>
                                            <FontAwesomeIcon
                                                icon={['fas', 'angle-up']}
                                                className="font-size-xl accordion-icon"
                                            />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <Collapse in={isOpen}>
                                <div className="p-5">
                                    <Grid container spacing={6}>
                                        <Grid item md={4}>
                                            <div className="app-page-title--filters" align="center">
                                                <FormControl variant="outlined" className="w-100">
                                                    <InputLabel id="demo-simple-select-outlined-label">
                                                        Periodo
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        value={period.id}
                                                        onChange={handleChangePeriod}
                                                        label="Periodo"
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "left"
                                                            },
                                                            getContentAnchorEl: null
                                                        }}>
                                                        {optionsPeriods.map((period) => (
                                                            <MenuItem key={period.id} value={period.id}>{period.label}</MenuItem>
                                                        ))}

                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </Grid>
                                        <Grid item md={4}>
                                            <div className="app-page-title--filters" align="center">
                                                <FormControl variant="outlined" className="w-100">
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <div className="m-1">
                                                            <KeyboardDatePicker
                                                                disableToolbar
                                                                variant="inline"
                                                                format="dd-MM-yyyy"
                                                                margin="normal"
                                                                id="date-picker-inline"
                                                                label="Desde"
                                                                disabled={period.id != 0}
                                                                value={moment(filtersInputTmp.initDate, 'MM-DD-YYYY').toDate()}
                                                                onChange={handleInitDateChange}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                            />
                                                        </div>
                                                    </MuiPickersUtilsProvider>
                                                </FormControl>
                                            </div>
                                        </Grid>
                                        <Grid item md={4}>
                                            <div className="app-page-title--filters" align="center">
                                                <FormControl variant="outlined" className="w-100">
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <div className="m-1">
                                                            <KeyboardDatePicker
                                                                disableToolbar
                                                                variant="inline"
                                                                format="dd-MM-yyyy"
                                                                margin="normal"
                                                                id="date-picker-inline"
                                                                label="Hasta"
                                                                disabled={period.id != 0}
                                                                value={moment(filtersInputTmp.finishDate, 'MM-DD-YYYY').toDate()}
                                                                onChange={handleFinishDateChange}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                            />
                                                        </div>
                                                    </MuiPickersUtilsProvider>
                                                </FormControl>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={6}>
                                        <Grid item md={4}>
                                            <FormControl variant="outlined" className="w-100">
                                                <InputLabel id="demo-simple-select-outlined-label">
                                                    Departamentos
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-mutiple-checkbox-label"
                                                    id="demo-mutiple-checkbox-departments"
                                                    multiple
                                                    value={filtersInputTmp.departments}
                                                    disabled={optionsDepartment.length === 0 ||
                                                        optionsDepartment.length === null}
                                                    onChange={handleChangeDepartments}
                                                    input={<Input id="select-multiple-chip" />}
                                                    renderValue={(selected) => (
                                                        <div style={{ display: 'flex', flexWrap: 'Wrap' }}>
                                                            {selected.map((value) => {
                                                                // console.log(value);
                                                                return (
                                                                    <Chip key={value} label={optionsDepartment.find(el => el.id === value) != undefined && optionsDepartment.find(el => el.id === value).name} style={{ margin: 2 }} color='primary' />
                                                                    // <Chip key={value} label={value.name} style={{ margin: 2 }} color='primary' />
                                                                )
                                                            })}
                                                        </div>
                                                    )}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left"
                                                        },
                                                        getContentAnchorEl: null
                                                    }}
                                                >
                                                    {
                                                        optionsDepartment.length > 0 && optionsDepartment.map((department) => (
                                                            <MenuItem key={department.id} value={department.id} >
                                                                {department.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={4}>
                                            <FormControl variant="outlined" className="w-100">
                                                <InputLabel id="demo-simple-select-outlined-label">
                                                    Grupos
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-mutiple-checkbox-label"
                                                    id="demo-mutiple-checkbox-grupos"
                                                    multiple
                                                    value={filtersInputTmp.groups}
                                                    disabled={optionsGroup.length === 0 ||
                                                        optionsGroup.length === null}
                                                    onChange={handleChangeGroups}
                                                    input={<Input id="select-multiple-chip" />}
                                                    renderValue={(selected) => (
                                                        <div style={{ display: 'flex', flexWrap: 'Wrap' }}>
                                                            {selected.map((value) => (
                                                                <Chip key={value} label={optionsGroup.find(el => el.id === value).name} style={{ margin: 2 }} />
                                                            ))}
                                                        </div>
                                                    )}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left"
                                                        },
                                                        getContentAnchorEl: null
                                                    }}
                                                >
                                                    {optionsGroup.map((group) => (
                                                        <MenuItem key={group.id} value={group.id} label={group.name}>
                                                            {group.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={4}>
                                            <FormControl variant="outlined" className="w-100">
                                                <InputLabel id="demo-simple-select-outlined-label">
                                                    Sexo
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-mutiple-checkbox-label"
                                                    id="demo-mutiple-checkbox-grupos"
                                                    multiple
                                                    value={filtersInputTmp.genders}
                                                    onChange={handleChangeGender}
                                                    input={<Input id="select-multiple-chip" />}
                                                    renderValue={(selected) => (
                                                        <div style={{ display: 'flex', flexWrap: 'Wrap' }}>
                                                            {selected.map((value) => (
                                                                <Chip key={value} label={sexOptions.find(el => el.value === value).label} style={{ margin: 2 }} />
                                                            ))}
                                                        </div>
                                                    )}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left"
                                                        },
                                                        getContentAnchorEl: null
                                                    }}
                                                >
                                                    {sexOptions.map((sex) => (
                                                        <MenuItem key={sex.value} value={sex.value} disabled={sex.disabled}>
                                                            {sex.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <div className="mt-4 mb-4">
                                        <span >Filtros avanzados</span>
                                    </div>
                                    <Grid container spacing={6}>

                                        <Grid item md={4} >
                                            <div className="app-page-title--filters" align="center">
                                                <FormControl variant="outlined" className="w-100">
                                                    <InputLabel id="demo-simple-select-outlined-label">
                                                        Comparar
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        value={periodPrevious.id}
                                                        onChange={handleChangePreviousPeriod}
                                                        label="Periodo"
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "left"
                                                            },
                                                            getContentAnchorEl: null
                                                        }}>
                                                        {optionsComparativePeriods.map((period) => (
                                                            <MenuItem key={period.id} value={period.id}>{period.label}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </Grid>
                                        <Grid item md={4}>
                                            <div className="app-page-title--filters" align="center">
                                                <FormControl variant="outlined" className="w-100">
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <div className="m-1">
                                                            <KeyboardDatePicker
                                                                disableToolbar
                                                                variant="inline"
                                                                format="dd-MM-yyyy"
                                                                margin="normal"
                                                                id="date-picker-inline"
                                                                label="Desde"
                                                                disabled={periodPrevious.id != 0}
                                                                value={moment(filtersPreviousInputTmp.initDate, 'MM-DD-YYYY').toDate()}
                                                                onChange={handleInitDatePreviousChange}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                            />
                                                        </div>
                                                    </MuiPickersUtilsProvider>
                                                </FormControl>
                                            </div>
                                        </Grid>
                                        <Grid item md={4}>
                                            <div className="app-page-title--filters" align="center">
                                                <FormControl variant="outlined" className="w-100">
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <div className="m-1">
                                                            <KeyboardDatePicker
                                                                disableToolbar
                                                                variant="inline"
                                                                format="dd-MM-yyyy"
                                                                margin="normal"
                                                                id="date-picker-inline"
                                                                label="Hasta"
                                                                disabled={periodPrevious.id != 0}
                                                                value={moment(filtersPreviousInputTmp.finishDate, 'MM-DD-YYYY').toDate()}
                                                                onChange={handleFinishDatePreviousChange}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                            />
                                                        </div>
                                                    </MuiPickersUtilsProvider>
                                                </FormControl>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={6}>
                                        <Grid item md={6}>
                                            <div className="p-1 text-center">
                                                <Button className="btn-primary" size="small" onClick={handleApplyFilter}>
                                                    Aplicar Filtros
                                                </Button>
                                            </div>
                                        </Grid>
                                        <Grid item md={6}>
                                            <div className="p-1 text-center">
                                                <Button className="btn-primary" size="small" onClick={handleCleanFilters}>
                                                    Quitar Filtros
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Collapse>
                        </Card>
                    </Card>
                </div>
            </>
        );
    }
}
