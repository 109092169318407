import React, {useContext, useCallback, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Button,
  TextField,
  Snackbar,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions
} from '@material-ui/core';

import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';

import firebase from 'firebase'
import { UserContext } from '../context/UserContext'
import { withRouter, Redirect } from 'react-router'
import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
export default function Login() {

    console.log("dentro de LOGIN")
    
    const [email, setEmail] = useState("")
    const [emailReset, setEmailReset] = useState("")
    const [password, setPassword] = useState("")
    const {currentUser, dbuser, getUserWithFirebase} = useContext(UserContext)
    const [myMessage, setMyMessage] = useState(null)
    const [openSnackbarDanger, setOpenSnackbarDanger] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [modalAddModal, setModalAddModal] = useState(false);
    const toggleAddModal = () => {
        setModalAddModal(!modalAddModal);
        setEmailReset(null)
    }
    const history = useHistory()
    console.log(history)
    const handleLogin = useCallback(async event => {

        try {

            firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
            .then(async () => {
                
                const signInUser = await firebase
                .auth()
                .signInWithEmailAndPassword(email, password);
                if(signInUser.user.emailVerified == false){
                    console.log("Usuario sin tener verificado el correo")
                    setMyMessage("Usuario sin tener verificado el correo")
                setOpenSnackbarDanger(true, () => {
                    setTimeout(function () {
                        setOpenSnackbarDanger(false);
                      setMyMessage(null)
                    }, 5000); //5 Second delay
                  });
                    return null
                }
                console.log(signInUser)
                console.log(dbuser)
                getUserWithFirebase()
                 history.push("/")
            })
            .catch((error) => {

                console.log(error)
                setMyMessage("Usuario o contraseña incorrectos")
                setOpenSnackbarDanger(true, () => {
                    setTimeout(function () {
                        setOpenSnackbarDanger(false);
                      setMyMessage(null)
                    }, 5000); //5 Second delay
                  });
            });
            
        } catch (error) {
            console.log(error)
            setMyMessage("Usuario o contraseña incorrectos")
                setOpenSnackbarDanger(true, () => {
                    setTimeout(function () {
                        setOpenSnackbarDanger(false);
                      setMyMessage(null)
                    }, 5000); //5 Second delay
                  });
        }

    });
    const handleInputChange = (e) => {
        setEmailReset(e.target.value)
    };

        console.log("visualizar Login....")

        return (
            <>
            <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        key={"top,right"}
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={() => setOpenSnackbar(false)}
        message={"Email enviado correctamente"}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          style={{ color: "white" }}
        >
          {myMessage}
        </Alert>
      </Snackbar>
            <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        key={"top,right"}
        open={openSnackbarDanger}
        autoHideDuration={4000}
        onClose={() => setOpenSnackbarDanger(false)}
        message={"entregado correctamente"}
      >
        <Alert
          onClose={() => setOpenSnackbarDanger(false)}
          severity="warning"
          style={{ color: "white" }}
        >
          {myMessage}
        </Alert>
      </Snackbar>
      
      <Dialog
          open={modalAddModal}
          onClose={toggleAddModal}
          maxWidth="lg"
          width="80%"
          classes={{ paper: "modal-content rounded-lg" }}
        >
          <div className="text-center pt-5">
            
            <h5 className="text-center">
              Recuperar contraseña
            </h5>
          </div>
          <div className="p-4 text-center">
          <h5 className="font-size-xl mb-1 font-weight-bold mx-5">
          Introduce tu email para que te enviemos un correo de recuperación
          </h5>
          <Grid container spacing={12}>
            <Grid item md={12}>
            <Card
                className="p-4 mt-4"
                // style={{ backgroundColor: "#eceef7" }}
                elevation={0}
              >
                
                <div>
                  <TextField
                    fullWidth
                    className="m-2"
                    id="outlined-multiline-static-4"
                    name="email"
                    label="Email"
                    placeholder="Escribe aqui..."
                    defaultValue={emailReset}
                    onChange={handleInputChange}
                    variant="outlined"
                  />
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>
          <div className="text-center px-2">
            <div className="pt-4" style={{ marginBottom: 20 }}>
              <Button
                onClick={() => {
                    setEmailReset(null)
                    toggleAddModal();
                  }}
                className="btn-neutral-dark btn-pill mx-1"
              >
                <span className="btn-wrapper--label">Volver</span>
              </Button>
              <Button
                onClick={() => {
                    firebase.auth().sendPasswordResetEmail(emailReset)
                    .then(() => {
                        setMyMessage("Email enviado con éxito")
                        setOpenSnackbar(true, () => {
                            setTimeout(function () {
                            setOpenSnackbar(false);
                            setMyMessage(null)
                            }, 5000); //5 Second delay
                        });
                        setEmailReset(null)
                        toggleAddModal();
                    })
                    .catch((error) => {
                        setMyMessage("El email introducido no existe en nuestra base de datos")
                        setOpenSnackbarDanger(true, () => {
                            setTimeout(function () {
                            setOpenSnackbarDanger(false);
                            setMyMessage(null)
                            }, 5000); //5 Second delay
                        });
                    });

                  }}
                className="btn-success btn-pill mx-1"
                disabled={!emailReset}
              >
                <span className="btn-wrapper--label">Enviar</span>
              </Button>
            </div>
          </div>
        </Dialog>
            <div className="app-wrapper bg-white min-vh-100">
                <div className="app-main min-vh-100">
                <div className="app-content p-0">
                    <div className="app-content--inner d-flex align-items-center">
                    <div className="flex-grow-1 w-100 d-flex align-items-center">
                        <div className="bg-composed-wrapper--content py-5">
                        <Grid item xs={10} md={6} lg={5} xl={4} className="mx-auto">
                            <div className="text-center mb-4">
                                <img height="auto" alt="example" width={400} src={require("../assets/images/climind/logo_climind.png")}/>
                            </div>
                            
                            <div>
                            <div className="mb-4">
                                <TextField
                                fullWidth
                                variant="outlined"
                                id="textfield-email"
                                label="Email address"
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <MailOutlineTwoToneIcon />
                                    </InputAdornment>
                                    )
                                }}
                                onChange={(e) => {setEmail(e.target.value)}}
                                />
                            </div>
                            <div className="mb-3">
                                <TextField
                                fullWidth
                                variant="outlined"
                                id="textfield-password"
                                label="Password"
                                type="password"
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <LockTwoToneIcon />
                                    </InputAdornment>
                                    )
                                }}
                                onChange={(e) => {setPassword(e.target.value)}}
                                />
                            </div>
                            
                            
                            <div className="text-center py-4">
                                <Button className="btn-second font-weight-bold w-50 my-2"
                                        onClick={handleLogin}
                                >
                                Entrar
                                </Button>
                            </div>
                            <div className="text-center text-black-50 mt-3">
                                ¿No recuerdas tu contraseña?{' '}
                                <a
                                // href="#/"
                                onClick={(e) => toggleAddModal()}
                                className="text-first">
                                Recuperar contraseña
                                </a>
                            </div>
                            </div>
                        </Grid>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </>
        );
     }
    
