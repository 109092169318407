import React, { useState, useContext, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Table,
  InputLabel,
  InputAdornment,
  Card,
  MenuItem,
  Button,
  Tooltip,
  Checkbox,
  TextField,
  FormControl,
  Select,
  ButtonGroup,
  Menu,
  Dialog,
} from "@material-ui/core";

import Pagination from "@material-ui/lab/Pagination";

import avatar1 from "../../../../assets/images/avatars/avatar1.jpg";
import avatar2 from "../../../../assets/images/avatars/avatar2.jpg";
import avatar3 from "../../../../assets/images/avatars/avatar3.jpg";
import avatar4 from "../../../../assets/images/avatars/avatar4.jpg";
import avatar5 from "../../../../assets/images/avatars/avatar5.jpg";
import avatar6 from "../../../../assets/images/avatars/avatar6.jpg";
import avatar7 from "../../../../assets/images/avatars/avatar7.jpg";
import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import { UserContext } from "../../../../context/UserContext";

import { GET_GROUPS } from "data/queries";

import ModalAddGroup from "./ModalAddGroup";
import ModalEditGroup from "./ModalEditGroup";
import ModalAssignLeaderGroup from "./ModalAssignLeaderGroup";
import ModalViewTeam from "./ModalViewTeam";
import { DELETE_GROUP } from "data/mutations";

export default function GroupsTable({ compId }) {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);

  let companyId = null
  if (compId) {
    companyId = compId
  } else {
    companyId = dbuser.getUserByUid.companyId;
  }

  const { loading, error, data, networkStatus } = useQuery(GET_GROUPS, {
    variables: { id: companyId },
    skip: !companyId,
    notifyOnNetworkStatusChange: true,
  });

  const [deleteGroup, { data: dataDelete }] = useMutation(DELETE_GROUP);

  const [entries, setEntries] = useState("1");

  const [modalAddGroup, setModalAddGroup] = useState(false);
  const toggleAddGroup = () => setModalAddGroup(!modalAddGroup);

  const [modalEditGroup, setModalEditGroup] = useState(false);
  const toggleEditGroup = () => setModalEditGroup(!modalEditGroup);

  const [modalDelete, setModalDelete] = useState(false);
  const toggleDelete = () => setModalDelete(!modalDelete);

  const [modalViewTeam, setModalViewTeam] = useState(false);
  const toggleViewTeam = () => setModalViewTeam(!modalViewTeam);

  const [
    modalAssignLeaderGroup,
    setModalAssignLeaderGroup,
  ] = useState(false);
  const toggleLeaderGroup = () => setModalAssignLeaderGroup(!modalAssignLeaderGroup);

  const [groupSelected, setGroupSelected] = useState("");


  const handleChange = (event) => {
    setEntries(event.target.value);
  };

  // Menu
  const [team, setTeam] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [groupsList, setGroupsList] = useState([]);
  const [groupsListFilter, setGroupsListFilter] = useState([]);

  useEffect(() => {
    if (data != null) {
      setGroupsList([...data.getGroupsByCompanyId])
      setGroupsListFilter([...data.getGroupsByCompanyId])
    }
  }, [data])

  if (networkStatus === 4) return "Refetching!";
  if (loading) return "Esperando";
  if (error) return `Error! ${error}`;

  if (data) {
    // console.log(data);

    return (
      <>
        <Card className="card-box mb-spacing-6-x2" elevation={0}>
          <div className="d-flex justify-content-between px-4 py-3">
            <div className="d-flex align-items-center">
              <Button size="small" className="btn-neutral-primary" onClick={() => setModalAddGroup(true)}>
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={["fas", "plus-circle"]} />
                </span>
                <span className="btn-wrapper--label">Agregar Grupo</span>
              </Button>
            </div>
            <div className="search-wrapper">
              <TextField
                variant="outlined"
                size="small"
                id="input-search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchTwoToneIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={e => {
                  const filteredTable = groupsList.filter(o =>
                    Object.keys(o).some(k => k === "name" &&
                      String(o[k])
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    )
                  );
                  setGroupsListFilter(filteredTable)
                }}
              />
            </div>
          </div>
          <div className="divider" />
          <div className="table-responsive-md">
            <Table className="table table-hover text-nowrap mb-0">
              <thead>
                <tr>
                  <th className="bg-white">Nombre</th>
                  <th className="bg-white text-left" style={{ width: '40%' }}>Descripción</th>
                  <th className="bg-white text-center">Lider</th>
                  <th className="bg-white text-center">Miembros</th>
                  <th className="bg-white text-center">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {groupsListFilter.map((group, index) => {
                  return (
                    <tr key={"tGroup" + index}>
                      {/* Nombre */}
                      <td>
                        <div className="d-flex">
                          {/* <Checkbox
                              color="primary"
                              id="checkboxProjects15"
                              className="align-self-center mr-2"
                            /> */}
                          <div className="d-flex align-items-center">
                            <div className="avatar-icon-wrapper avatar-initials avatar-icon-sm pr-2">
                              <div className={`avatar-icon text-white`} style={{ backgroundColor: group.color }}>
                                {group.name.charAt(0).toUpperCase()}
                              </div>
                            </div>

                            <div>{group.name}</div>
                          </div>
                        </div>
                      </td>
                      {/* Descripción */}
                      <td>{group.description}</td>
                      {/* Managers */}
                      <td className="text-center">
                        <div className="avatar-wrapper-overlap d-flex justify-content-center">
                          {group.dataManager.map((manager, index) => {
                            // console.log(manager);
                            return (
                              <div className="avatar-icon-wrapper avatar-initials avatar-icon-sm pr-2" key={"mag" + index}>
                                <div
                                  className={`avatar-icon`}
                                // style={{ boxShadow: "0 0 0 3px " + group.color }}
                                >
                                  {manager.urlImage ? (
                                    <img
                                      alt="..."
                                      src={manager.urlImage}
                                    />
                                  ) :
                                    manager.name.charAt(0).toUpperCase()
                                  }
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </td>
                      {/* Miembros */}
                      <td className="text-center">
                        <div className="avatar-wrapper-overlap d-flex justify-content-center">

                          {group.dataUsers.length > 4
                            ? group.dataUsers.map((user, index) => {
                              if (index < 4) {
                                return (
                                  <div className="avatar-icon-wrapper avatar-initials avatar-icon-sm pr-2" key={"meg" + index}>
                                    <div
                                      className={`avatar-icon text-white`} style={{ backgroundColor: group.color }}
                                    >
                                      {user.urlImage ? (
                                        <img
                                          alt="..."
                                          src={user.urlImage}
                                        />
                                      ) :
                                        user.name.charAt(0).toUpperCase()
                                      }
                                    </div>
                                  </div>
                                );
                              }
                              if (index === 4) {
                                let totalTmp = group.dataUsers.length - 4;
                                return (
                                  <div className="avatar-icon-wrapper avatar-initials avatar-icon-sm pr-2" key={"meg2" + index}>
                                    <div
                                      className={`avatar-icon text-white`} style={{ backgroundColor: group.color }}
                                    >
                                      + {totalTmp}
                                    </div>
                                  </div>
                                );
                              } else {
                                return (
                                  <></>
                                );
                              }
                            })
                            : group.dataUsers.map((user, index) => {
                              // console.log(users);
                              return (
                                <div className="avatar-icon-wrapper avatar-initials avatar-icon-sm pr-2" key={"meg2" + index}>
                                  <div
                                    className={`avatar-icon text-white`} style={{ backgroundColor: group.color }}
                                  >
                                    {user.urlImage ? (
                                      <img
                                        alt="..."
                                        src={user.urlImage}
                                      />
                                    ) :
                                      user.name.charAt(0).toUpperCase()
                                    }
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </td>
                      {/* Acciones */}
                      <td className="text-center">
                        <Button
                          size="small"
                          className="btn-link d-30 p-0 btn-icon hover-scale-sm"
                          onClick={() => {
                            setGroupSelected(group);
                            setModalViewTeam(true);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={["far", "eye"]}
                            className="font-size-lg"
                          />
                        </Button>
                        <Button
                          size="small"
                          className="btn-link d-30 p-0 btn-icon hover-scale-sm"
                          onClick={() => {
                            setGroupSelected(group);
                            setModalEditGroup(true);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={["far", "edit"]}
                            className="font-size-lg"
                          />
                        </Button>
                        <Button
                          size="small"
                          className="btn-link d-30 p-0 btn-icon hover-scale-sm"
                          onClick={()=>{
                            setGroupSelected(group);
                            toggleDelete()}}
                        >
                          <FontAwesomeIcon
                            icon={["fas", "trash"]}
                            className="font-size-lg"
                          />
                        </Button>
                        <Dialog
                          open={modalDelete}
                          onClose={toggleDelete}
                          classes={{ paper: "shadow-lg rounded" }}
                        >
                          <div className="text-center p-5">
                            <div className="avatar-icon-wrapper rounded-circle m-0">
                              <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
                                <FontAwesomeIcon
                                  icon={["fas", "times"]}
                                  className="d-flex align-self-center display-3"
                                />
                              </div>
                            </div>
                            <h4 className="font-weight-bold mt-4">
                              ¿Estás seguro de que desas borrar este grupo?
                            </h4>
                            <p className="mb-0 font-size-lg text-muted">
                              No puedes deshacer esta operación.
                            </p>
                            <div className="pt-4">
                              <Button
                                onClick={toggleDelete}
                                className="btn-neutral-secondary btn-pill mx-1"
                              >
                                <span className="btn-wrapper--label">
                                  Cancelar
                                </span>
                              </Button>
                              <Button
                                onClick={() => {
                                  toggleDelete();
                                  deleteGroup({
                                    variables: { id: groupSelected.id },
                                    refetchQueries: [
                                      {
                                        query: GET_GROUPS,
                                        variables: { id: companyId },
                                      },
                                    ],
                                  });
                                }}
                                className="btn-danger btn-pill mx-1"
                              >
                                <span className="btn-wrapper--label">
                                  Borrar
                                </span>
                              </Button>
                            </div>
                          </div>
                        </Dialog>
                      </td>
                    </tr>
                  );
                })
                }
              </tbody>
            </Table>
            {groupsListFilter.length === 0 && (
              <div className="text-center my-5">
                <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-primary shadow-primary-sm text-primary mb-2 d-90">
                  <FontAwesomeIcon
                    icon={["far", "gem"]}
                    className="d-flex align-self-center font-size-xxl"
                  />
                </div>
                <h6 className="font-weight-bold font-size-xxl mb-1 mt-3 text-primary">
                  No tienes grupos registrados
                </h6>
                <p className="text-black-50 font-size-lg mb-0">
                  Registra grupos en tu compañía para continuar
                </p>
              </div>
            )}
          </div>
          {/* <div className="card-footer py-3 d-flex justify-content-between">
            <Pagination
              className="pagination-second"
              variant="outlined"
              count={10}
            />
            <div className="d-flex align-items-center">
              <span>Mostrar</span>
              <FormControl size="small" variant="outlined" className="mx-3">
                <InputLabel id="select-entries-label">Número</InputLabel>
                <Select
                  labelId="select-entries-label"
                  id="select-entries"
                  value={entries}
                  onChange={handleChange}
                  label="Entries"
                >
                  <MenuItem className="mx-2" value={1}>
                    Todos
                  </MenuItem>
                  <MenuItem className="mx-2" value={10}>
                    10
                  </MenuItem>
                  <MenuItem className="mx-2" value={15}>
                    15
                  </MenuItem>
                  <MenuItem className="mx-2" value={20}>
                    20
                  </MenuItem>
                  <MenuItem className="mx-2" value={25}>
                    25
                  </MenuItem>
                  <MenuItem className="mx-2" value={30}>
                    30
                  </MenuItem>
                </Select>
              </FormControl>
              <span>empleados</span>
            </div>
          </div> */}
        </Card>
        <ModalAddGroup openAddGroup={modalAddGroup} toggleAddGroup={toggleAddGroup} />
        {modalEditGroup && (
          <ModalEditGroup
            openEditGroup={modalEditGroup}
            toggleEditGroup={toggleEditGroup}
            group={groupSelected}
            compId={compId}
          />
        )}
        {modalAssignLeaderGroup && (
          <ModalAssignLeaderGroup
            openLeaderGroup={modalAssignLeaderGroup}
            toggleLeaderGroup={toggleLeaderGroup}
            groupSelected={groupSelected}
            compId={compId}
          />
        )}
        {modalViewTeam && (
          <ModalViewTeam
            openViewTeam={modalViewTeam}
            toggleViewTeam={toggleViewTeam}
            teamData={groupSelected}
            type={"group"}
            compId={compId}
          />
        )}
      </>
    );
  }
}
