import React, { useState, useCallback, useContext } from "react";

import clsx from 'clsx';
import { GET_USERS_WITH_CIE_BY_COMPANY_ID, } from 'data/queries';
import { PageTitle } from 'layout-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserContext } from "../../../context/UserContext";
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import {
  Table,
  Grid,
  InputLabel,
  InputAdornment,
  Card,
  Menu,
  MenuItem,
  Button,
  List,
  ListItem,
  TextField,
  LinearProgress,
  FormControl,
  Select
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

import Trend from 'react-trend';

import FilterListTwoToneIcon from '@material-ui/icons/FilterListTwoTone';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import SaveTwoToneIcon from '@material-ui/icons/SaveTwoTone';

import ArrowDownwardTwoToneIcon from '@material-ui/icons/ArrowDownwardTwoTone';
import ArrowUpwardTwoToneIcon from '@material-ui/icons/ArrowUpwardTwoTone';
import RadioButtonUncheckedTwoToneIcon from '@material-ui/icons/RadioButtonUncheckedTwoTone';

import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';
import avatar from "../../../assets/images/climind/default_avatar.png";
import RowTableRanking from "./RowTableRanking"
export default function Ranking() {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  const { loading: loadingUsers, error: errorUsers, data: dataUsers, networkStatus: networkStatusUsers, refetch: refetchUsers } = useQuery(GET_USERS_WITH_CIE_BY_COMPANY_ID,
    {
      variables: { id: dbuser.getUserByUid.id },
      skip: !dbuser.getUserByUid.id,
      notifyOnNetworkStatusChange: true,
    })
  const [searchOpen, setSearchOpen] = useState(false);
  const [filterTable, setFilterTable] = useState(null)
  const openSearch = () => setSearchOpen(true);
  const closeSearch = () => setSearchOpen(false);

  if (dataUsers) {



    return (
      <>
        <PageTitle
          titleHeading="Ranking"
          titleDescription="Observa la participación de los empleados y motívalos."
        />

        <Card className="card-box mb-spacing-6-x2">


          <div className="divider" />
          <div className="px-4 py-3">
            <div
              className={clsx(
                'search-wrapper search-wrapper--alternate search-wrapper--grow',
                { 'is-active': searchOpen }
              )}>
              <TextField
                variant="outlined"
                size="small"
                id="input-with-icon-textfield22-2"
                onFocus={openSearch}
                onBlur={closeSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchTwoToneIcon />
                    </InputAdornment>
                  )
                }}
                onChange={async e => {
                  // const filteredTable = dataUsers.getCompanyUsersCie.filter(o =>
                  //   Object.keys(o).some(k => k === "name" &&
                  //     String(o[k])
                  //       .toLowerCase()
                  //       .includes(e.target.value.toLowerCase())
                  //   )
                  // );
                  let filter = e.target.value.toLowerCase();
                  const filteredTable = await dataUsers.getCompanyUsersCie.filter(
                    (o) => {
                      let dataFilter = o.name
                        .toLowerCase()
                        .concat(" ", o.surname.toLowerCase());
                      let dataFilter2 = o.email.toLowerCase();
                      return (
                        dataFilter.indexOf(filter) !== -1 ||
                        dataFilter2.indexOf(filter) !== -1
                      );
                    }

                  );

                  setFilterTable(filteredTable)
                }}
              />
            </div>
          </div>
          <div className="divider" />
          <div className="pt-4 px-4">
            <Table className="table table-alternate-spaced text-nowrap mb-0">
              <thead className="bg-white font-size-sm text-uppercase">
                <tr>
                  <th className="bg-white text-center" style={{ width: '5%' }}>Posición</th>
                  <th className="bg-white text-left" style={{ width: '25%' }}>Usuario</th>
                  <th className="bg-white text-center" style={{ width: '10%' }}>Coins</th>
                  <th className="bg-white text-center" style={{ width: '10%' }}>Puntos</th>
                  <th className="bg-white text-center" style={{ width: '10%' }}>Nivel</th>
                  <th className="bg-white text-center" style={{ width: '20%' }}>Cie Mensual</th>
                  <th className="bg-white text-center" style={{ width: '10%' }}>Cie Histórico</th>
                </tr>
              </thead>
              <tbody>
                {!filterTable ? dataUsers.getCompanyUsersCie.map((element, index) => {

                  return (
                    <RowTableRanking element={element} index={index} />
                  )

                })
                  :

                  filterTable.map((element, index) => {

                    return (
                      <RowTableRanking element={element} index={index} />
                    )

                  })}
              </tbody>
            </Table>
          </div>
          {/* <div className="p-4 d-flex justify-content-center">
          <Pagination className="pagination-primary" count={10} />
        </div> */}
        </Card>
      </>
    );
  } else {
    return null
  }
}
