import React, { useState, useCallback, useContext } from "react";
import { GET_COMPETENCES_BY_USER_ID } from "data/queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "../../../../context/UserContext";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  Card,
  Button,
  Tooltip,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Input,
  Select,
} from "@material-ui/core";
import { EDIT_GROUP } from "../../../../data/mutations";
import { GET_GROUPS } from "data/queries";

// Forms
import { useFormik } from "formik";
import * as Yup from "yup";

export default function ModalAddGroup({
  openEditGroup,
  toggleEditGroup,
  group,
  compId
}) {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  let companyId = null;
  if (compId) {
    companyId = compId;
  } else {
    companyId = dbuser.getUserByUid.companyId;
  }

  const [editGroup, { data }] = useMutation(EDIT_GROUP);
  const [values, setValues] = useState({
    name: "",
    description: "",
    color: "",
  });
  let thumb = "";
  const { name, description, color } = values;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  const handleSelectChange = (event) => {
    setValues({ ...values, color: event.target.value });
  };

  const formik = useFormik({
    initialValues: {
      name: group.name,
      description: group.description,
      color: group.color,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Obligatorio"),
      description: Yup.string().required("Obligatorio"),
      color: Yup.string().required("Obligatorio"),
    }),
    onSubmit: async (data, { setErrors, resetForm }) => {
      try {
        let dataInput = {
          name: data.name,
          description: data.description,
          color: data.color,
        };
        await editGroup({ 
          variables: { id: group.id ,input: dataInput },
          refetchQueries: [
            {
              query: GET_GROUPS,
              variables: { id: companyId },
            },
          ], });
        resetForm();
        toggleEditGroup();
      } catch (err) {
        console.log(err);
      }
    },
  });

  return (
    <>
      <Dialog
        open={openEditGroup}
        onClose={toggleEditGroup}
        classes={{ paper: "modal-content rounded-lg" }}
      >
        <DialogTitle className="bg-white-10">Editar grupo</DialogTitle>
        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <div className="text-center my-auto">
                  <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-primary shadow-primary-sm text-primary mb-2 d-90">
                    <FontAwesomeIcon
                      icon={["fas", "building"]}
                      className="d-flex align-self-center font-size-xxl"
                    />
                  </div>
                  <h6 className="font-weight-bold font-size-xxl mb-1 mt-3 text-primary">
                    Información básica del grupo
                  </h6>
                </div>
                <Card
                  className="p-4 mt-4"
                  style={{ backgroundColor: "#eceef7" }}
                  elevation={0}
                >
                  <div>
                    <TextField
                      fullWidth
                      className="m-2"
                      id="name"
                      name="name"
                      label="Nombre"
                      placeholder="Escribe aqui..."
                      variant="outlined"
                      {...formik.getFieldProps("name")}
                      error={Boolean(formik.touched.name && formik.errors.name)}
                      helperText={formik.touched.name ? formik.errors.name : ""}
                    />
                    <TextField
                      fullWidth
                      className="m-2"
                      id="description"
                      name="description"
                      label="Descripción"
                      placeholder="Escribe aqui..."
                      variant="outlined"
                      {...formik.getFieldProps("description")}
                      error={Boolean(
                        formik.touched.description && formik.errors.description
                      )}
                      helperText={
                        formik.touched.description
                          ? formik.errors.description
                          : ""
                      }
                    />
                    <FormControl fullWidth variant="outlined" className="m-2">
                    <InputLabel id="demo-simple-select-outlined-label">
                      Color
                    </InputLabel>
                    <Input type="color" style={{width: '100px'}} name="color" id="color" {...formik.getFieldProps("color")}></Input>
                  </FormControl>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="p-4 bg-white-10">
            <Button
              className="btn-neutral-primary"
              onClick={() => toggleEditGroup()}
              type="reset"
            >
              Cancelar
            </Button>{" "}
            <Button
              className="btn-primary ml-auto"
              type="submit"
            >
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
