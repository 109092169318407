import React, { useEffect, useState,useContext } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GaugeChart from "react-gauge-chart";
import {
  Grid,
  LinearProgress,
  Card,
  CardContent,
  Button,
} from "@material-ui/core";

import LinearGauge from "../../../../climind-components/Linear-Gauge/LinearGauge";
import Gauge from "../../../../climind-components/Gauge/Gauge";
import ChartClimind from "../../../../climind-components/Chart/Chart";

import { UserContext } from "../../../../context/UserContext";

import { GETS_FOR_USAGE } from "data/queries";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
  },
}));

export default function UsageDashboard() {
  const classes = useStyles();
  const [currentPercent2, setCurrentPercent2] = useState();

  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  let companyId = dbuser.getUserByUid.companyId;

  const { loading, error, data, networkStatus } = useQuery(GETS_FOR_USAGE, {
    variables: { id: companyId },
    skip: !companyId,
    notifyOnNetworkStatusChange: true,
  });

  if (networkStatus === 4) return "Refetching!";
  if (loading) return "Esperando";
  if (error) return `Error! ${error}`;

  if(data){
  
  // Número total de empleados (Entero) - QUERY
  let TotalUsers = data.getNumberOfUsersByCompanyId.count

  // Número total de empleados Activos (Entero) - QUERY
  let TotalActiveUsers = data.getNumberOfActiveUsersByCompanyId.count

  // Array de usuarios activos (Array) - QUERY
  let ArrayActivityUsersData = []
  data.getUsersCounted.map((item,index)=>{
    let value=item.count
    ArrayActivityUsersData.push(value)
  })

  // Nivel de usuarios activos
  let TotalActiveUsersPercent = TotalActiveUsers/TotalUsers

  // Número de días contabilizados
  let TotalDays = ArrayActivityUsersData.length

  // Nivel de recurrencia (Array)
  let ArrayRecurringUsers = []
  let tmpTotalRecurringUsers = 0
  ArrayActivityUsersData.map((item, index)=>{
    let tmpRecurringDay = item/TotalUsers
    tmpTotalRecurringUsers = tmpTotalRecurringUsers + tmpRecurringDay
    ArrayRecurringUsers.push(tmpRecurringDay*100)
  })
  let TotalRecurringUsers = tmpTotalRecurringUsers/TotalDays

  // Nivel KPI total de uso 
  let KpiGlobalUsage = TotalRecurringUsers*TotalActiveUsersPercent

  // Array de fechas
  let date = new Date()
  date.setDate(date.getDate()-TotalDays)
  let ArrayDates = []
  for (let i=0;i<TotalDays;i++){
    let tmpDate = date.toLocaleDateString()
    date.setDate(date.getDate()+1)
    ArrayDates.push(tmpDate)
  }



  // Datos para el chart de evolución
  const dataSeriesChart = [
    {
      name: "Recurrencia",
      data: ArrayRecurringUsers,
    },
  ];
  const dataOptionsChart = {
    xaxis: {
      categories:ArrayDates,
      labels: {
        rotate: -15,
        rotateAlways: true,
      }
    },
    yaxis: {
      labels: {
        formatter: function(val) {
          return val + '%'
        }
      }
    }
  };


  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={5}>
          <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="center"
            className="h-100"
          >
            <Grid item>
              <Gauge id="u-g-01" title={"Usuarios Activos"} dataPercent={TotalActiveUsersPercent} />
            </Grid>
            <Grid item>
              <Gauge
                id="u-g-02"
                title={"Nivel de recurrencia"}
                dataPercent={TotalRecurringUsers}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={7}>
          <ChartClimind
            id="u-chart-01"
            title={"Evolución del uso"}
            dataSeriesChart={dataSeriesChart}
            dataOptionsChart={dataOptionsChart}
          />
        </Grid>
        <Grid item xs={12}>
          <LinearGauge
            id="u-lguage-01"
            title={"KPI de uso global"}
            subtitle={
              "Producto de el Porcentaje de usuarios activos por el Nivel de recurrencia"
            }
            dataPercent={KpiGlobalUsage}
          />
        </Grid>
      </Grid>
    </div>
  );
  }
}
