import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GaugeChart from "react-gauge-chart";
import {
  Grid,
  LinearProgress,
  Card,
  CardContent,
  Button,
} from "@material-ui/core";

import Chart from "react-apexcharts";

export default function RadarSpider({
  id,
  title,
  optionsRadar,
}) {
  console.log(optionsRadar)
 var series= [{
  name: 'Series 1',
  data: [80, 50, 30, 40, 100, 20],
}]
var options = {
xaxis: {
  categories: ['January', 'February', 'March', 'April', 'May', 'June']
}
};
  return (
    <Card className="card-box">
      <div className="card-header">
        <div className="card-header--title">
          <h4 className="font-size-lg mb-0 py-1 font-weight-bold">{title}</h4>
        </div>
      </div>
      <CardContent>
        <div>
          { optionsRadar &&
            <Chart
            // options={optionsRadar}
            type="radar"
            series={series}
            id={"radar-clima-" + id}
            options={options}
          />}
        </div>
      </CardContent>
    </Card>
  );
}
