import React, { useEffect, useState, useContext } from "react";

import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  Container,
  InputAdornment,
  ButtonGroup,
  Card,
  Button,
  Dialog,
  List,
  ListItem,
  TextField,
  LinearProgress,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Table,
  CardContent,
  Tooltip
} from "@material-ui/core";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";

import PerfectScrollbar from "react-perfect-scrollbar";
import { useDropzone } from "react-dropzone";
import CountUp from "react-countup";

import CloseTwoToneIcon from "@material-ui/icons/CloseTwoTone";
import PublishTwoToneIcon from "@material-ui/icons/PublishTwoTone";
import AccountCircleTwoToneIcon from "@material-ui/icons/AccountCircleTwoTone";
import CheckIcon from "@material-ui/icons/Check";
import { UserContext } from "../../../context/UserContext";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
  } from '@material-ui/pickers';
  import 'date-fns';
  import DateFnsUtils from '@date-io/date-fns';
  import avatar1 from '../../../assets/images/avatars/avatar1.jpg';
  import avatar2 from '../../../assets/images/avatars/avatar2.jpg';
  import avatar4 from '../../../assets/images/avatars/avatar4.jpg';
  import avatar5 from '../../../assets/images/avatars/avatar5.jpg';
  import avatar6 from '../../../assets/images/avatars/avatar6.jpg';
  import avatar7 from '../../../assets/images/avatars/avatar7.jpg';
  import { GET_USER_BY_ID, GETS_FOR_GENERAL, GET_USER_WITH_GROUPS } from "../../../data/queries";
  import defaultAvatar from "../../../assets/images/climind/default_avatar.png";
export default function Groups( {user}) {
    const [selectedDate, setSelectedDate] = useState(new Date('2020-08-18'));
    const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
    const toggleUsers = () => setModalUsers(!modalUsers);
    const [modalUsers, setModalUsers] = useState(false);
    const [groupSelected, setGroupSelected] = useState(null);
    const { loading:loadingGroups, error:errorGroups, data:dataGroups, networkStatus:networkStatusGroups } = useQuery(
      GET_USER_WITH_GROUPS,
      {
        variables: { userId: user ? user.id : dbuser.getUserByUid.id },
        skip: user ? !user.id : !dbuser.getUserByUid.id,
        notifyOnNetworkStatusChange: true,
      }
    );
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleBack = async (e) => {
    setGroupSelected(null)
    toggleUsers();
  };

  const [age, setAge] = useState('');

  const handleChangeAge = (event) => {
    setAge(event.target.value);
  };

  if(dataGroups){
    console.log(dataGroups)
    return (
      <>
<Grid container spacing={6}>
        <Grid item xl={12}>
<Card className="card-box mb-spacing-6-x2">
      <div className="card-header pr-2">
        <div className="card-header--title">Grupos</div>
        
      </div>
      <CardContent>
        <div className="table-responsive-md">
          <Table className="table table-borderless table-hover text-nowrap mb-0">
            <thead>
              <tr>
                <th className="text-left">Nombre</th>
                <th className="text-left">Descripción</th>
                <th className="text-right" style={{width:20}}>Ver información</th>
              </tr>
            </thead>
            <tbody>
            {
                dataGroups.getUserWithGroups.groups.map((item, index) => (
                  <tr>
                <td>
                  <div className="d-flex align-items-center">
                    
                    <div>
                      <a
                        href="#/"
                        onClick={(e) => e.preventDefault()}
                        className="font-weight-bold text-black"
                        title="...">
                        {item.name}
                      </a>
                      
                    </div>
                  </div>
                </td>
                <td  >
                  <div className="d-flex align-items-center">
                    
                    <div>
                     
                      <span className="text-black-50 d-block" style={{overflow:"auto"}}>
                      {item.description.substring(0,60)}...
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                <Tooltip title="Ver empleado" arrow placement="top">
                  <div className="d-flex justify-content-center">
                          <Button
                            size="small"
                            className="btn-link d-30 p-0 btn-icon hover-scale-sm"
                            onClick={() => {
                              setGroupSelected(item)
                              setModalUsers(true)
                            }}
                          >
                            <FontAwesomeIcon
                              icon={["far", "eye"]}
                              className="font-size-lg"
                            />
                          </Button>
                          </div>
                          </Tooltip>
                          </td>
              </tr>
                ))
              }
             
              
              
            </tbody>
          </Table>
        </div>
      </CardContent>
     
    </Card>
    <Card className="card-box mb-spacing-6-x2">
      <div className="card-header pr-2">
        <div className="card-header--title">Departamentos</div>
        
      </div>
      <CardContent>
        <div className="table-responsive-md">
          <Table className="table table-borderless table-hover text-nowrap mb-0">
            <thead>
              <tr>
                <th className="text-left">Nombre</th>
                <th className="text-left">Descripción</th>
                <th className="text-right" style={{width:20}}>Ver información</th>
              </tr>
            </thead>
            <tbody>
            {
                dataGroups.getUserWithGroups.departments.map((item, index) => (
                  <tr>
                <td>
                  <div className="d-flex align-items-center">
                    
                    <div>
                      <a
                        href="#/"
                        onClick={(e) => e.preventDefault()}
                        className="font-weight-bold text-black"
                        title="...">
                        {item.name}
                      </a>
                     
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    
                    <div>
                     
                      <span className="text-black-50 d-block">
                      {item.description.substring(0,60)}...
                      </span>
                    </div>
                  </div>
                </td>
                <td className="text-center">
                <Tooltip title="Ver grupo" arrow placement="top">
                  <div className="d-flex justify-content-center">
                          <Button
                            size="small"
                            className="btn-link d-30 p-0 btn-icon hover-scale-sm"
                            onClick={() => {
                              setGroupSelected(item)
                              console.log(item.users)
                              setModalUsers(true)
                            }}
                          >
                            <FontAwesomeIcon
                              icon={["far", "eye"]}
                              className="font-size-lg"
                            />
                          </Button>
                          </div>
                          </Tooltip>
                          </td>
              </tr>
                ))
              }
             
              
              
            </tbody>
          </Table>
        </div>
      </CardContent>
     
    </Card>
    </Grid>
    </Grid>
    {groupSelected && <Dialog
      open={modalUsers}
      onClose={handleBack}
      maxWidth="lg"
      width="80%"
      classes={{ paper: "modal-content rounded-lg" }}
    >
      <div className="text-center">

      <Card className="card-box mb-spacing-6-x2 m-3">
                    <CardContent>
                      <div className="table-responsive-xl">
                        <Table className="table table-borderless table-hover text-nowrap mb-0">
                          <thead>
                            <tr>
                              <th className="text-left">Lider {groupSelected.name}</th>
                            </tr>
                            
                           
                          </thead>
                          <tbody>
                          {groupSelected.manager && <div className="d-flex align-items-center">
                                            <div className="avatar-icon-wrapper mr-3">
                                                <div className="avatar-icon">
                                                {groupSelected.manager.urlImage ? <img alt="..." src={groupSelected.manager.urlImage} /> : <img alt="..." src={defaultAvatar}/>}
                                                </div>
                                            </div>
                                            <div>
                                                <a
                                                href="#/"
                                                onClick={(e) => e.preventDefault()}
                                                className="font-weight-bold text-black"
                                                title="..."
                                                >
                                                
                                                {`${groupSelected.manager.name}`+" "+`${groupSelected.manager.surname}`}
                                                </a>
                                                <span className="text-black-50 d-block">
                                                {groupSelected.manager.email}
                                                </span>
                                            </div>
                                            </div>}
                            
                          </tbody>
                        </Table>
                      </div>
                      <div className="table-responsive-xl">
                        <Table className="table table-borderless table-hover text-nowrap mb-0">
                          <thead>
                            
                            <tr>
                              <th className="text-left">Compañeross {groupSelected.name}</th>
                            </tr>
                          </thead>
                          <tbody>
                              {groupSelected.users.map((element, index) => {
                                  return(
                                    <tr>
                                        <td>
                                            <div className="d-flex align-items-center">
                                            <div className="avatar-icon-wrapper mr-3">
                                                <div className="avatar-icon">
                                                {element.urlImage ? <img alt="..." src={element.urlImage} /> : <img alt="..." src={defaultAvatar}/>}
                                                </div>
                                            </div>
                                            <div>
                                                <a
                                                href="#/"
                                                onClick={(e) => e.preventDefault()}
                                                className="font-weight-bold text-black"
                                                title="..."
                                                >
                                                
                                                {`${element.name}`+" "+`${element.surname}`}
                                                </a>
                                                <span className="text-black-50 d-block">
                                                {element.email}
                                                </span>
                                            </div>
                                            </div>
                                        </td>
                                        
                                    </tr>
                                  )
                              }) 
                              }
                            
                          </tbody>
                        </Table>
                      </div>
                    </CardContent>
                  </Card>
                  <div className="pt-4" style={{ marginBottom: 20 }}>
            <Button
              onClick={handleBack}
              className="btn-neutral-success btn-pill mx-1"
            >
              <span className="btn-wrapper--label">Cerrar</span>
            </Button>
            
          </div>
          </div>

    </Dialog>}
    </>
  )
  }else{
    return null
  }
    }