import gql from 'graphql-tag';

export const SAVE_ANSWER = gql`
  mutation saveAnswer($input: AnswerInput!) {
    saveAnswer(input: $input){
      id
      companyId
      userId
      questionId
      date
      answer
      commentary
    }
  }
`;

export const SAVE_ANSWER_EMOTION = gql`
  mutation saveAnswerEmotion($input: AnswerEmotionInput!) {
    saveAnswerEmotion(input: $input){
    id
    companyId
    userId
    date
    answer
    value
  }
  }
`;

export const NEW_RECOGNITION = gql`
  mutation newRecognition($input:RecognitionInput!){
    newRecognition(input: $input){
      companyId
      sender
      receiver
      date
      commentary
      competenceId
    }
  }
`;

export const NEW_IMPROVEMENT_PROPOSAL = gql`
  mutation newImprovementProposal($input:ImprovementProposalInput!){
    newImprovementProposal(input: $input){
      companyId
      sender
      receiver
      date
      commentary
      competenceId
    }
  }
`;

export const IMPORT_USERS = gql`
  mutation addUsersFromCsv($input: String!, $companyId: ID!, $fields: String!) {
    addUsersFromCsv(input: $input, companyId: $companyId, fields: $fields){
      name
      surname
      email
      dni
      role
      gender
      position
      error
    }
  }
`;


export const NEW_USER = gql`
  mutation newUser($input: UserInput!) {
    newUser(input: $input){
      dni
    }
  }
`;

export const EDIT_USER = gql`
  mutation editUser($id: ID!, $input: UserEditInput!) {
    editUser(id: $id, input: $input) {
      dni
    }
  }
`;


export const EDIT_USER_BASIC_INFO = gql`
  mutation editUserBasicInfo($id: ID!, $input: UserInputBasicInfo!) {
    editUserBasicInfo(id: $id, input: $input) {
      name
    }
  }
`;

export const NEW_DEPARTMENT = gql`
  mutation newDepartment($input: DepartmentInput!) {
    newDepartment(input: $input){
      name
    }
  }
`;
export const EDIT_DEPARTMENT = gql`
  mutation editDepartment($id: ID!, $input: DepartmentInputEdit!) {
    editDepartment(id: $id, input: $input){
      name
    }
  }
`;

export const NEW_GROUP = gql`
  mutation newGroup($input: GroupInput!) {
    newGroup(input: $input){
      name
    }
  }
`;
export const EDIT_GROUP = gql`
  mutation editGroup($id: ID!, $input: GroupInputEdit!) {
    editGroup(id: $id, input: $input){
      name
    }
  }
`;

export const PREVIOUS_STEP = gql`
mutation previousStep($input: PreviousStep!) {
  previousStep(input: $input){
    id
    companyId
    username
    useTerms
  }
}
`;

export const CHANGE_PRIVACITY = gql`
  mutation changePrivacity($id:ID!, $input:PrivacityInput!){
    changePrivacity(id: $id, input:$input){
      commentaryQuestionInTimeline
      commentaryQuestionForAdmins
      commentaryEmotionInTimeline
      commentaryEmotionForAdmins
      recognitionInTimeline
    }
  }
`;

export const NEW_CONGRATULATION = gql`
  mutation newCongratulation($input:CongratulationInput!){
    newCongratulation(input: $input){
      companyId
      sender
      receiver
      date
      commentary
    }
  }
`;

export const NEW_GRATITUDE = gql`
  mutation newGratitude($input:GratitudeInput!){
    newGratitude(input: $input){
      companyId
      sender
      receiver
      date
      commentary
    }
  }
`;

export const NEW_COMPANY = gql`
  mutation newCompany($input:CompanyInput!){
    newCompany(input: $input){
      name
    }
  }
`;

export const ADD_USERS_TO_DEPARTMENT = gql`
  mutation addUsersToDepartment($id: ID!, $users: [ID!]!) {
    addUsersToDepartment(id: $id, users: $users){
      name
    }
  }
`;
export const DELETE_USER_FROM_DEPARTMENT = gql`
  mutation deleteUsersFromDepartment($id: ID!, $users: [ID!]!) {
    deleteUsersFromDepartment(id: $id, users: $users){
      name
    }
  }
`;

export const NEW_COMPETENCE = gql`
  mutation newCompetence($input:CompetenceInput!){
    newCompetence(input: $input){
      id
      name
      description
      entitiesInvolved
      type
      complexity
      color
      urlImage
      active
    }
  }
`;

export const ADD_USERS_TO_GROUP = gql`
  mutation addUsersToGroup($id: ID!, $users: [ID!]!) {
    addUsersToGroup(id: $id, users: $users){
      name
    }
  }
`;
export const DELETE_USER_FROM_GROUP = gql`
  mutation deleteUsersFromGroup($id: ID!, $users: [ID!]!) {
    deleteUsersFromGroup(id: $id, users: $users){
      name
    }
  }
`;

export const EDIT_COMPETENCE = gql`
  mutation editCompetence($id: ID!, $input: CompetenceEditInput!) {
    editCompetence(id: $id ,input: $input){
      id
      name
      description
      entitiesInvolved
      type
      complexity
      color
      urlImage
      active
    }
  }
`;

export const EDIT_LEADER_GROUP = gql`
  mutation editLeaderGroup($id:ID!, $user:ID!){
    editLeaderGroup(id: $id, user:$user){
      name
      color
      companyId
    }
  }
`;

export const NEW_BEHAVIOR = gql`
  mutation newBehavior($input:BehaviorInput!){
    newBehavior(input: $input){
      id
      name
      description
      competenceId
      active
    }
  }
`;

export const EDIT_LEADER_DEPARTMENT = gql`
  mutation editLeaderDepartment($id:ID!, $user:ID!){
    editLeaderDepartment(id: $id, user:$user){
      name
      color
      companyId
    }
  }
`;
export const EDIT_BEHAVIOR = gql`
  mutation editBehavior($id: ID!, $input: BehaviorEditInput!) {
    editBehavior(id: $id ,input: $input){
      id
      name
      description
    }
  }
`;
export const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id){
      id
    }
  }
`;
export const DELETE_GROUP = gql`
  mutation deleteGroup($id: ID!) {
    deleteGroup(id: $id){
      id
    }
  }
`;
export const DELETE_DEPARTMENT = gql`
  mutation deleteDepartment($id: ID!) {
    deleteDepartment(id: $id){
      id
    }
  }
`;

export const DELETE_AWARD = gql`
  mutation deleteAward($id: ID!) {
    deleteAward(id: $id){
      id
    }
  }
`;

export const EDIT_COMPANY = gql`
  mutation editCompany($id: ID!, $input: CompanyInput!) {
    editCompany(id: $id ,input: $input){
      name
    }
  }
`;

export const SAVE_USER_IMAGE = gql`
  mutation saveUserImage($input: UserImageInput!) {
    saveUserImage(input: $input){
      id
      urlImage
    }
  }
`;

export const CHANGE_STATE = gql`
  mutation changeState($id: ID!, $input: State!){
    changeState(id: $id, input: $input){
      id
    }
  }
`;

export const CHANGE_STATE_AWARD = gql`
  mutation changeStateAward($id: ID!, $input: StateAward!){
    changeStateAward(id: $id, input: $input){
      id
    }
  }
`;


export const NEW_AWARD = gql`
  mutation newAward($input:AwardInput!){
    newAward(input: $input){
      id
      companyId
      name
      description
      price
      urlImage
    }
  }
`;

export const EDIT_AWARD = gql`
  mutation editAward($id: ID!, $input: AwardEditInput!) {
    editAward(id: $id ,input: $input){
      id
      name
      description
      price
      urlImage
    }
  }
`;

export const NEW_TICKET = gql`
  mutation newTicket($input:TicketInput!){
    newTicket(input: $input)
  }
`;

export const CHANGE_PASSWORD_USER = gql`
  mutation changePasswordUser($input: UserChangePassword!) {
    changePasswordUser(input: $input)
  }
`;

export const NEW_ACHIEVED_AWARD = gql`
  mutation newAchievedAward($input:AchievedAwardInput!){
    newAchievedAward(input: $input){
      id
      companyId
      price
      awardId
      userId
    }
  }
`;

export const NEW_ENTITY = gql`
  mutation newEntity($input:EntityInput!){
    newEntity(input: $input){
      name
      description
      parent
      id
      color
    }
  }
`;

export const NEW_QUESTION = gql`
mutation newQuestion($input:QuestionInput!){
  newQuestion(input: $input){
    id
    name
    active
    entityId
  }
}
`;

export const EDIT_ENTITY = gql`
  mutation editEntity($id: ID!, $input: EntityEditInput!) {
    editEntity(id: $id ,input: $input){
      id
      name
      description
      urlImage
      parent
      color
    }
  }
`;

export const EDIT_QUESTION = gql`
  mutation editQuestion($id: ID!, $input: QuestionEditInput!) {
    editQuestion(id: $id ,input: $input){
      id
      name
      description
    }
  }
`;

export const NEW_OKR = gql`
  mutation newOkr($input: OkrInput!) {
    newOkr(input: $input){
    id
    }
  }
`;

export const EDIT_OKR = gql`
  mutation editOkr($okrId: ID!, $input: OkrEditInput!) {
    editOkr(okrId: $okrId, input: $input){
    id
    }
  }
`;

export const NEW_METRIC = gql`
  mutation newMetric($keyResultNumber: Int!, $okrId: ID!, $input: MetricInput!) {
    newMetric(keyResultNumber: $keyResultNumber, okrId: $okrId, input: $input){
    id
    }
  }
`;

export const NEW_CHALLENGE = gql`
  mutation newChallenge($userId: ID!, $input: ChallengeInput!) {
    newChallenge(userId: $userId ,input: $input){
      id
      userId
      companyId
      name
      description
      criteria
      forAllCompany
      departments
      groups
      competences
      reward
      date
      initDate
      endDate
      urlImage
      validated
      users
  	  awardedUsers
      endedUsers
    }
  }
`;

export const EDIT_CHALLENGE = gql`
  mutation editChallenge($userId: ID!, $challengeId: ID!, $input: ChallengeEditInput!) {
    editChallenge(userId: $userId, challengeId: $challengeId, input: $input){
      id
      userId
      companyId
      name
      description
      criteria
      forAllCompany
      departments
      groups
      competences
      reward
      date
      initDate
      endDate
      urlImage
      validated
      users
  	  awardedUsers
      endedUsers
    }
  }
`;

export const REGISTER_TO_CHALLENGE = gql`
  mutation registerToChallenge($userId: ID!, $challengeId: ID!) {
    registerToChallenge(userId: $userId, challengeId: $challengeId){
      id
      userId
      companyId
      name
      description
      criteria
      forAllCompany
      departments
      groups
      competences
      reward
      date
      initDate
      endDate
      urlImage
      validated
      users
  	  awardedUsers
      endedUsers
    }
  }
`;

export const UNREGISTER_TO_CHALLENGE = gql`
  mutation unregisterToChallenge($userId: ID!, $challengeId: ID!) {
    unregisterToChallenge(userId: $userId, challengeId: $challengeId){
      id
      userId
      companyId
      name
      description
      criteria
      forAllCompany
      departments
      groups
      competences
      reward
      date
      initDate
      endDate
      urlImage
      validated
      users
  	  awardedUsers
      endedUsers
    }
  }
`;

export const DELETE_CHALLENGE = gql`
  mutation deleteChallenge($userId: ID!, $challengeId: ID!) {
    deleteChallenge(userId: $userId, challengeId: $challengeId){
      id
      userId
      companyId
      name
      description
      criteria
      forAllCompany
      departments
      groups
      competences
      reward
      date
      initDate
      endDate
      urlImage
      validated
      users
  	  awardedUsers
      endedUsers
    }
  }
`;

export const USER_REWARD = gql`
  mutation userReward($userId: ID!, $challengeId: ID!, $userRewardId: ID!) {
    userReward(userId: $userId, challengeId: $challengeId, userRewardId: $userRewardId){
      id
      userId
      companyId
      name
      description
      criteria
      forAllCompany
      departments
      groups
      competences
      reward
      date
      initDate
      endDate
      urlImage
      validated
      users
  	  awardedUsers
      endedUsers
    }
  }
`;


export const FINISH_CHALLENGE = gql`
  mutation finishChallenge($userId: ID!, $challengeId: ID!) {
    finishChallenge(userId: $userId, challengeId: $challengeId){
      id
      userId
      companyId
      name
      description
      criteria
      forAllCompany
      departments
      groups
      competences
      reward
      date
      initDate
      endDate
      urlImage
      validated
      users
  	  awardedUsers
      endedUsers
    }
  }
`;

export const DELETE_OKR = gql`
  mutation deleteOkr($id: ID!) {
    deleteOkr(id: $id){
      id
    }
  }
`;