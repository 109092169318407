import React, { useState, useCallback, useContext } from 'react';
import { PageTitle } from "layout-components";
import ModalSelectUser from "./components/ModalSelectUser";
import ModalSelectCompetence from "./components/ModalSelectCompetence";
import defaultCompetence from "../../assets/images/climind/default_competence.png";
import defaultAvatar from "../../assets/images/climind/default_avatar.png";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link } from 'react-router-dom'
import {NEW_RECOGNITION} from '../../data/mutations';
import {GET_USER_BY_UID,GET_TIMELINE,GETS_FOR_GENERAL} from '../../data/queries';
import { useMutation } from '@apollo/react-hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserContext } from "../../context/UserContext";
import {
  Grid,
  Container,
  Card,
  Button,
  TextField,
  Switch
} from '@material-ui/core';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import CommentIcon from '@material-ui/icons/Comment';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DashboardIcon from '@material-ui/icons/Dashboard';
import StepConnector from '@material-ui/core/StepConnector';



const Step1 = ({toggleUser, userSelected}) => {
  return (
    <>
      <Container>
        <div className="p-4 ">
          <h5 className="font-size-xl mb-1 font-weight-bold">
          Selecciona el usuario al que vas a entregar el reconocimiento
          </h5>
          <p className="text-black-50 mb-4">Obligatorio</p>
          <Grid container spacing={6} className="justify-content-center">
            <Grid item md={6}>
            <Button
            className="btn-success text-uppercase font-weight-bold font-size-xs mb-5"
            style={{ backgroundColor: "#5a318e" }}
            onClick={toggleUser}
          >
            Seleccionar usuario
          </Button>
          {userSelected ? (
            <>
              <div className="d-flex align-items-center justify-content-center mb-5">
                <div className="avatar-icon-wrapper mr-3">
                  <div className="avatar-icon">
                    {userSelected.urlImage ? (
                      <img alt="..." src={userSelected.urlImage} />
                    ) : (
                      <img alt="..." src={defaultAvatar} />
                    )}
                  </div>
                </div>
                <div>
                  <a
                    href="#/"
                    onClick={(e) => e.preventDefault()}
                    className="font-weight-bold text-black"
                    title="..."
                  >
                    {userSelected.name}
                  </a>
                  <span className="text-black-50 d-block">
                    UI Engineer, Apple Inc.
                  </span>
                </div>
              </div>
            </>
          ) : null}
            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  );
};

const Step2 = ({toggleCompetence, competenceSelected}) => {



  return (
    <>
      <Container>
        <div className="p-4">
          <h5 className="font-size-xl mb-1 font-weight-bold">
            Selecciona una competencia
          </h5>
          <p className="text-black-50 mb-4">
            Opcional
          </p>
          <Grid container spacing={6} className="justify-content-center">
            <Grid item md={6}>
            <Button
            className="btn-success text-uppercase font-weight-bold font-size-xs mb-5"
            style={{ backgroundColor: "#5a318e" }}
            onClick={toggleCompetence}
          >
            Seleccionar competencia
          </Button>
          {competenceSelected ? (
            <>
              <div className="d-flex align-items-center justify-content-center  mb-5">
                <div className="avatar-icon-wrapper mr-3">
                  <div className="avatar-icon">
                    {competenceSelected.urlImage ? (
                      <img alt="..." src={competenceSelected.urlImage} />
                    ) : (
                      <img alt="..." src={defaultCompetence} />
                    )}
                  </div>
                </div>
                <div>
                  <a
                    href="#/"
                    onClick={(e) => e.preventDefault()}
                    className="font-weight-bold text-black"
                    title="..."
                  >
                    {competenceSelected.name}
                  </a>
                </div>
              </div>
            </>
          ) : null}
            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  );
};
const Step3 = ({setCommentary, handleChangeCheckTimeline, checkTimeline}) => {
    const handleCommentaryChange = useCallback( (e) => {
        setCommentary(e.target.value);
      });
  return (
    <>
        <Container>
        <div className="p-4">
          <h5 className="font-size-xl mb-1 font-weight-bold">
          Explicación del reconocimiento
          </h5>
          <p className="text-black-50 mb-4">Obligatorio</p>
          <Grid container spacing={6}>
            <Grid item md={12}>
            <TextField
              variant="outlined"
              className="m-3"
              fullWidth
              id="standard-multiline-static"
              label="Escribe aquí"
              multiline
              rows="4"
              defaultValue=""
              onChange={handleCommentaryChange}
            />
            <div
                style={{ width: "80%", marginLeft: 100 }}
                className="text-left"
              >
                <div className="d-flex flex-row align-items-center justify-content-center">
                  <div className="m-2">
                    <Switch
                      onChange={handleChangeCheckTimeline}
                      checked={checkTimeline}
                      color="primary"
                      name="checkedA"
                      className="switch-small"
                    />
                  </div>
                  <h6 className="mb-0">
                  Quiero que mi comentario sea público y se vea en el Timeline (Mínimo 10 caracteres){" "}
                  </h6>
                </div>
  
              </div>
        <div >   
        </div> 
            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  );
};

function StepIcon(props) {
  const { active, completed } = props;

  const icons = {
    1: <PersonAddIcon />,
    2: <DashboardIcon />,
    3: <CommentIcon />

  };

  return (
    <div
      className={clsx(
        'd-50 transition-base d-flex align-items-center bg-gray-400 justify-content-center rounded',
        {
          'd-80 bg-primary text-white shadow-primary-sm': active,
          'd-50 bg-success text-white shadow-success-sm': completed
        }
      )}>
      {completed ? <Check className="completed" /> : icons[String(props.icon)]}
    </div>
  );
}

StepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node
};

function getSteps(userSelected, competenceSelected) {
  return [userSelected ? userSelected.name :'Seleccionar usuario', competenceSelected ? competenceSelected.name :'Añadir competencia', 'Incluir comentario'];
}

function getStepContent(step, 
                        toggleUser, 
                        userSelected, 
                        setCommentary, 
                        toggleCompetence, 
                        competenceSelected,
                        handleChangeCheckTimeline,
                        checkTimeline) {
                          switch (step) {
                            case 0:
                              return <Step1 toggleUser={toggleUser} userSelected={userSelected}/>;
                            case 1:
                              return <Step2 toggleCompetence={toggleCompetence} 
                                            competenceSelected={competenceSelected} />;
                            case 2:
                              return <Step3 setCommentary={setCommentary} handleChangeCheckTimeline={handleChangeCheckTimeline} checkTimeline={checkTimeline}/>;
                            default:
                              return <Step1 />;
                          }
}

export default function GiveProposal() {
  const [userSelected, setUserSelected] = useState(null);
  const [competenceSelected, setCompetenceSelected] = useState(null);
  const [modalUser, setModalUser] = useState(false);
  const [modalCompetence, setModalCompetence] = useState(false);
  const toggleUser = () => setModalUser(!modalUser);
  const toggleCompetence = () => setModalCompetence(!modalCompetence);
  const [commentary, setCommentary] = useState("")
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  const [saveNewRecognition, { data:dataSaveE, error: errorSaveE, loading: loadingSaveE }] = useMutation(NEW_RECOGNITION);
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps(userSelected, competenceSelected);
  const [checkTimeline, setCheckTimeline] = useState(dbuser.getUserByUid.recognitionInTimeline);
  const handleChangeCheckTimeline = useCallback((e) => {
    setCheckTimeline(!checkTimeline);
  });


  const handleNext = async () => {
      if(activeStep === 2){
        let dataInput = {
            "companyId": dbuser.getUserByUid.companyId,
            "sender": dbuser.getUserByUid.id,
            "receiver": userSelected.id,
            "competenceId": competenceSelected.id,
            "commentary": commentary,
            "commentaryInTimeline": checkTimeline,
          };
          let uid = dbuser.getUserByUid.uid
          let companyId = dbuser.getUserByUid.companyId
          let id = dbuser.getUserByUid.id
          let response = await saveNewRecognition({variables:{input:dataInput}, refetchQueries: [
            { query: GET_TIMELINE, variables: { id, companyId }},
            {query: GET_USER_BY_UID, variables: {uid, uid}},
            // {query: GET_RECOGNITIONS_RECEIVED_BY_COMPANY_ID, variables: {id, id}},
            
            { query: GETS_FOR_GENERAL, variables: { id, id } }
          ]});
          console.log("mutation response:"+response);

           setCommentary("")
           setCompetenceSelected(null)
           setUserSelected(null)

          if(response){
            console.log('se ha entregado el reconocimiento correctamente')
            
            }
      }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const nextDisabled = () => {
    console.log(activeStep)
    if(activeStep === 0){
      if(!userSelected){
        return true
      }else{
        return false
      }
    }
    else if(activeStep === 1){
      if(!competenceSelected){
        return true
      }else{
        return false
      }
    }
    else if(activeStep === 2){
      console.log(commentary.length)
      if(commentary.length < 10){
        return true
      }else{
        return false
      }
    }
    else{
      return true
    }
  };

  return (
    <>
    <PageTitle
        titleHeading="Entregar Reconocimiento"
        titleDescription="¡Potencia el desarrollo de tus compañer@s!"
      />
      <Grid container spacing={4} className="justify-content-center">
            <Grid item xl={8} className="">
    <Card className="card-box text-center">
      <div className="card-header">
        <div className="card-header--title">
          <b>Nuevo reconocimiento</b>
        </div>
      </div>
      <div>
        <div className="bg-secondary mb-3">
          <Stepper
            className="stepper-horizontal-1"
            alternativeLabel
            activeStep={activeStep}
            connector={<StepConnector />}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        {activeStep === steps.length ? (
          <div className="text-center p-5">
            <div className="avatar-icon-wrapper rounded-circle m-0">
              <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-success text-success m-0 d-130">
                <FontAwesomeIcon
                  icon={['far', 'paper-plane']}
                  className="d-flex align-self-center display-3"
                />
              </div>
            </div>
            <h4 className="font-weight-bold mt-4">¡Has entregado reconocimiento!</h4>
            <div className="pt-4">
              <Button
                onClick={handleReset}
                className="btn-warning font-weight-bold rounded hover-scale-lg mx-1"
                size="large"
                component={Link} to="/homeuser/"
                >
                <span className="btn-wrapper--label">Volver al Home</span>
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <div>{getStepContent(activeStep, 
                                  toggleUser, 
                                  userSelected, 
                                  setCommentary,
                                  toggleCompetence,
                                  competenceSelected,
                                  handleChangeCheckTimeline,
                                  checkTimeline
              )}</div>
            <div className="card-footer mt-4 p-4 d-flex align-items-center justify-content-around bg-secondary">
              {activeStep === 0 ? <Button
                
                className="btn-neutral-dark btn-pill mx-1"
                onClick={handleReset}
                component={Link} to="/homeuser/">
                Atrás
              </Button>
              :
              <Button
                disabled={activeStep === 0}
                className="btn-neutral-dark btn-pill mx-1"
                onClick={handleBack}
                >
                Atrás
              </Button>
              }
              <Button
                className="btn-success btn-pill mx-1"
                onClick={handleNext}
                disabled={nextDisabled()}
                >
                
                {activeStep === steps.length - 1 ? 'Enviar' : 'Siguiente'}
              </Button>
            </div>
          </div>
        )}
      </div>
    </Card>
    </Grid>
    </Grid>
    <ModalSelectUser
        modalUser={modalUser}
        toggleUser={toggleUser}
        userSelected={userSelected}
        setUserSelected={setUserSelected}
      />
    <ModalSelectCompetence
        modalCompetence={modalCompetence}
        toggleCompetence={toggleCompetence}
        competenceSelected={competenceSelected}
        setCompetenceSelected={setCompetenceSelected}
      />
    </>
  );
}



