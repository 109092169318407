import React, { useState, useCallback, useContext,useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Dialog,
  Button,
  Card,
  TextField,
  Menu,
  Grid,
  Switch,
  Snackbar,
  Tooltip
} from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { FlagIcon } from "react-flag-kit";
import { POSSIBLE_ANSWER_EMOTION_TODAY, GET_USER_BY_UID, GET_TIMELINE, GETS_FOR_GENERAL } from "data/queries";
import { SAVE_ANSWER_EMOTION } from "data/mutations";

import tipoDeTrabajo from "../../../assets/images/climind/contenidoTrabajo.svg";
import trabajoConPersonas from "../../../assets/images/climind/trabajoEnEquipo.svg";
import condicionesDeTrabajo from "../../../assets/images/climind/condicionesLaborales.svg";
import esfuerzoYResultados from "../../../assets/images/climind/esfuerzoYResultado.svg";
import sensacionDeBienestar from "../../../assets/images/climind/bienestar.svg";

import feliz from "../../../assets/images/climind/feliz.svg";

import { UserContext } from "../../../context/UserContext";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import MuiAlert from "@material-ui/lab/Alert";
import InputEmoji from "react-input-emoji";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function valuetext(value) {
  return <span>{{ value }}°C</span>;
}

export default function ModalEmotionHappy({ modal2, toggle2, refetch, refetchEmotion }) {



  const [emotion, setEmotion] = useState(null);
  const [commentary, setCommentary] = useState("");
  const [value, setValue] = useState(null);
  const [iconContenidoTrabajo, setIconContenidoTrabajo] = useState(false);
  const [iconParticipacion, setIconParticipacion] = useState(false);
  const [iconRol, setIconRol] = useState(false);
  const [iconTrabajoGrupo, setIconTrabajoGrupo] = useState(false);
  const [iconTiempo, setIconTiempo] = useState(false);
  const [anchorElMenu3, setAnchorElMenu3] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openSnackbarDanger, setOpenSnackbarDanger] = useState(false);
  const { currentUser, dbuser, getUserWithFirebase } = useContext(UserContext);
  const [checkAdmin, setCheckAdmin] = useState(dbuser.getUserByUid.commentaryEmotionForAdmins);
  const [checkTimeline, setCheckTimeline] = useState(dbuser.getUserByUid.commentaryEmotionInTimeline);

  const [buttonClicked, setButtonClicked] = useState(false)

  const [
    saveAnswerEmotion,
    { data: dataSaveE, error: errorSaveE, loading: loadingSaveE },
  ] = useMutation(SAVE_ANSWER_EMOTION);

  const handleChangeCheckTimeline = useCallback((e) => {
    setCheckTimeline(!checkTimeline);
    // if(!checkTimeline){
    //   setCheckAdmin(false)
    // }
  });
  const handleChangeCheckAdmin = useCallback((e) => {
    setCheckAdmin(!checkAdmin);
  });

  const onClickContenidoTrabajo = useCallback((e) => {
    setIconContenidoTrabajo(!iconContenidoTrabajo);
  });
  const onClickParticipacion = useCallback((e) => {
    setIconParticipacion(!iconParticipacion);
  });
  const onClickRol = useCallback((e) => {
    setIconRol(!iconRol);
  });
  const onClickTrabajoGrupo = useCallback((e) => {
    setIconTrabajoGrupo(!iconTrabajoGrupo);
  });
  const onClickTiempo = useCallback((e) => {
    setIconTiempo(!iconTiempo);
  });

  const handleClickMenu3 = (event) => {
    setAnchorElMenu3(event.currentTarget);
  };
  const handleCloseMenu3 = () => {
    setAnchorElMenu3(null);
  };
  async function onChangeValue(event, value) {
    setValue(value);
  }

  async function onClickButton() {
    if (buttonClicked) return
    setButtonClicked(true) 
    console.log("click en emotion" + emotion);
    let dataInput = {
      companyId: dbuser.getUserByUid.companyId,
      userId: dbuser.getUserByUid.id,
      answer: "HAPPY",
      value: value,
      commentary: commentary,
      commentaryInTimeline: checkTimeline,
	    commentaryForAdmins: checkAdmin,
      typeContenidoTrabajoSad: iconContenidoTrabajo,
      typeTiempoSad: iconTiempo,
      typeParticipacionSad: iconParticipacion,
      typeRolSad: iconRol,
      typeTrabajoGrupoSad: iconTrabajoGrupo
    };
    console.log(dataInput);
    let companyId = dbuser.getUserByUid.companyId
    let uid = dbuser.getUserByUid.uid;
    let id = dbuser.getUserByUid.id;
    let response = await saveAnswerEmotion({
      variables: { input: dataInput },
      refetchQueries: [
        { query: GET_TIMELINE, variables: { id, companyId }},
        { query: GET_USER_BY_UID, variables: { uid, uid } },
        { query: POSSIBLE_ANSWER_EMOTION_TODAY, variables: { id, id } },
        { query: GETS_FOR_GENERAL, variables: { id, id } }
      ],
    });
    getUserWithFirebase()
    console.log(response);
    setValue(null);
    setIconContenidoTrabajo(false);
    setIconParticipacion(false);
    setIconRol(false);
    setIconTrabajoGrupo(false);
    setIconTiempo(false);
    setCommentary("");
    setCheckAdmin(dbuser.getUserByUid.commentaryEmotionForAdmins)
    setCheckTimeline(dbuser.getUserByUid.commentaryEmotionInTimeline)
    if(response.data.saveAnswerEmotion === null){
      setOpenSnackbarDanger(true, () => {
        setTimeout(function () {
          setOpenSnackbarDanger(false);
        }, 5000); //5 Second delay
      });
    }else{
      setOpenSnackbar(true, () => {
        setTimeout(function () {
          setOpenSnackbar(false);
        }, 5000); //5 Second delay
      });
    }
    
    refetch()
    refetchEmotion()
    toggle2();
    setButtonClicked(false)
    if (response.data.saveAnswerEmotion) {
      console.log("guardado correctamente");
    }
  }
  const handleCloseModal = () => {
    setIconContenidoTrabajo(false);
    setIconParticipacion(false);
    setIconRol(false);
    setIconTrabajoGrupo(false);
    setIconTiempo(false);
    setValue(null);
    setCommentary("");
    setCheckAdmin(dbuser.getUserByUid.commentaryEmotionForAdmins)
    setCheckTimeline(dbuser.getUserByUid.commentaryEmotionInTimeline)
    toggle2();
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        key={"top,center"}
        open={openSnackbar}
        // classes={{ root: "toastr-primary" }}
        autoHideDuration={4000}
        onClose={() => setOpenSnackbar(false)}
        message={"entregado correctamente"}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          style={{ color: "white" }}
        >
          Entregado correctamente
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        key={"top,right"}
        open={openSnackbarDanger}
        autoHideDuration={4000}
        onClose={() => setOpenSnackbarDanger(false)}
        message={"entregado correctamente"}
      >
        <Alert
          onClose={() => setOpenSnackbarDanger(false)}
          severity="warning"
          style={{ color: "white" }}
        >
          No se ha permitido contestar la pregunta
        </Alert>
      </Snackbar>
      <Dialog
        open={modal2}
        onClose={handleCloseModal}
        maxWidth="lg"
        width="80%"
        classes={{ paper: "modal-content rounded-lg" }}
      >
        <div className="text-center mx-5 my-4">
        <h4 className="text-center">Tu jornada laboral ha sido</h4>
          <div className="avatar-icon-wrapper rounded-circle mb-3 mt-2">
            {/* <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-success text-success m-0 d-130"> */}
            <img
              alt="..."
              className="card-img-top rounded-sm"
              src={feliz}
              style={{ width: 70 }}
            />
            {/* </div> */}
          </div>

          {/* {!value && (
            <>
              <h4 className="text-center gx-mb-4">¿Con que intensidad?</h4>

              <Slider
                className="slider-primary my-3"
                track="inverted"
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                defaultValue={5}
                step={1}
                marks
                min={1}
                max={10}
                marks={marksRed}
                onChangeCommitted={onChangeValue}
              />
            </>
          )}
          {value && (
            <>
              <h4 className="text-center gx-mb-4">Has marcado un {value}</h4>
            </>
          )} */}

          <h4 className="text-center ">¿Con que intensidad?</h4>
            <div className="d-flex flex-row align-items-center justify-content-center" style={{marginTop:40}}>

            <h4 className="text-center mr-2 mb-0">Baja</h4>
              
        <Button 
          className={value === 1 ? "btn-success btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2" : "btn-secondary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"}
          style={{borderRadius:"50%" , border: value === 1 ? "" : "solid 3px green"}}
          onClick={() => setValue(1)}>
          <h5 className="text-center font-weight-bold mb-0">1</h5>
        </Button>
        <Button 
          className={value === 2 ? "btn-success btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2" : "btn-secondary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"}
          style={{borderRadius:"50%" , border: value === 2 ? "" : "solid 3px green"}}
          onClick={() => setValue(2)}>
          <h5 className="text-center font-weight-bold mb-0">2</h5>
        </Button>
        <Button 
          className={value === 3 ? "btn-success btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2" : "btn-secondary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"}
          style={{borderRadius:"50%" , border: value === 3 ? "" : "solid 3px green"}}
          onClick={() => setValue(3)}>
          <h5 className="text-center font-weight-bold mb-0">3</h5>
        </Button>
        <Button 
          className={value === 4 ? "btn-success btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2" : "btn-secondary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"}
          style={{borderRadius:"50%" , border: value === 4 ? "" : "solid 3px green"}}
          onClick={() => setValue(4)}>
          <h5 className="text-center font-weight-bold mb-0">4</h5>
        </Button>
        <Button 
          className={value === 5 ? "btn-success btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2" : "btn-secondary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"}
          style={{borderRadius:"50%" , border: value === 5 ? "" : "solid 3px green"}}
          onClick={() => setValue(5)}>
          <h5 className="text-center font-weight-bold mb-0">5</h5>
        </Button>
        <Button 
          className={value === 6 ? "btn-success btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2" : "btn-secondary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"}
          style={{borderRadius:"50%" , border: value === 6 ? "" : "solid 3px green"}}
          onClick={() => setValue(6)}>
          <h5 className="text-center font-weight-bold mb-0">6</h5>
        </Button>
        <Button 
          className={value === 7 ? "btn-success btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2" : "btn-secondary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"}
          style={{borderRadius:"50%" , border: value === 7 ? "" : "solid 3px green"}}
          onClick={() => setValue(7)}>
          <h5 className="text-center font-weight-bold mb-0">7</h5>
        </Button>
        <Button 
          className={value === 8 ? "btn-success btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2" : "btn-secondary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"}
          style={{borderRadius:"50%" , border: value === 8 ? "" : "solid 3px green"}}
          onClick={() => setValue(8)}>
          <h5 className="text-center font-weight-bold mb-0">8</h5>
        </Button>
        <Button 
          className={value === 9 ? "btn-success btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2" : "btn-secondary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"}
          style={{borderRadius:"50%" , border: value === 9 ? "" : "solid 3px green"}}
          onClick={() => setValue(9)}>
          <h5 className="text-center font-weight-bold mb-0">9</h5>
        </Button>
        <Button 
          className={value === 10 ? "btn-success btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2" : "btn-secondary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"}
          style={{borderRadius:"50%" , border: value === 10 ? "" : "solid 3px green"}}
          onClick={() => setValue(10)}>
          <h5 className="text-center font-weight-bold mb-0">10</h5>
        </Button>
        <h4 className="text-center ml-4 mb-0">Alta</h4>
        </div>

        </div>

        <div className="text-center px-2">
          {value <= 6 && value && (
            <>
              <h4 className="font-weight-bold" style={{ color: "#5a318e", marginTop:20 }}>
                ¿A que se debe?
              </h4>
              <h5 className="mb-4">Debes seleccionar al menos una opción</h5>
              <div>
                {/* <Grid justify="center" container spacing={1}>
            <Grid item xs={12} md={5} xl={4}>
            <div className="col ">
                <a
              onClick={() => {
                onClickContenidoTrabajo();
              }}
              className={iconContenidoTrabajo ? "active m-3 btn-input-select" : "m-3 btn-input-select"}>
              <div className="selected-icon" >
                <FontAwesomeIcon icon={['fas', 'check']} />
              </div>
              <img
                  alt="..."
                  className="card-img-top rounded-sm"
                  src={contenidotrabajo}
                  style={{ width: 80 }}
                />
              <div className="font-weight-bold mt-2">Naturaleza y contenido</div>
            </a>
            </div>
            </Grid>
            <Grid item xs={12} md={5} xl={4}>
            <div className="col ">
                <a
              onClick={() => {
                onClickContenidoTrabajo();
              }}
              className={iconParticipacion ? "active m-3 btn-input-select" : "m-3 btn-input-select"}>
              <div className="selected-icon" >
                <FontAwesomeIcon icon={['fas', 'check']} />
              </div>
              <img
                  alt="..."
                  className="card-img-top rounded-sm"
                  src={cargayritmo}
                  style={{ width: 80 }}
                />
              <div className="font-weight-bold mt-2">El trabajo en grupo y sus directivos</div>
            </a>
            </div>
            </Grid>
            <Grid item xs={12} md={5} xl={4}>
            <div className="col">
                <a
              onClick={() => {
                onClickContenidoTrabajo();
              }}
              className={iconContenidoTrabajo ? "active m-3 btn-input-select" : "m-3 btn-input-select"}>
              <div className="selected-icon" >
                <FontAwesomeIcon icon={['fas', 'check']} />
              </div>
              <img
                  alt="..."
                  className="card-img-top rounded-sm"
                  src={tiempodetrabajo}
                  style={{ width: 80 }}
                />
              <div className="font-weight-bold mt-2">Tiempo de trabajo</div>
            </a>
            </div>
            </Grid>
            <Grid item xs={12} md={5} xl={4}>
            <div className="col ">
                <a
              onClick={() => {
                onClickContenidoTrabajo();
              }}
              className={iconParticipacion ? "active m-3 btn-input-select" : "m-3 btn-input-select"}>
              <div className="selected-icon" >
                <FontAwesomeIcon icon={['fas', 'check']} />
              </div>
              <img
                  alt="..."
                  className="card-img-top rounded-sm"
                  src={participacionycontrol}
                  style={{ width: 80 }}
                />
              <div className="font-weight-bold mt-2">Participación y control</div>
            </a>
            </div>
            </Grid>
            <Grid item xs={12} md={5} xl={4}>
            <div className="col ">
                <a
              onClick={() => {
                onClickContenidoTrabajo();
              }}
              className={iconContenidoTrabajo ? "active m-3 btn-input-select" : "m-3 btn-input-select"}>
              <div className="selected-icon" >
                <FontAwesomeIcon icon={['fas', 'check']} />
              </div>
              <img
                  alt="..."
                  className="card-img-top rounded-sm"
                  src={desempeñoderol}
                  style={{ width: 80, height:80 }}
                />
              <div className="font-weight-bold mt-2">Desempeño de rol</div>
            </a>
            </div>
            </Grid> */}
                {/* <Card
                className={iconContenidoTrabajo ? "p-2 text-success active btn-input-select" : "p-2 text-success"}
                onClick={() => {
                  onClickContenidoTrabajo();
                }}
              >
                <img
                  alt="..."
                  className="card-img-top rounded-sm"
                  src={contenidotrabajo}
                  style={{ width: 80 }}
                />
              </Card>
              <h6 className="font-weight-bold">Naturaleza y contenido</h6>
              <h6 className="font-weight-bold">
                de trabajo
                <Button className="btn-warning btn-icon btn-animated-icon btn-transition-none btn-pill d-20 p-0 ml-2">
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon
                      icon={["fas", "info"]}
                      className="font-size-xs"
                    />
                  </span>
                </Button>
              </h6> */}
                <div className="mb-spacing-6">
                  <Grid container spacing={4} className=" d-flex justify-content-center">
                    <Grid item xl={2} md={2} sm={2}>
                      <Card
                        className="shadow-xxl"
                        width={140}
                        height={140}
                        style={
                          iconContenidoTrabajo
                            ? {
                                borderRadius: "30px",
                                border: `solid 7px green`,
                                height: 150,
                                width: 150
                              }
                            : {}
                        }
                      >
                        <a
                          href="#/"
                          onClick={(e) => e.preventDefault()}
                          className="card-img-wrapper rounded"
                        >
                          <div className="img-wrapper-overlay p-10">
                            <div className="overlay-btn-wrapper ">
                            <Tooltip
                              classes={{ tooltip: 'text-center p-3 tooltip-secondary' }}
                              arrow
                              placement="top"
                              title="Tarea que realizas: cómo está diseñada, el sentido que tiene para ti, las habilidades que te requiere, la autonomía que te permite, y lo que te aporta.">
                              

                              <Button
                                size="small"
                                className="btn-info btn-pill px-6 hover-scale-sm mb-2"
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "info"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Información
                                </span>
                              </Button>
                              </Tooltip>
                              {/* <Button
                                size="small"
                                className="btn-warning btn-pill px-4 hover-scale-sm mb-2"
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "info"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Incluye
                                </span>
                              </Button> */}
                              {!iconContenidoTrabajo ?
                              <Button
                                size="small"
                                className="btn-success btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickContenidoTrabajo()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Seleccionar
                                </span>
                              </Button>
                              :
                              <Button
                                size="small"
                                className="btn-danger btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickContenidoTrabajo()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Quitar
                                </span>
                              </Button>
                              }
                            </div>
                          </div>
                          <img
                            src={tipoDeTrabajo}
                            className="card-img-top rounded"
                            alt="..."
                            style={{width: 140, height: 140}}
                          />
                        </a>
                      </Card>
                      <h5 className="text-center mt-4">
                      Características de las tareas
                      </h5>
                    </Grid>
                    <Grid item xl={2} md={2} sm={2}>
                    <Card
                        className="shadow-xxl"
                        width={140}
                        height={140}
                        style={
                          iconTrabajoGrupo
                            ? {
                                borderRadius: "30px",
                                border: `solid 7px green`,
                                height: 150,
                                width: 150
                              }
                            : {}
                        }
                      >
                        <a
                          href="#/"
                          onClick={(e) => e.preventDefault()}
                          className="card-img-wrapper rounded"
                        >
                          <div className="img-wrapper-overlay">
                            <div className="overlay-btn-wrapper">
                            <Tooltip
                              classes={{ tooltip: 'text-center p-3 tooltip-secondary' }}
                              arrow
                              placement="top"
                              title="Trabajo en equipo y cómo colaboráis en el logro de objetivos entre todas las personas.">
                              <Button
                                size="small"
                                className="btn-info btn-pill px-6 hover-scale-sm mb-2"
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "info"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Información
                                </span>
                              </Button>
                              </Tooltip>
                              {/* <Button
                                size="small"
                                className="btn-warning btn-pill px-4 hover-scale-sm mb-2"
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "info"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Incluye
                                </span>
                              </Button> */}
                              {!iconTrabajoGrupo ?
                              <Button
                                size="small"
                                className="btn-success btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickTrabajoGrupo()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Seleccionar
                                </span>
                              </Button>
                              :
                              <Button
                                size="small"
                                className="btn-danger btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickTrabajoGrupo()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Quitar
                                </span>
                              </Button>
                              }
                            </div>
                          </div>
                          <img
                            src={trabajoConPersonas}
                            className="card-img-top rounded"
                            alt="..."
                            style={{width: 140, height: 140}}
                          />
                        </a>
                      </Card>
                      <h5 className="text-center mt-4">
                        Trabajo con personas
                      </h5>
                    </Grid>
                    <Grid item xl={2} md={2} sm={2}>
                    <Card
                        className="shadow-xxl"
                        width={140}
                        height={140}
                        style={
                          iconTiempo
                            ? {
                                borderRadius: "30px",
                                border: `solid 7px green`,
                                height: 150,
                                width: 150
                              }
                            : {}
                        }
                      >
                        <a
                          href="#/"
                          onClick={(e) => e.preventDefault()}
                          className="card-img-wrapper rounded"
                        >
                          <div className="img-wrapper-overlay">
                            <div className="overlay-btn-wrapper">
                            <Tooltip
                              classes={{ tooltip: 'text-center p-3 tooltip-secondary' }}
                              arrow
                              placement="top"
                              title="Nivel de exigencia física y mental que te requiere tu trabajo.">
                              <Button
                                size="small"
                                className="btn-info btn-pill px-6 hover-scale-sm mb-2"
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "info"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Información
                                </span>
                              </Button>
                              </Tooltip>
                              {!iconTiempo ?
                              <Button
                                size="small"
                                className="btn-success btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickTiempo()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Seleccionar
                                </span>
                              </Button>
                              :
                              <Button
                                size="small"
                                className="btn-danger btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickTiempo()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Quitar
                                </span>
                              </Button>
                              }
                            </div>
                          </div>
                          <img
                            src={condicionesDeTrabajo}
                            className="card-img-top rounded"
                            alt="..."
                            style={{width: 140, height: 140}}
                          />
                        </a>
                      </Card>
                      <h5 className="text-center mt-4">
                        Nivel de exigencia
                      </h5>
                    </Grid>
                    <Grid item xl={2} md={2} sm={2}>
                    <Card
                        className="shadow-xxl"
                        width={140}
                        height={140}
                        style={
                          iconParticipacion
                            ? {
                                borderRadius: "30px",
                                border: `solid 7px green`,
                                height: 150,
                                width: 150
                              }
                            : {}
                        }
                      >
                        <a
                          href="#/"
                          onClick={(e) => e.preventDefault()}
                          className="card-img-wrapper rounded"
                        >
                          <div className="img-wrapper-overlay">
                            <div className="overlay-btn-wrapper">
                            <Tooltip
                              classes={{ tooltip: 'text-center p-3 tooltip-secondary' }}
                              arrow
                              placement="top"
                              title="Valoración que hacen tus superiores de tu trabajo (esfuerzo realizado y resultados obtenidos).">
                              <Button
                                size="small"
                                className="btn-info btn-pill px-6 hover-scale-sm mb-2"
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "info"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Información
                                </span>
                              </Button>
                              </Tooltip>
                              {!iconParticipacion ?
                              <Button
                                size="small"
                                className="btn-success btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickParticipacion()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Seleccionar
                                </span>
                              </Button>
                              :
                              <Button
                                size="small"
                                className="btn-danger btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickParticipacion()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Quitar
                                </span>
                              </Button>
                                }
                            </div>
                          </div>
                          <img
                            src={esfuerzoYResultados}
                            className="card-img-top rounded"
                            alt="..."
                            style={{width: 140, height: 140}}
                          />
                        </a>
                      </Card>
                      <h5 className="text-center mt-4">
                        Esfuerzo y resultados
                      </h5>
                    </Grid>
                    <Grid item xl={2} md={2} sm={2}>
                    <Card
                        className="shadow-xxl"
                        width={140}
                        height={140}
                        style={
                          iconRol
                            ? {
                                borderRadius: "30px",
                                border: `solid 7px green`,
                                height: 150,
                                width: 150
                              }
                            : {}
                        }
                      >
                        <a
                          href="#/"
                          onClick={(e) => e.preventDefault()}
                          className="card-img-wrapper rounded"
                        >
                          <div className="img-wrapper-overlay">
                            <div className="overlay-btn-wrapper">
                            <Tooltip
                              classes={{ tooltip: 'text-center p-3 tooltip-secondary' }}
                              arrow
                              placement="top"
                              title="Cómo te afectan las condiciones laborales en tu sensación de bienestar.">
                              <Button
                                size="small"
                                className="btn-info btn-pill px-6 hover-scale-sm mb-2"
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "info"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Información
                                </span>
                              </Button>
                              </Tooltip>
                              {!iconRol ?
                              <Button
                                size="small"
                                className="btn-success btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickRol()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Seleccionar
                                </span>
                              </Button>
                              :
                              <Button
                                size="small"
                                className="btn-danger btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickRol()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Quitar
                                </span>
                              </Button>
                              }
                            </div>
                          </div>
                          <img
                            src={sensacionDeBienestar}
                            className="card-img-top rounded"
                            alt="..."
                            style={{width: 140, height: 140}}
                          />
                        </a>
                      </Card>
                      <h5 className="text-center mt-4">
                        Sensación de Bienestar
                      </h5>
                    </Grid>
                  </Grid>
                </div>
                {/* <div className="col mx-4 mb-4" style={{ marginBottom: 20 }}>
                  <Card
                    className={
                      iconContenidoTrabajo
                        ? "p-2 text-success active btn-input-select"
                        : "p-2 text-success"
                    }
                    onClick={() => {
                      onClickContenidoTrabajo();
                    }}
                  >
                    <img
                      alt="..."
                      className="card-img-top rounded-sm"
                      src={contenidotrabajo}
                      style={{ width: 80 }}
                    />
                  </Card>
                  <h6 className="font-weight-bold">Naturaleza y contenido</h6>
                  <h6 className="font-weight-bold">
                    de trabajo
                    <Button className="btn-warning btn-icon btn-animated-icon btn-transition-none btn-pill d-20 p-0 ml-2">
                      <span className="btn-wrapper--icon">
                        <FontAwesomeIcon
                          icon={["fas", "info"]}
                          className="font-size-xs"
                        />
                      </span>
                    </Button>
                  </h6>
                </div>
                <div className="col mx-4 mb-4" style={{ marginBottom: 20 }}>
                  <Card
                    className={
                      iconParticipacion
                        ? "p-2 text-success active btn-input-select"
                        : "p-2 text-success"
                    }
                    onClick={() => {
                      onClickParticipacion();
                    }}
                  >
                    <img
                      alt="..."
                      className="card-img-top rounded-sm"
                      src={cargayritmo}
                      style={{ width: 80 }}
                    />
                  </Card>
                  <h6 className="font-weight-bold">El trabajo en grupo y</h6>
                  <h6 className="font-weight-bold">
                    sus directivos
                    <Button
                      className="btn-warning btn-icon btn-animated-icon btn-transition-none btn-pill d-20 p-0 ml-2"
                      onClick={handleClickMenu3}
                    >
                      <span className="btn-wrapper--icon">
                        <FontAwesomeIcon
                          icon={["fas", "info"]}
                          className="font-size-xs"
                        />
                      </span>
                    </Button>
                    <Menu
                      anchorEl={anchorElMenu3}
                      keepMounted
                      open={Boolean(anchorElMenu3)}
                      onClose={handleCloseMenu3}
                      classes={{ list: "p-0" }}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <InfoCargayritmo />
                    </Menu>
                  </h6>
                </div> */}
                {/* <div className="col mx-4 mb-4" style={{ marginBottom: 20 }}>
                  <Card
                    className={
                      iconRol
                        ? "p-2 text-success active btn-input-select"
                        : "p-2 text-success"
                    }
                    onClick={() => {
                      onClickRol();
                    }}
                  >
                    <img
                      alt="..."
                      className="card-img-top rounded-sm"
                      src={tiempodetrabajo}
                      style={{ width: 80 }}
                    />
                  </Card>
                  <h6 className="font-weight-bold">Tiempo</h6>
                  <h6 className="font-weight-bold">
                    de trabajo
                    <Button className="btn-warning btn-icon btn-animated-icon btn-transition-none btn-pill d-20 p-0 ml-2">
                      <span className="btn-wrapper--icon">
                        <FontAwesomeIcon
                          icon={["fas", "info"]}
                          className="font-size-xs"
                        />
                      </span>
                    </Button>
                  </h6>
                </div> */}
                {/* <div className="col mx-4 mb-4" style={{ marginBottom: 20 }}>
                  <Card
                    className={
                      iconTrabajoGrupo
                        ? "p-2 text-success active btn-input-select"
                        : "p-2 text-success"
                    }
                    onClick={() => {
                      onClickTrabajoGrupo();
                    }}
                  >
                    <img
                      alt="..."
                      className="card-img-top rounded-sm"
                      src={participacionycontrol}
                      style={{ width: 80 }}
                    />
                  </Card>
                  <h6 className="font-weight-bold">Participación</h6>
                  <h6 className="font-weight-bold">
                    y control
                    <Button className="btn-warning btn-icon btn-animated-icon btn-transition-none btn-pill d-20 p-0 ml-2">
                      <span className="btn-wrapper--icon">
                        <FontAwesomeIcon
                          icon={["fas", "info"]}
                          className="font-size-xs"
                        />
                      </span>
                    </Button>
                  </h6>
                </div> */}
                {/* <div className="col mx-4 mb-4" style={{ marginBottom: 20 }}>
                  <Card
                    className={
                      iconTiempo
                        ? "p-2 text-success active btn-input-select"
                        : "p-2 text-success"
                    }
                    onClick={() => {
                      onClickTiempo();
                    }}
                  >
                    <img
                      alt="..."
                      className="card-img-top rounded-sm"
                      src={desempeñoderol}
                      style={{ width: 80 }}
                    />
                  </Card>
                  <h6 className="font-weight-bold">Desempeño de rol</h6>
                  <h6 className="font-weight-bold">
                    de la organización
                    <Button className="btn-warning btn-icon btn-animated-icon btn-transition-none btn-pill d-20 p-0 ml-2">
                      <span className="btn-wrapper--icon">
                        <FontAwesomeIcon
                          icon={["fas", "info"]}
                          className="font-size-xs"
                        />
                      </span>
                    </Button>
                  </h6>
                </div> */}
              </div>

              <h4
                className="font-weight-bold mt-4"
                style={{ color: "#5a318e" }}
              >
                ¿En qué se puede mejorar?
              </h4>
              <h5>Puedes añadir un comentario opcional</h5>
              {/* <TextField
                variant="outlined"
                className="m-3"
                fullWidth
                id="standard-multiline-static"
                label="Escribe aquí"
                multiline
                rows="4"
                defaultValue=""
                style={{ width: "80%" }}
                onChange={(e) => setCommentary(e.target.value)}
              /> */}
              <div className="d-flex justify-content-center">
                <div  style={{ width: "80%"}} >
                <InputEmoji
                  value={commentary}
                  onChange={setCommentary}
                  cleanOnEnter
                  borderColor="purple"
                  placeholder="Escribe aquí"
                  keepOpenend
                />
                </div>
                </div>
              <div
                style={{ width: "80%", marginLeft: 100 }}
                className="text-left"
              >
                <div className="d-flex flex-row align-items-center">
                  <div className="m-2">
                    <Switch
                      onChange={handleChangeCheckTimeline}
                      checked={checkTimeline}
                      color="primary"
                      name="checkedA"
                      className="switch-small"
                    />
                  </div>
                  <h6 className="mb-0">
                  El comentario podrá ser visto siempre por los administradores. Si se selecciona el check, el comentario también será público y se mostrará en el Timeline
                  </h6>
                </div>
                {/* <div className="d-flex flex-row align-items-center">
                  <div className="m-2">
                    <Switch
                      onChange={checkTimeline ? handleChangeCheckAdmin : null}
                      checked={checkAdmin}
                      color="primary"
                      name="checkedA"
                      className="switch-small"
                      // disabled={!checkTimeline}
                    />
                  </div>
                  <h6 className="mb-0">
                  Si se selecciona, se enviará una notificación a los administradores para que lean antes este comentario. (Debe estar marcada la opción anterior)
                  </h6>
                </div> */}
                <h6 className="text-black-50 mb-0 mt-3">
                  * Hemos cogido por defecto la configuración de privacidad de tu perfil
                </h6>
              </div>
            </>
          )}
          <div className="pt-4" style={{ marginBottom: 20 }}>
            <Button
              onClick={handleCloseModal}
              className="btn-neutral-dark btn-pill mx-1"
            >
              <span className="btn-wrapper--label">Cancelar</span>
            </Button>
            <Button
              onClick={onClickButton}
              className="btn-success btn-pill mx-1"
              disabled={
                !value ||
                (value < 7 &&
                  iconContenidoTrabajo === false &&
                  iconParticipacion === false &&
                  iconRol === false &&
                  iconTiempo === false &&
                  iconTrabajoGrupo === false)
              }
            >
              <span className="btn-wrapper--label">Enviar</span>
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

const marksRed = [
  {
    value: 1,
    label: (
      <h5 className="pt-2" color="red">
        <strong>Baja</strong>
      </h5>
    ),
  },
  {
    value: 10,
    label: (
      <h5 className="pt-2" color="red">
        <strong>Alta</strong>
      </h5>
    ),
  },
];

const PrettoSliderRed = withStyles({
  root: {
    color: "red",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);
