import React, { useEffect, useState } from "react";


export default function Traffic({ color }) {

    var trafficStyle = {
        red: {
            backgroundColor: "rgb(248, 50, 69)",
            borderColor: "rgb(248, 50, 69)"
        },
        yellow: {
            backgroundColor: "rgb(239, 204, 31)",
            borderColor: "rgb(239, 204, 31)"
        },
        green: {
            backgroundColor: "rgb(27, 201, 67)",
            borderColor: "rgb(27, 201, 67)"
        },
        redSecondary: {
            borderColor: "rgb(248, 50, 69)"
        },
        yellowSecondary: {
            borderColor: "rgb(239, 204, 31)"
        },
        greenSecondary: {
            borderColor: "rgb(27, 201, 67)"
        },
        black: {
            // backgroundColor: "rgb(70, 74, 79)"
        }
    };


    return (
        <div id="traffic">
            {
                color === 'red' ? (
                    <div className="box">
                        <div className="circle" style={ trafficStyle.red }></div>
                        <div className="circle" style={ trafficStyle.yellowSecondary} ></div>
                        <div className="circle" style={ trafficStyle.greenSecondary}></div>
                    </div>) :
                    color === "yellow" ? (
                        <div className="box">
                            <div className="circle" style={ trafficStyle.redSecondary}></div>
                            <div className="circle" style={ trafficStyle.yellow}></div>
                            <div className="circle" style={ trafficStyle.greenSecondary}></div>
                        </div>) :
                        (<div className="box">
                            <div className="circle" style={ trafficStyle.redSecondary}></div>
                            <div className="circle" style={ trafficStyle.yellowSecondary}></div>
                            <div className="circle" style={ trafficStyle.green}></div>
                        </div>)
            }
        </div>
    )
}


// var Traffic = React.createClass({
//     getInitialState: function () {
//         return {
//             red: trafficStyle.red,
//             yellow: trafficStyle.black,
//             green: trafficStyle.black,
//             next: "yellow"
//         };
//     },
//     setInterval: function () {
//         this._timeout = setTimeout(function () {
//             this.changeHandle();
//         }.bind(this), 500);
//     },
//     componentDidMount: function () {
//         this.setInterval(this.tick, 500);
//     },
//     componentWillUnmount: function () {
//         clearInterval(this._timeout);
//     },
//     changeHandle: function () {
//         switch (this.state.next) {
//             case "red":
//                 this.setState({
//                     red: trafficStyle.red,
//                     yellow: trafficStyle.black,
//                     green: trafficStyle.black,
//                     next: "yellow"
//                 });
//                 $('#traffic').animate({ backgroundColor: '#5075c5' }, 'slow');
//                 break;
//             case "yellow":
//                 this.setState({
//                     red: trafficStyle.black,
//                     yellow: trafficStyle.yellow,
//                     green: trafficStyle.black,
//                     next: "green"
//                 });
//                 console.log($('#traffic'));
//                 $('#traffic').animate({ backgroundColor: '#dbba94' }, 'slow');
//                 break;
//             case "green":
//                 this.setState({
//                     red: trafficStyle.black,
//                     yellow: trafficStyle.black,
//                     green: trafficStyle.green,
//                     next: "red"
//                 });
//                 $('#traffic').animate({ backgroundColor: '#6f2565' }, 'slow');
//                 break;
//         }

//         this._timeout = setTimeout(function () {
//             this.changeHandle();
//         }.bind(this), 5000);
//     },
//     render: function () {
//         return (
//             <div className="box">
//                 <div className="circle" style={this.state.red}></div>
//                 <div className="circle" style={this.state.yellow}></div>
//                 <div className="circle" style={this.state.green}></div>
//             </div>
//         );
//     }
// });

// export default Traffic;