import React, { useState, useCallback, useContext } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Button, Card, TextField, Menu, Switch, Snackbar, Tooltip, Grid } from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { FlagIcon } from "react-flag-kit";
import { POSSIBLE_ANSWER_EMOTION_TODAY, GET_USER_BY_UID, GETS_FOR_GENERAL } from "data/queries";
import { SAVE_ANSWER_EMOTION } from "data/mutations";

import neutro from "../../../assets/images/climind/neutro.svg";

import { UserContext } from "../../../context/UserContext";
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import MuiAlert from '@material-ui/lab/Alert';

import tipoDeTrabajo from "../../../assets/images/climind/contenidoTrabajo.svg";
import trabajoConPersonas from "../../../assets/images/climind/trabajoEnEquipo.svg";
import condicionesDeTrabajo from "../../../assets/images/climind/condicionesLaborales.svg";
import esfuerzoYResultados from "../../../assets/images/climind/esfuerzoYResultado.svg";
import sensacionDeBienestar from "../../../assets/images/climind/bienestar.svg";
import InputEmoji from "react-input-emoji";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function valuetext(value) {
  return <span>{{ value }}°C</span>;
}

export default function ModalEmotionMiddle({ modal3, toggle3, refetch, refetchEmotion }) {
  const [emotion, setEmotion] = useState(null)
  const [commentary, setCommentary] = useState("")
  const [iconContenidoTrabajo, setIconContenidoTrabajo] = useState(false)
  const [iconParticipacion, setIconParticipacion] = useState(false)
  const [iconRol, setIconRol] = useState(false)
  const [iconTrabajoGrupo, setIconTrabajoGrupo] = useState(false)
  const [iconTiempo, setIconTiempo] = useState(false)
  const [anchorElMenu3, setAnchorElMenu3] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [openSnackbarDanger, setOpenSnackbarDanger] = useState(false);
  const { currentUser, dbuser, getUserWithFirebase } = useContext(UserContext);
  const [checkAdmin, setCheckAdmin] = useState(dbuser.getUserByUid.commentaryEmotionForAdmins);
  const [checkTimeline, setCheckTimeline] = useState(dbuser.getUserByUid.commentaryEmotionInTimeline);
  const [saveAnswerEmotion, { data:dataSaveE, error: errorSaveE, loading: loadingSaveE }] = useMutation(SAVE_ANSWER_EMOTION);

  const [buttonClicked, setButtonClicked] = useState(false)
  
  const handleChangeCheckTimeline = useCallback((e) => {
    setCheckTimeline(!checkTimeline);
  });
  const handleChangeCheckAdmin = useCallback((e) => {
    setCheckAdmin(!checkAdmin);
  });

  const onClickContenidoTrabajo = useCallback((e) => {
    setIconContenidoTrabajo(!iconContenidoTrabajo)
  });
  const onClickParticipacion = useCallback((e) => {
    setIconParticipacion(!iconParticipacion)
  });
  const onClickRol = useCallback((e) => {
    setIconRol(!iconRol)
  });
  const onClickTrabajoGrupo = useCallback((e) => {
    setIconTrabajoGrupo(!iconTrabajoGrupo)
  });
  const onClickTiempo = useCallback((e) => {
    setIconTiempo(!iconTiempo)
  });


  const handleClickMenu3 = (event) => {
    setAnchorElMenu3(event.currentTarget);
  };
  const handleCloseMenu3 = () => {
    setAnchorElMenu3(null);
  };

  async function onClickButton ()  {
    if (buttonClicked) return
    setButtonClicked(true) 
    console.log("click en emotion"+emotion)
    let dataInput = {
      "companyId": dbuser.getUserByUid.companyId,
      "userId": dbuser.getUserByUid.id,
      "answer": "MIDDLE",
      "value": 0,
      "commentary": commentary,
      "commentaryInTimeline": checkTimeline,
	    "commentaryForAdmins": checkAdmin,
      "typeContenidoTrabajoSad": iconContenidoTrabajo,
      "typeTiempoSad": iconTiempo,
      "typeParticipacionSad": iconParticipacion,
      "typeRolSad": iconRol,
      "typeTrabajoGrupoSad": iconTrabajoGrupo,
    };
    console.log(dataInput)
    let uid = dbuser.getUserByUid.uid
    let id = dbuser.getUserByUid.id
    let response = await saveAnswerEmotion({
      variables:{input:dataInput}, 
      refetchQueries: [
        {query: GET_USER_BY_UID, variables: {uid,uid}}, 
        { query: GETS_FOR_GENERAL, variables: { id, id } },
        { query: POSSIBLE_ANSWER_EMOTION_TODAY, variables: { id, id } },
      ]});
      getUserWithFirebase()
    console.log(response);
    setIconContenidoTrabajo(false)
    setIconParticipacion(false)
    setIconRol(false)
    setIconTrabajoGrupo(false)
    setIconTiempo(false)
    setCommentary("");
    setCheckAdmin(dbuser.getUserByUid.commentaryEmotionForAdmins)
    setCheckTimeline(dbuser.getUserByUid.commentaryEmotionInTimeline)
    if(response.data.saveAnswerEmotion === null){
      setOpenSnackbarDanger(true, () => {
        setTimeout(function () {
          setOpenSnackbarDanger(false);
        }, 5000); //5 Second delay
      });
    }else{
      setOpenSnackbar(true, () => {
        setTimeout(function () {
          setOpenSnackbar(false);
        }, 5000); //5 Second delay
      });
    }
    refetch()
    refetchEmotion()
    toggle3()
    setButtonClicked(false)
    if (response.data.saveAnswerEmotion) {
      console.log('guardado correctamente')
    }
  }
  const handleCloseModal = () => {
    setIconContenidoTrabajo(false)
    setIconParticipacion(false)
    setIconRol(false)
    setIconTrabajoGrupo(false)
    setIconTiempo(false)
    setCommentary("");
    setCheckAdmin(dbuser.getUserByUid.commentaryEmotionForAdmins)
    setCheckTimeline(dbuser.getUserByUid.commentaryEmotionInTimeline)
    toggle3();
  };
  return (
    <>
    <Snackbar
          anchorOrigin={{ vertical:"top", horizontal:"center" }}
          key={"top,center"}
          open={openSnackbar}
          // classes={{ root: "toastr-primary" }}
          autoHideDuration={4000}
          onClose={() => setOpenSnackbar(false)}
          message={"entregado correctamente"}
        >
          <Alert onClose={() => setOpenSnackbar(false)} severity="success" style={{color:"white"}}>
          Entregado correctamente
        </Alert>
        </Snackbar>
        <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        key={"top,right"}
        open={openSnackbarDanger}
        autoHideDuration={4000}
        onClose={() => setOpenSnackbarDanger(false)}
        message={"entregado correctamente"}
      >
        <Alert
          onClose={() => setOpenSnackbarDanger(false)}
          severity="warning"
          style={{ color: "white" }}
        >
          No se ha permitido contestar la pregunta
        </Alert>
      </Snackbar>
      <Dialog
        open={modal3}
        onClose={handleCloseModal}
        maxWidth="lg"
        width="80%"
        classes={{ paper: "modal-content rounded-lg" }}
      >
        <div className="text-center mx-5 my-4">
        <h4 className="text-center">Tu jornada laboral ha sido</h4>
          <div className="avatar-icon-wrapper rounded-circle mb-3 mt-2">
            {/* <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-success text-success m-0 d-130"> */}
            <img
              alt="..."
              className="card-img-top rounded-sm"
              src={neutro}
              style={{ width: 70 }}
            />
            {/* </div> */}
          </div>
        </div>
        
        <div className="text-center px-2">
        <h5 className="font-weight-bold" style={{ color: "#5a318e" }}>
                ¿A qué se debe?
              </h5>
              <h6 className="mb-4">Debes seleccionar al menos una opción</h6>
              
              <div className="mb-spacing-6">
                  <Grid container spacing={4} className=" d-flex justify-content-center">
                    <Grid item xl={2} md={2} sm={2}>
                      <Card
                        className="shadow-xxl"
                        width={140}
                        height={140}
                        style={
                          iconContenidoTrabajo
                            ? {
                                borderRadius: "30px",
                                border: `solid 7px green`,
                                height: 150,
                                width: 150
                              }
                            : {}
                        }
                      >
                        <a
                          href="#/"
                          onClick={(e) => e.preventDefault()}
                          className="card-img-wrapper rounded"
                        >
                          <div className="img-wrapper-overlay p-10">
                            <div className="overlay-btn-wrapper ">
                            <Tooltip
                              classes={{ tooltip: 'text-center p-3 tooltip-secondary' }}
                              arrow
                              placement="top"
                              title="Tarea que realizas: cómo está diseñada, el sentido que tiene para ti, las habilidades que te requiere, la autonomía que te permite, y lo que te aporta.">
                              

                              <Button
                                size="small"
                                className="btn-info btn-pill px-6 hover-scale-sm mb-2"
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "info"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Información
                                </span>
                              </Button>
                              </Tooltip>
                              {/* <Button
                                size="small"
                                className="btn-warning btn-pill px-4 hover-scale-sm mb-2"
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "info"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Incluye
                                </span>
                              </Button> */}
                              {!iconContenidoTrabajo ?
                              <Button
                                size="small"
                                className="btn-success btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickContenidoTrabajo()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Seleccionar
                                </span>
                              </Button>
                              :
                              <Button
                                size="small"
                                className="btn-danger btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickContenidoTrabajo()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Quitar
                                </span>
                              </Button>
                              }
                            </div>
                          </div>
                          <img
                            src={tipoDeTrabajo}
                            className="card-img-top rounded"
                            alt="..."
                            style={{width: 140, height: 140}}
                          />
                        </a>
                      </Card>
                      <h5 className="text-center mt-4">
                      Características de las tareas
                      </h5>
                    </Grid>
                    <Grid item xl={2} md={2} sm={2}>
                    <Card
                        className="shadow-xxl"
                        width={140}
                        height={140}
                        style={
                          iconTrabajoGrupo
                            ? {
                                borderRadius: "30px",
                                border: `solid 7px green`,
                                height: 150,
                                width: 150
                              }
                            : {}
                        }
                      >
                        <a
                          href="#/"
                          onClick={(e) => e.preventDefault()}
                          className="card-img-wrapper rounded"
                        >
                          <div className="img-wrapper-overlay">
                            <div className="overlay-btn-wrapper">
                            <Tooltip
                              classes={{ tooltip: 'text-center p-3 tooltip-secondary' }}
                              arrow
                              placement="top"
                              title="Trabajo en equipo y cómo colaboráis en el logro de objetivos entre todas las personas.">
                              <Button
                                size="small"
                                className="btn-info btn-pill px-6 hover-scale-sm mb-2"
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "info"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Información
                                </span>
                              </Button>
                              </Tooltip>
                              {/* <Button
                                size="small"
                                className="btn-warning btn-pill px-4 hover-scale-sm mb-2"
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "info"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Incluye
                                </span>
                              </Button> */}
                              {!iconTrabajoGrupo ?
                              <Button
                                size="small"
                                className="btn-success btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickTrabajoGrupo()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Seleccionar
                                </span>
                              </Button>
                              :
                              <Button
                                size="small"
                                className="btn-danger btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickTrabajoGrupo()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Quitar
                                </span>
                              </Button>
                              }
                            </div>
                          </div>
                          <img
                            src={trabajoConPersonas}
                            className="card-img-top rounded"
                            alt="..."
                            style={{width: 140, height: 140}}
                          />
                        </a>
                      </Card>
                      <h5 className="text-center mt-4">
                        Trabajo con personas
                      </h5>
                    </Grid>
                    <Grid item xl={2} md={2} sm={2}>
                    <Card
                        className="shadow-xxl"
                        width={140}
                        height={140}
                        style={
                          iconTiempo
                            ? {
                                borderRadius: "30px",
                                border: `solid 7px green`,
                                height: 150,
                                width: 150
                              }
                            : {}
                        }
                      >
                        <a
                          href="#/"
                          onClick={(e) => e.preventDefault()}
                          className="card-img-wrapper rounded"
                        >
                          <div className="img-wrapper-overlay">
                            <div className="overlay-btn-wrapper">
                            <Tooltip
                              classes={{ tooltip: 'text-center p-3 tooltip-secondary' }}
                              arrow
                              placement="top"
                              title="Nivel de exigencia física y mental que te requiere tu trabajo.">
                              <Button
                                size="small"
                                className="btn-info btn-pill px-6 hover-scale-sm mb-2"
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "info"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Información
                                </span>
                              </Button>
                              </Tooltip>
                              {!iconTiempo ?
                              <Button
                                size="small"
                                className="btn-success btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickTiempo()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Seleccionar
                                </span>
                              </Button>
                              :
                              <Button
                                size="small"
                                className="btn-danger btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickTiempo()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Quitar
                                </span>
                              </Button>
                              }
                            </div>
                          </div>
                          <img
                            src={condicionesDeTrabajo}
                            className="card-img-top rounded"
                            alt="..."
                            style={{width: 140, height: 140}}
                          />
                        </a>
                      </Card>
                      <h5 className="text-center mt-4">
                        Nivel de exigencia
                      </h5>
                    </Grid>
                    <Grid item xl={2} md={2} sm={2}>
                    <Card
                        className="shadow-xxl"
                        width={140}
                        height={140}
                        style={
                          iconParticipacion
                            ? {
                                borderRadius: "30px",
                                border: `solid 7px green`,
                                height: 150,
                                width: 150
                              }
                            : {}
                        }
                      >
                        <a
                          href="#/"
                          onClick={(e) => e.preventDefault()}
                          className="card-img-wrapper rounded"
                        >
                          <div className="img-wrapper-overlay">
                            <div className="overlay-btn-wrapper">
                            <Tooltip
                              classes={{ tooltip: 'text-center p-3 tooltip-secondary' }}
                              arrow
                              placement="top"
                              title="Valoración que hacen tus superiores de tu trabajo (esfuerzo realizado y resultados obtenidos).">
                              <Button
                                size="small"
                                className="btn-info btn-pill px-6 hover-scale-sm mb-2"
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "info"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Información
                                </span>
                              </Button>
                              </Tooltip>
                              {!iconParticipacion ?
                              <Button
                                size="small"
                                className="btn-success btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickParticipacion()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Seleccionar
                                </span>
                              </Button>
                              :
                              <Button
                                size="small"
                                className="btn-danger btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickParticipacion()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Quitar
                                </span>
                              </Button>
                                }
                            </div>
                          </div>
                          <img
                            src={esfuerzoYResultados}
                            className="card-img-top rounded"
                            alt="..."
                            style={{width: 140, height: 140}}
                          />
                        </a>
                      </Card>
                      <h5 className="text-center mt-4">
                        Esfuerzo y resultados
                      </h5>
                    </Grid>
                    <Grid item xl={2} md={2} sm={2}>
                    <Card
                        className="shadow-xxl"
                        width={140}
                        height={140}
                        style={
                          iconRol
                            ? {
                                borderRadius: "30px",
                                border: `solid 7px green`,
                                height: 150,
                                width: 150
                              }
                            : {}
                        }
                      >
                        <a
                          href="#/"
                          onClick={(e) => e.preventDefault()}
                          className="card-img-wrapper rounded"
                        >
                          <div className="img-wrapper-overlay">
                            <div className="overlay-btn-wrapper">
                            <Tooltip
                              classes={{ tooltip: 'text-center p-3 tooltip-secondary' }}
                              arrow
                              placement="top"
                              title="Cómo te afectan las condiciones laborales en tu sensación de bienestar.">
                              <Button
                                size="small"
                                className="btn-info btn-pill px-6 hover-scale-sm mb-2"
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "info"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Información
                                </span>
                              </Button>
                              </Tooltip>
                              {!iconRol ?
                              <Button
                                size="small"
                                className="btn-success btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickRol()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Seleccionar
                                </span>
                              </Button>
                              :
                              <Button
                                size="small"
                                className="btn-danger btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickRol()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Quitar
                                </span>
                              </Button>
                              }
                            </div>
                          </div>
                          <img
                            src={sensacionDeBienestar}
                            className="card-img-top rounded"
                            alt="..."
                            style={{width: 140, height: 140}}
                          />
                        </a>
                      </Card>
                      <h5 className="text-center mt-4">
                        Sensación de Bienestar
                      </h5>
                    </Grid>
                  </Grid>
                </div>
          <h5 className="font-weight-bold mt-2" style={{ color: "#5a318e" }}>
            ¿En qué se puede mejorar?
          </h5>
          <h6>Opcional</h6>
          <div className="d-flex justify-content-center">
                <div  style={{ width: "80%"}} >
                <InputEmoji
                  value={commentary}
                  onChange={setCommentary}
                  cleanOnEnter
                  borderColor="purple"
                  placeholder="Escribe aquí"
                  keepOpenend
                />
                </div>
                </div>
          <div style={{ width: "80%", marginLeft:70 }} className="text-left">
            <div className="d-flex flex-row align-items-center">
              

     
           <div className="m-2" >
              <Switch
                onChange={handleChangeCheckTimeline}
                checked={checkTimeline}
                color="primary"
                name="checkedA"
                className="switch-small"
              />
            </div>
            <h6 className="mb-0">El comentario podrá ser visto siempre por los administradores. Si se selecciona el check, el comentario también será público y se mostrará en el Timeline </h6>
            </div>
            {/* <div className="d-flex flex-row align-items-center">
           <div className="m-2" >
              <Switch
                onChange={checkTimeline ? handleChangeCheckAdmin : null}
                checked={checkAdmin}
                color="primary"
                name="checkedA"
                className="switch-small"
              />
            </div>
            <h6 className="mb-0">Si se selecciona, se enviará una notificación a los administradores para que lean antes este comentario. (Debe estar marcada la opción anterior)</h6>
            </div> */}
            <h6 className="text-black-50 mb-0 mt-4">* por defecto se usa la configuración de privacidad de cada usuario, puedes consultarla en tu perfil</h6>
            </div>
          <div className="pt-4" style={{ marginBottom: 20 }}>
            <Button
              onClick={toggle3}
              className="btn-neutral-dark btn-pill mx-1"
            >
              <span className="btn-wrapper--label">Cancelar</span>
            </Button>
            <Button
              onClick={onClickButton} 
              className="btn-success btn-pill mx-1"
              disabled={(iconContenidoTrabajo === false &&
                iconParticipacion === false &&
                iconRol === false &&
                iconTiempo === false &&
                iconTrabajoGrupo === false)}  
            >
              <span className="btn-wrapper--label">Enviar</span>
            </Button>
          </div>
        </div>
      
      </Dialog>
    </>
  );
}

const marksRed = [
  {
    value: 1,
    label: (
      <h5 className="pt-2" color="red">
        <strong>Baja</strong>
      </h5>
    ),
  },
  {
    value: 10,
    label: (
      <h5 className="pt-2" color="red">
        <strong>Alta</strong>
      </h5>
    ),
  },
];

const PrettoSliderRed = withStyles({
  root: {
    color: "red",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);
