// STEP 1 - Include Dependencies

// Include react
import React from "react";
import ReactDOM from "react-dom";

// Include the react-fusioncharts component
import ReactFC from "react-fusioncharts";

// Include the fusioncharts library
import FusionCharts from "fusioncharts";

//Import the Widgets
import Widgets from "fusioncharts/fusioncharts.widgets";

// Include the theme as fusion
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

import {
  Grid,
  LinearProgress,
  Card,
  CardContent,
  Button,
} from "@material-ui/core";

// Adding the chart and theme as dependency to the core fusioncharts
ReactFC.fcRoot(FusionCharts, Widgets, FusionTheme);

// STEP 3 - Creating the JSON object to store the chart configurations
const chartConfigs = {};

// STEP 3 - Creating the DOM element to pass the react-fusioncharts component
export default function ThermometerGauge({ id, title, dataPercent }) {
  const dataSource = {
    chart: {
      // caption: "Clima de la organización",
      lowerLimit: "0",
      upperLimit: "100",
      numberSuffix: "°C",
      thmFillColor: "#29C3BE",
      plotToolText: "Temperatura: <b>$datavalue</b> ",
      theme: "fusion",
    },
    value: (dataPercent*100),
  }

  return (
    <Card className="card-box w-100 p-4 d-flex align-items-center">
      <div className="d-flex flex-column flex-grow-1 justify-content-center w-100">
        <div className="card-header-alt d-flex justify-content-between align-items-center">
          <h6 className="font-weight-bold font-size-lg mb-0 text-black">
            {title}
          </h6>
        </div>
        <div className="w-100 mx-auto py-3 justify-content-between align-items-center">
          <ReactFC
            type={"thermometer"}
            width={"100%"}
            height={"200"}
            dataFormat={"json"}
            id={id}
            dataSource={dataSource}
          />
        </div>
      </div>
    </Card>
  );
}
