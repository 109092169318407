import React, { useState } from 'react';

import {
    Grid,
    Button,
} from '@material-ui/core';

import { useDropzone } from 'react-dropzone';

import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';
import CloudUploadTwoToneIcon from '@material-ui/icons/CloudUploadTwoTone';
import CheckIcon from '@material-ui/icons/Check';
import XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { CSVLink } from "react-csv";
export default function Step1({ setEmployeesImport, handleNext }) {

    const handleExport = () => {
        let dataDemo = [
            {
                EMAIL: "demo@mail.com",
                EMAIL2: "demo@mail2.com",
                FNAME: "demo"
            },
            {
                EMAIL: "demo2@mail.com",
                EMAIL2: "demo2@mail2.com",
                FNAME: "demo2"
            },
        ];

        const ws = XLSX.utils.json_to_sheet(dataDemo);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
        const data = new Blob([excelBuffer], { type: "application/octet-stream" });

        FileSaver.saveAs(data, "demo.csv");
    };

    const getTableData = arr => {
        setEmployeesImport(arr)
        handleNext(1)
    }
    const plantilla = [{
        name: 'María',
        surname: 'Gómez García',
        email: 'mariagg@climind.com',
        dni: '00000001B',
        gender: 'MALE/FEMALE',
        position: 'contable',
    }]

    function Uploader(props) {
        const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
            accept: 'text/csv',
            onDrop: acceptedFiles => {
                var reader = new FileReader()
                reader.onload = function () {
                    var fileData = reader.result
                    var wb = XLSX.read(fileData, { type: "binary" })
                    
                    wb.SheetNames.forEach(function (sheetName) {
                        var rowObj = XLSX.utils.sheet_to_row_object_array(
                            wb.Sheets[sheetName]
                        )
                        rowObj[0].name = utf8_decode(rowObj[0].name)
                        rowObj[0].surname = utf8_decode(rowObj[0].surname)
                        console.log(rowObj)
                        props.getTableData(rowObj, acceptedFiles[0].name)
                    })
                }
                if (acceptedFiles.length) {
                    reader.readAsBinaryString(acceptedFiles[0])
                    // acceptedFiles.forEach(element => {
                    //     element.name = utf8_decode(element.name)
                    // });
                } else {
                }
            }
        })

        return (
            <section className="pb-1">
                <div className="dropzone">
                    <div
                        {...getRootProps({ className: 'dropzone-upload-wrapper' })}>
                        <input {...getInputProps()} />
                        <div className="dropzone-inner-wrapper dropzone-inner-wrapper-alt">
                            {isDragAccept && (
                                <div>
                                    <div className="d-100 btn-icon mb-3 hover-scale-rounded bg-success shadow-success-sm rounded-lg text-white">
                                        <CheckIcon className="d-50" />
                                    </div>
                                    <div className="font-size-xs text-success">
                                        El formato del archivos correcto
                                    </div>
                                </div>
                            )}
                            {isDragReject && (
                                <div>
                                    <div className="d-100 btn-icon mb-3 hover-scale-rounded bg-danger shadow-danger-sm rounded-lg text-white">
                                        <CloseTwoToneIcon className="d-50" />
                                    </div>
                                    <div className="font-size-xs text-danger">
                                        Este archivo no es csv
                                    </div>
                                </div>
                            )}
                            {!isDragActive && (
                                <div>
                                    <div className="d-100 btn-icon mb-3 hover-scale-lg bg-white shadow-light-sm rounded-circle text-primary">
                                        <CloudUploadTwoToneIcon className="d-50" />
                                    </div>
                                    <div className="font-size-sm">
                                        Arrastra aquí tu archivo{' '}
                                        <span className="font-size-xs text-dark">
                                            (csv)
                                        </span>
                                    </div>
                                </div>
                            )}

                            <small className="py-2 text-black-50">o</small>
                            <div>
                                <Button className="btn-primary hover-scale-sm font-weight-bold btn-pill px-4">
                                    <span className="px-2">Seleccionar archivo</span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    return (
        <>
            <div className="pt-2">
                <Grid container spacing={12} className="justify-content-center">
                    <div className="p-3">
                        <div className="px-2 pb-2">
                            <div className="font-weight-bold pb-1 font-size-lg text-capitalize">
                                Sube aquí tu archivo
                            </div>
                            <div className="text-black-50">
                                Desde esta opción puedes importar un csv con toda la información de tus empleados &nbsp;
                                <CSVLink
                                filename="users_template.csv"
                                data={plantilla}
                                >
                                Descargar plantilla
                                </CSVLink>
                            </div>
                        </div>
                        <Uploader getTableData={getTableData} />
                    </div>
                </Grid>
            </div>
        </>
    );
};

function utf8_decode (strData) { // eslint-disable-line camelcase
    //  discuss at: https://locutus.io/php/utf8_decode/
    // original by: Webtoolkit.info (https://www.webtoolkit.info/)
    //    input by: Aman Gupta
    //    input by: Brett Zamir (https://brett-zamir.me)
    // improved by: Kevin van Zonneveld (https://kvz.io)
    // improved by: Norman "zEh" Fuchs
    // bugfixed by: hitwork
    // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
    // bugfixed by: Kevin van Zonneveld (https://kvz.io)
    // bugfixed by: kirilloid
    // bugfixed by: w35l3y (https://www.wesley.eti.br)
    //   example 1: utf8_decode('Kevin van Zonneveld')
    //   returns 1: 'Kevin van Zonneveld'
    const tmpArr = []
    let i = 0
    let c1 = 0
    let seqlen = 0
    strData += ''
    while (i < strData.length) {
      c1 = strData.charCodeAt(i) & 0xFF
      seqlen = 0
      // https://en.wikipedia.org/wiki/UTF-8#Codepage_layout
      if (c1 <= 0xBF) {
        c1 = (c1 & 0x7F)
        seqlen = 1
      } else if (c1 <= 0xDF) {
        c1 = (c1 & 0x1F)
        seqlen = 2
      } else if (c1 <= 0xEF) {
        c1 = (c1 & 0x0F)
        seqlen = 3
      } else {
        c1 = (c1 & 0x07)
        seqlen = 4
      }
      for (let ai = 1; ai < seqlen; ++ai) {
        c1 = ((c1 << 0x06) | (strData.charCodeAt(ai + i) & 0x3F))
      }
      if (seqlen === 4) {
        c1 -= 0x10000
        tmpArr.push(String.fromCharCode(0xD800 | ((c1 >> 10) & 0x3FF)))
        tmpArr.push(String.fromCharCode(0xDC00 | (c1 & 0x3FF)))
      } else {
        tmpArr.push(String.fromCharCode(c1))
      }
      i += seqlen
    }
    return tmpArr.join('')
  }