import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GaugeChart from "react-gauge-chart";
import {
  Grid,
  LinearProgress,
  Card,
  CardContent,
  Button,
} from "@material-ui/core";

export default function CardClimind({ id, title, dataPercent,type }) {
  const [currentPercent2, setCurrentPercent2] = useState();
  let typeCard = "percent"
  if(type){
    typeCard = type;
  }

  return (
    <Card className="card-box  p-4 w-100 h-100">
      <div className="d-flex align-items-center">
        <div className="d-40 btn-icon rounded-circle bg-primary text-white text-center font-size-lg mr-3">
          <FontAwesomeIcon icon={["fas", "users"]} />
        </div>
        <div className="text-black-50">{title}</div>
      </div>
      <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
        {typeCard === "percent" ? (<div>{Math.round(dataPercent * 100)} %</div>):<div>{Math.round(dataPercent)}</div>}
      </div>
      {dataPercent < 0.15 ? (
        <div className="text-black-50 text-center pt-3">
          <b>
            <FontAwesomeIcon
              icon={["fas", "circle"]}
              className="font-size-lg mr-2"
              color="red"
            />
          </b>{" "}
          Rojo
        </div>
      ) : dataPercent < 0.3 ? (
        <div className="text-black-50 text-center pt-3">
          <b>
            <FontAwesomeIcon
              icon={["fas", "circle"]}
              className="font-size-lg mr-2 "
              color="yellow"
            />
          </b>{" "}
          Amarillo
        </div>
      ) : (
        <div className="text-black-50 text-center pt-3">
            <FontAwesomeIcon
              icon={["fas", "circle"]}
              className="font-size-lg mr-2"
              color="green"
            />
          Verde
        </div>
      )}
    </Card>
  );
}

