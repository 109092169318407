import React, { useState, useCallback, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { UserContext } from "../../../../../../context/UserContext";
import {
    Table,
    Tooltip,
    Button,
    Dialog,
    DialogTitle,
    Grid,
} from "@material-ui/core";
import { GETS_METRICS_DEATILS_FOR_NATURES, GETS_FOR_GENERAL } from "../../../../../../data/queries";



export default function ModalViewVariable({
    dataNature,
    openViewVariable,
    toggleViewVariable,
    filtersInput
}) {
    const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
    let companyId = dbuser.getUserByUid.companyId;

    const { loading, error, data, networkStatus } = useQuery(GETS_METRICS_DEATILS_FOR_NATURES, {
        variables: { id: dataNature.id, companyId: companyId, input: filtersInput },
        skip: !dataNature.id,
        notifyOnNetworkStatusChange: true,
    });

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [anchorElFilter, setAnchorElFilter] = useState(null);

    const handleClickFilter = (event) => {
        setAnchorElFilter(event.currentTarget);
    };

    const handleCloseFilter = () => {
        setAnchorElFilter(null);
    };

    const [searchOpen, setSearchOpen] = useState(false);

    const openSearch = () => setSearchOpen(true);
    const closeSearch = () => setSearchOpen(false);

    const [status, setStatus] = useState("");

    const handleStatus = (event) => {
        setStatus(event.target.value);
    };

    const [naturesList, setNaturesList] = useState([]);
    const [naturesListFilter, setNaturesListFilter] = useState([]);
    const [variablesArrayRadar, setVariablesArrayRadar] = useState([]);

    let uniqueNatures = [];

    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    const kpiIndicator = (dataPercent) => {
        // console.log(dataPercent);
        if (!dataPercent) {
            return (
                <div className="text-left font-size-lg text-center">
                    Sin datos
                </div>
            )
        }
        if (dataPercent == 0) {
            return (
                <div className="text-left font-size-lg text-center">
                    {/* <small className="text-black-50 d-block">(>30%)</small>
          <div className="px-4 py-1 h-auto text-danger border-1 border-danger badge badge-neutral-danger">
            Critico
          </div> */}
                    <div className="avatar-icon-wrapper avatar-initials avatar-icon-lg">
                        <Tooltip
                            classes={{
                                tooltip: "text-center p-3 tooltip-primary",
                            }}
                            arrow
                            placement="top"
                            title="0 de 2 personas han marcado este punto de vista"
                        >
                            <div className="avatar-icon text-white bg-success">
                                {Math.round(dataPercent * 100) + "%"}
                            </div>
                        </Tooltip>
                    </div>
                </div>
            );
        } else if (dataPercent < 0.15 && dataPercent > 0) {
            return (
                <div className="text-left font-size-lg text-center">
                    {/* <small className="text-black-50 d-block">(>30%)</small>
          <div className="px-4 py-1 h-auto text-danger border-1 border-danger badge badge-neutral-danger">
            Critico
          </div> */}
                    <div className="avatar-icon-wrapper avatar-initials avatar-icon-lg">
                        <div className="avatar-icon text-white bg-success">
                            {Math.round(dataPercent * 100) + "%"}
                        </div>
                    </div>
                </div>
            );
        } else if (dataPercent >= 0.15 && dataPercent < 0.3) {
            return (
                <div className="text-left font-size-lg text-center">
                    {/* <small className="text-black-50 d-block">(>30%)</small>
          <div className="px-4 py-1 h-auto text-warning border-1 border-warning badge badge-neutral-warning">
            Atender
          </div> */}
                    <div className="avatar-icon-wrapper avatar-initials avatar-icon-lg">
                        <div className="avatar-icon text-white bg-warning">
                            {Math.round(dataPercent * 100) + "%"}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="text-left font-size-lg text-center">
                    {/* <small className="text-black-50 d-block">(>30%)</small>
          <div className="px-4 py-1 h-auto text-success border-1 border-success badge badge-neutral-success">
            Mantener
          </div> */}
                    <div className="avatar-icon-wrapper avatar-initials avatar-icon-lg">
                        <div className="avatar-icon text-white bg-danger">
                            {Math.round(dataPercent * 100) + "%"}
                        </div>
                    </div>
                </div>
            );
        }
    };
    const valueIndicator = (value) => {
        console.log(value);
        if (!value) {
            return (
                <div className="text-center font-size-lg">

                    Sin datos

                </div>
            )
        }
        if (value < 4) {
            return (
                <div className="text-center font-size-lg">
                    <span className="text-danger">{value.toFixed(2)}</span>
                </div>
            );
        } else if (value < 7 && value >= 4) {
            return (
                <div className="text-center font-size-lg">
                    <span className="text-warning">{value.toFixed(2)}</span>
                </div>
            );
        } else {
            return (
                <div className="text-center font-size-lg">
                    <span className="text-success">{value.toFixed(2)}</span>
                </div>
            );
        }
    };

    const handleFilterVariables = () => {
        let filterNatures = naturesList.filter((el) => el.variable === status)
        console.log(filterNatures)
        setNaturesListFilter(filterNatures)
        handleCloseFilter()
    }
    console.log(variablesArrayRadar)
    let variablesArrayTable = [];

    if (data) {

        if (data.getTreeEntityMetricsById != null) {
            //Obtener naturalezas
            data.getTreeEntityMetricsById.map((el, sindex) => {
                let itemEl = {};
                itemEl.id = el.id;
                itemEl.name = el.name;
                itemEl.description = el.description;
                itemEl.order = el.order;
                itemEl.value = el.value;
                itemEl.numberAnswers = el.numberAnswers;
                itemEl.kpi = el.value / el.numberAnswers;
                itemEl.bosses = el.badWithBosses / el.numberAnswers;
                itemEl.clients = el.badWithClients / el.numberAnswers;
                itemEl.departments =
                    el.badWithDepartments / el.numberAnswers;
                itemEl.partners = el.badWithPartners / el.numberAnswers;
                itemEl.numberUsers = el.numberUsers;
                variablesArrayTable.push(itemEl);
            });
        }

        variablesArrayTable.sort((a, b) => a.order - b.order);


        return (
            <>
                <Dialog
                    open={openViewVariable}
                    onClose={toggleViewVariable}
                    classes={{ paper: "modal-content rounded-lg" }}
                    maxWidth="lg"
                    fullWidth
                >
                    <DialogTitle className="bg-white-10">
                        Ver Naturaleza: {dataNature.name.toUpperCase()}
                        <Tooltip
                            classes={{
                                tooltip: "text-center p-3 tooltip-primary",
                            }}
                            arrow
                            placement="bottom"
                            title={dataNature.description}
                        >
                            <Button
                                variant="text"
                                className="p-0 d-30 border-0 btn-transition-none text-dark"
                                disableRipple
                            >
                                <FontAwesomeIcon
                                    icon={["far", "question-circle"]}
                                    className="font-size-lg"
                                />
                            </Button>
                        </Tooltip>
                    </DialogTitle>

                    <Grid item>
                        {/* <Card className="card-box shadow-none mb-5"> */}
                        <div className="divider" />
                        <div className="p-4">
                            <div className="table-responsive-md">
                                <Table className="table table-alternate-spaced mb-0">
                                    <thead>
                                        <tr>
                                            <th
                                                // style={{ width: "300px" }}
                                                className="font-size-lg font-weight-normal text-capitalize text-dark text-center"
                                                scope="col"
                                            >
                                                Dimensión
                                            </th>
                                            <th
                                                className="font-size-lg font-weight-normal text-capitalize text-dark text-center"
                                                scope="col"
                                            >
                                                Info
                                            </th>
                                            <th
                                                className="font-size-lg font-weight-normal text-capitalize text-dark text-center"
                                                scope="col"
                                            >
                                                Num. Respuestas
                                            </th>
                                            <th
                                                className="font-size-lg font-weight-normal text-capitalize text-dark text-center"
                                                scope="col"
                                            >
                                                Num. Usuarios
                                            </th>
                                            <th
                                                className="font-size-lg font-weight-normal text-capitalize text-dark text-center"
                                                scope="col"
                                            >
                                                Valor
                                            </th>
                                            <th
                                                className="font-size-lg font-weight-normal text-capitalize text-dark text-center"
                                                scope="col"
                                            >
                                                Entre Compañeros
                                            </th>
                                            <th
                                                className="font-size-lg font-weight-normal text-capitalize text-dark text-center"
                                                scope="col"
                                            >
                                                Con Superiores
                                            </th>
                                            <th
                                                className="font-size-lg font-weight-normal text-capitalize text-dark text-center"
                                                scope="col"
                                            >
                                                Entre Departamentos
                                            </th>
                                            <th
                                                className="font-size-lg font-weight-normal text-capitalize text-dark text-center"
                                                scope="col"
                                            >
                                                Clientes o proveedores
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {variablesArrayTable.map((el1, index) => {
                                            let valueI = valueIndicator(el1.value);
                                            let partnersIndicator = kpiIndicator(el1.partners);
                                            let bossesIndicator = kpiIndicator(el1.bosses);
                                            let departmentsIndicator = kpiIndicator(el1.departments);
                                            let clientsIndicator = kpiIndicator(el1.clients);
                                            uniqueNatures.indexOf(el1) === -1 ? uniqueNatures.push(el1) : console.log("ya existe");
                                            return (
                                                <>
                                                    <tr key={index}>
                                                        <td className="text-center">
                                                            <span className="font-weight-bold">
                                                                {capitalize(el1.name)}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <div className="text-center"><Tooltip
                                                                classes={{
                                                                    tooltip: "text-center p-3 tooltip-primary",
                                                                }}
                                                                arrow
                                                                placement="bottom"
                                                                title={capitalize(el1.description)}
                                                            >
                                                                <Button
                                                                    variant="text"
                                                                    className="p-0 d-30 border-0 btn-transition-none text-dark"
                                                                    disableRipple
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={["far", "question-circle"]}
                                                                        className="font-size-lg"
                                                                    />
                                                                </Button>
                                                            </Tooltip>
                                                            </div></td>
                                                        <td><div className="text-center font-size-lg">{el1.numberAnswers}</div></td>
                                                        <td><div className="text-center font-size-lg">{el1.numberUsers}</div></td>
                                                        <td>{valueI}</td>
                                                        <td>{partnersIndicator}</td>
                                                        <td>{bossesIndicator}</td>
                                                        <td>{departmentsIndicator}</td>
                                                        <td>{clientsIndicator}</td>
                                                    </tr>
                                                    <tr className="divider"></tr>
                                                </>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        {/* </Card> */}
                    </Grid>
                </Dialog>
            </>
        )
    }
    return null
}
