import React, { useState, useContext } from "react";

import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Card, List, ListItem, Button ,Container} from "@material-ui/core";

import BasicInformation from './BasicInformation';
import AdditionalInformation from './AdditionalInformation';
import FormTicket from './FormTicket';
// import AdministratorsTab from './AdministratorsTab';

import { UserContext } from "../../../../context/UserContext";

import { useQuery } from '@apollo/react-hooks';
import { GET_COMPANY_AND_ADMINS_BY_COMPANY_ID } from 'data/queries';



export default function GeneralTabs() {

  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  let companyId = dbuser.getUserByUid.companyId
  let userID = dbuser.getUserByUid.id
  let userEmail = dbuser.getUserByUid.email

  const [activeTab, setActiveTab] = useState( (dbuser.getUserByUid.role === "admin" || dbuser.getUserByUid.role === "superadmin") ? "0" : "2");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const { loading, error, data, refetch, networkStatus } = useQuery(GET_COMPANY_AND_ADMINS_BY_COMPANY_ID,
    {
      variables: { id: companyId },
      skip: !companyId,
      notifyOnNetworkStatusChange: true
    },
  );

  if (networkStatus === 4) return 'Refetching!';
  if (loading) return null;
  if (error) return `Error! ${error}`;

  if (data) {

    let companyData = data.getCompanyById;
    console.log(data)
    return (
      <>
        <Grid container spacing={6}>
          <Grid item lg={12}>
            <Card className="shadow-xxl p-3">
              <List className="nav-tabs nav-tabs-primary d-flex">
                {(dbuser.getUserByUid.role === "admin" || dbuser.getUserByUid.role === "superadmin") && <ListItem
                  button
                  disableRipple
                  selected={activeTab === "0"}
                  onClick={() => {
                    toggle("0");
                  }}
                >
                  Perfil de empresa
                </ListItem>}
                {/* <ListItem
                  button
                  disableRipple
                  selected={activeTab === "1"}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  Administradores
              </ListItem> */}
                <ListItem
                  button
                  disableRipple
                  selected={activeTab === "2"}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  Ajustes y Soporte
                </ListItem>
              </List>

              {(dbuser.getUserByUid.role === "admin" || dbuser.getUserByUid.role === "superadmin") && <div
                className={clsx("tab-item-wrapper", {
                  active: activeTab === "0",
                })}
                index={0}
              >
                <AdditionalInformation companyData={companyData} companyId={companyId} />
              </div>}

              <div
                className={clsx("tab-item-wrapper", {
                  active: activeTab === "1",
                })}
                index={2}
              >
                <div className="text-center">
                  <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-primary shadow-primary-sm text-primary d-90">
                    <FontAwesomeIcon
                      icon={["far", "gem"]}
                      className="d-flex align-self-center font-size-xxl"
                    />
                  </div>
                  <h6 className="font-weight-bold font-size-xxl mb-1 mt-3 text-primary">
                    Sección en desarrollo
                  </h6>
                  <p className="text-black-50 font-size-lg mb-0">
                    Estamos desarrollando lo mejor para tu empresa y tus empleados.
                  </p>
                </div>
              </div>

              <div
                className={clsx("tab-item-wrapper", {
                  active: activeTab === "2",
                })}
                index={3}
              >
                <Grid container>
                  <Grid item md={4} className="my-auto">
                    <div className="text-center">
                      <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-primary shadow-primary-sm text-primary d-90">
                        <FontAwesomeIcon
                          icon={["fas", "ticket-alt"]}
                          className="d-flex align-self-center font-size-xxl"
                        />
                      </div>
                      <h6 className="font-weight-bold font-size-xxl mb-1 mt-3 text-primary">
                        Crear un ticket
                      </h6>
                    </div>
                  </Grid>
                  <Grid item md={8}>
                    <FormTicket companyData={companyData} companyId={companyId} userID={userID} userEmail={userEmail}/>
                  </Grid>
                </Grid>
              </div>
            </Card>
          </Grid>
        </Grid>
      </>
    );

  }
}
