import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  LinearProgress,
  Card,
  Menu,
  Button,
  List,
  ListItem,
  CardContent,
  Table
} from "@material-ui/core";

import Chart from "react-apexcharts";
import CountUp from "react-countup";
import { CircularProgressbar } from "react-circular-progressbar";
import Trend from "react-trend";
import PerfectScrollbar from "react-perfect-scrollbar";
import regalo from "../../../../../assets/images/climind/regalo.png";
import felicitacion from "../../../../../assets/images/climind/thumbs-up.png";
import agradecimiento from "../../../../../assets/images/climind/pray.png";
import grafico from "../../../../../assets/images/climind/planta-de-cultivo.svg";
import avatar from "../../../../../assets/images/climind/default_avatar.png";
export default function DashboardRecognitions1({
  totalRecognitions,
  totalCongratulations,
  totalProposal,
  totalGratitudes,
  totalActions,
  dataRecognitionsCount
}) {

  let percentRecognotions = (totalRecognitions / totalActions * 100).toFixed(0)
  let percentCongratulations = (totalCongratulations / totalActions * 100).toFixed(0)
  let percentProposal = (totalProposal / totalActions * 100).toFixed(0)
  let percentGratitudes = (totalGratitudes / totalActions * 100).toFixed(0)

  console.log(percentRecognotions);

  return (
    <>
      <div className="mb-spacing-6">
        <Grid container spacing={6}>
          <Grid item xl={12}>
            <Grid container spacing={6}>
              <Grid item md={3}>
                <Card className="mb-5 card-box card-box-border-bottom border-success">
                  <CardContent>
                    <div className="text-center">
                      <div className="mt-1 mb-3">
                        <span>Reconocimientos enviados</span>
                      </div>
                      <img
                        alt="..."
                        className="card-img-top rounded-sm mb-2"
                        src={felicitacion}
                        style={{ width: 70 }}
                      />
                      <div className="mt-3 line-height-sm">
                        <b className="font-size-xl pr-1">{totalRecognitions}</b>

                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={3}>
                <Card className="mb-5 card-box card-box-border-bottom border-danger">
                  <CardContent>
                    <div className="text-center">
                      <div className="mt-1 mb-3">
                        <span>Propuestas de mejora enviadas</span>
                      </div>
                      <img
                        alt="..."
                        className="card-img-top rounded-sm mb-2"
                        src={grafico}
                        style={{ width: 70 }}
                      />
                      <div className="mt-3 line-height-sm">
                        <b className="font-size-xl pr-1">{totalProposal}</b>

                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={3}>
                <Card className="mb-5 card-box card-box-border-bottom border-warning">
                  <CardContent>
                    <div className="text-center">
                      <div className="mt-1 mb-3">
                        <span>Felicitaciones enviadas</span>
                      </div>
                      <img
                        alt="..."
                        className="card-img-top rounded-sm mb-2"
                        src={regalo}
                        style={{ width: 70 }}
                      />
                      <div className="mt-3 line-height-sm">
                        <b className="font-size-xl pr-1">{totalCongratulations}</b>

                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={3}>
                <Card className="mb-5 card-box card-box-border-bottom border-info">
                  <CardContent>
                    <div className="text-center">
                      <div className="mt-1 mb-3">
                        <span>Agradecimientos enviados</span>
                      </div>
                      <img
                        alt="..."
                        className="card-img-top rounded-sm mb-2"
                        src={agradecimiento}
                        style={{ width: 70 }}
                      />
                      <div className="mt-3 line-height-sm">
                        <b className="font-size-xl pr-1">{totalGratitudes}</b>

                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={12}>
            <Card className="card-box">
              <div className="card-header">
                <div className="card-header--title">
                  <small className="d-block text-uppercase mt-1">
                    Evolución del feedback
                  </small>
                  <b>Acciones realizadas</b>
                </div>
              </div>
              <div className="px-5 pt-5 pb-5">
                <h1 className="display-2 font-weight-bold mb-4">
                  <span className="pl-1">
                    <CountUp
                      start={0}
                      end={totalActions}
                      duration={4}
                      separator=""
                      decimals={0}
                      decimal=","
                      prefix=""
                      suffix=""
                    />
                  </span>
                  {" "}Acciones realizadas en el periodo seleccionado
                </h1>
                <Grid container spacing={6} className="mb-10">
                  <Grid item sm={3}>
                    <div>
                      <span className="font-size-xl font-weight-bold">{(percentRecognotions && !isNaN(percentRecognotions)) ? percentRecognotions : 0}%</span>
                    </div>
                    <LinearProgress
                      variant="determinate"
                      value={percentRecognotions ? percentRecognotions : 0}
                      className="progress-animated-alt progress-bar-rounded progress-sm mb-2 progress-bar-success"
                    />
                    <div className="text-dark">Reconocimientos</div>
                  </Grid>
                  <Grid item sm={3}>
                    <div>
                      <span className="font-size-xl font-weight-bold">{(percentProposal && !isNaN(percentProposal)) ? percentProposal : 0}%</span>
                    </div>
                    <LinearProgress
                      variant="determinate"
                      value={percentProposal ? percentProposal : 0}
                      className="progress-bar-danger progress-bar-rounded progress-sm mb-2"
                    />
                    <div className="text-dark">Propuestas de mejora</div>
                  </Grid>
                  <Grid item sm={3}>
                    <div>
                      <span className="font-size-xl font-weight-bold">{(percentCongratulations && !isNaN(percentCongratulations)) ? percentCongratulations : 0}%</span>
                    </div>
                    <LinearProgress
                      variant="determinate"
                      value={percentCongratulations ? percentCongratulations : 0}
                      className="progress-bar-warning progress-bar-rounded progress-sm mb-2"
                    />
                    <div className="text-dark">Felicitaciones</div>
                  </Grid>
                  <Grid item sm={3}>
                    <div>
                      <span className="font-size-xl font-weight-bold">{(percentGratitudes && !isNaN(percentGratitudes)) ? percentGratitudes : 0}%</span>
                    </div>
                    <LinearProgress
                      variant="determinate"
                      value={percentGratitudes ? percentGratitudes : 0}
                      className="progress-bar-info progress-bar-rounded progress-sm mb-2"
                    />
                    <div className="text-dark">Agradecimientos</div>
                  </Grid>
                </Grid>
              </div>
            </Card>
          </Grid>
          <Grid item xl={12}>
            <Card className="card-box">
              <div className="card-header">
                <div className="card-header--title">
                  <small className="d-block text-uppercase mt-1">
                    Competencias recibidas
                  </small>
                  <b>Total de competencias recibidas en el periodo seleccionado (Se actualiza cada 24h)</b>
                </div>
              </div>
              <div className="pt-4 px-4">
                <Table className="table table-alternate-spaced text-nowrap mb-0">
                  <thead className="bg-white font-size-sm text-uppercase">
                    <tr>
                      <th className="bg-white text-left" style={{ width: '40%', marginTop: 10 }}>Nombre</th>
                      <th className="bg-white text-left" style={{ width: '40%', marginTop: 10 }}>Tipo</th>
                      <th className="bg-white text-center" style={{ width: '20%', marginTop: 10 }}>Recibidas</th>

                    </tr>
                  </thead>
                  <tbody>
                    {dataRecognitionsCount.getRecognitionsReceivedByCompanyIdCount.map((element, index) => {
                      return (
                        <>
                          <tr>
                            <td className="px-4">
                              <div className="d-flex align-items-left">
                                <div className="avatar-icon-wrapper avatar-icon-lg mr-3">
                                  <div className="avatar-icon rounded-circle">
                                    {element.datacompetence?.urlImage ? (
                                      <img alt="..." src={element.datacompetence?.urlImage} />
                                    ) : (
                                      <img alt="..." src={avatar} />
                                    )}
                                  </div>
                                </div>
                                <div>
                                  <div className="font-weight-bold">
                                    {element.datacompetence?.name}
                                  </div>

                                </div>
                              </div>
                            </td>
                            <td className="text-left">
                              <span>{element.datacompetence?.type}</span>
                            </td>


                            <td className="text-center">
                              <div className="font-weight-bold">{element.count}</div>
                            </td>

                          </tr>
                          <tr className="divider"></tr>
                        </>
                      )
                    })}
                  </tbody>
                </Table>
              </div>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
