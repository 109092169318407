import React, { useState, useCallback, useContext } from "react";
import ModalSelectDepartment from "./ModalSelectDepartment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import defaultAvatar from "../../../../assets/images/climind/default_avatar.png";
import {
  Dialog,
  Button,
  Card,
  TextField,
  Menu,
  Snackbar,
  Switch,
  Grid,
  Table,
} from "@material-ui/core";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { UserContext } from "../../../../context/UserContext";
import {
  GET_DEPARTMENTS,
  GET_DEPARTMENTS_GROUPS_AND_USER
} from "data/queries";
import { ADD_USERS_TO_DEPARTMENT } from "data/mutations";

import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function valuetext(value) {
  return <span>{{ value }}°C</span>;
}

export default function ModalAddToDepartment({
  openModalAddTeam,
  toggleModalAddTeam,
  dataEmployees,
  compId
}) {

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleBack = async (e) => {
    toggleModalAddTeam();
  };

  const handleOk = async (e) => {
    let dataIdList = []
    dataEmployees.map((item,index)=>{
      dataIdList.push(item.id)
    })
    let response = await addUsersToDepartment({
      variables: { id: deparmentSelected.id, users: dataIdList },
      refetchQueries: [
        {
          query: GET_DEPARTMENTS_GROUPS_AND_USER,
          variables: { id: companyId },
        },
      ],
    });
    console.log("mutation response:" + response);
    setOpenSnackbar(true, () => {
      setTimeout(function () {
        setOpenSnackbar(false);
      }, 5000); //5 Second delay
    });
    //refetch()
    toggleModalAddTeam();
  };

  //State
  const [deparmentSelected, setDepartmentSelected] = useState(null);
  const [modalDepartment, setModalDepartment] = useState(false);
  const toggleDepartment = () => setModalDepartment(!modalDepartment);

  //Variables
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  let companyId = null;
  if (compId) {
    companyId = compId;
  } else {
    companyId = dbuser.getUserByUid.companyId;
  }

  //Hooks
  const { loading, error, data, networkStatus } = useQuery(GET_DEPARTMENTS, {
    variables: { id: companyId },
    skip: !companyId,
    notifyOnNetworkStatusChange: true,
  });
  const [addUsersToDepartment, { dataDepartment }] = useMutation(
    ADD_USERS_TO_DEPARTMENT
  );

  //Functions

  if (data) {
    let departmentsList = data.getDepartmentsByCompanyId;

    return (
      <>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          key={"top,center"}
          open={openSnackbar}
          // classes={{ root: "toastr-primary" }}
          autoHideDuration={4000}
          onClose={() => setOpenSnackbar(false)}
          message={"entregado correctamente"}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity="success"
            style={{ color: "white" }}
          >
            Asignado correctamente
          </Alert>
        </Snackbar>
        <Dialog
          open={openModalAddTeam}
          onClose={handleBack}
          maxWidth="lg"
          width="80%"
          classes={{ paper: "modal-content rounded-lg" }}
        >
          <div className="p-4 text-center">
            <h5 className="font-size-xl mb-1 font-weight-bold mx-5">
              Asignar usuarios a departamento
            </h5>
            <p className="text-black-50 mb-4">Obligatorio</p>
            <Grid container spacing={12}>
              <Grid item md={12}>
                {deparmentSelected && (
                  <>
                    <div className="d-flex align-items-center justify-content-center mb-2 mt-3">
                      <div
                        className="avatar-icon-wrapper avatar-initials avatar-icon-lg pr-2"
                        // key={index}
                      >
                        <div
                          className={`avatar-icon text-white bg-${deparmentSelected.color}`}
                        >
                          {deparmentSelected.name.charAt(0).toUpperCase()}
                        </div>
                      </div>
                      <div>
                        <a
                          href="#/"
                          onClick={(e) => e.preventDefault()}
                          className="font-weight-bold text-black"
                          title="..."
                        >
                          {deparmentSelected.name}
                        </a>
                        <span className="text-black-50 d-block">
                          {deparmentSelected.description}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                <Button
                  className="btn-success text-uppercase font-weight-bold font-size-xs my-2"
                  style={{ backgroundColor: "#5a318e" }}
                  onClick={toggleDepartment}
                >
                  {deparmentSelected
                    ? "Cambiar Departamento"
                    : "Seleccionar Departamento"}
                </Button>
              </Grid>
            </Grid>
          </div>
          <div className="text-center px-2">
            <h5 className="font-weight-bold mt-2" style={{ color: "#5a318e" }}>
              Usuarios seleccionados:
            </h5>
            <div className="table-responsive-md mx-2">
              {/* <Table className="table table-borderless table-hover text-nowrap mb-0"> */}
              <Table className="table text-nowrap mb-0">
                <thead>
                  <tr>
                    <th className="text-left">Usuario</th>
                    <th className="text-left">Email</th>
                  </tr>
                </thead>
                <tbody>
                  {dataEmployees.map((element, index) => {
                    return (
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="avatar-icon-wrapper">
                              <div className="avatar-icon">
                                {element.urlImage ? (
                                  <img alt="..." src={element.urlImage} />
                                ) : (
                                  <img alt="..." src={defaultAvatar} />
                                )}
                              </div>
                            </div>
                            <div>
                              <a
                                href="#/"
                                onClick={(e) => e.preventDefault()}
                                className="font-weight-bold text-black"
                                title="..."
                              >
                                {element.name}
                              </a>
                              <span className="text-black-50 d-block">
                                {element.mail}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div>
                              <a
                                href="#/"
                                onClick={(e) => e.preventDefault()}
                                className="font-weight-bold text-black"
                                title="..."
                              >
                                {element.email}
                              </a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>

            <h6 className="text-black-50 mb-0 mt-4">
              Si deseas modificar la lista de usuarios vuelve a la vista
              anterior
            </h6>

            <div className="pt-4" style={{ marginBottom: 20 }}>
              <Button
                onClick={handleBack}
                className="btn-neutral-dark btn-pill mx-1"
              >
                <span className="btn-wrapper--label">Cancelar</span>
              </Button>
              <Button
                onClick={handleOk}
                className="btn-success btn-pill mx-1"
                // disabled={!buttonValue}
              >
                <span className="btn-wrapper--label">Enviar</span>
              </Button>
            </div>
          </div>
        </Dialog>
        <ModalSelectDepartment
          modalDepartment={modalDepartment}
          toggleDepartment={toggleDepartment}
          deparmentSelected={deparmentSelected}
          setDepartmentSelected={setDepartmentSelected}
          departmentsList={departmentsList}
        />
      </>
    );
  } else {
    return null;
  }
}
