import React from 'react';

import { PageTitle } from 'layout-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Card, Button, List, Table } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UsersTable from './components/UsersTable'


export default function Users() {
  return (
    <>
      <PageTitle
        titleHeading="Usuarios"
        titleDescription="Información de todos los usuarios."
      />
      <UsersTable/>

    </>
  );
}
