import React, { useState, useCallback, useContext } from "react";
import {GET_USERS_BY_COMPANY_ID_WITHOUT_ME} from 'data/queries';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "../../context/UserContext";
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  Table,
  LinearProgress,
  Card,
  CardContent,
  Button,
  Tooltip,
  Dialog,
} from "@material-ui/core";
import clsx from 'clsx';

import { InputAdornment, TextField } from '@material-ui/core';

import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';

import defaultAvatar from "../../assets/images/climind/default_avatar.png";
import { USER_REWARD } from "data/mutations";
import { GET_CHALLENGES_BY_COMPANYID, GET_CHALLENGE } from "data/queries";
var moment = require("moment-timezone");
export default function ModalViewChallenge({ toggleViewChallenge, modalViewChallenge, challengeSelected, setChallengeSelected, refetchChallenges }) {
  console.log(challengeSelected)
    const [filterTable, setFilterTable] = useState(null)
    const [searchStatus1, setSearchStatus1] = useState(false);
    const [reload, setReload] = useState(false);
    const toggleSearch1 = () => setSearchStatus1(!searchStatus1);
    const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
    const [userReward,{ data: dataUserReward, error: errorUserReward, loading: loadingUserReward }] = useMutation(USER_REWARD);
    const {loading: loadingChallenge,error: errorChallenge,data: dataChallenge,networkStatus: networkStatusChallenge, refetch: refetchChallenge} = useQuery(GET_CHALLENGE,
      {
      variables:{ challengeId: challengeSelected.id},
      skip: !challengeSelected.id,
      notifyOnNetworkStatusChange: true,
    })
    const onRewardUser = async (element) => {
      console.log(element)
      let userId = dbuser.getUserByUid.id
      let userRewardId = element._id
      let challengeId = challengeSelected.id

      let responseReward = await userReward({
          variables: {
            userId: userId,
            challengeId: challengeId,
            userRewardId: userRewardId
          },refetchQueries:[
            { query: GET_CHALLENGE, variables: { challengeId: challengeId } }]
      });
      refetchChallenges()
      setReload(!reload)
    };

      if(dataChallenge){
        let groups = ""
        let departments = ""
        dataChallenge.getChallenge.groups.forEach(element => {
          groups = groups + `${element.name},`
        });
        dataChallenge.getChallenge.departments.forEach(element => {
          departments = departments + `${element.name},`
        });
        return (
            <>
              <Dialog
                open={modalViewChallenge}
                onClose={() => {
                  toggleViewChallenge()
                  setChallengeSelected(null)
                }}
                maxWidth="xl"
                classes={{ paper: "modal-content rounded-lg" }}
              >
                <div className="text-center p-5 mb-3">
                  <h4 className="text-center font-weight-bold mb-20" style={{marginBottom:30}}>
                    Información del reto
                  </h4>
                  <h6 className="text-center mb-4">
                    Nombre: {dataChallenge.getChallenge.name}
                  </h6>
                  <h6 className="text-center mb-4">
                    Descripción: {dataChallenge.getChallenge.description}
                  </h6>
                  {dataChallenge.getChallenge.forAllCompany && <h6 className="text-center mb-4">
                    Destinatarios del reto: Toda la empresa
                  </h6>}
                  {!dataChallenge.getChallenge.forAllCompany && dataChallenge.getChallenge.groups.length > 0 && <h6 className="text-center mb-4">
                    Grupos destinatarios del reto: {groups}
                  </h6>}
                  {!dataChallenge.getChallenge.forAllCompany && dataChallenge.getChallenge.departments.length > 0 && <h6 className="text-center mb-4">
                    Departamentos destinatarios del reto: {departments}
                  </h6>}
                  <h6 className="text-center mb-4">
                    Criterio de validación: {dataChallenge.getChallenge.criteria}
                  </h6>
                  <h6 className="text-center mb-4">
                    Recompensa: {dataChallenge.getChallenge.reward} coins
                  </h6>
                  <h6 className="text-center mb-4">
                  Fechas Inicio - Fin: {moment(dataChallenge.getChallenge.initDate).format('DD/MM/YYYY')} - {moment(dataChallenge.getChallenge.endDate).format('DD/MM/YYYY')}
                  </h6>
                  <h6 className="text-center mb-4">
                    Competencias que se entregarán al pasar el reto:
                  </h6>
                  {dataChallenge.getChallenge.competences.map(element => {
                    console.log(element)
                    return(
                      <div className="d-flex align-items-center justify-content-center mb-2 mt-3">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon">
                          {element.urlImage ? (
                            <img alt="..." src={element.urlImage} />
                          ) : (
                            <img alt="..." src={defaultAvatar} />
                          )}
                        </div>
                      </div>
                      <div>
                        <a
                          href="#/"
                          onClick={(e) => e.preventDefault()}
                          className="font-weight-bold text-black"
                          title="..."
                        >
                          {element.name}
                        </a>
                        
                      </div>
                    </div>
                    )
                  })}
                  <h4 className="text-center font-weight-bold" style={{marginBottom:20}}>
                    Usuarios incritos al reto
                  </h4>
                  <div
                    className={clsx('search-wrapper search-wrapper--grow mb-4', {
                    'is-active': searchStatus1
                    })}>
                    <TextField
                    variant="outlined"
                    size="small"
                    id="input-with-icon-textfield22-1"
                    onFocus={toggleSearch1}
                    onBlur={toggleSearch1}
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <SearchTwoToneIcon />
                        </InputAdornment>
                        )
                    }}
                    onChange={e => {
                        const filteredTable = dataChallenge.getChallenge.users.filter(o =>
                          Object.keys(o).some(k => k==="name" &&
                              String(o[k])
                                .toLowerCase()
                                .includes(e.target.value.toLowerCase())
                          )
                        );
  
                        setFilterTable(filteredTable)
                    }}
                    />
                </div>
                  <Card className="card-box mb-spacing-6-x2">
                    <CardContent>
                      <div className="table-responsive-xl">
                        <Table className="table table-borderless table-hover text-nowrap mb-0">
                          <thead>
                            <tr>
                              <th className="text-left">Usuario</th>
                              <th className="text-left">Antes fecha fin</th>
                              {moment().isAfter(dataChallenge.getChallenge.endDate) ? <th className="text-center">Entregar premio</th> : null}
                            </tr>
                          </thead>
                          <tbody>
                              {!filterTable ? dataChallenge.getChallenge.users.map((element, index) => {
                                  return(
                                    <tr>
                                        <td>
                                            <div className="d-flex align-items-center">
                                            <div className="avatar-icon-wrapper mr-3">
                                                <div className="avatar-icon">
                                                {element.urlImage ? <img alt="..." src={element.urlImage} /> : <img alt="..." src={defaultAvatar}/>}
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column justify-content-left">
                                                <a
                                                href="#/"
                                                onClick={(e) => e.preventDefault()}
                                                className="d-flex font-weight-bold text-black"
                                                title="..."
                                                >
                                                {element.name}
                                                </a>
                                                <span className="text-black-50 d-block">
                                                {element.email}
                                                </span>
                                            </div>
                                            </div>
                                        </td>
                                        {
                                        !dataChallenge.getChallenge.endedUsers.includes(element._id) ?
                                        <td className="text-center">
                                          <h5>No</h5>
                                      </td>
                                      :
                                      <td className="text-center">
                                      <h5>Si</h5>
                                  </td>
                                      
                                      }
                                        {moment().isAfter(dataChallenge.getChallenge.endDate) ? 
                                        !dataChallenge.getChallenge.awardedUsers.includes(element._id) ?
                                        <td className="text-center">
                                          <Button
                                          size="small"
                                          className="btn-success hover-scale-sm d-40 p-0 btn-icon"
                                          onClick={()=>onRewardUser(element)}
                                          >
                                          <FontAwesomeIcon icon={["fas", "arrow-right"]} />
                                          </Button>
                                      </td>
                                      :
                                      <td className="text-center">
                                      <h5>entregado</h5>
                                  </td>
                                      :
                                      null
                                      }
                                    </tr>
                                  )
                              }) : 
                              filterTable.map((element, index) => {
                                return(
                                  <tr>
                                      <td>
                                          <div className="d-flex align-items-center">
                                          <div className="avatar-icon-wrapper mr-3">
                                              <div className="avatar-icon">
                                              {element.urlImage ? <img alt="..." src={element.urlImage} /> : <img alt="..." src={defaultAvatar}/>}
                                              </div>
                                          </div>
                                          <div className="d-flex flex-column justify-content-left">
                                                <a
                                                href="#/"
                                                onClick={(e) => e.preventDefault()}
                                                className="d-flex font-weight-bold text-black"
                                                title="..."
                                                >
                                                {element.name}
                                                </a>
                                                <span className="text-black-50 d-block">
                                                {element.email}
                                                </span>
                                            </div>
                                          </div>
                                      </td>
                                      {
                                        !dataChallenge.getChallenge.endedUsers.includes(element._id) ?
                                        <td className="text-center">
                                          <h5>No</h5>
                                      </td>
                                      :
                                      <td className="text-center">
                                      <h5>Si</h5>
                                  </td>
                                    
                                      }
                                      {moment().isAfter(dataChallenge.getChallenge.endDate) ? 
                                        !dataChallenge.getChallenge.awardedUsers.includes(element._id) ?
                                        <td className="text-center">
                                          <Button
                                          size="small"
                                          className="btn-success hover-scale-sm d-40 p-0 btn-icon"
                                          onClick={()=>onRewardUser(element)}
                                          >
                                          <FontAwesomeIcon icon={["fas", "arrow-right"]} />
                                          </Button>
                                      </td>
                                      :
                                      <td className="text-center">
                                      <h5>entregado</h5>
                                  </td>
                                      :
                                      null
                                      }
                                  </tr>
                                )
                            })
                              }
                            
                          </tbody>
                        </Table>
                      </div>
                    </CardContent>
                  </Card>
        
                  <div className="pt-4" style={{ marginBottom: 20 }}>
                    <Button
                      onClick={()=> {
                        toggleViewChallenge()
                        setChallengeSelected(null)
                      }}
                      className="btn-success btn-pill mx-1"
                    >
                      <span className="btn-wrapper--label">Atrás</span>
                    </Button>
                  </div>
                </div>
              </Dialog>
            </>
          );
      }else{
          return null
      }
  
}
