import React, { useEffect, useState, useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import moment from 'moment';

import {
  RingLoader,
} from 'react-spinners';

import { UserContext } from "../../../../../context/UserContext";
import { GET_METRICS_RECOGNITIONS, GET_DEPARTMENTS, GET_GROUPS, GET_RECOGNITIONS_RECEIVED_BY_COMPANYID_COUNT } from "data/queries";

import { PageTitle } from 'layout-components';


import DashboardRecognitions1 from './DashboardRecognitions1';
export default function Dashboards({
  filtersInput,
  filtersPreviousInput
}) {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  let companyId = dbuser.getUserByUid.companyId;
  let userId = dbuser.getUserByUid.id;

  const { loading: loadingRecognitionsCount, error: errorRecognitionsCount, data: dataRecognitionsCount, networkStatus: netRecognitionsCount } = useQuery(GET_RECOGNITIONS_RECEIVED_BY_COMPANYID_COUNT, {
    variables: { id: userId, input: filtersInput },
    skip: !companyId,
    notifyOnNetworkStatusChange: true,
  });

  // const { loading: loadingPrevious, error: errorPrevious, data: dataPrevious, networkStatus: netPrevious } = useQuery(GET_METRICS_RECOGNITIONS, {
  //   variables: { id: userId, input: filtersPreviousInput },
  //   skip: !companyId,
  //   notifyOnNetworkStatusChange: true,
  // });

  const { loading, error, data, networkStatus } = useQuery(GET_METRICS_RECOGNITIONS, {
    variables: { id: userId, input: filtersInput },
    skip: !companyId,
    notifyOnNetworkStatusChange: true,
  });
  // const { loading: loadingDepartments, error: errorDepartments, data: dataDepartments, networkStatus: netDepartments } = useQuery(GET_DEPARTMENTS, {
  //   variables: { id: companyId },
  //   skip: !companyId,
  //   notifyOnNetworkStatusChange: true,
  // });
  // const { loading: loadingGroups, error: errorloadingGroups, data: dataGroups, networkStatus: netGroups } = useQuery(GET_GROUPS, {
  //   variables: { id: companyId },
  //   skip: !companyId,
  //   notifyOnNetworkStatusChange: true,
  // });
  console.log(dataRecognitionsCount)
  if (networkStatus === 4) return "Refetching!";
  if (loading || loadingRecognitionsCount) return (<div
    className="d-flex align-items-center justify-content-center"
    style={{ width: '100%', height: '100%' }}>
    <RingLoader color={'var(--primary)'} loading={true} />
  </div>)
  if (error) return `Error! ${error}`;

  // if (data && dataDepartments && dataGroups && dataPrevious && dataRecognitionsCount) {
  if (data && dataRecognitionsCount) {

    console.log(data);
    console.log(dataRecognitionsCount)
    let totalRecognitions = data.getRecognitionsMetrics.totalRecognitions
    let totalCongratulations = data.getRecognitionsMetrics.totalCongratulations
    let totalProposal = data.getRecognitionsMetrics.totalProposals
    let totalGratitudes = data.getRecognitionsMetrics.totalGratitudes
    let totalActions = data.getRecognitionsMetrics.totalActions
    return (
      <>
        <DashboardRecognitions1
          totalRecognitions={totalRecognitions}
          totalCongratulations={totalCongratulations}
          totalProposal={totalProposal}
          totalGratitudes={totalGratitudes}
          totalActions={totalActions}
          dataRecognitionsCount={dataRecognitionsCount}
        />

      </>
    );
  }

}
