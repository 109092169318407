import React, { useEffect, useState, useContext } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import moment from 'moment';

import { UserContext } from "../../context/UserContext";

import { PageTitle } from 'layout-components';

import DashboardOkrs from './components/index';

import { GET_OKRS_BY_COMPANYID } from "data/queries";

export default function Okrs() {

    const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
    let companyId = dbuser.getUserByUid.companyId;

    const { loading, error, data, refetch, networkStatus } = useQuery(GET_OKRS_BY_COMPANYID, {
        variables: { companyId: companyId },
        skip: !companyId,
        notifyOnNetworkStatusChange: true,
    });

    if (networkStatus === 4) return "Refetching!";
    if (loading) return "Esperando";
    if (error) return `Error! ${error}`;

    if (data) {
        console.log(data.getOkrsByCompanyId);
        return (
            <>
                <PageTitle
                    titleHeading="OKR's"
                    titleDescription="Objetivos claves enfocados a los resultados."
                />

                <DashboardOkrs
                    dataOkr={data.getOkrsByCompanyId}
                    refetch={refetch}
                />

            </>
        );
    }
}
