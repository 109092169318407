import React, { useState, useContext, useEffect } from "react"
import { useQuery, useMutation } from "@apollo/react-hooks"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Table,
  InputLabel,
  Card,
  MenuItem,
  Button,
  Tooltip,
  FormControl,
  Select,
  Dialog,
  Menu,
  Grid,
} from "@material-ui/core"
import { UserContext } from "../../../../context/UserContext"
import { GET_DEPARTMENTS_GROUPS_AND_USER } from "data/queries"
import { DELETE_USER } from "data/mutations"
import Pagination from "@material-ui/lab/Pagination"
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ModalAddEmployee from "./ModalAddEmployee"
import ModalEditEmployee from "./ModalEditEmployee"
import ModalViewEmployee from "./ModalViewEmployee"
import ModalAddToDepartment from "./ModalAddToDepartment"
import ModalAddToGroup from "./ModalAddToGroup"
import ModalImportEmployees from "./ModalImportEmployees"
import FilterListTwoToneIcon from "@material-ui/icons/FilterListTwoTone"
import SnackBarMessage from "../../../../utils/SnackBarMessage"

export default function UsersTable({ compId }) {

  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext)

  const [alert, setAlert] = useState({
    messageAlert: '',
    severity: '',
    openSnackbar: false
  })

  let companyId = null
  if (compId) {
    companyId = compId
  } else {
    companyId = dbuser.getUserByUid.companyId
  }

  const { loading, error, data, networkStatus, fetchMore, refetch } = useQuery(
    GET_DEPARTMENTS_GROUPS_AND_USER,
    {
      variables: { id: companyId, offset: 0, limit: 10 },
      skip: !companyId,
      notifyOnNetworkStatusChange: true,
    }
  )

  const [deleteUser, { data: dataDelete }] = useMutation(DELETE_USER)

  const [page, setPage] = useState(1)
  const [entries, setEntries] = useState(10)

  const [modalAddEmployee, setModalAddEmployee] = useState(false)
  const toggleAddEmployee = () => setModalAddEmployee(!modalAddEmployee)

  const [modalEditEmployee, setModalEditEmployee] = useState(false)
  const toggleEditEmployee = () => setModalEditEmployee(!modalEditEmployee)

  const [modalImportEmployees, setModalImportEmployees] = useState(false)
  const toggleImportEmployees = () => setModalImportEmployees(!modalImportEmployees)

  const [modalViewEmployee, setModalViewEmployee] = useState(false)
  const toggleViewEmployee = () => setModalViewEmployee(!modalViewEmployee)

  const [modalAssignTeam, setModalAssignTeam] = useState(false)
  const toggleAssignTeam = () => setModalAssignTeam(!modalAssignTeam)

  const [modalAssignDepartment, setModalAssignDepartment] = useState(false)
  const toggleAssignDepartment = () => setModalAssignDepartment(!modalAssignDepartment)

  const [modalDelete, setModalDelete] = useState(false)
  const toggleDelete = () => setModalDelete(!modalDelete)

  const [employeeSelected, setEmployeeSelected] = useState("")
  const [employeesSelected, setEmployeesSelected] = useState([])
  const [employeesList, setEmployeesList] = useState([])

  const [anchorElFilter, setAnchorElFilter] = useState(null)
  const handleClickFilter = (event) => {
    setAnchorElFilter(event.currentTarget)
  }
  const handleCloseFilter = () => {
    setAnchorElFilter(null)
  }

  const [status, setStatus] = useState(null)
  const handleStatus = (event) => {
    setStatus(event.target.value)
  }

  const [type, setType] = useState(null)
  const handleType = (event) => {
    setType(event.target.value)
    setStatus("")
  }

  const handleChange = (event, value) => {
    setPage(value)
    fetchMore({
      variables: {
        offset: entries * (value - 1),
        limit: entries,
        typeFilter: type !== null ? type : 1,
        nameFilter: status
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return Object.assign({}, prev, {
          getUsersWithGroups: {
            users: [...fetchMoreResult.getUsersWithGroups.users],
            totalPages: fetchMoreResult.getUsersWithGroups.totalPages
          }
        })
      },
    })
  }

  const handleChangeLimit = (e) => {
    setEntries(e.target.value)
    setPage(1)

    fetchMore({
      variables: {
        offset: 0,
        limit: e.target.value,
        typeFilter: type !== null ? type : 1,
        nameFilter: status
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return Object.assign({}, prev, {
          getUsersWithGroups: {
            users: [...fetchMoreResult.getUsersWithGroups.users],
            totalPages: fetchMoreResult.getUsersWithGroups.totalPages
          }
        })
      },
    })
  }

  useEffect(() => {
    if (data != null) {
      setEmployeesList([...data.getUsersWithGroups.users])
    }
  }, [data])

  if (networkStatus === 4) return "Cargando..."
  if (loading) return "Esperando..."
  if (error) return `Error! ${error}`

  if (data) {
    let groups = data.getGroupsByCompanyId
    let deparments = data.getDepartmentsByCompanyId

    const handleFilterEmployees = () => {
      setPage(1)
      setEntries(5)

      fetchMore({
        variables: {
          offset: 0,
          typeFilter: type,
          nameFilter: status
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev
          return Object.assign({}, prev, {
            getUsersWithGroups: {
              users: [...fetchMoreResult.getUsersWithGroups.users],
              totalPages: fetchMoreResult.getUsersWithGroups.totalPages

            }
          })
        },
      })
      handleCloseFilter()
    }

    const handleDeleteFilters = () => {
      setType(0)
      setStatus("Todos")

      setPage(1)
      setEntries(5)

      fetchMore({
        variables: {
          offset: 0,
          typeFilter: 0,
          nameFilter: status
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev
          return Object.assign({}, prev, {
            getUsersWithGroups: {
              users: [...fetchMoreResult.getUsersWithGroups.users],
              totalPages: fetchMoreResult.getUsersWithGroups.totalPages

            }
          })
        },
      })

      handleCloseFilter()
    }

    return (
      <>
        <SnackBarMessage
          open={alert.openSnackbar}
          handleClose={() => setAlert({ ...alert, openSnackbar: false })}
          message={alert.messageAlert}
          severity={alert.severity}
        />
        <Card className="card-box mb-spacing-6-x2">
          <div className="card-header py-3">
            <div className="card-header--title font-size-lg">
              Lista de empleados
            </div>
            <div className="card-header--actions d-flex">
              <div>
                <Button
                  onClick={handleClickFilter}
                  variant="text"
                  className="btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill"
                >
                  <FilterListTwoToneIcon className="w-50" />
                </Button>
                <Menu
                  anchorEl={anchorElFilter}
                  keepMounted
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElFilter)}
                  classes={{ list: "p-0" }}
                  onClose={handleCloseFilter}
                >
                  <div className="dropdown-menu-xxl overflow-hidden p-0">
                    <div className="p-3">
                      <Grid container spacing={6}>
                        <Grid item md={6}>
                          <FormControl
                            variant="outlined"
                            size="small"
                            className="w-100"
                          >
                            <InputLabel id="type-select-label">Tipo</InputLabel>
                            <Select
                              labelId="type-select-label"
                              id="type-select-label-id"
                              fullWidth
                              label="Type"
                              value={type}
                              onChange={handleType}
                            >
                              <MenuItem value={0}>Todos los tipos</MenuItem>
                              <MenuItem value={1}>Departamentos</MenuItem>
                              <MenuItem value={2}>Grupos</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item md={6}>
                          <FormControl
                            variant="outlined"
                            size="small"
                            className="w-100"
                          >
                            <InputLabel id="status-select-label">
                              Nombre
                            </InputLabel>
                            <Select
                              labelId="status-select-label"
                              id="status-select-label-id"
                              fullWidth
                              label="Status"
                              value={status}
                              onChange={handleStatus}
                            >
                              {type === 0 ? (
                                <MenuItem value={'Todos'}>Todos</MenuItem>
                              ) : type === 1 ? (
                                deparments.map((item, index) => {
                                  return (
                                    <MenuItem value={item.name} id={index}>
                                      {item.name}
                                    </MenuItem>
                                  )
                                })
                              ) : (
                                groups.map((item, index) => {
                                  return (
                                    <MenuItem value={item.name} id={index}>
                                      {item.name}
                                    </MenuItem>
                                  )
                                })
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="divider" />
                    <div className="p-3 text-center bg-secondary">
                      <Button className="btn-primary m-2" size="small" onClick={handleDeleteFilters} disabled={!status}>
                        Eliminar filtros
                      </Button>
                      <Button className="btn-primary m-2" size="small" onClick={handleFilterEmployees} disabled={!status}>
                        Resultados filtrados
                      </Button>
                    </div>
                  </div>
                </Menu>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between px-4 py-3">
            <div className="d-flex align-items-center px-2">
              <Button
                size="small"
                className="btn-neutral-primary"
                onClick={() => setModalAddEmployee(true)}
              >
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={["fas", "plus-circle"]} />
                </span>
                <span className="btn-wrapper--label">Agregar empleado</span>
              </Button>
              {dbuser.getUserByUid.role === "superadmin" && <Button
                size="small"
                className="btn-neutral-primary ml-1"
                onClick={() => setModalImportEmployees(true)}
              >
                <span className="btn-wrapper--label">Importar empleados</span>
              </Button>}
            </div>
            {employeesSelected.length > 0 && (
              <>
                <div className="d-flex align-items-center px-2">
                  <Button
                    size="small"
                    className="btn-neutral-primary"
                    onClick={() => setModalAssignDepartment(true)}
                  >
                    <span className="btn-wrapper--icon">
                      <FontAwesomeIcon icon={["fas", "plus-circle"]} />
                    </span>
                    <span className="btn-wrapper--label">
                      Agregar a departamento
                    </span>
                  </Button>
                </div>
                <div className="d-flex align-items-center px-2">
                  <Button
                    size="small"
                    className="btn-neutral-primary"
                    onClick={() => setModalAssignTeam(true)}
                  >
                    <span className="btn-wrapper--icon">
                      <FontAwesomeIcon icon={["fas", "plus-circle"]} />
                    </span>
                    <span className="btn-wrapper--label">Agregar a grupo</span>
                  </Button>
                </div>
              </>
            )}
          </div>
          <div className="divider" />
          <div className="table-responsive-md">
            <Table className="table table-hover text-nowrap mb-0">
              <thead>
                <tr>
                  <th className="bg-white">Empleado</th>
                  <th className="bg-white text-left">Mail</th>
                  <th className="bg-white text-center">Departamentos</th>
                  <th className="bg-white text-center">Grupos</th>
                  <th className="bg-white text-center">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {employeesList.length > 0 && employeesList.map((employee, index) => {
                  return (
                    <tr key={index}>
                      {/* Nombre y Apellido */}
                      <td>
                        <div className="d-flex">
                          <div className="d-flex align-items-center">
                            {employee.urlImage === null ||
                              employee.urlImage === "" ? (
                              <div className="avatar-icon-wrapper avatar-initials avatar-icon-sm pr-2">
                                <div className="avatar-icon text-white bg-primary">
                                  {employee.name.charAt(0).toUpperCase()}
                                </div>
                              </div>
                            ) : (
                              <div className="avatar-icon-wrapper rounded-circle avatar-icon-sm pr-2">
                                <div className="avatar-icon ">
                                  <img alt="..." className="img-fluid" src={employee.urlImage} />
                                </div>
                              </div>
                            )}

                            <div>{employee.name}&nbsp;{employee.surname}&nbsp;</div>
                          </div>
                        </div>
                      </td>
                      {/* Email */}
                      <td>{employee.username ? <CheckCircleIcon color="secondary"/> : null} {employee.email}</td>

                      {/* Departamentos */}
                      <td className="text-center">
                        <div className="avatar-wrapper-overlap d-flex justify-content-center">
                          {employee.departments.map((deparment, index) => {

                            return (
                              <div
                                className="avatar-icon-wrapper avatar-initials avatar-icon-sm pr-2"
                                key={index}
                              >
                                <div className={`avatar-icon text-white`} style={{ backgroundColor: deparment.color }}>
                                  {deparment.name.charAt(0).toUpperCase()}
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </td>
                      {/* Grupos */}
                      <td className="text-center">
                        <div className="avatar-wrapper-overlap d-flex justify-content-center">
                          {employee.groups.map((group, index) => {
                            return (
                              <div
                                className="avatar-icon-wrapper avatar-initials avatar-icon-sm pr-2"
                                key={index}
                              >
                                <div className={`avatar-icon text-white`} style={{ backgroundColor: group.color }}>
                                  {group.name.charAt(0).toUpperCase()}
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </td>
                      <td className="text-center">
                        <Tooltip title="Ver empleado" arrow placement="top">
                          <Button
                            size="small"
                            className="btn-link d-30 p-0 btn-icon hover-scale-sm"
                            onClick={() => {
                              setEmployeeSelected(employee)
                              setModalViewEmployee(true)
                            }}
                          >
                            <FontAwesomeIcon
                              icon={["far", "eye"]}
                              className="font-size-lg"
                            />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Editar empleado" arrow placement="top">
                          <Button
                            size="small"
                            className="btn-link d-30 p-0 btn-icon hover-scale-sm"
                            onClick={() => {
                              setEmployeeSelected(employee)
                              setModalEditEmployee(true)
                            }}
                          >
                            <FontAwesomeIcon
                              icon={["far", "edit"]}
                              className="font-size-lg"
                            />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Eliminar empleado" arrow placement="top">
                          <Button
                            size="small"
                            className="btn-link d-30 p-0 btn-icon hover-scale-sm"
                            onClick={() => {
                              setEmployeeSelected(employee)
                              toggleDelete()
                            }}
                          >
                            <FontAwesomeIcon
                              icon={["fas", "trash"]}
                              className="font-size-lg"
                            />
                          </Button>
                        </Tooltip>
                        <Dialog
                          open={modalDelete}
                          onClose={toggleDelete}
                          classes={{ paper: "shadow-lg rounded" }}
                        >
                          <div className="text-center p-5">
                            <div className="avatar-icon-wrapper rounded-circle m-0">
                              <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
                                <FontAwesomeIcon
                                  icon={["fas", "times"]}
                                  className="d-flex align-self-center display-3"
                                />
                              </div>
                            </div>
                            <h4 className="font-weight-bold mt-4">
                              ¿Estás seguro de que desas borrar a este
                              empleado?
                            </h4>
                            <p className="mb-0 font-size-lg text-muted">
                              No puedes deshacer esta operación.
                            </p>
                            <div className="pt-4">
                              <Button
                                onClick={toggleDelete}
                                className="btn-neutral-secondary btn-pill mx-1"
                              >
                                <span className="btn-wrapper--label">
                                  Cancelar
                                </span>
                              </Button>
                              <Button
                                onClick={() => {
                                  toggleDelete()
                                  deleteUser({
                                    variables: { id: employeeSelected.id },
                                    refetchQueries: [
                                      {
                                        query: GET_DEPARTMENTS_GROUPS_AND_USER,
                                        variables: { id: companyId, offset: entries, limit: entries },
                                      },
                                    ],
                                  })
                                  refetch({ variables: { id: companyId, offset: entries, limit: entries } })
                                  handleChange(null, 1)
                                  setAlert({
                                    ...alert, 
                                    messageAlert: 'Borrado exitosamente', 
                                    severity: 'success', 
                                    openSnackbar: (true, () => {
                                      setTimeout(function () {
                                        setAlert({ ...alert, openSnackbar: false })
                                      }, 5000)
                                    })
                                  })
                                }}
                                className="btn-danger btn-pill mx-1"
                              >
                                <span className="btn-wrapper--label">
                                  Borrar
                                </span>
                              </Button>
                            </div>
                          </div>
                        </Dialog>
                      </td>
                    </tr>
                  )
                })
                }
              </tbody>
            </Table>
            {employeesList.length === 0 &&
              <div className="text-center my-5">
                <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-primary shadow-primary-sm text-primary mb-2 d-90">
                  <FontAwesomeIcon
                    icon={["far", "gem"]}
                    className="d-flex align-self-center font-size-xxl"
                  />
                </div>
                <h6 className="font-weight-bold font-size-xxl mb-1 mt-3 text-primary">
                  No tienes empleados registrados
                </h6>
                <p className="text-black-50 font-size-lg mb-0">
                  Registra empleados en tu compañía para continuar
                </p>
              </div>
            }
          </div>
          <div className="card-footer py-3 d-flex justify-content-between">
            <Pagination
              className="pagination-primary"
              variant="outlined"
              count={data.getUsersWithGroups.totalPages}
              page={page}
              onChange={handleChange}
            />
            <div className="d-flex align-items-center">
              <span>Mostrar</span>
              <FormControl size="small" variant="outlined" className="mx-3">
                <InputLabel id="select-entries-label">entradas</InputLabel>
                <Select
                  labelId="select-entries-label"
                  id="select-entries"
                  value={entries}
                  onChange={handleChangeLimit}
                  label="Entradas">
                  <MenuItem className="mx-2" value={10}>
                    10
                  </MenuItem>
                  <MenuItem className="mx-2" value={15}>
                    15
                  </MenuItem>
                  <MenuItem className="mx-2" value={20}>
                    20
                  </MenuItem>
                  <MenuItem className="mx-2" value={25}>
                    25
                  </MenuItem>
                  <MenuItem className="mx-2" value={30}>
                    30
                  </MenuItem>
                </Select>
              </FormControl>
              <span>entradas</span>
            </div>
          </div>
        </Card>
        <ModalAddEmployee
          openAddEmployee={modalAddEmployee}
          toggleAddEmployee={toggleAddEmployee}
          compId={compId}
          refetch={refetch}
        />
        <ModalViewEmployee
          openViewEmployee={modalViewEmployee}
          toggleViewEmployee={toggleViewEmployee}
          dataEmployee={employeeSelected}
          compId={compId}
        />
        <ModalEditEmployee
          openEditEmployee={modalEditEmployee}
          toggleEditEmployee={toggleEditEmployee}
          dataEmployee={employeeSelected}
          compId={compId}
          refetch={refetch}
          setPage={setPage}
        />
        <ModalAddToDepartment
          openModalAddTeam={modalAssignDepartment}
          toggleModalAddTeam={toggleAssignDepartment}
          dataEmployees={employeesSelected}
          compId={compId}
        />
        <ModalAddToGroup
          openModalAddGroup={modalAssignTeam}
          toggleModalAddGroup={toggleAssignTeam}
          dataEmployees={employeesSelected}
          compId={compId}
        />
        <ModalImportEmployees
          openImportEmployees={modalImportEmployees}
          toggleImportEmployees={toggleImportEmployees}
          compId={compId}
        />
      </>
    )
  }
}
