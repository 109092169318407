import React, { Fragment, useContext, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Table, Tooltip, Link, Grid } from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import { GET_ANSWERS_BY_COMPANYID, GET_ANSWERS_EMOTION_BY_COMPANYID } from "data/queries";
import { UserContext } from "../../../../../context/UserContext";
import emotion from "../../../../../assets/images/climind/reaction.png";
import recognition from "../../../../../assets/images/climind/thumbs-up.png";
import pregunta from "../../../../../assets/images/climind/faq.svg";
import felicitacion from "../../../../../assets/images/climind/regalo.png";
import agradecimiento from "../../../../../assets/images/climind/pray.png";
import avatar from "../../../../../assets/images/climind/default_avatar.png";
import { HTML5_FMT } from "moment-timezone";
import ModalViewEmployee from "./ModalViewEmployee";
import { Button } from "antd";
import { PageTitle } from 'layout-components';
import {
  RingLoader,
} from 'react-spinners';

import { CSVLink, CSVDownload } from "react-csv";
var moment = require("moment-timezone");
const csvData = [
  ["firstname", "lastname", "email"],
  ["Ahmed", "Tomi", "ah@smthing.co.com"],
  ["Raed", "Labes", "rl@smthing.co.com"],
  ["Yezzi", "Min l3b", "ymin@cocococo.com"]
];
export default function Dashboards({filtersInput}) {

  const [employeeSelected, setEmployeeSelected] = useState("");
  const [dataClimaCsv, setDataClimaCsv] = useState([])
  const [modalViewEmployee, setModalViewEmployee] = useState(false);
  const [hoverNameEmotion, setHoverNameEmotion] = useState(false);
  const [hoverNameRecognition, setHoverNameRecognition] = useState(false);
  const [hoverNameGratitude, setHoverNameGratitude] = useState(false);
  const [hoverNameCongratulation, setHoverNameCongratulation] = useState(false);
  const [hoverNameQuestion, setHoverNameQuestion] = useState(false);
  const toggleViewEmployee = () => setModalViewEmployee(!modalViewEmployee);

  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  const { loading: loadingAnswers, error: errorAnswers, data: dataAnswers, networkStatus: networkStatusAnswers } = useQuery(
    GET_ANSWERS_BY_COMPANYID,
    {
      variables: { id: dbuser.getUserByUid.id , input: filtersInput },
      skip: !dbuser.getUserByUid.companyId,
      notifyOnNetworkStatusChange: true,
    }
  );
  const { loading: loadingAnswersEmotion, error: errorAnswersEmotion, data: dataAnswersEmotion, networkStatus: networkStatusAnswersEmotion } = useQuery(
    GET_ANSWERS_EMOTION_BY_COMPANYID,
    {
      variables: { id: dbuser.getUserByUid.id , input: filtersInput },
      skip: !dbuser.getUserByUid.companyId,
      notifyOnNetworkStatusChange: true,
    }
  );

  
  if (loadingAnswers || loadingAnswersEmotion) return (<div
    className="d-flex align-items-center justify-content-center"
    style={{ width: '100%', height: '100%' }}>
    <RingLoader color={'var(--primary)'} loading={true} />
  </div>)
  if (dataAnswers && dataAnswersEmotion) {
    //ARRAY CSV CLIMA
    const newArr = dataAnswers.getAnswersByCompanyId.filter(object => {
      return (object.commentary !== "")
    });
    const newArr2 = newArr.filter(object => {
      return (object.commentaryInTimeline !== false)
    });
    const finalArrayClimaCsv = []
    newArr2.forEach(element => {
      let moonLanding = new Date(element.date);
      finalArrayClimaCsv.push({
        month:moonLanding.getMonth()+1,
        commentary:element.commentary,
        // departments:element.departments, 
        question:element.questionId.name
      })
    });
    console.log(finalArrayClimaCsv)
    //ARRAY CSV PULSO
    const newArrPulso = dataAnswersEmotion.getAnswersEmotionByCompanyId.filter(object => {
      return (object.commentary !== "")
    });
    const newArr2Pulso = newArrPulso.filter(object => {
      return (object.commentaryInTimeline !== false)
    });
    console.log(newArr2Pulso)
    const finalArrayPulsoCsv = []
    newArr2Pulso.forEach(element => {
      let moonLanding = new Date(element.date);
      let problema = ""
      if(element.typeInteracciones === true){
        problema += "Interacciones trabajo-casa,"
      }
      if(element.typeDesarrollo === true){
        problema += "Desarrollo profesional,"
      }
      if(element.typeContenidoTrabajo === true){
        problema += "Características de mis tareas,"
      }
      if(element.typeParticipacion === true){
        problema += "Participación y control en tu trabajo,"
      }
      if(element.typeRelacciones === true){
        problema += "Relaciones y apoyo social,"
      }
      if(element.typeCargaRitmo === true){
        problema += "Carga y ritmo de tu trabajo,"
      }
      if(element.typeRol === true){
        problema += "Funciones y responsabilidades,"
      }
      if(element.typeEquipos === true){
        problema += "Equipamiento para trabajar,"
      }
      if(element.typeTiempo === true){
        problema += "Tiempo de trabajo,"
      }
      if(element.typeContenidoTrabajoSad === true){
        problema += "Características de las tareas,"
      }
      if(element.typeTiempoSad === true){
        problema += "Nivel de exigencia,"
      }
      if(element.typeParticipacionSad === true){
        problema += "Esfuerzo y resultados,"
      }
      if(element.typeRolSad === true){
        problema += "Sensación de Bienestar,"
      }
      if(element.typeTrabajoGrupoSad === true){
        problema += "Trabajo con personas,"
      }
      finalArrayPulsoCsv.push({
        month:moonLanding.getMonth()+1,
        commentary:element.commentary,
        factors: problema
        // positive_caracteristicas_de_mis_tareas:
      })
    });
    console.log(finalArrayClimaCsv)
    console.log(dataAnswersEmotion)
    console.log(finalArrayPulsoCsv)
    // console.log(dataAnswers)
    // console.log(dataAnswersEmotion)
    return (
      <>
      <div>
        
        <CSVLink  separator={";"} data={finalArrayClimaCsv}><Button className="btn-primary mb-3 mr-3">Descargar CSV de comentarios públicos de Clima</Button></CSVLink>
        <CSVLink  separator={";"} data={finalArrayPulsoCsv}><Button className="btn-primary mb-3">Descargar CSV de comentarios públicos de Pulso</Button></CSVLink>
      </div>
        <Grid container spacing={6}>
          <Grid item md={12} lg={12} xl={6}>
            <Card className="card-box">
              <div className="card-tr-actions">
                <Tooltip
                  classes={{
                    tooltip: "text-center p-3 tooltip-secondary",
                  }}
                  arrow
                  placement="bottom"
                  title="En este timeline podrás ver todos los comentarios que se están enviando en las preguntas de clima"
                >
                  <a
                    href="#/"
                    onClick={(e) => e.preventDefault()}
                    className="text-first font-weight-bold">
                    <div
                      className=" avatar-icon rounded-circle mr-2"
                      style={{ width: 26, height: 26 }}
                    >
                      <img
                        alt="..."
                        src={require("../../../../../assets/images/climind/info.png")}
                      />
                    </div>
                  </a>
                </Tooltip>
              </div>
              <div className="card-header">
                <div className="card-header--title font-weight-bold">Comentarios enviados en Clima</div>
              </div>
              
              <PerfectScrollbar className="scroll-area-xl mb-2">
                <div className="table-responsive-md">
                  <Table className="table table-borderless table-hover mb-0">
                    <tbody>
                      {dataAnswers.getAnswersByCompanyId &&
                        dataAnswers.getAnswersByCompanyId.length > 0 ? (
                        dataAnswers.getAnswersByCompanyId.map(
                          (element, index) => {
                            // console.log(element)
                            let hour = moment(element.date).add(-1, "hour");
                            if (element.commentary && element.commentary != "" && element.userId != null) {
                              return (
                                <Fragment key={index}>
                                  <tr>
                                    <td colSpan="5" className="">
                                      {/* <div className="mb-3"> */}
                                      <div className="d-flex justify-content-between">
                                        <div className="d-flex align-items-left mb-3">
                                          <div className="avatar-icon-wrapper avatar-icon-lg mr-3">
                                            <div className="avatar-icon rounded-circle">
                                              {element.userId.urlImage ?
                                                <img
                                                  alt="..."
                                                  src={element.userId.urlImage}

                                                /> :
                                                <img
                                                  alt="..."
                                                  src={avatar}
                                                />
                                              }
                                            </div>
                                          </div>
                                          <div>
                                            <h6
                                              className="font-weight-bold  btn-icon "
                                            >
                                              {element.userId.name}&nbsp;{element.userId.surname}
                                              {element.commentaryInTimeline ? <h6 className="mb-0 font-weight-bold" style={{ color: "green" }}>&nbsp;Público</h6> : <h6 className="mb-0 font-weight-bold" style={{ color: "orange" }}>&nbsp;Privado</h6>}
                                            </h6>
                                            <span className="text-black-50 d-block">
                                              <FontAwesomeIcon
                                                icon={["far", "calendar-alt"]}
                                                className="opacity-7 mr-1"
                                              />
                                              {moment(hour).startOf("minute").fromNow()}
                                            </span>
                                          </div>
                                        </div>
                                        <img
                                          alt="..."
                                          className="card-img-top rounded-sm mb-2 mr-2"
                                          src={pregunta}
                                          style={{ width: 50, height: 50 }}
                                        />
                                      </div>
                                      <div className="align-items-left">
                                        {/* <h6 className="font-weight-bold mb-3">
                                          {`A la pregunta de:`}
                                        </h6> */}
                                        <h6 className="font-weight-bold mb-3" style={{ color: "purple" }}>
                                          {/* {` Ha entregado el reconocimiento ${element.competenceId.name} a ${element.receiver.name}`} */}
                                          {`Pregunta: "${element.questionId.name}"`}
                                        </h6>
                                        <h6 className="font-weight-bold mb-3">
                                          {`Ha comentado:`}
                                        </h6>
                                        <h5 className=" mb-3">
                                          {/* {` Ha entregado el reconocimiento ${element.competenceId.name} a ${element.receiver.name}`} */}
                                          {`${element.commentary}`}
                                        </h5>
                                        {/* <div className="d-flex flex-row align-items-center">
                                    <Card className="card-transparent hover-scale-xl">
                                    <a
                                      href="#/"
                                      onClick={(e) => e.preventDefault()}
                                      className="text-first font-weight-bold">
                                      
                                      <FontAwesomeIcon
                                        icon={["far", "thumbs-up"]}
                                        className="font-size-xl mr-2 card-box-hover"
                                        color="blue"
                                      />
                                    </a>
                                    </Card>
                                    <h5 className="font-size-lg mr-3 mb-0">3</h5>
                                    <Card className="card-transparent hover-scale-xl">
                                    <a
                                      href="#/"
                                      onClick={(e) => e.preventDefault()}
                                      className="text-first font-weight-bold">
                                      
                                      <FontAwesomeIcon
                                        icon={["far", "thumbs-down"]}
                                        className="font-size-xl mr-2 card-box-hover"
                                        color="brown"
                                      />
                                    </a>
                                    </Card>
                                    <h5 className="font-size-lg mr-3 mb-0">5</h5>
                                    </div> */}
                                      </div>

                                      {/* </div> */}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="divider" />
                                    </td>
                                  </tr>
                                </Fragment>
                              );
                            }
                            else {
                              return null
                            }


                          }
                        )
                      ) : (
                        <Fragment>
                          <h5 className="mt-10 text-center">
                            Aún o hay elementos para mostrar
                          </h5>
                        </Fragment>
                      )}
                    </tbody>
                  </Table>
                </div>
              </PerfectScrollbar>
            </Card>
          </Grid>
          <Grid item md={12} lg={12} xl={6}>
            <Card className="card-box">
              <div className="card-tr-actions">
                <Tooltip
                  classes={{
                    tooltip: "text-center p-3 tooltip-secondary",
                  }}
                  arrow
                  placement="bottom"
                  title="En este timeline podrás ver todos los comentarios que se están enviando en las preguntas de pulso"
                >
                  <a
                    href="#/"
                    onClick={(e) => e.preventDefault()}
                    className="text-first font-weight-bold">
                    <div
                      className=" avatar-icon rounded-circle mr-2"
                      style={{ width: 26, height: 26 }}
                    >
                      <img
                        alt="..."
                        src={require("../../../../../assets/images/climind/info.png")}
                      />
                    </div>
                  </a>
                </Tooltip>
              </div>
              <div className="card-header">
                <div className="card-header--title font-weight-bold">Comentarios enviados en Pulso</div>
              </div>
              <PerfectScrollbar className="scroll-area-xl mb-2">
                <div className="table-responsive-md">
                  <Table className="table table-borderless table-hover mb-0">
                    <tbody>
                      {dataAnswersEmotion.getAnswersEmotionByCompanyId &&
                        dataAnswersEmotion.getAnswersEmotionByCompanyId.length > 0 ? (
                        dataAnswersEmotion.getAnswersEmotionByCompanyId.map(
                          (element, index) => {
                            let hour = moment(element.date).add(-1, "hour");
                            if (element.commentary && element.commentary != "" && element.userId != null) {
                              return (
                                <Fragment key={index}>
                                  <tr>
                                    <td colSpan="5" className="">
                                      {/* <div className="mb-3"> */}
                                      <div className="d-flex justify-content-between">
                                        <div className="d-flex align-items-left mb-3">
                                          <div className="avatar-icon-wrapper avatar-icon-lg mr-3">
                                            <div className="avatar-icon rounded-circle">
                                              {element.userId.urlImage ?
                                                <img
                                                  alt="..."
                                                  src={element.userId.urlImage}

                                                /> :
                                                <img
                                                  alt="..."
                                                  src={avatar}
                                                />
                                              }
                                            </div>
                                          </div>
                                          <div>
                                            <h6
                                              className="font-weight-bold  btn-icon "
                                            >
                                              {element.userId.name}&nbsp;{element.userId.surname}
                                              {element.commentaryInTimeline ? <h6 className="mb-0 font-weight-bold" style={{ color: "green" }}>&nbsp;Público</h6> : <h6 className="mb-0 font-weight-bold" style={{ color: "orange" }}>&nbsp;Privado</h6>}
                                            </h6>
                                            <span className="text-black-50 d-block">
                                              <FontAwesomeIcon
                                                icon={["far", "calendar-alt"]}
                                                className="opacity-7 mr-1"
                                              />
                                              {moment(hour).startOf("minute").fromNow()}
                                            </span>
                                          </div>
                                        </div>
                                        <img
                                          alt="..."
                                          className="card-img-top rounded-sm mb-2 mr-2"
                                          src={emotion}
                                          style={{ width: 50, height: 50 }}
                                        />
                                      </div>
                                      <div className="align-items-left">
                                        <h6 className="font-weight-bold mb-3">
                                          {`Ha comentado`}
                                        </h6>
                                        <h5 className=" mb-3">
                                          {/* {` Ha entregado el reconocimiento ${element.competenceId.name} a ${element.receiver.name}`} */}
                                          {`${element.commentary}`}
                                        </h5>
                                        {/* <div className="d-flex flex-row align-items-center">
                                    <Card className="card-transparent hover-scale-xl">
                                    <a
                                      href="#/"
                                      onClick={(e) => e.preventDefault()}
                                      className="text-first font-weight-bold">
                                      
                                      <FontAwesomeIcon
                                        icon={["far", "thumbs-up"]}
                                        className="font-size-xl mr-2 card-box-hover"
                                        color="blue"
                                      />
                                    </a>
                                    </Card>
                                    <h5 className="font-size-lg mr-3 mb-0">3</h5>
                                    <Card className="card-transparent hover-scale-xl">
                                    <a
                                      href="#/"
                                      onClick={(e) => e.preventDefault()}
                                      className="text-first font-weight-bold">
                                      
                                      <FontAwesomeIcon
                                        icon={["far", "thumbs-down"]}
                                        className="font-size-xl mr-2 card-box-hover"
                                        color="brown"
                                      />
                                    </a>
                                    </Card>
                                    <h5 className="font-size-lg mr-3 mb-0">5</h5>
                                    </div> */}
                                      </div>

                                      {/* </div> */}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="divider" />
                                    </td>
                                  </tr>
                                </Fragment>
                              );
                            }
                            else {
                              return null
                            }


                          }
                        )
                      ) : (
                        <Fragment>
                          <h5 className="mt-10 text-center">
                            Aún o hay elementos para mostrar
                          </h5>
                        </Fragment>
                      )}
                    </tbody>
                  </Table>
                </div>
              </PerfectScrollbar>
            </Card>
          </Grid>
        </Grid>
        <ModalViewEmployee
          openViewEmployee={modalViewEmployee}
          toggleViewEmployee={toggleViewEmployee}
          dataEmployee={employeeSelected}
        />
      </>
    );
  } else {
    return null
  }
}
