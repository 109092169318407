import React, { useEffect, useState, useContext } from "react";
import HeatMap from '@uiw/react-heat-map';
import {
  Grid,
  Card,
  LinearProgress,
  Tooltip
} from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import { UserContext } from "../../../context/UserContext";

import CountUp from "react-countup";
import { GET_ANSWER_EMOTIONS_SENDED_BY_USER_ID_LAST_90 } from "data/queries";

import regalo from "../../../assets/images/climind/regalo.png";
import felicitacion from "../../../assets/images/climind/thumbs-up.png";
import agradecimiento from "../../../assets/images/climind/pray.png";
import grafico from "../../../assets/images/climind/planta-de-cultivo.svg";
import pregunta from "../../../assets/images/climind/faq.svg";
import feliz from "../../../assets/images/climind/feliz.svg";
import emotion from "../../../assets/images/climind/reaction.png";
var moment = require("moment-timezone");
export default function General({data}) {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  
  if (data) {

    let cie = (data.getAnswerEmotionsSendedByUserIdLast30.length <= 20 ? data.getAnswerEmotionsSendedByUserIdLast30.length : 20)/20*100 * (30/100) +
              (data.getAnswersSendedByUserIdLast30.length <= 20 ? data.getAnswersSendedByUserIdLast30.length : 20)/20*100 * (30/100)+
              (data.getRecognitionsSendedByUserIdLast30.length <= 20 ? data.getRecognitionsSendedByUserIdLast30.length : 20)/20*100 * (15/100)+
              (data.getImprovementProposalsSendedByUserIdLast30.length <= 20 ? data.getImprovementProposalsSendedByUserIdLast30.length : 20)/20*100 * (15/100)+
              (data.getCongratulationsSendedByUserIdLast30.length <= 20 ? data.getCongratulationsSendedByUserIdLast30.length : 20)/20*100 * (5/100)+
              (data.getGratitudesSendedByUserIdLast30.length <= 20 ? data.getGratitudesSendedByUserIdLast30.length : 20)/20*100 * (5/100)
    return (
      <> <div className="font-size-lg mb-3 mt-0 text-center">
    Los datos que se muestran a continuación corresponden a un periodo correspondiente a los últimos 30 días
    </div>
        <div className="mb-spacing-6">
          <Grid container spacing={4}>
            <Grid item xl={12} className="">
              <Card className="card-box justify-content-center">
                <div className="d-flex flex-column card-content-overlay text-center py-4 justify-content-center">
                  <div>
                    <img
                      alt="..."
                      className="card-img-top rounded-sm mb-1"
                      src={require("../../../assets/images/climind/logo_climind.png")}
                      style={{ width: 200 }}
                    />
                  </div>
                  <div
                    className="font-weight-bold text-black display-3 mt-4"
                    style={{ width: "80%", margin: "0 auto" }}
                  >
                    <div className="mb-1 font-weight-bold font-size-sm">
                      <div className="font-size-lg mb-4">{cie}%</div>
                    </div>
                    <LinearProgress
                      variant="determinate"
                      className="progress-animated-alt progress-sm progress-bar-primary"
                      value={cie}
                    />
                  </div>
                  <div className="font-size-lg mt-3">
                    Este es tu CIE (Climind Index Engagement)
                  </div>
                  {/* <div className="divider mx-4 my-4" />
                    <div className="text-center">
                      <Button size="small" className="px-4 btn-neutral-primary">
                        View details
                      </Button>
                    </div> */}
                </div>
              </Card>
            </Grid>
            
            <Grid item md={6} xl={6}>
              <Card className="card-box">
                <div className="card-content-overlay text-center py-4">
                  <div className="d-70 rounded-circle  text-white btn-icon mx-auto text-center shadow-primary">
                    <Tooltip
                      classes={{
                        tooltip: "text-center p-3 tooltip-secondary",
                      }}
                      arrow
                      placement="top"
                      title="Número de veces que has enviado información sobre cómo ha sido tu jornada laboral en los últimos 30 días"
                    >
                    <img
                      alt="..."
                      className="card-img-top rounded-sm mb-2"
                      src={emotion}
                      // style={{ width: 70 }}
                    />
                    </Tooltip>
                  </div>
                  <div className="font-weight-bold text-black display-3 mt-4 mb-1">
                    <CountUp
                      start={0}
                      end={data.getAnswerEmotionsSendedByUserIdLast30.length}
                      duration={2}
                      delay={2}
                      separator=""
                    />
                  </div>
                  <div className="font-size-lg opacity-8">
                    Contestadas
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item md={6} xl={6}>
              <Card className="card-box">
                <div className="card-content-overlay text-center py-4">
                  <div className="d-70 rounded-circle  text-white btn-icon mx-auto text-center shadow-primary">
                  <Tooltip
                      classes={{
                        tooltip: "text-center p-3 tooltip-secondary",
                      }}
                      arrow
                      placement="top"
                      title="Número de veces que has enviado información sobre clima laboral en los últimos 30 días"
                    >
                    <img
                      alt="..."
                      className="card-img-top rounded-sm mb-2"
                      src={pregunta}
                      // style={{ width: 70 }}
                    />
                    </Tooltip>
                  </div>
                  <div className="font-weight-bold text-black display-3 mt-4 mb-1">
                    <CountUp
                      start={0}
                      end={data.getAnswersSendedByUserIdLast30.length}
                      duration={2}
                      delay={2}
                      separator=""
                    />
                  </div>
                  <div className="font-size-lg opacity-8">
                    Contestadas
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item md={6} xl={6}>
              <Card className="card-box">
                <div className="card-content-overlay text-center py-4">
                  <div className="d-70 rounded-circle  text-white btn-icon mx-auto text-center shadow-primary">
                  <Tooltip
                      classes={{
                        tooltip: "text-center p-3 tooltip-secondary",
                      }}
                      arrow
                      placement="top"
                      title="Número de veces que has enviado o recibido un reconocimiento en los últimos 30 días"
                    >
                    <img
                      alt="..."
                      className="card-img-top rounded-sm mb-2"
                      src={felicitacion}
                      // style={{ width: 70 }}
                    />
                    </Tooltip>
                  </div>
                  <div className="d-flex flex-row justify-content-around">
                    <div>
                      <div className="font-weight-bold text-black display-3 mt-4 mb-1">
                        <CountUp
                          start={0}
                          end={data.getRecognitionsSendedByUserIdLast30.length}
                          duration={2}
                          delay={2}
                          separator=""
                        />
                      </div>
                      <div className="font-size-lg opacity-8">Enviadas</div>
                    </div>
                    <div>
                      <div className="font-weight-bold text-black display-3 mt-4 mb-1">
                        <CountUp
                          start={0}
                          end={
                            data.getRecognitionsReceivedByUserIdLast30.length
                          }
                          duration={2}
                          delay={2}
                          separator=""
                        />
                      </div>
                      <div className="font-size-lg opacity-8">Recibidas</div>
                    </div>
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item md={6} xl={6}>
              <Card className="card-box">
                <div className="card-content-overlay text-center py-4">
                  <div className="d-70 rounded-circle  text-white btn-icon mx-auto text-center shadow-primary">
                  <Tooltip
                      classes={{
                        tooltip: "text-center p-3 tooltip-secondary",
                      }}
                      arrow
                      placement="top"
                      title="Número de veces que has enviado o recibido una propuesta de mejora en los últimos 30 días"
                    >
                    <img
                      alt="..."
                      className="card-img-top rounded-sm mb-2"
                      src={grafico}
                      // style={{ width: 70 }}
                    />
                    </Tooltip>
                  </div>
                  <div className="d-flex flex-row justify-content-around">
                    <div>
                    <div className="font-weight-bold text-black display-3 mt-4 mb-1">
                    <CountUp
                      start={0}
                      end={
                        data.getImprovementProposalsSendedByUserIdLast30.length
                      }
                      duration={2}
                      delay={2}
                      separator=""
                    />
                  </div>
                  <div className="font-size-lg opacity-8">
                  Enviadas
                  </div>
                    </div>
                    <div>
                    <div className="font-weight-bold text-black display-3 mt-4 mb-1">
                    <CountUp
                      start={0}
                      end={
                        data.getImprovementProposalsReceivedByUserIdLast30
                          .length
                      }
                      duration={2}
                      delay={2}
                      separator=""
                    />
                  </div>
                  <div className="font-size-lg">
                    Recibidas
                  </div>
                    </div>
                  </div>

                </div>
              </Card>
            </Grid>
            <Grid item md={6} xl={6}>
              <Card className="card-box">
                <div className="card-content-overlay text-center py-4">
                  <div className="d-70 rounded-circle  text-white btn-icon mx-auto text-center shadow-primary">
                  <Tooltip
                      classes={{
                        tooltip: "text-center p-3 tooltip-secondary",
                      }}
                      arrow
                      placement="top"
                      title="Número de veces que has enviado o recibido una felicitación en los últimos 30 días"
                    >
                    <img
                      alt="..."
                      className="card-img-top rounded-sm mb-2"
                      src={regalo}
                      // style={{ width: 70 }}
                    />
                    </Tooltip>
                  </div>
                  <div className="d-flex flex-row justify-content-around">
                    <div>
                    <div className="font-weight-bold text-black display-3 mt-4 mb-1">
                    <CountUp
                      start={0}
                      end={data.getCongratulationsSendedByUserIdLast30.length}
                      duration={2}
                      delay={2}
                      separator=""
                    />
                  </div>
                  <div className="font-size-lg">Enviadas</div>
                    </div>
                    <div>
                    <div className="font-weight-bold text-black display-3 mt-4 mb-1">
                    <CountUp
                      start={0}
                      end={data.getCongratulationsReceivedByUserIdLast30.length}
                      duration={2}
                      delay={2}
                      separator=""
                    />
                  </div>
                  <div className="font-size-lg">Recibidas</div>
                    </div>
                  </div>
                  
                </div>
              </Card>
            </Grid>
            <Grid item md={6} xl={6}>
              <Card className="card-box">
                <div className="card-content-overlay text-center py-4">
                  <div className="d-70 rounded-circle  text-white btn-icon mx-auto text-center shadow-primary">
                  <Tooltip
                      classes={{
                        tooltip: "text-center p-3 tooltip-secondary",
                      }}
                      arrow
                      placement="top"
                      title="Número de veces que has enviado o recibido un agradecimiento en los últimos 30 días"
                    >
                    <img
                      alt="..."
                      className="card-img-top rounded-sm mb-2"
                      src={agradecimiento}
                      // style={{ width: 70 }}
                    />
                    </Tooltip>
                  </div>
                  <div className="d-flex flex-row justify-content-around">
                    <div>
                    <div className="font-weight-bold text-black display-3 mt-4 mb-1">
                    <CountUp
                      start={0}
                      end={data.getGratitudesSendedByUserIdLast30.length}
                      duration={2}
                      delay={2}
                      separator=""
                    />
                  </div>
                  <div className="font-size-lg">Enviadas</div>
                    </div>
                    <div>
                    <div className="font-weight-bold text-black display-3 mt-4 mb-1">
                    <CountUp
                      start={0}
                      end={data.getGratitudesReceivedByUserIdLast30.length}
                      duration={2}
                      delay={2}
                      separator=""
                    />
                  </div>
                  <div className="font-size-lg">Recibidas</div>
                    </div>
                  </div>
                  
                </div>
              </Card>
            </Grid>
            
          </Grid>
        </div>
        {/* <Grid container spacing={4}>
          <Grid item xl={6} className="d-flex">
            <Card className="card-box w-100 d-flex">
              <div className="d-flex flex-column flex-grow-1 w-100">
                <div className="card-header-alt d-flex-col text-center px-4 mt-4 pb-2">
                  <h6 className="font-weight-bold font-size-xl mb-1 text-black">
                    Engagement Builder
                  </h6>
                  <h6 className=" font-size-md mb-1 text-black">
                    (Reconocimientos entregados en los últimos 30 días)
                  </h6>
                </div>
                <div className="mx-auto">
                  <CircularProgressbarWithChildren
                    value={87.5}
                    strokeWidth={6}
                    className="m-3 circular-progress-xl circular-progress-warning"
                  >
                    <span className="text-warning font-size-xxl font-weight-bold">
                      9
                    </span>
                  </CircularProgressbarWithChildren>
                </div>
                <div>
                  <Grid container spacing={0}>
                    <Grid item md={6} className="p-3">
                      <div className="divider-v divider-v-lg" />
                      <div className="text-center">
                        <div>
                          <FontAwesomeIcon
                            icon={["fas", "users"]}
                            className="font-size-xxl text-info"
                          />
                        </div>
                        <div className="mt-2 line-height-sm">
                          <b className="font-size-lg">6</b>
                          <span className=" d-block">Enviadas a usuarios</span>
                        </div>
                      </div>
                    </Grid>
                    <Grid item md={6} className="p-3">
                      <div className="divider-v divider-v-lg" />

                      <div className="text-center">
                        <div>
                          <FontAwesomeIcon
                            icon={["fas", "user-check"]}
                            className="font-size-xxl text-success"
                          />
                        </div>
                        <div className="mt-2 line-height-sm">
                          <b className="font-size-lg">2</b>
                          <span className=" d-block">
                            Enviadas a mandos intermedios
                          </span>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  
                </div>
              </div>
            </Card>
          </Grid>
          <Grid item xl={6} className="d-flex">
            <Card className="card-box w-100 d-flex">
              <div className="d-flex flex-column flex-grow-1 w-100">
                <div className="card-header-alt d-flex-col text-center px-4 mt-4 pb-2">
                  <h6 className="font-weight-bold font-size-xl mb-1 text-black">
                    Desarrollo
                  </h6>
                  <h6 className=" font-size-md mb-1 text-black">
                    (Reconocimientos recibidos en los últimos 30 días)
                  </h6>
                </div>
                <div className="mx-auto">
                  <CircularProgressbarWithChildren
                    value={37.5}
                    strokeWidth={6}
                    className="m-3 circular-progress-xl circular-progress-primary"
                  >
                    <span className="text-primary font-size-xxl font-weight-bold">
                      3
                    </span>
                  </CircularProgressbarWithChildren>
                </div>

                
                <Grid container spacing={0}>
                  <Grid item md={6} className="p-3">
                    <div className="divider-v divider-v-lg" />
                    <div className="text-center">
                      <div>
                        <FontAwesomeIcon
                          icon={["fas", "users"]}
                          className="font-size-xxl text-info"
                        />
                      </div>
                      <div className="mt-2 line-height-sm">
                        <b className="font-size-lg">6</b>
                        <span className=" d-block">Recibidas de usuarios</span>
                      </div>
                    </div>
                  </Grid>
                  <Grid item md={6} className="p-3">
                    <div className="divider-v divider-v-lg" />

                    <div className="text-center">
                      <div>
                        <FontAwesomeIcon
                          icon={["fas", "user-check"]}
                          className="font-size-xxl text-success"
                        />
                      </div>
                      <div className="mt-2 line-height-sm">
                        <b className="font-size-lg">2</b>
                        <span className=" d-block">
                          Recibidas de mandos intermedios
                        </span>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Card>
          </Grid>
        </Grid> */}
      </>
    );
  } else {
    return null;
  }
}
