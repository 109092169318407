import React, { Component, useState, useCallback } from "react";
import firebase from 'firebase'
import Avatar from 'react-avatar-edit'

const UploadImage = ({setCrop}) => {

    const onClose = useCallback( () => {
      //setPreview(null)
    })
    const onCrop = useCallback( (image) => {
      setCrop(image)
    })

    return(
        <div>
          <div>
            <Avatar
              width={390}
              height={295}
              imageWidth={390}
              imageHeight={295}
              onCrop={onCrop}
              onClose={onClose}
              label={"Elige una imagen"}
            />
          </div>
        </div>
      )
}

export default UploadImage;