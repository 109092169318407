import React, { useState, useCallback, useContext } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Button, Card, TextField, Menu, Switch, Snackbar, Tooltip, Grid } from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { FlagIcon } from "react-flag-kit";
import { POSSIBLE_ANSWER_EMOTION_TODAY, GET_USER_BY_UID,GETS_FOR_GENERAL } from "data/queries";
import { SAVE_ANSWER_EMOTION } from "data/mutations";
import InputEmoji from "react-input-emoji";
import contenidoDeTrabajo from "../../../assets/images/climind/contenidoTrabajo.svg";
import interaccionCasaTrabajo from "../../../assets/images/climind/interaccionCasaTrabajo.svg";
import cargaYRitmo from "../../../assets/images/climind/cargaYRitmo.svg";
import relacionesInterpersonales from "../../../assets/images/climind/relacionesInterpersonales.svg";
import contenidoTrabajo from "../../../assets/images/climind/contenidoTrabajo.svg";
import desarrolloProfesional from "../../../assets/images/climind/desarrolloprofesional.svg";
import desempeñoDeRol from "../../../assets/images/climind/desempeñoDeRol.svg";
import equiposDeTrabajo from "../../../assets/images/climind/equiposdetrabajo.svg";
import tiempoDeTrabajo from "../../../assets/images/climind/tiempoDeTrabajo.svg";
import participacionYControl from "../../../assets/images/climind/participacionYControl.svg";

import triste from "../../../assets/images/climind/triste.svg";
import { UserContext } from "../../../context/UserContext";
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import MuiAlert from '@material-ui/lab/Alert';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function valuetext(value) {
  return <span>{{ value }}°C</span>;
}

export default function ModalEmotionsSad({ modal1, toggle1, refetch, refetchEmotion }) {
  const [emotion, setEmotion] = useState(null)
  const [value, setValue] = useState(null)
  const [commentary, setCommentary] = useState("")
  const [modalQuestionBad, setModalQuestionBad] = useState(null)
  const [buttonClicked, setButtonClicked] = useState(false)

  const [iconContenidoTrabajo, setIconContenidoTrabajo] = useState(false)
  const [iconCargaRitmo, setIconCargaRitmo] = useState(false)
  const [iconTiempo, setIconTiempo] = useState(false)
  const [iconParticipacion, setIconParticipacion] = useState(false)
  const [iconRol, setIconRol] = useState(false)
  const [iconDesarrollo, setIconDesarrollo] = useState(false)
  const [iconRelacciones, setIconRelacciones] = useState(false)
  const [iconEquipos, setIconEquipos] = useState(false)
  const [iconInteracciones, setIconInteracciones] = useState(false);
  
  const [anchorElMenu3, setAnchorElMenu3] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [openSnackbarDanger, setOpenSnackbarDanger] = useState(false);
  const { currentUser, dbuser, getUserWithFirebase } = useContext(UserContext);
  const [checkAdmin, setCheckAdmin] = useState(dbuser.getUserByUid.commentaryEmotionForAdmins);
  const [checkTimeline, setCheckTimeline] = useState(dbuser.getUserByUid.commentaryEmotionInTimeline);
  const [saveAnswerEmotion, { data:dataSaveE, error: errorSaveE, loading: loadingSaveE }] = useMutation(SAVE_ANSWER_EMOTION);

  const handleChangeCheckTimeline = useCallback((e) => {
    setCheckTimeline(!checkTimeline);
  });
  const handleChangeCheckAdmin = useCallback((e) => {
    setCheckAdmin(!checkAdmin);
  });
  const onClickInteracciones = useCallback((e) => {
    setIconInteracciones(!iconInteracciones)
  });
  const onClickDesarrollo = useCallback((e) => {
    setIconDesarrollo(!iconDesarrollo)
  });
  const onClickContenidoTrabajo = useCallback((e) => {
    setIconContenidoTrabajo(!iconContenidoTrabajo)
  });
  const onClickParticipacion = useCallback((e) => {
    setIconParticipacion(!iconParticipacion)
  });
  const onClickRelacciones = useCallback((e) => {
    setIconRelacciones(!iconRelacciones)
  });
  const onClickCargaRitmo = useCallback((e) => {
    setIconCargaRitmo(!iconCargaRitmo)
  });
  const onClickRol = useCallback((e) => {
    setIconRol(!iconRol)
  });
  const onClickEquipos = useCallback((e) => {
    setIconEquipos(!iconEquipos)
  });
  const onClickTiempo = useCallback((e) => {
    setIconTiempo(!iconTiempo)
  });
  const handleClickMenu3 = (event) => {
    setAnchorElMenu3(event.currentTarget);
  };
  const handleCloseMenu3 = () => {
    setAnchorElMenu3(null);
  };
  async function onChangeValue (event, value)  {
    setValue(value)
  }

  async function onClickButton ()  {
    if (buttonClicked) return
    setButtonClicked(true) 

    console.log("click en emotion"+emotion)
    let dataInput = {
      "companyId": dbuser.getUserByUid.companyId,
      "userId": dbuser.getUserByUid.id,
      "answer": 'SAD',
      "value": -value,
      "commentary": commentary,
      "commentaryInTimeline": checkTimeline,
	    "commentaryForAdmins": checkAdmin,
      "typeInteracciones": iconInteracciones,
      "typeDesarrollo": iconDesarrollo,
      "typeContenidoTrabajo": iconContenidoTrabajo,
      "typeParticipacion": iconParticipacion,
      "typeRelacciones": iconRelacciones,
      "typeCargaRitmo": iconCargaRitmo,
      "typeRol": iconRol,
      "typeEquipos": iconEquipos,
      "typeTiempo": iconTiempo,
    };
    console.log(dataInput)
    let uid = dbuser.getUserByUid.uid
    let id = dbuser.getUserByUid.id
    let response = await saveAnswerEmotion({
      variables:{input:dataInput}, 
      refetchQueries: [
      {query: GET_USER_BY_UID, variables: {uid,uid}},
      { query: GETS_FOR_GENERAL, variables: { id, id } },
      { query: POSSIBLE_ANSWER_EMOTION_TODAY, variables: { id, id } },
    ]});
    getUserWithFirebase()
    console.log(response);
    setIconInteracciones(false)
    setIconDesarrollo(false)
    setIconContenidoTrabajo(false)
    setIconParticipacion(false)
    setIconRelacciones(false)
    setIconCargaRitmo(false)
    setIconRol(false)
    setIconEquipos(false)
    setIconTiempo(false)
    setValue(null)
    setCommentary("");
    setCheckAdmin(dbuser.getUserByUid.commentaryEmotionForAdmins)
    setCheckTimeline(dbuser.getUserByUid.commentaryEmotionInTimeline)
    if(response.data.saveAnswerEmotion === null){
      setOpenSnackbarDanger(true, () => {
        setTimeout(function () {
          setOpenSnackbarDanger(false);
        }, 5000); //5 Second delay
      });
    }else{
      setOpenSnackbar(true, () => {
        setTimeout(function () {
          setOpenSnackbar(false);
        }, 5000); //5 Second delay
      });
    }
    refetch()
    refetchEmotion()
    toggle1()
    setButtonClicked(false)
    if (response.data.saveAnswerEmotion) {
      console.log('guardado correctamente')
    }
  }
  const handleCloseModal = () => {
    setIconInteracciones(false)
    setIconDesarrollo(false)
    setIconContenidoTrabajo(false)
    setIconParticipacion(false)
    setIconRelacciones(false)
    setIconCargaRitmo(false)
    setIconRol(false)
    setIconEquipos(false)
    setIconTiempo(false)
    setValue(null)
    setCommentary("");
    setCheckAdmin(dbuser.getUserByUid.commentaryEmotionForAdmins)
    setCheckTimeline(dbuser.getUserByUid.commentaryEmotionInTimeline)
    toggle1();
  };
  return (
    <>
    <Snackbar
          anchorOrigin={{ vertical:"top", horizontal:"center" }}
          key={"top,center"}
          open={openSnackbar}
          // classes={{ root: "toastr-primary" }}
          autoHideDuration={4000}
          onClose={() => setOpenSnackbar(false)}
          message={"entregado correctamente"}
        >
          <Alert onClose={() => setOpenSnackbar(false)} severity="success" style={{color:"white"}}>
          Entregado correctamente
        </Alert>
        </Snackbar>
        <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        key={"top,right"}
        open={openSnackbarDanger}
        autoHideDuration={4000}
        onClose={() => setOpenSnackbarDanger(false)}
        message={"entregado correctamente"}
      >
        <Alert
          onClose={() => setOpenSnackbarDanger(false)}
          severity="warning"
          style={{ color: "white" }}
        >
          No se ha permitido contestar la pregunta
        </Alert>
      </Snackbar>
      <Dialog
        open={modal1}
        onClose={handleCloseModal}
        maxWidth="lg"
        classes={{ paper: "modal-content rounded-lg" }}
      >
       <div className="text-center mx-5 my-4">
        <h4 className="text-center">Tu jornada laboral ha sido</h4>
          <div className="avatar-icon-wrapper rounded-circle mb-3 mt-2">
            {/* <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-success text-success m-0 d-130"> */}
            <img
              alt="..."
              className="card-img-top rounded-sm"
              src={triste}
              style={{ width: 70 }}
            />
            {/* </div> */}
          </div>

          {/* {!value && (
            <>
              <h4 className="text-center gx-mb-4">¿Con que intensidad?</h4>

              <Slider
                className="slider-primary my-3"
                track="inverted"
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                defaultValue={5}
                step={1}
                marks
                min={1}
                max={10}
                marks={marksRed}
                onChangeCommitted={onChangeValue}
              />
            </>
          )}
          {value && (
            <>
              <h4 className="text-center gx-mb-4">Has marcado un {value}</h4>
            </>
          )} */}

          <h4 className="text-center ">¿Con que intensidad?</h4>
            <div className="d-flex flex-row align-items-center justify-content-center" style={{marginTop:40}}>

            <h4 className="text-center mr-2 mb-0">Baja</h4>
              
        <Button 
          className={value === 1 ? "btn-danger btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2" : "btn-secondary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"}
          style={{borderRadius:"50%" , border: value === 1 ? "" : "solid 3px red"}}
          onClick={() => setValue(1)}>
          <h5 className="text-center font-weight-bold mb-0">1</h5>
        </Button>
        <Button 
          className={value === 2 ? "btn-danger btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2" : "btn-secondary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"}
          style={{borderRadius:"50%" , border: value === 2 ? "" : "solid 3px red"}}
          onClick={() => setValue(2)}>
          <h5 className="text-center font-weight-bold mb-0">2</h5>
        </Button>
        <Button 
          className={value === 3 ? "btn-danger btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2" : "btn-secondary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"}
          style={{borderRadius:"50%" , border: value === 3 ? "" : "solid 3px red"}}
          onClick={() => setValue(3)}>
          <h5 className="text-center font-weight-bold mb-0">3</h5>
        </Button>
        <Button 
          className={value === 4 ? "btn-danger btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2" : "btn-secondary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"}
          style={{borderRadius:"50%" , border: value === 4 ? "" : "solid 3px red"}}
          onClick={() => setValue(4)}>
          <h5 className="text-center font-weight-bold mb-0">4</h5>
        </Button>
        <Button 
          className={value === 5 ? "btn-danger btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2" : "btn-secondary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"}
          style={{borderRadius:"50%" , border: value === 5 ? "" : "solid 3px red"}}
          onClick={() => setValue(5)}>
          <h5 className="text-center font-weight-bold mb-0">5</h5>
        </Button>
        <Button 
          className={value === 6 ? "btn-danger btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2" : "btn-secondary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"}
          style={{borderRadius:"50%" , border: value === 6 ? "" : "solid 3px red"}}
          onClick={() => setValue(6)}>
          <h5 className="text-center font-weight-bold mb-0">6</h5>
        </Button>
        <Button 
          className={value === 7 ? "btn-danger btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2" : "btn-secondary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"}
          style={{borderRadius:"50%" , border: value === 7 ? "" : "solid 3px red"}}
          onClick={() => setValue(7)}>
          <h5 className="text-center font-weight-bold mb-0">7</h5>
        </Button>
        <Button 
          className={value === 8 ? "btn-danger btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2" : "btn-secondary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"}
          style={{borderRadius:"50%" , border: value === 8 ? "" : "solid 3px red"}}
          onClick={() => setValue(8)}>
          <h5 className="text-center font-weight-bold mb-0">8</h5>
        </Button>
        <Button 
          className={value === 9 ? "btn-danger btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2" : "btn-secondary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"}
          style={{borderRadius:"50%" , border: value === 9 ? "" : "solid 3px red"}}
          onClick={() => setValue(9)}>
          <h5 className="text-center font-weight-bold mb-0">9</h5>
        </Button>
        <Button 
          className={value === 10 ? "btn-danger btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2" : "btn-secondary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"}
          style={{borderRadius:"50%" , border: value === 10 ? "" : "solid 3px red"}}
          onClick={() => setValue(10)}>
          <h5 className="text-center font-weight-bold mb-0">10</h5>
        </Button>
        <h4 className="text-center ml-4 mb-0">Alta</h4>
        </div>

        </div>
        <div className="text-center">
        {(value >= 4 && value) && <>
          <h4 className="font-weight-bold" style={{ color: "#5a318e", marginTop:20 }}>
                ¿A que se debe?
              </h4>
              <h5 >Debes seleccionar al menos una opción</h5>
          <div className="mb-spacing-6" style={{marginTop:40}}>
                  <Grid container spacing={4} className=" d-flex justify-content-center">
                  <Grid container spacing={4} md={12} lg={12} xl={12} className=" d-flex justify-content-center">
                    <Grid item xl={2} md={2} sm={2}>
                      <Card
                        className="shadow-xxl"
                        width={140}
                        height={140}
                        style={
                          iconContenidoTrabajo
                            ? {
                                borderRadius: "30px",
                                border: `solid 7px green`,
                                height: 150,
                                width: 150
                              }
                            : {}
                        }
                      >
                        <a
                          href="#/"
                          onClick={(e) => e.preventDefault()}
                          className="card-img-wrapper rounded"
                        >
                          <div className="img-wrapper-overlay p-10">
                            <div className="overlay-btn-wrapper ">
                            <Tooltip
                              classes={{ tooltip: 'text-center p-3 tooltip-secondary' }}
                              arrow
                              placement="top"
                              title="Tarea que realizas, cómo está diseñada y el sentido que tiene para ti.">
                              

                              <Button
                                size="small"
                                className="btn-info btn-pill px-6 hover-scale-sm mb-2"
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "info"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Información
                                </span>
                              </Button>
                              </Tooltip>

                              {!iconContenidoTrabajo ?
                              <Button
                                size="small"
                                className="btn-success btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickContenidoTrabajo()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Seleccionar
                                </span>
                              </Button>
                              :
                              <Button
                                size="small"
                                className="btn-danger btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickContenidoTrabajo()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Quitar
                                </span>
                              </Button>
                              }
                            </div>
                          </div>
                          <img
                            src={contenidoDeTrabajo}
                            className="card-img-top rounded"
                            alt="..."
                            style={{width: 140, height: 140}}
                          />
                        </a>
                      </Card>
                      <h5 className="text-center mt-4">
                      Características de mis tareas
                      </h5>
                    </Grid>
                    <Grid item xl={2} md={2} sm={2}>
                      <Card
                        className="shadow-xxl"
                        width={140}
                        height={140}
                        style={
                          iconCargaRitmo
                            ? {
                                borderRadius: "30px",
                                border: `solid 7px green`,
                                height: 150,
                                width: 150
                              }
                            : {}
                        }
                      >
                        <a
                          href="#/"
                          onClick={(e) => e.preventDefault()}
                          className="card-img-wrapper rounded"
                        >
                          <div className="img-wrapper-overlay p-10">
                            <div className="overlay-btn-wrapper ">
                            <Tooltip
                              classes={{ tooltip: 'text-center p-3 tooltip-secondary' }}
                              arrow
                              placement="top"
                              title="Cantidad y ritmo de tu trabajo, y nivel de atención que te requiere.">
                              

                              <Button
                                size="small"
                                className="btn-info btn-pill px-6 hover-scale-sm mb-2"
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "info"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Información
                                </span>
                              </Button>
                              </Tooltip>

                              {!iconCargaRitmo ?
                              <Button
                                size="small"
                                className="btn-success btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickCargaRitmo()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Seleccionar
                                </span>
                              </Button>
                              :
                              <Button
                                size="small"
                                className="btn-danger btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickCargaRitmo()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Quitar
                                </span>
                              </Button>
                              }
                            </div>
                          </div>
                          <img
                            src={cargaYRitmo}
                            className="card-img-top rounded"
                            alt="..."
                            style={{width: 140, height: 140}}
                          />
                        </a>
                      </Card>
                      <h5 className="text-center mt-4">
                      Carga y ritmo de tu trabajo
                      </h5>
                    </Grid>
                    <Grid item xl={2} md={2} sm={2}>
                      <Card
                        className="shadow-xxl"
                        width={140}
                        height={140}
                        style={
                          iconTiempo
                            ? {
                                borderRadius: "30px",
                                border: `solid 7px green`,
                                height: 150,
                                width: 150
                              }
                            : {}
                        }
                      >
                        <a
                          href="#/"
                          onClick={(e) => e.preventDefault()}
                          className="card-img-wrapper rounded"
                        >
                          <div className="img-wrapper-overlay p-10">
                            <div className="overlay-btn-wrapper ">
                            <Tooltip
                              classes={{ tooltip: 'text-center p-3 tooltip-secondary' }}
                              arrow
                              placement="top"
                              title="Cómo está organizado tu tiempo de trabajo (cantidad de tiempo, distribución, descansos entre jornadas y pausas, horarios atípicos, etc.)">
                              

                              <Button
                                size="small"
                                className="btn-info btn-pill px-6 hover-scale-sm mb-2"
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "info"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Información
                                </span>
                              </Button>
                              </Tooltip>

                              {!iconTiempo ?
                              <Button
                                size="small"
                                className="btn-success btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickTiempo()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Seleccionar
                                </span>
                              </Button>
                              :
                              <Button
                                size="small"
                                className="btn-danger btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickTiempo()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Quitar
                                </span>
                              </Button>
                              }
                            </div>
                          </div>
                          <img
                            src={tiempoDeTrabajo}
                            className="card-img-top rounded"
                            alt="..."
                            style={{width: 140, height: 140}}
                          />
                        </a>
                      </Card>
                      <h5 className="text-center mt-4">
                      Tiempo de trabajo
                      </h5>
                    </Grid>
                    <Grid item xl={2} md={2} sm={2}>
                      <Card
                        className="shadow-xxl"
                        width={140}
                        height={140}
                        style={
                          iconParticipacion
                            ? {
                                borderRadius: "30px",
                                border: `solid 7px green`,
                                height: 150,
                                width: 150
                              }
                            : {}
                        }
                      >
                        <a
                          href="#/"
                          onClick={(e) => e.preventDefault()}
                          className="card-img-wrapper rounded"
                        >
                          <div className="img-wrapper-overlay p-10">
                            <div className="overlay-btn-wrapper ">
                            <Tooltip
                              classes={{ tooltip: 'text-center p-3 tooltip-secondary' }}
                              arrow
                              placement="top"
                              title="Posibilidad de participar, organizar, tomar decisiones y ser autónomo/a en tu trabajo.">
                              

                              <Button
                                size="small"
                                className="btn-info btn-pill px-6 hover-scale-sm mb-2"
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "info"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Información
                                </span>
                              </Button>
                              </Tooltip>

                              {!iconParticipacion ?
                              <Button
                                size="small"
                                className="btn-success btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickParticipacion()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Seleccionar
                                </span>
                              </Button>
                              :
                              <Button
                                size="small"
                                className="btn-danger btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickParticipacion()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Quitar
                                </span>
                              </Button>
                              }
                            </div>
                          </div>
                          <img
                            src={participacionYControl}
                            className="card-img-top rounded"
                            alt="..."
                            style={{width: 140, height: 140}}
                          />
                        </a>
                      </Card>
                      <h5 className="text-center mt-4">
                      Participación y control en tu trabajo
                      </h5>
                    </Grid>
                    <Grid item xl={2} md={2} sm={2}>
                      <Card
                        className="shadow-xxl"
                        width={140}
                        height={140}
                        style={
                          iconRol
                            ? {
                                borderRadius: "30px",
                                border: `solid 7px green`,
                                height: 150,
                                width: 150
                              }
                            : {}
                        }
                      >
                        <a
                          href="#/"
                          onClick={(e) => e.preventDefault()}
                          className="card-img-wrapper rounded"
                        >
                          <div className="img-wrapper-overlay p-10">
                            <div className="overlay-btn-wrapper ">
                            <Tooltip
                              classes={{ tooltip: 'text-center p-3 tooltip-secondary' }}
                              arrow
                              placement="top"
                              title="Claridad y cantidad de funciones y responsabilidades.">
                              

                              <Button
                                size="small"
                                className="btn-info btn-pill px-6 hover-scale-sm mb-2"
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "info"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Información
                                </span>
                              </Button>
                              </Tooltip>

                              {!iconRol ?
                              <Button
                                size="small"
                                className="btn-success btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickRol()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Seleccionar
                                </span>
                              </Button>
                              :
                              <Button
                                size="small"
                                className="btn-danger btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickRol()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Quitar
                                </span>
                              </Button>
                              }
                            </div>
                          </div>
                          <img
                            src={desempeñoDeRol}
                            className="card-img-top rounded"
                            alt="..."
                            style={{width: 140, height: 140}}
                          />
                        </a>
                      </Card>
                      <h5 className="text-center mt-4">
                      Funciones y responsabilidades
                      </h5>
                    </Grid>
                    </Grid>
                    <Grid item xl={2} md={2} sm={2}>
                      <Card
                        className="shadow-xxl"
                        width={140}
                        height={140}
                        style={
                          iconDesarrollo
                            ? {
                                borderRadius: "30px",
                                border: `solid 7px green`,
                                height: 150,
                                width: 150
                              }
                            : {}
                        }
                      >
                        <a
                          href="#/"
                          onClick={(e) => e.preventDefault()}
                          className="card-img-wrapper rounded"
                        >
                          <div className="img-wrapper-overlay p-10">
                            <div className="overlay-btn-wrapper ">
                            <Tooltip
                              classes={{ tooltip: 'text-center p-3 tooltip-secondary' }}
                              arrow
                              placement="top"
                              title="Puesto/cargo dentro de la organización, estabilidad y oportunidades de crecimiento.">
                              

                              <Button
                                size="small"
                                className="btn-info btn-pill px-6 hover-scale-sm mb-2"
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "info"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Información
                                </span>
                              </Button>
                              </Tooltip>

                              {!iconDesarrollo?
                              <Button
                                size="small"
                                className="btn-success btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickDesarrollo()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Seleccionar
                                </span>
                              </Button>
                              :
                              <Button
                                size="small"
                                className="btn-danger btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickDesarrollo()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Quitar
                                </span>
                              </Button>
                              }
                            </div>
                          </div>
                          <img
                            src={desarrolloProfesional}
                            className="card-img-top rounded"
                            alt="..."
                            style={{width: 140, height: 140}}
                          />
                        </a>
                      </Card>
                      <h5 className="text-center mt-4">
                      Desarrollo profesional
                      </h5>
                    </Grid>
                    <Grid item xl={2} md={2} sm={2}>
                      <Card
                        className="shadow-xxl"
                        width={140}
                        height={140}
                        style={
                          iconRelacciones
                            ? {
                                borderRadius: "30px",
                                border: `solid 7px green`,
                                height: 150,
                                width: 150
                              }
                            : {}
                        }
                      >
                        <a
                          href="#/"
                          onClick={(e) => e.preventDefault()}
                          className="card-img-wrapper rounded"
                        >
                          <div className="img-wrapper-overlay p-10">
                            <div className="overlay-btn-wrapper ">
                            <Tooltip
                              classes={{ tooltip: 'text-center p-3 tooltip-secondary' }}
                              arrow
                              placement="top"
                              title="Cómo te relaciones con las personas en tu trabajo y apoyo que sientes.">
                              

                              <Button
                                size="small"
                                className="btn-info btn-pill px-6 hover-scale-sm mb-2"
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "info"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Información
                                </span>
                              </Button>
                              </Tooltip>

                              {!iconRelacciones?
                              <Button
                                size="small"
                                className="btn-success btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickRelacciones()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Seleccionar
                                </span>
                              </Button>
                              :
                              <Button
                                size="small"
                                className="btn-danger btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickRelacciones()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Quitar
                                </span>
                              </Button>
                              }
                            </div>
                          </div>
                          <img
                            src={relacionesInterpersonales}
                            className="card-img-top rounded"
                            alt="..."
                            style={{width: 140, height: 140}}
                          />
                        </a>
                      </Card>
                      <h5 className="text-center mt-4">
                      Relaciones y apoyo social
                      </h5>
                    </Grid>
                    <Grid item xl={2} md={2} sm={2}>
                      <Card
                        className="shadow-xxl"
                        width={140}
                        height={140}
                        style={
                          iconEquipos
                            ? {
                                borderRadius: "30px",
                                border: `solid 7px green`,
                                height: 150,
                                width: 150
                              }
                            : {}
                        }
                      >
                        <a
                          href="#/"
                          onClick={(e) => e.preventDefault()}
                          className="card-img-wrapper rounded"
                        >
                          <div className="img-wrapper-overlay p-10">
                            <div className="overlay-btn-wrapper ">
                            <Tooltip
                              classes={{ tooltip: 'text-center p-3 tooltip-secondary' }}
                              arrow
                              placement="top"
                              title="Interacción que tienes con tu entorno de trabajo, los equipos, máquinas, herramientas, y software que utilizas para el desempeño de tus tareas.">
                              

                              <Button
                                size="small"
                                className="btn-info btn-pill px-6 hover-scale-sm mb-2"
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "info"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Información
                                </span>
                              </Button>
                              </Tooltip>

                              {!iconEquipos?
                              <Button
                                size="small"
                                className="btn-success btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickEquipos()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Seleccionar
                                </span>
                              </Button>
                              :
                              <Button
                                size="small"
                                className="btn-danger btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickEquipos()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Quitar
                                </span>
                              </Button>
                              }
                            </div>
                          </div>
                          <img
                            src={equiposDeTrabajo}
                            className="card-img-top rounded"
                            alt="..."
                            style={{width: 140, height: 140}}
                          />
                        </a>
                      </Card>
                      <h5 className="text-center mt-4">
                      Equipamiento para trabajar
                      </h5>
                    </Grid>
                    <Grid item xl={2} md={2} sm={2}>
                      <Card
                        className="shadow-xxl"
                        width={140}
                        height={140}
                        style={
                          iconInteracciones
                            ? {
                                borderRadius: "30px",
                                border: `solid 7px green`,
                                height: 150,
                                width: 150
                              }
                            : {}
                        }
                      >
                        <a
                          href="#/"
                          onClick={(e) => e.preventDefault()}
                          className="card-img-wrapper rounded"
                        >
                          <div className="img-wrapper-overlay p-10">
                            <div className="overlay-btn-wrapper ">
                            <Tooltip
                              classes={{ tooltip: 'text-center p-3 tooltip-secondary' }}
                              arrow
                              placement="top"
                              title="Posibilidad de conciliar tu trabajo con las necesidades/exigencias de tu vida familiar y social.">
                              

                              <Button
                                size="small"
                                className="btn-info btn-pill px-6 hover-scale-sm mb-2"
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "info"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Información
                                </span>
                              </Button>
                              </Tooltip>

                              {!iconInteracciones?
                              <Button
                                size="small"
                                className="btn-success btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickInteracciones()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Seleccionar
                                </span>
                              </Button>
                              :
                              <Button
                                size="small"
                                className="btn-danger btn-pill px-8 hover-scale-sm"
                                onClick={() => onClickInteracciones()}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "check"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Quitar
                                </span>
                              </Button>
                              }
                            </div>
                          </div>
                          <img
                            src={interaccionCasaTrabajo}
                            className="card-img-top rounded"
                            alt="..."
                            style={{width: 140, height: 140}}
                          />
                        </a>
                      </Card>
                      <h5 className="text-center mt-4">
                      Interacciones trabajo-casa
                      </h5>
                    </Grid>
                    </Grid>
                    </div>
          <h5 className="font-weight-bold mt-2" style={{ color: "#5a318e" }}>
            ¿En qué se puede mejorar?
          </h5>
          <h6>Opcional</h6>

         
          <div className="d-flex justify-content-center">
                <div  style={{ width: "80%"}} >
                <InputEmoji
                  value={commentary}
                  onChange={setCommentary}
                  cleanOnEnter
                  borderColor="purple"
                  placeholder="Escribe aquí"
                  keepOpenend
                />
                </div>
                </div>
          <div style={{ width: "80%", marginLeft:70 }} className="text-left">
            <div className="d-flex flex-row align-items-center">
              

     
           <div className="m-2" >
              <Switch
                onChange={handleChangeCheckTimeline}
                checked={checkTimeline}
                color="primary"
                name="checkedA"
                className="switch-small"
              />
            </div>
            <h6 className="mb-0">El comentario podrá ser visto siempre por los administradores. Si se selecciona el check, el comentario también será público y se mostrará en el Timeline</h6>
            </div>
            {/* <div className="d-flex flex-row align-items-center">
           <div className="m-2" >
              <Switch
                onChange={checkTimeline ? handleChangeCheckAdmin : null}
                checked={checkAdmin}
                color="primary"
                name="checkedA"
                className="switch-small"
              />
            </div>
            <h6 className="mb-0">Si se selecciona, se enviará una notificación a los administradores para que lean antes este comentario. (Debe estar marcada la opción anterior)</h6>
            </div> */}
            <h6 className="text-black-50 mb-0 mt-4">* por defecto se usa la configuración de privacidad de cada usuario, puedes consultarla en tu perfil</h6>
            </div>
          </>}
          <div className="" style={{ marginBottom: 20, marginTop:20 }}>
            <Button
              onClick={handleCloseModal}
              className="btn-neutral-dark btn-pill mx-1"
            >
              <span className="btn-wrapper--label">Cancelar</span>
            </Button>
            <Button 
              onClick={onClickButton}
              className="btn-success btn-pill mx-1" 
              disabled={!value || (value>=4 && iconContenidoTrabajo === false &&
                                              iconCargaRitmo === false &&
                                              iconTiempo === false &&
                                              iconParticipacion === false &&
                                              iconRol === false &&
                                              iconParticipacion === false &&
                                              iconDesarrollo === false &&
                                              iconRelacciones === false &&
                                              iconEquipos === false &&
                                              iconInteracciones === false)}
            >
              <span className="btn-wrapper--label">Enviar</span>
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

const marksRed = [
  {
    value: 1,
    label: (
      <h5 className="pt-2" color="red">
        <strong>Baja</strong>
      </h5>
    ),
  },
  {
    value: 10,
    label: (
      <h5 className="pt-2" color="red">
        <strong>Alta</strong>
      </h5>
    ),
  },
];

const PrettoSliderRed = withStyles({
  root: {
    color: "red",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);
