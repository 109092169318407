import React, { useEffect, useState, useContext } from "react";
import { Grid, LinearProgress, Card, CardTitle, CardHeader, CardBody, Tooltip } from "@material-ui/core";
import HeatMap from '@uiw/react-heat-map';

import '../../../../../assets/climind.scss'

import CountUp from "react-countup";


export default function DashboardPulse4({
  arrayDates,
  representivityPositive,
  representivityNegative,
  representivityNeutro,
  totalPulse,
  initDate
}) {
  // console.log(arrayDates);
  // let tmp = {
  //   "date": "2021/10/31",
  //   "positive": 1,
  //   "neutro": 2,
  //   "negative": 1,
  //   "trend": "neutro",
  //   "count": 20,
  //   "total": 0
  // }
  // arrayDates.push(tmp);
  const oneDayTime = 24 * 60 * 60 * 1000;
  const startDate = new Date(2021, 9, 1);

  let parts = initDate.split('-'); // divide la cadena en partes
  let formattedDate = parts[0] + '/' + parts[1] + '/' + parts[2]; // cambia el orden de las partes


  console.log(totalPulse);
  console.log(initDate);


  return (
    <>
      <Card className="card-box mb-spacing-6-x2">
        <div className="card-header">
          <div className="card-header--title">
            <small className="d-block text-uppercase mt-1">
              Pulso
            </small>
            <b>Evolución del pulso</b>
          </div>
          <div className="card-tr-actions">
            <Tooltip
              classes={{
                tooltip: "text-center p-3 tooltip-secondary",
              }}
              arrow
              placement="top"
              title="Esta tabla resume todos los días del año colocando el color del pulso predominante del día"
            >
              <a
                href="#/"
                onClick={(e) => e.preventDefault()}
                className="text-first font-weight-bold"
              >
                <div
                  className=" avatar-icon rounded-circle mr-2"
                  style={{ width: 26, height: 26 }}
                >
                  <img
                    alt="..."
                    src={require("../../../../../assets/images/climind/info.png")}
                  />
                </div>
              </a>
            </Tooltip>
          </div>
        </div>
        <div className="px-5 pt-5 pb-2">
          <h1 className="display-2 font-weight-bold mb-4">
            <span className="pl-1">
              <CountUp
                start={0}
                end={totalPulse}
                duration={4}
                separator=""
                decimals={0}
                decimal=","
                prefix=""
                suffix=""
              /> Pulsos medidos

            </span>
          </h1>
          <Grid container spacing={6}>
            <Grid item sm={4}>
              <div>
                <span className="font-size-xl font-weight-bold">{isFinite((representivityPositive * 100).toFixed(2)) ? (representivityPositive * 100).toFixed(2) + "%" : 0 + "%"}</span>
              </div>
              <LinearProgress
                variant="determinate"
                value={isFinite((representivityPositive * 100).toFixed(2)) ? (representivityPositive * 100).toFixed(2) : 0}
                className="progress-animated-alt progress-bar-rounded progress-sm mb-2 progress-bar-success progress-bar-climind-green"
              />
              <div className="text-dark">Pulso positivo</div>
            </Grid>
            <Grid item sm={4}>
              <div>
                <span className="font-size-xl font-weight-bold">{isFinite((representivityNegative * 100).toFixed(2)) ? (representivityNegative * 100).toFixed(2) + "%" : 0 + "%"}</span>
              </div>
              <LinearProgress
                variant="determinate"
                value={isFinite((representivityNegative * 100).toFixed(2)) ? (representivityNegative * 100).toFixed(2) : 0}
                className="progress-bar-danger progress-bar-rounded progress-sm mb-2 progress-bar-climind-red"
              />
              <div className="text-dark">Pulso negativo</div>
            </Grid>
            <Grid item sm={4}>
              <div>
                <span className="font-size-xl font-weight-bold">{isFinite((representivityNeutro * 100).toFixed(2)) ? (representivityNeutro * 100).toFixed(2) + "%" : 0 + "%"}</span>
              </div>
              <LinearProgress
                variant="determinate"
                value={isFinite((representivityNeutro * 100).toFixed(2)) ? (representivityNeutro * 100).toFixed(2) : 0}
                className="progress-bar-warning progress-bar-rounded progress-sm mb-2 progress-bar-climind-yellow"
              />
              <div className="text-dark">Pulso neutro</div>
            </Grid>
          </Grid>
        </div>
        <div style={{ height: '380px' }}>
          <HeatMap
            value={arrayDates}
            width='100%'
            height='380px'
            legendCellSize={0}
            startDate={new Date(formattedDate)}
            rectSize={40}
            weekLables={['Do', 'Lu', 'Mar', 'Mi', 'Ju', 'Vi', 'Sá']}
            monthLables={['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']}
            legendRender={(props) => <rect {...props} y={props.y + 10} rx={5} />}
            rectProps={{
              rx: 10
            }}
            rectRender={(props, data) => {
              // if (!data.count) return <rect {...props} />;
              if (data.date)
                return (
                  <Tooltip
                    classes={{
                      tooltip: "text-center p-3 tooltip-secondary",
                    }}
                    arrow
                    placement="top"
                    title={`Positivos: ${data.positive || 0}, Neutros: ${data.neutro || 0}, Negativos: ${data.negative || 0}, Promedio: ${data.prom || 0}, Fecha:${data.date}`}
                    key={data.date}
                  >
                    <rect {...props} />
                  </Tooltip>
                );
            }}
            panelColors={{
              0: '#ebedf0',
              5: '#1bc943',
              10: '#1bc943',
              13: '#1bc943',
              15: '#efcc1f',
              20: '#efcc1f',
              23: '#efcc1f',
              25: '#f83245',
              30: '#f83245',
              33: '#f83245',
            }}
          />
        </div>


      </Card>
    </>
  );
}
