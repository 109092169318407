import React, { useEffect, useState, useContext } from "react";

import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  Container,
  InputAdornment,
  ButtonGroup,
  Card,
  Button,
  List,
  ListItem,
  TextField,
  LinearProgress,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Snackbar
} from "@material-ui/core";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";

import PerfectScrollbar from "react-perfect-scrollbar";
import { useDropzone } from "react-dropzone";
import CountUp from "react-countup";

import CloseTwoToneIcon from "@material-ui/icons/CloseTwoTone";
import PublishTwoToneIcon from "@material-ui/icons/PublishTwoTone";
import AccountCircleTwoToneIcon from "@material-ui/icons/AccountCircleTwoTone";
import CheckIcon from "@material-ui/icons/Check";
import { UserContext } from "../../../../context/UserContext";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";

import { useFormik, Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { EDIT_COMPANY } from "../../../../data/mutations";
import { GET_COMPANIES, GET_COMPANY_BY_ID } from "data/queries";
import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function CompanyInfo({ companyId }) {
  // const [selectedDate, setSelectedDate] = useState(new Date("2020-08-18"));
  const [editCompany, { dataMutation }] = useMutation(EDIT_COMPANY);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { loading, error, data, networkStatus } = useQuery(GET_COMPANY_BY_ID, {
    variables: { id: companyId },
    skip: !companyId,
    notifyOnNetworkStatusChange: true,
  });

  const today = new Date();
  //const [newCompany, { data }] = useMutation(NEW_COMPANY);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Obligatorio")
      .max(100, "Como mucho 100 caracteres")
      .typeError("nombre invalido"),
    legalName: Yup.string()
      .required("Obligatorio")
      .typeError("denominación social invalida"),
    email: Yup.string().email("Email invalido").required("Obligatorio"),
    nif: Yup.string().required("Obligatorio"),
    numberTotalUsers: Yup.number()
      .integer("numero total de usuarios invalido")
      .required("Obligatorio")
      .typeError("numero de trabajadores invalido"),
    numberUsersToRegister: Yup.number()
      .integer("numero de usuarios a registrar invalido")
      .required("Obligatorio")
      .typeError("numero de usuarios a registrar invalido"),
    payment: Yup.string().required("Obligatorio"),
    website: Yup.string().required("Obligatorio"),
    nameOffice: Yup.string().required("Obligatorio"),
    address: Yup.string().required("Obligatorio"),
    country: Yup.string().required("Obligatorio"),
    city: Yup.string().required("Obligatorio"),
    province: Yup.string().required("Obligatorio"),
    zipCode: Yup.number()
      .integer("Código postal invalido")
      .required("Obligatorio")
      .typeError("código postal invalido"),
    code: Yup.number()
      .integer("Código invalido")
      .required("Obligatorio")
      .max(999, "Como mucho 3 caracteres")
      .typeError("código invalido"),
  });

  if (data) {
    console.log(data);
    return (
      <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        key={"top,center"}
        open={openSnackbar}
        // classes={{ root: "toastr-primary" }}
        autoHideDuration={4000}
        onClose={() => setOpenSnackbar(false)}
        message={"entregado correctamente"}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          style={{ color: "white" }}
        >
          Compañía editada correctamente
        </Alert>
      </Snackbar>
        <Formik
          initialValues={{
            name: data.getCompanyById.name,
            legalName: data.getCompanyById.legalName,
            email: data.getCompanyById.email,
            nif: data.getCompanyById.nif,
            payment: data.getCompanyById.payment,
            website: data.getCompanyById.website,
            numberTotalUsers: parseInt(data.getCompanyById.numberTotalUsers),
            numberUsersToRegister: parseInt(data.getCompanyById.numberUsersToRegister),
            main: true,
            nameOffice: data.getCompanyById.offices[0].name,
            address: data.getCompanyById.offices[0].address,
            country: data.getCompanyById.offices[0].country,
            city: data.getCompanyById.offices[0].city,
            province: data.getCompanyById.offices[0].province,
            zipCode: data.getCompanyById.offices[0].zipCode,
            code: data.getCompanyById.offices[0].code,
          }}
          onSubmit={(values, actions) => {
            try {
              let dataInput = {
                name: values.name,
                legalName: values.legalName,
                email: values.email,
                nif: values.nif,
                payment: values.payment,
                website: values.website,
                numberTotalUsers: parseInt(values.numberTotalUsers),
                numberUsersToRegister: parseInt(values.numberUsersToRegister),
                offices: {
                  main: true,
                  name: values.nameOffice,
                  address: values.address,
                  country: values.country,
                  city: values.city,
                  province: values.province,
                  zipCode: values.zipCode,
                  code: values.code,
                },
              };
              console.log(dataInput);
              let id=data.getCompanyById.id
              editCompany({
                variables: { id: data.getCompanyById.id, input: dataInput },
                refetchQueries: 
                    [{query: GET_COMPANIES} ,{query:GET_COMPANY_BY_ID, variables: {id, id}}]
                  ,
              });
              // toggleEditEmployee();
            } catch (err) {
              console.log(err);
            }
            setOpenSnackbar(true, () => {
              setTimeout(function () {
                setOpenSnackbar(false);
              }, 5000); //5 Second delay
            });
          }}
          validationSchema={validationSchema}
        >
          {(props) => (
            <>
            {console.log(props)}
            
              <form onSubmit={props.handleSubmit} onReset={props.handleReset}>
                <Grid container spacing={4}>
                  <Grid item md={4}>
                    <div className="text-center my-5">
                      <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-primary shadow-primary-sm text-primary mb-2 d-90">
                        <FontAwesomeIcon
                          icon={["fas", "building"]}
                          className="d-flex align-self-center font-size-xxl"
                        />
                      </div>
                      <h6 className="font-weight-bold font-size-xxl mb-1 mt-3 text-primary">
                        Información Básica
                      </h6>
                    </div>
                  </Grid>

                  <Grid item md={8}>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <Card
                          className="p-4 mt-4"
                          style={{ backgroundColor: "#eceef7" }}
                          elevation={0}
                        >
                          <div>
                            <TextField
                              fullWidth
                              className="m-2"
                              id="outlined-multiline-static-1"
                              name="name"
                              label="Nombre"
                              placeholder="Escribe aqui..."
                              variant="outlined"
                              value={props.values.name}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={Boolean(
                                props.touched.name && props.errors.name
                              )}
                              helperText={
                                props.touched.name ? props.errors.name : ""
                              }
                            />
                            <TextField
                              fullWidth
                              className="m-2"
                              id="outlined-multiline-static-2"
                              name="legalName"
                              label="Nombre Legal"
                              placeholder="Escribe aqui..."
                              variant="outlined"
                              value={props.values.legalName}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={Boolean(
                                props.touched.legalName && props.errors.legalName
                              )}
                              helperText={
                                props.touched.legalName ? props.errors.legalName : ""
                              }
                            />
                            <TextField
                              fullWidth
                              className="m-2"
                              id="outlined-multiline-static-3"
                              name="nif"
                              label="NIF"
                              placeholder="Escribe aqui..."
                              variant="outlined"
                              value={props.values.nif}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={Boolean(
                                props.touched.nif && props.errors.nif
                              )}
                              helperText={
                                props.touched.nif ? props.errors.nif : ""
                              }
                            />
                            <TextField
                              fullWidth
                              className="m-2"
                              id="outlined-multiline-static-4"
                              name="email"
                              label="Email"
                              placeholder="Escribe aqui..."
                              variant="outlined"
                              value={props.values.email}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={Boolean(
                                props.touched.email && props.errors.email
                              )}
                              helperText={
                                props.touched.email ? props.errors.email : ""
                              }
                            />
                            <TextField
                              fullWidth
                              className="m-2"
                              id="outlined-multiline-static-4"
                              name="website"
                              label="Sitio web"
                              placeholder="Escribe aqui..."
                              variant="outlined"
                              value={props.values.website}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={Boolean(
                                props.touched.website && props.errors.website
                              )}
                              helperText={
                                props.touched.website ? props.errors.website : ""
                              }
                            />
                            <TextField
                              fullWidth
                              className="m-2"
                              id="outlined-multiline-static-4"
                              name="numberTotalUsers"
                              label="Número de usuarios de la empresa"
                              placeholder="Escribe aqui..."
                              variant="outlined"
                              value={props.values.numberTotalUsers}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={Boolean(
                                props.touched.numberTotalUsers && props.errors.numberTotalUsers
                              )}
                              helperText={
                                props.touched.numberTotalUsers ? props.errors.numberTotalUsers : ""
                              }
                            />
                            <TextField
                              fullWidth
                              className="m-2"
                              id="outlined-multiline-static-4"
                              name="numberUsersToRegister"
                              label="Número de usuarios a registrar en Climind"
                              placeholder="Escribe aqui..."
                              variant="outlined"
                              value={props.values.numberUsersToRegister}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={Boolean(
                                props.touched.numberUsersToRegister && props.errors.numberUsersToRegister
                              )}
                              helperText={
                                props.touched.numberUsersToRegister ? props.errors.numberUsersToRegister : ""
                              }
                            />
                            <div className="heading-3 text-center ml-2 mt-2">
                              <FormControl fullWidth variant="outlined">
                                <InputLabel id="demo-simple-select-outlined-label">
                                  Forma de pago
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  name="payment"
                                  label="payment"
                                  value={props.values.payment}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={Boolean(
                                props.touched.payment && props.errors.payment
                              )}
                              helperText={
                                props.touched.payment ? props.errors.payment : ""
                              }
                                >
                                  <MenuItem value="">
                                    <em>Ninguno</em>
                                  </MenuItem>
                                  <MenuItem value="YEAR">Anual</MenuItem>
                                  <MenuItem value="MONTH">Mensual</MenuItem>
                                </Select>
                              </FormControl>
                            </div>

                            {/* <TextField
                      fullWidth
                      className="m-2"
                      id="outlined-multiline-static-4"
                      name="constitutionDate"
                      type="date"
                      placeholder="Escribe aqui..."
                      variant="outlined"
                      {...formik.getFieldProps("constitutionDate")}
                      error={Boolean(formik.touched.constitutionDate && formik.errors.constitutionDate)}
                      helperText={formik.touched.constitutionDate ? formik.errors.constitutionDate : ""}
                    /> */}
                          </div>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={6}>
                  <Grid item md={4}>
                    <div className="text-center my-5">
                      <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-primary shadow-primary-sm text-primary mb-2 d-90">
                        <FontAwesomeIcon
                          icon={["fas", "plus"]}
                          className="d-flex align-self-center font-size-xxl"
                        />
                      </div>
                      <h6 className="font-weight-bold font-size-xxl mb-1 mt-3 text-primary">
                        Información del centro principal
                      </h6>
                    </div>
                  </Grid>
                  <Grid item md={8}>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <Card
                          className="p-4 mt-4"
                          style={{ backgroundColor: "#eceef7" }}
                          elevation={0}
                        >
                          <div>
                            <TextField
                              fullWidth
                              className="m-2"
                              id="outlined-multiline-static-1"
                              name="nameOffice"
                              label="Nombre"
                              placeholder="Escribe aqui..."
                              variant="outlined"
                              value={props.values.nameOffice}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={Boolean(
                                props.touched.nameOffice && props.errors.nameOffice
                              )}
                              helperText={
                                props.touched.nameOffice ? props.errors.nameOffice : ""
                              }
                            />
                            <TextField
                              fullWidth
                              className="m-2"
                              id="outlined-multiline-static-1"
                              name="code"
                              label="Código"
                              placeholder="Escribe aqui..."
                              variant="outlined"
                              value={props.values.code}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={Boolean(
                                props.touched.code && props.errors.code
                              )}
                              helperText={
                                props.touched.code ? props.errors.code : ""
                              }
                            />
                            <TextField
                              fullWidth
                              className="m-2"
                              id="outlined-multiline-static-2"
                              name="address"
                              label="Dirección"
                              placeholder="Escribe aqui..."
                              variant="outlined"
                              value={props.values.address}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={Boolean(
                                props.touched.address && props.errors.address
                              )}
                              helperText={
                                props.touched.address ? props.errors.address : ""
                              }
                            />
                            <TextField
                              fullWidth
                              className="m-2"
                              id="outlined-multiline-static-3"
                              name="country"
                              label="País"
                              placeholder="Escribe aqui..."
                              variant="outlined"
                              value={props.values.country}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={Boolean(
                                props.touched.nacountryme && props.errors.country
                              )}
                              helperText={
                                props.touched.country ? props.errors.country : ""
                              }
                            />
                            <TextField
                              fullWidth
                              className="m-2"
                              id="outlined-multiline-static-4"
                              name="city"
                              label="Ciudad"
                              placeholder="Escribe aqui..."
                              variant="outlined"
                              value={props.values.city}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={Boolean(
                                props.touched.city && props.errors.city
                              )}
                              helperText={
                                props.touched.city ? props.errors.city : ""
                              }
                            />
                            <TextField
                              fullWidth
                              className="m-2"
                              id="outlined-multiline-static-4"
                              name="province"
                              label="Provincia"
                              placeholder="Escribe aqui..."
                              variant="outlined"
                              value={props.values.province}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={Boolean(
                                props.touched.province && props.errors.province
                              )}
                              helperText={
                                props.touched.province ? props.errors.province : ""
                              }
                            />
                            <TextField
                              fullWidth
                              className="m-2"
                              id="outlined-multiline-static-4"
                              name="zipCode"
                              label="Código postal"
                              placeholder="Escribe aqui..."
                              variant="outlined"
                              value={props.values.zipCode}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={Boolean(
                                props.touched.zipCode && props.errors.zipCode
                              )}
                              helperText={
                                props.touched.zipCode ? props.errors.zipCode : ""
                              }
                            />
                          </div>
                        </Card>
                        <div className="pt-4 text-center">
                          <Button
                            // onClick={handleReset}
                            className="btn-warning font-weight-bold rounded hover-scale-lg mx-1"
                            size="large"
                            type="submit"
                            // component={Link} to="/homeuser/"
                          >
                            <span className="btn-wrapper--label" >
                              Actualizar
                            </span>
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </>
          )}
        </Formik>
      </>
    );
  } else {
    return null;
  }
}
