import React, { useState, useCallback, useContext } from "react";
import { GET_COMPETENCES_BY_USER_ID, GET_COMPANIES } from "data/queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "../../../../context/UserContext";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  Table,
  LinearProgress,
  Card,
  CardContent,
  Button,
  Tooltip,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  Select
} from "@material-ui/core";
import { parse, isDate } from "date-fns";
import { NEW_COMPANY } from "../../../../data/mutations";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { useFormik } from "formik";
import * as Yup from "yup";
export default function ModalAddCompany({
  modalAddCompany,
  toggleAddCompany,
}) {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);

  const today = new Date();
  const [newCompany, { data }] = useMutation(NEW_COMPANY);
  const [values, setValues] = useState({
    name: "",
    legalName: "",
    email: "",
    nif: "",
    payment: "",
    website: "",
    numberTotalUsers: "",
    numberUsersToRegister: "",
    nameOffice: "",
    address: "",
    country: "",
    city: "",
    province: "",
    zipCode: "",
    code: ""
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      legalName: "",
      email: "",
      nif: "",
      payment: "",
      website: "",
      numberTotalUsers: null,
      numberUsersToRegister: null,
      nameOffice: "",
      address: "",
      country: "",
      city: "",
      province: "",
      zipCode: "",
      code: ""
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Obligatorio").max(100,"Como mucho 100 caracteres").typeError('nombre invalido'),
      legalName: Yup.string().required("Obligatorio").typeError('denominación social invalida'),
      email: Yup.string().email("Email invalido").required("Obligatorio"),
      nif: Yup.string().required("Obligatorio"),
      numberTotalUsers: Yup.number().integer("numero total de usuarios invalido").required("Obligatorio").typeError('numero de trabajadores invalido'),
      numberUsersToRegister: Yup.number().integer("numero de usuarios a registrar invalido").required("Obligatorio").typeError('numero de usuarios a registrar invalido'),
      payment: Yup.string().required("Obligatorio"),
      website: Yup.string().required("Obligatorio"),
      nameOffice: Yup.string().required("Obligatorio"),
      address: Yup.string().required("Obligatorio"),
      country: Yup.string().required("Obligatorio"),
      city: Yup.string().required("Obligatorio"),
      province: Yup.string().required("Obligatorio"),
      zipCode: Yup.number().integer("Código postal invalido").required("Obligatorio").typeError('código postal invalido'),
      code: Yup.number().integer("Código invalido").required("Obligatorio").max(999,"Como mucho 3 caracteres").typeError('código invalido')
    }),
    onSubmit: async (data, { setErrors, resetForm }) => {
      try {
          let dataInput = {
                name: data.name,
                legalName: data.legalName,
                email: data.email,
                nif: data.nif,
                payment: data.payment,
                website: data.website,
                numberTotalUsers: parseInt(data.numberTotalUsers),
                numberUsersToRegister: parseInt(data.numberUsersToRegister),
                offices: {
                  main: true,
                  name: data.nameOffice,
                  address: data.address,
                  country: data.country,
                  city: data.city,
                  province: data.province,
                  zipCode: data.zipCode,
                  code: data.code
                }
              }
        console.log(dataInput)
        let response = await newCompany({
          variables: { input: dataInput },
          refetchQueries: [
            { query: GET_COMPANIES}
          ],
        });
        setValues({
          name: "",
          legalName: "",
          email: "",
          nif: "",
          payment: "",
          website: "",
          numberTotalUsers: null,
          numberUsersToRegister: null,
          nameOffice: "",
          address: "",
          country: "",
          city: "",
          province: "",
          zipCode: ""
        })
        toggleAddCompany();
      } catch (err) {
        console.log(err);
      }
    },
  });

  return (
    <>
      <Dialog
        open={modalAddCompany}
        onClose={toggleAddCompany}
        classes={{ paper: "modal-content rounded-lg" }}
      >
        <DialogTitle className="bg-white-10">Nueva Empresa</DialogTitle>
        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
            <div className="text-center my-auto">
            <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-primary shadow-primary-sm text-primary mb-2 d-90">
                  <FontAwesomeIcon
                    icon={["fas", "building"]}
                    className="d-flex align-self-center font-size-xxl"
                  />
                </div>
                <h6 className="font-weight-bold font-size-xxl mb-1 mt-3 text-primary">
                      Información Básica
                    </h6>
                    </div>
              <Card
                className="p-4 mt-4"
                style={{ backgroundColor: "#eceef7" }}
                elevation={0}
              >
                
                <div>
                  <TextField
                    fullWidth
                    className="m-2"
                    id="outlined-multiline-static-1"
                    name="name"
                    label="Persona de contacto"
                    placeholder="Por ejemplo, Carlos García"
                    variant="outlined"
                    {...formik.getFieldProps("name")}
                    error={Boolean(formik.touched.name && formik.errors.name)}
                    helperText={formik.touched.name ? formik.errors.name : ""}
                  />
                  <TextField
                    fullWidth
                    className="m-2"
                    id="outlined-multiline-static-2"
                    name="legalName"
                    label="Denominación social"
                    placeholder="Por ejemplo, Empresa, S.l."
                    variant="outlined"
                    {...formik.getFieldProps("legalName")}
                    error={Boolean(formik.touched.legalName && formik.errors.legalName)}
                    helperText={formik.touched.legalName ? formik.errors.legalName : ""}
                  />
                  <TextField
                    fullWidth
                    className="m-2"
                    id="outlined-multiline-static-3"
                    name="nif"
                    label="NIF"
                    placeholder="Por ejemplo, A98673452 o 52122853S"
                    variant="outlined"
                    {...formik.getFieldProps("nif")}
                    error={Boolean(formik.touched.nif && formik.errors.nif)}
                    helperText={formik.touched.nif ? formik.errors.nif : ""}
                  />
                  <TextField
                    fullWidth
                    className="m-2"
                    id="outlined-multiline-static-4"
                    name="email"
                    label="Email"
                    placeholder="Por ejemplo, carlosgarcia@micorreo.com"
                    variant="outlined"
                    {...formik.getFieldProps("email")}
                    error={Boolean(formik.touched.email && formik.errors.email)}
                    helperText={formik.touched.email ? formik.errors.email : ""}
                  />
                  <TextField
                    fullWidth
                    className="m-2"
                    id="outlined-multiline-static-4"
                    name="website"
                    label="Sitio web"
                    placeholder="Por ejemplo,  www.mi página.com"
                    variant="outlined"
                    {...formik.getFieldProps("website")}
                    error={Boolean(formik.touched.website && formik.errors.website)}
                    helperText={formik.touched.website ? formik.errors.website : ""}
                  />
                  <TextField
                    fullWidth
                    className="m-2"
                    id="outlined-multiline-static-4"
                    name="numberTotalUsers"
                    label="Número de trabajadores"
                    placeholder="Por ejemplo, 40"
                    variant="outlined"
                    {...formik.getFieldProps("numberTotalUsers")}
                    error={Boolean(formik.touched.numberTotalUsers && formik.errors.numberTotalUsers)}
                    helperText={formik.touched.numberTotalUsers ? formik.errors.numberTotalUsers : ""}
                  />
                  <TextField
                    fullWidth
                    className="m-2"
                    id="outlined-multiline-static-4"
                    name="numberUsersToRegister"
                    label="Número de usuarios a registrar en Climind"
                    placeholder="Por ejemplo, 30"
                    variant="outlined"
                    {...formik.getFieldProps("numberUsersToRegister")}
                    error={Boolean(formik.touched.numberUsersToRegister && formik.errors.numberUsersToRegister)}
                    helperText={formik.touched.numberUsersToRegister ? formik.errors.numberUsersToRegister : ""}
                  />
                   <div className="heading-3 text-center ml-2 mt-2">

                  <FormControl fullWidth variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                Forma de pago
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name="payment"
                label="payment"
                {...formik.getFieldProps("payment")}
                    error={Boolean(formik.touched.payment && formik.errors.payment)}
                    helperText={formik.touched.payment ? formik.errors.payment : ""}
                  >
                
                <MenuItem value="YEAR">Anual</MenuItem>
                <MenuItem value="MONTH">Mensual</MenuItem>
                <MenuItem value="TEST">Test</MenuItem>
                
              </Select>
            </FormControl>
            </div>
                 
          {/* <TextField
                    fullWidth
                    className="m-2"
                    id="outlined-multiline-static-4"
                    name="constitutionDate"
                    type="date"
                    placeholder="Escribe aqui..."
                    variant="outlined"
                    {...formik.getFieldProps("constitutionDate")}
                    error={Boolean(formik.touched.constitutionDate && formik.errors.constitutionDate)}
                    helperText={formik.touched.constitutionDate ? formik.errors.constitutionDate : ""}
                  /> */}
                </div>
              </Card>
              <div className="text-center my-auto">
              <h6 className="font-weight-bold font-size-xxl mb-1 mt-3 text-primary">
                      Información del centro principal
                    </h6>
                    </div>
              <Card
                className="p-4 mt-4"
                style={{ backgroundColor: "#eceef7" }}
                elevation={0}
              >
                
                <div>
                  <TextField
                    fullWidth
                    className="m-2"
                    id="outlined-multiline-static-1"
                    name="nameOffice"
                    label="Nombre"
                    placeholder="Por ejemplo, Centro principal de Salamanca"
                    variant="outlined"
                    {...formik.getFieldProps("nameOffice")}
                    error={Boolean(formik.touched.nameOffice && formik.errors.nameOffice)}
                    helperText={formik.touched.nameOffice ? formik.errors.nameOffice : ""}
                  />
                  <TextField
                    fullWidth
                    className="m-2"
                    id="outlined-multiline-static-1"
                    name="code"
                    label="Código"
                    placeholder="Código de 3 dígitos, por ejemplo, 001"
                    variant="outlined"
                    {...formik.getFieldProps("code")}
                    error={Boolean(formik.touched.code && formik.errors.code)}
                    helperText={formik.touched.code ? formik.errors.code : ""}
                  />
                  <TextField
                    fullWidth
                    className="m-2"
                    id="outlined-multiline-static-2"
                    name="address"
                    label="Dirección"
                    placeholder="Por ejemplo, calle Toledo"
                    variant="outlined"
                    {...formik.getFieldProps("address")}
                    error={Boolean(formik.touched.address && formik.errors.address)}
                    helperText={formik.touched.address ? formik.errors.address : ""}
                  />
                  <TextField
                    fullWidth
                    className="m-2"
                    id="outlined-multiline-static-4"
                    name="city"
                    label="Ciudad"
                    placeholder="Por ejemplo, Salamanca"
                    variant="outlined"
                    {...formik.getFieldProps("city")}
                    error={Boolean(formik.touched.city && formik.errors.city)}
                    helperText={formik.touched.city ? formik.errors.city : ""}
                  />
                  <TextField
                    fullWidth
                    className="m-2"
                    id="outlined-multiline-static-4"
                    name="province"
                    label="Provincia"
                    placeholder="Por ejemplo, Salamanca"
                    variant="outlined"
                    {...formik.getFieldProps("province")}
                    error={Boolean(formik.touched.province && formik.errors.province)}
                    helperText={formik.touched.province ? formik.errors.province : ""}
                  />
                  <TextField
                    fullWidth
                    className="m-2"
                    id="outlined-multiline-static-3"
                    name="country"
                    label="País"
                    placeholder="Por ejemplo, España"
                    variant="outlined"
                    {...formik.getFieldProps("country")}
                    error={Boolean(formik.touched.country && formik.errors.country)}
                    helperText={formik.touched.country ? formik.errors.country : ""}
                  />
                  <TextField
                    fullWidth
                    className="m-2"
                    id="outlined-multiline-static-4"
                    name="zipCode"
                    label="Código postal"
                    placeholder="Por ejemplo, 37008"
                    variant="outlined"
                    {...formik.getFieldProps("zipCode")}
                    error={Boolean(formik.touched.zipCode && formik.errors.zipCode)}
                    helperText={formik.touched.zipCode ? formik.errors.zipCode : ""}
                  />
                  
                  
                </div>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        
        <DialogActions className="p-4 bg-white-10">
          <Button
            className="btn-neutral-primary"
            onClick={() => toggleAddCompany()}
          >
            Cancelar
          </Button>{" "}
          <Button
            className="btn-primary ml-auto"
            type="submit"
            // onClick={() => {
            //   console.log(dbuser)
            //   let dataInput = {
            //     name: name,
            //     legalName: legalName,
            //     email: email,
            //     nif: nif,
            //     payment: payment,
            //     numberTotalUsers: numberTotalUsers,
            //     numberUsersToRegister: numberUsersToRegister,
            //     offices: {
            //       name: nameOffice,
            //       address: address,
            //       country: country,
            //       city: city,
            //       province: province,
            //       zipCode: zipCode
            //     }
                

            //   };
            //   console.log(dataInput)
            //   // newUser({variables:{input:dataInput}});
            //   // setValues({
            //   //   'name': '',
            //   //   'dni': '',
            //   //   'email': '',

            //   // })
            //   toggleAddCompany();
            // }}
          >
            Guardar
          </Button>
        </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
