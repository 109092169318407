import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Table,
  Grid,
  InputLabel,
  InputAdornment,
  Checkbox,
  Card,
  Menu,
  MenuItem,
  Button,
  List,
  ListItem,
  TextField,
  FormControl,
  Select,
  Avatar,
  Tooltip,
} from "@material-ui/core";


import FilterListTwoToneIcon from "@material-ui/icons/FilterListTwoTone";

import ModalViewVariable from "./ModalViewVariable"

export default function TableClimindNatures({ arrayNatures, filtersInput }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorElFilter, setAnchorElFilter] = useState(null);

  const handleClickFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorElFilter(null);
  };

  const [searchOpen, setSearchOpen] = useState(false);

  const openSearch = () => setSearchOpen(true);
  const closeSearch = () => setSearchOpen(false);

  const [status, setStatus] = useState("");

  const handleStatus = (event) => {
    setStatus(event.target.value);
  };

  const [naturesList, setNaturesList] = useState([]);
  const [naturesListFilter, setNaturesListFilter] = useState([]);
  const [variablesArrayRadar, setVariablesArrayRadar] = useState([]);
  const [variableSelected, setVariableSelected] = useState("");
  const [modalViewVariable, setModalViewVariable] = useState(false);
  const toggleViewVariable = () => setModalViewVariable(!modalViewVariable);

  let uniqueNatures = [];
  let naturesArrayTable = [];
  let variablesArrayRadarTmp = [];

  let variablesSubsSytems = [{
    name: 'Subsistema individual',
    value: ["personales", "auto-realizacion", "actualizacion digital"]
  }, {
    name: 'Subsistema interpersonal',
    value: ["vinculo relacional", "capital relacional"]
  }, {
    name: 'Subsistema organizacional',
    value: ["ecología", "identidad organizativa"]
  }]

  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  useEffect(() => {
    if (arrayNatures != null) {
      //Obtener naturalezas
      arrayNatures.map((subsystem, sindex) => {
        subsystem.items.map((variable, vindex) => {
          let itemVariable = {};
          itemVariable.name = variable.name;
          itemVariable.kpi = variable.value / variable.numberAnswers;
          variablesArrayRadarTmp.push(itemVariable);
          variable.items.map((nature, nindex) => {
            let itemNature = {};
            itemNature.id = nature.id;
            itemNature.variable = variable.name;
            itemNature.name = nature.name;
            itemNature.description = nature.description;
            itemNature.value = nature.value;
            itemNature.numberAnswers = nature.numberAnswers;
            itemNature.numberUsers = nature.numberUsers;
            itemNature.kpi = nature.value / nature.numberAnswers;
            itemNature.bosses = nature.badWithBosses / nature.numberAnswers;
            itemNature.clients = nature.badWithClients / nature.numberAnswers;
            itemNature.departments =
              nature.badWithDepartments / nature.numberAnswers;
            itemNature.partners = nature.badWithPartners / nature.numberAnswers;
            naturesArrayTable.push(itemNature);
          });
        });
      });
      setNaturesList(naturesArrayTable)
      setNaturesListFilter(naturesArrayTable)
      setVariablesArrayRadar(variablesArrayRadarTmp)
    }
  }, [arrayNatures])


  const kpiIndicator = (dataPercent) => {
    if (!dataPercent) {
      return (
        <div className="text-left font-size-lg text-center">
          Sin datos
        </div>
      )
    }
    if (dataPercent === 0) {
      return (
        <div className="text-left font-size-lg text-center">
          {/* <small className="text-black-50 d-block">(>30%)</small>
          <div className="px-4 py-1 h-auto text-danger border-1 border-danger badge badge-neutral-danger">
            Critico
          </div> */}
          <div className="avatar-icon-wrapper avatar-initials avatar-icon-lg">
            <Tooltip
              classes={{
                tooltip: "text-center p-3 tooltip-primary",
              }}
              arrow
              placement="top"
              title="0 de 2 personas han marcado este punto de vista"
            >
              <div className="avatar-icon text-white bg-dark">
                {Math.round(dataPercent * 100) + "%"}
              </div>
            </Tooltip>
          </div>
        </div>
      );
    } else if (dataPercent < 0.15 && dataPercent > 0) {
      return (
        <div className="text-left font-size-lg text-center">
          {/* <small className="text-black-50 d-block">(>30%)</small>
          <div className="px-4 py-1 h-auto text-danger border-1 border-danger badge badge-neutral-danger">
            Critico
          </div> */}
          <div className="avatar-icon-wrapper avatar-initials avatar-icon-lg">
            <div className="avatar-icon text-white bg-success">
              {Math.round(dataPercent * 100) + "%"}
            </div>
          </div>
        </div>
      );
    } else if (dataPercent >= 0.15 && dataPercent < 0.3) {
      return (
        <div className="text-left font-size-lg text-center">
          {/* <small className="text-black-50 d-block">(>30%)</small>
          <div className="px-4 py-1 h-auto text-warning border-1 border-warning badge badge-neutral-warning">
            Atender
          </div> */}
          <div className="avatar-icon-wrapper avatar-initials avatar-icon-lg">
            <div className="avatar-icon text-white bg-warning">
              {Math.round(dataPercent * 100) + "%"}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="text-left font-size-lg text-center">
          {/* <small className="text-black-50 d-block">(>30%)</small>
          <div className="px-4 py-1 h-auto text-success border-1 border-success badge badge-neutral-success">
            Mantener
          </div> */}
          <div className="avatar-icon-wrapper avatar-initials avatar-icon-lg">
            <div className="avatar-icon text-white bg-danger">
              {Math.round(dataPercent * 100) + "%"}
            </div>
          </div>
        </div>
      );
    }
  };
  const valueIndicator = (value) => {
    if (!value) {
      return (
        <div className="text-center font-size-lg">

          Sin datos

        </div>
      )
    }
    if (value < 4) {
      return (
        <div className="text-center font-size-lg">
          {/* <small className="text-black-50 d-block text-danger">Valor</small> */}
          <span className="text-danger">{value}</span>
        </div>
      );
    } else if (value >= 4 && value < 7) {
      return (
        <div className="text-center font-size-lg">
          {/* <small className="text-black-50 d-block text-warning">Valor</small> */}
          <span className="text-warning">{value}</span>
        </div>
      );
    } else {
      return (
        <div className="text-center font-size-lg">
          {/* <small className="text-black-50 d-block text-success">Valor</small> */}
          <span className="text-success">{value}</span>
        </div>
      );
    }
  };

  Array.prototype.findIndexBy = function (key, value) {
    return this.findIndex(item => item[key] === value)
  }

  const handleFilterVariables = () => {
    let indexSubsystem = variablesSubsSytems.findIndexBy('name', status)
    console.log(variablesSubsSytems[indexSubsystem].value);
    console.log(naturesList);
    let filterNatures = naturesList.filter((el) => variablesSubsSytems[indexSubsystem].value.indexOf(el.variable) !== -1)
    console.log(filterNatures);
    //let filterNatures = naturesList.filter((el) => el.variable === status)
    // console.log(filterNatures)
    setNaturesListFilter(filterNatures)
    handleCloseFilter()
  }

  const handleCleanFilters = (event) => {
    setNaturesListFilter(naturesList)
    handleCloseFilter()
  };
  // console.log(naturesListFilter);

  return (
    <>
      <Card className="card-box shadow-none mb-5">
        <div className="d-flex justify-content-between px-4 py-3">
          <div className="d-flex align-items-center">
            <div>
              <Button
                onClick={handleClickFilter}
                variant="text"
                className="btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill"
              >
                <FilterListTwoToneIcon className="w-50" />
              </Button>
              <Menu
                anchorEl={anchorElFilter}
                keepMounted
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElFilter)}
                classes={{ list: "p-0" }}
                onClose={handleCloseFilter}
              >
                <div className="dropdown-menu-xxl overflow-hidden p-0">
                  <div className="p-3">
                    <Grid container spacing={6}>
                      <Grid item md={12}>
                        <FormControl variant="outlined" className="w-100">
                          <InputLabel id="demo-simple-select-outlined-label">
                            Variables
                          </InputLabel>
                          <Select
                            fullWidth
                            label="Dimensiones"
                            value={status}
                            onChange={handleStatus}
                          >
                            {/* {
                              variablesArrayRadar.map((e, index) => (
                                <MenuItem key={e.name} value={e.name}>{e.name}</MenuItem>
                              ))
                            } */}
                            {
                              variablesSubsSytems.map((e, index) => (
                                <MenuItem key={e.name} value={e.name}>{e.name}</MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="divider" />
                  <div className="p-3 text-center bg-secondary">
                    <Button className="btn-primary mr-2" size="small" onClick={handleFilterVariables}>
                      Resultados Filtrados
                    </Button>
                    <Button className="btn-primary ml-2" size="small" onClick={handleCleanFilters}>
                      Quitar Filtros
                    </Button>
                  </div>
                </div>
              </Menu>
            </div>
            <div className="card-tr-actions">
              <Tooltip
                classes={{
                  tooltip: "text-center p-3 tooltip-secondary",
                }}
                arrow
                placement="top"
                title='Los datos corresponden al n.º de usuarios que han contestado a cada naturaleza de Clima, en el marco de los "filtros" establecidos, con un valor promedio de todas las contestaciones aportadas en la escala Lickert, entre 1 y 10. Aquellos usuarios que puntuaron en la pregunta de clima con valor menor a 6, consideran que el problema está entre compañeros, con superiores, entre departamentos, y/o con clientes/proveedores, en el porcentaje indicado. Si la intensidad de respuesta es 6 o mayor que 6, no se lanza la pregunta de detalle, y no se recogen datos.'
              >
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="text-first font-weight-bold"
                >
                  <div
                    className=" avatar-icon rounded-circle mr-2"
                    style={{ width: 26, height: 26 }}
                  >
                    <img
                      alt="..."
                      src={require("../../../../../../assets/images/climind/info.png")}
                    />
                  </div>
                </a>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="divider" />
        <div className="p-4">
          <div className="table-responsive-md">
            <Table className="table table-alternate-spaced mb-0">
              <thead>
                <tr>
                  <th
                    style={{ width: "110px" }}
                    className="font-size-lg font-weight-normal text-capitalize text-dark text-center"
                    scope="col"
                  >
                    Variables
                  </th>
                  <th
                    style={{ width: "300px" }}
                    className="font-size-lg font-weight-normal text-capitalize text-dark text-center"
                    scope="col"
                  >
                    Naturalezas
                  </th>
                  <th
                    className="font-size-lg font-weight-normal text-capitalize text-dark text-center"
                    scope="col"
                  >
                    Info
                  </th>
                  <th
                    className="font-size-lg font-weight-normal text-capitalize text-dark text-center"
                    scope="col"
                  >
                    Nº Respuestas
                  </th>
                  <th
                    className="font-size-lg font-weight-normal text-capitalize text-dark text-center"
                    scope="col"
                  >
                    Nº Usuarios
                  </th>
                  <th
                    className="font-size-lg font-weight-normal text-capitalize text-dark text-center"
                    scope="col"
                  >
                    Valor
                  </th>
                  <th
                    className="font-size-lg font-weight-normal text-capitalize text-dark text-center"
                    scope="col"
                  >
                    Entre compañeros
                  </th>
                  <th
                    className="font-size-lg font-weight-normal text-capitalize text-dark text-center"
                    scope="col"
                  >
                    Con superiores
                  </th>
                  <th
                    className="font-size-lg font-weight-normal text-capitalize text-dark text-center"
                    scope="col"
                  >
                    Entre departamentos
                  </th>
                  <th
                    className="font-size-lg font-weight-normal text-capitalize text-dark text-center"
                    scope="col"
                  >
                    Clientes/Proveedores
                  </th>
                  <th
                    className="font-size-lg font-weight-normal text-capitalize text-dark text-center"
                    scope="col"
                  >
                    Dimensiones
                  </th>
                </tr>
              </thead>
              <tbody>
                {naturesListFilter &&
                  naturesListFilter.map((nature, index) => {
                    let valueI = valueIndicator(nature.value ? nature.value.toFixed(2) : 0);
                    let partnersIndicator = kpiIndicator(nature.partners);
                    let bossesIndicator = kpiIndicator(nature.bosses);
                    let departmentsIndicator = kpiIndicator(nature.departments);
                    let clientsIndicator = kpiIndicator(nature.clients);
                    let variable = nature.variable;
                    uniqueNatures.indexOf(variable) === -1 ? uniqueNatures.push(variable) : console.log("ya existe");
                    return (
                      <>
                        <tr key={index}>
                          <td className="text-center">
                            <span className="font-weight-bold">
                              {capitalize(nature.variable)}
                            </span>
                          </td>
                          <td className="text-center">
                            <span className=" font-weight-bold">
                              {capitalize(nature.name)}
                            </span>
                          </td>
                          <td>
                            <div className="text-center"><Tooltip
                              classes={{
                                tooltip: "text-center p-3 tooltip-primary",
                              }}
                              arrow
                              placement="bottom"
                              title={capitalize(nature.description)}
                            >
                              <Button
                                variant="text"
                                className="p-0 d-30 border-0 btn-transition-none text-dark"
                                disableRipple
                              >
                                <FontAwesomeIcon
                                  icon={["far", "question-circle"]}
                                  className="font-size-lg"
                                />
                              </Button>
                            </Tooltip>
                            </div>
                          </td>
                          <td><div className="text-center font-size-lg">{nature.numberAnswers}</div></td>
                          <td><div className="text-center font-size-lg">{nature.numberUsers}</div></td>
                          <td>{valueI}</td>
                          <td>{partnersIndicator}</td>
                          <td>{bossesIndicator}</td>
                          <td>{departmentsIndicator}</td>
                          <td>{clientsIndicator}</td>
                          <td><div className="text-center"><Tooltip title="Ver detalle" arrow placement="top">
                            <Button
                              size="small"
                              className="btn-link d-30 p-0 btn-icon hover-scale-sm"
                              onClick={() => {
                                setVariableSelected(nature);
                                setModalViewVariable(true);
                              }}
                            >
                              <FontAwesomeIcon
                                icon={["far", "eye"]}
                                className="font-size-lg"
                              />
                            </Button>
                          </Tooltip></div></td>
                        </tr>
                        <tr className="divider"></tr>
                      </>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </div>
      </Card>
      {modalViewVariable &&
        <ModalViewVariable
          openViewVariable={modalViewVariable}
          toggleViewVariable={toggleViewVariable}
          dataNature={variableSelected}
          filtersInput={filtersInput}
        />}
    </>
  );
}
