import React, { useState, useCallback, useContext } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Table,
  LinearProgress,
  Card,
  CardContent,
  Button,
  Tooltip,
  Dialog
} from '@material-ui/core';
import { UserContext } from "../../../context/UserContext";
import { GET_USER_WITH_GROUPS } from "data/queries";
import { DELETE_OKR} from "data/mutations";


import ModalKeyResults from "./ModalKeyResults";
var moment = require("moment-timezone")
export default function DashboardOkr1({ dataOkr, refetch }) {
  console.log(dataOkr);
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  const { loading:loadingGroups, error:errorGroups, data:dataGroups, networkStatus:networkStatusGroups } = useQuery(
    GET_USER_WITH_GROUPS,
    {
      variables: { userId: dbuser.getUserByUid.id },
      skip:!dbuser.getUserByUid.id,
      notifyOnNetworkStatusChange: true,
    }
  );
console.log(dataGroups)
  
  const [okrSelected, setOkrSelected] = useState(null);
  const [modalNewOkr, setModalNewOkr] = useState(false);
  const toggleNewOkr = () => setModalNewOkr(!modalNewOkr);
  const [modalEditOkr, setModalEditOkr] = useState(false);
  const toggleEditOkr = () => setModalEditOkr(!modalEditOkr);
  const [modalKeyResults, setModalKeyResults] = useState(false);
  const toggleKeyResults = () => setModalKeyResults(!modalKeyResults);
  const [modalDelete, setModalDelete] = useState(false);
  const toggleDelete = () => setModalDelete(!modalDelete);
  const [deleteOkr, { data: dataDelete }] = useMutation(DELETE_OKR);
  if(dataGroups){
  return (
    <>
      <Card className="card-box mb-spacing-6-x2">
        <div className="card-header pr-2">
          <div className="card-header--title">Lista de OKR's</div>
          
        </div>
        <CardContent>
          <div className="table-responsive-md">
            <Table className="table table-borderless table-hover text-nowrap mb-0">
              <thead>
                <tr>
                  {/* <th>#</th> */}
                  <th className="text-left">Objetivo</th>
                  <th className="text-center">Duración</th>
                  <th className="text-center">Destinatarios</th>
                  <th className="text-center">Ver resultados clave</th>
                </tr>
              </thead>
              <tbody>
                {
                  dataOkr.map(el => {
                    
                    let groupsAndDepartmentNames = ""
                  
                    el.groups.map(e =>{
                      groupsAndDepartmentNames = groupsAndDepartmentNames + e.name + ", "
                    })
                    el.departments.map(e =>{
                      groupsAndDepartmentNames = groupsAndDepartmentNames + e.name + ", "
                    })
                    return(
                    
                    <tr>
                      <td className="text-left">{el.objective.substring(0,60)}...</td>
                      <td className="text-center">{moment(el.initDate).format('DD/MM/YYYY')} - {moment(el.endDate).format('DD/MM/YYYY')}</td>
                      <td className="text-center">{el.forAllCompany ? "Toda la empresa" : groupsAndDepartmentNames}</td>
                        <td className="text-center"><Tooltip title="Ver resultados clave" arrow placement="top">
                          <Button
                            size="small"
                            className="btn-link d-30 p-0 btn-icon hover-scale-sm"
                            onClick={() => {
                              setOkrSelected(el);
                              setModalKeyResults(true);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={["far", "eye"]}
                              className="font-size-lg"
                            />
                          </Button>
                        </Tooltip></td>
                      
                    </tr>
                  )})
                }
                    {/* <div className="badge badge-neutral-danger text-danger px-4">
                      Failed
                    </div>
                    <div className="badge badge-neutral-success text-success px-4">
                      Completed
                    </div> */}
              </tbody>
            </Table>
          </div>
        </CardContent>
        <div className="card-footer d-flex justify-content-between">
          {/* <div>
            <Button size="small" color="primary" variant="contained">
              Agregar nuevo OKR
            </Button>
          </div> */}
        </div>
      </Card>

      {okrSelected && <ModalKeyResults
        okrSelected={okrSelected}
        setOkrSelected={setOkrSelected}
        modalKeyResults={modalKeyResults}
        toggleKeyResults={toggleKeyResults}
        refetch={refetch}
      />}

    </>
  )
      }else{
        return null
      }
}
