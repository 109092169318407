import React, { useEffect, useState, useContext, useCallback } from "react";

import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {

  Card,
  CardContent,
  Button,

  Switch,
  Snackbar
} from "@material-ui/core";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";



import emotion from "../../../../assets/images/climind/reaction.png";
import recognition from  "../../../../assets/images/climind/thumbs-up.png";
import pregunta from "../../../../assets/images/climind/faq.svg";

import { UserContext } from "../../../../context/UserContext";
import { CHANGE_PRIVACITY } from "data/mutations";
import { GET_USER_BY_UID, GET_TIMELINE } from "data/queries";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
  } from '@material-ui/pickers';
  import 'date-fns';
  import DateFnsUtils from '@date-io/date-fns';


  import MuiAlert from "@material-ui/lab/Alert";
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

export default function Privacity() {

  const { currentUser, dbuser, getUserWithFirebase } = useContext(UserContext);
  const [checkAdminQuestion, setCheckAdminQuestion] = useState(dbuser.getUserByUid.commentaryQuestionForAdmins);
  const [checkTimelineQuestion, setCheckTimelineQuestion] = useState(dbuser.getUserByUid.commentaryQuestionInTimeline);
  const [checkAdminEmotion, setCheckAdminEmotion] = useState(dbuser.getUserByUid.commentaryEmotionForAdmins);
  const [checkTimelineEmotion, setCheckTimelineEmotion] = useState(dbuser.getUserByUid.commentaryEmotionInTimeline);
  const [checkRecognition, setCheckRecognition] = useState(dbuser.getUserByUid.recognitionInTimeline);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [
    changePrivacity,
    { data: data, error: error, loading: loading },
  ] = useMutation(CHANGE_PRIVACITY);

  const handleChangeCheckTimelineQuestion = useCallback((e) => {
    setCheckTimelineQuestion(!checkTimelineQuestion);
  });
  const handleChangeCheckAdminQuestion = useCallback((e) => {
    setCheckAdminQuestion(!checkAdminQuestion);
  });
  const handleChangeCheckTimelineEmotion = useCallback((e) => {
    setCheckTimelineEmotion(!checkTimelineEmotion);
  });
  const handleChangeCheckAdminEmotion = useCallback((e) => {
    setCheckAdminEmotion(!checkAdminEmotion);
  });
  const handleChangeCheckRecognition = useCallback((e) => {
    setCheckRecognition(!checkRecognition);
  });

  async function onClickButton() {

    let dataInput = {
      commentaryQuestionInTimeline: checkTimelineQuestion,
      commentaryQuestionForAdmins: checkAdminQuestion,
      commentaryEmotionInTimeline: checkTimelineEmotion,
      commentaryEmotionForAdmins: checkAdminEmotion,
      recognitionInTimeline: checkRecognition
    };
    console.log(dataInput);

    let uid = dbuser.getUserByUid.uid;
    let id = dbuser.getUserByUid.id;
    let response = await changePrivacity({
      variables: { id: id, input: dataInput },
      refetchQueries: [
        { query: GET_USER_BY_UID, variables: { uid, uid } }
      ],
    });
    console.log(response);
    getUserWithFirebase()
    setOpenSnackbar(true, () => {
      setTimeout(function () {
        setOpenSnackbar(false);
      }, 5000); //5 Second delay
    });

    if (response.data.saveAnswerEmotion) {
      console.log("guardado correctamente");
    }
  }
return(
  <>
  <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        key={"top,center"}
        open={openSnackbar}
        // classes={{ root: "toastr-primary" }}
        autoHideDuration={4000}
        onClose={() => setOpenSnackbar(false)}
        message={"Se han actualizado tu preferencias de privacidad"}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          style={{ color: "white" }}
        >
          Se han actualizado tu preferencias de privacidad
        </Alert>
      </Snackbar>
    <Card className="card-box">
        <div className="card-header">
          <h4 className="font-size-lg mb-0 py-2 font-weight-bold">
            Configuración de accesos y funcionalidades de la empresa
          </h4>
        </div>
        <CardContent>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex">
            
              <div>
                <b>Pulso</b>
                {/* <div className="text-black-50">Mostrar los comentarios de cómo fue mi jornada en el timeline</div> */}
                {checkTimelineEmotion ? 
                <div ><a style={{ color: "red"}}>activado</a></div>
                :
                <div ><a style={{ color: "green"}}>desactivado</a></div>
                }
              </div>
            </div>
            
            <div className="font-weight-bold text-warning font-size-lg">
              
            <div className="d-flex flex-row m-2">
              <div className="mr-2">
            <h6>Activar / Desactivar</h6>
            </div>
              <Switch
                onChange={handleChangeCheckTimelineEmotion}
                checked={checkTimelineEmotion}
                color="primary"
                name="checkedA"
                className="switch-small"
              />
            </div>
            </div>
          </div>
          <div className="divider my-3" />

          <div className="divider my-3" />
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex">
            
              <div>
                <b>Clima</b>
                {/* <div className="text-black-50">Mostrar los comentarios de la pregunta de clima en el timeline</div> */}
                {checkTimelineQuestion ? 
                <div ><a style={{ color: "red"}}>activado</a></div>
                :
                <div ><a style={{ color: "green"}}>desactivado</a></div>
                }
              </div>
            </div>
            <div className="font-weight-bold text-warning font-size-lg">
            <div className="d-flex flex-row m-2">
              <div className="mr-2">
            <h6>Activar / Desactivar</h6>
            </div>
              <Switch
                onChange={handleChangeCheckTimelineQuestion}
                checked={checkTimelineQuestion}
                color="primary"
                name="checkedA"
                className="switch-small"
              />
            </div>
            </div>
          </div>
          <div className="divider my-3" />
          
          <div className="divider my-3" />
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex">
           
              <div>
                <b>Timeline</b>
                {/* <div className="text-black-50">Mostrar los Reconocimientos que envío en el Timeline</div> */}
                {checkRecognition ? 
                <div ><a style={{ color: "red"}}>activado</a></div>
                :
                <div ><a style={{ color: "green"}}>desactivado</a></div>
                }
              </div>
            </div>
            <div className="font-weight-bold text-warning font-size-lg">
            <div className="d-flex flex-row m-2">
              <div className="mr-2">
            <h6>Activar / Desactivar</h6>
            </div>
              <Switch
                onChange={handleChangeCheckRecognition}
                checked={checkRecognition}
                color="primary"
                name="checkedA"
                className="switch-small"
              />
            </div>
            </div>
          </div>

          <div className="divider my-3" />
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex">
            
              <div>
                <b>Reconocimientos</b>
                {/* <div className="text-black-50">Mostrar los Reconocimientos que envío en el Timeline</div> */}
                {checkRecognition ? 
                <div ><a style={{ color: "red"}}>activado</a></div>
                :
                <div ><a style={{ color: "green"}}>desactivado</a></div>
                }
              </div>
            </div>
            <div className="font-weight-bold text-warning font-size-lg">
            <div className="d-flex flex-row m-2">
              <div className="mr-2">
            <h6>Activar / Desactivar</h6>
            </div>
              <Switch
                onChange={handleChangeCheckRecognition}
                checked={checkRecognition}
                color="primary"
                name="checkedA"
                className="switch-small"
              />
            </div>
            </div>
          </div>

          <div className="divider my-3" />
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex">
            
              <div>
                <b>Felicitaciones</b>
                {/* <div className="text-black-50">Mostrar los Reconocimientos que envío en el Timeline</div> */}
                {checkRecognition ? 
                <div ><a style={{ color: "red"}}>activado</a></div>
                :
                <div ><a style={{ color: "green"}}>desactivado</a></div>
                }
              </div>
            </div>
            <div className="font-weight-bold text-warning font-size-lg">
            <div className="d-flex flex-row m-2">
              <div className="mr-2">
            <h6>Activar / Desactivar</h6>
            </div>
              <Switch
                onChange={handleChangeCheckRecognition}
                checked={checkRecognition}
                color="primary"
                name="checkedA"
                className="switch-small"
              />
            </div>
            </div>
          </div>

          <div className="divider my-3" />
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex">
            
              <div>
                <b>Agradecimientos</b>
                {/* <div className="text-black-50">Mostrar los Reconocimientos que envío en el Timeline</div> */}
                {checkRecognition ? 
                <div ><a style={{ color: "red"}}>activado</a></div>
                :
                <div ><a style={{ color: "green"}}>desactivado</a></div>
                }
              </div>
            </div>
            <div className="font-weight-bold text-warning font-size-lg">
            <div className="d-flex flex-row m-2">
              <div className="mr-2">
            <h6>Activar / Desactivar</h6>
            </div>
              <Switch
                onChange={handleChangeCheckRecognition}
                checked={checkRecognition}
                color="primary"
                name="checkedA"
                className="switch-small"
              />
            </div>
            </div>
          </div>

          <div className="divider my-3" />
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex">
            
              <div>
                <b>Propuestas de mejora</b>
                {/* <div className="text-black-50">Mostrar los Reconocimientos que envío en el Timeline</div> */}
                {checkRecognition ? 
                <div ><a style={{ color: "red"}}>activado</a></div>
                :
                <div ><a style={{ color: "green"}}>desactivado</a></div>
                }
              </div>
            </div>
            <div className="font-weight-bold text-warning font-size-lg">
            <div className="d-flex flex-row m-2">
              <div className="mr-2">
            <h6>Activar / Desactivar</h6>
            </div>
              <Switch
                onChange={handleChangeCheckRecognition}
                checked={checkRecognition}
                color="primary"
                name="checkedA"
                className="switch-small"
              />
            </div>
            </div>
          </div>

          <div className="divider my-3" />
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex">
            
              <div>
                <b>Centro de bienestar</b>
                {/* <div className="text-black-50">Mostrar los Reconocimientos que envío en el Timeline</div> */}
                {checkRecognition ? 
                <div ><a style={{ color: "red"}}>activado</a></div>
                :
                <div ><a style={{ color: "green"}}>desactivado</a></div>
                }
              </div>
            </div>
            <div className="font-weight-bold text-warning font-size-lg">
            <div className="d-flex flex-row m-2">
              <div className="mr-2">
            <h6>Activar / Desactivar</h6>
            </div>
              <Switch
                onChange={handleChangeCheckRecognition}
                checked={checkRecognition}
                color="primary"
                name="checkedA"
                className="switch-small"
              />
            </div>
            </div>
          </div>

          <div className="divider my-3" />
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex">
            
              <div>
                <b>Retos</b>
                {/* <div className="text-black-50">Mostrar los Reconocimientos que envío en el Timeline</div> */}
                {checkRecognition ? 
                <div ><a style={{ color: "red"}}>activado</a></div>
                :
                <div ><a style={{ color: "green"}}>desactivado</a></div>
                }
              </div>
            </div>
            <div className="font-weight-bold text-warning font-size-lg">
            <div className="d-flex flex-row m-2">
              <div className="mr-2">
            <h6>Activar / Desactivar</h6>
            </div>
              <Switch
                onChange={handleChangeCheckRecognition}
                checked={checkRecognition}
                color="primary"
                name="checkedA"
                className="switch-small"
              />
            </div>
            </div>
          </div>

          <div className="divider my-3" />
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex">
            
              <div>
                <b>OKR's</b>
                {/* <div className="text-black-50">Mostrar los Reconocimientos que envío en el Timeline</div> */}
                {checkRecognition ? 
                <div ><a style={{ color: "red"}}>activado</a></div>
                :
                <div ><a style={{ color: "green"}}>desactivado</a></div>
                }
              </div>
            </div>
            <div className="font-weight-bold text-warning font-size-lg">
            <div className="d-flex flex-row m-2">
              <div className="mr-2">
            <h6>Activar / Desactivar</h6>
            </div>
              <Switch
                onChange={handleChangeCheckRecognition}
                checked={checkRecognition}
                color="primary"
                name="checkedA"
                className="switch-small"
              />
            </div>
            </div>
          </div>

          <div className="divider my-3" />
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex">
            
              <div>
                <b>Ranking</b>
                {/* <div className="text-black-50">Mostrar los Reconocimientos que envío en el Timeline</div> */}
                {checkRecognition ? 
                <div ><a style={{ color: "red"}}>activado</a></div>
                :
                <div ><a style={{ color: "green"}}>desactivado</a></div>
                }
              </div>
            </div>
            <div className="font-weight-bold text-warning font-size-lg">
            <div className="d-flex flex-row m-2">
              <div className="mr-2">
            <h6>Activar / Desactivar</h6>
            </div>
              <Switch
                onChange={handleChangeCheckRecognition}
                checked={checkRecognition}
                color="primary"
                name="checkedA"
                className="switch-small"
              />
            </div>
            </div>
          </div>
        </CardContent>
        <div className="pt-4 text-center">
              <Button
                onClick={onClickButton}
                className="btn-warning font-weight-bold rounded hover-scale-lg mx-1 mb-3"
                size="large"
                // component={Link} to="/homeuser/"
                >
                <span className="btn-wrapper--label">Actualizar</span>
              </Button>
            </div>
      </Card>

     </>       
)}