import React, { useState, useCallback, useContext } from "react";
import { GET_COMPANIES } from "data/queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "../../../context/UserContext";
import { useQuery } from "@apollo/react-hooks";
import { PageTitle } from "layout-components";
import ModalAddCompany from "./components/ModalAddCompany";
import {
  Table,
  LinearProgress,
  Card,
  CardContent,
  Button,
  Tooltip,
  Dialog,
  Grid,
} from "@material-ui/core";

import {Link} from 'react-router-dom'

import stock1 from "../../../assets/images/stock-photos/stock-1.jpg";
import stock2 from "../../../assets/images/stock-photos/stock-2.jpg";
import stock3 from "../../../assets/images/stock-photos/stock-3.jpg";
import avatar5 from "../../../assets/images/avatars/avatar5.jpg";
import defaultAvatar from "../../../assets/images/climind/default_avatar.png";

export default function Companies() {
    const [modalAddCompany, setModalAddCompany] = useState(false);
    const toggleAddCompany = () => setModalAddCompany(!modalAddCompany);
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  const {loading: loadingCompanies,error: errorCompanies,data: dataCompanies,networkStatus: networkStatusCompanies, refetch: refetchCompanies} = useQuery(GET_COMPANIES,
    {
    notifyOnNetworkStatusChange: true,
  })
//   const onSelectCompetence = useCallback((competence) => {
//     setCompetenceSelected(competence);
//     toggleCompetence();
//   });
  if (dataCompanies) {
    return (
      <>
      <PageTitle
        titleHeading="Empresas"
        titleDescription="Administra las empresas de Climind"
      />
        <Card className="card-box">
          <div className="text-center p-5 mb-3">
            <h4 className="text-center mb-4">
              Empresas
            </h4>
            <div className="mb-20">
              <Button size="small" className="btn-neutral-primary" onClick={()=>setModalAddCompany(true)}>
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={["fas", "plus-circle"]} />
                </span>
                <span className="btn-wrapper--label">Nueva empresa</span>
              </Button>
            </div>
            <div className="mb-spacing-6 mt-5">
              <Grid container spacing={6}>
              {dataCompanies.getCompanies.map((element, index) => {
                console.log(element)
                console.log(element.id)
                return(
                <Grid item xl={2} md={4} xs={6}>
                  <Card className="shadow-xxl" style={{borderRadius:"12px" , border: `solid 4px ${element.color}`}}>
                    <a
                      href="#/"
                      onClick={(e) => e.preventDefault()}
                      className="card-img-wrapper rounded"
                    >
                      <div className="img-wrapper-overlay" >
                        <div className="overlay-btn-wrapper">
                          <div className="font-size-lg font-weight-bold mb-2">
                            {element.legalName}
                          </div>
                          <Link 
                          to={{
                            pathname:`/company/${element.id}`
                          }}>
                          <Button
                            size="small"
                            className="btn-success btn-pill px-4 hover-scale-sm mb-2"
                            // onClick={}
                          >
                            <span className="btn-wrapper--icon">
                              <FontAwesomeIcon icon={["fas", "check"]} />
                            </span>
                            <span className="btn-wrapper--label">
                              Ver Información
                            </span>
                          </Button>
                          </Link>
                          {/* <Button
                            size="small"
                            className="btn-danger btn-pill px-4 hover-scale-sm"
                          >
                            <span className="btn-wrapper--icon">
                              <FontAwesomeIcon icon={["fas", "info"]} />
                            </span>
                            <span className="btn-wrapper--label">
                              Eliminar
                            </span>
                          </Button>
                          <Button
                            size="small"
                            className="btn-warning btn-pill px-4 hover-scale-sm"
                          >
                            <span className="btn-wrapper--icon">
                              <FontAwesomeIcon icon={["fas", "info"]} />
                            </span>
                            <span className="btn-wrapper--label">
                              Desactivar
                            </span>
                          </Button> */}
                        </div>
                      </div>
                      {/* <div className="card-badges">
                        <div className="badge badge-pill badge-neutral-info text-info">
                          Información
                        </div>
                      </div> */}
                      <img
                        src={require("../../../assets/images/climind/empresa.svg")}
                        className="card-img-top rounded"
                        alt="..."
                      />
                    </a>
                    
                  </Card>
                  <h5 className="text-center font-weight-bold mt-4">
                  {element.name}
                  </h5>

                  
                </Grid>
                )
            })}
              </Grid>
            </div>
          </div>
          </Card>
          <ModalAddCompany modalAddCompany={modalAddCompany} toggleAddCompany={toggleAddCompany}/>
      </>
    );
  } else {
    return null;
  }
}