import React, { useState, useEffect, useCallback, useContext } from "react";
// import ModalSelectUser from "./ModalSelectUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import defaultAvatar from "../../../assets/images/climind/default_avatar.png";
import {
  Dialog,
  Button,
  Card,
  TextField,
  Menu,
  Snackbar,
  Switch,
  Grid,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Input,
  MenuItem,
  Select,
  Chip,
  Collapse,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { UserContext } from "../../../../../../context/UserContext";
import { GET_USER_BY_UID, GETS_FOR_GENERAL } from "data/queries";
import { NEW_OKR } from "data/mutations";

import challenge from "../../../../../../assets/images/climind/target.png";

import MuiAlert from "@material-ui/lab/Alert";
import {
  GET_DEPARTMENTS,
  GET_GROUPS,
  GET_CHALLENGES_BY_COMPANYID,
} from "data/queries";

import SnackBarMessage from "../../../../../../utils/SnackBarMessage";
import firebase from "firebase";

import clsx from "clsx";

var moment = require("moment-timezone");
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function valuetext(value) {
  return <span>{{ value }}°C</span>;
}

export default function ModalNewOkr({ modalNewOkr, toggleNewOkr, refetch }) {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  const [newOkr, { data: dataOkr, error: errorOkr, loading: loadingOkr }] = useMutation(NEW_OKR);

  const [objective, setObjective] = useState("");
  const [description, setDescription] = useState("");

  const [keyResultOneDescription, setKeyResultOneDescription] = useState("");
  const [keyResultOneUnit, setKeyResultOneUnit] = useState("PERCENTAGE");
  const [keyResultOnePercentage, setKeyResultOnePercentage] = useState(100);
  const [keyResultOneNumber, setKeyResultOneNumber] = useState(10);
  const [keyResultOneInitDate, setKeyResultOneInitDate] = useState("");
  const [keyResultOneEndDate, setKeyResultOneEndDate] = useState("");
  const [checkBooleanOne, setCheckBooleanOne] = useState(false);
  const [checkPercentageOne, setCheckPercentageOne] = useState(true);
  const [checkNumberOne, setCheckNumberOne] = useState(false);

  const [keyResultTwoDescription, setKeyResultTwoDescription] = useState("");
  const [keyResultTwoUnit, setKeyResultTwoUnit] = useState("PERCENTAGE");
  const [keyResultTwoPercentage, setKeyResultTwoPercentage] = useState(100);
  const [keyResultTwoNumber, setKeyResultTwoNumber] = useState(10);
  const [keyResultTwoInitDate, setKeyResultTwoInitDate] = useState("");
  const [keyResultTwoEndDate, setKeyResultTwoEndDate] = useState("");
  const [checkBooleanTwo, setCheckBooleanTwo] = useState(false);
  const [checkPercentageTwo, setCheckPercentageTwo] = useState(true);
  const [checkNumberTwo, setCheckNumberTwo] = useState(false);

  const [keyResultThreeDescription, setKeyResultThreeDescription] = useState("");
  const [keyResultThreeUnit, setKeyResultThreeUnit] = useState("PERCENTAGE");
  const [keyResultThreePercentage, setKeyResultThreePercentage] = useState(100);
  const [keyResultThreeNumber, setKeyResultThreeNumber] = useState(10);
  const [keyResultThreeInitDate, setKeyResultThreeInitDate] = useState("");
  const [keyResultThreeEndDate, setKeyResultThreeEndDate] = useState("");
  const [checkBooleanThree, setCheckBooleanThree] = useState(false);
  const [checkPercentageThree, setCheckPercentageThree] = useState(true);
  const [checkNumberThree, setCheckNumberThree] = useState(false);

  const [accordion1, setAccordion1] = useState(false);
  const [accordion2, setAccordion2] = useState(false);
  const [accordion3, setAccordion3] = useState(false);
  const [messageAlert, setMessageAlert] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [error, setError] = useState(false);
  const [initDate, setInitDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [actionProposal, setActionProposal] = useState(null);
  const [checkAllCompany, setCheckAllCompany] = useState(true);
  const [checkDepartmentsGroups, setCheckDepartmentsGroups] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [groups, setGroups] = useState([]);

  const [optionsDepartment, setOptionsDepartment] = useState([]);
  const [optionsGroup, setOptionsGroup] = useState([]);

  const {
    loading: loadingGroups,
    error: errorGroups,
    data: dataGroups,
    networkStatus: netGroups,
  } = useQuery(GET_GROUPS, {
    variables: { id: dbuser.getUserByUid.companyId },
    skip: !dbuser.getUserByUid.companyId,
    notifyOnNetworkStatusChange: true,
  });

  const {
    loading: loadingDepartments,
    error: errorDepartments,
    data: dataDepartments,
    networkStatus: netDepartments,
  } = useQuery(GET_DEPARTMENTS, {
    variables: { id: dbuser.getUserByUid.companyId },
    skip: !dbuser.getUserByUid.companyId,
    notifyOnNetworkStatusChange: true,
  });

  const onChangeNumberOne = (e) => {
    const re = /^[0-9\b]+$/;
    console.log(e);
    if (e.target.value === "" || re.test(e.target.value)) {
      setKeyResultOneNumber(e.target.value);
    }
  };
  const onChangePercentageOne = (e) => {
    const re = /^[0-9\b]+$/;

    if (e.target.value === "" || re.test(e.target.value)) {
      setKeyResultOnePercentage(e.target.value);
    }
  };
  const onChangeNumberTwo = (e) => {
    const re = /^[0-9\b]+$/;
    console.log(e);
    if (e.target.value === "" || re.test(e.target.value)) {
      setKeyResultTwoNumber(e.target.value);
    }
  };
  const onChangePercentageTwo = (e) => {
    const re = /^[0-9\b]+$/;

    if (e.target.value === "" || re.test(e.target.value)) {
      setKeyResultTwoPercentage(e.target.value);
    }
  };
  const onChangeNumberThree = (e) => {
    const re = /^[0-9\b]+$/;
    console.log(e);
    if (e.target.value === "" || re.test(e.target.value)) {
      setKeyResultThreeNumber(e.target.value);
    }
  };
  const onChangePercentageThree = (e) => {
    const re = /^[0-9\b]+$/;

    if (e.target.value === "" || re.test(e.target.value)) {
      setKeyResultThreePercentage(e.target.value);
    }
  };
  const handleCancel = useCallback((e) => {
    setObjective("");
    setDescription("");
    // setKeyResults("");
    toggleNewOkr();
  });

  const handleOkTest = async (e) => {
    if (!objective || objective === "") {
      setSeverity("error");
      setMessageAlert("Debes de agregar el campo del nombre");
      setOpenSnackbar(true, () => {
        setTimeout(function () {
          setOpenSnackbar(false);
        }, 5000);
      });
    } else if (!description || description === "") {
      setSeverity("error");
      setMessageAlert("Debes de agregar el campo de la descripción");
      setOpenSnackbar(true, () => {
        setTimeout(function () {
          setOpenSnackbar(false);
        }, 5000);
      });
    }
    // else if(!keyResults || keyResults === ""){
    //   setSeverity("error")
    //   setMessageAlert("Debes de agregar el campo del criterio")
    //   setOpenSnackbar(true, () => {
    //     setTimeout(function () {
    //         setOpenSnackbar(false);
    //     }, 5000);
    //   });
    // }
    else {
      let dataInput = {
        userId: dbuser.getUserByUid.id,
        companyId: dbuser.getUserByUid.companyId,
        objective: objective,
        description: description,
        initDate: initDate,
        endDate: endDate,
        forAllCompany: checkAllCompany,
        groups: groups,
        departments: departments,
        keyResultOne: {
          description: keyResultOneDescription,
          unit: keyResultOneUnit,
          percentage: keyResultOneUnit === "PERCENTAGE" ? parseInt(keyResultOnePercentage) : null,
          number: keyResultOneUnit === "NUMBER" ? parseInt(keyResultOneNumber) : null,
          boolean: keyResultOneUnit === "BOOLEAN" ? false : null,
          initDate: keyResultOneInitDate,
          endDate: keyResultOneEndDate,
        },
        keyResultTwo: {
          description: keyResultTwoDescription,
          unit: keyResultTwoUnit,
          percentage: keyResultTwoUnit === "PERCENTAGE" ? parseInt(keyResultTwoPercentage) : null,
          number: keyResultTwoUnit === "NUMBER" ? parseInt(keyResultTwoNumber) : null,
          boolean: keyResultTwoUnit === "BOOLEAN" ? false : null,
          initDate: keyResultTwoInitDate,
          endDate: keyResultTwoEndDate,
        },
        keyResultThree: {
          description: keyResultThreeDescription,
          unit: keyResultThreeUnit,
          percentage: keyResultThreeUnit === "PERCENTAGE" ? parseInt(keyResultThreePercentage) : null,
          number: keyResultThreeUnit === "NUMBER" ? parseInt(keyResultThreeNumber) : null,
          boolean: keyResultThreeUnit === "BOOLEAN" ? false : null,
          initDate: keyResultThreeInitDate,
          endDate: keyResultThreeEndDate,
        },
      };

      console.log(dataInput);

      let response = await newOkr({
        variables: { input: dataInput },
      });
      if (response.data) {
        setSeverity("success");
        setMessageAlert("Reto creado correctamente");
        setOpenSnackbar(true, () => {
          setTimeout(function () {
            setOpenSnackbar(false);
          }, 5000);
        });
        setObjective("");
        setDescription("");
        // setKeyResults("");
        toggleNewOkr();
        refetch()
      } else {
        console.log(response);
        setSeverity("error");
        setMessageAlert("Error al crear el reto");
        setOpenSnackbar(true, () => {
          setTimeout(function () {
            setOpenSnackbar(false);
          }, 5000);
        });
        setObjective("");
        setDescription("");
        // setKeyResults("");
        toggleNewOkr();
      }
    }
  };
  const handleBack = async (e) => {
    setObjective("");
    setDescription("");
    // setKeyResults("");
    toggleNewOkr();
  };

  useEffect(() => {
    let userId = dbuser.getUserByUid.id;
    let isManager = dbuser.getIfIsManager;
    let role = dbuser.getUserByUid.role;
    if (dataDepartments && dataGroups) {
      if (role === 'admin') {

        let optionsDepartmentTmp = dataDepartments.getDepartmentsByCompanyId
        let optionsGroupTmp = dataGroups.getGroupsByCompanyId
        console.log(optionsDepartmentTmp);

        // optionsDepartmentTmp = optionsDepartmentTmp.filter(department => department.dataUsers.length >= 6)
        // optionsGroupTmp = optionsGroupTmp.filter(group => group.dataUsers.length >= 6)

        setOptionsDepartment(optionsDepartmentTmp)
        setOptionsGroup(optionsGroupTmp)
      } else {
        if (isManager) {
          let optionsDepartmentTmp = dataDepartments.getDepartmentsByCompanyId
          let optionsGroupTmp = dataGroups.getGroupsByCompanyId

          // optionsDepartmentTmp = optionsDepartmentTmp.filter(department => department.dataManager.length > 0 && department.dataManager[0]._id === userId && department.dataUsers.length >= 6)
          // optionsGroupTmp = optionsGroupTmp.filter(group => group.dataManager.length > 0 && group.dataManager[0]._id === userId && group.dataUsers.length >= 6)

          optionsDepartmentTmp = optionsDepartmentTmp.filter(department => department.dataManager.length > 0 && department.dataManager[0]._id === userId)
          optionsGroupTmp = optionsGroupTmp.filter(group => group.dataManager.length > 0 && group.dataManager[0]._id === userId)

          let arrayDepartments = optionsDepartmentTmp.map(({ id }) => id)
          let arrayGroups = optionsGroupTmp.map(({ id }) => id)

          setOptionsDepartment(optionsDepartmentTmp)
          setOptionsGroup(optionsGroupTmp)

        }
      }
    }
  }, [dataDepartments, dataGroups])

  if (dataDepartments && dataGroups) {
    // let optionsDepartment = dataDepartments.getDepartmentsByCompanyId;
    // let optionsGroup = dataGroups.getGroupsByCompanyId;
    return (
      <>
        <SnackBarMessage
          open={openSnackbar}
          handleClose={() => setOpenSnackbar(false)}
          message={messageAlert}
          severity={severity}
        />
        <Dialog
          open={modalNewOkr}
          onClose={handleBack}
          fullWidth
          maxWidth="lg"
          width="80%"
          classes={{ paper: "modal-content rounded-lg" }}
        >
          <div className="text-center pt-5">
            <div className="avatar-icon-wrapper rounded-circle mb-4">
              {/* <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-success text-success m-0 d-130"> */}
              <img
                alt="..."
                className="card-img-top rounded-sm"
                src={challenge}
                style={{ width: 70 }}
              />
              {/* </div> */}
            </div>
            <h5 className="text-center">Nuevo OKR</h5>
          </div>
          <div className=" text-center d-flex flex-column justify-content-center align-items-center">
            <h5 className="font-weight-bold mt-2" style={{ color: "#5a318e" }}>
              Paso 1. Objetivo del OKR
            </h5>
            <h6>Obligatorio (min 10 caracteres)</h6>
            <TextField
              style={{ width: "90%" }}
              className="m-2"
              id="outlined-multiline-static"
              label="Objetivo"
              placeholder="Escribe aqui..."
              value={objective}
              onChange={(e) => setObjective(e.target.value)}
              variant="outlined"
            />
            <h5 className="font-weight-bold mt-2" style={{ color: "#5a318e" }}>
              Paso 2. Descripción del OKR
            </h5>
            <h6>Obligatorio (min 10 caracteres)</h6>
            <TextField
              style={{ width: "90%" }}
              className="m-2"
              id="outlined-multiline-static"
              label="Descripción"
              placeholder="Escribe aqui..."
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              variant="outlined"
            />
            <h5 className="font-weight-bold mt-2" style={{ color: "#5a318e" }}>
              Paso 3. Fechas de inicio y finalización del OKR
            </h5>
            <h6>Obligatorio introducir ambas fechas</h6>
            <div className="d-flex flex-row justify-content-center">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className="d-flex-row align-items-center justify-content-center m-4">
                  <h5 className="font-weight-bold">Fecha de inicio</h5>
                  <TextField
                    id="initDate"
                    // label="Birthday"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={initDate}
                    onChange={(e) => setInitDate(e.target.value)}
                  />
                </div>
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className="d-flex-row align-items-center justify-content-center m-4">
                  <h5 className="font-weight-bold">Fecha de fin</h5>
                  <TextField
                    id="endDate"
                    // label="Birthday"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
              </MuiPickersUtilsProvider>
            </div>
            <h5 className="font-weight-bold mt-4" style={{ color: "#5a318e" }}>
              Paso 4. Elige a quien va destinado el OKR
            </h5>

            <div className="d-flex flex-row justify-content-center mb-2">
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => {
                  event.stopPropagation();
                  setCheckAllCompany(!checkAllCompany);
                  setCheckDepartmentsGroups(!checkDepartmentsGroups);
                  // if(checkExclude === false){
                  //   setCheckInclude(false)
                  //   setCheckImprove(false)
                  //   setActionProposal("EXCLUDE")
                  // }else{
                  //   setActionProposal(null)
                  // }
                }}
                onFocus={(event) => event.stopPropagation()}
                control={
                  <Checkbox checked={checkAllCompany} className="mr-2" />
                }
                label="Toda la empresa"
              />

              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => {
                  event.stopPropagation();
                  setCheckDepartmentsGroups(!checkDepartmentsGroups);
                  setCheckAllCompany(!checkAllCompany);
                  // if(checkInclude === false){
                  //   setCheckExclude(false)
                  //   setCheckImprove(false)
                  //   setActionProposal("INCLUDE")
                  // }else{
                  //   setActionProposal(null)
                  // }
                }}
                onFocus={(event) => event.stopPropagation()}
                control={
                  <Checkbox checked={checkDepartmentsGroups} className="mr-2" />
                }
                label="Algunos grupos o departamentos"
              />
            </div>

            {/* <div className="d-flex flex-row justify-content-center mb-spacing-6-x2">
              <Button
                className="btn-success text-uppercase font-weight-bold font-size-xs mb-2 mr-2"
                // style={ userSelected ? { backgroundColor: "orange" } : { backgroundColor: "#5a318e" }}
                style={{ backgroundColor: "orange" }}
                // onClick={toggleUser}
                disabled={checkGroups === true}
              >

                Seleccionar Grupos
              </Button>


              <Button
                className="btn-success text-uppercase font-weight-bold font-size-xs mb-2 ml-2"
                // style={ userSelected ? { backgroundColor: "orange" } : { backgroundColor: "#5a318e" }}
                style={{ backgroundColor: "orange" }}
                // onClick={toggleUser}
                disabled={checkGroups === true}
              >

                Seleccionar Departamentos
              </Button>
            </div> */}
            {!checkAllCompany && (
              <div
                className="d-flex flex-row text-center justify-content-center mb-spacing-6-x2"
                style={{ width: "100%" }}
              >
                <FormControl
                  variant="outlined"
                  className="w-100"
                  style={{ maxWidth: "60%" }}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Departamentos
                  </InputLabel>
                  <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox-departments"
                    multiple
                    value={departments}
                    disabled={
                      optionsDepartment.length === 0 ||
                      optionsDepartment.length === null ||
                      checkAllCompany
                    }
                    onChange={(e) => setDepartments(e.target.value)}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={(selected) => (
                      <div style={{ display: "flex", flexWrap: "Wrap" }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={
                              optionsDepartment.find((el) => el.id === value)
                                .name
                            }
                            style={{ margin: 2 }}
                            color="primary"
                          />
                        ))}
                      </div>
                    )}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {optionsDepartment.map((department) => (
                      <MenuItem key={department.id} value={department.id}>
                        {department.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
            {!checkAllCompany && (
              <div
                className="d-flex flex-row text-center justify-content-center mb-spacing-6-x2"
                style={{ width: "100%" }}
              >
                <FormControl
                  variant="outlined"
                  className="w-100"
                  style={{ maxWidth: "60%" }}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Grupos
                  </InputLabel>
                  <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox-departments"
                    multiple
                    value={groups}
                    disabled={
                      optionsGroup.length === 0 ||
                      optionsGroup.length === null ||
                      checkAllCompany
                    }
                    onChange={(e) => setGroups(e.target.value)}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={(selected) => (
                      <div style={{ display: "flex", flexWrap: "Wrap" }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={
                              optionsGroup.find((el) => el.id === value).name
                            }
                            style={{ margin: 2 }}
                            color="primary"
                          />
                        ))}
                      </div>
                    )}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {optionsGroup.map((group) => (
                      <MenuItem key={group.id} value={group.id}>
                        {group.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}

            <h5 className="font-weight-bold mt-2" style={{ color: "#5a318e" }}>
              Paso 3. Resultados clave del OKR
            </h5>
            <h6>Obligatorio (min 10 caracteres)</h6>

            <div className="accordion mb-spacing-6-x2" style={{ width: "90%" }}>
              <Card
                className={clsx("card-box", {
                  "panel-open": accordion1,
                })}
              >
                <Card>
                  <div className="card-header">
                    <div className="panel-title">
                      <div className="accordion-toggle">
                        <Button
                          variant="text"
                          size="large"
                          className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                          onClick={() => setAccordion1(!accordion1)}
                          aria-expanded={accordion1}
                        >
                          <span>Resultado clave 1</span>
                          <FontAwesomeIcon
                            icon={["fas", "angle-up"]}
                            className="font-size-xl accordion-icon"
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <Collapse in={accordion1}>
                    <h5
                      className="font-weight-bold mt-4"
                      style={{ color: "orange" }}
                    >
                      Añade la descripción del resultado clave
                    </h5>
                    <TextField
                      style={{ width: "90%" }}
                      className="m-2"
                      id="outlined-multiline-static"
                      label="Descripción"
                      placeholder="Escribe aqui..."
                      value={keyResultOneDescription}
                      onChange={(e) =>
                        setKeyResultOneDescription(e.target.value)
                      }
                      variant="outlined"
                    />
                    <h5
                      className="font-weight-bold mt-2"
                      style={{ color: "orange" }}
                    >
                      Unidades de medición del OKR
                    </h5>
                    <div className="d-flex flex-row justify-content-center mb-spacing-3-x2">
                      <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => {
                          event.stopPropagation();
                          setCheckBooleanOne(!checkBooleanOne);
                          if (checkBooleanOne === false) {
                            setCheckPercentageOne(false);
                            setCheckNumberOne(false);
                            setKeyResultOneUnit("BOOLEAN");
                          } else {
                            setKeyResultOneUnit(null);
                          }
                        }}
                        onFocus={(event) => event.stopPropagation()}
                        control={
                          <Checkbox checked={checkBooleanOne} className="mr-1" />
                        }
                        label="Si/No (No por defecto)"
                      />

                      <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => {
                          event.stopPropagation();
                          setCheckPercentageOne(!checkPercentageOne);
                          if (checkPercentageOne === false) {
                            setCheckBooleanOne(false);
                            setCheckNumberOne(false);
                            setKeyResultOneUnit("PERCENTAGE");
                          } else {
                            setKeyResultOneUnit(null);
                          }
                        }}
                        onFocus={(event) => event.stopPropagation()}
                        control={
                          <Checkbox
                            checked={checkPercentageOne}
                            className="mr-1"
                          />
                        }
                        label="Porcentaje"
                      />

                      <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => {
                          event.stopPropagation();
                          setCheckNumberOne(!checkNumberOne);
                          if (checkNumberOne === false) {
                            setCheckBooleanOne(false);
                            setCheckPercentageOne(false);
                            setKeyResultOneUnit("NUMBER");
                          } else {
                            setKeyResultOneUnit(null);
                          }
                        }}
                        onFocus={(event) => event.stopPropagation()}
                        control={
                          <Checkbox checked={checkNumberOne} className="mr-1" />
                        }
                        label="Numérico"
                      />
                    </div>
                    {checkPercentageOne && (
                      <>
                        <h5
                          className="font-weight-bold mt-2"
                          style={{ color: "orange" }}
                        >
                          Añade el porcentaje objetivo
                        </h5>
                        <TextField
                          style={{ width: "90%" }}
                          className="m-2"
                          id="outlined-multiline-static"
                          label="Porcentaje"
                          placeholder="Escribe aqui..."
                          value={keyResultOnePercentage}
                          onChange={(e) => onChangePercentageOne(e)}
                          variant="outlined"
                        />
                      </>
                    )}
                    {checkNumberOne && (
                      <>
                        <h5
                          className="font-weight-bold mt-2"
                          style={{ color: "orange" }}
                        >
                          Añade el número entero objetivo
                        </h5>
                        <TextField
                          style={{ width: "90%" }}
                          className="m-2"
                          id="outlined-multiline-static"
                          label="Número entero"
                          placeholder="Escribe aqui..."
                          value={keyResultOneNumber}
                          onChange={(e) => onChangeNumberOne(e)}
                          variant="outlined"
                        />
                      </>
                    )}

                    <div className="d-flex flex-row justify-content-center">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className="d-flex-row align-items-center justify-content-center m-4">
                          <h5
                            className="font-weight-bold"
                            style={{ color: "orange" }}
                          >
                            fecha de inicio
                          </h5>
                          <TextField
                            id="initDate"
                            // label="Birthday"
                            type="date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={keyResultOneInitDate}
                            onChange={(e) =>
                              setKeyResultOneInitDate(e.target.value)
                            }
                          />
                        </div>
                      </MuiPickersUtilsProvider>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className="d-flex-row align-items-center justify-content-center m-4">
                          <h5
                            className="font-weight-bold"
                            style={{ color: "orange" }}
                          >
                            fecha de fin
                          </h5>
                          <TextField
                            id="endDate"
                            // label="Birthday"
                            type="date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={keyResultOneEndDate}
                            onChange={(e) =>
                              setKeyResultOneEndDate(e.target.value)
                            }
                          />
                        </div>
                      </MuiPickersUtilsProvider>
                    </div>
                  </Collapse>
                </Card>
              </Card>
              <Card
                className={clsx("card-box", {
                  "panel-open": accordion2,
                })}
              >
                <Card>
                  <div className="card-header">
                    <div className="panel-title">
                      <div className="accordion-toggle">
                        <Button
                          variant="text"
                          size="large"
                          className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                          onClick={() => setAccordion2(!accordion2)}
                          aria-expanded={accordion2}
                        >
                          <span>Resultado clave 2</span>
                          <FontAwesomeIcon
                            icon={["fas", "angle-up"]}
                            className="font-size-xl accordion-icon"
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <Collapse in={accordion2}>
                    <h5
                      className="font-weight-bold mt-4"
                      style={{ color: "orange" }}
                    >
                      Añade la descripción del resultado clave
                    </h5>
                    <TextField
                      style={{ width: "90%" }}
                      className="m-2"
                      id="outlined-multiline-static"
                      label="Descripción"
                      placeholder="Escribe aqui..."
                      value={keyResultTwoDescription}
                      onChange={(e) =>
                        setKeyResultTwoDescription(e.target.value)
                      }
                      variant="outlined"
                    />
                    <h5
                      className="font-weight-bold mt-2"
                      style={{ color: "orange" }}
                    >
                      Unidades de medición del OKR
                    </h5>
                    <div className="d-flex flex-row justify-content-center mb-spacing-3-x2">
                      <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => {
                          event.stopPropagation();
                          setCheckBooleanTwo(!checkBooleanTwo);
                          if (checkBooleanTwo === false) {
                            setCheckPercentageTwo(false);
                            setCheckNumberTwo(false);
                            setKeyResultTwoUnit("BOOLEAN");
                          } else {
                            setKeyResultTwoUnit(null);
                          }
                        }}
                        onFocus={(event) => event.stopPropagation()}
                        control={
                          <Checkbox checked={checkBooleanTwo} className="mr-1" />
                        }
                        label="Si/No (No por defecto)"
                      />

                      <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => {
                          event.stopPropagation();
                          setCheckPercentageTwo(!checkPercentageTwo);
                          if (checkPercentageTwo === false) {
                            setCheckBooleanTwo(false);
                            setCheckNumberTwo(false);
                            setKeyResultTwoUnit("PERCENTAGE");
                          } else {
                            setKeyResultTwoUnit(null);
                          }
                        }}
                        onFocus={(event) => event.stopPropagation()}
                        control={
                          <Checkbox
                            checked={checkPercentageTwo}
                            className="mr-1"
                          />
                        }
                        label="Porcentaje"
                      />

                      <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => {
                          event.stopPropagation();
                          setCheckNumberTwo(!checkNumberTwo);
                          if (checkNumberTwo === false) {
                            setCheckBooleanTwo(false);
                            setCheckPercentageTwo(false);
                            setKeyResultTwoUnit("NUMBER");
                          } else {
                            setKeyResultTwoUnit(null);
                          }
                        }}
                        onFocus={(event) => event.stopPropagation()}
                        control={
                          <Checkbox checked={checkNumberTwo} className="mr-1" />
                        }
                        label="Numérico"
                      />
                    </div>
                    {checkPercentageTwo && (
                      <>
                        <h5
                          className="font-weight-bold mt-2"
                          style={{ color: "orange" }}
                        >
                          Añade el porcentaje objetivo
                        </h5>
                        <TextField
                          style={{ width: "90%" }}
                          className="m-2"
                          id="outlined-multiline-static"
                          label="Porcentaje"
                          placeholder="Escribe aqui..."
                          value={keyResultTwoPercentage}
                          onChange={(e) => onChangePercentageTwo(e)}
                          variant="outlined"
                        />
                      </>
                    )}
                    {checkNumberTwo && (
                      <>
                        <h5
                          className="font-weight-bold mt-2"
                          style={{ color: "orange" }}
                        >
                          Añade el número entero objetivo
                        </h5>
                        <TextField
                          style={{ width: "90%" }}
                          className="m-2"
                          id="outlined-multiline-static"
                          label="Número entero"
                          placeholder="Escribe aqui..."
                          value={keyResultTwoNumber}
                          onChange={(e) => onChangeNumberTwo(e)}
                          variant="outlined"
                        />
                      </>
                    )}

                    <div className="d-flex flex-row justify-content-center">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className="d-flex-row align-items-center justify-content-center m-4">
                          <h5
                            className="font-weight-bold"
                            style={{ color: "orange" }}
                          >
                            fecha de inicio
                          </h5>
                          <TextField
                            id="initDate"
                            // label="Birthday"
                            type="date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={keyResultTwoInitDate}
                            onChange={(e) =>
                              setKeyResultTwoInitDate(e.target.value)
                            }
                          />
                        </div>
                      </MuiPickersUtilsProvider>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className="d-flex-row align-items-center justify-content-center m-4">
                          <h5
                            className="font-weight-bold"
                            style={{ color: "orange" }}
                          >
                            fecha de fin
                          </h5>
                          <TextField
                            id="endDate"
                            // label="Birthday"
                            type="date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={keyResultTwoEndDate}
                            onChange={(e) =>
                              setKeyResultTwoEndDate(e.target.value)
                            }
                          />
                        </div>
                      </MuiPickersUtilsProvider>
                    </div>
                  </Collapse>
                </Card>
              </Card>
              <Card
                className={clsx("card-box", {
                  "panel-open": accordion3,
                })}
              >
                <Card>
                  <div className="card-header">
                    <div className="panel-title">
                      <div className="accordion-toggle">
                        <Button
                          variant="text"
                          size="large"
                          className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                          onClick={() => setAccordion3(!accordion3)}
                          aria-expanded={accordion3}
                        >
                          <span>Resultado clave 3</span>
                          <FontAwesomeIcon
                            icon={["fas", "angle-up"]}
                            className="font-size-xl accordion-icon"
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <Collapse in={accordion3}>
                    <h5
                      className="font-weight-bold mt-4"
                      style={{ color: "orange" }}
                    >
                      Añade la descripción del resultado clave
                    </h5>
                    <TextField
                      style={{ width: "90%" }}
                      className="m-2"
                      id="outlined-multiline-static"
                      label="Descripción"
                      placeholder="Escribe aqui..."
                      value={keyResultThreeDescription}
                      onChange={(e) =>
                        setKeyResultThreeDescription(e.target.value)
                      }
                      variant="outlined"
                    />
                    <h5
                      className="font-weight-bold mt-2"
                      style={{ color: "orange" }}
                    >
                      Unidades de medición del OKR
                    </h5>
                    <div className="d-flex flex-row justify-content-center mb-spacing-3-x2">
                      <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => {
                          event.stopPropagation();
                          setCheckBooleanThree(!checkBooleanThree);
                          if (checkBooleanThree === false) {
                            setCheckPercentageThree(false);
                            setCheckNumberThree(false);
                            setKeyResultThreeUnit("BOOLEAN");
                          } else {
                            setKeyResultThreeUnit(null);
                          }
                        }}
                        onFocus={(event) => event.stopPropagation()}
                        control={
                          <Checkbox checked={checkBooleanThree} className="mr-1" />
                        }
                        label="Si/No (No por defecto)"
                      />

                      <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => {
                          event.stopPropagation();
                          setCheckPercentageThree(!checkPercentageThree);
                          if (checkPercentageThree === false) {
                            setCheckBooleanThree(false);
                            setCheckNumberThree(false);
                            setKeyResultThreeUnit("PERCENTAGE");
                          } else {
                            setKeyResultThreeUnit(null);
                          }
                        }}
                        onFocus={(event) => event.stopPropagation()}
                        control={
                          <Checkbox
                            checked={checkPercentageThree}
                            className="mr-1"
                          />
                        }
                        label="Porcentaje"
                      />

                      <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => {
                          event.stopPropagation();
                          setCheckNumberThree(!checkNumberThree);
                          if (checkNumberThree === false) {
                            setCheckBooleanThree(false);
                            setCheckPercentageThree(false);
                            setKeyResultThreeUnit("NUMBER");
                          } else {
                            setKeyResultThreeUnit(null);
                          }
                        }}
                        onFocus={(event) => event.stopPropagation()}
                        control={
                          <Checkbox checked={checkNumberThree} className="mr-1" />
                        }
                        label="Numérico"
                      />
                    </div>
                    {checkPercentageThree && (
                      <>
                        <h5
                          className="font-weight-bold mt-2"
                          style={{ color: "orange" }}
                        >
                          Añade el porcentaje objetivo
                        </h5>
                        <TextField
                          style={{ width: "90%" }}
                          className="m-2"
                          id="outlined-multiline-static"
                          label="Porcentaje"
                          placeholder="Escribe aqui..."
                          value={keyResultThreePercentage}
                          onChange={(e) => onChangePercentageThree(e)}
                          variant="outlined"
                        />
                      </>
                    )}
                    {checkNumberThree && (
                      <>
                        <h5
                          className="font-weight-bold mt-2"
                          style={{ color: "orange" }}
                        >
                          Añade el número entero objetivo
                        </h5>
                        <TextField
                          style={{ width: "90%" }}
                          className="m-2"
                          id="outlined-multiline-static"
                          label="Número entero"
                          placeholder="Escribe aqui..."
                          value={keyResultThreeNumber}
                          onChange={(e) => onChangeNumberThree(e)}
                          variant="outlined"
                        />
                      </>
                    )}

                    <div className="d-flex flex-row justify-content-center">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className="d-flex-row align-items-center justify-content-center m-4">
                          <h5
                            className="font-weight-bold"
                            style={{ color: "orange" }}
                          >
                            fecha de inicio
                          </h5>
                          <TextField
                            id="initDate"
                            // label="Birthday"
                            type="date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={keyResultThreeInitDate}
                            onChange={(e) =>
                              setKeyResultThreeInitDate(e.target.value)
                            }
                          />
                        </div>
                      </MuiPickersUtilsProvider>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className="d-flex-row align-items-center justify-content-center m-4">
                          <h5
                            className="font-weight-bold"
                            style={{ color: "orange" }}
                          >
                            fecha de fin
                          </h5>
                          <TextField
                            id="endDate"
                            // label="Birthday"
                            type="date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={keyResultThreeEndDate}
                            onChange={(e) =>
                              setKeyResultThreeEndDate(e.target.value)
                            }
                          />
                        </div>
                      </MuiPickersUtilsProvider>
                    </div>
                  </Collapse>
                </Card>
              </Card>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-center mb-spacing-6-x2">
            <div className="pt-4" style={{ marginBottom: 20 }}>
              <Button
                onClick={handleBack}
                className="btn-neutral-dark btn-pill mx-1"
              >
                <span className="btn-wrapper--label">Cancelar</span>
              </Button>
              <Button
                onClick={handleOkTest}
                className="btn-success btn-pill mx-1"
              // disabled={!userSelected || !commentary || commentary.length < 10}
              // disabled={!endDate || !initDate}
              >
                <span className="btn-wrapper--label">Crear</span>
              </Button>
            </div>
          </div>
        </Dialog>
      </>
    );
  } else {
    return null;
  }
}
