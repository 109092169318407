import React, { useState, useCallback, useContext } from "react";
import {GET_USERS_BY_COMPANY_ID_WITHOUT_ME} from 'data/queries';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "../../../context/UserContext";
import { useQuery } from '@apollo/react-hooks';
import {
  Table,
  LinearProgress,
  Card,
  CardContent,
  Button,
  Tooltip,
  Dialog,
} from "@material-ui/core";
import clsx from 'clsx';

import { InputAdornment, TextField } from '@material-ui/core';

import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';

import defaultAvatar from "../../../assets/images/climind/default_avatar.png";
export default function ModalSelectUSer({ modalUser, toggleUser, userSelected, setUserSelected }) {
    const [filterTable, setFilterTable] = useState(null)
    const [searchStatus1, setSearchStatus1] = useState(false);
    const toggleSearch1 = () => setSearchStatus1(!searchStatus1);

    const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
    const {loading: loadingUsers,error: errorUsers,data: dataUsers,networkStatus: networkStatusUsers, refetch: refetchUsers} = useQuery(GET_USERS_BY_COMPANY_ID_WITHOUT_ME,
        {
        variables:{ id: dbuser.getUserByUid.companyId, userId: dbuser.getUserByUid.id},
        skip: !dbuser.getUserByUid.companyId,
        notifyOnNetworkStatusChange: true,
      })
      const onSelectUser = useCallback( (user) => {
        setUserSelected(user)
        toggleUser()
        })
      if(dataUsers){
        return (
            <>
              <Dialog
                open={modalUser}
                onClose={toggleUser}
                maxWidth="xl"
                classes={{ paper: "modal-content rounded-lg" }}
              >
                <div className="text-center p-5 mb-3">
                  <h4 className="text-center gx-mb-4">
                    Selecciona un usuario
                  </h4>
                  <div
                    className={clsx('search-wrapper search-wrapper--grow mb-4', {
                    'is-active': searchStatus1
                    })}>
                    <TextField
                    variant="outlined"
                    size="small"
                    id="input-with-icon-textfield22-1"
                    onFocus={toggleSearch1}
                    onBlur={toggleSearch1}
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <SearchTwoToneIcon />
                        </InputAdornment>
                        )
                    }}
                    onChange={async (e) => {
                        // const filteredTable = dataUsers.getUsersByCompanyIdWithoutMe.filter(o =>
                        //   Object.keys(o).some(k => k==="name" &&
                        //       String(o[k])
                        //         .toLowerCase()
                        //         .includes(e.target.value.toLowerCase())
                        //   )
                        // );
                        let filter = e.target.value.toLowerCase();
                        const filteredTable = await dataUsers.getUsersByCompanyIdWithoutMe.filter(
                          (o) => {
                            let dataFilter = o.name
                              .toLowerCase()
                              .concat(" ", o.surname.toLowerCase());
                            let dataFilter2 = o.email.toLowerCase();
                            return (
                              dataFilter.indexOf(filter) !== -1 ||
                              dataFilter2.indexOf(filter) !== -1
                            );
                          }
  
                        );
                        setFilterTable(filteredTable)
                    }}
                    />
                </div>
                  <Card className="card-box mb-spacing-6-x2">
                    <CardContent>
                      <div className="table-responsive-xl">
                        <Table className="table table-borderless table-hover text-nowrap mb-0">
                          <thead>
                            <tr>
                              <th className="text-left">Usuario</th>
                              <th className="text-center">Entregar</th>
                            </tr>
                          </thead>
                          <tbody>
                              {!filterTable ? dataUsers.getUsersByCompanyIdWithoutMe.map((element, index) => {
                                
                                  return(
                                    <tr>
                                        <td>
                                            <div className="d-flex align-items-center">
                                            <div className="avatar-icon-wrapper mr-3">
                                                <div className="avatar-icon">
                                                {element.urlImage ? <img alt="..." src={element.urlImage} /> : <img alt="..." src={defaultAvatar}/>}
                                                </div>
                                            </div>
                                            <div className="text-left">
                                              <a
                                                href="#/"
                                                onClick={(e) => e.preventDefault()}
                                                className="font-weight-bold text-black"
                                                title="..."
                                              >
                                                {element.name} {element.surname}
                                              </a>
                                              <span className="text-black-50 d-block">
                                                {element.email}
                                              </span>
                                            </div>
                                            </div>
                                        </td>
                                        <td className="text-center">
                                            <Button
                                            size="small"
                                            className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon"
                                            onClick={()=>onSelectUser(element)}
                                            >
                                            <FontAwesomeIcon icon={["fas", "arrow-right"]} />
                                            </Button>
                                        </td>
                                    </tr>
                                  )
                              }) : 
                              filterTable.map((element, index) => {
                                console.log(element)
                                return(
                                  <tr>
                                      <td>
                                          <div className="d-flex align-items-center">
                                          <div className="avatar-icon-wrapper mr-3">
                                              <div className="avatar-icon">
                                              {element.urlImage ? <img alt="..." src={element.urlImage} /> : <img alt="..." src={defaultAvatar}/>}
                                              </div>
                                          </div>
                                          <div className="text-left">
                                            <a
                                              href="#/"
                                              onClick={(e) => e.preventDefault()}
                                              className="font-weight-bold text-black"
                                              title="..."
                                            >
                                              {element.name} {element.surname}
                                            </a>
                                            <span className="text-black-50 d-block">
                                              {element.email}
                                            </span>
                                          </div>
                                          </div>
                                      </td>
                                      <td className="text-center">
                                          <Button
                                          size="small"
                                          className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon"
                                          onClick={()=>onSelectUser(element)}
                                          >
                                          <FontAwesomeIcon icon={["fas", "arrow-right"]} />
                                          </Button>
                                      </td>
                                  </tr>
                                )
                            })
                              }
                            
                          </tbody>
                        </Table>
                      </div>
                    </CardContent>
                  </Card>
        
                  <div className="pt-4" style={{ marginBottom: 20 }}>
                    <Button
                      onClick={toggleUser}
                      className="btn-neutral-dark btn-pill mx-1"
                    >
                      <span className="btn-wrapper--label">Atrás</span>
                    </Button>
                  </div>
                </div>
              </Dialog>
            </>
          );
      }else{
          return null
      }
  
}
