import React, { useState, useEffect } from "react"

import {
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default function Step1({ employeesImport, handleNext, fieldMatched, setFieldMatched }) {

    let columnsArray = []
    let columnsRequired = ["Nombre", "Apellido", "DNI", "Email", "Sexo", "Puesto"]

    useEffect(() => {
        if (employeesImport.length > 0) {
            setFieldMatched(columnsArray.map(f => ({ fieldImport: f, fieldMatch: null })))
        }
    }, [employeesImport]);

    const matchField = (fieldImport, selectOption) => {
        setFieldMatched(fieldMatched.map(item => item.fieldImport === fieldImport ? { ...item, "fieldMatch": selectOption } : item))
    }

    employeesImport.map(contact => {
        Object.keys(contact).map(column => {
            if (columnsArray.find(col => col === column) === undefined)
                columnsArray.push(column)
        })
    })

    return (
        <>
            <div className="pt-2">
                <Grid container spacing={12} className="justify-content-center">
                    <div className="p-3">
                        <div className="px-2 pb-2">
                            <div className="font-weight-bold pb-1 font-size-lg text-capitalize">
                                Campos encontrados
                            </div>
                            <div className="text-black-50">
                                Los campos encontrados en el csv importado deben ser emparejados con los campos del sistema
                            </div>
                        </div>
                        {columnsArray.map(fieldFound =>
                            <Grid container spacing={12} className="mb-4">
                                <Grid item md={6} className="">
                                    <Grid container spacing={12} className="bg-secondary p-2">
                                        <Grid item md={6} className="pt-2">
                                            <span>{fieldFound}</span>
                                        </Grid>
                                        <Grid item md={6}>
                                            <FormControl
                                                variant="outlined"
                                                size="small"
                                                className="w-100"
                                            >
                                                <InputLabel id="type-select-placeholder-label-label">Campo</InputLabel>
                                                <Select
                                                    labelId="type-select-placeholder-label-label"
                                                    id="type-select-placeholder-label-id"
                                                    fullWidth
                                                    onChange={(e) => matchField(fieldFound, e.target.value)}
                                                    label="Type"
                                                >
                                                    <MenuItem value={null}>Ninguno</MenuItem>
                                                    {columnsRequired.map(row =>
                                                        <MenuItem value={row}>{row}</MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} className="pt-3 pl-3">
                                    {fieldMatched.length > 0 && fieldMatched.find(item => item.fieldImport == fieldFound).fieldMatch !== null ?
                                        <div>
                                            <FontAwesomeIcon
                                                icon={["fas", "check-circle"]}
                                                color="green"
                                            />
                                            <span> Campo emparejado</span>
                                        </div>
                                        :
                                        <div>
                                            <FontAwesomeIcon
                                                icon={["fas", "times-circle"]}
                                                color="red"
                                            />
                                            <span> Campo ignorado</span>
                                        </div>
                                    }
                                </Grid>
                            </Grid>
                        )}
                    </div>
                </Grid>
            </div>
        </>
    );
};