import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, LinearProgress, Card, Tooltip, Table, Button } from "@material-ui/core";

import Chart from "react-apexcharts";
import CountUp from "react-countup";

import '../../../../../assets/climind.scss'


export default function DashboardPulse3({
  representivityPositive,
  representivityNegative,
  representivityNeutro,
  totalPulse,
  TotalActivePulseUsers,
  dataMatrix,
  countedAspectNegative,
  dataNegative
}) {
  // console.log(TotalActivePulseUsers);
  let dataScatter = []
  dataMatrix.map(el => {
    let tmp = {
      x: 0,
      y: 0,
    }
    if (el.promSad < 0) {
      tmp.x = el.promSad.toFixed(2) * -1;
      tmp.y = (el.percentSad * 10).toFixed(2);
      dataScatter.push(tmp)
    }
  })
  console.log(dataScatter);
  const options = {
    series: [{
      name: 'Intensidad',
      type: 'scatter',
      data: dataScatter
    }],
    options: {
      chart: {
        height: 450,
        type: 'line',
        toolbar: {
          show: false,
        },
        // background:"#efcc1f"
      },
      fill: {
        type: 'solid',
      },
      markers: {
        size: [6, 0, 0]
      },
      tooltip: {
        shared: false,
        intersect: true,
        x: {
          show: true,
          formatter: function (val) {
            return 'Intensidad: ' + val
          },
        },
        y: {
          formatter: function (val) {
            return (val * 10).toFixed(2) + ' %'
          },
          title: {
            formatter: (seriesName) => 'Recurrencia:',
          },
        },
      },
      colors: ['#2E93fA', '#b372e2', '#b372e2'],
      legend: {
        show: false
      },
      xaxis: {
        type: 'numeric',
        min: 0,
        max: 10,
        tickAmount: 10
      },
      yaxis: {
        type: 'numeric',
        min: 0,
        max: 10,
        tickAmount: 10,
        labels: {
          formatter: function (value) {
            return value * 10 + "%";
          }
        },
      },
      annotations: {
        position: "back",
        yaxis: [
          {
            y: 5,
            y2: 10,
            fillColor: "#efcc1f"
          },
          {
            y: 0,
            y2: 5,
            fillColor: "#1bc943"
          },

        ],
        xaxis: [{
          x: 5,
          x2: 10,
          fillColor: "red",
        },
        {
          x: 5,
          x2: 10,
          offsetX: 0,
          offsetY: 144,
          fillColor: "#efcc1f",
        },
        ],
      }
    },
  }
  let dataTableRisk = [{
    label: "Características de mis tareas",
    description: "Tarea que realizas, cómo está diseñada y el sentido que tiene para ti.",
    type: "typeContenidoTrabajo",
    value: 0,
    responses: 0,
    users: 0,
    percent: 0
  }, {
    label: "Carga y ritmo de tu trabajo",
    description: "Cantidad y ritmo de tu trabajo, y nivel de atención que te requiere.",
    type: "typeCargaRitmo",
    value: 0,
    responses: 0,
    users: 0,
    percent: 0
  }, {
    label: "Tiempo de trabajo",
    description: "Cómo está organizado tu tiempo de trabajo (cantidad de tiempo, distribución, descansos entre jornadas y pausas, horarios atípicos, etc.)",
    type: "typeTiempo",
    value: 0,
    responses: 0,
    users: 0,
    percent: 0
  }, {
    label: "Participación y control en tu trabajo",
    description: "Posibilidad de participar, organizar, tomar decisiones y ser autónomo/a en tu trabajo.",
    type: "typeParticipacion",
    value: 0,
    responses: 0,
    users: 0,
    percent: 0
  }, {
    label: "Funciones y responsabilidades",
    description: "Claridad y cantidad de funciones y responsabilidades.",
    type: "typeRol",
    value: 0,
    responses: 0,
    users: 0,
    percent: 0
  }, {
    label: "Desarrollo profesional",
    description: "Puesto/cargo dentro de la organización, estabilidad y oportunidades de crecimiento.",
    type: "typeDesarrollo",
    value: 0,
    responses: 0,
    users: 0,
    percent: 0
  }, {
    label: "Relaciones y apoyo social",
    description: "Cómo te relaciones con las personas en tu trabajo y apoyo que sientes.",
    type: "typeRelacciones",
    value: 0,
    responses: 0,
    users: 0,
    percent: 0
  }, {
    label: "Equipamiento para trabajar",
    description: "Interacción que tienes con tu entorno de trabajo, los equipos, máquinas, herramientas, y software que utilizas para el desempeño de tus tareas.",
    type: " ",
    value: 0,
    responses: 0,
    users: 0,
    percent: 0
  }, {
    label: "Interacciones trabajo-casa",
    description: "Posibilidad de conciliar tu trabajo con las necesidades/exigencias de tu vida familiar y social.",
    type: "typeInteracciones",
    value: 0,
    responses: 0,
    users: 0,
    percent: 0
  }
  ]
  // console.log(dataNegative);
  // console.log(dataMatrix);
  console.log(countedAspectNegative);
  let totalResponses = 0;
  dataTableRisk.map(el => {
    countedAspectNegative.map(n => {
      if (n[el.type] > 0) {
        totalResponses += n.frecuencySad
      }
    })
  })

  let usersTotal = [];

  dataTableRisk.map(el => {
    let valueTmp = 0
    let responsesTmp = 0
    let users = []
    countedAspectNegative.map(n => {
      if (n[el.type] > 0) {
        if (users.includes(n.userId)) {
          valueTmp += n.valueSad
          responsesTmp += n.frecuencySad
          // totalResponses += n.frecuencySad
        } else {
          users.push(n.userId)
          valueTmp += n.valueSad
          responsesTmp += n.frecuencySad
          // totalResponses += n.frecuencySad
        }
        if (!usersTotal.includes(n.userId)) {
          usersTotal.push(n.userId)
        }
      }
    })
    el.value = responsesTmp > 0 ? valueTmp / responsesTmp : 0;
    el.responses = responsesTmp;
    el.users = users.length;
    el.percent = responsesTmp / totalResponses
  })
  console.log(dataTableRisk);

  function compareVariables(a, b) {
    if (a.percent > b.percent) {
      return -1;
    }
    if (a.percent < b.percent) {
      return 1;
    }
    return 0;
  }
  dataTableRisk.sort(compareVariables);
  const kpiIndicator = (dataPercent) => {
    if (!dataPercent) {
      return (
        <div className="text-left font-size-lg text-center">
          Sin datos
        </div>
      )
    }
    if (dataPercent < 0.15 && dataPercent >= 0) {
      return (
        <div className="text-left font-size-lg text-center">
          <div className="avatar-icon-wrapper avatar-initials avatar-icon-lg">
            <div className="avatar-icon avatar-icon-red-medium">
              {Math.round(dataPercent * 100) + "%"}
            </div>
          </div>
        </div>
      );
    } else if (dataPercent >= 0.15 && dataPercent < 0.3) {
      return (
        <div className="text-left font-size-lg text-center">
          <div className="avatar-icon-wrapper avatar-initials avatar-icon-lg">
            <div className="avatar-icon avatar-icon-red-high text-white">
              {Math.round(dataPercent * 100) + "%"}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="text-left font-size-lg text-center">
          <div className="avatar-icon-wrapper avatar-initials avatar-icon-lg">
            <div className="avatar-icon avatar-icon-red-high text-white">
              {Math.round(dataPercent * 100) + "%"}
            </div>
          </div>
        </div>
      );
    }
  };
  // console.log(totalResponses);
  // console.log(dataTableRisk);
  // console.log(options);
  console.table(dataTableRisk);


  return (
    <>
      <Card className="card-box mb-spacing-6-x2">
        <div className="card-header">
          <div className="card-header--title">
            <small className="d-block text-uppercase mt-1">
              Pulso
            </small>
            <b>Matriz de Riesgos Psicosociales: Detecta potenciales riesgos</b>
          </div>
          <div className="card-tr-actions">
            <Tooltip
              classes={{
                tooltip: "text-center p-3 tooltip-secondary",
              }}
              arrow
              placement="top"
              title="Cada cuadrante representa un nivel de riesgo psicosocial. Cada punto representa a un usuario. El lugar en el que se encuentre cada usuario representa el nivel de riesgo al que se encuentra expuesto en el periodo de tiempo seleccionado."
            >
              <a
                href="#/"
                onClick={(e) => e.preventDefault()}
                className="text-first font-weight-bold"
              >
                <div
                  className=" avatar-icon rounded-circle mr-2"
                  style={{ width: 26, height: 26 }}
                >
                  <img
                    alt="..."
                    src={require("../../../../../assets/images/climind/info.png")}
                  />
                </div>
              </a>
            </Tooltip>
          </div>
        </div>
        <div>
          <div className="px-5 pt-5 pb-2">
            <p className="display-2 font-weight-bold mb-4">
              <span className="pl-1">
                <CountUp
                  start={0}
                  end={dataScatter.length}
                  duration={4}
                  separator=""
                  decimals={0}
                  decimal=","
                  prefix=""
                  suffix=""
                /> Usuarios en matriz de Riesgos Psicosociales

              </span>
            </p>
          </div>
          <Chart
            options={options.options}
            series={options.series}
            type="line"
            height={350}
          />
          <div className="px-5 pt-5 pb-1">
            <p className="display-2 font-weight-bold mb-4">
              <span className="pl-1">
                Tabla de Factores de riesgos psicosociales
              </span>
            </p>
          </div>
          <div className="px-5 pb-2">
            <p>
              Los datos corresponden a {usersTotal.length} usuarios que han marcado un riesgo psicosocial con intensidad mayor o igual 4 de un total de {TotalActivePulseUsers} usuarios activos en pulso. (Es decir un {(usersTotal.length / TotalActivePulseUsers * 100).toFixed(2)}% del total de usuarios activos en pulso)
            </p>
          </div>
          <div className="table-responsive-md">
            <Table className="table table-alternate-spaced mb-0">
              <thead>
                <tr>
                  <th
                    // style={{ width: "300px" }}
                    className="font-size-lg font-weight-normal text-dark text-center"
                    scope="col"
                  >
                    Factores de riesgo
                  </th>
                  <th
                    className="font-size-lg font-weight-normal text-dark text-center"
                    scope="col"
                  >
                    Num. de veces marcado
                  </th>
                  <th
                    className="font-size-lg font-weight-normal text-dark text-center"
                    scope="col"
                  >
                    Num. usuarios
                  </th>
                  <th
                    className="font-size-lg font-weight-normal text-dark text-center"
                    scope="col"
                  >
                    Valor negativo promedio
                  </th>
                  <th
                    className="font-size-lg font-weight-normal text-dark text-center"
                    scope="col"
                  >
                    Porcentaje de representatividad
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataTableRisk.map((el, index) => {
                  let percentRep = kpiIndicator(el.percent)
                  return (
                    <>
                      <tr key={index}>
                        <td className="text-center">
                          <span className="font-weight-bold">
                            {el.label}
                          </span>
                          <Tooltip
                            classes={{
                              tooltip: "text-center p-3 tooltip-primary",
                            }}
                            arrow
                            placement="bottom"
                            title={el.description}
                          >
                            <Button
                              variant="text"
                              className="p-0 d-30 border-0 btn-transition-none text-dark"
                              disableRipple
                            >
                              <FontAwesomeIcon
                                icon={["far", "question-circle"]}
                                className="font-size-lg"
                              />
                            </Button>
                          </Tooltip>
                        </td>
                        <td><div className="text-center font-size-lg">{el.responses}</div></td>
                        <td><div className="text-center font-size-lg">{el.users}</div></td>
                        <td><div className="text-center font-size-lg">{el.value.toFixed(2)}</div></td>
                        <td>{percentRep}</td>
                      </tr>
                      <tr className="divider"></tr>
                    </>
                  )
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </Card>
    </>
  );
}
