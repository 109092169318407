import React, { useEffect, useState, useContext, useCallback } from "react";

import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  Container,
  InputAdornment,
  ButtonGroup,
  Card,
  CardContent,
  Button,
  List,
  ListItem,
  TextField,
  LinearProgress,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Switch,
  Snackbar
} from "@material-ui/core";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";

import PerfectScrollbar from "react-perfect-scrollbar";
import { useDropzone } from "react-dropzone";
import CountUp from "react-countup";

import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';
import ViewCompactTwoToneIcon from '@material-ui/icons/ViewCompactTwoTone';
import PeopleAltTwoToneIcon from '@material-ui/icons/PeopleAltTwoTone';
import LayersTwoToneIcon from '@material-ui/icons/LayersTwoTone';

import emotion from "../../../assets/images/climind/reaction.png";
import recognition from "../../../assets/images/climind/thumbs-up.png";
import pregunta from "../../../assets/images/climind/faq.svg";
import felicitacion from "../../../assets/images/climind/regalo.png";
import agradecimiento from "../../../assets/images/climind/pray.png";
import avatar from "../../../assets/images/climind/default_avatar.png";

import CloseTwoToneIcon from "@material-ui/icons/CloseTwoTone";
import PublishTwoToneIcon from "@material-ui/icons/PublishTwoTone";
import AccountCircleTwoToneIcon from "@material-ui/icons/AccountCircleTwoTone";
import CheckIcon from "@material-ui/icons/Check";
import { UserContext } from "../../../context/UserContext";
import { CHANGE_PRIVACITY } from "data/mutations";
import { GET_USER_BY_UID, GET_TIMELINE } from "data/queries";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';


import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Privacity() {

  const { currentUser, dbuser, getUserWithFirebase } = useContext(UserContext);
  console.log(dbuser.getUserByUid)
  const [checkAdminQuestion, setCheckAdminQuestion] = useState(dbuser.getUserByUid.commentaryQuestionForAdmins);
  const [checkTimelineQuestion, setCheckTimelineQuestion] = useState(dbuser.getUserByUid.commentaryQuestionInTimeline);
  const [checkAdminEmotion, setCheckAdminEmotion] = useState(dbuser.getUserByUid.commentaryEmotionForAdmins);
  const [checkTimelineEmotion, setCheckTimelineEmotion] = useState(dbuser.getUserByUid.commentaryEmotionInTimeline);
  const [checkRecognition, setCheckRecognition] = useState(dbuser.getUserByUid.recognitionInTimeline);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [
    changePrivacity,
    { data: data, error: error, loading: loading },
  ] = useMutation(CHANGE_PRIVACITY);

  const handleChangeCheckTimelineQuestion = useCallback((e) => {
    setCheckTimelineQuestion(!checkTimelineQuestion);
  });
  const handleChangeCheckAdminQuestion = useCallback((e) => {
    setCheckAdminQuestion(!checkAdminQuestion);
  });
  const handleChangeCheckTimelineEmotion = useCallback((e) => {
    setCheckTimelineEmotion(!checkTimelineEmotion);
  });
  const handleChangeCheckAdminEmotion = useCallback((e) => {
    setCheckAdminEmotion(!checkAdminEmotion);
  });
  const handleChangeCheckRecognition = useCallback((e) => {
    setCheckRecognition(!checkRecognition);
  });

  async function onClickButton() {

    let dataInput = {
      commentaryQuestionInTimeline: checkTimelineQuestion,
      commentaryQuestionForAdmins: checkAdminQuestion,
      commentaryEmotionInTimeline: checkTimelineEmotion,
      commentaryEmotionForAdmins: checkAdminEmotion,
      recognitionInTimeline: checkRecognition
    };
    console.log(dataInput);

    let uid = dbuser.getUserByUid.uid;
    let id = dbuser.getUserByUid.id;
    try {
      let response = await changePrivacity({
        variables: { id: id, input: dataInput },
        refetchQueries: [
          { query: GET_USER_BY_UID, variables: { uid, uid } }
        ],
      });
      console.log(response);
      getUserWithFirebase()
      setOpenSnackbar(true, () => {
        setTimeout(function () {
          setOpenSnackbar(false);
        }, 5000); //5 Second delay
      });

      if (response.data.saveAnswerEmotion) {
        console.log("guardado correctamente");
      }
      window.location.reload();
    }
    catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        key={"top,center"}
        open={openSnackbar}
        // classes={{ root: "toastr-primary" }}
        autoHideDuration={4000}
        onClose={() => setOpenSnackbar(false)}
        message={"Se han actualizado tu preferencias de privacidad"}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          style={{ color: "white" }}
        >
          Se han actualizado tu preferencias de privacidad
        </Alert>
      </Snackbar>
      <Card className="card-box">
        <div className="card-header">
          <h4 className="font-size-lg mb-0 py-2 font-weight-bold">
            Cambia tu configuración de privacidad
          </h4>
        </div>
        <CardContent>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex">
              <img
                alt="..."
                className="card-img-top rounded-sm mr-3"
                src={emotion}
                style={{ width: 50, height: 50 }}
              />
              <div>
                <b>Mostrar los comentarios de cómo fue mi jornada en el timeline</b>
                {/* <div className="text-black-50">Mostrar los comentarios de cómo fue mi jornada en el timeline</div> */}
                {checkTimelineEmotion ?
                  <div ><a style={{ color: "red" }}>activado</a></div>
                  :
                  <div ><a style={{ color: "green" }}>desactivado</a></div>
                }
              </div>
            </div>

            <div className="font-weight-bold text-warning font-size-lg">

              <div className="d-flex flex-row m-2">
                <div className="mr-2">
                  <h6>Activar / Desactivar</h6>
                </div>
                <Switch
                  onChange={handleChangeCheckTimelineEmotion}
                  checked={checkTimelineEmotion}
                  color="primary"
                  name="checkedA"
                  className="switch-small"
                />
              </div>
            </div>
          </div>
          <div className="divider my-3" />
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex">
              <img
                alt="..."
                className="card-img-top rounded-sm mr-3"
                src={pregunta}
                style={{ width: 50, height: 50 }}
              />
              <div>
                <b>Mostrar los comentarios de la pregunta de clima en el timeline</b>
                {/* <div className="text-black-50">Mostrar los comentarios de la pregunta de clima en el timeline</div> */}
                {checkTimelineQuestion ?
                  <div ><a style={{ color: "red" }}>activado</a></div>
                  :
                  <div ><a style={{ color: "green" }}>desactivado</a></div>
                }
              </div>
            </div>
            <div className="font-weight-bold text-warning font-size-lg">
              <div className="d-flex flex-row m-2">
                <div className="mr-2">
                  <h6>Activar / Desactivar</h6>
                </div>
                <Switch
                  onChange={handleChangeCheckTimelineQuestion}
                  checked={checkTimelineQuestion}
                  color="primary"
                  name="checkedA"
                  className="switch-small"
                />
              </div>
            </div>
          </div>
          <div className="divider my-3" />
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex">
              <img
                alt="..."
                className="card-img-top rounded-sm mr-3"
                src={recognition}
                style={{ width: 50, height: 50 }}
              />
              <div>
                <b>Mostrar los Reconocimientos que envío en el timeline</b>
                {/* <div className="text-black-50">Mostrar los Reconocimientos que envío en el Timeline</div> */}
                {checkRecognition ?
                  <div ><a style={{ color: "red" }}>activado</a></div>
                  :
                  <div ><a style={{ color: "green" }}>desactivado</a></div>
                }
              </div>
            </div>
            <div className="font-weight-bold text-warning font-size-lg">
              <div className="d-flex flex-row m-2">
                <div className="mr-2">
                  <h6>Activar / Desactivar</h6>
                </div>
                <Switch
                  onChange={handleChangeCheckRecognition}
                  checked={checkRecognition}
                  color="primary"
                  name="checkedA"
                  className="switch-small"
                />
              </div>
            </div>
          </div>
        </CardContent>
        <div className="pt-4 text-center">
          <Button
            onClick={onClickButton}
            className="btn-warning font-weight-bold rounded hover-scale-lg mx-1 mb-3"
            size="large"
          // component={Link} to="/homeuser/"
          >
            <span className="btn-wrapper--label">Actualizar</span>
          </Button>
        </div>
      </Card>

    </>
  )
}
