import React, { useState, useContext } from 'react';

import {
  Grid,
  Card,
  TextField,
  Button
} from '@material-ui/core';
import { useQuery, useMutation } from "@apollo/react-hooks";
import { GET_COMPANY_AND_ADMINS_BY_COMPANY_ID } from "../../../../data/queries";
import { EDIT_COMPANY } from "../../../../data/mutations";
import { UserContext } from "../../../../context/UserContext";
import SnackBarMessage from "../../../../utils/SnackBarMessage";
import * as Yup from "yup";
import { Formik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function AdditionalInformation({ companyData }) {

  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  let companyId = dbuser.getUserByUid.companyId;

  let { website, nif,name,legalName  } = companyData;
  let { country, province, address } = companyData.offices[0];

  const [addressData, setAddress] = useState(address);
  const [web, setWeb] = useState(website);
  const [countryData, setCountry] = useState(country);
  const [provinceData, setProvince] = useState(province);

  const [options, setOptions] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [messageAlert, setMessageAlert] = React.useState('');
  const [severity, setSeverity] = React.useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [editCompany, { data }] = useMutation(EDIT_COMPANY);

  const validationSchema = Yup.object().shape({
  });

  console.log(companyData)
  console.log(name)
  
  return (
    <>
      <SnackBarMessage
        open={openSnackbar}
        handleClose={() => setOpenSnackbar(false)}
        message={messageAlert}
        severity={severity}
      />

      <Formik
        initialValues={{
          name: name ? name : null,
          legalName: legalName ? legalName : null,
          nif: nif ? nif : null,
          website: website ? website : null,
          country: country ? country : null,
          province: province ? province : null,
          address: address ? address : null,
        }}
        onSubmit={async (values, actions) => {
          try {
            let offices = [{
              country: null,
              province: null,
              address: null
            }]
            offices[0].country = values.country ? values.country : null
            offices[0].province = values.province ? values.province : null
            offices[0].address = values.address ? values.address : null

            let dataInput = {
              website: values.website ? values.website : null,
              nif: values.nif ? values.nif : null,
              name: values.name ? values.name : null,
              legalName: values.legalName ? values.legalName : null,
              offices: offices
            };
            let response = await editCompany({
              variables: { id: companyId, input: dataInput },
              refetchQueries:[
                { query: GET_COMPANY_AND_ADMINS_BY_COMPANY_ID, variables: { id: companyId } }]
            });
            setMessageAlert("Datos de compañia editados")
            setSeverity("success")

            setMessage('')
            setOptions('')
          } catch (err) {
            console.log(err);
            setMessageAlert("Error al editar compañía")
            setSeverity("error")
          }

          setOpenSnackbar(true, () => {
            setTimeout(function () {
              setOpenSnackbar(false);
            }, 5000);
          });

        }}
        validationSchema={validationSchema}
      >
        {(props) => (

          <>
            <form onSubmit={props.handleSubmit} onReset={props.handleReset}>
              <Grid container>
                <Grid item md={4} className="my-auto">
                  <div className="text-center">
                    <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-primary shadow-primary-sm text-primary d-90">
                      <FontAwesomeIcon
                        icon={["fas", "building"]}
                        className="d-flex align-self-center font-size-xxl"
                      />
                    </div>
                    <h6 className="font-weight-bold font-size-xxl mb-1 mt-3 text-primary">
                      Información Básica
                    </h6>
                  </div>
                </Grid>
                <Grid item md={8}>

                  <Grid container>
                    <Grid item xs={12}>
                      <Card className="p-4 mt-4" style={{ backgroundColor: '#eceef7' }} elevation={0}>
                        <div>
                          <TextField
                            fullWidth
                            className="m-2"
                            id="outlined-multiline-static"
                            label="Nombre legal"
                            placeholder="Ninguno"
                            variant="outlined"
                            name="legalName"
                            value={props.values.legalName}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={Boolean(
                              props.touched.legalName && props.errors.legalName
                            )}
                            helperText={
                              props.touched.legalName ? props.errors.legalName : ""
                            }
                          />
                          <TextField
                            fullWidth
                            className="m-2"
                            id="outlined-multiline-static"
                            label="Nombre comercial"
                            placeholder="Ninguno"
                            variant="outlined"
                            name="name"
                            value={props.values.name}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={Boolean(
                              props.touched.name && props.errors.name
                            )}
                            helperText={
                              props.touched.name ? props.errors.name : ""
                            }
                          />
                          <TextField
                            fullWidth
                            className="m-2"
                            id="outlined-multiline-static"
                            label="CIF"
                            placeholder="Ninguno"
                            variant="outlined"
                            name="nif"
                            value={props.values.nif}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={Boolean(
                              props.touched.nif && props.errors.nif
                            )}
                            helperText={
                              props.touched.nif ? props.errors.nif : ""
                            }
                          />
                          <TextField
                            fullWidth
                            disabled
                            className="m-2"
                            id="outlined-multiline-static"
                            label="Número de usuarios contratados para registrar"
                            placeholder="Ninguno"
                            variant="outlined"
                            // name="numberUsersToRegister"
                            value={companyData.numberUsersToRegister}
                            // error={Boolean(
                            //   props.touched.numberUsersToRegister && props.errors.numberUsersToRegister
                            // )}
                            // helperText={
                            //   props.touched.numberUsersToRegister ? props.errors.numberUsersToRegister : ""
                            // }
                          />
                        </div>
                      </Card>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>

              <Grid container>
                <Grid item md={4} className="my-auto">
                  <div className="text-center">
                    <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-primary shadow-primary-sm text-primary d-90">
                      <FontAwesomeIcon
                        icon={["fas", "plus"]}
                        className="d-flex align-self-center font-size-xxl"
                      />
                    </div>
                    <h6 className="font-weight-bold font-size-xxl mb-1 mt-3 text-primary">
                      Información Complementaria
                    </h6>
                  </div>
                </Grid>
                <Grid item md={8}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Card className="p-4 mt-4" style={{ backgroundColor: '#eceef7' }} elevation={0}>
                        <div>
                          <TextField
                            fullWidth
                            className="m-2"
                            id="outlined-multiline-static"
                            label="Dirección"
                            placeholder="Placeholder"
                            variant="outlined"
                            name="address"
                            value={props.values.address}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={Boolean(
                              props.touched.address && props.errors.address
                            )}
                            helperText={
                              props.touched.address ? props.errors.address : ""
                            }
                          />
                          <TextField
                            fullWidth
                            className="m-2"
                            id="outlined-multiline-static"
                            label="Web"
                            placeholder="Placeholder"
                            variant="outlined"
                            name="website"
                            value={props.values.website}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={Boolean(
                              props.touched.website && props.errors.website
                            )}
                            helperText={
                              props.touched.website ? props.errors.website : ""
                            }
                          />
                          <TextField
                            fullWidth
                            className="m-2"
                            id="outlined-multiline-static"
                            label="País"
                            placeholder="Placeholder"
                            variant="outlined"
                            name="country"
                            value={props.values.country}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={Boolean(
                              props.touched.country && props.errors.country
                            )}
                            helperText={
                              props.touched.country ? props.errors.country : ""
                            }
                          />
                          <TextField
                            fullWidth
                            className="m-2"
                            id="outlined-multiline-static"
                            label="Provincia"
                            placeholder="Placeholder"
                            variant="outlined"
                            name="province"
                            value={props.values.province}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={Boolean(
                              props.touched.province && props.errors.province
                            )}
                            helperText={
                              props.touched.province ? props.errors.province : ""
                            }
                          />
                        </div>
                      </Card>
                    </Grid>
                    <div className="pt-4 text-center">
                      <Button
                        // onClick={handleReset}
                        className="btn-warning font-weight-bold rounded hover-scale-lg mx-1"
                        size="large"
                        type="submit"
                      // component={Link} to="/homeuser/"
                      >
                        <span className="btn-wrapper--label">Actualizar</span>
                      </Button>
                    </div>
                  </Grid>

                </Grid>
              </Grid>


            </form>
          </>
        )}
      </Formik>


    </>
  );
}
