// import React, { useEffect, useState, useContext } from "react";
// import { PageTitle } from 'layout-components';
// import clsx from "clsx";
// import { useHistory } from 'react-router-dom';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   Grid,
//   Container,
//   InputAdornment,
//   ButtonGroup,
//   Card,
//   Button,
//   List,
//   ListItem,
//   TextField,
// } from "@material-ui/core";
// import {
//   CircularProgressbar,
//   CircularProgressbarWithChildren,
//   buildStyles,
// } from "react-circular-progressbar";

// import PerfectScrollbar from "react-perfect-scrollbar";
// import { useDropzone } from "react-dropzone";
// import CountUp from "react-countup";

// import CloseTwoToneIcon from "@material-ui/icons/CloseTwoTone";
// import PublishTwoToneIcon from "@material-ui/icons/PublishTwoTone";
// import AccountCircleTwoToneIcon from "@material-ui/icons/AccountCircleTwoTone";
// import CheckIcon from "@material-ui/icons/Check";
// // import ReceivedRecognitions from "./components/ReceivedRecognitions"
// // import PreviousRecognitions from "./components/PreviousRecognitions"
// // import SentRecognitions from "./components/SentRecognitions"
// import { UserContext } from "../../../context/UserContext";
// //import General from "./components/General"
// // import EditProfile from "./components/EditProfile"
// // import Privacity from "./components/Privacity"
// // import Groups from "./components/Groups"

// import CompanyUsers  from "./components/CompanyUsers"
// import CompanyGroups  from "./components/CompanyGroups"
// import UsersTable from "../../Admin/Users/components/UsersTable"
// import TeamsTable from "../../Admin/Teams/components/TeamsTable"
// import CompanyInfo from "./components/CompanyInfo"


// export default function Company ({match}) {
//   const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
//   const [inputBg, setInputBg] = useState(false);
//   const toggleInputBg = () => setInputBg(!inputBg);
//   const history = useHistory()
//   const [activeTab, setActiveTab] = useState("1");

//   const toggle = (tab) => {
//     if (activeTab !== tab) setActiveTab(tab);
//   };

//   const [activeTab2, setActiveTab2] = useState("1");

//   const toggle2 = (tab) => {
//     if (activeTab2 !== tab) setActiveTab2(tab);
//   };


//   if (dbuser) {
//     return (
//       <>
//       <PageTitle
//         titleHeading="Panel de Superadmin"
//         titleDescription="Gestión de las empresas de Climind."
//       />
//         <div className="app-inner-content-layout">
//           <div className="app-inner-content-layout--main bg-white p-0">
//             <div className="hero-wrapper rounded-bottom shadow-xxl bg-composed-wrapper bg-second">
//               <div className="flex-grow-1 w-100 d-flex align-items-center">
//                 {/* <div
//                   className="bg-composed-wrapper--image rounded-bottom opacity-3"
//                   backgroundColor="orange"
//                   style={{ backgroundColor: "white" }}
//                 /> */}
//                 <div className="bg-composed-wrapper--bg rounded-bottom bg-deep-sky opacity-4" />
//                 <div className="bg-composed-wrapper--content px-3">
//                   <Container className="pt-4" style={{ width: "80%" }}>
//                     <List className="d-flex nav-tabs justify-content-center nav-tabs-warning tabs-animated tabs-animated-shadow my-5">
//                     <ListItem
//                         button
              
//                         className="bg-white-10 mx-3 rounded-lg"
//                         onClick={() => {
//                           history.goBack()
//                         }}
//                       >
//                         <span className="font-size-lg text-white px-2 py-1">
//                           Atrás
//                         </span>
//                         <div className="divider" />
//                       </ListItem>
//                       <ListItem
//                         button
//                         selected={activeTab2 === "1"}
//                         className="bg-white-10 mx-3 rounded-lg"
//                         onClick={() => {
//                           toggle2("1");
//                         }}
//                       >
//                         <span className="font-size-lg text-white px-2 py-1">
//                           Información de Empresa
//                         </span>
//                         <div className="divider" />
//                       </ListItem>

//                       <ListItem
//                         button
//                         selected={activeTab2 === "2"}
//                         className="bg-white-10 mx-3 rounded-lg"
//                         onClick={() => {
//                           toggle2("2");
//                         }}
//                       >
//                         <span className="font-size-lg text-white px-2 py-1">
//                           Usuarios
//                         </span>
//                         <div className="divider" />
//                       </ListItem>

//                       <ListItem
//                         button
//                         selected={activeTab2 === "3"}
//                         className="bg-white-10 mx-3 rounded-lg"
//                         onClick={() => {
//                           toggle2("3");
//                         }}
//                       >
//                         <span className="font-size-lg text-white px-2 py-1">
//                         Grupos y departamentos
//                         </span>
//                         <div className="divider" />
//                       </ListItem>

                      

                     
//                     </List>
//                   </Container>
//                 </div>
//               </div>
//             </div>
//             {/* <Container className="z-over py-5" style={{width:"80%"}}> */}
//             <div className="p-5">
//               <div
//                 className={clsx("tab-item-wrapper overflow-visible d-none", {
//                   "d-block active": activeTab2 === "1",
//                 })}
//                 index={1}
//               >
//                 <CompanyInfo companyId={match.params.id}/>
//               </div>
              
//               <div
//                 className={clsx("tab-item-wrapper overflow-visible d-none", {
//                   "d-block active": activeTab2 === "2",
//                 })}
//                 index={2}
//               >
//                 <UsersTable compId={match.params.id}/>
//               </div>
//               <div
//                 className={clsx("tab-item-wrapper overflow-visible d-none", {
//                   "d-block active": activeTab2 === "3",
//                 })}
//                 index={3}
//               >
//                   <TeamsTable compId={match.params.id}/>
//               </div>
//               </div>
//             {/* </Container> */}
//           </div>
//         </div>
//       </>
//     );
//   } else {
//     return null;
//   }
// }


import React, { useState } from 'react';

import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  Card,
  CardContent,
  Button,
  List,
  ListItem,
  Tooltip,
  TextField
} from '@material-ui/core';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { useHistory } from 'react-router-dom';

import CompanyInfo from "./components/CompanyInfo"
import UsersTable from "../../Admin/Users/components/UsersTable"
import TeamsTable from "../../Admin/Teams/components/TeamsTable"
import Permissions from "./components/Permissions"
import Ranking from "./Ranking/Ranking"
import Shop from "./Shop/Shop"

export default function Company({match}) {
  const [isSidebarMenuOpen, setIsSidebarMenuOpen] = useState(false);
  const [isSidebarMenuOpen2, setIsSidebarMenuOpen2] = useState(false);

  const toggleSidebarMenu = () => setIsSidebarMenuOpen(!isSidebarMenuOpen);
  const toggleSidebarMenu2 = () => setIsSidebarMenuOpen2(!isSidebarMenuOpen2);

  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [activeTab2, setActiveTab2] = useState("1");

  const toggle2 = (tab) => {
    if (activeTab2 !== tab) setActiveTab2(tab);
  };

  const [display, setDisplay] = useState(1);
  const [inputBg, setInputBg] = useState(false);
  const toggleInputBg = () => setInputBg(!inputBg);

  const history = useHistory()

  return (
    <>
      <div className="app-inner-content-layout app-inner-content-layout-fixed">
        <div className="btn-md-pane d-lg-none px-4 order-0">
          <Button
            onClick={toggleSidebarMenu}
            size="small"
            className="btn-primary p-0 btn-icon d-40">
            <FontAwesomeIcon icon={['fas', 'ellipsis-v']} />
          </Button>
        </div>
        <div
          className={clsx(
            'app-inner-content-layout--sidebar bg-white app-inner-content-layout--sidebar__lg order-1',
            { 'layout-sidebar-open': isSidebarMenuOpen }
          )}>
          <PerfectScrollbar>
            <div className="px-4 pt-4">
              <List
                component="div"
                className="nav-pills nav-neutral-primary flex-column">
                <ListItem className="d-flex px-0 pt-1 pb-3 justify-content-between">
                  <div className="text-uppercase font-size-sm text-primary font-weight-bold">
                    General
                  </div>
                </ListItem>
                <ListItem
                  button
                  onClick={() => {toggle2("1")}}
                  selected={activeTab2 === "1"}>
                  Usuarios
                </ListItem>
                <ListItem
                  button
                  onClick={() => {toggle2("2")}}
                  selected={activeTab2 === "2"}>
                  Grupos y departamentos
                </ListItem>
                <ListItem
                  button
                  onClick={() => {toggle2("4")}}
                  selected={activeTab2 === "4"}>
                  Ranking
                </ListItem>
                <ListItem
                  button
                  onClick={() => {toggle2("5")}}
                  selected={activeTab2 === "5"}>
                  Centro de bienestar
                </ListItem>
                <ListItem
                  button
                  onClick={() => {toggle2("6")}}
                  selected={activeTab2 === "6"}>
                  Permisos y funcionalidades
                </ListItem>
                <ListItem
                  button
                  onClick={() => {toggle2("3")}}
                  selected={activeTab2 === "3"}
                  >
                  Información Básica
                </ListItem>
              </List>
              <div className="divider my-3" />
              <List
                component="div"
                className="nav-pills nav-neutral-primary flex-column">
                <ListItem className="d-flex px-0 pt-1 pb-3 justify-content-between">
                  <div className="text-uppercase font-size-sm text-primary font-weight-bold">
                    Dashboards
                  </div>
                </ListItem>

                <ListItem
                  component="a"
                  button
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  selected>
                  Uso
                </ListItem>
                <ListItem
                  component="a"
                  button
                  href="#/"
                  onClick={(e) => e.preventDefault()}>
                  Clima
                </ListItem>
                <ListItem
                  component="a"
                  button
                  href="#/"
                  onClick={(e) => e.preventDefault()}>
                  Pulso
                </ListItem>
                <ListItem
                  component="a"
                  button
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  selected>
                  Feedback
                </ListItem>
                <ListItem
                  component="a"
                  button
                  href="#/"
                  onClick={(e) => e.preventDefault()}>
                  Retos
                </ListItem>
                <ListItem
                  component="a"
                  button
                  href="#/"
                  onClick={(e) => e.preventDefault()}>
                  OKR'S
                </ListItem>
              </List>
              <div className="divider my-3" />
              <div className="divider my-3" />
              <List
                component="div"
                className="nav-pills nav-neutral-primary flex-column">
                <ListItem className="pt-1 px-0 pb-3">
                  <div className="text-uppercase font-size-sm text-primary font-weight-bold">
                    Datos globales
                  </div>
                </ListItem>
              </List>
              <Grid container spacing={6} className="font-size-xs">
                <Grid item lg={6}>
                  <Card className="shadow-success-sm card-box text-center mb-4 p-3">
                    <div>
                      <FontAwesomeIcon
                        icon={['far', 'user']}
                        className="font-size-xxl text-success"
                      />
                    </div>
                    <div className="mt-2 line-height-sm">
                      <b className="font-size-lg">123</b>
                      <span className="text-black-50 d-block">Usuarios</span>
                    </div>
                  </Card>
                </Grid>
                <Grid item lg={6}>
                  <Card className="shadow-danger-sm card-box text-center mb-4 p-3">
                    <div>
                      <FontAwesomeIcon
                        icon={['far', 'user']}
                        className="font-size-xxl text-danger"
                      />
                    </div>
                    <div className="mt-2 line-height-sm">
                      <b className="font-size-lg">102</b>
                      <span className="text-black-50 d-block">Activos</span>
                    </div>
                  </Card>
                </Grid>
              </Grid>

            </div>
          </PerfectScrollbar>
        </div>
        <div className="app-inner-content-layout--main order-3 order-lg-2 card-box bg-secondary">
          <PerfectScrollbar>
            <div className="card-header rounded-0 bg-white p-4 border-bottom">
              <div className="card-header--title">
                <small>Titulo Pagina</small>
                <b>titulo pagina</b>
              </div>
              <div className="card-header--actions">
                <Tooltip title="Atrás">
                  <Button 
                    size="small" 
                    className="btn-first btn-pill d-40 p-0"
                    onClick={() => {
                      history.goBack()
                    }}
                  >
                    <FontAwesomeIcon icon={['fas', 'arrow-left']} />
                  </Button>
                </Tooltip>
              </div>
            </div>
            <div className="p-5">
              {/* {activeTab2 == "1" && <CompanyInfo companyId={match.params.id}/>}
              {activeTab2 == "2" && <UsersTable compId={match.params.id}/>}
              {activeTab2 == "3" && <TeamsTable compId={match.params.id}/>} */}
              <div
                className={clsx("tab-item-wrapper overflow-visible d-none", {
                  "d-block active": activeTab2 === "1",
                })}
                index={1}
              >
                <UsersTable compId={match.params.id}/>
              </div>
              <div
                className={clsx("tab-item-wrapper overflow-visible d-none", {
                  "d-block active": activeTab2 === "2",
                })}
                index={2}
              >
                  <TeamsTable compId={match.params.id}/>
              </div>
              <div
                className={clsx("tab-item-wrapper overflow-visible d-none", {
                  "d-block active": activeTab2 === "3",
                })}
                index={3}
              >
                <CompanyInfo companyId={match.params.id}/>
              </div>
              <div
                className={clsx("tab-item-wrapper overflow-visible d-none", {
                  "d-block active": activeTab2 === "4",
                })}
                index={4}
              >
                <Ranking companyId={match.params.id}/>
              </div>
              <div
                className={clsx("tab-item-wrapper overflow-visible d-none", {
                  "d-block active": activeTab2 === "5",
                })}
                index={5}
              >
                <Shop companyId={match.params.id}/>
              </div>
              <div
                className={clsx("tab-item-wrapper overflow-visible d-none", {
                  "d-block active": activeTab2 === "6",
                })}
                index={6}
              >
                <Permissions companyId={match.params.id}/>
              </div>
              </div>
          </PerfectScrollbar>
        </div>
        <div
          onClick={toggleSidebarMenu}
          className={clsx('sidebar-inner-layout-overlay', {
            active: isSidebarMenuOpen
          })}
        />
        <div
          onClick={toggleSidebarMenu2}
          className={clsx('sidebar-inner-layout-overlay', {
            active: isSidebarMenuOpen2
          })}
        />
      </div>
    </>
  );
}
