import React, { useState, useCallback, useContext, useEffect } from "react";
import {
  GET_COMPETENCES_BY_USER_ID,
  GET_RECOGNITIONS_RECEIVED_BY_USER_ID,
  GET_RECOGNITIONS_RECEIVED_BY_USER_ID_GROUPS
} from "data/queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "../../context/UserContext";
import { useQuery } from "@apollo/react-hooks";
import { PageTitle } from "layout-components";
import {
  Grid,
  LinearProgress,
  Card,
  Button,
  Dialog,
  List,
  ListItem,
  Tooltip,
  Chip,
} from "@material-ui/core";

export default function CurriculumGroups() {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);

  const {
    loading: loadingRecognitions,
    error: errorRecognitions,
    data: dataRecognitions,
    networkStatus: networkStatusRecognitions,
    refetch: refetchRecognitions,
  } = useQuery(GET_RECOGNITIONS_RECEIVED_BY_USER_ID_GROUPS, {
    variables: { id: dbuser.getUserByUid.id },
    skip: !dbuser.getUserByUid.id,
    notifyOnNetworkStatusChange: true,
  });

  console.log(dataRecognitions);
  const variables = [
    {
      variable: "Todas las competencias",
      color: "grey",
    },
    {
      variable: "Emocionales",
      color: "#FF3146",
    },
    {
      variable: "Transversales",
      color: "#00029E",
    },
    {
      variable: "Sociales",
      color: "#E6D600",
    },
    {
      variable: "Digitales",
      color: "#00ACDF",
    },
  ];

  const [totalCount, setTotalCount] = useState(0)
  const [variablesList, setVariablesList] = useState([]);
  const [variablesListFilter, setVariablesListFilter] = useState([]);

  useEffect(() => {
    if (dataRecognitions != null) {
      let count = 0
      setVariablesList([...dataRecognitions.getRecognitionsReceivedByUserIdGroups]);
      setVariablesListFilter([...dataRecognitions.getRecognitionsReceivedByUserIdGroups]);
      if (dataRecognitions.getRecognitionsReceivedByUserIdGroups.length > 0) {
        dataRecognitions.getRecognitionsReceivedByUserIdGroups.forEach(element => {
          console.log(element)
          count = count + element.count
        });
      }
      setTotalCount(count)
    }
  }, [dataRecognitions]);

  const handleClickFilter = (element) => () => {
    if (element.color != "grey") {
      const filteredVariables = variablesList.filter((o) =>
        Object.keys(o.competence).some(
          (k) => 
            k === "color" &&
            String(o.competence[k]).toLowerCase().includes(element.color.toLowerCase())
        )
      );
      setVariablesListFilter(filteredVariables);
    } else {
      setVariablesListFilter(variablesList);
    }
  };
  if (dataRecognitions) {
    if (dataRecognitions.getRecognitionsReceivedByUserIdGroups.length > 0) {
      return (
        <>
       

          <Grid item xl={12}>
            <div className="mb-spacing-6 text-center">
              {variables.map((element, index) => {
                return (
                  <Chip
                    variant="outlined"
                    style={{
                      backgroundColor: element.color,
                      color: "white",
                      borderColor: element.color,
                      marginRight: "10px",
                      fontSize: 18,
                      marginBottom: "10px"
                    }}
                    label={element.variable}
                    key={element.color}
                    onClick={handleClickFilter(element)}
                  />
                );
              })}
            </div>
            {/* <h5 className="m-4 text-center font-weight-bold" style={{ color: "#5a318e" }}>Recibe <a style={{ color: "orange" }}>100</a> competencias y podrás descargar tu curriculum de soft skills</h5>
            <div className="text-center">
            <Button
                variant="text"
                className="btn-outline-primary mt-1"
                disabled
              >
                <span className="btn-wrapper--label">Descargar CV</span>
              </Button>
              </div> */}
            <h5 className="m-4 text-center font-weight-bold" style={{ color: "#5a318e" }}>Has recibido un total <a style={{ color: "orange" }}>{totalCount}</a> de Soft Skills por parte de grupos o departamentos</h5>
            <div className="mb-spacing-12">
              <Grid container spacing={4}>
                {variablesListFilter.map((element, index) => {
                  return (
                    <Grid item xl={2} md={3} sm={6}>
                      <Card
                        className="shadow-xxl"
                        style={{
                          borderRadius: "12px",
                          border: `solid 4px ${element.competence.color}`,
                        }}
                      >
                        <a
                          href="#/"
                          onClick={(e) => e.preventDefault()}
                          className="card-img-wrapper rounded"
                        >
                          <div className="img-wrapper-overlay">
                            <div className="overlay-btn-wrapper">
                              <div className="font-size-lg font-weight-bold mb-2">
                                {element.competence.name}
                              </div>

                              <Tooltip
                                classes={{
                                  tooltip: "text-center p-3 tooltip-secondary",
                                }}
                                arrow
                                placement="top"
                                title={<h6>{element.competence.description}</h6>}
                              >
                                <Button
                                  size="small"
                                  className="btn-info btn-pill px-4 hover-scale-sm"
                                >
                                  <span className="btn-wrapper--icon">
                                    <FontAwesomeIcon icon={["fas", "info"]} />
                                  </span>
                                  <span className="btn-wrapper--label">
                                    Información
                                  </span>
                                </Button>
                              </Tooltip>
                            </div>
                          </div>
                          <img
                            src={element.competence.urlImage}
                            className="card-img-top rounded"
                            alt="..."
                          />
                        </a>
                      </Card>
                      <h5 className="text-center font-weight-bold mt-4" >
                        Recibidos: <a style={{ color: "orange" }}>{element.count}</a>
                      </h5>
                      {/* <h5 className="text-center font-weight-bold mt-4">
                        {element.competence.name}
                      </h5> */}
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </Grid>
        </>
      );
    } else {
      return <h5 className="m-5 text-center font-weight-bold" style={{ color: "#5a318e" }}>Aun no has recibido ninguna competencia</h5>
    }
  } else {
    return null;
  }
}
