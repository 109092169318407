import React, { useState, useCallback, useEffect, useContext } from "react";
import { GET_COMPETENCES_BY_USER_ID } from "data/queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "../../../../context/UserContext";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  Table,
  LinearProgress,
  Card,
  CardContent,
  Button,
  Tooltip,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { EDIT_USER } from "../../../../data/mutations";
import { GET_DEPARTMENTS_GROUPS_AND_USER } from "../../../../data/queries";

import { useFormik } from "formik";
import * as Yup from "yup";

import { Formik } from "formik";
import MaskedInput from "react-input-mask";

import PropTypes from 'prop-types';

import SnackBarMessage from "../../../../utils/SnackBarMessage";


function TextMaskDate(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
      guide={true}
    />
  );
}
TextMaskDate.propTypes = {
  inputRef: PropTypes.func.isRequired
};

function TextMaskCc(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]}
      placeholderChar={'\u2000'}
      showMask
      guide={true}
    />
  );
}
TextMaskCc.propTypes = {
  inputRef: PropTypes.func.isRequired
};

export default function ModalEditEmployee({
  openEditEmployee,
  toggleEditEmployee,
  dataEmployee,
  compId,
  refetch,
  setPage
}) {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  let companyId = null;
  if (compId) {
    companyId = compId;
  } else {
    companyId = dbuser.getUserByUid.companyId;
  }
console.log(dataEmployee)
  const [editUser, { data }] = useMutation(EDIT_USER);
  const [messageAlert, setMessageAlert] = React.useState('');
  const [severity, setSeverity] = React.useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(/^[A-zÀ-ÿ\s]*$/, {
        message: "Solo están permitidos letras A-Z",
      })
      .required("Obligatorio"),
    surname: Yup.string()
      .matches(/^[A-zÀ-ÿ\s]*$/, {
        message: "Solo están permitidos letras A-Z",
      })
      .required("Obligatorio"),
    // email: Yup.string().email("Email invalido").required("Obligatorio"),
    dni: Yup.string()
    .matches(/^([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])$/, {
      message: "El documento de identidad no es valido",
    })
      .required("Obligatorio"),
    role: Yup.string().required("Obligatorio"),
    gender: Yup.string().required("Obligatorio"),
    position: Yup.string().required("Obligatorio"),
  });

  

  return (
    <>
      <SnackBarMessage
        open={openSnackbar}
        handleClose={() => setOpenSnackbar(false)}
        message={messageAlert}
        severity={severity}
      />
      <Dialog
        open={openEditEmployee}
        onClose={toggleEditEmployee}
        classes={{ paper: "modal-content rounded-lg" }}
      >
        <DialogTitle className="bg-white-10">Editar Empleado</DialogTitle>

        <Formik
          initialValues={{
            name: dataEmployee.name,
            surname: dataEmployee.surname,
            dni: dataEmployee.dni,
            role: dataEmployee.role,
            gender: dataEmployee.gender,
            position: dataEmployee.position,
          }}
          onSubmit={async (values, actions) => {
            try {
              let dataInput = {
                name: values.name,
                surname: values.surname,
                dni: values.dni,
                role: values.role,
                gender: values.gender,
                position: values.position,
              };
              console.log(dataInput);
              let response = await editUser({
                variables: { id: dataEmployee.id, input: dataInput }
              })
              if(response.data){
                setPage(1)
                refetch()
                setSeverity("success")
                setMessageAlert("Usuario Editado")
              }else{
                setSeverity("error")
                setMessageAlert("Error al editar al usuario")
              }
              setOpenSnackbar(true, () => {
                setTimeout(function () {
                    setOpenSnackbar(false);
                }, 5000);
              });
              
              toggleEditEmployee();
            } catch (err) {
              setSeverity("error")
              setMessageAlert(err.message)
              setOpenSnackbar(true, () => {
                setTimeout(function () {
                    setOpenSnackbar(false);
                }, 5000);
              });
            }
          }}
          validationSchema={validationSchema}
        >
          {(props) => {
            return (
              <form onSubmit={props.handleSubmit} onReset={props.handleReset}>
                <DialogContent>
                  <Grid container>
                    <Grid item xs={12}>
                      <div className="text-center my-auto">
                        {dataEmployee.urlImage === null ||
                          dataEmployee.urlImage === "" ? (
                          <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-primary shadow-primary-sm text-primary mb-2 d-90">
                            {dataEmployee.name.charAt(0).toUpperCase()}
                          </div>
                        ) : (
                          <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-primary shadow-primary-sm text-primary mb-2 d-90">
                            <img alt="..." className="img-fluid" src={dataEmployee.urlImage} />
                          </div>
                        )}
                        <h6 className="font-weight-bold font-size-xxl mb-1 mt-3 text-primary">
                          Información Básica
                        </h6>
                      </div>
                      <Card
                        className="p-4 mt-4"
                        style={{ backgroundColor: "#eceef7" }}
                        elevation={0}
                      >
                        <div>
                          <TextField
                            fullWidth
                            className="m-2"
                            id="outlined-multiline-static-1"
                            name="name"
                            label="Nombre"
                            placeholder="Escribe aqui..."
                            variant="outlined"
                            value={props.values.name}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={Boolean(props.touched.name && props.errors.name)}
                            helperText={
                              props.touched.name ? props.errors.name : ""
                            }
                          />
                          <TextField
                            fullWidth
                            className="m-2"
                            id="outlined-multiline-static-2"
                            name="surname"
                            label="Apellidos"
                            placeholder="Escribe aqui..."
                            variant="outlined"
                            value={props.values.surname}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={Boolean(
                              props.touched.surname && props.errors.surname
                            )}
                            helperText={
                              props.touched.surname ? props.errors.surname : ""
                            }
                          />
                          <TextField
                            fullWidth
                            className="m-2"
                            id="outlined-multiline-static-3"
                            name="dni"
                            label="DNI"
                            placeholder="Escribe aqui..."
                            variant="outlined"
                            value={props.values.dni}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={Boolean(
                              props.touched.dni && props.errors.dni
                            )}
                            helperText={
                              props.touched.dni ? props.errors.dni : ""
                            }
                          />
                          <TextField
                            fullWidth
                            className="m-2"
                            id="outlined-multiline-static-4"
                            name="position"
                            label="Puesto de trabajo"
                            placeholder="Escribe aqui..."
                            variant="outlined"
                            value={props.values.position}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={Boolean(
                              props.touched.position && props.errors.position
                            )}
                            helperText={
                              props.touched.position ? props.errors.position : ""
                            }
                          />
                          {/* <TextField
                            fullWidth
                            className="m-2"
                            id="demo-simple-select-outlined"
                            select
                            name="role"
                            label="Rol"
                            value={props.values.role}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={Boolean(
                              props.touched.role && props.errors.role
                            )}
                            helperText={
                              props.touched.role ? props.errors.role : ""
                            }
                          >
                            <MenuItem value="user">Usuario</MenuItem>
                            <MenuItem value="admin">Admin</MenuItem>
                          </TextField> */}
                          <FormControl fullWidth variant="outlined" className="m-2">
                            <InputLabel id="demo-simple-select-outlined-label">
                              Sexo
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined"
                              id="demo-simple-select-outlined"
                              name="gender"
                              label="Sexo"
                              value={props.values.gender}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={Boolean(
                                props.touched.gender && props.errors.gender
                              )}
                              helperText={
                                props.touched.gender ? props.errors.gender : ""
                              }
                            >
                              <MenuItem value={"MALE"}>MASCULINO</MenuItem>
                              <MenuItem value={"FEMALE"}>FEMENINO</MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl fullWidth variant="outlined" className="m-2">
                            <InputLabel id="demo-simple-select-outlined-label">
                              Rol
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined"
                              id="demo-simple-select-outlined"
                              name="role"
                              label="Rol"
                              value={props.values.role}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={Boolean(
                                props.touched.role && props.errors.role
                              )}
                              helperText={
                                props.touched.role ? props.errors.role : ""
                              }
                            >
                              <MenuItem value="user">USUARIO</MenuItem>
                              <MenuItem value="admin">ADMINISTRADOR</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </Card>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions className="p-4 bg-white-10">
                  <Button
                    className="btn-neutral-primary"
                    onClick={() => toggleEditEmployee()}
                  >
                    Cancelar
                  </Button>{" "}
                  <Button className="btn-primary ml-auto" type="submit">
                    Guardar
                  </Button>
                </DialogActions>
              </form>
            )
          }
          }
        </Formik>
      </Dialog>
    </>
  );
}
