import React, { useState, useCallback, useContext } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Table,
  LinearProgress,
  Card,
  CardContent,
  Button,
  Tooltip,
  Dialog
} from '@material-ui/core';
import { UserContext } from "../../../../../../context/UserContext";
import { GET_OKRS_BY_COMPANYID } from "data/queries";
import { DELETE_OKR} from "data/mutations";
import avatar4 from '../../../../../../assets/images/avatars/avatar4.jpg';
import avatar5 from '../../../../../../assets/images/avatars/avatar5.jpg';
import avatar6 from '../../../../../../assets/images/avatars/avatar6.jpg';
import avatar7 from '../../../../../../assets/images/avatars/avatar7.jpg';
import ModalNewOkr from "./ModalNewOkr";
import ModalEditOkr from "./ModalEditOkr";
import ModalKeyResults from "./ModalKeyResults";
var moment = require("moment-timezone")
export default function DashboardOkr1({ dataOkr, refetch }) {
  console.log(dataOkr);
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  const [okrSelected, setOkrSelected] = useState(null);
  const [modalNewOkr, setModalNewOkr] = useState(false);
  const toggleNewOkr = () => setModalNewOkr(!modalNewOkr);
  const [modalEditOkr, setModalEditOkr] = useState(false);
  const toggleEditOkr = () => setModalEditOkr(!modalEditOkr);
  const [modalKeyResults, setModalKeyResults] = useState(false);
  const toggleKeyResults = () => setModalKeyResults(!modalKeyResults);
  const [modalDelete, setModalDelete] = useState(false);
  const toggleDelete = () => setModalDelete(!modalDelete);
  const [deleteOkr, { data: dataDelete }] = useMutation(DELETE_OKR);
  return (
    <>
      <Card className="card-box mb-spacing-6-x2">
        <div className="card-header pr-2">
          <div className="card-header--title">Lista de OKR's</div>
          <div className="card-header--actions">
          <div className="d-flex justify-content-between px-4 py-3">
            <div className="d-flex align-items-center px-2">
              <Button
                size="small"
                className="btn-neutral-primary"
                onClick={() => setModalNewOkr(true)}
              >
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={["fas", "plus-circle"]} />
                </span>
                <span className="btn-wrapper--label">Crear nuevo OKR</span>
              </Button>
            </div>
          </div>
          </div>
        </div>
        <CardContent>
          <div className="table-responsive-md">
            <Table className="table table-borderless table-hover text-nowrap mb-0">
              <thead>
                <tr>
                  {/* <th>#</th> */}
                  <th className="text-left">Objetivo</th>
                  <th className="text-left">Descripción</th>
                  <th className="text-center">Duración</th>
                  <th className="text-center">Acciones</th>
                  <th className="text-center">Gestionar resultados clave</th>
                </tr>
              </thead>
              <tbody>
                {
                  dataOkr.map(el => (
                    <tr>
                      <td className="text-left">{el.objective.substring(0,60)}...</td>
                      <td className="text-left">{el.description.substring(0,60)}...</td>
                      <td className="text-center">{moment(el.initDate).format('DD/MM/YYYY')} - {moment(el.endDate).format('DD/MM/YYYY')}</td>
                      <td className="text-center">
                        <Tooltip title="Editar OKR" arrow placement="top">
                          <Button
                            size="small"
                            className="btn-link d-30 p-0 btn-icon hover-scale-sm"
                            onClick={() => {
                              setOkrSelected(el);
                              setModalEditOkr(true);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={["far", "edit"]}
                              className="font-size-lg"
                            />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Borrar OKR" arrow placement="top">
                          <Button
                            size="small"
                            className="btn-link d-30 p-0 btn-icon hover-scale-sm"
                            onClick={() => {
                              setOkrSelected(el);
                              toggleDelete()
                            }}
                          >
                            <FontAwesomeIcon
                              icon={["fas", "trash"]}
                              className="font-size-lg"
                            />
                          </Button>
                        </Tooltip>
                        </td>
                        <td className="text-center"><Tooltip title="Ver resultados clave" arrow placement="top">
                          <Button
                            size="small"
                            className="btn-link d-30 p-0 btn-icon hover-scale-sm"
                            onClick={() => {
                              setOkrSelected(el);
                              setModalKeyResults(true);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={["far", "eye"]}
                              className="font-size-lg"
                            />
                          </Button>
                        </Tooltip></td>
                      
                    </tr>
                  ))
                }
                    {/* <div className="badge badge-neutral-danger text-danger px-4">
                      Failed
                    </div>
                    <div className="badge badge-neutral-success text-success px-4">
                      Completed
                    </div> */}
              </tbody>
            </Table>
          </div>
        </CardContent>
        <div className="card-footer d-flex justify-content-between">
          {/* <div>
            <Button size="small" color="primary" variant="contained">
              Agregar nuevo OKR
            </Button>
          </div> */}
        </div>
      </Card>
      <ModalNewOkr
        modalNewOkr={modalNewOkr}
        toggleNewOkr={toggleNewOkr}
        refetch={refetch}
      />
      {okrSelected && <ModalEditOkr
        okrSelected={okrSelected}
        setOkrSelected={setOkrSelected}
        modalEditOkr={modalEditOkr}
        toggleEditOkr={toggleEditOkr}
        refetch={refetch}
      />}
      {okrSelected && <ModalKeyResults
        okrSelected={okrSelected}
        setOkrSelected={setOkrSelected}
        modalKeyResults={modalKeyResults}
        toggleKeyResults={toggleKeyResults}
        refetch={refetch}
      />}
      <Dialog
                            open={modalDelete}
                            onClose={toggleDelete}
                            classes={{ paper: "shadow-lg rounded" }}
                            
                          >
                            <div className="text-center p-5">
                              <div className="avatar-icon-wrapper rounded-circle m-0">
                                <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
                                  <FontAwesomeIcon
                                    icon={["fas", "times"]}
                                    className="d-flex align-self-center display-3"
                                  />
                                </div>
                              </div>
                              <h4 className="font-weight-bold mt-4">
                                ¿Estás seguro de que desas borrar este OKR?
                              </h4>
                              <p className="mb-0 font-size-lg text-muted">
                                No puedes deshacer esta operación.
                              </p>
                              <div className="pt-4">
                                <Button
                                  onClick={toggleDelete}
                                  className="btn-neutral-secondary btn-pill mx-1"
                                >
                                  <span className="btn-wrapper--label">
                                    Cancelar
                                  </span>
                                </Button>
                                <Button
                                  onClick={() => {
                                    toggleDelete();
                                    deleteOkr({
                                      variables: { id: okrSelected.id },
                                      refetchQueries: [
                                        {
                                          query: GET_OKRS_BY_COMPANYID,
                                          variables: { companyId: dbuser.getUserByUid.companyId },
                                        },
                                      ],
                                    });
                                  }}
                                  className="btn-danger btn-pill mx-1"
                                >
                                  <span className="btn-wrapper--label">
                                    Borrar
                                  </span>
                                </Button>
                              </div>
                            </div>
                          </Dialog>
    </>
  );
}
