import React, { useState, useCallback, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Badge, Menu, Button, Tooltip, Divider } from "@material-ui/core";

import avatar2 from "../../assets/images/avatars/avatar2.jpg";
import { NavLink, useHistory } from "react-router-dom";
import { Height } from "@material-ui/icons";
import firebase from "firebase";
import Login from "../../Pages/Login";

import { UserContext } from "../../context/UserContext";
import { HTML5_FMT } from "moment";
import {useApolloClient} from "@apollo/client"
const SidebarUserbox = () => {
  const { currentUser, dbuser, restoreUser, getUserWithFirebase } = useContext(UserContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const apolloClient = useApolloClient()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = useCallback(async (event) => {
    await firebase
      .auth()
      .signOut()
      .then(async function () {
        // localStorage.removeItem("token");
        // await setReloadUserContext(true)
        await getUserWithFirebase()
        await restoreUser()
        // history.push("/");
        apolloClient.clearStore()
        history.push("/login");
        
      })
      .catch(function (error) {
        console.log("no se ha posido cerrar sesión");
      });
      
  });
  if (dbuser && currentUser) {
    return (
      <>
        <div className="app-sidebar--userbox">
          {/* <Box className="card-tr-actions">
            <Button
              variant="text"
              onClick={handleClick}
              className="ml-2 p-0 d-30 border-0 btn-transition-none text-white-50"
              disableRipple
            >
              <FontAwesomeIcon
                icon={["fas", "ellipsis-h"]}
                className="font-size-lg"
              />
            </Button>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={Boolean(anchorEl)}
              classes={{ list: "p-0" }}
              onClose={handleClose}
            >
              <div className="dropdown-menu-lg overflow-hidden p-0">
                <div className="align-box-row align-items-center p-3">
                  <div className="avatar-icon-wrapper avatar-icon-md">
                    <div className="avatar-icon rounded-circle">
                      <img alt="..." src={avatar2} />
                    </div>
                  </div>
                  <div className="pl-2">
                    <span className="font-weight-bold d-block">
                      Emma Taylor
                    </span>
                    <div className="badge badge-success border-0">Active</div>
                  </div>
                </div>
                <Divider className="w-100" />
                <div className="d-flex py-3 justify-content-center">
                  <div className="d-flex align-items-center">
                    <div>
                      <FontAwesomeIcon
                        icon={["far", "user"]}
                        className="font-size-xxl text-success"
                      />
                    </div>
                    <div className="pl-3 line-height-sm">
                      <b className="font-size-lg">14,596</b>
                      <span className="text-black-50 d-block">reports</span>
                    </div>
                  </div>
                </div>
                <Divider className="w-100" />
                <div className="d-block rounded-bottom py-3 text-center">
                  <Tooltip arrow title="Facebook">
                    <Button
                      size="large"
                      className="btn-facebook mx-1 p-0 d-40 font-size-lg text-white"
                    >
                      <span className="btn-wrapper--icon">
                        <FontAwesomeIcon icon={["fab", "facebook"]} />
                      </span>
                    </Button>
                  </Tooltip>
                  <Tooltip arrow title="Twitter">
                    <Button
                      size="large"
                      className="btn-twitter mx-1 p-0 d-40 font-size-lg text-white"
                    >
                      <span className="btn-wrapper--icon">
                        <FontAwesomeIcon icon={["fab", "twitter"]} />
                      </span>
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </Menu>
          </Box> */}
          <div className="avatar-icon-wrapper avatar-icon-md">

              <div className="avatar-icon rounded-circle" style={{width: 80, height: 80}}>
                <img alt="..." 
                src={dbuser.getUserByUid.urlImage ? dbuser.getUserByUid.urlImage : require("../../assets/images/climind/default_avatar.png")} 
                // style={{borderRadius:"50%" , border: "solid 6px gold"}}
                />
              </div>

          </div>
          <div className="my-3 userbox-details">
            <span>{dbuser.getUserByUid.name}</span>
            <span className="d-block text-white-50">
              {dbuser.getUserByUid.email}
            </span>
          </div>
          {/* <h2 className="text-center mb-4">
               Tu nivel y puntos en Climind
              </h2> */}

          <div>
            {/* <div className="align-box-row userbox-details">
              <div className=" avatar-icon rounded-circle">
                <img
                  alt="..."
                  src={require("../../assets/images/climind/level.png")}
                />
              </div>
              <h5 className="d-flex align-items-center justify-content-center p-2">
                Nivel {Math.trunc(dbuser.getUserByUid.score / 100)}
              </h5>
            </div>
            <div className="align-box-row mb-2 userbox-details">
              <div className=" avatar-icon rounded-circle">
                <img
                  alt="..."
                  src={require("../../assets/images/climind/coin.png")}
                />
              </div>
              <h5 className="d-flex align-items-center justify-content-center p-2">
                  {dbuser.getUserByUid.score} Puntos
                </h5>
            </div> */}
            <Button
              component={NavLink}
              to="/Profile"
              size="small"
              className="btn-userbox m-1"
            >
              Ver perfil
            </Button>
            <Button onClick={handleLogout} size="small" className="btn-userbox m-1">
              Cerrar sesión
            </Button>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default SidebarUserbox;
