import React, { useState, useContext } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Table,
  InputLabel,
  InputAdornment,
  Card,
  MenuItem,
  Button,
  Tooltip,
  Checkbox,
  TextField,
  FormControl,
  Select,
} from "@material-ui/core";

import Pagination from "@material-ui/lab/Pagination";

import avatar1 from "../../../../assets/images/avatars/avatar1.jpg";
import avatar2 from "../../../../assets/images/avatars/avatar2.jpg";
import avatar3 from "../../../../assets/images/avatars/avatar3.jpg";
import avatar4 from "../../../../assets/images/avatars/avatar4.jpg";
import avatar5 from "../../../../assets/images/avatars/avatar5.jpg";
import avatar6 from "../../../../assets/images/avatars/avatar6.jpg";
import avatar7 from "../../../../assets/images/avatars/avatar7.jpg";
import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";

import { UserContext } from "../../../../context/UserContext";

import { GET_DEPARTMENTS_GROUPS_AND_USER } from "data/queries";
import { MinimalLayout } from "layout-blueprints";

import ModalAddEmployee from "./ModalAddEmployee";
import ModalEditEmployee from "./ModalEditEmployee";

export default function UsersTable() {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  let companyId = dbuser.getUserByUid.companyId;

  const { loading, error, data, networkStatus } = useQuery(
    GET_DEPARTMENTS_GROUPS_AND_USER,
    {
      variables: { id: companyId },
      skip: !companyId,
      notifyOnNetworkStatusChange: true,
    }
  );

  const [entries, setEntries] = useState("1");
  const [modalAddEmployee, setModalAddEmployee] = useState(false);
  const toggleAddEmployee = () => setModalAddEmployee(!modalAddEmployee);
  const [modalEditEmployee, setModalEditEmployee] = useState(false);
  const [employeeSelected, setEmployeeSelected] = useState("");
  const toggleEditEmployee = () => setModalEditEmployee(!modalEditEmployee);

  const [age, setAge] = useState('');

  const handleChangeAge = (event) => {
    setAge(event.target.value);
  };


  const handleChange = (event) => {
    setEntries(event.target.value);
  };

  if (networkStatus === 4) return "Refetching!";
  if (loading) return "Esperando";
  if (error) return `Error! ${error}`;

  if (data) {
    console.log(data);
    let groups = data.getGroupsByCompanyId;
    let deparments = data.getDepartmentsByCompanyId;
    let employeesList = data.getUsersWithGroups.users;

    return (
      <>
        <Card className="card-box mb-spacing-6-x2">
          <div className="card-header py-3">
            <div className="card-header--title font-size-lg">
              Lista de empleados
            </div>
          </div>
          <div className="d-flex justify-content-between px-4 py-3">
            <div className="d-flex align-items-center">
              <Button size="small" className="btn-neutral-primary" onClick={()=>setModalAddEmployee(true)}>
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={["fas", "plus-circle"]} />
                </span>
                <span className="btn-wrapper--label">Agregar usuario</span>
              </Button>
            </div>
            <div style={{width:200}}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                Filtrar por empresa
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={age}
                onChange={handleChangeAge}
                label="Age">
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
            </div>
            <div style={{width:200}}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                Filtrar por rol
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={age}
                onChange={handleChangeAge}
                label="Age">
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
            </div>
            <div className="search-wrapper">
              <TextField
                variant="outlined"
                size="small"
                id="input-search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchTwoToneIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div className="divider" />
          <div className="table-responsive-md">
            <Table className="table table-hover text-nowrap mb-0">
              <thead>
                <tr>
                  <th className="bg-white">Empleado</th>
                  <th className="bg-white text-left">Mail</th>
                  <th className="bg-white text-center">Manager</th>
                  <th className="bg-white text-center">Departamentos</th>
                  <th className="bg-white text-center">Grupos</th>
                  <th className="bg-white text-center">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {employeesList.length === 0 ? (
                  <div className="text-center my-5">
                    <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-primary shadow-primary-sm text-primary mb-2 d-90">
                      <FontAwesomeIcon
                        icon={["far", "gem"]}
                        className="d-flex align-self-center font-size-xxl"
                      />
                    </div>
                    <h6 className="font-weight-bold font-size-xxl mb-1 mt-3 text-primary">
                      No tienes empleados registrados
                    </h6>
                    <p className="text-black-50 font-size-lg mb-0">
                      Registra empleados en tu compañía para continuar
                    </p>
                  </div>
                ) : (
                  employeesList.map((employee, index) => {
                    // console.log(employee);
                    return (
                      <tr key={index}>
                        {/* Nombre y Apellido */}
                        <td>
                          <div className="d-flex">
                            <Checkbox
                              color="primary"
                              id="checkboxProjects15"
                              className="align-self-center mr-2"
                            />
                            <div className="d-flex align-items-center">
                              {employee.thumb === null ||
                              employee.thumb === "" ? (
                                <div className="avatar-icon-wrapper avatar-initials avatar-icon-sm pr-2">
                                  <div className="avatar-icon text-white bg-primary">
                                    {employee.name.charAt(0).toUpperCase()}
                                  </div>
                                </div>
                              ) : (
                                <div className="avatar-icon-wrapper avatar-initials avatar-icon-sm pr-2">
                                  <div className="avatar-icon text-white bg-primary">
                                    {employee.name.charAt(0).toUpperCase()}
                                  </div>
                                </div>
                              )}

                              <div>{employee.name}</div>
                            </div>
                          </div>
                        </td>
                        {/* Email */}
                        <td>{employee.email}</td>
                        {/* Manager */}
                        <td className="text-center">
                          <div className="avatar-icon-wrapper avatar-initials avatar-icon-sm pr-2">
                            <div className="avatar-icon text-white bg-primary">
                              {employee.name.charAt(0).toUpperCase()}
                            </div>
                          </div>
                        </td>
                        {/* Departamentos */}
                        <td className="text-center">
                          <div className="avatar-wrapper-overlap d-flex justify-content-center">
                            {employee.departments.map((deparment, index)=> {
                              // console.log(deparment)
                              return(
                                <div className="avatar-icon-wrapper avatar-initials avatar-icon-sm pr-2" key={index}>
                                  <div className={`avatar-icon text-white bg-${deparment.color}`}>
                                    {deparment.name.charAt(0).toUpperCase()}
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </td>
                        {/* Grupos */}
                        <td className="text-center">
                          <div className="avatar-wrapper-overlap d-flex justify-content-center">
                            {employee.groups.map((group, index)=> {
                              return(
                                <div className="avatar-icon-wrapper avatar-initials avatar-icon-sm pr-2" key={index}>
                                  <div className={`avatar-icon text-white bg-${group.color}`}>
                                    {group.name.charAt(0).toUpperCase()}
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </td>
                        <td className="text-center">
                          <Button
                            size="small"
                            className="btn-link d-30 p-0 btn-icon hover-scale-sm"
                            onClick={()=>{
                              setEmployeeSelected(employee);
                              setModalEditEmployee(true)
                            }}
                          >
                            <FontAwesomeIcon
                              icon={["far", "edit"]}
                              className="font-size-lg"
                            />
                          </Button>
                          <Button
                            size="small"
                            className="btn-link d-30 p-0 btn-icon hover-scale-sm"
                          >
                            <FontAwesomeIcon
                              icon={["fas", "trash"]}
                              className="font-size-lg"
                            />
                          </Button>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </Table>
          </div>
          {/* <div className="card-footer py-3 d-flex justify-content-between">
            <Pagination
              className="pagination-second"
              variant="outlined"
              count={10}
            />
            <div className="d-flex align-items-center">
              <span>Mostrar</span>
              <FormControl size="small" variant="outlined" className="mx-3">
                <InputLabel id="select-entries-label">Número</InputLabel>
                <Select
                  labelId="select-entries-label"
                  id="select-entries"
                  value={entries}
                  onChange={handleChange}
                  label="Entries"
                >
                  <MenuItem className="mx-2" value={1}>
                    Todos
                  </MenuItem>
                  <MenuItem className="mx-2" value={10}>
                    10
                  </MenuItem>
                  <MenuItem className="mx-2" value={15}>
                    15
                  </MenuItem>
                  <MenuItem className="mx-2" value={20}>
                    20
                  </MenuItem>
                  <MenuItem className="mx-2" value={25}>
                    25
                  </MenuItem>
                  <MenuItem className="mx-2" value={30}>
                    30
                  </MenuItem>
                </Select>
              </FormControl>
              <span>empleados</span>
            </div>
          </div> */}
        </Card>
        <ModalAddEmployee openAddEmployee={modalAddEmployee} toggleAddEmployee={toggleAddEmployee}/>
        <ModalEditEmployee openEditEmployee={modalEditEmployee} toggleEditEmployee={toggleEditEmployee} dataEmployee={employeeSelected}/>
      </>
    );
  }
}
