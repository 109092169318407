import React, { useEffect, useState } from 'react';

import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  Dialog,
  Menu,
  Button,
  List,
  ListItem,
  Tooltip,
  FormControl,
  InputLabel,
  Input,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  Box,
  TextField,
  Chip
} from '@material-ui/core';

import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

import { connect } from 'react-redux';

import people3 from '../../assets/images/stock-photos/people-3.jpg';

import HomeWorkTwoToneIcon from '@material-ui/icons/HomeWorkTwoTone';
import FilterListTwoToneIcon from "@material-ui/icons/FilterListTwoTone";



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const PageTitle = (props) => {
  const {
    pageTitleStyle,
    pageTitleBackground,
    pageTitleShadow,
    pageTitleIconBox,
    pageTitleDescription,
    titleHeading,
    titleDescription,
    children,
    filtersInput,
    setFiltersInput,
    handleFilterVariables
  } = props;
  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);
  const [anchorEl, setAnchorEl] = useState(null);

  const [status, setStatus] = useState("");
  const [anchorElFilter, setAnchorElFilter] = useState(null);

  const handleInitDateChange = date => {
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    let day = date.getDate()
    let dateF = month + '-' + day + '-' + year
    setFiltersInput({ ...filtersInput, initDate: dateF });
  };

  const handleFinishDateChange = date => {
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear()
    let day = date.getDate().toString().padStart(2, "0");
    let dateF = month + '-' + day + '-' + year
    setFiltersInput({ ...filtersInput, finishDate: dateF });
  };

  const handleClickFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
  };
  const handleCloseFilter = () => {
    setAnchorElFilter(null);
  };

  const handleChangeDepartments = (event) => {
    setFiltersInput({ ...filtersInput, departments: event.target.value });
  };

  const handleChangeGroups = (event) => {
    setFiltersInput({ ...filtersInput, groups: event.target.value });
  };

  const handleCleanFilters = (event) => {
    let filtersClean= {
      initDate: moment().startOf('month').format('MM-DD-YYYY'),
      finishDate: moment().endOf('month').format('MM-DD-YYYY'),
      departments: [],
      groups: []
    }
    setFiltersInput(filtersClean);
  };


  function openUserMenu(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <div
        className={clsx('app-page-title', pageTitleStyle, pageTitleBackground, {
          'app-page-title--shadow': pageTitleShadow
        })}>
        <>
          <div className="app-page-title--first">
            {pageTitleIconBox && (
              <div className="app-page-title--iconbox d-70">
                <div className="d-80 d-flex align-items-center justify-content-center display-1">
                  <HomeWorkTwoToneIcon className="text-primary" />
                </div>
              </div>
            )}
            <div className="app-page-title--heading">
              <h1>{titleHeading}</h1>
              {pageTitleDescription && (
                <div className="app-page-title--description">
                  {titleDescription}
                </div>
              )}

            </div>
          </div>
        </>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  pageTitleStyle: state.ThemeOptions.pageTitleStyle,
  pageTitleBackground: state.ThemeOptions.pageTitleBackground,
  pageTitleShadow: state.ThemeOptions.pageTitleShadow,
  pageTitleIconBox: state.ThemeOptions.pageTitleIconBox,
  pageTitleDescription: state.ThemeOptions.pageTitleDescription
});

export default connect(mapStateToProps)(PageTitle);
