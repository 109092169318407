import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Button, Tooltip } from "@material-ui/core";
import regalo from "../../../assets/images/climind/regalo.png";
import felicitacion from "../../../assets/images/climind/thumbs-up.png";
import agradecimiento from "../../../assets/images/climind/pray.png";
import grafico from "../../../assets/images/climind/planta-de-cultivo.svg";

export default function ActionRecognition({
  toggleCongratulation,
  toggleGratitude,
  toggleRecognition,
  toggleProposal,
}) {
  return (
    <Card className="p-4" style={{ height: 220 }}>
      <div className="card-tr-left">
        <div className="d-flex flex-row align-items-center">
          <div className="d-flex flex-row align-items-center">
            <Tooltip
              classes={{
                tooltip: "text-center p-3 tooltip-secondary",
              }}
              arrow
              placement="bottom"
              title="Gana 15 puntos por enviar Reconocimientos y Propuestas de mejora y 5 puntos por enviar Felicitaciones y Agradecimientos"
            >
              <div
                className=" avatar-icon rounded-circle "
                style={{ width: 30, height: 30 }}
              >
                <img
                  alt="..."
                  src={require("../../../assets/images/climind/coin-final.png")}
                />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="card-tr-actions">
        <div className="d-flex flex-row align-items-center">
          <Tooltip
            classes={{
              tooltip: "text-center p-3 tooltip-secondary",
            }}
            arrow
            placement="bottom"
            title="las respuestas de reconocimientos y propuestas de mejora son SIEMPRE anónimas (puedes cambiar la privacidad en tu perfil). Los agradecimientos y felicitaciones serán SIEMPRE publicadas en el timeline."
          >
            <a
              href="#/"
              onClick={(e) => e.preventDefault()}
              className="text-first font-weight-bold"
            >
              <div
                className=" avatar-icon rounded-circle mr-2"
                style={{ width: 30, height: 30 }}
              >
                <img
                  alt="..."
                  src={require("../../../assets/images/climind/candado.svg")}
                />
              </div>
            </a>
          </Tooltip>
          <Tooltip
            classes={{
              tooltip: "text-center p-3 tooltip-secondary",
            }}
            arrow
            placement="bottom"
            title="Aquí podrás entregar Reconocimientos, Felicitaciones, Agradecimientos y Propuestas de mejora al resto de miembros de tu empresa"
          >
            <a
              href="#/"
              onClick={(e) => e.preventDefault()}
              className="text-first font-weight-bold"
            >
              <div
                className=" avatar-icon rounded-circle mr-2"
                style={{ width: 26, height: 26 }}
              >
                <img
                  alt="..."
                  src={require("../../../assets/images/climind/info.png")}
                />
              </div>
            </a>
          </Tooltip>
        </div>
      </div>
      <h3 className="font-size-lg font-weight-bold mb-4 text-center">
        Entregar Feedback
      </h3>

      <div className="d-flex justify-content-around">
        <div className="d-flex flex-column align-items-center">
          <img
            alt="..."
            className="card-img-top rounded-sm mb-2"
            src={felicitacion}
            style={{ width: 70 }}
          />
          <Button
            onClick={toggleRecognition}
            variant="text"
            className="btn-outline-primary mt-1"
          >
            <span className="btn-wrapper--label">Reconocimiento</span>
            <span className="btn-wrapper--icon">
              <FontAwesomeIcon icon={["fas", "arrow-right"]} />
            </span>
          </Button>
        </div>
        <div className="d-flex flex-column align-items-center">
          <img
            alt="..."
            className="card-img-top rounded-sm mb-2"
            src={regalo}
            style={{ width: 70 }}
          />
          <Button
            onClick={toggleCongratulation}
            variant="text"
            className="btn-outline-primary mt-1"
          >
            <span className="btn-wrapper--label">Felicitación</span>
            <span className="btn-wrapper--icon">
              <FontAwesomeIcon icon={["fas", "arrow-right"]} />
            </span>
          </Button>
        </div>
        <div className="d-flex flex-column align-items-center">
          <img
            alt="..."
            className="card-img-top rounded-sm mb-2"
            src={agradecimiento}
            style={{ width: 70 }}
          />
          <Button
            onClick={toggleGratitude}
            variant="text"
            className="btn-outline-primary mt-1"
          >
            <span className="btn-wrapper--label">Agradecimiento</span>
            <span className="btn-wrapper--icon">
              <FontAwesomeIcon icon={["fas", "arrow-right"]} />
            </span>
          </Button>
        </div>
        <div className="d-flex flex-column align-items-center">
          <img
            alt="..."
            className="card-img-top rounded-sm mb-2"
            src={grafico}
            style={{ width: 70 }}
          />
          <Button
            onClick={toggleProposal}
            variant="text"
            className="btn-outline-primary mt-1"
          >
            <span className="btn-wrapper--label">Propuesta de mejora</span>
            <span className="btn-wrapper--icon">
              <FontAwesomeIcon icon={["fas", "arrow-right"]} />
            </span>
          </Button>
        </div>
      </div>
    </Card>
  );
}
