import React, { useState, useCallback, useContext } from "react";
import { PageTitle } from "layout-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Table,
  LinearProgress,
  Card,
  CardContent,
  Button,
  Tooltip,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Input,
  Select,
  Snackbar,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Formik } from "formik";
import { UserContext } from "../../context/UserContext";
import PerfectScrollbar from "react-perfect-scrollbar";
import GridOnTwoToneIcon from "@material-ui/icons/GridOnTwoTone";
import NotificationsActiveTwoToneIcon from "@material-ui/icons/NotificationsActiveTwoTone";
import SettingsTwoToneIcon from "@material-ui/icons/SettingsTwoTone";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import BusinessCenterTwoToneIcon from "@material-ui/icons/BusinessCenterTwoTone";
import LocalLibraryTwoToneIcon from "@material-ui/icons/LocalLibraryTwoTone";
import MaskedInput from "react-input-mask";
import UploadImage from "./UploadImage";
import PropTypes from "prop-types";
import Cropper from "react-cropper";
import firebase from "firebase";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import {
  NEW_AWARD,
  EDIT_AWARD,
  NEW_ACHIEVED_AWARD,
  DELETE_AWARD,
} from "data/mutations";
import {
  GET_AWARDS_BY_COMPANYID,
  GET_USER_BY_UID,
  GET_ACHIEVED_AWARDS_BY_USERID,
  GETS_FOR_GENERAL
} from "data/queries";
import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Shop() {
  const { currentUser, dbuser, getUserWithFirebase } = useContext(UserContext);

  const [modalAddPrice, setModalAddPrice] = useState(false);
  const toggleAddPrice = () => setModalAddPrice(!modalAddPrice);

  const [modalEditPrice, setModalEditPrice] = useState(false);
  const toggleEditPrice = () => setModalEditPrice(!modalEditPrice);

  const [modalBuy, setModalBuy] = useState(false);
  const toggleBuy = () => setModalBuy(!modalBuy);

  const [modalDelete, setModalDelete] = useState(false);
  const toggleDelete = () => setModalDelete(!modalDelete);

  const [awardSelected, setAwardSelected] = useState(null);
  const [picture, setPicture] = useState(null);
  const [crop, setCrop] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openSnackbarError, setOpenSnackbarError] = useState(false);
  const [deleteSelected, setDeleteSelected] = useState(null);
  const [editSelected, setEditSelected] = useState(null);
  const [noCie, setNoCie] = useState(true);
  const [monthCie, setMonthCie] = useState(false);
  const [yearCie, setYearCie] = useState(false);
  const [
    editAward,
    { data: dataEditAward, error: errorEditAward, loading: loadingEditAward },
  ] = useMutation(EDIT_AWARD);
  const [
    saveAward,
    { data: dataAward, error: errorAward, loading: loadingAward },
  ] = useMutation(NEW_AWARD);
  const [deleteAward, { data: dataDeleteAward }] = useMutation(DELETE_AWARD);
  const [
    newAchievedAward,
    {
      data: dataAchievedAward,
      error: errorAchievedAward,
      loading: loadingAchievedAward,
    },
  ] = useMutation(NEW_ACHIEVED_AWARD);
  const {
    loading: loadingAwards,
    error: errorAwards,
    data: dataAwards,
    networkStatus: networkStatusAwards,
    refetch: refetchAwards,
  } = useQuery(GET_AWARDS_BY_COMPANYID, {
    variables: { id: dbuser.getUserByUid.companyId },
    skip: !dbuser.getUserByUid.companyId,
    notifyOnNetworkStatusChange: true,
  });
  const { loading, error, data, networkStatus } = useQuery(GETS_FOR_GENERAL, {
    variables: { id: dbuser.getUserByUid.id },
    skip: !dbuser.getUserByUid.id,
    notifyOnNetworkStatusChange: true,
  });

  const handleCancel = useCallback((e) => {
    setCrop(null);
    setPicture(null);
  });
  const handleOk = (e) => {
    setPicture(crop);
  };

  const onBuy = useCallback((e) => {
    console.log(e);
    setAwardSelected(e);
    toggleBuy();
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(25, "Máximo 25 caracteres")
      .required("Obligatorio")
      .typeError("Debes especificar un nombre"),
    description: Yup.string()
      .required("Obligatorio")
      .typeError("Debes especificar una descripción"),
    price: Yup.number()
      .integer("precio invalido")
      .required("Obligatorio")
      .max(9999999, "Como mucho 7 caracteres")
      .typeError("Debes especificar un precio válido, máximo 7 cifras"),
    stock: Yup.number()
      .integer("stock invalido")
      .required("Obligatorio")
      .max(9999999, "Como mucho 7 caracteres")
      .typeError("Debes especificar un precio válido, máximo 7 cifras"),
    delivery: Yup.string()
      .required("Obligatorio")
      .typeError("Debes especificar una forma de entrega"),
    cost: Yup.number()
      .required("Obligatorio")
      .max(9999999, "Como mucho 7 caracteres")
      .typeError("Debes especificar un precio válido, máximo 7 cifras"),
    cieValue: Yup.number()
      .integer("cie invalido")
      .max(99, "Como mucho 2 caracteres")
      .typeError("Debes especificar un cie válido, máximo 2 cifras, hasta 99"),
  });
  if (dataAwards && data) {
    console.log(dataAwards);
    let cie = (data.getAnswerEmotionsSendedByUserIdLast30.length <= 20 ? data.getAnswerEmotionsSendedByUserIdLast30.length : 20)/20*100 * (30/100) +
    (data.getAnswersSendedByUserIdLast30.length <= 20 ? data.getAnswersSendedByUserIdLast30.length : 20)/20*100 * (30/100)+
    (data.getRecognitionsSendedByUserIdLast30.length <= 20 ? data.getRecognitionsSendedByUserIdLast30.length : 20)/20*100 * (15/100)+
    (data.getImprovementProposalsSendedByUserIdLast30.length <= 20 ? data.getImprovementProposalsSendedByUserIdLast30.length : 20)/20*100 * (15/100)+
    (data.getCongratulationsSendedByUserIdLast30.length <= 20 ? data.getCongratulationsSendedByUserIdLast30.length : 20)/20*100 * (5/100)+
    (data.getGratitudesSendedByUserIdLast30.length <= 20 ? data.getGratitudesSendedByUserIdLast30.length : 20)/20*100 * (5/100)
    console.log(cie)
    return (
      <>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          key={"top,center"}
          open={openSnackbar}
          // classes={{ root: "toastr-primary" }}
          autoHideDuration={4000}
          onClose={() => setOpenSnackbar(false)}
          message={"entregado correctamente"}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity="success"
            style={{ color: "white" }}
          >
            Canjeado correctamente
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          key={"top,center"}
          open={openSnackbarError}
          // classes={{ root: "toastr-primary" }}
          autoHideDuration={4000}
          onClose={() => setOpenSnackbarError(false)}
          message={"No tienes puntos suficientes"}
        >
          <Alert
            onClose={() => setOpenSnackbarError(false)}
            severity="warning"
            style={{ color: "white" }}
          >
            No tienes puntos suficientes
          </Alert>
        </Snackbar>
        <Grid item xl={12}>
          {/* <Card className="card-box d-flex flex-row flex-wrap justify-content-center mb-spacing-6-x2">
        <div className="py-4 px-5 d-flex align-items-center">
          <MonetizationOnIcon className="d-50 text-dark opacity-2 mr-3" />
          <div>
            <span className="d-block opacity-7">Puntos totales generados </span>
            <span className="font-weight-bold font-size-lg text-danger">
              1,693
            </span>
          </div>
        </div> */}
          {dbuser.getUserByUid.role === "admin" && (
            <div className="mb-5 py-2 px-5 d-flex flex-column align-items-center justify-content-center">
              <h5 className="mb-2">Acciones administradores</h5>
              <Button
                onClick={toggleAddPrice}
                variant="text"
                className="btn-outline-primary m-2"
              >
                <span className="btn-wrapper--label">Crear nuevo premio</span>
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={["fas", "arrow-right"]} />
                </span>
              </Button>
            </div>
          )}
          {/* </Card> */}
        </Grid>
        <Grid item xl={12}>
          <div className="text-center">
            <h5 className="mb-20">Premios disponibles</h5>
            {/* <h6 style={{color:"purple"}}>Tienes 345 puntos</h6> */}
          </div>
        </Grid>
        <Grid item xl={12}>
          <div className="mb-spacing-12">
            <Grid container spacing={4}>
              {/* {dataCompetences.getCompetencesByUserId.map((element, index) => { */}
              {/* return( */}
              {dataAwards.getAwardsByCompanyId.map((element, index) => {
                return (
                  <Grid
                    item
                    xl={3}
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="avatar-icon-wrapper avatar-icon-md d-flex justify-content-center">
                      <div
                        className="avatar-icon rounded-circle text-center"
                        style={{ width: 100, height: 100 }}
                      >
                        <img
                          alt="..."
                          src={
                            element.urlImage
                              ? element.urlImage
                              : require("../../assets/images/climind/caja-de-regalo.png")
                          }
                          // style={{borderRadius:"50%" , border: "solid 6px gold"}}
                        />
                      </div>
                    </div>
                    <div
                      className="font-size-xl font-weight-bold mb-2 d-flex justify-content-center"
                      style={{ marginTop: 10 }}
                    >
                      {element.name}
                    </div>
                    <div className="align-box-row userbox-details d-flex justify-content-center">
                      <div
                        className=" avatar-icon rounded-circle"
                        style={{ width: 30, height: 30 }}
                      >
                        <img
                          alt="..."
                          src={require("../../assets/images/climind/coin-final.png")}
                        />
                      </div>
                      <h6 className="d-flex align-items-center justify-content-center p-2 mb-0 mr-3">
                        {element.price} Coins
                      </h6>
                    </div>
                    <div className="align-box-row userbox-details d-flex justify-content-center">
                      <h6 className="d-flex align-items-center justify-content-center p-2 mb-0 mr-3">
                        Unidades disponibles: <a className="font-weight-bold"> &nbsp;{element.stock}</a>
                      </h6>
                    </div>
                    <Button
                      size="small"
                      className="btn-success btn-pill px-4 hover-scale-sm mb-2 mt-10"
                      style={{ marginTop: 10 }}
                      onClick={() => onBuy(element)}
                    >
                      <span className="btn-wrapper--label">
                        Adquirir premio
                      </span>
                    </Button>
                    {dbuser.getUserByUid.role === "admin" && (
                      <Button
                        size="small"
                        className="btn-info btn-pill px-4 hover-scale-sm mb-2 mt-10"
                        onClick={() => {
                          setEditSelected(element);
                          if(element.cieTime === "MONTHCIE"){
                            setNoCie(false)
                            setMonthCie(true)
                            setYearCie(false)
                          }else if(element.cieTime === "YEARCIE"){
                            setNoCie(false)
                            setMonthCie(false)
                            setYearCie(true)
                          }
                          setPicture(element.urlImage);
                          setCrop(element.urlImage);
                          toggleEditPrice();
                        }}
                      >
                        <span className="btn-wrapper--label">
                          Editar premio
                        </span>
                      </Button>
                    )}
                    {dbuser.getUserByUid.role === "admin" && (
                      <Button
                        size="small"
                        className="btn-danger btn-pill px-4 hover-scale-sm"
                        onClick={() => {
                          setDeleteSelected(element);
                          toggleDelete();
                        }}
                      >
                        <span className="btn-wrapper--label">
                          Eliminar premio
                        </span>
                      </Button>
                    )}

                    <div></div>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </Grid>
        {/* --------------------------------------------
                   MODAL DE NUEVO PREMIO 
      -----------------------------------------------*/}
        {/* <Dialog
        open={modalAddPrice}
        onClose={toggleAddPrice}
        classes={{ paper: "modal-content rounded-lg" }}
      >
        <DialogTitle className="bg-white-10">Nuevo premio</DialogTitle>

        <Formik
          initialValues={{
            name: null,
            description: null,
            price: null,
            stock: null,
            delivery: null,
            cost: null,
          }}
          onSubmit={async (values, actions) => {
            console.log(crop)
            try {
              let dataInput = {
                name: values.name,
                description: values.description,
                price: parseInt(values.price),
                companyId: dbuser.getUserByUid.companyId,
                stock: parseInt(values.stock),
                delivery: values.delivery,
                cost: parseFloat(values.cost),
              };
              console.log(dataInput);
              let id = dbuser.getUserByUid.companyId
              let response = await saveAward({
                variables: { input: dataInput },
                refetchQueries: [{ query: GET_AWARDS_BY_COMPANYID, variables: { id, id } }],
              });
              console.log(response)
              try {
                const storageRef = firebase
                  .storage()
                  .ref(`/iconsAwards/${response.data.newAward.id}`);
                const pictureUploaded = storageRef
                  .putString(picture, "data_url")
                  .then(async function (snapshot) {
                    try {
                      await storageRef.getDownloadURL().then(async (url) => {
                        console.log(url);
                        // setIcon(url)
                        let dataEditInput = {
                          urlImage: url,
                        };
                        let responseEdit = await editAward({
                          variables: {
                            id: response.data.newAward.id,
                            input: dataEditInput,
                          },
                          refetchQueries: [{ query: GET_AWARDS_BY_COMPANYID, variables: { id, id } }],
                        });
                      });
                    } catch (e) {
                      console.log(e);
                    }
                  });
              } catch (e) {
                console.log(e);
              }
              setCrop(null);
              setPicture(null)
              toggleAddPrice()
            } catch (err) {
              console.log(err);
            }
          }}
          validationSchema={validationSchema}
        >
          {(props) => (
            
            <form onSubmit={props.handleSubmit} onReset={props.handleReset}>
              <DialogContent style={{ overflow: "hidden" }}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Card
                      className="p-4 mt-4"
                      style={{ backgroundColor: "#eceef7" }}
                      elevation={0}
                    >
                      <div>
                        <TextField
                          fullWidth
                          className="m-2"
                          id="outlined-multiline-static-1"
                          name="name"
                          label="Nombre"
                          placeholder="Escribe aqui..."
                          variant="outlined"
                          value={props.values.name}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur} 
                          error={Boolean(props.touched.name && props.errors.name)}
                          helperText={
                            props.touched.name ? props.errors.name : ""
                          }
                        />
                        <TextField
                          fullWidth
                          multiline
                          rows="4"
                          className="m-2"
                          id="outlined-multiline-static-2"
                          name="description"
                          label="Descripción"
                          placeholder="Escribe aqui..."
                          variant="outlined"
                          value={props.values.description}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur} 
                          error={Boolean(
                          props.touched.description && props.errors.description
                          )}
                          helperText={
                          props.touched.description ? props.errors.description : ""
                          }
                        /> 
                        <TextField
                          fullWidth
                          multiline
                          rows="4"
                          className="m-2"
                          id="outlined-multiline-static-2"
                          name="delivery"
                          label="Forma de entrega"
                          placeholder="Escribe aqui..."
                          variant="outlined"
                          value={props.values.delivery}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur} 
                          error={Boolean(
                          props.touched.delivery && props.errors.delivery
                          )}
                          helperText={
                          props.touched.delivery ? props.errors.delivery : ""
                          }
                        /> 
                        <TextField
                          fullWidth
                          className="m-2"
                          id="outlined-multiline-static-4"
                          name="price"
                          label="Coste en coins"
                          placeholder="Escribe aqui..."
                          variant="outlined"
                          value={props.values.price}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur} 
                          error={Boolean(
                            props.touched.price && props.errors.price
                          )}
                          helperText={
                            props.touched.price ? props.errors.price : ""
                          }
                        />
                        <TextField
                          fullWidth
                          className="m-2"
                          id="outlined-multiline-static-4"
                          name="cost"
                          label="Precio en euros"
                          placeholder="Escribe aqui..."
                          variant="outlined"
                          value={props.values.cost}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur} 
                          error={Boolean(
                            props.touched.cost && props.errors.cost
                          )}
                          helperText={
                            props.touched.cost ? props.errors.cost : ""
                          }
                        />
                        <TextField
                          fullWidth
                          className="m-2"
                          id="outlined-multiline-static-4"
                          name="stock"
                          label="Unidades disponibles"
                          placeholder="Escribe aqui..."
                          variant="outlined"
                          value={props.values.stock}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur} 
                          error={Boolean(
                            props.touched.stock && props.errors.stock
                          )}
                          helperText={
                            props.touched.stock ? props.errors.stock : ""
                          }
                        />
                      </div>
                      <div className="text-center p-5">
            
                        <UploadImage picture={picture} setCrop={setCrop}/>
                        <div className="pt-4">
                          <Button
                            onClick={handleOk}
                            disabled={!crop || picture}
                            className="btn-primary btn-pill mx-1">
                            <span className="btn-wrapper--label">Seleccionar</span>
                          </Button>
                          <Button
                            onClick={handleCancel}
                            disabled={!crop || !picture}
                            className="btn-warning btn-pill mx-1">
                            <span className="btn-wrapper--label">Cambiar</span>
                          </Button>
                        </div>
                      </div>
                    </Card>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions className="p-4 bg-white-10">
                <Button
                  className="btn-neutral-primary"
                  onClick={() => toggleAddPrice()}
                >
                  Cancelar
                </Button>{" "}
                <Button className="btn-primary ml-auto" type="submit">
                  Guardar
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog> */}
        {awardSelected && (
          <Dialog
            open={modalBuy}
            onClose={toggleBuy}
            classes={{ paper: "shadow-lg rounded" }}
          >
            <div className="text-center p-5">
              <div className="avatar-icon-wrapper rounded-circle m-0">
                <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
                  <div
                    className="avatar-icon rounded-circle text-center"
                    style={{ width: 100, height: 100 }}
                  >
                    <img
                      alt="..."
                      src={
                        awardSelected.urlImage
                          ? awardSelected.urlImage
                          : require("../../assets/images/climind/caja-de-regalo.png")
                      }
                      // style={{borderRadius:"50%" , border: "solid 6px gold"}}
                    />
                  </div>
                </div>
              </div>
              <h4 className="font-weight-bold mt-4">
                ¿Quieres adquirir este Premio?
              </h4>
              <h4 className=" mt-4">{awardSelected.name}</h4>
              <h6 className=" mt-4">{awardSelected.description}</h6>
              <h4 className="font-weight-bold mt-4">
                Tiene un coste de: {awardSelected.price} Coins
              </h4>
              {/* <p className="mb-0 font-size-lg">
                No podrás deshacer esta operación.
              </p> */}
              {dbuser.getUserByUid.points - awardSelected.price < 0 && (
                <p className="mb-0 font-size-lg font-weight-bold" style={{color:"red"}}>
                  No tienes suficientes coins para canjearlo
                </p>
              )}
              {awardSelected.cieTime === "MONTHCIE" && cie < awardSelected.cieValue && (
                <p className="mb-0 font-size-lg font-weight-bold" style={{color:"red"}}>
                  Tu CIE MENSUAL debe de ser igual o superior a {awardSelected.cieValue}% para canjearlo
                </p>
              )}
              {awardSelected.cieTime === "YEARCIE" && dbuser.getUserByUid.cieHistory < awardSelected.cieValue &&(
                <p className="mb-0 font-size-lg font-weight-bold" style={{color:"red"}}>
                  Tu CIE HISTÓRICO debe de ser igual o superior a {awardSelected.cieValue}% para canjearlo, prueba más adelante
                </p>
              )}
              {awardSelected.stock <= 0 && (
                <p className="mb-0 font-size-lg">
                  No hay unidades disponibles de este premio
                </p>
              )}
              <div className="pt-4">
                <Button onClick={toggleBuy} className="btn-info btn-pill mx-1">
                  <span className="btn-wrapper--label">Cancelar</span>
                </Button>
                <Button
                  disabled={
                    dbuser.getUserByUid.points - awardSelected.price < 0 || awardSelected.stock <= 0 || (awardSelected.cieTime === "MONTHCIE" && cie < awardSelected.cieValue) || (awardSelected.cieTime === "YEARCIE" && dbuser.getUserByUid.cieHistory < awardSelected.cieValue) 
                  }
                  onClick={async () => {
                    if (dbuser.getUserByUid.points - awardSelected.price < 0) {
                      setOpenSnackbarError(true, () => {
                        setTimeout(function () {
                          setOpenSnackbarError(false);
                        }, 5000); //5 Second delay
                      });
                    } else {
                      let uid = dbuser.getUserByUid.uid;
                      let id = dbuser.getUserByUid.id;
                      let dataInput = {
                        price: awardSelected.price,
                        companyId: dbuser.getUserByUid.companyId,
                        userId: dbuser.getUserByUid.id,
                        awardId: awardSelected.id,
                        urlImage: awardSelected.urlImage,
                      };
                      console.log(dataInput);
                      try {
                        let response = await newAchievedAward({
                          variables: { input: dataInput },
                          refetchQueries: [
                            { query: GET_USER_BY_UID, variables: { uid, uid } },
                            {
                              query: GET_ACHIEVED_AWARDS_BY_USERID,
                              variables: { id, id },
                            },
                            {
                              query: GET_AWARDS_BY_COMPANYID,
                              variables: { id: dbuser.getUserByUid.companyId },
                            },
                          ],
                        });
                      } catch (e) {
                        console.log(e);
                      }
                      getUserWithFirebase();
                      setAwardSelected(null);
                      toggleBuy();
                      setOpenSnackbar(true, () => {
                        setTimeout(function () {
                          setOpenSnackbar(false);
                        }, 5000); //5 Second delay
                      });
                    }
                  }}
                  className="btn-success btn-pill mx-1"
                >
                  <span className="btn-wrapper--label">Continuar</span>
                </Button>
              </div>
            </div>
          </Dialog>
        )}
        {deleteSelected && (
          <Dialog
            open={modalDelete}
            onClose={toggleDelete}
            classes={{ paper: "shadow-lg rounded" }}
          >
            <div className="text-center p-5">
              <div className="avatar-icon-wrapper rounded-circle m-0">
                <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
                  <FontAwesomeIcon
                    icon={["fas", "times"]}
                    className="d-flex align-self-center display-3"
                  />
                </div>
              </div>
              <h4 className="font-weight-bold mt-4">
                ¿Estás seguro de que desas borrar este premio?
              </h4>
              <h4 className="font-weight-bold mt-4">{deleteSelected.name}</h4>
              <p className="mb-0 font-size-lg text-muted">
                No puedes deshacer esta operación.
              </p>
              <div className="pt-4">
                <Button
                  onClick={toggleDelete}
                  className="btn-neutral-secondary btn-pill mx-1"
                >
                  <span className="btn-wrapper--label">Cancelar</span>
                </Button>
                <Button
                  onClick={() => {
                    toggleDelete();
                    try {
                      deleteAward({
                        variables: { id: deleteSelected.id },
                        refetchQueries: [
                          {
                            query: GET_AWARDS_BY_COMPANYID,
                            variables: { id: dbuser.getUserByUid.companyId },
                          },
                        ],
                      });
                      setDeleteSelected(null);
                    } catch (err) {
                      console.log(err);
                    }
                  }}
                  className="btn-danger btn-pill mx-1"
                >
                  <span className="btn-wrapper--label">Borrar</span>
                </Button>
              </div>
            </div>
          </Dialog>
        )}
        <Dialog
          open={modalAddPrice}
          onClose={toggleAddPrice}
          classes={{ paper: "modal-content rounded-lg" }}
        >
          <DialogTitle className="bg-white-10">Nuevo premio</DialogTitle>

          <Formik
            initialValues={{
              name: null,
              description: null,
              price: null,
              stock: null,
              delivery: null,
              cost: null,
              cieValue: null,
              cieTime: null
            }}
            onSubmit={async (values, actions) => {
              console.log(crop);
              if(noCie){
                values.cieTime="NOCIE"
              }else if(monthCie){
                values.cieTime="MONTHCIE"
              }else if(yearCie){
                values.cieTime="YEARCIE"
              }
              try {
                let dataInput = {
                  name: values.name,
                  description: values.description,
                  price: parseInt(values.price),
                  companyId: dbuser.getUserByUid.companyId,
                  stock: parseInt(values.stock),
                  delivery: values.delivery,
                  cost: parseFloat(values.cost),
                  cieTime: values.cieTime,
                  cieValue: parseInt(values.cieValue),
                };
                console.log(dataInput);
                let id = dbuser.getUserByUid.companyId;
                let response = await saveAward({
                  variables: { input: dataInput },
                  refetchQueries: [
                    { query: GET_AWARDS_BY_COMPANYID, variables: { id, id } },
                  ],
                });
                console.log(response);
                try {
                  const storageRef = firebase
                    .storage()
                    .ref(`/iconsAwards/${response.data.newAward.id}`);
                  const pictureUploaded = storageRef
                    .putString(picture, "data_url")
                    .then(async function (snapshot) {
                      try {
                        await storageRef.getDownloadURL().then(async (url) => {
                          console.log(url);
                          // setIcon(url)
                          let dataEditInput = {
                            urlImage: url,
                          };
                          let responseEdit = await editAward({
                            variables: {
                              id: response.data.newAward.id,
                              input: dataEditInput,
                            },
                            refetchQueries: [
                              {
                                query: GET_AWARDS_BY_COMPANYID,
                                variables: { id, id },
                              },
                            ],
                          });
                        });
                      } catch (e) {
                        console.log(e);
                      }
                    });
                } catch (e) {
                  console.log(e);
                }
                setCrop(null);
                setPicture(null);
                setYearCie(false)
                setMonthCie(false)
                setNoCie(true)
                toggleAddPrice();
              } catch (err) {
                console.log(err);
              }
            }}
            validationSchema={validationSchema}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit} onReset={props.handleReset}>
                <DialogContent style={{ overflow: "hidden" }}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <h6 className="mb-2">
                        Tenga en cuenta a la hora de crear un premio que cada
                        usuario puede generar como máximo 2000 puntos cada mes
                      </h6>
                      <Card
                        className="p-4 mt-4"
                        style={{ backgroundColor: "#eceef7" }}
                        elevation={0}
                      >
                        <div>
                          <TextField
                            fullWidth
                            className="m-2"
                            id="outlined-multiline-static-1"
                            name="name"
                            label="Nombre"
                            placeholder="Escribe aqui..."
                            variant="outlined"
                            value={props.values.name}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={Boolean(
                              props.touched.name && props.errors.name
                            )}
                            helperText={
                              props.touched.name ? props.errors.name : ""
                            }
                          />
                          <TextField
                            fullWidth
                            multiline
                            rows="4"
                            className="m-2"
                            id="outlined-multiline-static-2"
                            name="description"
                            label="Descripción"
                            placeholder="Escribe aqui..."
                            variant="outlined"
                            value={props.values.description}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={Boolean(
                              props.touched.description &&
                                props.errors.description
                            )}
                            helperText={
                              props.touched.description
                                ? props.errors.description
                                : ""
                            }
                          />
                          <TextField
                            fullWidth
                            multiline
                            rows="4"
                            className="m-2"
                            id="outlined-multiline-static-2"
                            name="delivery"
                            label="Forma de entrega"
                            placeholder="Escribe aqui..."
                            variant="outlined"
                            value={props.values.delivery}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={Boolean(
                              props.touched.delivery && props.errors.delivery
                            )}
                            helperText={
                              props.touched.delivery
                                ? props.errors.delivery
                                : ""
                            }
                          />
                          <TextField
                            fullWidth
                            className="m-2"
                            id="outlined-multiline-static-4"
                            name="price"
                            label="Coste en coins"
                            placeholder="Escribe aqui..."
                            variant="outlined"
                            value={props.values.price}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={Boolean(
                              props.touched.price && props.errors.price
                            )}
                            helperText={
                              props.touched.price ? props.errors.price : ""
                            }
                          />
                          <TextField
                            fullWidth
                            className="m-2"
                            id="outlined-multiline-static-4"
                            name="cost"
                            label="Precio en euros"
                            placeholder="Escribe aqui..."
                            variant="outlined"
                            value={props.values.cost}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={Boolean(
                              props.touched.cost && props.errors.cost
                            )}
                            helperText={
                              props.touched.cost ? props.errors.cost : ""
                            }
                          />
                          <TextField
                            fullWidth
                            className="m-2"
                            id="outlined-multiline-static-4"
                            name="stock"
                            label="Unidades disponibles"
                            placeholder="Escribe aqui..."
                            variant="outlined"
                            value={props.values.stock}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={Boolean(
                              props.touched.stock && props.errors.stock
                            )}
                            helperText={
                              props.touched.stock ? props.errors.stock : ""
                            }
                          />
                        </div>
                        <h5 className="font-weight-bold mt-4" style={{ color: "#5a318e" }}>
                          Cambia si deseas el cie necesario para el canje del premio
                        </h5>
                        <div className="d-flex flex-row justify-content-center mb-2">
                          <FormControlLabel
                            aria-label="Acknowledge"
                            onClick={(event) => {
                              event.stopPropagation();
                              setNoCie(true)
                              setMonthCie(false)
                              setYearCie(false)
                            }}
                            onFocus={(event) => event.stopPropagation()}
                            control={<Checkbox checked={noCie} />}
                            label="Sin Cie"
                          />

                          <FormControlLabel
                            aria-label="Acknowledge"
                            onClick={(event) => {
                              event.stopPropagation();
                              setNoCie(false)
                              setMonthCie(true)
                              setYearCie(false)
                            }}
                            onFocus={(event) => event.stopPropagation()}
                            control={
                              <Checkbox checked={monthCie} />
                            }
                            label="Cie mensual"
                          />
                          <FormControlLabel
                            aria-label="Acknowledge"
                            onClick={(event) => {
                              event.stopPropagation();
                              setNoCie(false)
                              setMonthCie(false)
                              setYearCie(true)
                            }}
                            onFocus={(event) => event.stopPropagation()}
                            control={
                              <Checkbox checked={yearCie} />
                            }
                            label="Cie histórico"
                          />
                        </div>
                        {(monthCie || yearCie) && <div className="d-flex flex-row justify-content-center mb-2">
                        <TextField
                          style={{ width: "50%" }}
                          fullWidth
                          className="m-2"
                          id="outlined-multiline-static-4"
                          name="cieValue"
                          label="Valor de Cie necesario"
                          placeholder="Escribe aqui..."
                          variant="outlined"
                          value={props.values.cieValue}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          error={Boolean(
                            props.touched.cieValue && props.errors.cieValue
                          )}
                          helperText={
                            props.touched.cieValue ? props.errors.cieValue : ""
                          }

                        />
                        </div>
                        }
                        <div className="text-center p-5">
                          <UploadImage picture={picture} setCrop={setCrop} />
                          <div className="pt-4">
                            <Button
                              onClick={handleOk}
                              disabled={!crop || picture}
                              className="btn-primary btn-pill mx-1"
                            >
                              <span className="btn-wrapper--label">
                                Seleccionar
                              </span>
                            </Button>
                            <Button
                              onClick={handleCancel}
                              disabled={!crop || !picture}
                              className="btn-warning btn-pill mx-1"
                            >
                              <span className="btn-wrapper--label">
                                Cambiar
                              </span>
                            </Button>
                          </div>
                        </div>
                      </Card>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions className="p-4 bg-white-10">
                  <Button
                    className="btn-neutral-primary"
                    onClick={() => toggleAddPrice()}
                  >
                    Cancelar
                  </Button>{" "}
                  <Button className="btn-primary ml-auto" type="submit">
                    Guardar
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </Dialog>
        {/* --------------------------------------------
                   MODAL DE EDITAR PREMIO 
      -----------------------------------------------*/}
        {editSelected && (
          <Dialog
            open={modalEditPrice}
            onClose={toggleEditPrice}
            classes={{ paper: "modal-content rounded-lg" }}
          >
            <DialogTitle className="bg-white-10">Editar premio</DialogTitle>

            <Formik
              initialValues={{
                name: editSelected.name,
                description: editSelected.description,
                price: editSelected.price,
                stock: editSelected.stock,
                delivery: editSelected.delivery,
                cost: editSelected.cost,
                cieTime: editSelected.cieTime,
                cieValue: editSelected.cieValue,
              }}
              onSubmit={async (values, actions) => {
                console.log(crop);
                if(noCie){
                  values.cieTime="NOCIE"
                }else if(monthCie){
                  values.cieTime="MONTHCIE"
                }else if(yearCie){
                  values.cieTime="YEARCIE"
                }
                try {
                  let dataInput = {
                    name: values.name,
                    description: values.description,
                    price: parseInt(values.price),
                    stock: parseInt(values.stock),
                    delivery: values.delivery,
                    cost: parseFloat(values.cost),
                    cieTime: values.cieTime,
                    cieValue: parseInt(values.cieValue),
                  };
                  console.log(dataInput);
                  let id = dbuser.getUserByUid.companyId;
                  let response = await editAward({
                    variables: { id: editSelected.id, input: dataInput },
                    refetchQueries: [
                      { query: GET_AWARDS_BY_COMPANYID, variables: { id, id } },
                    ],
                  });
                  console.log(response);
                  try {
                    const storageRef = firebase
                      .storage()
                      .ref(`/iconsAwards/${response.data.editAward.id}`);
                    const pictureUploaded = storageRef
                      .putString(picture, "data_url")
                      .then(async function (snapshot) {
                        try {
                          await storageRef
                            .getDownloadURL()
                            .then(async (url) => {
                              console.log(url);
                              // setIcon(url)
                              let dataEditInput = {
                                urlImage: url,
                              };
                              let responseEdit = await editAward({
                                variables: {
                                  id: response.data.editAward.id,
                                  input: dataEditInput,
                                },
                                refetchQueries: [
                                  {
                                    query: GET_AWARDS_BY_COMPANYID,
                                    variables: { id, id },
                                  },
                                ],
                              });
                            });
                        } catch (e) {
                          console.log(e);
                        }
                      });
                  } catch (e) {
                    console.log(e);
                  }
                  setCrop(null);
                  setPicture(null);
                  setYearCie(false)
                  setMonthCie(false)
                  setNoCie(true)
                  toggleEditPrice();
                } catch (err) {
                  console.log(err);
                }
              }}
              validationSchema={validationSchema}
            >
              {(props) => (
                <form onSubmit={props.handleSubmit} onReset={props.handleReset}>
                  <DialogContent style={{ overflow: "hidden" }}>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <h6 className="mb-2">
                          Tenga en cuenta a la hora de crear un premio que cada
                          usuario puede generar como máximo 2000 puntos cada mes
                        </h6>
                        <Card
                          className="p-4 mt-4"
                          style={{ backgroundColor: "#eceef7" }}
                          elevation={0}
                        >
                          <div>
                            <TextField
                              fullWidth
                              className="m-2"
                              id="outlined-multiline-static-1"
                              name="name"
                              label="Nombre"
                              placeholder="Escribe aqui..."
                              variant="outlined"
                              value={props.values.name}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={Boolean(
                                props.touched.name && props.errors.name
                              )}
                              helperText={
                                props.touched.name ? props.errors.name : ""
                              }
                            />
                            <TextField
                              fullWidth
                              multiline
                              rows="4"
                              className="m-2"
                              id="outlined-multiline-static-2"
                              name="description"
                              label="Descripción"
                              placeholder="Escribe aqui..."
                              variant="outlined"
                              value={props.values.description}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={Boolean(
                                props.touched.description &&
                                  props.errors.description
                              )}
                              helperText={
                                props.touched.description
                                  ? props.errors.description
                                  : ""
                              }
                            />
                            <TextField
                              fullWidth
                              multiline
                              rows="4"
                              className="m-2"
                              id="outlined-multiline-static-2"
                              name="delivery"
                              label="Forma de entrega"
                              placeholder="Escribe aqui..."
                              variant="outlined"
                              value={props.values.delivery}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={Boolean(
                                props.touched.delivery && props.errors.delivery
                              )}
                              helperText={
                                props.touched.delivery
                                  ? props.errors.delivery
                                  : ""
                              }
                            />
                            <TextField
                              fullWidth
                              className="m-2"
                              id="outlined-multiline-static-4"
                              name="price"
                              label="Coste en coins"
                              placeholder="Escribe aqui..."
                              variant="outlined"
                              value={props.values.price}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={Boolean(
                                props.touched.price && props.errors.price
                              )}
                              helperText={
                                props.touched.price ? props.errors.price : ""
                              }
                            />
                            <TextField
                              fullWidth
                              className="m-2"
                              id="outlined-multiline-static-4"
                              name="cost"
                              label="Precio en euros"
                              placeholder="Escribe aqui..."
                              variant="outlined"
                              value={props.values.cost}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={Boolean(
                                props.touched.cost && props.errors.cost
                              )}
                              helperText={
                                props.touched.cost ? props.errors.cost : ""
                              }
                            />
                            <TextField
                              fullWidth
                              className="m-2"
                              id="outlined-multiline-static-4"
                              name="stock"
                              label="Unidades disponibles"
                              placeholder="Escribe aqui..."
                              variant="outlined"
                              value={props.values.stock}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={Boolean(
                                props.touched.stock && props.errors.stock
                              )}
                              helperText={
                                props.touched.stock ? props.errors.stock : ""
                              }
                            />
                          </div>
                          <h5 className="font-weight-bold mt-4" style={{ color: "#5a318e" }}>
                          Cambia si deseas el cie necesario para el canje del premio
                        </h5>
                        <div className="d-flex flex-row justify-content-center mb-2">
                          <FormControlLabel
                            aria-label="Acknowledge"
                            onClick={(event) => {
                              event.stopPropagation();
                              setNoCie(true)
                              setMonthCie(false)
                              setYearCie(false)
                            }}
                            onFocus={(event) => event.stopPropagation()}
                            control={<Checkbox checked={noCie} />}
                            label="Sin Cie"
                          />

                          <FormControlLabel
                            aria-label="Acknowledge"
                            onClick={(event) => {
                              event.stopPropagation();
                              setNoCie(false)
                              setMonthCie(true)
                              setYearCie(false)
                            }}
                            onFocus={(event) => event.stopPropagation()}
                            control={
                              <Checkbox checked={monthCie} />
                            }
                            label="Cie mensual"
                          />
                          <FormControlLabel
                            aria-label="Acknowledge"
                            onClick={(event) => {
                              event.stopPropagation();
                              setNoCie(false)
                              setMonthCie(false)
                              setYearCie(true)
                            }}
                            onFocus={(event) => event.stopPropagation()}
                            control={
                              <Checkbox checked={yearCie} />
                            }
                            label="Cie histórico"
                          />
                        </div>
                        {(monthCie || yearCie) && <div className="d-flex flex-row justify-content-center mb-2">
                        <TextField
                          style={{ width: "50%" }}
                          fullWidth
                          className="m-2"
                          id="outlined-multiline-static-4"
                          name="cieValue"
                          label="Valor de Cie necesario"
                          placeholder="Escribe aqui..."
                          variant="outlined"
                          value={props.values.cieValue}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          error={Boolean(
                            props.touched.cieValue && props.errors.cieValue
                          )}
                          helperText={
                            props.touched.cieValue ? props.errors.cieValue : ""
                          }

                        />
                        </div>
                        }
                          <div className="text-center p-5">
                            <UploadImage picture={picture} setCrop={setCrop} />
                            <div className="pt-4">
                              <Button
                                onClick={handleOk}
                                disabled={!crop || picture}
                                className="btn-primary btn-pill mx-1"
                              >
                                <span className="btn-wrapper--label">
                                  Seleccionar
                                </span>
                              </Button>
                              <Button
                                onClick={handleCancel}
                                disabled={!crop || !picture}
                                className="btn-warning btn-pill mx-1"
                              >
                                <span className="btn-wrapper--label">
                                  Cambiar
                                </span>
                              </Button>
                            </div>
                          </div>
                        </Card>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions className="p-4 bg-white-10">
                    <Button
                      className="btn-neutral-primary"
                      onClick={() => toggleEditPrice()}
                    >
                      Cancelar
                    </Button>{" "}
                    <Button className="btn-primary ml-auto" type="submit">
                      Guardar
                    </Button>
                  </DialogActions>
                </form>
              )}
            </Formik>
          </Dialog>
        )}
      </>
    );
  } else {
    return null;
  }
}
