import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GaugeChart from "react-gauge-chart";
import {
  Grid,
  LinearProgress,
  Card,
  CardContent,
  Button,
} from "@material-ui/core";



export default function Gauge({id, title,dataPercent}) {
  const [currentPercent2, setCurrentPercent2] = useState();

  return (
    <Card className="card-box w-100 p-3 d-flex align-items-center">
    <div className="d-flex flex-column flex-grow-1 justify-content-center w-100">
      <div className="card-header-alt d-flex justify-content-between align-items-center">
        <h6 className="font-weight-bold font-size-lg mb-0 text-black">
          {title}
        </h6>
      </div>
      <div className="w-100 mx-auto py-2 justify-content-between align-items-center">
        <GaugeChart
          id={"chartsGauges-clima-"+id}
          nrOfLevels={6}
          colors={["#f83245", "#f4772e", "#1bc943"]}
          arcWidth={0.3}
          percent={dataPercent}
          textColor="#000000"
        />
      </div>
    </div>
    </Card>
  );
}
