import React, { useEffect, useState, useContext } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";

import { UserContext } from "../../../../context/UserContext";
import { GETS_FOR_RECOGNITION } from "data/queries";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Gauge from "../../../../climind-components/Gauge/Gauge";
import LinearGauge from "../../../../climind-components/Linear-Gauge/LinearGauge";
import CardClimind from "../../../../climind-components/Card/Card";
import ThermometerGauge from "../../../../climind-components/Thermometer/ThermometerGauge";
import ChartClimind from "../../../../climind-components/Chart/Chart";
import RadarSpider from "../../../../climind-components/RadarSpider/RadarSpider";
import TablaClimind from "../../../../climind-components/TablaClimind/TablaClimind";
import Chart from "react-apexcharts";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
  },
}));

export default function PulseDashboard() {
  const classes = useStyles();

  function convert(array) {
    var map = {};
    for (var i = 0; i < array.length; i++) {
      var obj = array[i];
      obj.items = [];
      map[obj.id] = obj;
      var parent = obj.parent || "-";
      if (!map[parent]) {
        map[parent] = {
          items: [],
        };
      }
      map[parent].items.push(obj);
    }
    return map["-"].items;
  }

  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  let companyId = dbuser.getUserByUid.companyId;

  const { loading, error, data, networkStatus } = useQuery(GETS_FOR_RECOGNITION, {
    variables: { id: companyId },
    skip: !companyId,
    notifyOnNetworkStatusChange: true,
  });

  if (networkStatus === 4) return "Refetching!";
  if (loading) return "Esperando";
  if (error) return `Error! ${error}`;

  if (data) {
    // Número total de empleados (Entero) - QUERY
    let TotalUsers = data.getNumberOfUsersByCompanyId.count;

    // Número total de empleados Activos (Entero) - QUERY
    let TotalActiveUsers = data.getNumberOfActiveUsersByCompanyId.count;

    // Número total de empleados Activos en Reconocimiento (Entero) - QUERY
    let TotalActivePulseUsers = data.getNumberOfPulsoActiveUsersByCompanyId
      ? data.getNumberOfPulsoActiveUsersByCompanyId.count
      : 0;

    // Array de usuarios activos en Clima(Array) - QUERY
    let ArrayActivityUsersData = [];
    data.getUsersCountedPulso.map((item, index) => {
      let value = item.count;
      ArrayActivityUsersData.push(value);
    });

    // Número de días contabilizados
    // let TotalDays = ArrayActivityUsersData.length;
    let TotalDays = data.getUsersPulsoAverage.length;

    // Negative & Positive
    let arrayPositiveRatio = [];
    let arrayNegativeRatio = [];
    let totalNegativeDays = 0;
    let totalPositiveDays = 0;
    data.getUsersPulsoAverage.map((item, index) => {
      if (item.count < 0) {
        arrayNegativeRatio.push(item.count);
        totalNegativeDays += 1;
      } else {
        arrayPositiveRatio.push(item.count);
        totalPositiveDays += 1;
      }
    });

    // Indicadores
    console.log(data)

    // 1. Usuarios activos Reconocimientos en el mes
    let TotalActivePulseUsersPercent = TotalActivePulseUsers / TotalActiveUsers;

    // 2. Número de reconocimientos entregados
    let TotalRecognitions = 0
    data.getUsersCountedRecognitions.map((item,index)=>{
      TotalRecognitions = TotalRecognitions + item.count
    })

    // 3. Ratio Reconocimiento por usuario
    let ratioRecognitionsByUsers = TotalRecognitions / TotalActivePulseUsers;

    // 4. Numero de Propuestas de Mejora
    let numberProposal = 0
    data.getUsersCountedProposals.map((item,index)=>{
      numberProposal = numberProposal + item.count
    })
    



    return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <CardClimind
                  id="r-c-01"
                  title={"Usuarios activos Reconocimientos en el mes"}
                  dataPercent={TotalActivePulseUsersPercent}
                />
              </Grid>
              <Grid item xs={6}>
                <CardClimind
                  id="r-c-02"
                  title={"Número de reconocimientos entregados"}
                  dataPercent={TotalRecognitions}
                  type="number"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <CardClimind
                  id="r-c-03"
                  title={"Ratio Reconocimiento por usuario"}
                  dataPercent={ratioRecognitionsByUsers}
                />
              </Grid>
              <Grid item xs={6}>
                <CardClimind
                  id="r-c-04"
                  title={"Numero de Propuestas de Mejora"}
                  dataPercent={numberProposal}
                  type="number"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}
