// STEP 1 - Include Dependencies

// Include react
import React from "react";
import ReactDOM from "react-dom";

// Include the react-fusioncharts component
import ReactFC from "react-fusioncharts";

// Include the fusioncharts library
import FusionCharts from "fusioncharts";

//Import the Widgets
import Widgets from "fusioncharts/fusioncharts.widgets";

// Include the theme as fusion
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

import {
  Grid,
  LinearProgress,
  Card,
  CardContent,
  Button,
} from "@material-ui/core";

// Adding the chart and theme as dependency to the core fusioncharts
ReactFC.fcRoot(FusionCharts, Widgets, FusionTheme);

// STEP 3 - Creating the DOM element to pass the react-fusioncharts component
export default function LinearGauge({ id, title,subtitle,dataPercent }) {
  const dataSource = {
    chart: {
      theme: "fusion",
      caption: title,
      subcaption: subtitle,
      lowerLimit: "0",
      upperLimit: "100",
      numberSuffix: "%",
      chartBottomMargin: "40",
      valueFontSize: "11",
      valueFontBold: "0",
    },
    colorRange: {
      color: [
        {
          minValue: "0",
          maxValue: "35",
          label: "Bajo",
        },
        {
          minValue: "35",
          maxValue: "70",
          label: "Moderado",
        },
        {
          minValue: "70",
          maxValue: "100",
          label: "Alto",
        },
      ],
    },
    pointers: {
      pointer: [
        {
          value: dataPercent*100,
        },
      ],
    },
    trendPoints: {
      point: [
        //Trendpoint
        {
          startValue: "70",
          displayValue: " ",
          dashed: "1",
          showValues: "0",
        },
        {
          startValue: "85",
          displayValue: " ",
          dashed: "1",
          showValues: "0",
        },
        //Trendzone
        {
          startValue: "70",
          endValue: "85",
          displayValue: " ",
          alpha: "40",
        },
      ],
    },
  };

  return (
    <Card className="card-box">
      <ReactFC
        type={"hlineargauge"}
        width={"100%"}
        height={"180"}
        dataFormat={"json"}
        dataSource={dataSource}
        id={"lgauge-clima-"+id}
      />
    </Card>
  );
}
