import React, { useEffect, useState, useContext } from "react";
import { PageTitle } from 'layout-components';
import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  Container,
  InputAdornment,
  ButtonGroup,
  Card,
  Button,
  List,
  ListItem,
  TextField,
} from "@material-ui/core";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";

import PerfectScrollbar from "react-perfect-scrollbar";
import { useDropzone } from "react-dropzone";
import CountUp from "react-countup";

import CloseTwoToneIcon from "@material-ui/icons/CloseTwoTone";
import PublishTwoToneIcon from "@material-ui/icons/PublishTwoTone";
import AccountCircleTwoToneIcon from "@material-ui/icons/AccountCircleTwoTone";
import CheckIcon from "@material-ui/icons/Check";
import ReceivedRecognitions from "./components/ReceivedRecognitions"
import PreviousRecognitions from "./components/PreviousRecognitions"
import SentRecognitions from "./components/SentRecognitions"
import { UserContext } from "../../context/UserContext";
import { GETS_FOR_GENERAL } from "data/queries";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
//import General from "./components/General"
// import EditProfile from "./components/EditProfile"
// import Privacity from "./components/Privacity"
// import Groups from "./components/Groups"

export default function Recognitions() {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  const [inputBg, setInputBg] = useState(false);
  const toggleInputBg = () => setInputBg(!inputBg);

  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [activeTab2, setActiveTab2] = useState("1");

  const toggle2 = (tab) => {
    if (activeTab2 !== tab) setActiveTab2(tab);
  };

  const { loading, error, data, networkStatus } = useQuery(GETS_FOR_GENERAL, {
    variables: { id: dbuser.getUserByUid.id },
    skip: !dbuser.getUserByUid.id,
    notifyOnNetworkStatusChange: true,
  });

  if(data){
    return (
      <>
      <PageTitle
        titleHeading="Reconocimientos"
        titleDescription="Información de recoconocimientos."
      />
        <div className="app-inner-content-layout">
          <div className="app-inner-content-layout--main bg-white p-0">
            <div className="hero-wrapper rounded-bottom shadow-xxl bg-composed-wrapper bg-second">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                {/* <div
                  className="bg-composed-wrapper--image rounded-bottom opacity-3"
                  backgroundColor="orange"
                  style={{ backgroundColor: "white" }}
                /> */}
                <div className="bg-composed-wrapper--bg rounded-bottom bg-grow-early opacity-6" />
                <div className="bg-composed-wrapper--content px-3">
                  <Container className="pt-4" style={{ width: "80%" }}>
                    <div className="d-block d-md-flex align-items-start">
                      
                     
                    </div>

                    <List className="d-flex nav-tabs justify-content-center nav-tabs-warning tabs-animated tabs-animated-shadow my-5">
                      <ListItem
                        button
                        selected={activeTab2 === "1"}
                        className="bg-white-10 mx-3 rounded-lg"
                        onClick={() => {
                          toggle2("1");
                        }}
                      >
                        <span className="font-size-lg text-white px-2 py-1">
                          Reconocimientos recibidos
                        </span>
                        <div className="divider" />
                      </ListItem>

                      <ListItem
                        button
                        selected={activeTab2 === "2"}
                        className="bg-white-10 mx-3 rounded-lg"
                        onClick={() => {
                          toggle2("2");
                        }}
                      >
                        <span className="font-size-lg text-white px-2 py-1">
                          Reconocimientos entregados
                        </span>
                        <div className="divider" />
                      </ListItem>

                      <ListItem
                        button
                        selected={activeTab2 === "3"}
                        className="bg-white-10 mx-3 rounded-lg"
                        onClick={() => {
                          toggle2("3");
                        }}
                      >
                        <span className="font-size-lg text-white px-2 py-1">
                        Competencias de Climind
                        </span>
                        <div className="divider" />
                      </ListItem>

                      

                     
                    </List>
                  </Container>
                </div>
              </div>
            </div>
            <Container className="z-over py-5">

              <div
                className={clsx("tab-item-wrapper overflow-visible d-none", {
                  "d-block active": activeTab2 === "1",
                })}
                index={1}
              >
                  <ReceivedRecognitions data={data}/>
                {/* <General/> */}
              </div>
              
              <div
                className={clsx("tab-item-wrapper overflow-visible d-none", {
                  "d-block active": activeTab2 === "2",
                })}
                index={2}
              >
                  <PreviousRecognitions data={data}/>
              </div>
              <div
                className={clsx("tab-item-wrapper overflow-visible d-none", {
                  "d-block active": activeTab2 === "3",
                })}
                index={3}
              >
                  <SentRecognitions data={data}/>
              </div>
            
            </Container>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
}