import React, { useState, useCallback, useContext,useEffect } from "react";
import { GET_COMPETENCES_BY_USER_ID } from "data/queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "../../../../context/UserContext";
import { useQuery, useMutation,useLazyQuery } from "@apollo/react-hooks";
import defaultAvatar from "../../../../assets/images/climind/default_avatar.png";
import {
  Card,
  Button,
  Tooltip,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Input,
  Select,
  Menu,
  Table
} from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ModalAssignLeaderDepartment from "./ModalAssignLeaderDepartment";
import ModalAssignLeaderGroup from "./ModalAssignLeaderGroup";
import ModalEditUsers from "./ModalEditUsers";
import { DELETE_USER_FROM_DEPARTMENT, DELETE_USER_FROM_GROUP } from "data/mutations";
import { GET_DEPARTMENTS_GROUPS_AND_USER, GET_DEPARTMENT_BY_ID, GET_GROUP_BY_ID } from "data/queries";

// Forms
import { useFormik } from "formik";
import * as Yup from "yup";

export default function ModalViewTeam({
  openViewTeam,
  toggleViewTeam,
  teamData,
  type,
  compId
}) {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  let companyId = null;
  if (compId) {
    companyId = compId;
  } else {
    companyId = dbuser.getUserByUid.companyId;
  }
  
  const [team, setTeam] = useState(teamData);
  

  const [userSelected, setUserSelected] = useState({});

  const [modalDelete, setModalDelete] = useState(false);
  const toggleDelete = () => setModalDelete(!modalDelete);

  const [
    modalAssignLeaderDepartment,
    setModalAssignLeaderDepartment,
  ] = useState(false);

  const toggleLeaderDepartment = () =>
    setModalAssignLeaderDepartment(!modalAssignLeaderDepartment);

  const [
    modalAssignLeaderGroup,
    setModalAssignLeaderGroup,
  ] = useState(false);

  const toggleLeaderGroup = () =>
    setModalAssignLeaderGroup(!modalAssignLeaderGroup);

  const [
    modalEditUsers,
    setModalEditUsers,
  ] = useState(false);

  const [deleteUserDepartment, { data: dataDeleteDep }] = useMutation(DELETE_USER_FROM_DEPARTMENT);
  const [deleteUserGroup, { data: dataDeleteGroup }] = useMutation(DELETE_USER_FROM_GROUP);

  const { loading:loadingDep, error: errorDep, data:dataDep } = useQuery(GET_DEPARTMENT_BY_ID, {
    variables: { id: teamData.id },
    skip: type!="department",
    notifyOnNetworkStatusChange: true,
  });
  const { loading:loadingGroup, error: errorGroup, data:dataGroup } = useQuery(GET_GROUP_BY_ID, {
    variables: { id: teamData.id },
    skip: type!="group",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (dataDep != null|| dataGroup!= null){
      type === "department" ? setTeam(dataDep.getDepartmentById) : setTeam(dataGroup.getGroupById)
    }
  }, [dataDep,dataGroup]);

  const toggleEditUsers = () =>
    setModalEditUsers(!modalEditUsers);

  const [anchorEl, setAnchorEl] = useState(null);

  const [filterTable, setFilterTable] = useState(null);
  const [searchStatus1, setSearchStatus1] = useState(false);
  const toggleSearch1 = () => setSearchStatus1(!searchStatus1);

  const onSelectUser = useCallback((user) => {
    console.log(user);
  });


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (loadingDep || loadingGroup) return "Esperando";
  if(dataDep || dataGroup){
    
    console.log(team)
  return (
    <>
      <Dialog
        open={openViewTeam}
        onClose={toggleViewTeam}
        classes={{ paper: "modal-content rounded-lg" }}
        maxWidth="lg"
        fullWidth
      >
        <Card className="card-box p-4">
          <div className="card-tr-actions">
            <Button
              variant="text"
              className="p-0 d-30 border-0 btn-transition-none text-dark"
              aria-haspopup="true"
              aria-controls="simple-menu"
              onClick={handleClick}
            >
              <FontAwesomeIcon
                icon={["fas", "ellipsis-h"]}
                className="font-size-lg"
              />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              classes={{ list: 'p-0' }}
              open={Boolean(anchorEl)}
              onClose={handleClose}>
              <div className="p-3">
                <MenuItem className="pr-5 px-3 text-primary" onClick={() => {
                  if (type === "department") {
                    setModalAssignLeaderDepartment(true);
                  } else {
                    setModalAssignLeaderGroup(true);
                  }
                  handleClose();
                }}>
                  Asignar Líder
                </MenuItem>
                <MenuItem className="pr-5 px-3 text-primary" onClick={() => {
                  setModalEditUsers(true);
                  handleClose();
                }}>
                  Agregar Usuarios
                </MenuItem>
                {/* <MenuItem className="pr-5 px-3 text-primary" onClick={handleClose}>
                    Editar
                  </MenuItem> */}
              </div>
            </Menu>
          </div>
          <div className="d-flex align-items-center">
            <div className="avatar-icon-wrapper avatar-initials avatar-icon-md pr-2">
              <div className={`avatar-icon text-white`} style={{ backgroundColor: team.color }}>
                {team.name && team.name.charAt(0).toUpperCase()}
              </div>
            </div>

            <div className="w-100">
              <a
                href="#/"
                onClick={(e) => e.preventDefault()}
                className="font-weight-bold font-size-lg"
                title="..."
              >
                {team.name}
              </a>
              <p className="text-black-50 font-size-md mb-0">
                {team.description}
              </p>
            </div>
          </div>


          <div className="my-4 font-size-sm p-3 bg-secondary rounded-sm">
            <div className="d-flex justify-content-between">
              <span className="font-weight-bold">Color:</span>
              <span className="text-black-50">{team.color}</span>
              <span className="rounded-circle" style={{ backgroundColor: team.color, width: "20px" }}></span>
            </div>
          </div>

          <div className="my-4 font-size-sm p-3 bg-secondary rounded-sm">
            <div className="d-flex justify-content-between">
              <span className="font-weight-bold">Lider:</span>
            </div>
            <div className="d-flex justify-content-between py-2">
              {/* <span className="font-weight-bold">Nombre:</span>
                <span className="text-black-50">{team.color}</span> */}
              <div className="d-flex align-items-center">
                <div className="avatar-icon-wrapper mr-3">
                  <div className="avatar-icon">
                    {team.dataManager[0] && team.dataManager[0].urlImage ? (
                      <img
                        alt="..."
                        src={team.dataManager[0].urlImage}
                      />
                    ) : (
                      <img
                        alt="..."
                        src={defaultAvatar}
                      />
                    )}
                  </div>
                </div>
                <div>
                  <a
                    href="#/"
                    onClick={(e) => e.preventDefault()}
                    className="font-weight-bold text-black"
                    title="..."
                  >
                    {team.dataManager[0] && team.dataManager[0].name}
                  </a>
                  <span className="text-black-50 d-block">
                    {team.dataManager[0] && team.dataManager[0].email}
                    {/* {element.username ? <CheckCircleIcon color="secondary"/> : null} {element.email} */}
                  </span>
                </div>
              </div>
            </div>
          </div>


          <Table className="table table-borderless table-hover text-nowrap mb-0">
            <thead>
              <tr>
                <th className="text-left">Equipo de trabajo</th>
                <th className="text-center"></th>
              </tr>
            </thead>
            <tbody>
              {!filterTable
                ? team.dataUsers.map(
                  (element, index) => {
                    return (
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="avatar-icon-wrapper mr-3">
                              <div className="avatar-icon">
                                {element.urlImage ? (
                                  <img
                                    alt="..."
                                    src={element.urlImage}
                                  />
                                ) : (
                                  <img
                                    alt="..."
                                    src={defaultAvatar}
                                  />
                                )}
                              </div>
                            </div>
                            <div>
                              <a
                                href="#/"
                                onClick={(e) => e.preventDefault()}
                                className="font-weight-bold text-black"
                                title="..."
                              >
                                {element.name} {element.surname}
                              </a>
                              <span className="text-black-50 d-block">
                              {element.username ? <CheckCircleIcon color="secondary"/> : null} {element.email}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="text-center">
                          <Button
                            size="small"
                            className="btn-neutral-danger hover-scale-sm d-40 p-0 btn-icon"
                            // onClick={() => onSelectUser(element)}
                            onClick={()=>{
                              setUserSelected(element);
                              toggleDelete()}}
                          >
                            <FontAwesomeIcon
                              icon={["fas", "user-minus"]}
                            />
                          </Button>
                          <Dialog
                            open={modalDelete}
                            onClose={toggleDelete}
                            classes={{ paper: "shadow-lg rounded" }}

                          >
                            <div className="text-center p-5">
                              <div className="avatar-icon-wrapper rounded-circle m-0">
                                <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
                                  <FontAwesomeIcon
                                    icon={["fas", "times"]}
                                    className="d-flex align-self-center display-3"
                                  />
                                </div>
                              </div>
                              <h4 className="font-weight-bold mt-4">
                                ¿Estás seguro de que desas borrar este empleado?
                              </h4>
                              <p className="mb-0 font-size-lg text-muted">
                                No puedes deshacer esta operación.
                              </p>
                              <div className="pt-4">
                                <Button
                                  onClick={toggleDelete}
                                  className="btn-neutral-secondary btn-pill mx-1"
                                >
                                  <span className="btn-wrapper--label">
                                    Cancelar
                                  </span>
                                </Button>
                                <Button
                                  onClick={() => {
                                    console.log(userSelected)
                                    let users = []
                                    users.push(userSelected._id)
                                    if(type==="department"){
                                      deleteUserDepartment({
                                        variables: { id:team.id, users: users },
                                        refetchQueries: [
                                          {
                                            query: GET_DEPARTMENTS_GROUPS_AND_USER,
                                            variables: { id: companyId },
                                          },
                                          {
                                            query: GET_DEPARTMENT_BY_ID,
                                            variables: { id: teamData.id },
                                          }
                                        ],
                                      });
                                    }else{
                                      deleteUserGroup({
                                        variables: { id:team.id, users: users },
                                        refetchQueries: [
                                          {
                                            query: GET_DEPARTMENTS_GROUPS_AND_USER,
                                            variables: { id: companyId },
                                          },
                                          {
                                            query: GET_GROUP_BY_ID,
                                            variables: { id: teamData.id },
                                          }
                                        ],
                                      });
                                    }
                                    toggleDelete();
                                  }}
                                  className="btn-danger btn-pill mx-1"
                                >
                                  <span className="btn-wrapper--label">
                                    Borrar
                                  </span>
                                </Button>
                              </div>
                            </div>
                          </Dialog>
                        </td>
                      </tr>
                    );
                  }
                )
                : filterTable.map((element, index) => {
                  return (
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="avatar-icon-wrapper mr-3">
                            <div className="avatar-icon">
                              {element.urlImage ? (
                                <img
                                  alt="..."
                                  src={element.urlImage}
                                />
                              ) : (
                                <img
                                  alt="..."
                                  src={defaultAvatar}
                                />
                              )}
                            </div>
                          </div>
                          <div>
                            <a
                              href="#/"
                              onClick={(e) => e.preventDefault()}
                              className="font-weight-bold text-black"
                              title="..."
                            >
                              {element.name}
                            </a>
                            <span className="text-black-50 d-block">
                              UI Engineer, Apple Inc.
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="text-center">
                        <Button
                          size="small"
                          className="btn-neutral-dark hover-scale-sm d-40 p-0 btn-icon"
                          onClick={() => onSelectUser(element)}
                        >
                          <FontAwesomeIcon
                            icon={["fas", "arrow-right"]}
                          />
                        </Button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>

              <div className="text-center">
              <Button maxWidth="sm" className="btn-success text-uppercase mt-5" onClick={() => {
            toggleViewTeam()
          }}>
            <span className="font-weight-bold">Volver</span>
          </Button>
              </div>
          
        </Card>
      </Dialog>
      {modalAssignLeaderDepartment && (
        <ModalAssignLeaderDepartment
          openLeaderDepartment={modalAssignLeaderDepartment}
          toggleLeaderDepartment={toggleLeaderDepartment}
          departmentSelected={team}
        />
      )}
      {modalAssignLeaderGroup && (
        <ModalAssignLeaderGroup
          openLeaderGroup={modalAssignLeaderGroup}
          toggleLeaderGroup={toggleLeaderGroup}
          groupSelected={team}
        />
      )}

      {modalEditUsers && (
        <ModalEditUsers
          openLeaderDepartment={modalEditUsers}
          toggleLeaderDepartment={toggleEditUsers}
          teamSelected={team}
          type={type}
        />
      )}
    </>
  );
  }
}
