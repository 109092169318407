import React from 'react';

import { PageTitle } from 'layout-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Card, Button, List, Table } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import WrapperContainer from '../../../layout-components/WrapperContainer';
import GeneralTabs from './components/GeneralTabs'

export default function General() {
  return (
    <>
      <PageTitle
        titleHeading="General"
        titleDescription="Toda la información de tu empresa."
      />
      <WrapperContainer>
        <GeneralTabs/>

      </WrapperContainer>

    </>
  );
}
