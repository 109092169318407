import React, { useEffect, useState, useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import moment from 'moment';

import {
    RingLoader,
} from 'react-spinners';

import { UserContext } from "../../../../../context/UserContext";
import { GET_METRICS_ENVIRONMENTS, GET_DEPARTMENTS, GET_GROUPS } from "data/queries";

import DashboardEnvironment1 from "./DashboardEnvironment1";
import DashboardEnvironment2 from "./DashboardEnvironment2";
import DashboardEnvironment3 from "./DashboardEnvironment3";

export default function Dashboards({
    filtersInput,
    filtersPreviousInput
}) {
    function convert(array) {
        var map = {};
        array.forEach(obj => {
            map[obj.id] = { ...obj, items: [] };
        });
        array.forEach(obj => {
            var parent = obj.parent || "-";
            if (!map[parent]) {
                map[parent] = {
                    items: [],
                };
            }
            map[parent].items.push(map[obj.id]);
        });
        return map["-"].items;
    }

    function sortByOrder(arr) {
        arr.sort((a, b) => a.order - b.order);
    } 
    
    const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
    let companyId = dbuser.getUserByUid.companyId;
    let userId = dbuser.getUserByUid.id;

    const { loading: loadingPrevious, error: errorPrevious, data: dataPrevious, networkStatus: netPrevious } = useQuery(GET_METRICS_ENVIRONMENTS, {
        variables: { id: userId, input: filtersPreviousInput },
        skip: !companyId,
        notifyOnNetworkStatusChange: true,
    });

    const { loading, error, data, networkStatus } = useQuery(GET_METRICS_ENVIRONMENTS, {
        variables: { id: userId, input: filtersInput },
        skip: !companyId,
        notifyOnNetworkStatusChange: true,
    });

    if (networkStatus === 4) return "Refetching!";
    if (loading || loadingPrevious) return (<div
        className="d-flex align-items-center justify-content-center"
        style={{ width: '100%', height: '100%' }}>
        <RingLoader color={'var(--primary)'} loading={true} />
    </div>)
    if (error) return `Error! ${error}`;

    if (data && dataPrevious) {
        // console.log(dataDepartments.getDepartmentsByCompanyId);
        // console.log(dataGroups.getGroupsByCompanyId);
        // Número total de empleados (Entero) - QUERY
        let TotalUsers = data.getEnvironmentMetrics.totalUsers;
        let TotalUsersPrevious = dataPrevious.getEnvironmentMetrics.totalUsers;

        // Número total de empleados Activos (Entero) - QUERY
        let TotalActiveUsers = data.getEnvironmentMetrics.totalActiveUsers;
        let TotalActivePreviousUsers = dataPrevious.getEnvironmentMetrics.totalActiveUsers;


        // Número total de empleados Activos en Clima (Entero) - QUERY
        let TotalActiveClimaUsers = data.getEnvironmentMetrics.totalActiveClimaUsers
            ? data.getEnvironmentMetrics.totalActiveClimaUsers
            : 0;
        let TotalActiveClimaUsersPrevious = dataPrevious.getEnvironmentMetrics.totalActiveClimaUsers
            ? dataPrevious.getEnvironmentMetrics.totalActiveClimaUsers
            : 0;


        // // Array de usuarios activos en Clima(Array) - QUERY
        // let ArrayActivityUsersData = [];
        // data.getUsersCountedClima.map((item, index) => {
        //   let value = item.count;
        //   ArrayActivityUsersData.push(value);
        // });

        // Número de días contabilizados
        // console.log(data.getEnvironmentMetrics.dataUsers);
        let TotalDays = data.getEnvironmentMetrics.dataUsers.length;
        let TotalDaysPrevious = dataPrevious.getEnvironmentMetrics.dataUsers.length ? dataPrevious.getEnvironmentMetrics.dataUsers.length : 0;

        // Clima e indicadores
        console.log(data.getEnvironmentMetrics.arrayNatures)

        const arrayNaturesProccesed = [...data.getEnvironmentMetrics.arrayNatures]
        arrayNaturesProccesed.sort((a, b) => a.order - b.order);

        let arrayNatures = convert(arrayNaturesProccesed);

        // arrayNatures = arrayNatures.sort((a, b) => a.order - b.order);
        console.log(arrayNatures)

        // Indicadores

        // 1. Usuarios activos clima en el mes
        let TotalActiveClimaUsersPercent = TotalActiveClimaUsers / TotalActiveUsers;
        let TotalActivePreviousPulseUsersPercent = TotalActiveClimaUsersPrevious / TotalActivePreviousUsers;

        // console.log(TotalActiveClimaUsersPercent);
        // console.log(TotalActiveClimaUsers);
        // console.log(TotalActiveUsers);

        // 2. Nivel de recurrencia en clima
        let ArrayRecurringClimaUsers = [];
        let tmpTotalRecurringClimaUsers = 0;
        data.getEnvironmentMetrics.dataUsers.map((item, index) => {
            let tmpRecurringDay = item.users / TotalActiveUsers;
            tmpTotalRecurringClimaUsers =
                tmpTotalRecurringClimaUsers + tmpRecurringDay;
            ArrayRecurringClimaUsers.push(tmpRecurringDay * 100);
        });
        let TotalRecurringUsers = tmpTotalRecurringClimaUsers / TotalDays;
        // console.log(data.getEnvironmentMetrics.dataUsers);

        let tmpTotalRecurringClimaUsersPrevious = 0;
        dataPrevious.getEnvironmentMetrics.dataUsers.map((item, index) => {
            let tmpRecurringDay = item.users / TotalActivePreviousUsers;
            tmpTotalRecurringClimaUsersPrevious =
                tmpTotalRecurringClimaUsersPrevious + tmpRecurringDay;
        });
        let TotalRecurringUsersPrevious = tmpTotalRecurringClimaUsersPrevious / TotalDaysPrevious;
        // 3. Nivel de representatividad
        let RepresentivityClima = TotalActiveClimaUsers / TotalUsers;
        let RepresentivityClimaPrevious = TotalActiveClimaUsersPrevious / TotalUsersPrevious;
        // console.log(RepresentivityClima);
        // console.log(TotalActiveClimaUsers);
        // console.log(TotalUsers);
        // 4. Nivel de clima Global
        let KpiClimaGlobal = data.getEnvironmentMetrics.kpiGlobal;

        // 5. Nivel de Subsistema Individual
        let KpiIndividualSubsytem = data.getEnvironmentMetrics.kpiIndividual;

        // 6. Nivel de Subsistema Interpersonal
        let KpiInterpersonalSubsytem = data.getEnvironmentMetrics.kpiInterpersonal;

        // 7. Nivel de Subsistema Organizacional
        let KpiOrganizativeSubsytem = data.getEnvironmentMetrics.kpiOrganizative;

        // 8. Tabla de Naturalezas

        var optionsRadar = {
            series: [
                {
                    name: "Series 1",
                    data: [80, 50, 30, 40, 100, 20],
                },
            ],
            title: {
                text: "Basic Radar Chart",
            },
            xaxis: {
                categories: ["January", "February", "March", "April", "May", "June"],
            },
        };

        let optionsRadarArray = [];

        //Debugger de indices
        // console.log(TotalActiveClimaUsers);
        // console.log(TotalActiveUsers);


        // console.log(TotalActiveClimaUsersPrevious);
        // console.log(TotalActivePreviousUsers);

        // console.log(TotalActiveClimaUsersPercent);
        // console.log(TotalActivePreviousPulseUsersPercent);
        // console.log(TotalRecurringUsers);
        // console.log(RepresentivityClima); 
        // console.log(KpiClimaGlobal);
        // console.log(KpiIndividualSubsytem);
        // console.log(KpiInterpersonalSubsytem);
        // console.log(KpiOrganizativeSubsytem);
        // console.log(arrayNatures)

        return (
            <>
                <DashboardEnvironment1
                    TotalActiveClimaUsersPercent={TotalActiveClimaUsersPercent}
                    TotalActivePreviousPulseUsersPercent={TotalActivePreviousPulseUsersPercent}
                    TotalActiveClimaUsers={TotalActiveClimaUsers}
                    TotalRecurringUsers={TotalRecurringUsers}
                    RepresentivityClima={RepresentivityClima}
                    TotalActivePreviousUsers={TotalActivePreviousUsers}
                    TotalActiveClimaUsersPrevious={TotalActiveClimaUsersPrevious}
                    TotalRecurringUsersPrevious={TotalRecurringUsersPrevious}
                    RepresentivityClimaPrevious={RepresentivityClimaPrevious}
                />
                <DashboardEnvironment2
                    KpiClimaGlobal={KpiClimaGlobal}
                    KpiIndividualSubsytem={KpiIndividualSubsytem}
                    KpiInterpersonalSubsytem={KpiInterpersonalSubsytem}
                    KpiOrganizativeSubsytem={KpiOrganizativeSubsytem}
                />
                <DashboardEnvironment3 arrayNatures={arrayNatures} filtersInput={filtersInput} />
            </>
        );
    }
}
