import React, { Component, useState, useCallback } from "react";
import firebase from 'firebase'
import Avatar from 'react-avatar-edit'

const UploadImage = ({picture, setCrop}) => {

    const onClose = useCallback( () => {
      //setPreview(null)
    })
    const onCrop = useCallback( (image) => {
      setCrop(image)
    })

    return(
        <div>
          <div>
            {!picture ? 
            <Avatar
              width={390}
              height={295}
              imageWidth={390}
              imageHeight={295}
              onCrop={onCrop}
              onClose={onClose}
              label={"Elige aquí una imagen"}
            />
            :
            <>
            <div className="font-weight-bold text-center font-size-xl mb-4">
                Vista previa
              </div>
              {/* <div
                className="shadow-xxl m-4 rounded-lg overflow-hidden w-100 d-flex justify-content-center"
                style={{ height: 340 }}
              > */}
                <img
                                    className="rounded-md img-fit-container"
                                    src={picture}
                                    alt="..."
                                  />
              {/* </div> */}
            </>
            }
          </div>
        </div>
      )
}

export default UploadImage;