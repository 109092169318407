import React, { useState, useCallback, useContext } from "react";
import ModalSelectLeader from "./ModalSelectLeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import defaultAvatar from "../../../../assets/images/climind/default_avatar.png";
import {
  Dialog,
  Button,
  Card,
  CardContent,
  TextField,
  Menu,
  Snackbar,
  Switch,
  Checkbox,
  Grid,
  Table,
} from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { UserContext } from "../../../../context/UserContext";
import { GET_DEPARTMENTS, GET_GROUPS, GET_DEPARTMENTS_GROUPS_AND_USER, GET_GROUP_BY_ID,GET_DEPARTMENT_BY_ID } from "data/queries";
import { ADD_USERS_TO_GROUP, ADD_USERS_TO_DEPARTMENT } from "data/mutations";

import clsx from "clsx";

import { InputAdornment } from "@material-ui/core";

import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";

import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function valuetext(value) {
  return <span>{{ value }}°C</span>;
}

export default function ModalEditUsers({
  openLeaderDepartment,
  toggleLeaderDepartment,
  teamSelected,
  type,
  compId
}) {
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleBack = async (e) => {
    toggleLeaderDepartment();
  };

  const handleOk = async (e) => {
    let dataIdList = []
    let response
    employeesSelected.map((item, index) => {
      dataIdList.push(item.id)
    })

    if (type === "department") {
      response = await addUsersToDepartment({
        variables: { id: teamSelected.id, users: dataIdList },
        refetchQueries: [
          {
            query: GET_DEPARTMENTS_GROUPS_AND_USER,
            variables: { id: companyId },
          },
          {
            query: GET_DEPARTMENT_BY_ID,
            variables: { id: teamSelected.id },
          }
        ],
      });
    } else {
      response = await addUsersToGroup({
        variables: { id: teamSelected.id, users: dataIdList },
        refetchQueries: [
          {
            query: GET_DEPARTMENTS_GROUPS_AND_USER,
            variables: { id: companyId },
          },
          {
            query: GET_GROUP_BY_ID,
            variables: { id: teamSelected.id },
          }
        ],
      });
    }

    console.log("mutation response:" + response);
    setOpenSnackbar(true, () => {
      setTimeout(function () {
        setOpenSnackbar(false);
      }, 5000); //5 Second delay
    });

    toggleLeaderDepartment();
  };

  const onSelectUser = useCallback((user) => {
    setLeaderSelected(user);
  });

  //State
  const [leaderSelected, setLeaderSelected] = useState(null);
  const [modalLeader, setModalLeader] = useState(false);
  const toggleLeader = () => setModalLeader(!modalLeader);

  const [filterTable, setFilterTable] = useState(null);
  const [searchStatus1, setSearchStatus1] = useState(false);
  const toggleSearch1 = () => setSearchStatus1(!searchStatus1);

  const [employeesSelected, setEmployeesSelected] = useState([]);

  //Variables
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  let companyId = null;
  if (compId) {
    companyId = compId;
  } else {
    companyId = dbuser.getUserByUid.companyId;
  }

  //Hooks
  const { loading: loadingDep, error: errorDep, data: dataDep, networkStatus: netDep } = useQuery(GET_DEPARTMENTS, {
    variables: { id: companyId },
    skip: !companyId,
    notifyOnNetworkStatusChange: true,
  });
  const { loading: loadingGroup, error: errorGroup, data: dataGroup, networkStatus: netGroup } = useQuery(GET_GROUPS, {
    variables: { id: companyId },
    skip: !companyId,
    notifyOnNetworkStatusChange: true,
  });
  const { loading: loadingUsers, error: errorUsers, data: dataUsers, networkStatus: networkUsers } = useQuery(GET_DEPARTMENTS_GROUPS_AND_USER, {
    variables: { id: companyId, offset: 0, limit: 10000 },
    skip: !companyId,
    notifyOnNetworkStatusChange: true,
  });

  const [addUsersToDepartment, { dataMDepartment }] = useMutation(
    ADD_USERS_TO_DEPARTMENT
  );
  const [addUsersToGroup, { dataMGroup }] = useMutation(
    ADD_USERS_TO_GROUP
  );


  //Functions

  if (dataDep && dataGroup && dataUsers) {
    console.log(teamSelected);
    console.log(dataUsers);

    let departmentsList = dataDep.getDepartmentsByCompanyId;
    let groupsList = dataGroup.getDepartmentsByCompanyId;
    let allEmployees = dataUsers.getUsersWithGroups.users;

    allEmployees = allEmployees.filter((e) => !teamSelected.dataUsers.some((eS) => eS._id === e.id))
    // let employeesList = data.teamSelected.dataUsers;


    const handleCheckbox = (event) => {
      let employeeT = employeesSelected.find(
        (res) => res.id === event.target.value
      );
      if (employeeT) {
        //Remove
        let listTmp = employeesSelected.filter(
          (res) => res.id !== event.target.value
        );
        setEmployeesSelected(listTmp);
      } else {
        //Add
        let emp = allEmployees.find((res) => res.id === event.target.value);
        setEmployeesSelected([...employeesSelected, emp]);
      }
    };

    return (
      <>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          key={"top,center"}
          open={openSnackbar}
          // classes={{ root: "toastr-primary" }}
          autoHideDuration={4000}
          onClose={() => setOpenSnackbar(false)}
          message={"Usuarios asignados correctamente"}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity="success"
            style={{ color: "white" }}
          >
            Usuarios asignados correctamente
          </Alert>
        </Snackbar>
        <Dialog
          open={openLeaderDepartment}
          onClose={handleBack}
          maxWidth="lg"
          fullWidth
          // width="80%"
          classes={{ paper: "modal-content rounded-lg" }}
        >
          <div className="p-4 text-center">
            <h5 className="font-size-xl mb-1 font-weight-bold mx-2">
              Agregar usuarios al grupo
            </h5>
            {/* <p className="text-black-50 mb-4">Obligatorio seleccionar uno o más usuarios</p> */}
          </div>
          <div className="text-center px-2">
            {/* <h5 className="font-weight-bold mt-2" style={{ color: "#5a318e" }}>
              Usuarios seleccionados:
            </h5> */}

            <div className="text-center pl-5 pr-5 mb-3">
              <p className="text-black-50 mb-4">
                Selecciona a los usuarios que deseas agregar
              </p>
              <div
                className={clsx("search-wrapper search-wrapper--grow mb-4", {
                  "is-active": searchStatus1,
                })}
              >
                <TextField
                  variant="outlined"
                  size="small"
                  id="input-with-icon-textfield22-1"
                  onFocus={toggleSearch1}
                  onBlur={toggleSearch1}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchTwoToneIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    let filter = e.target.value.toLowerCase()
                    const filteredTable = allEmployees.filter(
                        (o) => {
                        let dataFilter =  o.name.toLowerCase().concat(" ", o.surname.toLowerCase())
                        let dataFilter2 = o.email.toLowerCase()
                        return dataFilter.indexOf(filter) !== -1 || dataFilter2.indexOf(filter) !== -1 
                      }

                      // (o) => {
                      //   let dataFilter = o.name.toLowerCase()
                      //   return dataFilter.indexOf(filter) !== -1
                      // }

                     
                        // (o) => Object.keys(o).some(
                        //   (k) =>
                        //     k === "name" &&
                        //     String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
                        // )
                    );

                    setFilterTable(filteredTable);
                  }}
                />
              </div>
              <Card className="card-box mb-spacing-6-x2">
                <CardContent>
                  <div className="table-responsive-xl">
                    <Table className="table table-borderless table-hover text-nowrap mb-0">
                      <thead>
                        <tr>
                          <th className="text-left">Empleados</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!filterTable || filterTable.lenght <= 0
                          ? allEmployees.map(
                            (element, index) => {
                              return (
                                <tr>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <Checkbox
                                        color="primary"
                                        id="checkboxProjects15"
                                        className="align-self-center mr-2"
                                        value={element.id}
                                        onChange={handleCheckbox}
                                      />
                                      <div className="avatar-icon-wrapper mr-3">
                                        <div className="avatar-icon">
                                          {element.urlImage ? (
                                            <img
                                              alt="..."
                                              src={element.urlImage}
                                            />
                                          ) : (
                                            <img
                                              alt="..."
                                              src={defaultAvatar}
                                            />
                                          )}
                                        </div>
                                      </div>
                                      <div className="text-left">
                                        <a
                                          href="#/"
                                          onClick={(e) => e.preventDefault()}
                                          className="font-weight-bold text-black"
                                          title="..."
                                        >
                                          {element.name} {element.surname}
                                        </a>
                                        <span className="text-black-50 d-block">
                                        {element.username ? <CheckCircleIcon color="secondary"/> : null} {element.email}
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            }
                          )
                          : filterTable.map((element, index) => {
                            return (
                              <tr>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <Checkbox
                                      color="primary"
                                      id="checkboxProjects15"
                                      className="align-self-center mr-2"
                                      value={element.id}
                                      onChange={handleCheckbox}
                                    />
                                    <div className="avatar-icon-wrapper mr-3">
                                      <div className="avatar-icon">
                                        {element.urlImage ? (
                                          <img
                                            alt="..."
                                            src={element.urlImage}
                                          />
                                        ) : (
                                          <img
                                            alt="..."
                                            src={defaultAvatar}
                                          />
                                        )}
                                      </div>
                                    </div>
                                    <div className="text-left">
                                      <a
                                        href="#/"
                                        onClick={(e) => e.preventDefault()}
                                        className="font-weight-bold text-black"
                                        title="..."
                                      >
                                        {element.name} {element.surname}
                                      </a>
                                      <span className="text-black-50 d-block">
                                      {element.username ? <CheckCircleIcon color="secondary"/> : null} {element.email}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                </CardContent>
              </Card>

              {/* <div className="pt-4" style={{ marginBottom: 20 }}>
                <Button
                  onClick={toggleLeader}
                  className="btn-neutral-dark btn-pill mx-1"
                >
                  <span className="btn-wrapper--label">Atrás</span>
                </Button>
              </div> */}
            </div>

            <h6 className="text-black-50 mb-0 mt-4">
              Si deseas modificar la lista de usuarios vuelve a la vista
              anterior
            </h6>

            <div className="pt-4" style={{ marginBottom: 20 }}>
              <Button
                onClick={handleBack}
                className="btn-neutral-dark btn-pill mx-1"
              >
                <span className="btn-wrapper--label">Cancelar</span>
              </Button>
              <Button
                onClick={handleOk}
                className="btn-success btn-pill mx-1"
              // disabled={!buttonValue}
              >
                <span className="btn-wrapper--label">Agregar</span>
              </Button>
            </div>
          </div>
        </Dialog>
        <ModalSelectLeader
          modalLeader={modalLeader}
          toggleLeader={toggleLeader}
          leaderSelected={leaderSelected}
          setLeaderSelected={setLeaderSelected}
        />
      </>
    );
  } else {
    return null;
  }
}
