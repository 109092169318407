import React, {Fragment, useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CreateIcon from '@material-ui/icons/Create';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import { Tree, Switch, Col, Row, message, Tag, Modal, Form, Input, Avatar } from 'antd';
import { GET_TREE_ENTITIES_WITH_QUESTIONS } from "data/queries";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {NEW_ENTITY, NEW_QUESTION, EDIT_ENTITY, EDIT_QUESTION} from 'data/mutations';
import {
    Table,
    LinearProgress,
    Card,
    CardContent,
    Button,
    Tooltip,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
  } from "@material-ui/core";
import UploadImage from './UploadImage'
import firebase from 'firebase'
const FormItem = Form.Item;
const {TextArea} = Input

const LoadQuestions = () => {
    //+++++++++++++++++++++++++++++++++++++++++
    //              ESTILOS
    //+++++++++++++++++++++++++++++++++++++++++
    const useTreeItemStyles = makeStyles((theme) => ({
        root: {
          color: theme.palette.text.secondary,
          '&:hover > $content': {
            backgroundColor: theme.palette.action.hover,
          },
          '&:focus > $content, &$selected > $content': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
            color: 'var(--tree-view-color)',
          },
          '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
            backgroundColor: 'transparent',
          },
        },
        content: {
          color: theme.palette.text.secondary,
          borderTopRightRadius: theme.spacing(2),
          borderBottomRightRadius: theme.spacing(2),
          paddingRight: theme.spacing(1),
          fontWeight: theme.typography.fontWeightMedium,
          '$expanded > &': {
            fontWeight: theme.typography.fontWeightRegular,
          },
        },
        group: {
          marginLeft: 16,
          '& $content': {
            paddingLeft: theme.spacing(3),
          },
        },
        expanded: {},
        selected: {},
        label: {
          fontWeight: 'inherit',
          color: 'inherit',
        },
        labelRoot: {
          display: 'flex',
          alignItems: 'center',
          padding: theme.spacing(0.5, 0),
        },
        labelIcon: {
          marginRight: theme.spacing(1),
        },
        labelText: {
          fontWeight: 'inherit',
          flexGrow: 1,
          fontSize: "1.17em"
        },
    }));

    StyledTreeItem.propTypes = {
        bgColor: PropTypes.string,
        color: PropTypes.string,
        labelIcon: PropTypes.elementType.isRequired,
        labelInfo: PropTypes.string,
        labelText: PropTypes.string.isRequired,
      };
      
    const useStyles = makeStyles({
        root: {
          height: 264,
          flexGrow: 1,
          maxWidth: 500,
        },
    });

    const classes = useStyles();

    // //+++++++++++++++++++++++++++++++++++++++++
    // //               STATE
    // //+++++++++++++++++++++++++++++++++++++++++

    const [entitySelected, setEntitySelected] = useState(null)
    const [questionSelected, setQuestionSelected] = useState(null)
    const [parentNewEntity, setParentNewEntity] = useState(null)
    const [nameNewEntity, setNameNewEntity] = useState(null)
    const [colorNewEntity, setColorNewEntity] = useState(null)
    const [nameNewQuestion, setNameNewQuestion] = useState(null)
    const [descriptionNewEntity, setDescriptionNewEntity] = useState(null)
    const [descriptionNewQuestion, setDescriptionNewQuestion] = useState(null)
    const [editEntityVisible, setEditEntityVisible] = useState(false)
    const [editQuestionVisible, setEditQuestionVisible] = useState(false)
    const [newEntityVisible, setNewEntityVisible] = useState(false)
    const [newQuestionVisible, setNewQuestionVisible] = useState(false)
    const [crop, setCrop] = useState(null)
    const [icon, setIcon] = useState(null)
    const [cropVisible, setCropVisible] = useState(false)
    const [saveEntity, { data:dataEntity, error: errorEntity, loading: loadingEntity }] = useMutation(NEW_ENTITY);
    const [saveQuestion, { data:dataQuestion, error: errorQuestion, loading: loadingQuestion }] = useMutation(NEW_QUESTION);
    const [editEntity, { data:dataEditEntity, error: errorEditEntity, loading: loadingEditEntity }] = useMutation(EDIT_ENTITY);
    const [editQuestion, { data:dataEditQuestion, error: errorEditQuestion, loading: loadingEditQuestion }] = useMutation(EDIT_QUESTION);
    const [num ,setNum] = useState(0)
    const selectEntity = useCallback( (parent) => {
        console.log(parent)
        setParentNewEntity(parent)
    })

    function onClickEntity(entity){
        console.log(entity)
        setEntitySelected(entity)
    }
    
    function StyledTreeItem(props) {
        const classes = useTreeItemStyles();
        const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, parent, entityData, ...other } = props;
        console.log(props)
        return (
            
            <TreeItem
                label={
                    <div className={classes.labelRoot} onClick={() => {selectEntity(parent)}}>
                        <Typography variant="body2" className={classes.labelText}>
                            {labelText}
                        </Typography>
                        <Typography variant="caption" color="inherit">
                            {labelInfo}
                        </Typography>
                        <div style={{ color: "#5a318e" }} onClick={()=>showModalEditEntity(entityData)}>
                            <LabelIcon color="inherit" className={classes.labelIcon}/>
                        </div>
                        <div style={{ color: "#5a318e" }} onClick={()=>showModalNewQuestion()}>
                            <AddCircleIcon color="inherit" className={classes.labelIcon}/>
                        </div>
                        <div style={{ color: "#5a318e" }} onClick={() => showModalNewEntity()}>
                            <AccountTreeIcon color="inherit" className={classes.labelIcon}/>
                        </div>
                        {/* <div style={{ color: "#5a318e" }} onClick={()=>{return(message.warning('eliminar'))}}>
                            <DeleteIcon color="inherit" className={classes.labelIcon}/>
                        </div> */}
            
                    </div>
                }
                style={{
                    '--tree-view-color': color,
                    '--tree-view-bg-color': bgColor,
                }}
                classes={{
                    root: classes.root,
                    content: classes.content,
                    expanded: classes.expanded,
                    selected: classes.selected,
                    group: classes.group,
                    label: classes.label,
                }}
                //   onIconClick={()=>{return(message.warning('Debe de contestar a la pregunta marcando un número'))}}
                {...other}
            />
                        
        );
    }

    // //+++++++++++++++++++++++++++++++++++++++++
    // //            MODAL DE ENTITY
    // //+++++++++++++++++++++++++++++++++++++++++
    const showModalNewEntity = useCallback( () => {
        setParentNewEntity(null)
        setNewEntityVisible(true)
    })
    const handleCancelNewEntity = useCallback( (e) => {
        setNewEntityVisible(false)
        setIcon(null)
        setCrop(null)
        setNameNewEntity(null)
        setColorNewEntity(null)
        setDescriptionNewEntity(null)
    })
    const handleOkNewEntity = async (e) => {
        let dataInput = {
            "name": nameNewEntity,
            "description": descriptionNewEntity,
            "parent": parentNewEntity ? parentNewEntity : null,
            "color": colorNewEntity
        };
        let response = await saveEntity({variables:{input:dataInput}, refetchQueries: [{query: GET_TREE_ENTITIES_WITH_QUESTIONS}]});
        console.log(response)
        //guardamos en firebase el icono
        try{
            const storageRef = firebase.storage().ref(`/icons/${response.data.newEntity.id}`);
            const pictureUploaded = storageRef.putString(crop, 'data_url').then( async function(snapshot) {
            try{
            await storageRef.getDownloadURL().then(async (url) => {
                console.log(url)
                    setIcon(url)
                    let dataEditInput = {
                        "urlImage": url
                    };
                    let responseEdit = await editEntity({variables:{id:response.data.newEntity.id, input:dataEditInput}, refetchQueries: [{query: GET_TREE_ENTITIES_WITH_QUESTIONS}]});
            })
            }catch(e){
            console.log(e)
            }
            });
        }catch(e){
            console.log(e)
        }
        
        setNameNewEntity(null)
        setDescriptionNewEntity(null)
        setColorNewEntity(null)
        setCrop(null)
        setIcon(null)
        setNewEntityVisible(false)
    }
    // //+++++++++++++++++++++++++++++++++++++++++
    // //            MODAL DE EDITAR ENTITY
    // //+++++++++++++++++++++++++++++++++++++++++
    const showModalEditEntity = useCallback( (element) => {
        console.log(element)
        setNameNewEntity(element.name)
        setDescriptionNewEntity(element.description)
        setColorNewEntity(element.color)
        setEditEntityVisible(true)
        // setCrop(entitySelected.urlImage)
    })
    const handleCancelEditEntity = useCallback( (e) => {
        setIcon(null)
        setCrop(null)
        setNameNewEntity(null)
        setDescriptionNewEntity(null)
        setColorNewEntity(null)
        setEditEntityVisible(false)
    })
    const handleOkEditEntity = async (e) => {
        //guardamos en firebase el icono
        let dataEditInput = {
            "name": (nameNewEntity ? nameNewEntity : entitySelected.name),
            "description": (descriptionNewEntity ? descriptionNewEntity : entitySelected.description),
            "color": (colorNewEntity ? colorNewEntity : entitySelected.color),
        };
        let responseEdit = await editEntity({variables:{id:entitySelected.id, input:dataEditInput}, refetchQueries: [{query: GET_TREE_ENTITIES_WITH_QUESTIONS}]});
        try{
            const storageRef = firebase.storage().ref(`/icons/${entitySelected.id}`);
            const pictureUploaded = storageRef.putString(crop, 'data_url').then( async function(snapshot) {
            try{
                console.log(crop)
            await storageRef.getDownloadURL().then(async (url) => {
                console.log(url)
                    setIcon(url)
                    let dataEditInput2 = {
                        "urlImage": url
                    };
                    let responseEdit2 = await editEntity({variables:{id:entitySelected.id, input:dataEditInput2}, refetchQueries: [{query: GET_TREE_ENTITIES_WITH_QUESTIONS}]});
            })
            }catch(e){
            console.log(e)
            }
            });
        }catch(e){
            console.log(e)
        }
        
        setNameNewEntity(null)
        setDescriptionNewEntity(null)
        setColorNewEntity(null)
        setCrop(null)
        setIcon(null)
        setEditEntityVisible(false)
    }
    // //+++++++++++++++++++++++++++++++++++++++++
    // //            MODAL DE EDITAR QUESTION
    // //+++++++++++++++++++++++++++++++++++++++++
    const showModalEditQuestion = useCallback( (element) => {
        console.log(element)
        setQuestionSelected(element)
        setNameNewQuestion(element.name)
        setDescriptionNewQuestion(element.description)
        setEditQuestionVisible(true)

    })
    const handleCancelEditQuestion = useCallback( (e) => {
        setQuestionSelected(null)
        setNameNewQuestion(null)
        setDescriptionNewQuestion(null)
        setEditQuestionVisible(false)
    })
    const handleOkEditQuestion = async (e) => {
        //guardamos en firebase el icono
        let dataEditInput = {
            "name": (nameNewQuestion ? nameNewQuestion : questionSelected.name),
            "description": (descriptionNewQuestion ? descriptionNewQuestion : questionSelected.description),
        };
        let responseEdit = await editQuestion({variables:{id:questionSelected.id, input:dataEditInput}, refetchQueries: [{query: GET_TREE_ENTITIES_WITH_QUESTIONS}]});
        
        setQuestionSelected(null)
        setNameNewQuestion(null)
        setDescriptionNewQuestion(null)
        setEditQuestionVisible(false)
    }
    // //+++++++++++++++++++++++++++++++++++++++++
    // //            MODAL DE QUESTION
    // //+++++++++++++++++++++++++++++++++++++++++
    const showModalNewQuestion = useCallback( () => {
        setNewQuestionVisible(true)
    })
    const handleCancelNewQuestion = useCallback( (e) => {
        setNewQuestionVisible(false)
    })
    const handleOkNewQuestion = async (e) => {
        let dataInput = {
            "name": nameNewQuestion,
            "description": descriptionNewQuestion,
            "entityId": parentNewEntity
        };
        console.log(dataInput)
        let response = await saveQuestion({variables:{input:dataInput}, refetchQueries: [{query: GET_TREE_ENTITIES_WITH_QUESTIONS}]});
        console.log(response)
        setNewQuestionVisible(false)
    }

    // //+++++++++++++++++++++++++++++++++++++++++
    // //            MODAL DE CROP
    // //+++++++++++++++++++++++++++++++++++++++++
    const showModalCrop = useCallback( () => {
        setCropVisible(true)
    })
    const handleCancelCrop = useCallback( (e) => {
        setCrop(null)
        setCropVisible(false)
    })
    const handleOkCrop = async (e) => {
        setCrop(crop)
        setCropVisible(false)
    }
    
    // //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    // //            FUNCION PARA CONVERTIR UN ARRAY DE OBJETOS A UN ARBOL 
    // //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

    function convert(array){
        var map = {};
        for(var i = 0; i < array.length; i++){
            var obj = array[i];
            obj.items= [];

            map[obj.id] = obj;

            var parent = obj.parent || '-';
            if(!map[parent]){
                map[parent] = {
                    items: []
                };
            }
            map[parent].items.push(obj);
        }
        return map['-'].items;
    }



    // //+++++++++++++++++++++++++++++++++++++++++
    // //            VISUALIZACION
    // //+++++++++++++++++++++++++++++++++++++++++
    const { loading, error, data, refetch, networkStatus } = useQuery(
    GET_TREE_ENTITIES_WITH_QUESTIONS,
    {
        notifyOnNetworkStatusChange: true
    }
    );
    if (networkStatus === 4) return "Refetching!";
    if (loading) return null;
    if (error) return `Error! ${error}`;
    if (data) {
        console.log(data)
        var treeEntities = convert(data.getTreeEntitiesWithQuestions)

        const getTreeItemsFromData = treeEntities => {
            return treeEntities.map(treeItemData => {
              let children = undefined;
              if (treeItemData.items && treeItemData.items.length > 0) {
                children = getTreeItemsFromData(treeItemData.items);
              }
              return (
                <StyledTreeItem 
                    key={treeItemData.id} 
                    nodeId={treeItemData.id} 
                    labelText={treeItemData.name}
                    parent={treeItemData.id} 
                    labelIcon={CreateIcon} 
                    children={children}
                    entityData={treeItemData}
                    onClick={()=>{onClickEntity(treeItemData)}}
                />
              );
            });
          };
          const formItemLayout = {
            labelCol: {
              xs: {span: 24},
              sm: {span: 6},
            },
            wrapperCol: {
              xs: {span: 24},
              sm: {span: 18},
            },
          };
  
        return (
            <Fragment>
                <Grid container spacing={4} style={{ maxHeight: "100%" }}>
                    <Grid item xs={12}>
                        <Button 
                            className="btn-primary text-uppercase fs-sm mb-2" 
                            onClick={() => showModalNewEntity()}
                        >
                            Nueva entidad de primer nivel
                        </Button>
                    </Grid>
                
                    <Grid item xs={6}>
                        {/* <Card className="card" style={{ borderColor: "#5a318e" }}> */}
                            <TreeView
                            style={{ maxHeight: "10000px", overflow: "auto", height:"10000px" }}
                                className={classes.root}
                                defaultExpanded={['3']}
                                defaultCollapseIcon={<ArrowDropDownIcon />}
                                defaultExpandIcon={<ArrowRightIcon />}
                                defaultEndIcon={<div style={{ width: 24 }} />}
                            >
                                {getTreeItemsFromData(treeEntities)}
                            </TreeView>
                        {/* </Card> */}
                    </Grid>
                    <Grid item xs={6} >
            <div style={{position: "fixed"}}>

            
                            <h3 className="mb-4">Preguntas</h3>

                        {entitySelected ? entitySelected.questions.map((element, index) => {
                            console.log(entitySelected.questions.length)
                            return(
                            <Card className="p-4 mt-4" style={{ borderColor: "#5a318e" }}>
                                        <Grid item xs={9}>
                                            <h4>{element.name}</h4>
                                        </Grid>
                                        
                                        <Grid item xs={3}>
                                            <Button className="btn-primary" onClick={() => showModalEditQuestion(element)}>Editar</Button>
                                        </Grid>
                                </Card> 
                            )
                        }) : (
                            <Card className="p-4 mt-4" style={{ borderColor: "#5a318e" }}>
                                <h4 >debes de seleccionar una entidad</h4>
                            </Card> )} 
                            </div>
                    </Grid>
                    
                </Grid>
                
                {/* +++++++++++++++++++++++++++++++++++++++++
                            MODAL DE EDITAR QUESTION
                +++++++++++++++++++++++++++++++++++++++++ */}
                {entitySelected && 
                <Dialog
                    open={editQuestionVisible}
                    onClose={handleCancelEditQuestion}
                    maxWidth="lg"
                    width="80%"
                    classes={{ paper: "modal-content rounded-lg" }}
                >
                    <div className="text-center pt-5">
                        <h5 className="text-center">
                            Editar pregunta
                        </h5>
                    </div>
                    <div className="p-4 text-center">
                    {questionSelected && <h5 className="font-size-xl mb-1 font-weight-bold mx-5">
                    Dentro de {entitySelected.name}
                    </h5>}
                    <Grid container spacing={12}>
                        <Grid style={{width:800}} item md={12}>
                        <Card
                            className="p-4 mt-4"
                            width="400px"
                            // style={{ backgroundColor: "#eceef7" }}
                            elevation={0}
                        >
                            <div>
                            <TextField
                                fullWidth
                                multiline
                                rows="4"
                                className="m-2"
                                id="outlined-multiline-static-4"
                                name="name"
                                label="Nombre"
                                placeholder="Escribe aqui..."
                                defaultValue={nameNewQuestion}
                                onChange={event => (setNameNewQuestion(event.target.value))}
                                variant="outlined"
                            />
                            </div>
                            <div>
                            <TextField
                                fullWidth
                                multiline
                                rows="4"
                                className="m-2"
                                id="outlined-multiline-static-4"
                                name="description"
                                label="Descripción"
                                placeholder="Escribe aqui..."
                                defaultValue={descriptionNewQuestion}
                                onChange={event => (setDescriptionNewQuestion(event.target.value))}
                                variant="outlined"
                            />
                            </div>
                        </Card>
                        </Grid>
                    </Grid>
                    </div>
                    <div className="text-center px-2">
                        <div className="pt-4" style={{ marginBottom: 20 }}>
                        <Button
                            onClick={() => {
                                handleCancelEditQuestion();
                            }}
                            className="btn-neutral-dark btn-pill mx-1"
                        >
                            <span className="btn-wrapper--label">Volver</span>
                        </Button>
                        <Button
                            onClick={() => { 
                                handleOkEditQuestion()
                            }}
                            className="btn-success btn-pill mx-1"
                            // disabled={!emailReset}
                        >
                            <span className="btn-wrapper--label">Cambiar</span>
                        </Button>
                        </div>
                    </div>
                </Dialog>
                }
                {/* +++++++++++++++++++++++++++++++++++++++++
                            MODAL DE ENTITY
                +++++++++++++++++++++++++++++++++++++++++ */}
                {/* {entitySelected &&  */}
                <Dialog
                    open={newEntityVisible}
                    onClose={handleCancelNewEntity}
                    maxWidth="lg"
                    width="80%"
                    classes={{ paper: "modal-content rounded-lg" }}
                >
                    <div className="text-center pt-5">
                        <h5 className="text-center">
                        Nueva entidad
                        </h5>
                    </div>
                    <div className="p-4 text-center">
                    {entitySelected && <h5 className="font-size-xl mb-1 font-weight-bold mx-5">
                    Dentro de {entitySelected.name}
                    </h5>}
                    <Grid container spacing={12}>
                        <Grid style={{width:800}} item md={12}>
                        <Card
                            className="p-4 mt-4"
                            width="400px"
                            // style={{ backgroundColor: "#eceef7" }}
                            elevation={0}
                        >
                            <div>
                            <TextField
                                fullWidth
                                multiline
                                rows="4"
                                className="m-2"
                                id="outlined-multiline-static-4"
                                name="name"
                                label="Nombre"
                                placeholder="Escribe aqui..."
                                defaultValue={nameNewEntity}
                                onChange={event => (setNameNewEntity(event.target.value))}
                                variant="outlined"
                            />
                            </div>
                            <div>
                            <TextField
                                fullWidth
                                multiline
                                rows="4"
                                className="m-2"
                                id="outlined-multiline-static-4"
                                name="description"
                                label="Descripción"
                                placeholder="Escribe aqui..."
                                defaultValue={descriptionNewEntity}
                                onChange={event => (setDescriptionNewEntity(event.target.value))}
                                variant="outlined"
                            />
                            </div>
                            <div>
                            <TextField
                                fullWidth
                                multiline
                                rows="4"
                                className="m-2"
                                id="outlined-multiline-static-4"
                                name="color"
                                label="Color"
                                placeholder="Escribe aqui..."
                                defaultValue={colorNewEntity}
                                onChange={event => (setColorNewEntity(event.target.value))}
                                variant="outlined"
                            />
                            </div>
                            <Avatar style={{ height: '70px', width: '70px' }} src={crop}/>
                            <br/>
                            <br/>
                            <br/>
                            <Button className="btn-primary text-uppercase fs-sm mb-2" onClick={showModalCrop}>
                                Añadir icono
                            </Button>
                        </Card>
                        </Grid>
                    </Grid>
                    </div>
                    <div className="text-center px-2">
                        <div className="pt-4" style={{ marginBottom: 20 }}>
                        <Button
                            onClick={() => {
                                handleCancelNewEntity();
                            }}
                            className="btn-neutral-dark btn-pill mx-1"
                        >
                            <span className="btn-wrapper--label">Volver</span>
                        </Button>
                        <Button
                            onClick={() => { 
                                handleOkNewEntity()
                            }}
                            className="btn-success btn-pill mx-1"
                            // disabled={!emailReset}
                        >
                            <span className="btn-wrapper--label">Crear</span>
                        </Button>
                        </div>
                    </div>
                </Dialog>
                {/* } */}
                {/* +++++++++++++++++++++++++++++++++++++++++
                            MODAL DE CROP
                +++++++++++++++++++++++++++++++++++++++++ */}
                <Dialog
                    open={cropVisible}
                    onClose={handleCancelCrop}
                    maxWidth="lg"
                    width="80%"
                    classes={{ paper: "modal-content rounded-lg" }}
                >
                    <UploadImage setCrop={setCrop}/>
                    <div className="text-center px-2">
                        <div className="pt-4" style={{ marginBottom: 20 }}>
                        <Button
                            onClick={() => {
                                handleCancelCrop();
                            }}
                            className="btn-neutral-dark btn-pill mx-1"
                        >
                            <span className="btn-wrapper--label">Volver</span>
                        </Button>
                        <Button
                            onClick={() => { 
                                handleOkCrop()
                            }}
                            className="btn-success btn-pill mx-1"
                            // disabled={!emailReset}
                        >
                            <span className="btn-wrapper--label">Crear</span>
                        </Button>
                        </div>
                    </div>
                </Dialog>
                {/* +++++++++++++++++++++++++++++++++++++++++
                            MODAL DE QUESTION
                +++++++++++++++++++++++++++++++++++++++++ */}
                <Dialog
                    open={newQuestionVisible}
                    onClose={handleCancelNewQuestion}
                    maxWidth="lg"
                    width="80%"
                    classes={{ paper: "modal-content rounded-lg" }}
                >
                     <div className="text-center pt-5">
                        <h5 className="text-center">
                            Nueva pregunta
                        </h5>
                    </div>
                    <div className="p-4 text-center">
                    {entitySelected && <h5 className="font-size-xl mb-1 font-weight-bold mx-5">
                    Dentro de {entitySelected.name}
                    </h5>}
                    <Grid container spacing={12}>
                        <Grid style={{width:800}} item md={12}>
                        <Card
                            className="p-4 mt-4"
                            width="400px"
                            // style={{ backgroundColor: "#eceef7" }}
                            elevation={0}
                        >
                            <div>
                            <TextField
                                fullWidth
                                multiline
                                rows="4"
                                className="m-2"
                                id="outlined-multiline-static-4"
                                name="name"
                                label="Nombre"
                                placeholder="Escribe aqui..."
                                defaultValue={nameNewQuestion}
                                onChange={event => (setNameNewQuestion(event.target.value))}
                                variant="outlined"
                            />
                            </div>
                            <div>
                            <TextField
                                fullWidth
                                multiline
                                rows="4"
                                className="m-2"
                                id="outlined-multiline-static-4"
                                name="description"
                                label="Descripción"
                                placeholder="Escribe aqui..."
                                defaultValue={descriptionNewQuestion}
                                onChange={event => (setDescriptionNewQuestion(event.target.value))}
                                variant="outlined"
                            />
                            </div>
                        </Card>
                        </Grid>
                    </Grid>
                    </div>
                    <div className="text-center px-2">
                        <div className="pt-4" style={{ marginBottom: 20 }}>
                        <Button
                            onClick={() => {
                                handleCancelNewQuestion();
                            }}
                            className="btn-neutral-dark btn-pill mx-1"
                        >
                            <span className="btn-wrapper--label">Volver</span>
                        </Button>
                        <Button
                            onClick={() => { 
                                handleOkNewQuestion()
                            }}
                            className="btn-success btn-pill mx-1"
                            // disabled={!emailReset}
                        >
                            <span className="btn-wrapper--label">Crear</span>
                        </Button>
                        </div>
                    </div>
                </Dialog>
                {/* +++++++++++++++++++++++++++++++++++++++++
                            MODAL DE EDITAR ENTITY
                +++++++++++++++++++++++++++++++++++++++++ */}
                                <Dialog
                    open={editEntityVisible}
                    onClose={handleCancelEditEntity}
                    maxWidth="lg"
                    width="80%"
                    classes={{ paper: "modal-content rounded-lg" }}
                >
                     <div className="text-center pt-5">
                        <h5 className="text-center">
                        Editar entidad
                        </h5>
                    </div>
                    <div className="p-4 text-center">
                    {entitySelected && <h5 className="font-size-xl mb-1 font-weight-bold mx-5">
                    Dentro de {entitySelected.name}
                    </h5>}
                    <Grid container spacing={12}>
                        <Grid style={{width:800}} item md={12}>
                        <Card
                            className="p-4 mt-4"
                            width="400px"
                            // style={{ backgroundColor: "#eceef7" }}
                            elevation={0}
                        >
                            <div>
                            <TextField
                                fullWidth
                                multiline
                                rows="4"
                                className="m-2"
                                id="outlined-multiline-static-4"
                                name="name"
                                label="Nombre"
                                placeholder="Escribe aqui..."
                                defaultValue={nameNewEntity}
                                onChange={event => (setNameNewEntity(event.target.value))}
                                variant="outlined"
                            />
                            </div>
                            <div>
                            <TextField
                                fullWidth
                                multiline
                                rows="4"
                                className="m-2"
                                id="outlined-multiline-static-4"
                                name="description"
                                label="Descripción"
                                placeholder="Escribe aqui..."
                                defaultValue={descriptionNewEntity}
                                onChange={event => (setDescriptionNewEntity(event.target.value))}
                                variant="outlined"
                            />
                            </div>
                            <div>
                            <TextField
                                fullWidth
                                multiline
                                rows="4"
                                className="m-2"
                                id="outlined-multiline-static-4"
                                name="color"
                                label="Color"
                                placeholder="Escribe aqui..."
                                defaultValue={colorNewEntity}
                                onChange={event => (setColorNewEntity(event.target.value))}
                                variant="outlined"
                            />
                            </div>
                            {entitySelected && entitySelected.urlImage && !crop? (
                            <Fragment>
                                <Avatar style={{ height: '70px', width: '70px' }} src={entitySelected.urlImage}/>        
                                <br/>
                                <br/>
                                <br/>
                                <Button className="btn-primary text-uppercase fs-sm mb-2" onClick={showModalCrop}>
                                    Añadir icono
                                </Button>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Avatar style={{ height: '70px', width: '70px' }} src={crop}/>        
                                <br/>
                                <br/>
                                <br/>
                                <Button className="btn-primary text-uppercase fs-sm mb-2" onClick={showModalCrop}>
                                    Añadir icono
                                </Button>
                            </Fragment>
                        )}
                        </Card>
                        </Grid>
                    </Grid>
                    </div>
                    <div className="text-center px-2">
                        <div className="pt-4" style={{ marginBottom: 20 }}>
                        <Button
                            onClick={() => {
                                handleCancelEditEntity();
                            }}
                            className="btn-neutral-dark btn-pill mx-1"
                        >
                            <span className="btn-wrapper--label">Volver</span>
                        </Button>
                        <Button
                            onClick={() => { 
                                handleOkEditEntity()
                            }}
                            className="btn-success btn-pill mx-1"
                            // disabled={!emailReset}
                        >
                            <span className="btn-wrapper--label">Crear</span>
                        </Button>
                        </div>
                    </div>
                </Dialog>
            </Fragment>
        );
    }
}

export default LoadQuestions