import React, {useContext} from 'react'
import {Route, Redirect } from 'react-router-dom'
import { UserContext } from "./context/UserContext";


const PrivateRoute = ({component: RouteComponent, ...rest}) => {
    const{ currentUser, dbuser, setReloadUserContext } = useContext(UserContext)

    // console.log("DENTRO DE PRIVATE ROUTE")

    return(
        <Route
        {...rest}
        render={ routeProps => 
            (currentUser ) ? (
                <RouteComponent {...routeProps} />
            ): (
                <Redirect to={'/SingIn'} />
            )    
        }
        />
    )
}

export default PrivateRoute