import React, { useState, useCallback, useContext } from "react";
import ModalSelectUser from "./ModalSelectUser";
import ModalSelectCompetence from "./ModalSelectCompetence";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import defaultAvatar from "../../../assets/images/climind/default_avatar.png";
import {
  Dialog,
  Button,
  Card,
  TextField,
  Menu,
  Snackbar,
  Switch,
  Grid,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { UserContext } from "../../../context/UserContext";
import {
  GET_USER_BY_UID,
  GETS_FOR_GENERAL,
  GET_TIMELINE
} from "data/queries";
import { NEW_IMPROVEMENT_PROPOSAL } from "data/mutations";
import InputEmoji from "react-input-emoji";
import grafico from "../../../assets/images/climind/planta-de-cultivo.svg";
import InfoCargayritmo from "./DataInfo";
import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function valuetext(value) {
  return <span>{{ value }}°C</span>;
}

export default function ModalProposal({ modalProposal, toggleProposal, refetch }) {
  const { currentUser, dbuser, getUserWithFirebase } = useContext(UserContext);
  const [userSelected, setUserSelected] = useState(null);
  const [checkExclude, setCheckExclude] = useState(false)
  const [checkInclude, setCheckInclude] = useState(false)
  const [checkImprove, setCheckImprove] = useState(false)
  const [actionProposal, setActionProposal] = useState(null)
  const [competenceSelected, setCompetenceSelected] = useState(null);
  const toggleUser = () => setModalUser(!modalUser);
  const toggleCompetence = () => setModalCompetence(!modalCompetence);
  const [modalUser, setModalUser] = useState(false);
  const [modalCompetence, setModalCompetence] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false)
  const [commentary, setCommentary] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [saveNewImprovement, { data:dataSaveE, error: errorSaveE, loading: loadingSaveE }] = useMutation(NEW_IMPROVEMENT_PROPOSAL);


  const handleBack = async (e) => {
    setCommentary("");
    setUserSelected(null)
    setActionProposal(null)
    setCompetenceSelected(null)
    toggleProposal();
  };

  const handleOk = async (e) => {
    if (buttonClicked) return
    setButtonClicked(true) 
    let dataInput = {
      "companyId": dbuser.getUserByUid.companyId,
      "sender": dbuser.getUserByUid.id,
      "receiver": userSelected.id,
      "competenceId": competenceSelected.id,
      "commentary": commentary,
      "actionCheck": actionProposal
    };
    let uid = dbuser.getUserByUid.uid
    let companyId = dbuser.getUserByUid.companyId
    let id = dbuser.getUserByUid.id
    let response = await saveNewImprovement({
        variables:{input:dataInput}, 
        refetchQueries: [{query: GET_USER_BY_UID, variables: {uid, uid}},
                         {query: GETS_FOR_GENERAL, variables: {id, id}},
                         {query: GET_TIMELINE, variables: {id, companyId}},]
    });
    console.log("mutation response:"+response);
    getUserWithFirebase()
     setCommentary("")
     setCompetenceSelected(null)
     setActionProposal(null)
     setUserSelected(null)
      setOpenSnackbar(true, () => {
        setTimeout(function () {
          setOpenSnackbar(false);
        }, 5000); //5 Second delay
      });
      refetch()
      toggleProposal();
      setButtonClicked(false)

  };




    return (
      <>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          key={"top,center"}
          open={openSnackbar}
          // classes={{ root: "toastr-primary" }}
          autoHideDuration={4000}
          onClose={() => setOpenSnackbar(false)}
          message={"entregado correctamente"}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity="success"
            style={{ color: "white" }}
          >
            Entregado correctamente
          </Alert>
        </Snackbar>
        <Dialog
          open={modalProposal}
          onClose={handleBack}
          maxWidth="lg"
          width="80%"
          classes={{ paper: "modal-content rounded-lg" }}
        >
          <div className="text-center pt-5">
            <div className="avatar-icon-wrapper rounded-circle mb-4">
              {/* <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-success text-success m-0 d-130"> */}
              <img
                alt="..."
                className="card-img-top rounded-sm"
                src={grafico}
                style={{ width: 70 }}
              />
              {/* </div> */}
            </div>
            <h5 className="text-center">
              Envía una Propuesta de mejora
            </h5>
          </div>
          <div className="p-4 text-center">
          <h5 className="font-size-xl mb-1 font-weight-bold mx-5">
          Paso 1. Selecciona el usuario al que vas a entregar la propuesta de mejora
          </h5>
          <p className=" mb-4">Obligatorio</p>
          <Grid container spacing={12}>
            <Grid item md={12}>
            <Button
            className="btn-success text-uppercase font-weight-bold font-size-xs mb-2"
            style={ userSelected ? { backgroundColor: "orange" } : { backgroundColor: "#5a318e" }}
            onClick={toggleUser}
          >
            {userSelected ? "Cambiar" : "Seleccionar usuario"}
          </Button>
          {userSelected ? (
            <>
              <div className="d-flex align-items-center justify-content-center mb-2 mt-3">
                <div className="avatar-icon-wrapper mr-3">
                  <div className="avatar-icon">
                    {userSelected.urlImage ? (
                      <img alt="..." src={userSelected.urlImage} />
                    ) : (
                      <img alt="..." src={defaultAvatar} />
                    )}
                  </div>
                </div>
                <div>
                  <a
                    href="#/"
                    onClick={(e) => e.preventDefault()}
                    className="font-weight-bold text-black"
                    title="..."
                  >
                    {userSelected.name}
                  </a>
                  <span className=" d-block">
                  {userSelected.username ? `@${userSelected.username}` : ""}
                  </span>
                </div>
              </div>
            </>
          ) : null}
            </Grid>
          </Grid>
          <h5 className="font-size-xl mb-1 font-weight-bold mx-5 mt-5">
          Paso 2. Selecciona la competencia que más se adecúe a tu propuesta de mejora
          </h5>
          <p className=" mb-4">Obligatorio</p>
          <Grid container spacing={12}>
            <Grid item md={12}>
            <Button
            className="btn-success text-uppercase font-weight-bold font-size-xs mb-2"
            style={ competenceSelected ? { backgroundColor: "orange" } : { backgroundColor: "#5a318e" }}
            onClick={toggleCompetence}
          >
           {competenceSelected ? "Cambiar" : "Seleccionar competencia"}
          </Button>
          {competenceSelected ? (
            <>
              <div className="d-flex align-items-center justify-content-center mb-2 mt-3">
                <div className="avatar-icon-wrapper mr-3">
                  <div className="avatar-icon">
                    {competenceSelected.urlImage ? (
                      <img alt="..." src={competenceSelected.urlImage} />
                    ) : (
                      <img alt="..." src={defaultAvatar} />
                    )}
                  </div>
                </div>
                <div>
                  <a
                    href="#/"
                    onClick={(e) => e.preventDefault()}
                    className="font-weight-bold text-black"
                    title="..."
                  >
                    {competenceSelected.name}
                  </a>
                  
                </div>
              </div>
            </>
          ) : null}
            </Grid>
          </Grid>
        </div>
          <div className="text-center px-2">
            <h5 className="font-weight-bold mt-2" style={{ color: "#5a318e" }}>
            Paso 3. Debes escribir un comentario
            </h5>
            <h6>Obligatorio (min 10 caracteres)</h6>
            <div className="d-flex justify-content-center">
                <div  style={{ width: "90%"}} >
                <InputEmoji
                  value={commentary}
                  onChange={setCommentary}
                  cleanOnEnter
                  borderColor="purple"
                  placeholder="Escribe aquí"
                  keepOpenend
                />
                </div>
                </div>

                <div >   
        <div className="pt-4 text-center">
        <h5 className="font-weight-bold mt-2" style={{ color: "#5a318e" }}>
            Paso 4. Marca lo que quieres que haga el usuario
            </h5>
          
          </div>
        <div className="d-flex flex-row justify-content-center mb-spacing-6-x2">

              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => {
                  event.stopPropagation()
                  setCheckExclude(!checkExclude)
                  if(checkExclude === false){
                    setCheckInclude(false)
                    setCheckImprove(false)
                    setActionProposal("EXCLUDE")
                  }else{
                    setActionProposal(null)
                  }
                }}
                onFocus={(event) => event.stopPropagation()}
                control={<Checkbox checked={checkExclude} className="mr-2" />}
                label="Excluir comportamiento"
              />
            

          
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => {
                  event.stopPropagation()
                  setCheckInclude(!checkInclude)
                  if(checkInclude === false){
                    setCheckExclude(false)
                    setCheckImprove(false)
                    setActionProposal("INCLUDE")
                  }else{
                    setActionProposal(null)
                  }
                  
                }}
                onFocus={(event) => event.stopPropagation()}
                control={<Checkbox checked={checkInclude} className="mr-2" />}
                label="Incluir comportamiento"
              />

          
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => {
                  event.stopPropagation()
                  setCheckImprove(!checkImprove)
                  if(checkImprove === false){
                    setCheckInclude(false)
                    setCheckExclude(false)
                    setActionProposal("IMPROVE")
                  }else{
                    setActionProposal(null)
                  }
                  
                }}
                onFocus={(event) => event.stopPropagation()}
                control={<Checkbox checked={checkImprove} className="mr-2" />}
                label="Mejorar comportamiento"
              />

        </div>
        </div> 
        <h6 className="text-black-50 mb-0 mt-4">
              * Por defecto el comentario sólo se enviará al usuario seleccionado. NUNCA podrá ser visto por los administradores, ni será enviado al Timeline
            </h6>

            <div className="pt-4" style={{ marginBottom: 20 }}>
              <Button
                onClick={handleBack}
                className="btn-neutral-dark btn-pill mx-1"
              >
                <span className="btn-wrapper--label">Cancelar</span>
              </Button>
              <Button
                onClick={handleOk}
                className="btn-success btn-pill mx-1"
                disabled={!userSelected || !competenceSelected || !commentary || commentary.length < 10 || !actionProposal}
              >
                <span className="btn-wrapper--label">Enviar</span>
              </Button>
            </div>
          </div>
        </Dialog>
        <ModalSelectUser
        modalUser={modalUser}
        toggleUser={toggleUser}
        userSelected={userSelected}
        setUserSelected={setUserSelected}
      />
      <ModalSelectCompetence
        modalCompetence={modalCompetence}
        toggleCompetence={toggleCompetence}
        competenceSelected={competenceSelected}
        setCompetenceSelected={setCompetenceSelected}
      />
      </>
    );

}