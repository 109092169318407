import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  Card,
  Button,
  Tooltip,
  CardContent,
  List,
  ListItem,
  Dialog,
} from "@material-ui/core";
import Chart from "react-apexcharts";
import GaugeChart from "react-gauge-chart";
import { UserContext } from "../../context/UserContext";
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";
import { CSVLink, CSVDownload } from "react-csv";
import {
  GET_ACHIEVED_AWARDS_BY_COMPANYID,
  GET_USERS_BY_COMPANY_ID,
  GET_USER_BY_UID,
  GET_USERS_WITH_CIE_BY_COMPANY_ID,
} from "data/queries";
import { CHANGE_STATE_AWARD } from "data/mutations";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
var moment = require("moment-timezone");
export default function GiveAwards() {
  const { currentUser, dbuser, getUserWithFirebase, setReloadUserContext } = useContext(UserContext);
  const [dataSelected, setDataSelected] = useState(false);
  const [modalViewPrice, setModalViewPrice] = useState(false);
  const toggleViewPrice = () => setModalViewPrice(!modalViewPrice);
  const [modalDeliver, setModalDeliver] = useState(false);
  const toggleDeliver = () => setModalDeliver(!modalDeliver);
  const [modalCancel, setModalCancel] = useState(false);
  const toggleCancel = () => setModalCancel(!modalCancel);
  const [countPoints, setCountPoints] = useState(0);
  const [offset, setOffset] = useState(0);
  const limit = 10;

  const [
    changeStateAward,
    { data: dataChange, error: errorChange, loading: loadingChange },
  ] = useMutation(CHANGE_STATE_AWARD);

  const { loading, error, data, networkStatus, refetch } = useQuery(
    GET_ACHIEVED_AWARDS_BY_COMPANYID,
    {
      variables: {
        id: dbuser.getUserByUid.companyId,
        limit: 10,
        offset: offset,
      },
      skip: !dbuser.getUserByUid.companyId,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache'
    }
  );

  const { loading: loadingCSV, error: errorCSV, data: dataCSV, networkStatus: networkStatusCSV, refetch: refetchCSV } = useQuery(
    GET_ACHIEVED_AWARDS_BY_COMPANYID,
    {
      variables: {
        id: dbuser.getUserByUid.companyId,
      },
      skip: !dbuser.getUserByUid.companyId,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache'
    }
  );

  const handlePageChange = (_, newPage) => {
    // Calcular el nuevo offset al cambiar de página
    const newOffset = (newPage - 1) * limit;
    setOffset(newOffset);
    // console.log(newOffset);
  };

  if (data && dataCSV) {
    // console.log(data);
    // console.log(dataCSV)
    console.log(dataSelected)

    const arrayCSV = []
    dataCSV.getAchievedAwardsByCompanyId.achievedAwards.forEach(
      element =>
      {
        let fechaSolicitado = new Date(element.date)
        let fechaEntregado = element.awardDeliveryDate ? new Date(element.awardDeliveryDate) : null
        let fechaCancelado = element.awardCancelledDate ? new Date(element.awardCancelledDate) : null
        let nombrePremio = (element.awardId && element.awardId.name) ?? "Premio sin nombre"
        let estado = element.state == "UNDELIVERED" ? "No entregado" : element.state == "DELIVERED" ? "Entregado" : element.state == "CANCELLED" ? "Cancelado" : "Estado desconocido"
        let email = element.userId.email
        let name = element.userId.name
        let surname = element.userId.surname
        arrayCSV.push({
          nombrePremio,
          name,
          surname,
          email,
          estado,
          fechaSolicitado,
          fechaEntregado,
          fechaCancelado
        })
      }
    )

    // console.log(arrayCSV)

    return (
      <>
        <div>
          <CSVLink separator={";"} data={arrayCSV} filename="awards.csv">
            <Button className="btn-primary mb-3 mr-3">
              Descargar CSV de Premios
            </Button>
          </CSVLink>
        </div>
        <div className="mb-spacing-6">
          <Grid container spacing={6}>
            <Grid item xl={12}>
              <Card className="card-box">
                <div className="card-header bg-light">
                  <div className="card-header--title">
                    <big className="d-block text-uppercase mt-1 font-weight-bold">
                      Premios canjeados por los usuarios de tu empresa
                    </big>
                  </div>
                </div>
                <List component="div" className="list-group-flush">
                  {data.getAchievedAwardsByCompanyId.achievedAwards.length !=
                  0 ? (
                    data.getAchievedAwardsByCompanyId.achievedAwards.map(
                      (element, index) => {
                        // console.log(element);
                        return (
                          <ListItem className="py-3">
                            <Grid container spacing={0}>
                              <Grid
                                item
                                xl={6}
                                md={12}
                                className="d-flex align-items-center"
                              >
                                <Button
                                  size="small"
                                  className="btn-neutral-success mr-4"
                                  onClick={() => {
                                    setDataSelected(element);
                                    toggleViewPrice();
                                  }}
                                >
                                  Ver
                                </Button>
                                <div className="d-flex align-items-center">
                                  <div className="avatar-icon-wrapper mr-2">
                                    <div className="avatar-icon">
                                      <img
                                        alt="..."
                                        src={
                                          element.userId.urlImage
                                            ? element.userId.urlImage
                                            : require("../../assets/images/climind/default_avatar.png")
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div>
                                    <a>
                                      {element.userId.name
                                        ? element.userId.name
                                        : "sin nombre"}
                                      &nbsp;
                                      {element.userId.surname
                                        ? element.userId.surname
                                        : null}
                                    </a>
                                    <span className="text-black-50 d-block">
                                      {element.userId.email
                                        ? element.userId.email
                                        : "sin email"}
                                    </span>
                                  </div>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xl={6}
                                md={12}
                                className="pt-3 pt-xl-0 d-flex align-items-center"
                              >
                                <div className="align-box-row flex-grow-1">
                                  <div className="d-flex flex-column flex-grow-1 text-left">
                                    <p className="mb-0">
                                      Solicitado (
                                      {moment(element.date).format(
                                        "DD/MM/YYYY"
                                      )})
                                    </p>
                                  </div>
                                  {element.state === "DELIVERED" && (
                                    <>
                                      <div className="d-flex flex-column flex-grow-1 text-left">
                                        <p className="mb-0">
                                          <a>
                                            Entregado (
                                            {element.awardDeliveryDate &&
                                              moment(
                                                element.awardDeliveryDate
                                              ).format("DD/MM/YYYY")}
                                            )
                                          </a>
                                        </p>
                                      </div>
                                    </>
                                  )}
                                  {element.state === "CANCELLED" && (
                                    <>
                                    <div className="d-flex flex-column flex-grow-1 text-left">
                                      <p className="mb-0">
                                        <a>
                                          Cancelado (
                                          {element.awardCancelledDate &&
                                            moment(
                                              element.awardCancelledDate
                                            ).format("DD/MM/YYYY")}
                                          )
                                        </a>
                                      </p>
                                    </div>
                                  </>
                                  )}
                                  {element.state === "UNDELIVERED" && (
                                    <Button
                                      size="small"
                                      className="btn-neutral-primary ml-4"
                                      onClick={() => {
                                        setDataSelected(element);
                                        refetch({
                                          id: dbuser.getUserByUid.companyId,
                                          limit: 10,
                                          offset: offset,
                                        })
                                        refetchCSV({
                                          id: dbuser.getUserByUid.companyId,
                                        })
                                        toggleDeliver();
                                      }}
                                    >
                                      Marcar como entregado
                                    </Button>
                                  )}
                                  {element.state != "CANCELLED" && (
                                    <Button
                                      size="small"
                                      className="btn-neutral-danger ml-4"
                                      onClick={() => {
                                        setDataSelected(element);
                                        refetch({
                                          id: dbuser.getUserByUid.companyId,
                                          limit: 10,
                                          offset: offset,
                                        })
                                        refetchCSV({
                                          id: dbuser.getUserByUid.companyId,
                                        })
                                        toggleCancel();
                                      }}
                                    >
                                      Cancelar entrega
                                    </Button>
                                    )}
                                </div>
                              </Grid>
                            </Grid>
                          </ListItem>
                        );
                      }
                    )
                  ) : (
                    <h5 className="my-5 ml-4">
                      Aún no se han canjeado premios en tu empresa
                    </h5>
                  )}
                </List>
                <div className="divider" />
                <div className="p-3 d-flex justify-content-center">
                  {/* <Pagination className="pagination-primary" count={10} onClick={handleLoadMore}/> */}
                  <Pagination
                    count={Math.ceil(
                      data.getAchievedAwardsByCompanyId.totalAchievedAwards /
                        limit
                    )}
                    page={offset / limit + 1}
                    onChange={handlePageChange}
                    renderItem={(item) => (
                      <PaginationItem
                        component="a"
                        // href="#"
                        onClick={(e) => e.preventDefault()}
                        {...item}
                      />
                    )}
                  />
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>
        {dataSelected && dataSelected.awardId && (
          <Dialog
            open={modalViewPrice}
            onClose={toggleViewPrice}
            classes={{ paper: "shadow-lg rounded" }}
          >
            <div className="text-center p-5">
              <h4 className="font-weight-bold mt-4">
                {dataSelected.awardId.name}
              </h4>

              <h5 className="font-weight-bold mt-4">
                {dataSelected.awardId.description}
              </h5>
              <h5 className="font-weight-bold mt-4">
                Forma de entrega: {dataSelected.awardId.delivery}
              </h5>
              <h6 className="font-weight-bold mt-4">Comprado en la fecha:</h6>
              <h6 className="font-weight-bold mt-4">
                {moment(dataSelected.date).format("DD/MM/YYYY")}
              </h6>
              <div className="pt-4">
                <Button
                  onClick={toggleViewPrice}
                  className="btn-success btn-pill mx-1"
                >
                  <span className="btn-wrapper--label">Cerrar</span>
                </Button>
              </div>
            </div>
          </Dialog>
        )}
        <Dialog
          open={modalDeliver}
          onClose={toggleDeliver}
          classes={{ paper: "shadow-lg rounded" }}
        >
          <div className="text-center p-5">
            <div className="avatar-icon-wrapper rounded-circle m-0">
              <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-info text-info m-0 d-130">
                <FontAwesomeIcon
                  icon={["fas", "gift"]}
                  className="d-flex align-self-center display-3"
                />
              </div>
            </div>
            <h4 className="font-weight-bold mt-4">
              ¿Quieres marcar como entregado este premio al usuario?
            </h4>
            <p className="mb-0 font-size-lg">
              No podrás deshacer esta operación.
            </p>
            <div className="pt-4">
              <Button
                onClick={toggleDeliver}
                className="btn-neutral-secondary btn-pill mx-1"
              >
                <span className="btn-wrapper--label">Cancelar</span>
              </Button>
              <Button
                onClick={async () => {
                  // console.log(dataSelected);
                  let uid = dbuser.getUserByUid.uid;
                  let id = dbuser.getUserByUid.companyId;
                  let response = await changeStateAward({
                    variables: {
                      id: dataSelected.id,
                      input: { state: "DELIVERED" },
                    },
                    refetchQueries: [
                      {
                        query: GET_ACHIEVED_AWARDS_BY_COMPANYID,
                        variables: { id, id },
                      },
                    ],
                  });
                  setDataSelected(null);
                  getUserWithFirebase()
                  toggleDeliver();
                }}
                className="btn-info btn-pill mx-1"
              >
                <span className="btn-wrapper--label">Continuar</span>
              </Button>
            </div>
          </div>
        </Dialog>
        <Dialog
          open={modalCancel}
          onClose={toggleCancel}
          classes={{ paper: "shadow-lg rounded" }}
        >
          <div className="text-center p-5">
            <div className="avatar-icon-wrapper rounded-circle m-0">
              <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
                <FontAwesomeIcon
                  icon={["fas", "gift"]}
                  className="d-flex align-self-center display-3"
                />
              </div>
            </div>
            <h4 className="font-weight-bold mt-4">
              ¿Quieres dar marcha atrás y cancelar la entrega de este premio al
              usuario? Se le devolverán los coins.
            </h4>
            <p className="mb-0 font-size-lg">
              No podrás deshacer esta operación.
            </p>
            <div className="pt-4">
              <Button
                onClick={toggleCancel}
                className="btn-neutral-danger btn-pill mx-1"
              >
                <span className="btn-wrapper--label">Cancelar</span>
              </Button>
              <Button
                onClick={async () => {
                  // console.log(dataSelected);
                  let uid = dbuser.getUserByUid.uid;
                  let id = dbuser.getUserByUid.companyId;
                  let response = await changeStateAward({
                    variables: {
                      id: dataSelected.id,
                      input: { state: "CANCELLED" },
                    },
                    refetchQueries: [
                      {
                        query: GET_ACHIEVED_AWARDS_BY_COMPANYID,
                        variables: { id, id },
                      },
                    ],
                  });
                  setDataSelected(null);
                  getUserWithFirebase();
                  toggleCancel();
                }}
                className="btn-danger btn-pill mx-1"
              >
                <span className="btn-wrapper--label">Continuar</span>
              </Button>
            </div>
          </div>
        </Dialog>
      </>
    );
  } else {
    return null;
  }
}
