import React from "react";

import { Card, CardContent, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Chart from "react-apexcharts";



export default function DashboardUsage2({
  arrayRecurringUsers,
  arrayDates,
  arrayRecurringUsersPrevious,
  arrayDatesPrevious
}) {

  // Datos para el chart de evolución
  const dataSeriesChart = [
    {
      name: "Recurrencia de usuarios",
      data: arrayRecurringUsers,
    },
  ];
  const dataOptionsChart = {
    chart: {
      toolbar: {
        show: false,
      }
    },
    xaxis: {
      categories: arrayDates,
      labels: {
        rotate: -15,
        rotateAlways: true,
        formatter: function (val) {
          if (val) {
            let tmp = val.split("T")
            let tmp2 = tmp[0].split("-")
            return tmp2[2] + "-" + tmp2[1]
          } else {
            return val
          }
        }
      },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return Math.round(val) + "%";
        },
      },
      // max: 100
    },
  };

  if (arrayRecurringUsers === undefined || arrayDates === undefined) return "Esperando";

  return (
    <>
      <Card className="card-box mb-spacing-6-x2 border-0 shadow-first-sm">
        <div className="card-header">
          <div className="card-header--title font-size-md font-weight-bold py-2">
            <div>
              <h6 className="font-weight-bold font-size-xl mb-1 text-black">
                Evolución del Uso de Climind
              </h6>
              <p className="text-black-50 font-size-lg mb-0">
                Mira como tus empleados son activos en la plataforma
              </p>
            </div>
            <div className="card-tr-actions">
              <Tooltip
                classes={{
                  tooltip: "text-center p-3 tooltip-secondary",
                }}
                arrow
                placement="top"
                title="Evolución de la recurrencia en el tiempo. 
                  Evolución del % de usuarios recurrentes en el período de tiempo."
              >
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="text-first font-weight-bold"
                >
                  <div
                    className=" avatar-icon rounded-circle mr-2"
                    style={{ width: 26, height: 26 }}
                  >
                    <img
                      alt="..."
                      src={require("../../../../../../assets/images/climind/info.png")}
                    />
                  </div>
                </a>
              </Tooltip>
            </div>
          </div>
        </div>
        <CardContent>
          {(dataOptionsChart && dataSeriesChart && (dataSeriesChart[0].data.length > 0)) ? (
            <Chart
              options={dataOptionsChart}
              series={dataSeriesChart}
              type="area"
              height={368}
            />
          ) : <div>No hay datos para los filtros seleccionados</div>}
        </CardContent>
      </Card>
    </>
  );
}
