import React, { useState } from 'react';

import {
    Grid,
    Card,
    TextField,
    Button
} from '@material-ui/core';

import { NEW_TICKET } from 'data/mutations';
import { useMutation } from '@apollo/react-hooks';
import SnackBarMessage from "../../../../utils/SnackBarMessage";

export default function FormTicket({ companyId,userEmail,userID }) {

    const [options, setOptions] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [messageAlert, setMessageAlert] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [newTicket, { data }] = useMutation(NEW_TICKET);

    const saveTicket = async () => {
        let dataInput = {
            companyId: companyId,
            userEmail: userEmail,
            userID: userID,
            subject: options,
            message: message,
        };

        if (message === '') {
            console.log(message);
            setMessageAlert("Error: Mensaje vacío")
            setSeverity("error")
        } else {

            let response = await newTicket({
                variables: { input: dataInput }
            });

            setMessageAlert(response.data.newTicket)
            setSeverity("success")

            
        }

        setOpenSnackbar(true, () => {
            setTimeout(function () {
                setOpenSnackbar(false);
            }, 5000);
        });

        setMessage('')
        setOptions('none')
    }

    const handleChangeSubject = (event) => {
        setOptions(event.target.value);
    };

    const handleChangeMessage = (event) => {
        setMessage(event.target.value);
    };

    const optionsM = [
        {
            value: 'Propuesta de mejora',
            label: 'Propuesta de mejora',
        },
        {
            value: 'Ayuda interpretación de datos',
            label: 'Ayuda interpretación de datos',
        },
        {
            value: 'Ayuda administracion',
            label: 'Ayuda administracion',
        },
        {
            value: 'Ayuda Usuarios',
            label: 'Ayuda Usuarios',
        },
        {
            value: 'Ayuda Funcionalidades',
            label: 'Ayuda Funcionalidades',
        },
        {
            value: 'Incidencia con datos',
            label: 'Incidencia con datos',
        },
        {
            value: 'Incidencia con resultados',
            label: 'Incidencia con resultados',
        },
        {
            value: 'Incidencia con funcionalidades',
            label: 'Incidencia con funcionalidades',
        },
    ];

    return (
        <>
            <SnackBarMessage
                open={openSnackbar}
                handleClose={() => setOpenSnackbar(false)}
                message={messageAlert}
                severity={severity}
            />
            <Grid container>
                <Grid item xs={12}>
                    <Card className="p-4 mt-4" style={{ backgroundColor: '#eceef7' }} elevation={0}>
                        <div>
                            <TextField
                                select
                                fullWidth
                                className="m-2"
                                id="outlined-multiline-static"
                                label="Motivo"
                                // defaultValue={optionsM[0]}   
                                value={options}
                                onChange={handleChangeSubject}
                                SelectProps={{
                                    native: true,
                                }}
                                helperText="Porfavor selecciona tu motivo"
                                variant="outlined"
                            >   
                                <option key="none" value="none" selected disabled>
                                    Selecciona una opción
                                </option>
                                {optionsM.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </TextField>

                            <TextField
                                className="m-2"
                                fullWidth
                                id="outlined-multiline-static"
                                label="Mensaje"
                                value={message}
                                multiline
                                rows={4}
                                placeholder="Escribe aquí tu mensaje"
                                variant="outlined"
                                onChange={handleChangeMessage}
                            />
                            <Button variant="contained" color="primary" className="m-2" onClick={() => saveTicket()}>
                                Enviar
                            </Button>

                        </div>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}
